/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */

import React, { useState, useEffect, useRef, useContext } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import moment from 'moment-timezone';

import {
  // Tooltip,
  TablePagination,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Paper,
  IconButton,
  Typography,
  Box,
  Collapse,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from 'date-fns/locale';
// import GetAppIcon from '@material-ui/icons/GetApp';

// import { useExcelDownloder } from 'react-xls';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';

import Button from '@material-ui/core/Button';

import Select from 'react-select';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import Status from '../../service/translateStatus';
import Loading from '../../components/loading/Loading';

import FlashContext from '../../components/contexts/FlashContext';

import api from '../../service/axios/api';

function createData(filename, bank, amount, items, createdAt, all) {
  // const density = population / size;
  return {
    filename,
    bank,
    amount,
    items,
    createdAt,
    all,
  };
}

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  table: {
    minWidth: 1100,
  },
});

const StyledTableCell = withStyles(() => ({
  head: {
    // backgroundColor: theme.palette.common.black,
    // color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const createDownload = url => {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'data:text/plain;charset=utf-8,arquivo.txt'); // or any other extension
  document.body.appendChild(link);
  link.click();
};

const translateStatusProc = proc => {
  switch (proc) {
    case '000':
      return 'Sucesso';
    case '100':
      return 'Cartão não existente';
    case '102':
      return 'Cartão não permitido';
    case '103':
      return 'Cartão em prevenção';
    case '200':
      return 'Erro na identificação';
    case '210':
      return 'Dado de campo inconsistente';
    case '909':
      return 'Erro genérico';
    default:
      return proc;
  }
};

const translateStatusCard = card => {
  switch (card) {
    case '00':
      return 'Ativo';
    case '01':
      return 'Bloqueado por tentativa de senha errada';
    case '02':
      return 'Inativo';
    case '03':
      return 'Cartão expirado';
    case '04':
      return 'Perdido';
    case '05':
      return 'Roubado';
    case '06':
      return 'Cancelado a pedido do cliente';
    case '07':
      return 'Cancelado a pedido do emissor';
    case '08':
      return 'Uso fraudulento';
    case '09':
      return 'Dormente';
    case '60':
      return 'Preventivo de fraude';
    case '64':
      return 'Definitivo de fraude';
    case '66':
      return 'Cancelado';
    case '99':
      return 'Não informado/não consultado';
    default:
      return card;
  }
};

function Row(props) {
  const { t } = useTranslation();
  const { row, idx } = props;
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <StyledTableRow
        key={idx}
        style={{ backgroundColor: idx % 2 !== 0 ? '#fff' : '' }}
      >
        {/* <StyledTableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </StyledTableCell> */}
        <StyledTableCell
          style={{ cursor: 'pointer' }}
          onClick={() => createDownload(row.all.gcs_file_send)}
        >
          {row.filename}
        </StyledTableCell>
        <StyledTableCell>{row.bank}</StyledTableCell>
        <StyledTableCell
          style={{ cursor: 'pointer' }}
          // onClick={() => createDownload(row.urlReturnFile)}
        >
          {new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(row.amount)}
        </StyledTableCell>
        {/* <StyledTableCell>{Status(row.acessoStatus, t)}</StyledTableCell> */}
        <StyledTableCell>{row.items}</StyledTableCell>
        <StyledTableCell>{row.createdAt}</StyledTableCell>
      </StyledTableRow>
      {/* <TableRow> */}
    </>
  );
}

// eslint-disable-next-line no-unused-vars
export default function CnabTable({
  docs,
  page,
  setPage,
  itens,
  setItens,
  total,
}) {
  const classes = useStyles();

  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (docs) {
      const list = [];
      docs.forEach(d => {
        list.push(
          createData(
            d.filename,
            d.bank_account.bank,
            d.amount,
            d.items,
            moment(d.created_at).format('DD/MM/YYYY hh:mm'),
            d,
          ),
        );
      });
      setRows(list);
    }
  }, [docs]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setItens(event.target.value);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              {/* <StyledTableCell>Inicio</StyledTableCell> */}
              {/* <StyledTableCell>Fim</StyledTableCell> */}
              {/* <StyledTableCell /> */}
              <StyledTableCell>CNAB</StyledTableCell>
              <StyledTableCell>Banco</StyledTableCell>
              <StyledTableCell>Valor</StyledTableCell>
              <StyledTableCell>Itens</StyledTableCell>
              <StyledTableCell>Data</StyledTableCell>
              {/* <StyledTableCell>Valor</StyledTableCell>
              <StyledTableCell>Data</StyledTableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row, i) => (
              <Row key={row.filename} row={row} idx={i} />
            ))}
            {/* {rows.map((row, i) => (
              <StyledTableRow key={i}>
                <StyledTableCell>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpen(!open)}
                  >
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                </StyledTableCell>
                <StyledTableCell>
                  <div>
                    Qtd.: {row.requestCards}
                    <br />
                    Sucesso: {row.cardSucess}
                    <br />
                    Rejeitados: {row.cardReject}
                    <br />
                  </div>
                </StyledTableCell>
                <StyledTableCell
                  style={{ cursor: 'pointer' }}
                  onClick={() => createDownload(row.urlSendFile)}
                >
                  {row.sendFile}
                </StyledTableCell>
                <StyledTableCell
                  style={{ cursor: 'pointer' }}
                  onClick={() => createDownload(row.urlReturnFile)}
                >
                  {row.fileReturn}
                </StyledTableCell>
                <StyledTableCell>{row.acessoStatus}</StyledTableCell>
                <StyledTableCell>{row.value}</StyledTableCell>
                <StyledTableCell>
                  <div>
                    Criação: {row.dataCriation}
                    <br />
                    Inicio: {row.dateCreated}
                    <br />
                    Fim: {row.dateEnd}
                    <br />
                  </div>
                </StyledTableCell>
              </StyledTableRow>
            ))} */}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100, 1000, 50000]}
        component="div"
        count={total}
        rowsPerPage={itens}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
