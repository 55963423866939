/* eslint-disable no-restricted-exports */
/* eslint-disable react/prop-types */
import React from 'react';

// Importing MaterialUI components
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import CycleMonth from './CycleMonth';
import CycleStatic from './CycleStatic';
import CyclePick from './CyclePick';

function Cycle({ title, options, value, onChange }) {
  return (
    <>
      <Typography>{title}</Typography>
      <Box display="flex" flexDirection="row" alignItems="center">
        <CycleStatic value={value} options={options} onChange={onChange} />
        <CycleMonth
          title="Ciclo Aberto"
          value={value}
          maxValue={new Date()}
          onChange={onChange}
        />
        <CyclePick value={value} maxValue={new Date()} onChange={onChange} />
      </Box>
    </>
  );
}

export { Cycle as default };
