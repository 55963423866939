/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';

// import { NextPageContext } from 'next';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import CampaignForm from '../../components/forms/CampaignForm';
import api from '../../../../service/axios/api';
// import { privateFetch } from '@/util/fetch';

const useStyles = makeStyles(theme => ({
  header: {
    marginBottom: theme.spacing(2),
  },
}));

function EditProposal({ proposal, id }) {
  const [campaign, setCampaign] = useState();
  const router = useHistory();
  const classes = useStyles();
  const pp = useParams();

  useEffect(() => {
    if (pp) {
      const cancel = new AbortController();
      api.campaign
        .getById(cancel, pp.id)
        .then(camp => {
          setCampaign({
            name: camp?.retorno?.name,
            expiresIn: camp?.retorno?.expires_in,
            comments: camp?.retorno?.comments,
          });
        })
        .catch(error => {
          console.log('e', error);
        });
    }
    // setCampaign(pp.id)
  }, [pp]);

  // const onSuccess = p => axios.put(`/api/proposals/${id}`, p);
  const onSuccess = p => api.campaign.edit(pp.id, p);

  const callback = () => {
    router.push('/sales/campaign');
  };

  return (
    <>
      <Grid
        className={classes.header}
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Typography variant="h4" component="h2" gutterBottom>
          Editar oportunidade
        </Typography>
      </Grid>
      {campaign && (
        <CampaignForm
          campaign={campaign}
          onSuccess={onSuccess}
          code={200}
          titleModal="Campanha editada com sucesso"
          actionModal={callback}
        />
      )}
    </>
  );
}

export const getServerSideProps = async context => {
  console.log('vamos consultar');
  const id = context.query.proposalId;
  const cancel = new AbortController();
  try {
    // const {
    //   data: { company, productDetails },
    // } = await api.campaign.getById(cancel, id);
    console.log('vamos consultar');
    console.log(id);
    const res = await api.campaign.getById(cancel, id);
    console.log('Consulta por id', res);
    return {
      props: {
        id,
        proposal: '',
      },
    };
  } catch (err) {
    const error = err;

    return {
      notFound: true,
    };
  }
};

export default EditProposal;
