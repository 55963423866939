/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { styled, Grid } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import styles from '../../../styles/Layout.module.css';

const useStyles = makeStyles(theme =>
  createStyles({
    myButton: {
      color: '#2a3176',
      backgroundColor: '#f4f4f4',
      fontWeight: 'bold',
    },
    myGrid: {
      minWidth: 260,
      backgroundColor: '#f4f4f4',
      margin: theme.spacing(1),
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      textAlign: 'center',
      cursor: 'pointer',
      border: '2px solid #c5c5c5',
      borderRadius: 10,
    },
    myGridSelected: {
      minWidth: 260,
      backgroundColor: '#41b490',
      margin: theme.spacing(1),
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      textAlign: 'center',
      cursor: 'pointer',
      border: '2px solid #41b490',
      borderRadius: 10,
    },
    myLabel: {
      padding: theme.spacing(6),
      color: '#2a3176',
      fontWeight: 'bold',
    },
    myLabelSelected: {
      padding: theme.spacing(6),
      color: '#fff',
      fontWeight: 'bold',
    },
  }),
);

// eslint-disable-next-line react/prop-types
export default function NotMoney({ notMoney, setNotMoney }) {
  const [selected, setSelected] = useState();
  const classes = useStyles();
  useEffect(() => {
    if (notMoney) {
      setSelected(notMoney);
    }
  }, []);

  useEffect(() => {
    if (selected) {
      setNotMoney(selected);
    }
  }, [selected]);
  return (
    <div className="box-new-card">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'start',
          alignItems: 'center',
        }}
      >
        <h6 className={styles.textBoxInform}>
          Se o cartão não tiver saldo para a cobrança do custo devemos:
        </h6>
      </div>
      <Grid
        container
        spacing={1}
        style={{
          justifyContent: 'center',
          padding: 40,
        }}
      >
        <Grid
          className={
            selected === 'company' ? classes.myGridSelected : classes.myGrid
          }
          item
          xs={12}
          sm={8}
          md={2}
          lg={2}
          xl={2}
          onClick={() => setSelected('company')}
        >
          <p
            className={
              selected === 'company' ? classes.myLabelSelected : classes.myLabel
            }
          >
            Cobrar da empresa
          </p>
        </Grid>
        <Grid
          className={
            selected === 'card' ? classes.myGridSelected : classes.myGrid
          }
          item
          xs={12}
          sm={8}
          md={2}
          lg={2}
          xl={2}
          onClick={() => setSelected('card')}
        >
          <p
            className={
              selected === 'card' ? classes.myLabelSelected : classes.myLabel
            }
          >
            Aguardar crédito
            <br />
            para efetivar solicitação
          </p>
        </Grid>
      </Grid>
    </div>
  );
}
