/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
// import Dialog from '@mui/material/Dialog';
import { Dialog } from '@material-ui/core';
import routing from '../../../service/routes/routing';
import MyButton from '../../../components/button/MyButton';
import TableTransfer from '../../../components/tables/transfer/TableTransfer';
import Checkout from './transfer/new/Checkout';
// import api from '../../../services/axios/api';

function NewCreditCard() {
  const history = useHistory();
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [itens, setItens] = useState({
    label: '25',
    value: 25,
  });
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    setTotalPages(0);
    setProducts([]);
    // api.product
    //   .getCard(itens.value, page)
    //   .then((card) => {
    //     setTotalPages(card.pages);
    //     setProducts(card.docs);
    //     console.log(card);
    //   })
    //   .catch((error) => { console.log(error); });
  }, [itens, page]);
  return (
    <>
      <div className="col-sm-12" style={{ marginTop: 50 }}>
        <p
          className="textMenu"
          onKeyDown={() => history.push(routing.dashboard)}
          onClick={() => history.push(routing.dashboard)}
        >
          Menu Inicial
        </p>
        <div
          className="col-sm-12"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <p className="textTitle">Transferência</p>
          <MyButton
            style={{ marginRight: 50, width: 300 }}
            label="Nova Transferência"
            onClick={() => setOpenModal(true)}
          />
        </div>
        <div>
          <TableTransfer
            products={products}
            totalPages={totalPages}
            page={page}
            setPage={setPage}
            itens={itens}
            setItens={setItens}
          />
        </div>
      </div>
      <Dialog
        onClose={() => setOpenModal(false)}
        open={openModal}
        // width={1192}
        // height={776}
        fullWidth
        maxWidth="lg"
      >
        <Checkout setOpenModal={setOpenModal} />
      </Dialog>
    </>
  );
}

export default NewCreditCard;
