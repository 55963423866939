/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */
import * as React from 'react';
import { Grid } from '@material-ui/core';
import styles from '../../../styles/Layout.module.css';

export default function Review({
  data,
  address,
  typeAddress,
  numberAddress,
  complement,
  district,
  city,
  ac,
  cep,
  uf,
  cobranca,
  notMoney,
}) {
  console.log(
    'REVIEW',
    address,
    typeAddress,
    numberAddress,
    complement,
    district,
    city,
    ac,
    cep,
    uf,
    cobranca,
    notMoney,
  );

  return (
    <div className="box-new-card">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'start',
          alignItems: 'center',
        }}
      >
        <h6 className={styles.textBoxInform}>Confirme os dados:</h6>
      </div>
      <Grid
        container
        spacing={1}
        style={{
          justifyContent: 'center',
          padding: 40,
        }}
      >
        <h6 className={styles.labelTitleResum} style={{ marginBottom: 8 }}>
          {`Você confirma a emissão de 2ª via do cartão de proxy ${data?.proxy}
          pertencente a ${data?.Individual?.name}, CPF ${
            data?.Individual?.cpf
          }, com custos pago ${
            cobranca === 'card'
              ? `no cartão pertencente a ${
                  data?.Individual?.name
                }, e se o cartão não tiver saldo ${
                  notMoney === 'company'
                    ? 'os custos serão pagos pela empresa'
                    : 'aguardaremos o credito para efetivar a solicitação'
                }`
              : 'pela empresa'
          }?`}
          <br />
          {`O envio do cartão será feito para: ${
            typeAddress === 'company'
              ? 'O endereço da empresa'
              : `${address}, ${numberAddress}, ${district} - ${city} - ${uf?.label}. CEP: ${cep}. Sendo entregue aos cuidados de: ${ac}`
          }?`}
        </h6>
      </Grid>
    </div>
  );
}
