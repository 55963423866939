/* eslint-disable no-unused-vars */
import { useState, useCallback } from 'react';

const useBoolean =
  params =>
  ({ initialValue }) => {
    const defaultValue = initialValue || false;
    const [value, setValue] = useState(defaultValue);

    const to = useCallback(newValue => {
      setValue(newValue);
    }, []);

    const toFalse = useCallback(() => {
      setValue(false);
    }, []);

    const toTrue = useCallback(() => {
      console.log('intern to true');
      setValue(true);
    }, []);

    const toggle = useCallback(() => {
      setValue(_value => !_value);
    }, []);

    return {
      value,
      to,
      toFalse,
      toTrue,
      toggle,
    };
  };

export default useBoolean;
