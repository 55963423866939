/* eslint-disable react/prop-types */
import React, { useContext, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import moment from 'moment-timezone';
import StepperContext from '../../context/StepperContext';
import SuccessModal from '../SuccessModal';
// import api from '../../../../service/axios/api';
// import { CARD_BRANDS, PRODUCTS } from '../../util/constants';

const useStyles = makeStyles(theme => ({
  form: {
    minHeight: '500px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  content: {
    padding: theme.spacing(4),
  },
  section: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(8),
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(4),
    background: '#f0f0f0',
    width: '100%',
    '& > *': {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    color: blue[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

function CampaingReviewForm({ campaing }) {
  const classes = useStyles();
  const stepper = useContext(StepperContext);
  const [loading, setLoading] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);

  const submit = async event => {
    event.preventDefault();
    setLoading(true);

    try {
      // const response = await api.campaign.new(
      //   campaing.name,
      //   campaing.expiresIn,
      //   campaing.comments,
      // );
      // const response = await axios.post('/api/proposals', proposal)
      const response = await stepper.onSuccess(campaing);
      // eslint-disable-next-line no-console
      if (response.status === stepper.code) {
        setOpenSuccessModal(true);
      }
    } catch (err) {
      const error = err;

      /* eslint no-console: ["error", { allow: ["error"] }] */
      console.error(error);
    }

    setLoading(false);
  };

  const handleCloseModal = () => {
    setOpenSuccessModal(false);
    stepper.actionModal();
  };

  // const { company, productDetails } = campaing;
  // const { campaign } = productDetails;

  // const { commercialAgent } = productDetails;

  return (
    <>
      <form className={classes.form} onSubmit={submit}>
        <Container className={classes.content}>
          <Typography variant="h5" component="h3" gutterBottom>
            Resumo da campanha
          </Typography>

          <Box bgcolor="background.gray" p={6}>
            <Grid container spacing={5}>
              <Grid item lg={4} sm={6} xs={12}>
                <Typography variant="subtitle1" color="initial">
                  Nome:
                </Typography>
                <Typography variant="body2" color="initial">
                  {campaing?.name}
                </Typography>
              </Grid>
              <Grid item lg={4} sm={6} xs={12}>
                <Typography variant="subtitle1" color="initial">
                  Validade:
                </Typography>
                <Typography variant="body2" color="initial">
                  {/* {PRODUCTS.find(p => p.value === productDetails.product)
                    ?.label || ''}
                  {' - '}
                  {
                    CARD_BRANDS.find(
                      brand => brand.value === productDetails.productBrand,
                    )?.label
                  } */}
                  {campaing?.expiresIn
                    ? moment(campaing?.expiresIn).format('DD-MM-YYYY HH:mm')
                    : 'sem data de validade definida'}
                </Typography>
              </Grid>
              {/* {productDetails.product === 'prepaid_card' && (
                <Grid item lg={4} sm={6} xs={12}>
                  <Typography variant="subtitle1" color="initial">
                    Valor do Cartão:
                  </Typography>
                  <Typography variant="body2" color="initial">
                    {campaign.issueFee}
                  </Typography>
                </Grid>
              )} */}
              <Grid item lg={4} sm={6} xs={12}>
                <Typography variant="subtitle1" color="initial">
                  Comentários:
                </Typography>
                <Typography variant="body2" color="initial">
                  {campaing?.comments}
                </Typography>
              </Grid>
              {/* <Grid item lg={4} sm={6} xs={12}>
                <Typography variant="subtitle1" color="initial">
                  Taxa ADM:
                </Typography>
                <Typography variant="body2" color="initial">
                  {campaign.isAdministrationRate
                    ? `${campaign.administrationRate}%`
                    : `R$ ${campaign?.administrationFee}`}
                </Typography>
              </Grid>
              <Grid item lg={4} sm={6} xs={12}>
                <Typography variant="subtitle1" color="initial">
                  CNPJ:
                </Typography>
                <Typography variant="body2" color="initial">
                  {company.cnpj}
                </Typography>
              </Grid>
              <Grid item lg={4} sm={6} xs={12}>
                <Typography variant="subtitle1" color="initial">
                  Nome da Campanha:
                </Typography>
                <Typography variant="body2" color="initial">
                  {campaign.name}
                </Typography>
              </Grid>
              <Grid item lg={4} sm={6} xs={12}>
                <Typography variant="subtitle1" color="initial">
                  Agentes Comerciais:
                </Typography>
                <Typography variant="body2" color="initial">
                  {commercialAgent.company?.legalName}
                </Typography>
              </Grid> */}
            </Grid>
          </Box>
        </Container>
        <div className={classes.footer}>
          <Button onClick={stepper.prev} type="button" variant="contained">
            Voltar
          </Button>
          <div className={classes.wrapper}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
            >
              Salvar campanha
            </Button>
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </div>
      </form>
      <SuccessModal
        title={stepper.titleModal}
        open={openSuccessModal}
        onClose={handleCloseModal}
      />
    </>
  );
}

export default CampaingReviewForm;
