/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import React from 'react';
// import MailOutline from '@mui/icons-material/MailOutline';
// import Box from '@mui/material/Box';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LockIcon from '@material-ui/icons/Lock';

import {
  Grid,
  Box,
  Input,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import './input.css';
import { Visibility, VisibilityOff } from '@material-ui/icons';

const MyInput = function (props) {
  const { label, icon, type, myRef, enterPress } = props;
  const [showPassword, setShowPassword] = React.useState('password');

  const handleClickShowPassword = () => {
    // setValues({ ...values, showPassword: !values.showPassword });
    setShowPassword(showPassword === 'text' ? 'password' : 'text');
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
    setShowPassword('text');
  };
  const handleMouseUpPassword = event => {
    event.preventDefault();
    setShowPassword('password');
  };

  // const handlePasswordChange = prop => event => {
  //   setValues({ ...values, [prop]: event.target.value });
  // };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={3} style={{ alignItems: 'center' }}>
        {/* <div className="myStyle"> */}
        <Grid item xs="auto" style={{ marginLeft: -50 }}>
          {icon ? (
            icon === 'password' ? (
              <LockIcon
                style={{
                  color: '#41b490',
                  height: '2em',
                  width: 31,
                  // marginLeft: -50,
                  // paddingRight: 30,
                }}
              />
            ) : icon === 'user' ? (
              <MailOutlineIcon
                style={{
                  color: '#41b490',
                  height: '2em',
                  width: 31,
                  // marginLeft: -50,
                  // paddingRight: 30,
                }}
              />
            ) : (
              ''
            )
          ) : null}
        </Grid>
        <Grid item xs="auto">
          <input
            name={label}
            onKeyUpCapture={e => e.key === 'Enter' && enterPress(label)}
            ref={myRef}
            className="inputStyle"
            placeholder={label}
            type={(label === 'Senha' || label === 'Código') && showPassword}
          />
          {/* <Input
            ref={myRef}
            className="inputStyle"
            type={values.showPassword ? 'text' : 'password'}
            onChange={handlePasswordChange('password')}
            value={values.password}
            name={label}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          /> */}
        </Grid>
        {label === 'Senha' || label === 'Código' ? (
          <IconButton
            style={{
              margin: 0,
              padding: 0,
              height: '100%',
              alignItems: 'center',
              marginLeft: 5,
              // display: 'flex',
              // justifyContent: 'center',
            }}
            // onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
            onMouseUp={handleMouseUpPassword}
          >
            {showPassword === 'text' ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        ) : (
          <div style={{ width: 24 }} />
        )}
      </Grid>
    </Box>
  );
};

export default MyInput;
