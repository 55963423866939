import { useState, useCallback } from 'react';

const initialState = {
  mouseX: null,
  mouseY: null,
};

const useContextMenu = () => {
  const [state, setState] = useState(initialState);

  const onOpen = useCallback(event => {
    event.preventDefault();
    setState({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
  }, []);

  const onClose = useCallback(() => {
    setState(initialState);
  }, []);

  return { state, onOpen, onClose };
};

export default useContextMenu;
