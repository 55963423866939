/* eslint-disable no-return-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
// import moment from 'moment-timezone';
import styles from '../../../styles/Layout.module.css';
import api from '../../../service/axios/api';
// import TablePremio from '../../../components/tables/premiados/TablePremioStoke';

// eslint-disable-next-line prettier/prettier
export default function UploadPlan({ refQtdCards, type, orderId, contract, myCnpj, campaign, specOrder }) {
  // const [myFile, setMyfile] = useState();
  // eslint-disable-next-line no-unused-vars
  const [orders, setOrders] = useState([]);
  const { t } = useTranslation();
  const [total, setTotal] = useState(0);
  const [sendValue, setSendValue] = useState(0);
  const [newCardValue, setNewCardValue] = useState(0);

  const [provision, setProvision] = useState(0);
  const [infMin, setInfMin] = useState(0);
  const [taxPercent, setTaxPercent] = useState(0);
  // const [sendValue, setSendValue] = useState(0);
  const [individuals, setIndividuals] = useState(0);
  // const [newCardValue, setNewCardValue] = useState(0);
  const [cardVincVlr, setCardVincVlr] = useState(0);
  const [finish, setFinish] = useState(false);
  const [ticket, setTicket] = useState(0);

  // useEffect(() => {
  //   if (valueCredit) {
  //     setTotal(Number(valueCredit));
  //   }
  // }, [valueCredit]);
  // useEffect(() => {
  //   if (orders.length > 0) {
  //     if (orders) {
  //       let vlr = 0.0;
  //       orders.forEach(o => {
  //         vlr += Number(o.Valor);
  //       });
  //       setTotal(vlr);
  //     }
  //   }
  // }, [orders]);
  useEffect(() => {
    if (orderId) {
      const cancel = new AbortController();
      api.order
        .getItemsByOrderId(Number(orderId), cancel)
        .then(details => {
          setOrders(details.docs);
          let tot = 0;
          let send = 0;
          // let card = 0;

          // details.docs.forEach(doc => {
          //   if (
          //     doc.comments === 'Carga' ||
          //     doc.comments === 'Transferência Emergencial para CC'
          //   ) {
          //     tot = Number(doc.value);
          //   }

          //   if (doc.comments === 'Tarifa de Postagem')
          //     send += Number(doc.value);
          //   if (
          //     doc.comments === 'Preço cartão Com Vínculo' ||
          //     doc.comments === '2a. via - Fraude' ||
          //     doc.comments === '2a. via cartão' ||
          //     doc.comments === '2a. via com Descarga' ||
          //     doc.comments === '2a. Via Cartão por Inatividade' ||
          //     doc.comments === 'Estoque Cartão Sem Vínculo'
          //   )
          //     card += Number(doc.value);
          // });

          // let card = 0;
          let prov = 0;
          let min = 0;
          let tax = 0;
          let cardVinc = 0;
          let iTicket = 0;
          const comp = [];
          const indv = [];

          details.docs.forEach(doc => {
            if (doc.indid) indv.push(doc.indid);
            if (doc.compid) comp.push(doc.compid);
            if (
              (doc.comments === 'Carga' ||
                doc.comments === 'Transferência Emergencial para CC') &&
              doc.status !== 'Rejected'
            ) {
              tot += Number(doc.value);
            }

            if (doc.comments === 'Tarifa de Postagem')
              send += Number(doc.value);
            if (
              (doc.comments === 'Preço cartão Com Vínculo' ||
                doc.comments === '2a. via - Fraude' ||
                doc.comments === '2a. via cartão' ||
                doc.comments === '2a. via com Descarga' ||
                doc.comments === '2a. Via Cartão por Inatividade' ||
                doc.comments === 'Estoque Cartão Sem Vínculo') &&
              doc.status === 'Received'
            ) {
              // card = Number(doc.value);
              if (refQtdCards) {
                cardVinc += Number(doc.value) * Number(refQtdCards);
              } else {
                cardVinc += Number(doc.value) * refQtdCards;
              }
            }

            if (doc.comments === 'Provisão') {
              prov += Number(doc.value);
              if (doc.is_auto_provision) {
                setFinish(true);
              }
            }
            if (
              doc.comments === 'Tarifa de Emissão de Boleto' &&
              !doc.is_detail
            )
              iTicket += Number(doc.value);
            if (
              doc.comments === 'Tarifa de Carga Inferior ao Minimo' &&
              doc.status === 'Received'
            )
              min += Number(doc.value);
            if (doc.comments === 'Honorários (Percentual)')
              tax += Number(doc.value);
          });

          const uniqInd = [...new Set(indv)];
          const uniqComp = [...new Set(comp)];

          setIndividuals(uniqInd.length + uniqComp.length);
          setSendValue(send.toFixed(2));
          // setNewCardValue(card.toFixed(2));
          setTicket(iTicket);
          setTotal(tot);
          setProvision(prov);
          setInfMin(min);
          setTaxPercent(tax);
          setCardVincVlr(cardVinc);

          // setSendValue(send.toFixed(2));
          // setNewCardValue(card.toFixed(2));
          // setTotal(tot);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [orderId]);

  return (
    <div className="box-new-card">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Grid container>
          <Grid item>
            <h6 className={styles.textBoxInform}>
              {t('newOrder.reviewStoke.label')}
            </h6>
          </Grid>
        </Grid>
        <Grid container style={{ justifyContent: 'end' }}>
          {/* <Grid item style={{ marginRight: 30 }}>
            <h6 className={styles.textBoxInform}>
              Data:{moment.utc(new Date()).format('DD/MM/yyyy')}
            </h6>
          </Grid> */}
          {/* <Grid item>
            <h6 className={styles.textBoxInform}>Pedido P000000</h6>
          </Grid> */}
        </Grid>
      </div>
      <Grid
        container
        direction="row"
        style={{ justifyContent: 'space-between' }}
      >
        <Grid item xs={12}>
          <div style={{ display: 'flex' }}>
            <p className={styles.labelTitleResum}>
              {t('newOrder.reviewStoke.type')}:
            </p>
            <p>
              {t('newOrder.reviewStoke.card')}
              {specOrder === 'DCP'
                ? ' (DEMONSTRATIVO CONSOLIDADO DE PREMIAÇÃO)'
                : ''}
            </p>
          </div>
        </Grid>
        <Grid item>
          <div style={{ display: 'flex' }}>
            <p className={styles.labelTitleResum}>
              {t('newOrder.reviewStoke.brand')}:
            </p>
            <p> MasterCard</p>
          </div>
        </Grid>
        <Grid item>
          <div style={{ display: 'flex' }}>
            <p className={styles.labelTitleResum}>
              {t('newOrder.reviewStoke.issuer')}:
            </p>
            <p> Acesso</p>
          </div>
        </Grid>
        <Grid item>
          <div style={{ display: 'flex' }}>
            <p className={styles.labelTitleResum}>
              {t('newOrder.reviewStoke.name')}:
            </p>
            <p> JoyCard</p>
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        style={{ justifyContent: 'space-between' }}
      >
        <Grid item>
          <div style={{ display: 'flex' }}>
            {type && (
              <p className={styles.labelTitleResum}>
                {t('newOrder.reviewStoke.function')}:
              </p>
            )}
            <p>
              {type === 'compra'
                ? t('newOrder.reviewStoke.purchases')
                : type === 'presente'
                ? t('newOrder.reviewStoke.gift')
                : type
                ? t('newOrder.reviewStoke.withdraw')
                : ''}
            </p>
          </div>
        </Grid>
        <Grid item>
          <div style={{ display: 'flex' }}>
            <p className={styles.labelTitleResum}>
              {t('newOrder.reviewStoke.technology')}:
            </p>
            <p> {t('newOrder.reviewStoke.chip')}</p>
          </div>
        </Grid>
        <Grid item>
          <div style={{ display: 'flex' }}>
            <p className={styles.labelTitleResum}>
              {t('newOrder.reviewStoke.coverage')}:
            </p>
            <p> {t('newOrder.reviewStoke.international')}</p>
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        style={{ justifyContent: 'space-between' }}
      >
        <Grid item>
          <div style={{ display: 'flex' }}>
            <p className={styles.labelTitleResum}>
              {t('newOrder.reviewCards.contractor')}:
            </p>
            <p>{contract && contract.label}</p>
          </div>
        </Grid>
        <Grid item>
          <div style={{ display: 'flex' }}>
            <p className={styles.labelTitleResum}>
              {t('newOrder.reviewCards.cnpjContractor')}:
            </p>
            <p>
              {myCnpj?.label?.replace(
                /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                '$1.$2.$3/$4-$5',
              )}
            </p>
          </div>
        </Grid>
        <Grid item>
          <div style={{ display: 'flex' }}>
            <p className={styles.labelTitleResum}>
              {t('newOrder.reviewCards.campaign')}:
            </p>
            <p> {campaign?.label}</p>
          </div>
        </Grid>
        {/* {individuals > 0 && (
          <Grid item>
            <div style={{ display: 'flex' }}>
              <p className={styles.labelTitleResum}>Quantidade de premiados:</p>
              <p> {individuals}</p>
            </div>
          </Grid>
        )} */}

        {/* <Grid item>
          <div style={{ display: 'flex' }}>
            <p className={styles.labelTitleResum}>Valor total de carga:</p>
            <p> R${total}</p>
          </div>
        </Grid> */}
        {/* <Grid item xs={12}>
          <p className={styles.labelTitleResum}>
            Tipo do Produto: Cartão Pré-Pago
          </p>
        </Grid> */}
      </Grid>
      <Grid
        container
        direction="row"
        spacing={5}
        style={{ justifyContent: 'start' }}
      >
        {total > 0 && (
          <Grid item>
            <div style={{ display: 'flex' }}>
              <p className={styles.labelTitleResum}>
                {t('newOrder.reviewCards.valueCharge')}:
              </p>
              <p>
                {new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })
                  .format(total)
                  .replace(',', '.')}
              </p>
            </div>
          </Grid>
        )}

        {/* <Grid item>
          <div style={{ display: 'flex' }}>
            <p className={styles.labelTitleResum}>
              {t('newOrder.reviewCards.valueCard')}
            </p>
            <p>
              {new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })
                .format(newCardValue)
                .replace(',', '.')}
            </p>
          </div>
        </Grid> */}
        {cardVincVlr > 0 && (
          <Grid item>
            <div style={{ display: 'flex' }}>
              <p className={styles.labelTitleResum}>Total cartão:</p>
              <p>
                {new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })
                  .format(cardVincVlr)
                  .replace(',', '.')}
              </p>
            </div>
          </Grid>
        )}
        {specOrder !== 'DCP' && (
          <Grid item>
            <div style={{ display: 'flex' }}>
              <p className={styles.labelTitleResum}>
                {t('newOrder.reviewStoke.qtd')}:
              </p>
              <p>{refQtdCards}</p>
            </div>
          </Grid>
        )}

        {sendValue > 0 && (
          <Grid item>
            <div style={{ display: 'flex' }}>
              <p className={styles.labelTitleResum}>
                {t('newOrder.reviewCards.valueSend')}:
              </p>
              <p>
                {new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })
                  .format(sendValue)
                  .replace(',', '.')}
              </p>
            </div>
          </Grid>
        )}
        {infMin > 0 && (
          <Grid item>
            <div style={{ display: 'flex' }}>
              <p className={styles.labelTitleResum}>Carga mínima:</p>
              <p>
                {new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })
                  .format(infMin)
                  .replace(',', '.')}
              </p>
            </div>
          </Grid>
        )}
        {ticket > 0 && (
          <Grid item>
            <div style={{ display: 'flex' }}>
              <p className={styles.labelTitleResum}>Tarifa boleto:</p>
              <p>
                {new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })
                  .format(ticket)
                  .replace(',', '.')}
              </p>
            </div>
          </Grid>
        )}
        {taxPercent > 0 && (
          <Grid item>
            <div style={{ display: 'flex' }}>
              <p className={styles.labelTitleResum}>Honorários:</p>
              <p>
                {new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })
                  .format(taxPercent)
                  .replace(',', '.')}
              </p>
            </div>
          </Grid>
        )}
        {provision > 0 && (
          <Grid item>
            <div style={{ display: 'flex' }}>
              <p className={styles.labelTitleResum}>Total da NF:</p>
              <p>
                {new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })
                  .format(
                    finish
                      ? provision
                      : Number(provision) +
                          Number(ticket) +
                          Number(sendValue) +
                          Number(infMin) +
                          Number(taxPercent),
                  )
                  .replace(',', '.')}
              </p>
            </div>
          </Grid>
        )}
      </Grid>
      <Grid
        container
        direction="row"
        spacing={5}
        style={{ justifyContent: 'start' }}
      >
        {/* <Grid item>
          <div style={{ display: 'flex' }}>
            <p className={styles.labelTitleResum}>
              {t('newOrder.reviewStoke.valueCharge')}:
            </p>
            <p>
              {new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })
                .format(total)
                .replace(',', '.')}
            </p>
          </div>
        </Grid> */}
        {/* <Grid item>
          <div style={{ display: 'flex' }}>
            <p className={styles.labelTitleResum}>
              {t('newOrder.reviewStoke.valueCard')}:
            </p>
            <p>
              {new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })
                .format(newCardValue)
                .replace(',', '.')}
            </p>
          </div>
        </Grid> */}
        {/* <Grid item>
          <div style={{ display: 'flex' }}>
            <p className={styles.labelTitleResum}>
              {t('newOrder.reviewStoke.valueSend')}:
            </p>
            <p>
              {new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })
                .format(sendValue)
                .replace(',', '.')}
            </p>
          </div>
        </Grid> */}
        {/* <Grid item>
          <div style={{ display: 'flex' }}>
            <p className={styles.labelTitleResum}>
              {t('newOrder.reviewStoke.qtd')}:
            </p>
            <p>{refQtdCards}</p>
          </div>
        </Grid> */}
      </Grid>
      {/* <Grid
        container
        spacing={1}
        direction="row"
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          paddingBottom: 10,
          marginTop: 10,
          marginBottom: 40,
        }}
      >
        <Grid item xs={12}>
          <div style={{ display: 'flex' }}>
            <h6 className={styles.labelTitleResum}>
              Quantidade de Cartões:
              {refQtdCards}
            </h6>
          </div>
        </Grid>
      </Grid> */}
      <Grid
        container
        spacing={1}
        direction="row"
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          paddingBottom: 10,
        }}
      >
        {/* {orders.length > 0 && (
          <Grid item xs={12}>
            <TablePremio
              orders={orders}
              totalPages={1}
              page={1}
              // setPage={setPage}
              itens={orders.length}
              // setItens={setItens}
            />
          </Grid>
        )} */}
      </Grid>
    </div>
  );
}
