/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
import React from 'react';
import Select from 'react-select';
import { Grid, TextField } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import './tableProduct.css';

const options = [
  {
    label: 'Todos',
    value: 1,
  },
];

const statusOptions = [
  {
    label: 'Ativo',
    value: 'Active',
  },
  {
    label: 'Aguardando envio',
    value: 'AwaitingShipping',
  },
  {
    label: 'Aguardando liberação',
    value: 'AwaitingRelease',
  },
  {
    label: 'Cancelado',
    value: 'Canceled',
  },
  {
    label: 'Em estoque',
    value: 'InStock',
  },
];

const orderOptions = [
  {
    label: 'Crescente',
    value: 1,
  },
  {
    label: 'Decrescente',
    value: 2,
  },
];

const useStyles = makeStyles(theme =>
  createStyles({
    myTextField: {
      backgroundColor: 'none',
      boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
      border: 'none',
      color: '#717171',
      fontWeight: 'bold',
      fontFamily: 'Open Sans',
    },
    text: {
      fontFamily: 'Open Sans',
      fontWeight: 'bold',
      color: '#717171',
    },
  }),
);

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    // width: 200,
    backgroundColor: 'none',
    boxShadow: 'none',
    border: 'none',
    // fontWeight: 'bold',
    fontFamily: 'Open Sans',
    // fontSize: 24,
  }),
  indicatorSeparator: styles => ({ display: 'none' }),
  option: (provided, state) => ({
    ...provided,
    fontWeight: 'bold',
    fontFamily: 'Open Sans',
    // color: state.data.color,
    // backgroundColor: state.data.bgcolor,
    // fontSize: 24,
  }),
  placeholder: (provided, state) => ({
    fontWeight: 'bold',
    fontFamily: 'Open Sans',
    marginTop: -20,
    // paddingBottom: 20,
    // fontSize: 24,
    // marginBottom: 30,
  }),
};

// eslint-disable-next-line react/prop-types
function SelectOptions({ search, find, setStatus }) {
  const classes = useStyles();
  const handleAnimation = () => {
    const refresh = document.getElementById('refresh');
    refresh.classList.toggle('animar');
    find();
  };
  return (
    <Grid
      container
      style={{
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 30,
      }}
    >
      <Grid item xs={12} sm={8} md={7} lg={7} xl={7}>
        <TextField
          classes={{ notchedOutline: classes.input }}
          className={classes.text}
          fullWidth
          id="outlined-basic"
          label="Busca"
          variant="outlined"
          size="small"
          inputRef={search}
          InputProps={{
            classes: {
              notchedOutline: classes.myTextField,
              input: classes.text,
            },
          }}
        />
      </Grid>
      <Grid item>
        <Select
          width="300"
          styles={customStyles}
          className="basic-single"
          classNamePrefix="select"
          placeholder="Status"
          onChange={setStatus}
          isSearchable={false}
          options={statusOptions}
        />
      </Grid>
      <Grid item>
        <Select
          styles={customStyles}
          className="basic-single"
          classNamePrefix="select"
          placeholder="Visualização"
          isSearchable={false}
          options={options}
        />
      </Grid>
      <Grid item>
        <Select
          styles={customStyles}
          className="basic-single"
          classNamePrefix="select"
          placeholder="Ordenar"
          // onChange={setOrder}
          isSearchable={false}
          options={orderOptions}
        />
      </Grid>
      <Grid item align="end">
        <img
          id="refresh"
          onClick={handleAnimation}
          src="./img/refresh.png"
          alt="logo"
          className="refresh"
          style={{ marginRight: 15 }}
        />
      </Grid>
    </Grid>
    // <div
    //   className="col-sm-12"
    //   style={{
    //     display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center',
    //   }}
    // >
    //   <input className="busca" placeholder="Busca" ref={search} />
    //   <Select
    //     width="300"
    //     styles={customStyles}
    //     className="basic-single"
    //     classNamePrefix="select"
    //     placeholder="Status"
    //     onChange={setStatus}
    //     isSearchable={false}
    //     options={statusOptions}
    //   />
    //   <Select
    //     styles={customStyles}
    //     className="basic-single"
    //     classNamePrefix="select"
    //     placeholder="Visualização"
    //     isSearchable={false}
    //     options={options}
    //   />
    //   <Select
    //     styles={customStyles}
    //     className="basic-single"
    //     classNamePrefix="select"
    //     placeholder="Ordenar"
    //     isSearchable={false}
    //     options={orderOptions}
    //   />
    //   <img id="refresh" onClick={handleAnimation}
    // src="./img/refresh.png" alt="logo" className="refresh" />
    // </div>
  );
}

export default SelectOptions;
