/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';

import SelectOptions from './SelectOptions';
import BillingTable from './BillingTable';
import api from '../../../../service/axios/api';

export default function Billing({ setLoading, setMsg }) {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [itens, setItens] = useState(10);
  const [selected, setSelected] = useState([]);
  const [bankSelected, setBankSelected] = useState({
    label: 'Todos',
    value: '',
  });

  const [chipData, setChipData] = useState([]);

  const searchData = extra => {
    const cancel = new AbortController();
    setLoading(true);
    setMsg('Buscando extrato...');
    api.invoice
      .viewFatReport(extra, chipData, bankSelected, cancel, itens, page + 1)
      .then(res => {
        setData(res.docs);
        setTotal(res.total);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
      });
  };

  useEffect(() => {
    searchData();
  }, [page, itens, bankSelected]);

  const download = () => {
    const cancel = new AbortController();
    setLoading(true);
    setMsg('Gerando arquivo...');
    api.invoice
      .xlsFatReport(chipData, cancel)
      .then(res => {
        const url = res.retorno.fileLink;
        const link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <>
      <Grid
        item
        xs={12}
        sm={12}
        style={{ display: 'flex', justifyContent: 'start' }}
      >
        <h3>Controle de Faturamento</h3>
      </Grid>
      <SelectOptions
        setStatus={setBankSelected}
        setChipData={setChipData}
        chipData={chipData}
        find={searchData}
      />

      <Grid item xs={12} sm={12}>
        <BillingTable
          docs={data}
          setDocs={setData}
          page={page}
          setPage={setPage}
          itens={itens}
          setItens={setItens}
          total={total}
          setSelected={setSelected}
          selected={selected}
          download={download}
        />
      </Grid>
    </>
  );
}
