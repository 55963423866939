/* eslint-disable import/prefer-default-export */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useRef, useContext } from 'react';
import Select from 'react-select';
import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  RadioGroup,
  Radio,
  FormControl,
  FormControlLabel,
} from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from 'date-fns/locale';
// import MailOutlineIcon from '@material-ui/icons/MailOutline';
// import LockIcon from '@material-ui/icons/Lock';
import Chip from '@material-ui/core/Chip';
// import CardsTable from './SettingsTable';
import api from '../../service/axios/api';
import Loading from '../../components/loading/Loading';
import FlashContext from '../../components/contexts/FlashContext';
import { SettingsTable } from './SettingsTable';

const optionsStatus = [
  {
    label: 'Ativo',
    value: 'Active',
  },
  {
    label: 'Suspenso ',
    value: 'Suspended',
  },
  {
    label: 'Cancelado',
    value: 'Canceled',
  },
  {
    label: 'Todos',
    value: '',
  },
];

const optionsFlag = [
  {
    label: 'Ativo',
    value: 'Active',
  },
  {
    label: 'Suspenso',
    value: 'Suspended',
  },
  {
    label: 'Cancelado',
    value: 'Canceled',
  },
];

const optionsProduct = [
  {
    label: 'Cartão',
    value: 'Card',
  },
  {
    label: 'Transferência',
    value: 'Transfer',
  },
  {
    label: 'Todos',
    value: '',
  },
];

const customStyles = {
  control: provided => ({
    ...provided,
    width: 105,
    height: 40,
    borderRadius: 10,
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  option: provided => ({
    ...provided,
  }),
};
const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      listStyle: 'none',
      padding: 10,
      margin: 0,
    },
    chip: {
      margin: 10,
    },
    textField: {
      // width: '90%',
      // // height: '40px',
      // marginLeft: 'auto',
      // marginRight: 'auto',
      // paddingTop: '2px',
      // paddingBottom: 0,
      // marginTop: 0,
      // fontWeight: 500,
      // backgroundColor: '#fff',
    },
    input: {
      backgroundColor: '#fff',
      marginTop: 0,
    },
    myGrid: {
      minWidth: 260,
      maxHeight: 100,
      backgroundColor: '#f4f4f4',
      margin: theme.spacing(1),
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      textAlign: 'center',
      cursor: 'pointer',
      border: '2px solid #c5c5c5',
      borderRadius: 10,
    },
    myGridSelected: {
      minWidth: 260,
      maxHeight: 100,
      backgroundColor: '#41b490',
      margin: theme.spacing(1),
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      textAlign: 'center',
      cursor: 'pointer',
      border: '2px solid #41b490',
      borderRadius: 10,
    },
    myLabel: {
      padding: theme.spacing(2),
      color: '#2a3176',
      fontWeight: 'bold',
    },
    myLabelSelected: {
      padding: theme.spacing(2),
      color: '#fff',
      fontWeight: 'bold',
    },
  }),
);

const customStylesEdit = {
  control: provided => ({
    ...provided,
    height: 40,
    borderRadius: 10,
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  option: provided => ({
    ...provided,
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
};

export function SettingsControl() {
  const classes = useStyles();
  const [openNew, setOpenNew] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [itens, setItens] = useState(10);
  const [loading, setLoading] = useState(false);
  const [loadingC, setLoadingC] = useState(false);
  const [selected, setSelected] = useState('');
  const [statusSelected, setStatusSelected] = useState({
    label: 'Todos',
    value: '',
  });
  const [flagSelected, setFlagSelected] = useState({
    label: 'Todos',
    value: '',
  });
  const [product, setProduct] = useState({
    label: 'Todos',
    value: '',
  });
  const [options, setOptions] = useState([
    { label: 'Razão Social', value: 'name' },
    { label: 'CNPJ', value: 'cnpj' },
  ]);
  const [searchType, setSearchType] = useState();
  const [msg, setMsg] = useState('Buscando configurações...');
  const [type, setType] = useState({ label: 'Todos', value: '' });
  const [chipData, setChipData] = useState([]);
  const searchRef = useRef(null);

  const { notifyError, notifySuccess } = useContext(FlashContext);

  const [optCompanys, setOptCompanys] = useState([]);
  const [campaing, setCampaing] = useState([]);
  const [cnpj, setCnpj] = useState();
  const [campaign, setCampaign] = useState();
  const [contract, setContract] = useState();
  const [reportInterval, setReportInterval] = useState('');
  const [overdueInvoice, setOverdueInvoice] = useState('');
  const [description, setDescription] = useState('');
  const [typeDays, setTypeDays] = useState('uteis');
  const [status, setStatus] = useState({ label: 'Ativo', value: 'Active' });
  const [pgtoType, setPgtoType] = useState('true');
  const [nfValue, setNfValue] = useState('1');
  const [loadingNew, setLoadingNew] = useState(false);
  const [companyName, setCompanyName] = useState(null);

  const searchData = extra => {
    const cancel = new AbortController();
    setLoading(true);
    setMsg('Buscando configurações...');
    setLoading(false);
    api.joycode
      .getConf(
        product.value,
        page + 1,
        itens,
        extra,
        statusSelected.value,
        chipData,
        cancel,
      )
      .then(res => {
        console.log(res);
        setTotal(res.pages);
        setData(res.docs);
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const handleSearch = () => {
    if (type.value === '') {
      notifyError('Selecione o tipo de busca');
    } else {
      searchData();
    }
  };
  useEffect(() => {
    searchData();
  }, [page, itens, statusSelected, flagSelected, product]);

  useEffect(() => {
    if (selected !== '') {
      // setCnpj(null);
      // setCampaign(null);
      setMsg('Buscando empresas...');
      // setLoad(true);
      const cancel = new AbortController();
      const mproduct = selected === 'card' ? 'Card' : 'Transfer';
      api.contract
        .getByProduct(cancel, mproduct)
        .then(res => {
          const companyList = [];
          const list = [];
          res.forEach(item => {
            // companyList.push(item.companyName);
            if (item.campaign) {
              list.push({
                label: `${item.companyName} - ${item.campaign}`,
                value: item.companyName,
                all: item,
              });
            } else {
              list.push({
                label: item.companyName,
                value: item.companyName,
                all: item,
              });
            }
          });
          setOptCompanys(list);
        })
        .catch(err => {
          console.log(err);
        });
      // .finally(() => {
      //   setLoad(false);
      // });
    }
  }, [selected]);

  const handleDelete = chipToDelete => () => {
    setOptions([
      ...options,
      { label: chipToDelete.by, value: chipToDelete.byType },
    ]);
    setChipData(chips => chips.filter(chip => chip.key !== chipToDelete.key));
  };

  const addChip = btn => {
    if (searchType) {
      const orig = _.cloneDeep(options);
      setChipData([
        ...chipData,
        {
          key: chipData.length + 1,
          label: searchRef.current.value,
          by: searchType.label,
          byType: searchType.value,
        },
      ]);
      const find = orig.findIndex(e => e.value === searchType.value);

      orig.splice(find, 1);
      setSearchType(null);
      // selectRef.current.select.clearValue();
      setOptions(orig);
      if (btn) {
        searchData({
          label: searchRef.current.value,
          byType: searchType.value,
        });
      }
      searchRef.current.value = '';
    } else {
      notifyError('Informe o tipo de busca');
    }
  };

  const internSearch = () => {
    if (searchType?.value !== '' && searchRef?.current?.value !== '') {
      addChip(true);
    } else {
      searchData();
    }
  };

  const teclaEnter = e => {
    if (e.key === 'Enter') {
      internSearch();
    }
  };

  const newConf = e => {
    e.preventDefault();
    setLoadingNew(true);
    api.joycode
      .new(
        contract.value,
        reportInterval,
        nfValue,
        overdueInvoice,
        pgtoType,
        status.value,
        typeDays,
        description,
      )
      .then(res => {
        notifySuccess(res.message);
        setOpenNew(false);
        setCompanyName(null);
        setSelected(null);
        setContract(null);
        setReportInterval('');
        setOverdueInvoice('');
      })
      .catch(err => {
        if (err?.problems?.length > 0) {
          err.problems.forEach(p => {
            notifyError(p.msg);
          });
        } else {
          notifyError(err.message);
        }
      })
      .finally(() => setLoadingNew(false));
  };

  const handleChange = e => {
    const cancel = new AbortController();
    const intern = [];
    setCompanyName(e);
    api.contract
      .getSubCompanies(cancel, e.all.id)
      .then(res => {
        res.retorno.forEach(c => {
          intern.push({
            label: c.LegalEntity.cnpj,
            value: c.id,
            contract: c.contract_id,
            companyName: e.label,
            campaign: e.all.campaign,
          });
        });
      })
      .catch(err => {
        console.log('ERROR', err);
      });
    setCampaign(null);
    setCnpj(null);
    setContract({
      label: e.label,
      value: null,
      cnpj: null,
    });
    setOptions(intern);
  };

  const handleChangeCnpj = item => {
    setCnpj(item);
    console.log(item);
    setContract({
      label: item.companyName,
      value: item.contract,
      cnpj: item.label,
    });
    setCampaign(null);
    setCampaing([
      {
        label: item.campaign ? item.campaign : 'SEM CAMPANHA',
        value: item.campaign,
      },
    ]);
  };

  return (
    <>
      <Loading open={loading} msg={msg} />
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          sm={12}
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <h3>Modo Pré ou Pós Pago</h3>
          <Grid item>
            {/* <Button
              onClick={downloadPlan}
              color="primary"
              variant="contained"
              size="small"
              style={{ height: '40px', marginRight: 15 }}
            >
              Planilha modelo
            </Button> */}
            <Button
              onClick={() => setOpenNew(true)}
              color="primary"
              variant="contained"
              size="small"
              style={{ height: '40px' }}
            >
              Nova Configuração
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={7}>
          <Grid container spacing={0} style={{ justifyContent: 'start' }}>
            <Grid item xs={12} sm={4}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Box flexGrow={1}>
                  <label>Filtrar por produto:</label>
                </Box>
                <Box ml={1} mt={1}>
                  <Select
                    // fullWidth
                    styles={customStyles}
                    classNamePrefix="select"
                    placeholder="Produto"
                    isSearchable={false}
                    options={optionsProduct}
                    value={product}
                    onChange={setProduct}
                    menuPortalTarget={document.body}
                    style={{ zIndex: 999 }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4} style={{ marginLeft: 15 }}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                {/* <Box flexGrow={1}> */}
                <label style={{ marginRight: 10 }}>Filtrar por status:</label>
                {/* </Box> */}
                {/* <Box ml={1} mt={1}> */}
                <Select
                  // fullWidth
                  styles={customStyles}
                  classNamePrefix="select"
                  placeholder="Tipo"
                  isSearchable={false}
                  options={optionsStatus}
                  onChange={setStatusSelected}
                  menuPortalTarget={document.body}
                  // style={{ zIndex: 999, width: 600 }}
                />
                {/* </Box> */}
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={5}>
          <Grid container spacing={1} style={{ justifyContent: 'end' }}>
            <Grid item xs={12} sm={3}>
              <Select
                fullWidth
                styles={customStyles}
                onChange={setSearchType}
                value={searchType}
                classNamePrefix="select"
                placeholder="Tipo"
                isSearchable={false}
                options={options}
                menuPortalTarget={document.body}
                style={{ zIndex: 999 }}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <TextField
                onKeyUpCapture={e => teclaEnter(e)}
                inputRef={searchRef}
                color="secondary"
                // margin="none"
                className={classes.textField}
                fullWidth
                size="small"
                style={{ maxHeight: 10 }}
                // label="Outlined"
                variant="outlined"
                InputProps={{
                  className: classes.input,
                }}
              />
            </Grid>
            <Button
              onClick={() => addChip(false)}
              variant="contained"
              size="small"
              style={{ marginLeft: 5, marginRight: 5 }}
            >
              <AddIcon />
            </Button>
            {/* <Grid item xs={12} sm={2}> */}
            <Button onClick={() => internSearch(true)} variant="contained">
              Buscar
            </Button>
            {/* </Grid> */}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Grid item className={classes.root}>
            {chipData.map(dt => {
              let icon;

              return (
                <li key={data.key}>
                  <Chip
                    icon={icon}
                    label={`${dt.by} | ${dt.label}`}
                    onDelete={handleDelete(dt)}
                    className={classes.chip}
                  />
                </li>
              );
            })}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12}>
          <SettingsTable
            docs={data}
            setDocs={setData}
            page={page}
            setPage={setPage}
            itens={itens}
            setItens={setItens}
            total={total}
            search={searchData}
          />
        </Grid>
      </Grid>
      {/* MODAL DE NOVA CONFIGURAÇÃO */}
      <Dialog
        onClose={() => setOpenNew(!openNew)}
        open={openNew}
        fullWidth
        maxWidth="sm"
      >
        <Loading open={loadingNew} msg="Salvando nova configuração..." />
        <DialogTitle id="simple-dialog-title">Nova configuração</DialogTitle>
        <form onSubmit={newConf}>
          {/* <h6>Escolha o tipo de produto</h6> */}
          <Grid
            container
            spacing={1}
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              paddingLeft: 20,
            }}
          >
            <Grid item xs={12} sm={12}>
              <h6>Escolha o tipo de produto</h6>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid
                className={
                  selected === 'card' ? classes.myGridSelected : classes.myGrid
                }
                item
                xs={12}
                sm={8}
                md={3}
                lg={3}
                xl={3}
                onClick={() => {
                  setSelected('card');
                  setCampaign(null);
                  setCnpj(null);
                  setCompanyName(null);
                }}
              >
                <p
                  className={
                    selected === 'card'
                      ? classes.myLabelSelected
                      : classes.myLabel
                  }
                >
                  Cartão
                </p>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid
                className={
                  selected === 'transfer'
                    ? classes.myGridSelected
                    : classes.myGrid
                }
                item
                xs={12}
                sm={8}
                md={3}
                lg={3}
                xl={3}
                onClick={() => {
                  setSelected('transfer');
                  setCampaign(null);
                  setCnpj(null);
                  setCompanyName(null);
                }}
              >
                <p
                  className={
                    selected === 'transfer'
                      ? classes.myLabelSelected
                      : classes.myLabel
                  }
                >
                  Transferência
                </p>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={1}
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              padding: 20,
            }}
          >
            <Grid item xs={12} sm={12}>
              <Grid
                container
                spacing={1}
                style={{
                  paddingRight: 20,
                  paddingLeft: 20,
                }}
              >
                <Grid item xs={12} sm={12}>
                  <h6>Nome da empresa</h6>
                  <Select
                    fullWidth
                    styles={customStylesEdit}
                    classNamePrefix="select"
                    placeholder="Tipo"
                    value={companyName}
                    onChange={handleChange}
                    // isSearchable={false}
                    options={optCompanys}
                    menuPortalTarget={document.body}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Grid
                container
                spacing={1}
                style={{
                  paddingRight: 20,
                  paddingLeft: 20,
                }}
              >
                <Grid item xs={12} sm={12}>
                  <h6>CNPJ da empresa</h6>
                  <Select
                    fullWidth
                    styles={customStylesEdit}
                    classNamePrefix="select"
                    placeholder="Tipo"
                    value={cnpj}
                    onChange={handleChangeCnpj}
                    isSearchable={false}
                    options={options}
                    menuPortalTarget={document.body}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Grid
                container
                spacing={1}
                style={{
                  paddingRight: 20,
                  paddingLeft: 20,
                }}
              >
                <Grid item xs={12} sm={12}>
                  <h6>Nome da campanha</h6>
                  <Select
                    fullWidth
                    styles={customStylesEdit}
                    classNamePrefix="select"
                    placeholder="Tipo"
                    value={campaign}
                    onChange={setCampaign}
                    isSearchable={false}
                    options={campaing}
                    menuPortalTarget={document.body}
                  />
                </Grid>
              </Grid>
            </Grid>
            {/* 
            <Grid item xs={12} sm={6}>
                  <h6>Status</h6>
                  <Select
                    fullWidth
                    styles={customStylesEdit}
                    classNamePrefix="select"
                    placeholder="Tipo"
                    value={typeSelected}
                    onChange={setTypeSelected}
                    isSearchable={false}
                    options={optionsStatus}
                    menuPortalTarget={document.body}
                  />
                </Grid>
                 */}
            <Grid item xs={12} sm={12}>
              <Grid
                container
                spacing={1}
                style={{
                  paddingRight: 20,
                  paddingLeft: 20,
                }}
              >
                <Grid item xs={12} sm={6}>
                  <h6>Prazo do Relatório</h6>
                  <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    // type="number"
                    value={reportInterval}
                    onChange={e => setReportInterval(e.target.value)}
                    // defaultValue={dataEdit && dataEdit.cardId}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <h6>Dias</h6>
                  <FormControl component="fieldset" style={{ marginLeft: 10 }}>
                    <RadioGroup
                      row
                      aria-label="gender"
                      name="row-radio-buttons-group"
                      value={typeDays}
                      onChange={e => setTypeDays(e.target.value)}
                    >
                      <FormControlLabel
                        // className="myLabel"
                        value="corridos"
                        control={
                          <Radio
                            classes={{
                              root: classes.radio,
                              checked: classes.checked,
                            }}
                          />
                        }
                        label="CORRIDOS"
                      />
                      <FormControlLabel
                        // className="myLabel"
                        value="uteis"
                        control={
                          <Radio
                            classes={{
                              root: classes.radio,
                              checked: classes.checked,
                            }}
                          />
                        }
                        label="ÚTEIS"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Grid
                container
                spacing={1}
                style={{
                  paddingRight: 20,
                  paddingLeft: 20,
                }}
              >
                <Grid item xs={12} sm={6}>
                  <h6>Prazo de Faturamento</h6>
                  <TextField
                    // inputRef={cpf}
                    type="number"
                    size="small"
                    variant="outlined"
                    fullWidth
                    value={overdueInvoice}
                    onChange={e => setOverdueInvoice(e.target.value)}
                    // defaultValue={dataEdit && dataEdit.cpf}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <h6>Status</h6>
                  <Select
                    fullWidth
                    styles={customStylesEdit}
                    value={status}
                    onChange={setStatus}
                    classNamePrefix="select"
                    placeholder="Tipo"
                    isSearchable={false}
                    options={optionsFlag}
                    menuPortalTarget={document.body}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{
                    marginTop: 10,
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <h6>Descrição da Nota</h6>
                  <TextField
                    multiline
                    rows={3}
                    size="small"
                    variant="outlined"
                    fullWidth
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{
                    marginTop: 10,
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <h6>Valor da Nota</h6>
                  <FormControl component="fieldset" style={{ marginTop: -15 }}>
                    <RadioGroup
                      row
                      aria-label="gender"
                      name="row-radio-buttons-group"
                      value={nfValue}
                      onChange={e => setNfValue(e.target.value)}
                    >
                      <FormControlLabel
                        // className="myLabel"
                        value="1"
                        control={
                          <Radio
                            classes={{
                              root: classes.radio,
                              checked: classes.checked,
                            }}
                          />
                        }
                        label="COMPLETO"
                      />
                      <FormControlLabel
                        // className="myLabel"
                        value="2"
                        control={
                          <Radio
                            classes={{
                              root: classes.radio,
                              checked: classes.checked,
                            }}
                          />
                        }
                        label="SERVIÇO"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <h6>Forma de Pagamento</h6>
                  <FormControl component="fieldset" style={{ marginTop: -15 }}>
                    <RadioGroup
                      row
                      aria-label="gender"
                      name="row-radio-buttons-group"
                      value={pgtoType}
                      onChange={e => setPgtoType(e.target.value)}
                    >
                      <FormControlLabel
                        // className="myLabel"
                        value="false"
                        control={
                          <Radio
                            classes={{
                              root: classes.radio,
                              checked: classes.checked,
                            }}
                          />
                        }
                        label="BOLETO"
                      />
                      <FormControlLabel
                        // className="myLabel"
                        value="true"
                        control={
                          <Radio
                            classes={{
                              root: classes.radio,
                              checked: classes.checked,
                            }}
                          />
                        }
                        label="TRANSFERÊNCIA"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* </form> */}
          <DialogActions>
            <Button onClick={() => setOpenNew(false)} color="secondary">
              Cancelar
            </Button>
            <Button
              // onClick={() => setOpen(false)}
              type="submit"
              color="primary"
              variant="contained"
              autoFocus
            >
              Salvar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
