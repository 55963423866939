import { initializeApp } from 'firebase/app';
// import * as firebase from 'firebase/app';

// const firebaseConfig = {
//   apiKey: 'AIzaSyDmCna157aYEFJ3G8Dpml58tf2-rk-5JAM',
//   authDomain: 'testelement-ccb5d.firebaseapp.com',
//   projectId: 'testelement-ccb5d',
//   storageBucket: 'testelement-ccb5d.appspot.com',
//   messagingSenderId: '869892877578',
//   appId: '1:869892877578:web:bdd8e83efbe04fd312def3',
// };

// NOVO ELEMENT
const firebaseConfig = {
  apiKey: 'AIzaSyDqgPy2fXSJ0jav-zEC_gLe6U5lMb_wxrM',
  authDomain: 'controlv2-6ad99.firebaseapp.com',
  projectId: 'controlv2-6ad99',
  storageBucket: 'controlv2-6ad99.appspot.com',
  messagingSenderId: '68374045443',
  appId: '1:68374045443:web:d47b5089c3455b0ea4ff36',
};

// willian
// const firebaseConfig = {
//   apiKey: 'AIzaSyCEsnnwGwILfUiQ5-dQhmVrY2swL2HNPw8',
//   authDomain: 'duplofactor.firebaseapp.com',
//   projectId: 'duplofactor',
//   storageBucket: 'duplofactor.appspot.com',
//   messagingSenderId: '751766346323',
//   appId: '1:751766346323:web:830fc66615cd9d71d244a6',
// };

const app = initializeApp(firebaseConfig);
export default app;
