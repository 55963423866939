/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Checkbox,
  TextField,
} from '@material-ui/core';

import Loading from '../../components/loading/Loading';

function createData(
  id,
  groupName,
  cnpj,
  companyName,
  campaign,
  product,
  status,
) {
  return {
    id,
    groupName,
    cnpj,
    companyName,
    campaign,
    product,
    status,
  };
}

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  table: {
    minWidth: 1100,
  },
  myTextField: {
    backgroundColor: 'none',
    boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
    border: 'none',
    color: '#717171',
    fontWeight: 'bold',
    fontFamily: 'Open Sans',
  },
  text: {
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
    color: '#717171',
  },
});

const isNumeric = function (value) {
  const tmp = value.replace(/[/().\s-]+/g, '');
  return /^\d+(?:\.\d+)?$/.test(tmp);
};

const StyledTableCell = withStyles(() => ({
  head: {
    // backgroundColor: theme.palette.common.black,
    // color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function NewContractsTable({
  docs,
  selected,
  filteredData,
  setFilteredData,
}) {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  // const [filteredData, setFilteredData] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const search = useRef(null);

  useEffect(() => {
    if (filteredData.length > 0) {
      const list = [];
      filteredData.forEach(d => {
        list.push(
          createData(
            d.id,
            d.group_name,
            d.cnpj,
            d.company_name,
            d.campaign,
            d.product_type === 'Card' ? 'Cartão' : 'Trânferencia',
            d.is_active,
          ),
        );
      });
      setRows(list);
      // setFilteredData(list);
    }
  }, [filteredData]);

  const handleSearch = () => {
    if (isNumeric(search.current.value)) {
      const tmp = docs.filter(
        row =>
          row.cnpj.indexOf(search.current.value.replace(/[/().\s-]+/g, '')) >
          -1,
      );
      setFilteredData(tmp);
    } else {
      const tmp = docs.filter(
        row =>
          row.company_name
            .toUpperCase()
            .indexOf(search.current.value.toUpperCase()) > -1,
      );
      setFilteredData(tmp);
    }
  };

  const handleSelect = (id, e) => {
    if (e.target.checked) {
      selected.push(id);
    } else {
      selected.splice(selected.indexOf(id), 1);
    }
  };

  const handleDelete = () => {
    setLoading(true);
    setLoading(false);
  };

  return (
    <>
      <TextField
        classes={{ notchedOutline: classes.input }}
        className={classes.text}
        inputRef={search}
        onChange={handleSearch}
        id="outlined-basic"
        label="Busca"
        variant="outlined"
        size="small"
        style={{ marginBottom: 15 }}
        InputProps={{
          classes: {
            notchedOutline: classes.myTextField,
            input: classes.text,
          },
        }}
      />
      <Paper className={classes.root}>
        <TableContainer component={Paper} style={{ maxHeight: 400 }}>
          <Table
            className={classes.table}
            aria-label="customized table"
            style={{ maxHeight: 400 }}
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>Grupo</StyledTableCell>
                <StyledTableCell>CNPJ</StyledTableCell>
                <StyledTableCell>Razão Social</StyledTableCell>
                <StyledTableCell>Campanha</StyledTableCell>
                <StyledTableCell>Produto</StyledTableCell>
                <StyledTableCell align="right">Status</StyledTableCell>
                <StyledTableCell align="right" />
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, i) => (
                <StyledTableRow key={i}>
                  <StyledTableCell>{row.groupName}</StyledTableCell>
                  <StyledTableCell>{row.cnpj}</StyledTableCell>
                  <StyledTableCell>{row.companyName}</StyledTableCell>
                  <StyledTableCell>{row.campaign}</StyledTableCell>
                  <StyledTableCell>{row.product}</StyledTableCell>
                  <StyledTableCell align="right">
                    {row.status ? 'Ativo' : 'Desativado'}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <Checkbox
                      style={{ margin: 0, padding: 0 }}
                      onChange={e => handleSelect(row.id, e)}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Dialog
        onClose={() => setOpen(!open)}
        open={open}
        fullWidth
        maxWidth="sm"
      >
        <Loading open={loading} msg="Desvinculando usuário..." />
        <DialogTitle id="simple-dialog-title">Desvincular contrato</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deseja realmente desvincular o usuário deste contrado?
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <h6
            style={{
              cursor: 'pointer',
              color: '#657588',
              fontWeight: 'bold',
              fontFamily: 'Open Sans',
              marginTop: 5,
            }}
            onClick={() => setOpen(false)}
          >
            Cancelar
          </h6>
          <Button color="primary" variant="contained" onClick={handleDelete}>
            Desvincular
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
