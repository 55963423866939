/* eslint-disable no-unused-vars */
import * as React from 'react';
// import Grid from '@mui/material/Grid';
// import Typography from '@mui/material/Typography';
// import TextField from '@mui/material/TextField';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
import Select from 'react-select';
// import FormGroup from '@mui/material/FormGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';

// import Radio from '@mui/material/Radio';
// import RadioGroup from '@mui/material/RadioGroup';
// import FormControl from '@mui/material/FormControl';

import {
  TextField,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
  styled,
} from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark'
        ? 'rgba(57,75,89,.5)'
        : 'rgba(206,217,224,.5)',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#41b490',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#41b490',
  },
});
const useStyles = makeStyles(theme =>
  createStyles({
    myTextField: {
      backgroundColor: 'none',
      boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
      border: 'none',
      color: '#717171',
      fontWeight: 'bold',
      fontFamily: 'Open Sans',
    },
    text: {
      fontFamily: 'Open Sans',
      fontWeight: 'bold',
      color: '#717171',
    },
    radio: {
      color: '#9c9c9c',
      '&$checked': {
        color: '#41b490',
      },
    },
    checked: {},
  }),
);

// eslint-disable-next-line react/prop-types
export default function TypeTransfer({ setProductName, productName }) {
  const classes = useStyles();
  return (
    <div className="box-new-card">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <p className="text-box-inform">Tipo do Produto: Transferência</p>
        <p className="text-box-inform-2">COD.: PROD_TRANSF_002</p>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 50,
        }}
      >
        <div>
          <p className="text-select-label ">Nome do produto</p>
          <TextField
            classes={{ notchedOutline: classes.input }}
            className={classes.text}
            fullWidth
            id="outlined-basic"
            variant="outlined"
            size="small"
            value={productName}
            onChange={e => setProductName(e.target.value)}
            // inputRef={search}
            InputProps={{
              classes: {
                notchedOutline: classes.myTextField,
                input: classes.text,
              },
            }}
          />
          {/* <input className="myInput" placeholder="" /> */}
        </div>
        <div>
          <p className="text-select-label ">Tecnologia</p>
          <FormControl component="fieldset">
            <RadioGroup row aria-label="gender" name="row-radio-buttons-group">
              <FormControlLabel
                value="chip"
                control={
                  <Radio
                    classes={{
                      root: classes.radio,
                      checked: classes.checked,
                    }}
                  />
                }
                label="Via TED"
              />
              <FormControlLabel
                value="nfc"
                control={
                  <Radio
                    classes={{
                      root: classes.radio,
                      checked: classes.checked,
                    }}
                  />
                }
                label="Via PIX"
              />
            </RadioGroup>
          </FormControl>
        </div>
        <div>
          <p className="text-select-label ">Cobertura</p>
          <FormControl component="fieldset">
            {/* <FormLabel component="legend">Gender</FormLabel> */}
            <RadioGroup
              aria-label="gender"
              // defaultValue="female"
              name="radio-buttons-group"
            >
              <FormControlLabel
                value="female"
                control={
                  <Radio
                    classes={{
                      root: classes.radio,
                      checked: classes.checked,
                    }}
                  />
                }
                label="Internacional"
              />
              <FormControlLabel
                value="male"
                control={
                  <Radio
                    classes={{
                      root: classes.radio,
                      checked: classes.checked,
                    }}
                  />
                }
                label="Nacional"
              />
            </RadioGroup>
          </FormControl>
        </div>
      </div>
      {/* <div style={{
        display: 'flex', flexDirection: 'row',
        justifyContent: 'space-between', alignItems: 'center', marginBottom: 40,
      }}
      >
        <div>
          <p className="text-select-label ">Emissor</p>
          <Select
            styles={customStyles}
            className="basic-single"
            classNamePrefix="select"
            placeholder="Selecione o emissor"
            isSearchable={false}
            options={optionsE}
          />
        </div>
        <div style={{ width: 270 }}>
          <p className="text-select-label ">Função transacional</p>
          <FormGroup className="myFormGroup">
            <FormControlLabel
              className="myLabel"
              control={(
                <Checkbox
                  sx={{
                    '&:hover': { bgcolor: 'transparent' },
                  }}
                  disableRipple
                  color="default"
                  checkedIcon={<BpCheckedIcon />}
                  icon={<BpIcon />}
                  inputProps={{ 'aria-label': 'Checkbox demo' }}

                />
)}
              label="Vale Presente"
            />
            <FormControlLabel
              className="myLabel"
              control={(
                <Checkbox
                  sx={{
                    '&:hover': { bgcolor: 'transparent' },
                  }}
                  disableRipple
                  color="default"
                  checkedIcon={<BpCheckedIcon />}
                  icon={<BpIcon />}
                  inputProps={{ 'aria-label': 'Checkbox demo' }}
                  // {...props}
                />
)}
              label="Saque e Compras"
            />
          </FormGroup>
        </div>
        <div>
          <p className="text-select-label ">Cobertura</p>
          <FormControl component="fieldset">
            <RadioGroup row aria-label="gender" name="row-radio-buttons-group">
              <FormControlLabel
                className="myLabel"
                value="nacional"
                control={(
                  <Radio sx={{
                    color: '#9c9c9c',
                    '&.Mui-checked': {
                      color: '#41b490',
                    },
                  }}
                  />
)}
                label="Nacional"
              />
              <FormControlLabel
                className="myLabel"
                value="internacional"
                control={(
                  <Radio sx={{
                    color: '#9c9c9c',
                    '&.Mui-checked': {
                      color: '#41b490',
                    },
                  }}
                  />
)}
                label="Internacional"
              />
            </RadioGroup>
          </FormControl>
        </div>
      </div> */}
    </div>
  );
}
