/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/react-in-jsx-scope */
import NumberFormat from 'react-number-format';

export function NumberFormatCustom(props) {
  const { inputRef, onChange, value, ...other } = props;
  const first = String(value).substr(0, 2) === 'R$';
  if (!first)
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={values => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        decimalSeparator=","
        thousandSeparator="."
        decimalScale={2}
        fixedDecimalScale
        allowEmptyFormatting
        allowNegative={false}
        isNumericString
        prefix="R$ "
      />
    );
  return (
    <NumberFormat
      {...props}
      decimalSeparator=","
      thousandSeparator="."
      isNumericString
    />
  );
}

export function NumberFormatRate(props) {
  const { inputRef, onChange, ...other } = props;
  const MAX_VAL = 100;
  const withValueCap = inputObj => {
    const { value } = inputObj;
    if (value <= MAX_VAL) return true;
    return false;
  };
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      decimalSeparator=","
      decimalScale={2}
      fixedDecimalScale
      isNumericString
      allowEmptyFormatting
      allowNegative={false}
      isAllowed={withValueCap}
    />
  );
}
