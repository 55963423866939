/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/require-default-props */
import React, { useState, useEffect } from 'react';
// import Grid from '@mui/material/Grid';

import clsx from 'clsx';
// import Box from '@mui/material/Box';
// import CssBaseline from '@mui/material/CssBaseline';
// import Drawer from '@mui/material/Drawer';

import { useLocation, useHistory, useParams } from 'react-router-dom';

// import Toolbar from '@mui/material/Toolbar';
// import List from '@mui/material/List';
// import Typography from '@mui/material/Typography';
// import Divider from '@mui/material/Divider';
// import IconButton from '@mui/material/IconButton';
// import MenuIcon from '@mui/icons-material/Menu';

import MenuIcon from '@material-ui/icons/Menu';
import {
  Box,
  CssBaseline,
  Drawer,
  Toolbar,
  List,
  Typography,
  IconButton,
  Divider,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@material-ui/core';

// import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import ListItem from '@mui/material/ListItem';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import ListItemText from '@mui/material/ListItemText';
// import InboxIcon from '@mui/icons-material/MoveToInbox';
import InboxIcon from '@material-ui/icons/Inbox';
// import MailIcon from '@mui/icons-material/Mail';
import MailIcon from '@material-ui/icons/Mail';
import { makeStyles, styled, useTheme } from '@material-ui/core/styles';
import styles from './SideBar.module.css';
import routing from '../../service/routes/routing';

import PermissionComponent from '../../components/permissionComponent/PermissionComponent';
import '../DashboardLayout.css';
import TopBarElement from './AppBar';
import UserRoles from '../../service/acl/UserRoles';

const drawerWidth = 245;

const Main = styled('main', { shouldForwardProp: prop => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 125,
    }),
  }),
);

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const useStyles = makeStyles(() => ({
  labels: {
    marginTop: 5,
  },
  labelplus: {
    marginTop: 5,
    marginLeft: 10,
    marginBottom: 10,
  },
}));

// eslint-disable-next-line react/prop-types
export default function DashboardLayout({ title = '', children }) {
  const history = useHistory();
  const [open, setOpen] = useState(true);
  const theme = useTheme();
  const location = useLocation();
  const classes = useStyles();
  const id = useParams();

  useEffect(() => {
    if (
      location.pathname !== '/administration' &&
      location.pathname !== '/ordersAdmin' &&
      location.pathname !== '/cardsAdmin' &&
      location.pathname !== '/customized' &&
      location.pathname !== '/rps' &&
      location.pathname !== '/product' &&
      location.pathname !== '/newProduct' &&
      location.pathname !== '/typeProduct/prepaidCard' &&
      location.pathname !== '/typeProduct/creditCard' &&
      location.pathname !== '/creditCard' &&
      location.pathname !== '/typeProduct/transfer' &&
      location.pathname !== '/transfer' &&
      location.pathname !== '/filesCnab' &&
      location.pathname !== '/filesAcesso' &&
      location.pathname !== '/filaAcesso' &&
      location.pathname !== '/extract' &&
      location.pathname !== '/contracts' &&
      location.pathname !== '/permissions' &&
      location.pathname !== '/banks' &&
      location.pathname !== '/users' &&
      location.pathname !== '/agentContracts' &&
      location.pathname !== '/agentPayment' &&
      location.pathname !== `/userDetails/${id.id}` &&
      location.pathname !== '/allCardsAdmin' &&
      location.pathname !== '/adminDcp' &&
      location.pathname !== '/settings' &&
      location.pathname !== '/banklysends' &&
      location.pathname !== '/companybase'
    )
      setOpen(false);
  }, [location]);

  const handleDrawerOpen = () => {
    if (
      location.pathname === '/administration' ||
      location.pathname === '/ordersAdmin' ||
      location.pathname === '/cardsAdmin' ||
      location.pathname === '/rps' ||
      location.pathname === '/product' ||
      location.pathname === '/newProduct' ||
      location.pathname === '/typeProduct/prepaidCard' ||
      location.pathname === '/creditCard' ||
      location.pathname === '/typeProduct/transfer' ||
      location.pathname === '/transfer' ||
      location.pathname === '/filesAcesso' ||
      location.pathname === '/filesCnab' ||
      location.pathname === '/filaAcesso' ||
      location.pathname === '/extract' ||
      location.pathname === '/contracts' ||
      location.pathname === '/agentContracts' ||
      location.pathname === '/agentPayment' ||
      location.pathname === '/banks' ||
      location.pathname === '/permissions' ||
      location.pathname === '/users' ||
      location.pathname === '/customized' ||
      location.pathname === `/userDetails/${id.id}` ||
      location.pathname === '/allCardsAdmin' ||
      location.pathname === '/adminDcp' ||
      location.pathname === '/settings' ||
      location.pathname !== '/banklysends' ||
      location.pathname === '/companybase'
    ) {
      setOpen(true);
    }
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box
      sx={{ display: 'flex' }}
      style={{
        marginTop: 30,
        marginLeft: 30,
        position: 'relative',
        minHeight: '100vh',
      }}
    >
      <CssBaseline />
      <TopBarElement
        title={title}
        sideMenuOpen={open}
        onToggleSideMenu={handleDrawerOpen}
      />
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <div className={styles.drawerContainer}>
          <a href={routing.administration} className={styles.link}>
            <img src="./img/resultsIcon.svg" height={30} width={30} alt="" />
            <span>Dashboard</span>
          </a>
          <a href={routing.ordersAdmin} className={styles.link}>
            <img src="./img/orders.svg" height={30} width={30} alt="" />
            <span>Pedidos</span>
          </a>
          <a href={routing.cardsAdmin} className={styles.link}>
            <img src="./img/cards.svg" height={30} width={30} alt="" />
            <span>Cartões</span>
          </a>
          <a href={routing.allCardsAdmin} className={styles.link}>
            <img src="./img/cards.svg" height={30} width={30} alt="" />
            <span>
              Cartões
              <br />
              TODA BASE
            </span>
          </a>
          <a href={routing.customized} className={styles.link}>
            <img src="./img/cards.svg" height={30} width={30} alt="" />
            <span>
              Cartões
              <br />
              Personalizados
            </span>
          </a>
          <a href={routing.rps} className={styles.link}>
            <img src="./img/contract.svg" height={30} width={30} alt="" />
            <span>RPSs</span>
          </a>
          <a href={routing.bankly} className={styles.link}>
            <img src="./img/contract.svg" height={30} width={30} alt="" />
            <span>Bankly</span>
          </a>
          {/* <a href={routing.moviment} className={styles.link}>
            <img src="./img/movimentIcon.svg" height={30} width={30} alt="" />
            <span>Movimentação</span>
          </a> */}
          {/* <a href={routing.product} className={styles.link}>
            <img src="./img/product.png" height={30} width={30} alt="" />
            <span>Produtos</span>
          </a> */}
          <a href={routing.filesAcesso} className={styles.link}>
            <img src="./img/boleto.svg" height={30} width={30} alt="" />
            <span>
              Arquivos
              <br />
              Acesso
            </span>
          </a>
          <a href={routing.filesCnab} className={styles.link}>
            <img src="./img/boleto.svg" height={30} width={30} alt="" />
            <span>
              Arquivos
              <br />
              CNAB
            </span>
          </a>
          <a href={routing.contracts} className={styles.link}>
            <img src="./img/contract.png" height={30} width={30} alt="" />
            <span>Contratos</span>
          </a>
          <a href={routing.agentContract} className={styles.link}>
            <img src="./img/contract.png" height={30} width={30} alt="" />
            <span>
              Contratos
              <br />
              Agentes
            </span>
          </a>
          <a href={routing.process} className={styles.link}>
            <img src="./img/process.svg" height={30} width={30} alt="" />
            <span>
              Fila
              <br />
              Processamento
            </span>
          </a>
          <PermissionComponent role={UserRoles.ADM_FINANCIAL}>
            <a href={routing.extract} className={styles.link}>
              <img src="./img/movimentIcon.svg" height={30} width={30} alt="" />
              <span>Financeiro</span>
            </a>
            <a href={routing.banks} className={styles.link}>
              <img src="./img/bank.png" height={30} width={30} alt="" />
              <span>Bancos</span>
            </a>
            <a href={routing.agentPayment} className={styles.link}>
              <img src="./img/movimentIcon.svg" height={30} width={30} alt="" />
              <span>
                Pagamento
                <br />
                Agentes
              </span>
            </a>
            <a href={routing.admindcps} className={styles.link}>
              <img src="/img/movimentIcon.svg" height={30} width={30} alt="" />
              <span>
                Pagamentos
                <br />
                DCP
              </span>
            </a>
          </PermissionComponent>
          <PermissionComponent role={UserRoles.ADM_GROUP_PERMISSION}>
            <a href={routing.settings} className={styles.link}>
              <img src="/img/settings.svg" height={30} width={30} alt="" />
              <span>
                Modo <br /> Pré/Pós
              </span>
            </a>
            <a href={routing.companybase} className={styles.link}>
              <img src="/img/settings.svg" height={30} width={30} alt="" />
              <span>
                Configurações <br /> Control
              </span>
            </a>
            <a href={routing.permissions} className={styles.link}>
              <img src="/img/permission.png" height={30} width={30} alt="" />
              <span>Permissões</span>
            </a>
          </PermissionComponent>
          {/* <a href={routing.releaseMoney} className={styles.link}>
            <img src="./img/movimentIcon.svg" height={30} width={30} alt="" />
            <span>Liberação</span>
            <span>de cargas</span>
          </a> */}
          {/* <a href={routing.baseCards} className={styles.link}>
            <img src="./img/cards.svg" height={30} width={30} alt="" />
            <span>Base Cartões</span>
          </a> */}
        </div>
        {/* <List>
          <ListItem button onClick={() => history.push(routing.dashboard)}>
            <ListItemIcon>
              <div className={classes.labels}>
                <img src="./img/dashboard.svg" alt="logo" />
              </div>
            </ListItemIcon>
            <ListItemText className="menufont" primary="Dashboard" />
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <div className={classes.labels}>
                <img src="./img/pedidos.svg" alt="logo" />
              </div>
            </ListItemIcon>
            <ListItemText className="menufont" primary="Pedidos" />
          </ListItem>
        </List> */}
      </Drawer>
      <Main open={open} style={{ marginTop: 30, paddingBottom: '2.5rem' }}>
        {children}
        <footer
          style={{
            textAlign: 'center',
            position: 'absolute',
            bottom: 0,
            height: '2.5rem',
            width: '100%',
            // marginTop: '100vh -20px',
            // left: 0,
          }}
        >
          <h6>Todos direitos reservados para Promotech</h6>
        </footer>
      </Main>
    </Box>
  );
}

// DashboardLayout.propTypes = propTypes;
