import React from 'react';
import MuiFormHelperText from '@material-ui/core/FormHelperText';

import { useStylesHelperText } from './styles';

function HelperText({ ...others }) {
  const classes = useStylesHelperText();
  return <MuiFormHelperText classes={classes} {...others} />;
}

export default HelperText;
