/* eslint-disable radix */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useContext, useRef, useEffect } from 'react';
import { Grid, TextField, Switch, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import readXlsxFile from 'read-excel-file';
// import _ from 'lodash';

import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import locale from 'date-fns/locale/pt-BR';
import DateFnsUtils from '@date-io/date-fns';
import FlashContext from '../../../components/contexts/FlashContext';
import TableVinc from '../../../components/tables/premiados/TableVinc';
import MyButton from '../../../components/button/MyButton';
import styles from '../../../styles/Layout.module.css';

const useStyles = makeStyles(() => ({
  myTextField: {
    backgroundColor: 'none',
    boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
    border: 'none',
    color: '#717171',
    fontWeight: 'bold',
    fontFamily: 'Open Sans',
  },
  text: {
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
    color: '#717171',
  },
  mySwitch: {
    '&.MuiSwitch-colorPrimary.MuiSwitch-checked': {
      color: 'green',
    },
  },
  checked: {},
  switchBase: {
    color: '#fff',
    '&$checked': {
      color: '#41b490',
    },
    '&$checked + $track': {
      backgroundColor: '#41b490',
    },
  },
  track: {},
}));

// eslint-disable-next-line prettier/prettier
export default function CardVinc({
  nf,
  setNf,
  boleto,
  setBoleto,
  paymentDate,
  setPaymentDate,
  isNew,
  orderName,
  setOrderName,
  nfComments,
  setNfComments,
  cardVinc,
  setCardVinc,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [cpf, setCpf] = useState('');
  const [name, setName] = useState('');
  const { notifyError } = useContext(FlashContext);
  const fileXls = useRef(null);
  const [myFile, setMyfile] = useState();

  const handleChangeBoleto = e => {
    if (e) {
      setBoleto(e);
      setNf(e);
    } else {
      setBoleto(e);
    }
  };

  const handleChangeNf = e => {
    if (!e) {
      setBoleto(e);
      setNf(e);
    } else {
      setNf(e);
    }
  };

  function validarCPF(inputCPF) {
    let soma = 0;
    let resto;
    // var inputCPF = document.getElementById('cpf').value;

    if (inputCPF === '00000000000') return false;
    // eslint-disable-next-line no-plusplus
    for (let i = 1; i <= 9; i++)
      // eslint-disable-next-line radix
      soma += parseInt(inputCPF.substring(i - 1, i)) * (11 - i);
    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) resto = 0;
    // eslint-disable-next-line radix
    if (resto !== parseInt(inputCPF.substring(9, 10))) return false;

    soma = 0;
    // eslint-disable-next-line no-plusplus
    for (let i = 1; i <= 10; i++)
      soma += parseInt(inputCPF.substring(i - 1, i)) * (12 - i);
    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(inputCPF.substring(10, 11))) return false;
    return true;
  }

  const addPerson = () => {
    if (name !== '' && cpf !== '') {
      if (validarCPF(cpf)) {
        if (cardVinc.length === 0) {
          setCardVinc([{ name, cpf }]);
        } else {
          setCardVinc([...cardVinc, { name, cpf }]);
        }
        setName('');
        setCpf('');
      } else {
        notifyError(t('newOrder.cardVinc.cpfError'));
      }
    }
  };

  useEffect(() => {
    if (myFile) {
      const dataTable = [];
      readXlsxFile(myFile).then(r => {
        // const keys = r[0];
        r.forEach((data, i) => {
          if (i > 0) {
            if (validarCPF(String(data[1]))) {
              dataTable.push({
                name: data[0],
                cpf: String(data[1]),
              });
            } else {
              notifyError(`${data[0]} não foi inserido. CPF inválido.`);
            }
          }
        });
        if (cardVinc.length === 0) {
          setCardVinc(dataTable);
        } else {
          setCardVinc([...cardVinc, ...dataTable]);
        }
      });
    }
  }, [myFile]);

  const downloadModel = () => {
    const url =
      'https://epp-storage.s3.amazonaws.com/templates/Vinculo_template.xlsx';
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Vinculo_template'); // or any other extension
    document.body.appendChild(link);
    link.click();
  };
  return (
    <div className="box-new-card">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h6 className={styles.textBoxInform}>{t('newOrder.cardVinc.label')}</h6>
        {isNew && (
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
            <Grid item>
              {t('newOrder.cardVinc.nfLabel')}
              <Switch
                checked={nf}
                onChange={e => handleChangeNf(e.target.checked)}
                classes={{
                  switchBase: classes.switchBase,
                  checked: classes.checked,
                  track: classes.track,
                }}
              />
            </Grid>
            <Grid item>
              {t('newOrder.cardVinc.ticket')}
              <Switch
                checked={boleto}
                onChange={e => handleChangeBoleto(e.target.checked)}
                classes={{
                  switchBase: classes.switchBase,
                  checked: classes.checked,
                  track: classes.track,
                }}
              />
            </Grid>
            <Grid item>
              <MuiPickersUtilsProvider locale={locale} utils={DateFnsUtils}>
                <DatePicker
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  // style={{ backgroundColor: '#fff', borderRadius: '30px' }}
                  label={t('newOrder.cardVinc.expirationDate')}
                  size="small"
                  format="dd/MM/yyyy"
                  value={paymentDate}
                  InputProps={{
                    readOnly: true,
                    style: {
                      borderRadius: '25px',
                      textAlign: 'center',
                      alignItems: 'center',
                      justifyContent: 'center',
                      maxWidth: '155px',
                    },
                  }}
                  onChange={date => setPaymentDate(date)}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </div>
        )}
      </div>

      {isNew && (
        <Grid
          container
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Grid item xs={5} style={{ marginTop: '15px', marginRight: '10px' }}>
            <TextField
              className={classes.text}
              variant="outlined"
              size="small"
              fullWidth
              placeholder={t('newOrder.cardVinc.orderName')}
              value={orderName}
              onChange={e => setOrderName(e.target.value)}
              // inputRef={refQtdCards}
              InputProps={{
                classes: {
                  notchedOutline: classes.myTextField,
                  input: classes.text,
                },
              }}
            />
          </Grid>
          <Grid item xs={5} style={{ marginTop: '15px', marginLeft: '10px' }}>
            <TextField
              className={classes.text}
              variant="outlined"
              size="small"
              fullWidth
              placeholder={t('newOrder.cardVinc.comments')}
              value={nfComments}
              onChange={e => setNfComments(e.target.value)}
              InputProps={{
                classes: {
                  notchedOutline: classes.myTextField,
                  input: classes.text,
                },
              }}
            />
          </Grid>
        </Grid>
      )}

      <Grid
        container
        spacing={1}
        direction="row"
        style={{
          justifyContent: 'end',
          alignItems: 'end',
          paddingBottom: 10,
          marginTop: 20,
          marginBottom: 20,
        }}
      >
        <Grid item xs={5}>
          <p className={styles.labelTitleResum}>
            {t('newOrder.cardVinc.name')}
          </p>
          <TextField
            className={classes.text}
            variant="outlined"
            size="small"
            fullWidth
            // placeholder="Nome do pedido"
            value={name}
            onChange={e => setName(e.target.value)}
            InputProps={{
              classes: {
                notchedOutline: classes.myTextField,
                input: classes.text,
              },
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <p className={styles.labelTitleResum}>{t('newOrder.cardVinc.cpf')}</p>
          <TextField
            type="number"
            className={classes.text}
            variant="outlined"
            size="small"
            fullWidth
            value={cpf}
            onChange={e => setCpf(e.target.value)}
            // inputRef={refCpf}
            InputProps={{
              classes: {
                notchedOutline: classes.myTextField,
                input: classes.text,
              },
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <MyButton
              style={{
                width: 100,
                height: 40,
                borderRadius: 10,
                marginLeft: 30,
              }}
              onClick={addPerson}
              label={t('newOrder.cardVinc.btnAdd')}
            />
            <MyButton
              style={{
                width: 100,
                height: 40,
                borderRadius: 10,
                marginLeft: 10,
              }}
              onClick={() => fileXls.current.click()}
              label="Planilha"
            />
            <Tooltip title="Download modelo de planilha">
              <img
                id="refresh"
                src="./img/download.svg"
                alt="logo"
                style={{
                  height: 15,
                  width: 15,
                  marginLeft: 10,
                  cursor: 'pointer',
                }}
                onClick={downloadModel}
              />
            </Tooltip>
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        direction="row"
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          paddingBottom: 10,
        }}
      >
        <Grid item xs={12}>
          <TableVinc
            setOrders={setCardVinc}
            orders={cardVinc}
            totalPages={1}
            page={1}
            setCpf={setCpf}
            setName={setName}
            // setPage={setPage}
            itens={10}
            // setItens={setItens}
          />
        </Grid>
      </Grid>
      <input
        ref={fileXls}
        type="file"
        style={{
          opacity: 0,
        }}
        // accept="media_type"
        onChange={() => setMyfile(fileXls.current.files[0])}
        className="form-control"
        id="inputNumSerie"
        // placeholder="Arquivo DWG do projeto"
      />
    </div>
  );
}
