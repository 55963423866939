/* eslint-disable camelcase */
export const STATES = [
  'AC',
  'AL',
  'AP',
  'AM',
  'BA',
  'CE',
  'DF',
  'ES',
  'GO',
  'MA',
  'MS',
  'MT',
  'MG',
  'PA',
  'PB',
  'PR',
  'PE',
  'PI',
  'RJ',
  'RN',
  'RS',
  'RO',
  'RR',
  'SC',
  'SP',
  'SE',
  'TO',
];

export const DELIVERY_AGENTS = [
  { value: 'logistics', label: 'Logística' },
  { value: 'mail', label: 'Correio' },
  { value: 'customer_goes', label: 'Cliente Retira' },
];

export const DELIVERY_SERVICES = {
  logistics: [
    { value: 'doc+', label: 'Doc+' },
    { value: 'flash_azul', label: 'Flash_Azul', default: true },
  ],
  mail: [
    { value: 'sedex', label: 'Sedex', default: true },
    { value: 'carta_registrada', label: 'Carta Registrada' },
  ],
};

export const hasDeliveryServices = delivery_agent =>
  Object.keys(DELIVERY_SERVICES).includes(delivery_agent);

export const CARD_BRANDS = [
  { id: 1, value: 'cabal', label: 'Cabal' },
  { id: 2, value: 'elo', label: 'Elo' },
  { id: 3, value: 'mastercard', label: 'Mastercard' },
  { id: 4, value: 'visa', label: 'Visa' },
];

export const CLASS_ACCOUNT = [
  { value: 'basic', label: 'Básica' },
  { value: 'special', label: 'Especial' },
];

export const CARD_BRANDS_PRODUCT = {
  prepaidCard: [
    { id: 1, value: 'cabal', label: 'Cabal' },
    { id: 2, value: 'elo', label: 'Elo' },
    { id: 3, value: 'mastercard', label: 'Mastercard' },
    { id: 4, value: 'visa', label: 'Visa' },
  ],
  digitalAccount: [{ id: 4, value: 'visa', label: 'Visa' }],
};

export const PRODUCTS = [
  { value: 'prepaid_card', label: 'Cartão' },
  { value: 'transfer', label: 'Tranferência' },
  // { value: 'digital_account', label: 'Conta Digital' },
];

export const CALC_COMISSION = [
  { value: 'liquid', label: 'Líquido' },
  { value: 'total', label: 'Bruto' },
  // { value: 'digital_account', label: 'Conta Digital' },
];

export const KIND_ACCOUNT = [
  { value: 'no_card', label: 'Sem cartão' },
  { value: 'virtual_card', label: 'Cartão Virtual' },
];

export const SALES_CHANELS = [{ value: 'direct', label: 'Direto' }];

export const ISSUERS = [
  { value: 'acesso', label: 'Acesso' },
  // { value: 'bpp', label: 'Bpp' },
  // { value: 'cabal', label: 'Cateno' },
  // { value: 'cateno', label: 'Cateno' },
  // { value: 'incentivale', label: 'Incentivale' },
];

export const ISSUERS_MC = [
  { value: 'acesso', label: 'Acesso' },
  { value: 'qui', label: 'Qui' },
];

export const ISSUERS_VISA = [{ value: 'bpp', label: 'Bpp' }];

export const SUPER_ADMIN_ROLE = 'superAdmin';
export const ADMIN_ROLE = 'admin';
export const EXPLORER_ROLE = 'explorer';
export const DEVELOPER_ROLE = 'developer';
export const COMMERCIAL_AGENT_ROLE = 'commercialAgent';

export const ROLES_PT = {
  [SUPER_ADMIN_ROLE]: 'Super administrador',
  [ADMIN_ROLE]: 'Administrador',
  [EXPLORER_ROLE]: 'Explorador',
  [DEVELOPER_ROLE]: 'Desenvolvedores',
  [COMMERCIAL_AGENT_ROLE]: 'Agente comercial',
};

export const ADMINS = [SUPER_ADMIN_ROLE, ADMIN_ROLE];

export const COMPANY_ROLES = [
  EXPLORER_ROLE,
  DEVELOPER_ROLE,
  COMMERCIAL_AGENT_ROLE,
];

export const COMPANY_PERMISSIONS = [
  'profiles',
  'communications',
  'businessRules',
];

export const MODULE_PERMISSIONS = ['create', 'read', 'write', 'delete'];

export const ELEMENT_COMPANY_ID = 'ELEMENT';
