/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { FC, useState, ChangeEvent } from 'react';
// Importing MaterialUI components
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import {
  Box,
  Button,
  TextField,
  Grid,
  InputBaseComponentProps,
  MenuItem,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  Checkbox,
  ListItemText,
  OutlinedInput,
} from '@material-ui/core';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import api from '../../../../service/axios/api';

// import { uploadAttachments } from '@/util/attachments';
import useBoolean from '../../../sales/hooks/useBoolean/useBoolean';
import TutorialSchema from './TutorialSchema';
import TutorialCategoryAutocomplete from './TutorialCategoryAutocomplete';
import SuccessModal from '../../../sales/components/SuccessModal';
import { Video } from '../../../../components/Video';
import { useStyles } from './styles';

const tutorialDefault = {
  id: undefined,
  title: undefined,
  subject: undefined,
  media: undefined,
};

const names = ['ADM', 'Desenvolvedor', 'Explorador', 'Usuario'];

export function Upload({ onClose, selected, close }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [files, setFiles] = useState();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const edit = selected !== undefined;
  const [tutorial, setTutorial] = useState(selected || tutorialDefault);
  const query2 = [];
  // const [tutorialCategories, loading2] =
  //   useCollectionDataOnce < TutorialCategory > query2;
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: tutorialDefault,
    resolver: yupResolver(TutorialSchema),
  });

  const {
    // value: success,
    toTrue: isSuccess,
    toFalse: isNotSuccess,
  } = useBoolean({
    initialValue: false,
  });
  const [success, setSuccess] = useState(false);
  const router = useHistory();
  const onClosed = () => {
    // isNotSuccess();
    // router.reload();
    window.location.reload();
    setSuccess(false);
    close();
  };
  const submit = async data => {
    setLoading(true);
    let response;
    try {
      if (data.title) {
        if (data.subject) {
          if (data.media) {
            if (edit) {
              response = await api.tutorials.update(data, selected.id);
            } else {
              response = await api.tutorials.create(data);
            }
          }
        }
      }

      if (response.code === 'API_RES_SUCCESS') setSuccess(true);
    } catch (err) {
      const erro = err;

      // eslint-disable-next-line no-console
      console.error(erro);
    }
    setLoading(false);
  };

  const uploadFile = event => {
    const newFiles = Array.from(event.target.files || event.dataTransfer.files);
    setFiles(newFiles);
  };

  const [personName, setPersonName] = useState([]);

  const handleChange = event => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <Box className={classes.container}>
      <form onSubmit={handleSubmit(submit)}>
        <Typography
          className={classes.subtitle}
          variant="h5"
          color="primary"
          paragraph
        >
          {t('tutorials.new.new.label')}
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Grid item xs={12} style={{ marginBottom: 10 }}>
              <TextField
                fullWidth
                label={t('tutorials.new.new.title')}
                variant="outlined"
                size="small"
                className={classes.input}
                autoFocus
                inputProps={{
                  ...register('title'),
                }}
                error={!!errors.title}
                helperText={errors.title?.message}
                value={tutorial.title}
                onChange={e => {
                  setTutorial({
                    ...tutorial,
                    title: e.target.value,
                  });
                }}
              />
            </Grid>
            {/* <Grid item xs={6} /> */}
            {/* <Grid item xs={6} style={{ pointerEvents: edit ? 'none' : 'auto' }}>
            <Controller
              control={control}
              name="section"
              render={props => (
                <TutorialCategoryAutocomplete
                  value={props.field.value}
                  onChange={props.field.onChange}
                  error={!!errors.section}
                  helperText={errors.section?.message}
                />
              )}
            />
            </Grid> */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="standard-multiline-static"
                multiline
                rows={3}
                label={t('tutorials.new.new.description')}
                variant="outlined"
                size="small"
                className={classes.input}
                inputProps={{
                  ...register('subject'),
                }}
                value={tutorial.subject}
                onChange={e => {
                  e.preventDefault();
                  setTutorial({
                    ...tutorial,
                    subject: e.target.value,
                  });
                }}
                error={!!errors.subject}
                helperText={errors.subject?.message}
                style={{ marginBottom: '10.5px' }}
              />
              <TextField
                fullWidth
                label={t('tutorials.new.new.midia')}
                variant="outlined"
                size="small"
                className={classes.input}
                inputProps={{
                  ...register('media'),
                }}
                value={tutorial.media}
                onChange={e => {
                  e.preventDefault();
                  setTutorial({
                    ...tutorial,
                    media: e.target.value,
                  });
                }}
                placeholder={t('tutorials.new.new.midia')}
                error={!!errors.media}
                helperText={errors.media?.message}
                style={{ marginBottom: '10.5px' }}
              />
              {/* <FormControl fullWidth>
              <InputLabel id="checkbox-label">Permitir</InputLabel>
              <Select
                fullWidth
                labelId="checkbox-label"
                id="multiple-checkbox"
                multiple
                variant="outlined"
                renderValue={s => s.join(', ')}
                value={personName}
                onChange={handleChange}
              >
                {names.map(name => {
                  const nameNever = name;
                  return (
                    <MenuItem key={name} value={name}>
                      <Checkbox checked={personName.indexOf(nameNever) > -1} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl> */}
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <div
              style={{
                width: 350,
                paddingTop: '10px',
                margin: 'auto',
                height: '100%',
              }}
            >
              <Video url={tutorial.media} />
            </div>
          </Grid>
          {/* {!edit && (
            <>
              <Grid item xs={4}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  component="label"
                  htmlFor="upload-file"
                >
                  Upload de arquivo
                </Button>
              </Grid>
              <Grid item xs={6} style={{ display: 'none' }}>
                <input
                  onChange={uploadFile}
                  accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt,.rtf,.odt,.ods,.odp,.odg,.odf,.odb,.odm,.csv,.png,.jpg,.jpeg,.gif"
                  id="upload-file"
                  type="file"
                  multiple
                />
              </Grid>
              <Grid item xs={8} style={{ alignSelf: 'center' }}>
                <Typography variant="caption" color="inherit">
                  {files
                    ? files.map(f => f.name).join(', ')
                    : error || 'Nenhum arquivo selecionado'}
                </Typography>
              </Grid>
            </>
          )} */}
          <div className={classes.footer}>
            <Button type="button" variant="contained" onClick={onClose}>
              {t('tutorials.new.new.btnCancel')}
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              // onClick={() => submit(tutorial)}
              // disabled={files === undefined && !edit}
            >
              {loading && <CircularProgress size={14} color="secondary" />}
              {!loading && t('tutorials.new.new.btnSalve')}
            </Button>
          </div>
        </Grid>
      </form>
      <SuccessModal
        title={
          edit
            ? t('tutorials.new.new.msgEdit')
            : t('tutorials.new.new.msgSalve')
        }
        open={success}
        onClose={onClosed}
      />
    </Box>
  );
}

export default Upload;
