import { string, object, boolean, number, date } from 'yup';

const RevenueSchema = object({
  calculation: string().required(),
  currency: string().required(),
  paymentMethod: string().required(),
  isInvoiceTopup: boolean().required(),
  issuingCompanyId: number().optional(),
  bankAccountId: number().optional(),
  serviceCodeId: number().optional(),
  signatureDate: date().optional(),
  expirationDate: date().optional(),
  isSigned: boolean(),
});

// export type Revenue = TypeOf<typeof RevenueSchema>

export default RevenueSchema;
