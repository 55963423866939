import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
// import Proposal from '@/types/Proposal'
// import axios from 'axios';
import { useHistory } from 'react-router-dom';
import ProposalForm from '../components/forms/ProposalForm';
import api from '../../../service/axios/api';

const useStyles = makeStyles(theme => ({
  header: {
    marginBottom: theme.spacing(2),
  },
}));

function CreateProposal() {
  const router = useHistory();
  const classes = useStyles();

  // const onSuccess = proposal => axios.post('/api/proposals', proposal);
  const onSuccess = proposal => api.oportunity.create(proposal);

  const callback = () => {
    router.push('/sales/proposals');
  };

  return (
    <>
      <Grid
        className={classes.header}
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Typography variant="h4" component="h2" gutterBottom>
          Nova oportunidade
        </Typography>
      </Grid>
      <ProposalForm
        onSuccess={onSuccess}
        code={201}
        titleModal="Oportunidade inserida com sucesso"
        actionModal={callback}
        disabled={false}
      />
    </>
  );
}

export default CreateProposal;
