/* eslint-disable no-unused-vars */
import * as React from 'react';
// import Grid from '@mui/material/Grid';
// import Typography from '@mui/material/Typography';
// import TextField from '@mui/material/TextField';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
import Select from 'react-select';
// import FormGroup from '@mui/material/FormGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
// import { styled } from '@mui/material/styles';
// import Radio from '@mui/material/Radio';
// import RadioGroup from '@mui/material/RadioGroup';
// import FormControl from '@mui/material/FormControl';
import { Switch, styled, Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styles from '../../../../../styles/Layout.module.css';

// import Switch from '@mui/material/Switch';

const options = [
  {
    label: 'MasterCard',
    value: 1,
  },
  {
    label: 'Visa',
    value: 2,
  },
  {
    label: 'elo',
    value: 3,
  },
];

const optionsE = [
  {
    label: 'Emissor 1',
    value: 1,
  },
  {
    label: 'Emissor 2',
    value: 2,
  },
  {
    label: 'Emissor 3',
    value: 3,
  },
];

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    width: 220,
    height: 50,
    borderRadius: 10,
    // backgroundColor: 'none',
    // boxShadow: 'none',
    // border: 'none',
    fontWeight: 'bold',
    fontFamily: 'Open Sans',
    fontSize: 14,
  }),
  indicatorSeparator: s => ({ display: 'none' }),
  option: (provided, state) => ({
    ...provided,
    fontFamily: 'Open Sans',
    fontSize: 14,
  }),
  placeholder: (provided, state) => ({
    // fontWeight: 'bold',
    fontFamily: 'Open Sans',
    fontSize: 14,
    color: '#9C9C9C',
    // marginBottom: 30,
  }),
};

const useStyles = makeStyles(() => ({
  myTextField: {
    backgroundColor: 'none',
    boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
    border: 'none',
    color: '#717171',
    fontWeight: 'bold',
    fontFamily: 'Open Sans',
  },
  text: {
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
    color: '#717171',
  },
  mySwitch: {
    '&.MuiSwitch-colorPrimary.MuiSwitch-checked': {
      color: 'green',
    },
  },
  checked: {},
  switchBase: {
    color: '#fff',
    '&$checked': {
      color: '#41b490',
    },
    '&$checked + $track': {
      backgroundColor: '#41b490',
    },
  },
  track: {},
}));

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark'
        ? 'rgba(57,75,89,.5)'
        : 'rgba(206,217,224,.5)',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#41b490',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#41b490',
  },
});

export default function TypeCard() {
  const classes = useStyles();
  return (
    <div className="box-new-card" style={{ height: 341 }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h5 className={styles.textBoxInform}>
          Características da Conta Digital
        </h5>
        <h5 className={styles.textBoxInform2}>COD.: PROD_CDPP_001</h5>
      </div>
      <Grid
        container
        spacing={2}
        direction="row"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Grid item sm={4}>
          <Grid container spacing={1}>
            <Grid item sm={4}>
              <p>Nome do produto</p>
              <TextField
                className={classes.text}
                fullWidth
                label="C. Digital"
                variant="outlined"
                size="small"
                // inputRef={search}
                InputProps={{
                  classes: {
                    notchedOutline: classes.myTextField,
                    input: classes.text,
                  },
                }}
              />
            </Grid>
            <Grid item sm={4}>
              <p>Adesão conta</p>
              <TextField
                className={classes.text}
                fullWidth
                label="R$00,00"
                variant="outlined"
                size="small"
                // inputRef={search}
                InputProps={{
                  classes: {
                    notchedOutline: classes.myTextField,
                    input: classes.text,
                  },
                }}
              />
            </Grid>
            <Grid item sm={4}>
              <p> Mensalidade</p>
              <TextField
                className={classes.text}
                fullWidth
                label="R$00,00"
                variant="outlined"
                size="small"
                // inputRef={search}
                InputProps={{
                  classes: {
                    notchedOutline: classes.myTextField,
                    input: classes.text,
                  },
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={4}>
          <Grid container spacing={1} direction="column">
            <Grid
              container
              direction="row"
              spacing={1}
              style={{ justifyContent: 'center' }}
            >
              <Grid item sm={4}>
                <p> TED</p>
                <TextField
                  className={classes.text}
                  fullWidth
                  label="R$00,00"
                  variant="outlined"
                  size="small"
                  // inputRef={search}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.myTextField,
                      input: classes.text,
                    },
                  }}
                />
              </Grid>
              <Grid item sm={4}>
                <p> P2P</p>
                <TextField
                  className={classes.text}
                  fullWidth
                  label="R$00,00"
                  variant="outlined"
                  size="small"
                  // inputRef={search}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.myTextField,
                      input: classes.text,
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              spacing={1}
              style={{ justifyContent: 'center' }}
            >
              <Grid item sm={4}>
                <p> Pix (PF)</p>
                <TextField
                  className={classes.text}
                  fullWidth
                  label="R$00,00"
                  variant="outlined"
                  size="small"
                  // inputRef={search}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.myTextField,
                      input: classes.text,
                    },
                  }}
                />
              </Grid>
              <Grid item sm={4}>
                <p> Pix (PJ)</p>
                <TextField
                  className={classes.text}
                  fullWidth
                  label="R$00,00"
                  variant="outlined"
                  size="small"
                  // inputRef={search}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.myTextField,
                      input: classes.text,
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              spacing={1}
              style={{ justifyContent: 'center', alignItems: 'center' }}
            >
              <Grid item sm={4}>
                <p> Serviços SMS Transacional</p>
                <TextField
                  className={classes.text}
                  fullWidth
                  label="R$00,00"
                  variant="outlined"
                  size="small"
                  // inputRef={search}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.myTextField,
                      input: classes.text,
                    },
                  }}
                />
              </Grid>
              <Grid item sm={4}>
                <p>+ adicionar serviço</p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={3}>
          <Grid container spacing={1} direction="column">
            <Grid
              container
              direction="row"
              style={{ justifyContent: 'center', alignItems: 'center' }}
            >
              <Grid item sm={4}>
                <p> Recargas via Sistema</p>
                <TextField
                  className={classes.text}
                  fullWidth
                  label="R$00,00"
                  variant="outlined"
                  size="small"
                  // inputRef={search}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.myTextField,
                      input: classes.text,
                    },
                  }}
                />
              </Grid>
              <Grid item sm={4}>
                <Switch
                  defaultChecked
                  classes={{
                    switchBase: classes.switchBase,
                    checked: classes.checked,
                    track: classes.track,
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              style={{ justifyContent: 'center', alignItems: 'center' }}
            >
              <Grid item sm={4}>
                <p> Via PDV</p>
                <TextField
                  className={classes.text}
                  fullWidth
                  label="R$00,00"
                  variant="outlined"
                  size="small"
                  // inputRef={search}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.myTextField,
                      input: classes.text,
                    },
                  }}
                />
              </Grid>
              <Grid item sm={4}>
                <Switch
                  classes={{
                    switchBase: classes.switchBase,
                    checked: classes.checked,
                    track: classes.track,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* <div className="my-row-align">
        <div className="my-colunm-align">
          <div className="my-row-align">
            <div>
              <p className="text-select-label ">Nome do produto</p>
              <input className="my-input-account" placeholder="Conta Digital" />
            </div>
            <div style={{ marginLeft: 5 }}>
              <p className="text-select-label ">Adesão Conta</p>
              <input className="my-input-account" placeholder="R$00,00" />
            </div>
            <div style={{ marginLeft: 5 }}>
              <p className="text-select-label ">Mensalidade</p>
              <input className="my-input-account" placeholder="R$00,00" />
            </div>
          </div>
        </div>
        <div className="my-colunm-align" style={{ marginTop: -40 }}>
          <p
            className="text-select-label "
            style={{ marginLeft: -180, marginBottom: -20 }}
          >
            Transferências
          </p>
          <div className="my-row-align">
            <div>
              <p className="text-select-label ">TED</p>
              <input className="my-input-account" placeholder="R$00,00" />
            </div>
            <div style={{ marginLeft: 5 }}>
              <p className="text-select-label ">P2P</p>
              <input className="my-input-account" placeholder="R$00,00" />
            </div>
          </div>
          <div className="my-row-align" style={{ marginTop: -15 }}>
            <div>
              <p className="text-select-label ">PIX(PF)</p>
              <input className="my-input-account" placeholder="R$00,00" />
            </div>
            <div style={{ marginLeft: 5 }}>
              <p className="text-select-label ">PIX(PJ)</p>
              <input className="my-input-account" placeholder="R$00,00" />
            </div>
          </div>
          <p
            className="text-select-label "
            style={{ marginLeft: -240, marginBottom: -20, marginTop: -1 }}
          >
            Serviços
          </p>
          <div className="my-row-align" style={{ marginTop: -1 }}>
            <div>
              <p className="text-select-label ">SMS Transacional</p>
              <input className="my-input-account" placeholder="R$00,00" />
            </div>
            <div style={{ marginLeft: 5 }}>
              <p className="text-select-label ">+ adicionar serviço</p>
            </div>
          </div>
        </div>
        <div className="my-colunm-align">
          <p
            className="text-select-label "
            style={{ marginLeft: -135, marginBottom: -20 }}
          >
            Recargas
          </p>
          <div className="my-row-align">
            <div>
              <p className="text-select-label ">Via Sistema</p>
              <input className="my-input-account" placeholder="R$00,00" />
            </div>
            <div>
              <Switch defaultChecked />
            </div>
          </div>
          <div className="my-row-align">
            <div>
              <p className="text-select-label ">Via PDV</p>
              <input className="my-input-account" placeholder="R$00,00" />
            </div>
            <div>
              <Switch />
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}
