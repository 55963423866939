import React, { forwardRef } from 'react';

import OutlinedInput from '@material-ui/core/OutlinedInput';

import { useStylesTextOutlined } from './styles';

const TextFieldOutlined = forwardRef(({ ...others }, ref) => {
  const classes = useStylesTextOutlined();
  return (
    <OutlinedInput
      inputRef={ref}
      classes={classes}
      notched={false}
      {...others}
    />
  );
});

export default TextFieldOutlined;
