/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Link from '@material-ui/core/Link';
import Toolbar from '@material-ui/core/Toolbar';
import Drawer from '@material-ui/core/Drawer';
import styles from '../styles/SideBar.module.css';

// import hasPermission from '@/hooks/hasPermission'

function SideBar() {
  // const showComunnications = hasPermission({ communications: ['read'] })
  // const showBusinessRules = hasPermission({ businessRules: ['read'] })

  return (
    <Drawer
      className={styles.drawer}
      variant="permanent"
      classes={{
        paper: styles.drawerPaper,
      }}
    >
      <Toolbar />
      <div className={styles.drawerContainer}>
        <Link href="/sales/dashboard">
          <a className={styles.link}>
            <img
              src="/assets/resultsIcon.svg"
              height="30px"
              width="30px"
              alt=""
            />
            <span>Dashboard</span>
          </a>
        </Link>
        <Link href="/sales/campaign">
          <a className={styles.link}>
            <img src="/assets/flag.svg" height="30px" width="30px" alt="" />
            <span>Campanhas</span>
          </a>
        </Link>
        <Link href="/sales/proposals">
          <a className={styles.link}>
            <img
              src="/assets/oportunity.svg"
              height="30px"
              width="30px"
              alt=""
            />
            <span>Oportunidade</span>
          </a>
        </Link>
        <Link href="/sales/contracts">
          <a className={styles.link}>
            <img src="/assets/contract.svg" height="30px" width="30px" alt="" />
            <span>Meus Contratos</span>
          </a>
        </Link>
        <Link href="/sales/business">
          <a className={styles.link}>
            <img
              src="/assets/pasteIcon.svg"
              height="30px"
              width="30px"
              alt=""
            />
            <span>Meus Negócios</span>
          </a>
        </Link>
        <Link href="/sales/extracts">
          <a className={styles.link}>
            <img
              src="/assets/movimentIcon.svg"
              height="30px"
              width="30px"
              alt=""
            />
            <span>Extratos</span>
          </a>
        </Link>
        <Link href="/sales/bankAccount">
          <a className={styles.link}>
            <img src="/img/bank.png" height={30} width={30} alt="" />
            <span>Dados Bancarios</span>
          </a>
        </Link>
        {/* <Link href="/sales/results">
          <a className={styles.link}>
            <img
              src="/assets/contractIcon.svg"
              height="30px"
              width="30px"
              alt=""
            />
            <span>Consolidaçoes</span>
          </a>
        </Link> */}
        {/* {showBusinessRules && ( */}
        {/* <Link href="/sales/rules"> */}
        {/* <a className={styles.link}>
          <img src="/assets/rulesIcon2.svg" height="30px" width="30px" alt="" />
          <span>Regras</span>
        </a> */}
        {/* </Link> */}
        {/* )} */}
        {/* {showBusinessRules && ( */}
        {/* <Link href="/sales/commercial-agents"> */}
        {/* <a className={styles.link}>
          <svg
            style={{ opacity: 0.5 }}
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 24 24"
          >
            <path d="M22 8v12h-16v-12h16zm2-2h-20v16h20v-16zm-8 11.677v.323h-8v-.333c-.004-.89.035-1.398 1.059-1.634 1.123-.259 2.23-.491 1.697-1.473-1.577-2.911-.449-4.56 1.244-4.56 1.662 0 2.816 1.588 1.244 4.56-.518.976.551 1.208 1.697 1.473 1.028.237 1.063.748 1.059 1.644zm4-7.677h-3v2h3v-2zm0 3h-3v2h3v-2zm0 3h-3v2h3v-2zm2-12h-20v16h1v-15h19v-1zm-2-2h-20v16h1v-15h19v-1z" />
          </svg>
          <span>Multiplicadores</span>
        </a> */}
        {/* </Link> */}
        {/* )} */}
        {/* {showComunnications && ( */}
        {/* <Link href="/sales/communications"> */}
        {/* <a className={styles.link}>
          <img src="/assets/chatIcon.svg" height="30px" width="30px" alt="" />
          <span>Comunicação</span>
        </a> */}
        {/* </Link> */}
        {/* )} */}
        {/* <Link href="/sales/tutorials"> */}
        {/* <a className={styles.link}>
          <img
            src="/assets/tutorialIcon.svg"
            height="30px"
            width="30px"
            alt=""
          />
          <span>Tutorial</span>
        </a> */}
        {/* </Link> */}
        {/* <Link href="/sales/formation"> */}
        {/* <a className={styles.link}>
          <img
            src="/assets/businessblueIcon.svg"
            height="30px"
            width="30px"
            alt=""
          />
          <span>Formação</span>
        </a> */}
        {/* </Link> */}
      </div>
    </Drawer>
  );
}

export default SideBar;
