/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-self-compare */
import React, { useState } from 'react';
import Link from '@material-ui/core/Link';
// Importing NextJS hooks
// import Link from 'next/link';

// Importing Material UI components
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import MuiBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import { FaUser } from 'react-icons/fa';

import useBoolean from '../../hooks/useBoolean/useBoolean';
// import { useSession } from '@/context/SessionContext';

import { useStyles } from './AppBar.styles';
import Dropdown from './AppBar.menu';

function AppBar() {
  const classes = useStyles();
  // const session = useSession();

  // const { user } = session;
  const user = {};

  const { value, toFalse, toggle } = useBoolean({ initialValue: false });
  const [anchorEl, setAnchorEl] = useState(null > null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
    toggle();
  };

  const handleClose = () => {
    setAnchorEl(null);
    toFalse();
  };

  const { fullName } = user;

  return (
    <>
      <MuiBar position="fixed">
        <Toolbar>
          <Link href="/dashboard">
            <a>
              <img
                src="/assets/login/logoWhite.svg"
                alt="Senex logo"
                height="64"
                width="150"
              />
            </a>
          </Link>
          <Box flexGrow={1} />
          <Button
            className={classes.userContainer}
            onClick={handleClick}
            disableRipple
          >
            {/* <Avatar>
              <FaUser />
            </Avatar> */}
            <Typography variant="h6" color="inherit">
              {fullName}
            </Typography>
          </Button>
        </Toolbar>
      </MuiBar>
      <Dropdown
        anchorEl={anchorEl}
        open={value}
        onClose={handleClose}
        // logout={session.logOut}
        user={user}
      />
    </>
  );
}

export default AppBar;
