/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import api from '../../service/axios/api';

function PemissionComponent({ role, children }) {
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    async function loadRoles() {
      if (api.currentUser) {
        if (api.currentUser.user.permissions) {
          // const tmp = '111111111111111111111111';
          setPermissions(
            api.currentUser.user.permissions.substring(role, role + 1) === '1',
          );
        }
        // FUNCIONA
        // if (api.currentUser.user.role) {
        //   response.push(api.currentUser.user.role.id);
        //   const findRole = response.some(r => role?.split(',').includes(r));
        //   console.log('findRole', findRole);
        //   setPermissions(findRole);
        // }
        // FIM DO QUE FUNCIONA
      }
    }

    loadRoles();
  }, []);
  return permissions && children;
}

export default PemissionComponent;
