/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */

import React, { useState, useEffect, useContext } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import moment from 'moment-timezone';
// import EditIcon from '@material-ui/icons/Edit';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

import {
  TablePagination,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Paper,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  Grid,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import locale from 'date-fns/locale/pt-BR';
import DateFnsUtils from '@date-io/date-fns';
import FlashContext from '../../../../components/contexts/FlashContext';
import Loading from '../../../../components/loading/Loading';
import MyExcelExport from './MyExcelExport';
import Button from '../../../../components/button/MyButton';
import api from '../../../../service/axios/api';

const customStyles = {
  control: provided => ({
    ...provided,
    width: 262,
    backgroundColor: 'none',
    boxShadow: 'none',
    // border: 'none',
    // fontWeight: 'bold',
    fontFamily: 'Open Sans',
    // fontSize: 24,
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  option: provided => ({
    ...provided,
    fontWeight: 'bold',
    fontFamily: 'Open Sans',
    // color: state.data.color,
    // backgroundColor: state.data.bgcolor,
    // fontSize: 24,
  }),
  placeholder: () => ({
    fontWeight: 'bold',
    fontFamily: 'Open Sans',
    marginTop: -20,
    // paddingBottom: 20,
    // fontSize: 24,
    // marginBottom: 30,
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
};

function createData(id, nf, client, cnpj, bank, paymentDate, value) {
  return {
    id,
    nf,
    client,
    cnpj,
    bank,
    paymentDate,
    value,
  };
}

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  table: {
    minWidth: 1100,
  },
});

const StyledTableCell = withStyles(() => ({
  head: {
    // backgroundColor: theme.palette.common.black,
    // color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function Extract({
  docs,
  page,
  setPage,
  itens,
  setItens,
  total,
  searchData,
}) {
  const { t } = useTranslation();
  const [dataExcel, setDataExcel] = useState();
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [menuOptions, setMenuOptions] = useState(null);
  const [openModalPayment, setOpenModalPayment] = useState(false);
  const [banks, setBanks] = useState([]);
  const [bankId, setBankId] = useState({ label: '', value: 0 });
  const [selectedDate, handleDateChange] = useState(new Date());
  const [paymentLoading, setPaymentLoading] = useState(false);
  const { notifySuccess } = useContext(FlashContext);

  useEffect(() => {
    const cancel = new AbortController();
    const query = '?status=Active';
    api.banks
      .get(cancel, query)
      .then(res => {
        const list = [];
        res.retorno.forEach(b => {
          list.push({ label: `${b.bank}-${b.account_number}`, value: b.id });
        });
        setBanks(list);
      })
      .catch(err => console.log(err));
  }, []);

  const editPayment = () => {
    setPaymentLoading(true);
    api.statement
      .update(menuOptions.id, bankId.value, selectedDate)
      .then(res => {
        searchData();
        notifySuccess(res.message);
        setOpenModalPayment(false);
      })
      .catch(err => console.log(err))
      .finally(() => setPaymentLoading(false));
  };

  const handleMenu = (row, event) => {
    setMenuOptions(row);
    const find = banks.find(b => b.label === row.bank);
    setBankId(find);
    handleDateChange(moment(row.paymentDate).tz('America/Sao_Paulo'));
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    setOpenModalPayment(true);
    handleClose();
  };

  useEffect(() => {
    if (docs) {
      const list = [];
      const listE = [];
      docs.forEach(d => {
        list.push(
          createData(
            d.id,
            d.InvoicePayment?.invoice,
            d.InvoicePayment?.client_name,
            d.InvoicePayment?.cnpj,
            `${d.bank?.bank}-${d.bank.account_number}`,
            d.statement_date,
            d.amount,
          ),
        );
        listE.push({
          id: d.id,
          'Data Entrada': moment(d.statement_date).format('DD/MM/YYYY'),
          'Banco recebedor': `${d.bank?.bank}-${d.bank.account_number}`,
          'Nota Fiscal': d.InvoicePayment?.invoice,
          Cliente: d.InvoicePayment?.client_name,
          Cnpj: d.InvoicePayment?.cnpj,
          'Valor Pago': new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(d.amount),
        });
      });
      setDataExcel({ Cartoes: listE });
      setRows(list);
    }
  }, [docs]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setItens(event.target.value);
  };

  const mountDataExcel = d => {
    return <MyExcelExport allData={d} />;
  };

  return (
    <>
      <Paper className={classes.root}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Data Entrada</StyledTableCell>
                <StyledTableCell>Banco recebedor</StyledTableCell>
                <StyledTableCell>Nota Fiscal</StyledTableCell>
                <StyledTableCell>Cliente</StyledTableCell>
                <StyledTableCell>CNPJ</StyledTableCell>
                <StyledTableCell align="right">
                  <div style={{ display: 'flex', justifyContent: 'end' }}>
                    Valor Pago
                    {dataExcel && mountDataExcel(dataExcel)}
                  </div>
                </StyledTableCell>
                <StyledTableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, i) => {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <StyledTableRow key={i}>
                    <StyledTableCell>
                      {moment(row.paymentDate).format('DD/MM/YYYY')}
                    </StyledTableCell>
                    <StyledTableCell>{row.bank}</StyledTableCell>
                    <StyledTableCell>{row.nf}</StyledTableCell>
                    <StyledTableCell>{row.client}</StyledTableCell>
                    <StyledTableCell>{row.cnpj}</StyledTableCell>
                    <StyledTableCell align="right">
                      {new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      })
                        .format(row.value)
                        .replace(',', '.')}
                    </StyledTableCell>
                    <StyledTableCell>
                      <MoreVertIcon
                        onClick={e => handleMenu(row, e)}
                        style={{ cursor: 'pointer' }}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100, 1000, 50000]}
          component="div"
          count={total}
          rowsPerPage={itens}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
        <Menu
          id="menu-appbar"
          style={{ borderRadius: '25px' }}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem className={classes.textNotification} onClick={handleEdit}>
            Editar pagamento
          </MenuItem>
          {/* <MenuItem className={classes.textNotification} onClick={handleClose}>
            Cancelar pagamento
          </MenuItem> */}
        </Menu>
      </Paper>
      <Dialog
        onClose={() => setOpenModalPayment(false)}
        open={openModalPayment}
        fullWidth
        maxWidth="sm"
      >
        <Loading open={paymentLoading} msg="Editando pagamento..." />
        <DialogTitle id="simple-dialog-title">
          <Grid
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Grid>Editar</Grid>
            <Grid>{menuOptions && menuOptions.nf && menuOptions.nf}</Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid container>
              {/* <Grid item xs={12}>
                Edição do pagamento
              </Grid> */}
              <Grid
                container
                style={{
                  // display: 'flex',
                  // justifyContent: 'center',
                  // alignItems: 'center',
                  marginTop: 25,
                }}
              >
                <Grid
                  item
                  xs={6}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <p>Data do Pagamento:</p>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <MuiPickersUtilsProvider locale={locale} utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      style={{ backgroundColor: '#fff' }}
                      // label="With keyboard"
                      size="small"
                      format="dd/MM/yyyy"
                      value={selectedDate}
                      InputProps={{ readOnly: true }}
                      InputAdornmentProps={{ position: 'start' }}
                      onChange={date => handleDateChange(date)}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
              <Grid
                container
                style={{
                  marginTop: 25,
                }}
              >
                <Grid
                  item
                  xs={6}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <p>Banco de Entrada:</p>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Select
                    fullWidth
                    styles={customStyles}
                    // style={{ zIndex: 999 }}
                    className="basic-single"
                    classNamePrefix="select"
                    placeholder="Banco"
                    menuPortalTarget={document.body}
                    // styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    value={bankId}
                    onChange={setBankId}
                    isSearchable={false}
                    options={banks}
                  />
                </Grid>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <h6
            style={{
              cursor: 'pointer',
              color: '#657588',
              fontWeight: 'bold',
              fontFamily: 'Open Sans',
              marginTop: 5,
            }}
            onClick={() => setOpenModalPayment(false)}
          >
            {t('OrderDetails.completeCancelBtn')}
          </h6>
          <Button
            style={{ height: 40 }}
            onClick={editPayment}
            color="primary"
            variant="contained"
            label="Editar"
          />
        </DialogActions>
      </Dialog>
    </>
  );
}
