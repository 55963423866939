/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import useStyles from '../styles/AuthForm.styles';
import useSteps from '../hooks/useSteps/useStep';
import LoginForm from './LoginForm';
import TwoFactor from './TwoFactorForm';

// import AuthForm from './forms/AuthForm';
const steps = ['login', '2FA'];

function SalesLogin() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const { step, next } = useSteps({ steps, initialStep: 0 });
  const [credentials, setCredentials] = useState();
  const router = useHistory();

  function redirect() {
    setLoading(true);
    setTimeout(() => {
      router.push('/sales/dashboard');
    }, 1000);
  }

  const handleLoginSuccess = data => {
    setCredentials(data);
    next();
  };

  async function handleTwoAuthSuccess(authToken) {
    try {
      // await auth.signInWithCustomToken(authToken);
      redirect();
    } catch (_) {
      // TODO: show error feedback
    }
  }

  return (
    <>
      {loading ? (
        <Box
          width="100vw"
          height="100vh"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <img
            src="/assets/login/icon.svg"
            alt="icon"
            width="100"
            height="100"
            className={classes.logo}
          />
          <Box component="span" pl={7} />
          <img
            src="/assets/login/text.svg"
            alt="name"
            width="200"
            height="100"
          />
        </Box>
      ) : (
        <Grid container alignItems="stretch">
          <Grid
            item
            lg={4}
            md={5}
            sm={8}
            xs={12}
            component={Box}
            className={classes.sectionForm}
          >
            <div className={classes.form}>
              <Box
                p={10}
                position="relative"
                width="100%"
                height="100"
                style={{
                  textAlign: 'center',
                }}
              >
                <img
                  src="/assets/login/logo.svg"
                  alt="Logo"
                  style={{ width: '250px' }}
                />
              </Box>
              <Box p={10} />
              {/* <LoginForm onSuccess={handleLoginSuccess} /> */}
              {step === 0 && <LoginForm onSuccess={handleLoginSuccess} />}
              {step === 1 && (
                <TwoFactor
                  credentials={credentials}
                  onSuccess={handleTwoAuthSuccess}
                  // email={credentials.email}
                  // toFactor={toFactor}
                />
              )}
            </div>
          </Grid>
          <Grid
            item
            lg={8}
            md={7}
            sm={4}
            className={classes.backgroundImage}
            style={{
              backgroundImage: 'url(/assets/login/background.jpeg)',
            }}
          />
        </Grid>
      )}
    </>
  );
}

// eslint-disable-next-line import/prefer-default-export
export { SalesLogin };
