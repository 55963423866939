/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Grid,
  TextField as MuiTextField,
  Typography,
  InputAdornment,
  makeStyles,
} from '@material-ui/core';

import cardFees from '../defaultStates/cardFees';
import { NumberFormatCustom } from '../../NumberFormatCustom';
import { operatorFees } from '../defaultStates/productDetails';

function TextField(props) {
  return <MuiTextField variant="outlined" size="small" fullWidth {...props} />;
}

const useStyles = makeStyles({
  commissions: {
    background: 'white',
  },
});

function TransferRight({ form }) {
  const {
    register,
    setValue,
    formState: { errors },
    getValues,
  } = form;
  const classes = useStyles();
  const { t } = useTranslation();

  const [rejectedTransactionFee, setRejectedTransactionFee] = useState(
    getValues('companyOperatingFees.rejectedTransactionFee') || '',
  );
  const [irFee, setIrFee] = useState(
    getValues('companyOperatingFees.ir_fee') || '',
  );
  const [minimumLoadAmount, setMinimunLoadAmount] = useState(
    getValues('companyOperatingFees.minimumLoadAmount') || '',
  );
  const [belowMinimumLoadFee, setBelowMinimumLoadFee] = useState(
    getValues('companyOperatingFees.belowMinimumLoadFee') || '',
  );
  const handleChange = event => {
    // event.preventDefault();
    const value = `${event.target.value}`;
    setRejectedTransactionFee(value);
  };

  const handleChangeFee = event => {
    const value = `${event.target.value}`;
    setIrFee(value);
  };

  const handleChangeMinimum = event => {
    const value = `${event.target.value}`;
    setMinimunLoadAmount(value);
  };
  const handleChangeMinimumLoadFee = event => {
    const value = `${event.target.value}`;
    setBelowMinimumLoadFee(value);
  };

  return (
    <Grid className={classes.commissions} item lg={6} md={6} sm={12} xs={12}>
      <Box
        p={{ xs: 3, sm: 3, md: 4, lg: 5 }}
        mb={{ xs: 3, sm: 3, md: 4, lg: 5 }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              color="primary"
              paragraph
              align="center"
            >
              {t('newContract.product.label1')}
            </Typography>
          </Grid>
          <Grid item lg={4} md={4} sm={3} xs={6}>
            <TextField
              error={!!errors.companyOperatingFees?.KYC}
              helperText={
                errors.companyOperatingFees?.KYC?.message ||
                t('newContract.product.cRejectTransferFee')
              }
              value={rejectedTransactionFee}
              onChange={handleChange}
              InputProps={{
                inputComponent: NumberFormatCustom,
                inputProps: {
                  ...register('companyOperatingFees.rejectedTransactionFee'),
                },
              }}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={3} xs={6}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              error={!!errors.companyOperatingFees?.ir_fee}
              helperText={errors.companyOperatingFees?.ir_fee?.message || 'DCP'}
              value={irFee}
              onChange={handleChangeFee}
              // onClick={handleChangeFee}
              InputProps={{
                inputComponent: NumberFormatCustom,
                inputProps: {
                  ...register('companyOperatingFees.ir_fee'),
                },
              }}
            />
          </Grid>
          {/* NOVOS CAMPOS */}
          <Grid item lg={4} md={4} sm={3} xs={6}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              error={!!errors.companyOperatingFees?.minimumLoadAmount}
              helperText={
                errors.companyOperatingFees?.minimumLoadAmount?.message ||
                'Valor de Transferência Mínima'
              }
              value={minimumLoadAmount}
              onChange={handleChangeMinimum}
              // onChange={handleChange('companyOperatingFees.minimumLoadAmount')}
              InputProps={{
                inputComponent: NumberFormatCustom,
                inputProps: {
                  ...register('companyOperatingFees.minimumLoadAmount'),
                },
              }}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={3} xs={6}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              error={!!errors.companyOperatingFees?.belowMinimumLoadFee}
              helperText={
                errors.companyOperatingFees?.belowMinimumLoadFee?.message ||
                'Transferência inferior ao Mínimo'
              }
              value={belowMinimumLoadFee}
              onChange={handleChangeMinimumLoadFee}
              // onChange={handleChange(
              //   'companyOperatingFees.belowMinimumLoadFee',
              // )}
              InputProps={{
                inputComponent: NumberFormatCustom,
                inputProps: {
                  ...register('companyOperatingFees.belowMinimumLoadFee'),
                },
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
}

export default TransferRight;
