/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import api from '../../../../service/axios/api';
// import { NextPageContext } from 'next';

import ProposalForm from '../../components/forms/ProposalForm';
// import SpinnerPage from '../../components/SpinnerPage';
import defaultProposal from '../../components/forms/defaultStates/proposal';

const useStyles = makeStyles(theme => ({
  header: {
    marginBottom: theme.spacing(2),
  },
}));

function CreateProposal() {
  const router = useHistory();
  const classes = useStyles();
  const { id } = useParams();
  const [proposal, setProposal] = useState();

  const tmp = {
    id: 2,
    cnpj: '16983156000170',
    company_name: 'IT Fast Consultoria em TI LTDA',
    trading_name: 'IT Fast',
    website: 'www.itfast.com.br',
    email: 'contato@itfast.com.br',
    phone: '1236002524',
    mobile: null,
    created_at: '2022-11-04T17:37:06.000Z',
    updated_at: '2022-11-04T17:37:06.000Z',
    origin: 'web',
    status: 'Pending',
    LeadContacts: [
      {
        id: 1,
        lead_id: '2',
        name: 'Glaydson Bertozzi',
        cpf: '11122233344455',
        role: 'CEO',
        department: '',
        phone: '12999999999',
        mobile: '',
        email: 'glaydson@itfast.com.br',
        created_at: '2022-11-04T17:37:07.000Z',
        updated_at: '2022-11-04T17:37:07.000Z',
      },
      {
        id: 5,
        lead_id: '2',
        name: 'Willian Martoszat',
        cpf: '11111111111',
        role: 'CTO',
        department: 'Desenvolvimento',
        phone: '12999999999',
        mobile: '12999999999',
        email: 'willian@itfast.com.br',
        created_at: '2022-11-04T17:37:07.000Z',
        updated_at: '2022-11-04T17:37:07.000Z',
      },
    ],
  };

  useEffect(() => {
    if (id) {
      const cancel = new AbortController();
      api.campaign
        .getLeadById(cancel, id)
        .then(lead => {
          console.log(lead);
          const listTmp = {};
          listTmp.tradingName = lead.docs[0].trading_name;
          listTmp.cnpj = lead.docs[0].cnpj;
          listTmp.companyName = lead.docs[0].company_name;
          listTmp.status = lead.docs[0].status;
          listTmp.email = lead.docs[0].email;
          listTmp.phone = lead.docs[0].phone;
          listTmp.mobile = lead.docs[0].mobile;
          listTmp.website = lead.docs[0].website;
          listTmp.comments = lead.docs[0].comments;
          listTmp.campaign = { label: 'Campanha1', value: '1' };
          listTmp.contacts = lead.docs[0].LeadContacts;

          setProposal(listTmp);
        })
        .catch(error => {
          console.log(error);
        });
    }
  }, []);
  // const query = firestore.collection('proposalRequests').doc(id);
  // const [proposalRequest, loading] =
  //   useDocumentDataOnce < ProposalRequest > query;

  // if (loading) {
  //   return <SpinnerPage />;
  // }

  // if (!proposalRequest) {
  //   router.push('/dashboard/proposals');
  //   return <SpinnerPage />;
  // }
  const proposalRequest = {
    cnpj: '99999999999999',
    companyLegalName: 'Itfast',
    mobile: '12996023366',
    phone: '1236261116',
    email: 'email@email.com.br',
    contactFullName: 'Genoveva',
    companyArea: 'TI',
    companyRole: 1,
    product: 'card',
    paymentFrequency: 'mensal',
    totalIncome: '100',
    commercialAgentsQuantity: 1,
  };

  // const proposal = {
  //   tradingName: '',
  //   cnpj: '',
  //   companyName: '',
  //   status: '',
  //   email: '',
  //   phone: '',
  //   mobile: '',
  //   website: '',
  //   comments: '',
  //   campaign: { label: 'Campanha', value: null },
  //   contacts: [
  //     {
  //       name: '',
  //       cpf: '',
  //       role: '',
  //       phone: '',
  //       mobile: '',
  //       email: '',
  //       department: '',
  //     },
  //   ],
  // };

  // const proposal = {
  //   company: {
  //     cnpj: '',
  //     legalName: '',
  //     mobile: '',
  //     phone: '',
  //     email: '',
  //     admin: {
  //       fullName: '',
  //       companyArea: '',
  //       companyRole: '',
  //       mobile: '',
  //       phone: '',
  //       email: '',
  //     },
  //   },
  //   productDetails: {
  //     product: '',
  //     campaign: {
  //       rechargeFrequency: '',
  //       annualEstimatedAward: '',

  //       administrationRate: '5.00',
  //     },
  //     cardQuantity: '',
  //   },
  // };

  // const onSuccess = prop => axios.post('/api/proposals', prop);
  const onSuccess = prop => api.oportunity.update(id, prop);

  const callback = () => {
    router.push('/sales/proposals');
  };

  return (
    <>
      <Grid
        className={classes.header}
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Typography variant="h4" component="h2" gutterBottom>
          Nova oportunidade
        </Typography>
      </Grid>
      {proposal && (
        <ProposalForm
          proposal={proposal}
          onSuccess={onSuccess}
          code={200}
          titleModal="Oportunidade inserida com sucesso"
          actionModal={callback}
        />
      )}
    </>
  );
}

export const getServerSideProps = async context => {
  const id = context.query.proposalRequestId;

  return { props: { id } };
};

export default CreateProposal;
