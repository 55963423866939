/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Menu from '@material-ui/core/Menu';
import { useTranslation } from 'react-i18next';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import { FaEdit } from 'react-icons/fa';

import {
  GridToolbarContainer,
  GridColumnsToolbarButton,
  GridFilterToolbarButton,
} from '@material-ui/data-grid';
import useContextMenu from '../../sales/hooks/useContextMenu/useContextMenu';

function CustomToolbar({ selectedFixed, onClickEdit }) {
  const { t } = useTranslation();
  const [selected, setSelected] = useState(false);
  const { state, onOpen, onClose } = useContextMenu();
  const onClick = e => {
    onOpen(e);
    setSelected(!selected);
  };
  return (
    <>
      <GridToolbarContainer>
        <GridColumnsToolbarButton />
        <GridFilterToolbarButton />
        {selectedFixed.length > 0 && (
          <Tooltip title="Editar" style={{ marginLeft: 'auto' }}>
            <Button
              variant="text"
              color="primary"
              startIcon={<FaEdit />}
              onClick={onClick}
            >
              {t('tutorials.new.table.status')}
            </Button>
          </Tooltip>
        )}
      </GridToolbarContainer>
      {selected && (
        <Menu
          keepMounted
          open={state.mouseY !== null}
          onClose={onClose}
          anchorReference="anchorPosition"
          anchorPosition={
            state.mouseY !== null && state.mouseX !== null
              ? { top: state.mouseY, left: state.mouseX }
              : undefined
          }
        >
          {/* <MenuItem onClick={() => onClickEdit('Programado')}>
            <Typography>Programado</Typography>
          </MenuItem> */}

          <MenuItem onClick={() => onClickEdit('Publicado')}>
            <Typography>{t('tutorials.new.new.btnVisible')}</Typography>
          </MenuItem>
          <MenuItem onClick={() => onClickEdit('Oculto')}>
            <Typography>{t('tutorials.new.new.btnHide')}</Typography>
          </MenuItem>
        </Menu>
      )}
    </>
  );
}

export default CustomToolbar;
