/* eslint-disable react/prop-types */
import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

function BusinessModal({ open, onClose }) {
  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle>Detalhamento</DialogTitle>
      <Box mt={0} m={6}>
        <Grid container spacing={3}>
          <Grid item lg={4} sm={6} xs={12}>
            <Typography variant="subtitle1" color="initial">
              Razão Social:
            </Typography>
            <Typography variant="body2" color="initial">
              -
            </Typography>
          </Grid>
          <Grid item lg={4} sm={6} xs={12}>
            <Typography variant="subtitle1" color="initial">
              Valor NF:
            </Typography>
            <Typography variant="body2" color="initial">
              R$ X,00
            </Typography>
          </Grid>
          <Grid item lg={4} sm={6} xs={12}>
            <Typography variant="subtitle1" color="initial">
              Agentes / UF:
            </Typography>
          </Grid>
          <Grid item lg={4} sm={6} xs={12}>
            <Typography variant="subtitle1" color="initial">
              Taxa:
            </Typography>
            <Typography variant="body2" color="initial">
              0,00%
            </Typography>
          </Grid>
          <Grid item lg={4} sm={6} xs={12}>
            <Typography variant="subtitle1" color="initial">
              Comissão Agente:
            </Typography>
            <Typography variant="body2" color="initial">
              0,00%
            </Typography>
          </Grid>
          <Grid item lg={4} sm={6} xs={12} />
          <Grid item lg={4} sm={6} xs={12}>
            <Typography variant="subtitle1" color="initial">
              No. Cargas:
            </Typography>
            <Typography variant="body2" color="initial">
              X.XXX
            </Typography>
          </Grid>
          <Grid item lg={4} sm={6} xs={12}>
            <Typography variant="subtitle1" color="initial">
              Valor Comissão:
            </Typography>
            <Typography variant="body2" color="initial">
              R$ X,00
            </Typography>
          </Grid>
          <Grid item lg={4} sm={6} xs={12} />
        </Grid>
      </Box>
    </Dialog>
  );
}

export default BusinessModal;
