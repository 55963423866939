/* eslint-disable react/prop-types */
import React from 'react';
import Table from '../Table';

import { formatProposalStatus } from '../../util/helpers';

import // getProductName,
// getTradingName,
// getCompanyName,
// getCnpj,
// getCommercialAgent,
'./getters';

const columns = [
  {
    field: 'tradingName',
    headerName: 'Razão Social',
    // valueGetter: getTradingName,
    flex: 1,
  },
  {
    field: 'companyCnpj',
    headerName: 'CNPJ',
    flex: 1,
    // valueGetter: getCnpj,
  },
  {
    field: 'companyName',
    headerName: 'Nome',
    // valueGetter: getCompanyName,
    flex: 1,
  },
  // {
  //   field: 'agent',
  //   headerName: 'Agente Comercial',
  //   valueGetter: getCommercialAgent,
  //   flex: 1,
  // },
  // {
  //   field: 'productName',
  //   headerName: 'Produto',
  //   valueGetter: getProductName,
  //   flex: 1,
  // },
  // {
  //   field: 'proposalNumber',
  //   headerName: 'Proposta',
  //   flex: 1,
  // },
  {
    field: 'status',
    headerName: 'Status',
    width: 120,
    valueFormatter: params => formatProposalStatus(params.value),
  },
  {
    field: 'createdAt',
    headerName: 'Data',
    valueFormatter: params => {
      const { value } = params;
      return value;
    },
    width: 0,
  },
];

function ProposalsTable({ proposals, ...others }) {
  return (
    <Table
      columns={columns}
      rows={proposals}
      sortModel={[{ field: 'createdAt', sort: 'desc' }]}
      {...others}
    />
  );
}

export default ProposalsTable;
