/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, {useRef, useEffect} from 'react';

import readXlsxFile from 'read-excel-file';
// import Grid from '@mui/material/Grid';
// import Typography from '@mui/material/Typography';
// import TextField from '@mui/material/TextField';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
import Select from 'react-select';
import { styled, Grid } from '@material-ui/core';
import styles from '../../../styles/Layout.module.css';

const options = [
  {
    label: 'CONTA 01',
    value: 1,
  },
  {
    label: 'CONTA 02',
    value: 2,
  },
  {
    label: 'CONTA 03',
    value: 3,
  },
];

const customStyles = {
  control: provided => ({
    ...provided,
    width: '80vw',
    backgroundColor: 'none',
    // boxShadow: 'none',
    boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
    border: 'none',
    color: '#717171',
    fontWeight: 'bold',
    fontFamily: 'Open Sans',
    // fontSize: 24,
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  option: provided => ({
    ...provided,
    fontWeight: 'bold',
    fontFamily: 'Open Sans',
    color: '#717171',
    // color: state.data.color,
    // backgroundColor: state.data.bgcolor,
    // fontSize: 24,
  }),
  placeholder: () => ({
    fontWeight: 'bold',
    fontFamily: 'Open Sans',
    marginTop: -20,
    color: '#717171',
    // paddingBottom: 20,
    // fontSize: 24,
    // marginBottom: 30,
  }),
};



export default function CardExist({setMyfile, myFile, setOrders}) {
  const fileXls = useRef(null);

  useEffect(() => {
    if (myFile) {
      const dataTable = [];
      readXlsxFile(myFile).then(r => {
        const keys = r[0];
        r.forEach((data, i) => {
          if (i > 0) {
            dataTable.push({
              [keys[0]]: data[0],
              [keys[1]]: data[1],
              [keys[2]]: data[2],
              [keys[3]]: data[3],
              [keys[4]]: data[4],
              [keys[5]]: data[5],
              [keys[6]]: data[6],
              [keys[7]]: data[7],
              [keys[8]]: data[8],
              [keys[9]]: data[9],
              [keys[10]]: data[10],
              [keys[11]]: data[11],
              [keys[12]]: data[12],
              [keys[13]]: data[13],
            });
          }
        });
        setOrders(dataTable);
      });
    }
  }, [myFile]);

  return (
    // <div className="box-new-card">
    <Grid
          container
          spacing={1}
          // padding={1}
          style={{
            padding: 10,
            border: '2px solid #c5c5c5',
            borderRadius: 10,
          }}
        >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'start',
          alignItems: 'center',
        }}
      >
          <h6 className={styles.textBoxInform}>Realizar o pedido completo</h6>
      </div>
      <Grid item xs={12}>
      <h6 className={styles.labelGridSecond}>
        Escolha a Conta Digital
      </h6>
      </Grid>
      <Grid
        container
        spacing={1}
        direction="column"
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          padding: 40,
        }}
      >
        <Grid item>
          <Select
            fullWidth
            styles={customStyles}
            className="basic-single"
            classNamePrefix="select"
            placeholder="SELECIONE A CONTA DIGITAL"
            isSearchable={false}
            options={options}
          />
        </Grid>
      </Grid>
      <Grid container direction="row" style={{justifyContent: 'end', alignItems: 'end'}}>
        {/* <Grid item>
          <p className="label-planilhas" styles={{ cursor: 'pointer' }}>
            <img
              id="planilha"
              src="../img/download.svg"
              alt="logo"
              className="img-planilhas"
            />
            Modelo Planilha de Premiado
          </p>
        </Grid> */}
        <Grid item style={{ display: 'flex', justifyContent: 'end', alignItems: 'end'}}>
          <p
            className="label-planilhas"
            styles={{ cursor: 'pointer' }}
            onClick={() => fileXls.current.click()}
          >
            <img
              id="planilha"
              src="../img/upload.svg"
              alt="logo"
              className="img-planilhas"
            />
            Planilha de Premiado
          </p>
          
        </Grid>
      </Grid>
      <input
            ref={fileXls}
            type="file"
            style={{
              opacity: 0,
            }}
            // accept="media_type"
            onChange={() => setMyfile(fileXls.current.files[0])}
            className="form-control"
            id="inputNumSerie"
            placeholder="Arquivo DWG do projeto"
          />
    </Grid>
  );
}
