import * as React from 'react';

import { TextField } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    myTextField: {
      backgroundColor: 'none',
      boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
      border: 'none',
      color: '#717171',
      fontWeight: 'bold',
      fontFamily: 'Open Sans',
    },
    text: {
      fontFamily: 'Open Sans',
      fontWeight: 'bold',
      color: '#717171',
    },
  }),
);

export default function TaxCompany() {
  const classes = useStyles();
  return (
    <div className="box-new-card">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h6 className="text-box-inform">Tarifas Operacionais Empresa</h6>
        <h6 className="text-box-inform-2">COD.: PROD_TRANSF_002</h6>
      </div>
      <h6 className="text-label">Transação Nacional </h6>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: -5,
        }}
      >
        <div>
          <p className="text-select-label ">Transferência via TED</p>
          {/* <input className="myInput-tax" placeholder="R$ 00,00" /> */}
          <TextField
            classes={{ notchedOutline: classes.input }}
            className={classes.text}
            fullWidth
            id="outlined-basic"
            label="R$00.00"
            variant="outlined"
            size="small"
            // inputRef={search}
            InputProps={{
              classes: {
                notchedOutline: classes.myTextField,
                input: classes.text,
              },
            }}
          />
        </div>
        <div>
          <p className="text-select-label ">Transferência via PIX</p>
          <TextField
            classes={{ notchedOutline: classes.input }}
            className={classes.text}
            fullWidth
            id="outlined-basic"
            label="R$00.00"
            variant="outlined"
            size="small"
            // inputRef={search}
            InputProps={{
              classes: {
                notchedOutline: classes.myTextField,
                input: classes.text,
              },
            }}
          />
          {/* <input className="myInput-tax" placeholder="R$ 00,00" /> */}
        </div>
        <div>
          <p className="text-select-label ">Transferência via Rejeitada</p>
          {/* <input className="myInput-tax" placeholder="R$ 00,00" /> */}
          <TextField
            classes={{ notchedOutline: classes.input }}
            className={classes.text}
            fullWidth
            id="outlined-basic"
            label="R$00.00"
            variant="outlined"
            size="small"
            // inputRef={search}
            InputProps={{
              classes: {
                notchedOutline: classes.myTextField,
                input: classes.text,
              },
            }}
          />
        </div>
      </div>
      <h6 className="text-label" style={{ marginTop: 10 }}>
        Transação Internacional{' '}
      </h6>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: -10,
          marginBottom: 30,
        }}
      >
        <div>
          <p className="text-select-label ">Taxa Swift</p>
          {/* <input className="myInput-tax" placeholder="USD 00,00" /> */}
          <TextField
            classes={{ notchedOutline: classes.input }}
            className={classes.text}
            fullWidth
            id="outlined-basic"
            label="USD00.00"
            variant="outlined"
            size="small"
            // inputRef={search}
            InputProps={{
              classes: {
                notchedOutline: classes.myTextField,
                input: classes.text,
              },
            }}
          />
        </div>
        <div>
          <p className="text-select-label ">Free</p>
          {/* <input className="myInput-tax" placeholder="USD 00,00" /> */}
          <TextField
            classes={{ notchedOutline: classes.input }}
            className={classes.text}
            fullWidth
            id="outlined-basic"
            label="USD00.00"
            variant="outlined"
            size="small"
            // inputRef={search}
            InputProps={{
              classes: {
                notchedOutline: classes.myTextField,
                input: classes.text,
              },
            }}
          />
        </div>
        <div>
          <p className="text-select-label ">Câmbio</p>
          {/* <input className="myInput-tax" placeholder="1 USD = 0,00 BLR" /> */}
          <TextField
            classes={{ notchedOutline: classes.input }}
            className={classes.text}
            fullWidth
            id="outlined-basic"
            label="1 USD = 0,00 BLR"
            variant="outlined"
            size="small"
            // inputRef={search}
            InputProps={{
              classes: {
                notchedOutline: classes.myTextField,
                input: classes.text,
              },
            }}
          />
        </div>
      </div>
    </div>
  );
}
