/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';

import { Grid, TextField, Dialog } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import routing from '../../../service/routes/routing';
import MyButton from '../../../components/button/MyButton';
import styles from '../../../styles/Layout.module.css';
import Created from './Created';
import Loading from '../../../components/loading/Loading';
import api from '../../../service/axios/api';

// const emailOptions = [{
//   label: 'willian@itfast.com.br',
//   value: 'willian@itfast.com.br',
// },
// {
//   label: 'felipe@itfast.com.br',
//   value: 'felipe@itfast.com.br',
// },
// {
//   label: 'glaydson@itfast.com.br',
//   value: 'glaydson@itfast.com.br',
// },
// {
//   label: 'glaydson.bertozzi@gmail.com',
//   value: 'glaydson.bertozzi@gmail.com',
// },
// {
//   label: 'duda.bertozzi@gmail.com',
//   value: 'duda.bertozzi@gmail.com',
// },
// {
//   label: 'maju.bertozzi@gmail.com',
//   value: 'maju.bertozzi@gmail.com',
// }];

const customStyles = {
  control: provided => ({
    ...provided,
    // width: 200,
    backgroundColor: 'none',
    boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
    border: 'none',
    color: '#717171',
    fontWeight: 'bold',
    fontFamily: 'Open Sans',
    // fontSize: 24,
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  option: provided => ({
    ...provided,
    fontWeight: 'bold',
    fontFamily: 'Open Sans',
    color: '#717171',
    // color: state.data.color,
    // backgroundColor: state.data.bgcolor,
    // fontSize: 24,
  }),
  placeholder: () => ({
    fontWeight: 'bold',
    fontFamily: 'Open Sans',
    marginTop: -20,
    color: '#717171',
    // paddingBottom: 20,
    // fontSize: 24,
    // marginBottom: 30,
  }),
};

const useStyles = makeStyles(() =>
  createStyles({
    label: {
      fontWeight: 'bold',
      fontFamily: 'Open Sans',
      color: '#717171',
    },
    selected: {
      fontFamily: 'Open Sans',
      color: '#41b490',
      fontWeight: 'bold',
    },
    myTextField: {
      backgroundColor: 'none',
      boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
      border: 'none',
      color: '#717171',
      fontWeight: 'bold',
      fontFamily: 'Open Sans',
    },
    text: {
      fontFamily: 'Open Sans',
      fontWeight: 'bold',
      color: '#717171',
    },
  }),
);

export default function NewClientsGroup() {
  const classes = useStyles();
  const history = useHistory();
  const fixedValue = {
    label: 'Selecione os e-mails já cadastrados',
    value: 'Selecione os e-mails já cadastrados',
  };
  const [emailSelected, setEmailSelected] = useState([]);
  const [emailCreated, setEmailCreated] = useState([]);
  const [handleEmail, setHandleEmail] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [emailOptions, setEmailOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const groupName = useRef(null);

  useEffect(() => {
    const cancel = new AbortController();
    api.user
      .getEmails(cancel)
      .then(res => {
        // console.log(res);
        const list = [];
        res.retorno.forEach(data => {
          // console.log(data);
          list.push({
            label: data.email,
            value: Number(data.id),
          });
        });
        setEmailOptions(list);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const mailSelected = e => {
    const found = emailOptions.indexOf(
      emailOptions.find(v => v.value === e.value),
    );
    emailOptions.splice(found, found + 1);

    if (emailSelected.length > 0) {
      setEmailSelected([...emailSelected, e]);
    } else {
      setEmailSelected([e]);
    }
  };

  const newEmail = () => {
    if (emailCreated.length > 0) {
      setEmailCreated([...emailCreated, handleEmail]);
    } else {
      setEmailCreated([handleEmail]);
    }
    setHandleEmail('');
  };

  useEffect(() => {
    const cancel = new AbortController();
    api.notification
      .getgroup(cancel)
      .then(res => console.log(res))
      .catch(err => console.log(err));
  }, []);

  async function userToGroup(userId, groupId) {
    await api.notification.linkUserToGroup(userId, groupId);
  }

  const handleCreate = () => {
    if (emailSelected.length > 0 && groupName.current.value) {
      setLoading(true);
      api.notification
        .newgroup(groupName.current.value)
        .then(res => {
          for (const user in emailSelected) {
            userToGroup(emailSelected[user].value, res.retorno.id);
          }
          setLoading(false);
          setOpenModal(true);
        })
        .catch(err => {
          setLoading(false);
          console.error(err);
        });
    } else {
      console.log('FALTA DADOS');
    }
  };

  return (
    <>
      {/* <Loading open={loading} msg="Buscando usuários..." /> */}
      <div className="col-sm-12" style={{ marginTop: 50 }}>
        <p
          className={styles.textMenu}
          onKeyDown={() => history.push(routing.dashboard)}
          onClick={() => history.push(routing.dashboard)}
        >
          Menu Inicial
        </p>
        <div
          className="col-sm-12"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <h4 className={styles.textTitle}>Novo grupo de clientes</h4>
        </div>
        {/* <div
        className="col-sm-12"
        style={{
          display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center',
        }}
      > */}
        <div>
          <Grid
            container
            spacing={1}
            style={{
              justifyContent: 'center',
              padding: 40,
              alignItems: 'center',
            }}
          >
            <Loading open={loading} msg="Criando grupo de usuários" />
            {/* <TextField fullWidth id="outlined-basic"
             label="Nome do grupo" variant="outlined" size="small" /> */}
            <Grid item xs={12}>
              {/* <TextField
                inputRef={groupName}
                inputProps={{ className: classes.label }}
                InputLabelProps={{ className: classes.label }}
                fullWidth
                id="outlined-basic"
                label="Nome do grupo"
                variant="outlined"
                size="small"
              /> */}
              <TextField
                className={classes.text}
                fullWidth
                label="Nome do Grupo"
                variant="outlined"
                size="small"
                inputRef={groupName}
                InputProps={{
                  classes: {
                    notchedOutline: classes.myTextField,
                    input: classes.text,
                  },
                }}
              />
            </Grid>
            {/* <Grid item xs={12} sm={6}>
              <TextField fullWidth id="outlined-basic" label="Nome do
              grupo" variant="outlined" size="small" />
            </Grid> */}
            {/* <Grid container direction="row"> */}
            <Grid
              container
              spacing={1}
              style={{
                justifyContent: 'space-between',
                alignItems: 'start',
                marginTop: 15,
              }}
            >
              <Grid item xs={12} sm={5}>
                {/* <TextField fullWidth id="outlined-basic"
              label="Nome do grupo" variant="outlined" size="small" /> */}
                <Select
                  // width="300"
                  styles={customStyles}
                  value={fixedValue}
                  classNamePrefix="select"
                  placeholder="Selecione os e-mails já cadastrados"
                  onChange={(e, i) => mailSelected(e, i)}
                  isSearchable={false}
                  options={emailOptions}
                />
              </Grid>
              <Grid item xs={12} sm={5} style={{ paddingLeft: 50 }}>
                {/* <TextField
                inputProps={{ className: classes.label }}
                InputLabelProps={{ className: classes.label }}
                fullWidth
                id="outlined-basic"
                label="Cadastrar um novo e-mail"
                variant="outlined"
                size="small"
                value={handleEmail}
                onChange={e => setHandleEmail(e.target.value)}
              /> */}
                <TextField
                  className={classes.text}
                  fullWidth
                  label="Cadastrar um novo e-mail"
                  variant="outlined"
                  size="small"
                  value={handleEmail}
                  onChange={e => setHandleEmail(e.target.value)}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.myTextField,
                      input: classes.text,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={2} align="right">
                <MyButton
                  onClick={newEmail}
                  style={{ width: 50, height: 50 }}
                  label="+"
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={1}
              style={{
                justifyContent: 'center',
                alignItems: 'start',
                marginLeft: 10,
              }}
            >
              <Grid item xs={12} sm={5} align="left">
                {emailSelected.map((v, i) => (
                  <p key={i} className={classes.selected}>
                    {v.label}
                  </p>
                ))}
              </Grid>
              <Grid item xs={12} sm={5} align="left">
                {emailCreated.map((v, i) => (
                  <p key={i} className={classes.selected}>
                    {v}
                  </p>
                ))}
              </Grid>
              <Grid item xs={12} sm={2} align="right" />
            </Grid>
            {/* </Grid> */}
          </Grid>
        </div>
        <Grid
          container
          spacing={1}
          style={{
            justifyContent: 'end',
            padding: 40,
            alignItems: 'center',
          }}
        >
          <MyButton
            style={{ width: 200, height: 50 }}
            label="Salvar grupo de clientes"
            onClick={handleCreate}
          />
        </Grid>
      </div>
      <Dialog
        onClose={() => setOpenModal(false)}
        open={openModal}
        // width={1192}
        // height={776}
        fullWidth
        // maxWidth="1192"
      >
        <Created />
      </Dialog>
    </>
  );
}
