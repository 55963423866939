import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../../../components/button/MyButton';
import routing from '../../../service/routes/routing';

// eslint-disable-next-line react/prop-types
export default function Created() {
  const history = useHistory();
  return (
    <div
      className="col-sm-12"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 70,
      }}
    >
      <img
        src="./img/emailSend.svg"
        alt="ok"
        style={{ marginBottom: 30, width: 200, marginLeft: -40 }}
      />
      <h4 className="txt-category-success">Notificação enviada com sucesso!</h4>
      <Button
        style={{
          marginTop: 30,
          marginBottom: 50,
          marginRight: 20,
          height: 40,
          width: 179,
          fontSize: 24,
        }}
        onClick={() => history.push(routing.newNotifications)}
        label="Sair"
      />
    </div>
  );
}
