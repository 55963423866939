/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputAdornment,
  makeStyles,
  MenuItem,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { Controller, useForm } from 'react-hook-form';
import { FaCalendarDay } from 'react-icons/fa';
import routing from '../../../../service/routes/routing';
import RevenueSchema from '../../schemas/RevenueSchema';
import StepperContext from '../../context/StepperContext';

const useStyles = makeStyles(theme => ({
  form: {
    minHeight: '500px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  content: {
    padding: theme.spacing(4),
  },
  section: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(8),
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(4),
    background: '#f0f0f0',
    width: '100%',
    '& > *': {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },
}));

const services = [
  {
    company_id: 1,
    description: 'Promoção de vendas',
    id: 1,
    service_code: '73.19-0-02',
  },

  {
    company_id: 1,
    description:
      'Atividades de Intermediação e agenciamento de serviços e negócios em geral, exceto imobiliários',
    id: 2,
    service_code: '74.90-1-04',
  },
  {
    company_id: 1,
    description: 'Consultoria em Publicidade',
    id: 3,
    service_code: '73.19-0-04',
  },
  {
    company_id: 1,
    description:
      'Desenvolvimento e licenciamento de programas de computador customizáveis',
    id: 4,
    service_code: '62.02-3-00',
  },
  {
    company_id: 1,
    description:
      'Desenvolvimento e licenciamento de programas de computador customizáveis',
    id: 5,
    service_code: '62.02-3-00',
  },
  {
    company_id: 1,
    description:
      'Desenvolvimento e licenciamento de programas de computador não-customizáveis',
    id: 6,
    service_code: '62.03-1-00',
  },
  {
    company_id: 1,
    description: 'Administração de cartões de crédito',
    id: 7,
    service_code: '66.13-4-00',
  },
  {
    company_id: 1,
    description: 'Marketing direto',
    id: 8,
    service_code: '73.19-0-99',
  },
];

const banks = {
  1: {
    id: 2,
    name: 'Santander',
    branch_number: '3832',
    bank_code: '033',
  },
  2: {
    id: 1,
    name: 'Itaú',
    branch_number: '8576',
    bank_code: '341',
  },
};

const bankAccounts = [
  {
    id: 1,
    bank_id: 2,
    account_number: '13390-7',
    account_holder: 'Element Marketing e Participações Eireli',
    company_id: 1,
  },
  {
    id: 2,
    bank_id: 1,
    account_number: '280062348',
    account_holder: 'Binary Groups',
    company_id: 3,
  },
  {
    id: 3,
    bank_id: 2,
    account_number: 'à definir',
    account_holder: 'Element Marketing e Participações Eireli',
    company_id: 2,
  },
  {
    id: 4,
    bank_id: 2,
    account_number: '1333232-7',
    account_holder: 'Binary Groups Tech',
    company_id: 3,
  },
];

const issuingCompanies = [
  {
    cnpj: '05401489000127',
    name: ' Matriz - Element Marketing e Participações Eireli',
    id: 1,
  },
  /*
  {
    cnpj: '05401489000399',
    name: 'Rio - Element Marketing e Participações Eireli',
    id: 2,
  },

  {
    cnpj: '56791489000399',
    name: 'Binary Groups',
    id: 3,
  },
  */
];

const renderTextField = props => {
  const { InputProps, inputRef, ...others } = props;

  return (
    <TextField
      {...others}
      fullWidth
      ref={inputRef}
      type="text"
      size="small"
      variant="outlined"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <FaCalendarDay />
          </InputAdornment>
        ),
        ...InputProps,
      }}
    />
  );
};

function RevenueFormViewer({ revenue }) {
  const history = useHistory();
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: revenue,
    resolver: yupResolver(RevenueSchema),
  });
  const classes = useStyles();
  const stepper = useContext(StepperContext);
  const { t } = useTranslation();

  const submit = data => {
    stepper.next({
      revenue: {
        ...data,
      },
    });
  };

  const isSigned = watch('isSigned');
  const isInvoiceTopup = watch('isInvoiceTopup');
  const issuingCompanyId = watch('issuingCompanyId');

  return (
    <form className={classes.form} onSubmit={handleSubmit(submit)}>
      <Container className={classes.content}>
        <Typography variant="h5" component="h3" gutterBottom>
          {t('newContract.revenue.label')}
        </Typography>
        <Box bgcolor="background.gray" p={{ xs: 4, sm: 6, md: 7, lg: 10 }}>
          <Grid container spacing={5}>
            <Grid item lg={9} md={8} sm={6} xs={12}>
              <FormControl
                component="fieldset"
                size="small"
                error={!!errors.calculation}
              >
                <FormLabel component="legend">
                  {t('newContract.revenue.calculation')}
                </FormLabel>
                <Controller
                  control={control}
                  name="calculation"
                  render={props => (
                    <RadioGroup
                      value={props.field.value}
                      onChange={(_, value) => props.field.onChange(value)}
                      aria-label="Cálculo"
                      row
                    >
                      <FormControlLabel
                        value="normal"
                        control={<Radio disabled />}
                        label={t('newContract.revenue.normal')}
                      />
                      <FormControlLabel
                        value="inverted"
                        control={<Radio disabled />}
                        label={t('newContract.revenue.inverted')}
                      />
                    </RadioGroup>
                  )}
                />
                <FormHelperText>{errors.calculation?.message}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <Controller
                control={control}
                name="currency"
                render={props => (
                  <TextField
                    onChange={e => props.field.onChange(e.target.value)}
                    value={props.field.value}
                    fullWidth
                    select
                    variant="outlined"
                    size="small"
                    label={t('newContract.revenue.currencyType')}
                    error={!!errors.currency}
                    helperText={errors.currency && errors.currency.message}
                    disabled
                  >
                    <MenuItem value="brl">BRL</MenuItem>
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <hr />
            </Grid>
            <Grid item xs={12}>
              <FormControl
                component="fieldset"
                size="small"
                error={!!errors.paymentMethod}
              >
                <FormLabel component="legend">
                  {t('newContract.revenue.paymentType')}
                </FormLabel>
                <Controller
                  control={control}
                  name="paymentMethod"
                  render={props => (
                    <RadioGroup
                      value={props.field.value}
                      onChange={(_, value) => props.field.onChange(value)}
                      aria-label="Forma de Pagamento"
                      row
                    >
                      <FormControlLabel
                        value="ticket"
                        control={<Radio disabled />}
                        label={t('newContract.revenue.ticket')}
                      />
                      <FormControlLabel
                        value="transfer"
                        control={<Radio disabled />}
                        label={t('newContract.revenue.transfer')}
                      />
                    </RadioGroup>
                  )}
                />
                <FormHelperText>{errors.paymentMethod?.message}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <FormControlLabel
                label={t('newContract.revenue.note')}
                labelPlacement="start"
                control={
                  <Controller
                    control={control}
                    name="isInvoiceTopup"
                    render={props => (
                      <Switch
                        onChange={e => props.field.onChange(e.target.checked)}
                        checked={props.field.value}
                        color="primary"
                        disabled
                      />
                    )}
                  />
                }
              />
            </Grid>
            <Grid item xs={12}>
              <hr />
            </Grid>

            {isSigned && (
              <>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Controller
                    control={control}
                    name="signatureDate"
                    render={props => (
                      <DatePicker
                        label="Data de assinatura"
                        TextFieldComponent={renderTextField}
                        onChange={props.field.onChange}
                        value={props.field.value}
                        disabled
                      />
                    )}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Controller
                    control={control}
                    name="expirationDate"
                    render={props => (
                      <DatePicker
                        label="Data de término do contrato"
                        TextFieldComponent={renderTextField}
                        onChange={props.field.onChange}
                        value={props.field.value}
                        disabled
                      />
                    )}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </Container>
      <div className={classes.footer}>
        <Button
          onClick={() => history.push(routing.allContracts)}
          type="button"
          variant="contained"
        >
          Voltar
        </Button>
        {/* <Button type="submit" variant="contained" color="primary">
          Avançar
        </Button> */}
      </div>
    </form>
  );
}

export default RevenueFormViewer;
