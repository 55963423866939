/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-undef */
import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from '../../styles/Layout.module.css';
import routing from '../../service/routes/routing';
import TableFidelity from '../../components/tables/fidelity/TableFidelity';
import api from '../../service/axios/api';
import Loading from '../../components/loading/Loading';
import MyButton from '../../components/button/MyButton';

export default function Extrac() {
  const history = useHistory();
  const { t } = useTranslation();

  const [orders, setOrders] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [itens, setItens] = useState({
    label: '25',
    value: 25,
  });
  const [orderBy, setOrderBy] = useState({ label: 'status', value: '' });
  const [loading, setLoading] = useState(false);

  const [status, setStatus] = useState({ label: '', value: '' });
  const [product, setProduct] = useState({ label: '', value: '' });
  const search = useRef(null);
  const [chipData, setChipData] = React.useState([]);

  const find = extra => {
    setOrders([]);
    const cancel = new AbortController();
    setLoading(true);
    api.gm
      .get(
        // search?.current?.value,
        extra,
        chipData,
        status.value,
        itens.value,
        page,
        orderBy.value,
        product.value,
        cancel,
      )
      .then(res => {
        console.log(res);
        setTotalPages(res.pages);
        setOrders(res.docs);
        // setOrders([
        //   {
        //     id: '1',
        //     date: new Date(),
        //     service: 'POOOO01',
        //     nf: 'NFB01649',
        //     description: 'Manutenção',
        //     cpf: '333.333.333-33',
        //     name: 'Geraldo',
        //     vlr: 'R$1200,00',
        //     status: 'AwaitingPayment',
        //   },
        // ]);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    find();
  }, [page, itens, status, orderBy, product]);

  return (
    <div className="col-sm-12" style={{ marginTop: 50 }}>
      <Loading open={loading} msg="Buscando pedidos..." />
      <div
        className="col-sm-12"
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'start',
        }}
      >
        <div>
          <p
            className={styles.textMenu}
            onKeyDown={() => history.push(routing.fidelitydashboard)}
            onClick={() => history.push(routing.fidelitydashboard)}
          >
            {t('Order.initialMenu')}
          </p>
        </div>
      </div>
      <div
        className="col-sm-12"
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <h4 className={styles.textTitle}>Extrato fidelidade</h4>
      </div>
      <div>
        <TableFidelity
          orders={orders}
          totalPages={totalPages}
          page={page}
          setPage={setPage}
          setOrderBy={setOrderBy}
          itens={itens}
          setItens={setItens}
          setStatus={setStatus}
          product={product}
          setProduct={setProduct}
          chipData={chipData}
          setChipData={setChipData}
          find={find}
        />
      </div>
      {/* <MyButton label="Download em Excel" /> */}
    </div>
  );
}
