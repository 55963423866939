export default {
  filesAcesso: '/filesAcesso',
  filesCnab: '/filesCnab',
  process: '/filaAcesso',
  extract: '/extract',
  banks: '/banks',
  administration: '/administration',
  myacessory: '/productAcessory',
  login: '/login',
  dcp: '/dcp',
  admindcps: '/adminDcp',
  reports: '/reports',
  tutorial: '/tutorials',
  salesLogin: '/sales/Login',
  salesDash: '/sales/dashboard',
  salesOpportunity: '/sales/proposals',
  salesExtracts: '/sales/extracts',
  salesAllContracts: '/sales/contracts',
  salesBusiness: '/sales/Business',
  salesOpportunityNew: '/sales/proposals/create',
  salesPublicLead: campaign => `/sales/proposals/public/${campaign}`,
  salesLeadToOpportunity: id => `/sales/proposals/create/${id}`,
  salesCampaign: '/sales/campaign',
  salesCampaignNew: '/sales/campaing/create',
  salesBankAccount: '/sales/bankAccount',
  salesCampaignEdit: id => `/sales/campaign/${id}/edit`,
  salesContract: '/sales/contracts',
  salesRemember: '/sales/sendRemember',
  salesSendInvoice: '/sales/sendInvoice',
  salesContractNew: '/sales/contracts/create',
  contracts: '/contracts',
  allContracts: '/allContracts',
  agentContract: '/agentContracts',
  agentPayment: '/agentPayment',
  dashboard: '/',
  painel: '/dashboard',
  painelc: '/dashboardclient',
  forgotPassword: '/fortPassword',
  orders: '/orders',
  cardsAdmin: '/cardsAdmin',
  allCardsAdmin: '/allCardsAdmin',
  customized: '/customized',
  ordersAdmin: '/ordersAdmin',
  orderDetails: id => `/orderDetails/${id}`,
  product: '/product',
  newProduct: '/newProduct',
  newContract: '/newContract',
  ContractEdit: id => `/contractEdit/${id}`,
  ContractAddCompany: id => `/contract/add/${id}`,
  ContractRemoveCompany: id => `/contract/remove/${id}`,
  contractDuplicate: id => `/contractDuplicate/${id}`,
  contractViewer: id => `/contractViewer/${id}`,
  typeProduct: type => `/typeProduct/${type}`,
  typeCategory: type => `/typeCategory/${type}`,
  notifications: '/notifications',
  newClientsGroup: '/clientsgroup',
  newNotifications: '/newNotifications',
  newEmailNotifications: '/newEmailNotifications',
  newEmailTextNotifications: '/newEmailTextNotifications',
  // savedNotification: '/savedNotification',
  creditCard: '/creditCard',
  account: '/account',
  transfer: '/transfer',
  users: '/users',
  usersDetails: id => `/userDetails/${id}`,
  profile: '/profile',
  rps: '/rps',
  bankly: '/banklysends',
  moviment: '/moviment',
  baseCards: '/baseCards',
  releaseMoney: '/releaseMoney',
  permissions: '/permissions',
  fidelityextract: '/fidelity/extract',
  fidelitycontract: '/fidelity/contract',
  fidelitydashboard: '/fidelity/dashboard',
  fidelityusers: '/fidelityusers',
  settings: '/settings',
  companybase: '/companybase',
};
