/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Table from './Table';
// import Business from '@/types/Business';

const columns = [
  {
    field: 'invoice_owner_name',
    headerName: 'Razão Social',
    flex: 1,
  },
  {
    field: 'invoice_number',
    headerName: 'N°NF',
    flex: 1,
  },
  {
    field: 'invoice_issue_date',
    headerName: 'Emissão NF',
    flex: 1,
  },
  {
    field: 'invoice_payment_date',
    headerName: 'Pagamento NF',
    flex: 1,
  },
  {
    field: 'invoice_price',
    headerName: 'Valor NF',
    flex: 1,
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
  },
];

function BusinessTable({ business, onRowClick }) {
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [itens, setItens] = useState({ label: '25', value: 25 });
  const [orderBy, setOrderBy] = useState({ label: 'status', value: '' });
  const [status, setStatus] = useState({ label: '', value: '' });
  const [chipData, setChipData] = useState([]);

  const [select, setSelect] = useState([]);

  const searchItems = () => {
    console.log('BUSCAR');
  };

  return (
    <Table
      orders={business}
      totalPages={totalPage}
      page={page}
      itens={itens}
      setItens={setItens}
      setPage={setPage}
      setStatus={setStatus}
      chipData={chipData}
      setChipData={setChipData}
      searchItems={searchItems}
      setSelected={setSelect}
      selected={select}
      setOrderBy={setOrderBy}
      onRowClick={onRowClick}
    />
  );
}

export default BusinessTable;
