/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */

import React, { useState, useEffect, useRef, useContext } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import moment from 'moment-timezone';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import {
  // Tooltip,
  TablePagination,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Paper,
  styled,
  Checkbox,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from 'date-fns/locale';
// import GetAppIcon from '@material-ui/icons/GetApp';

// import { useExcelDownloder } from 'react-xls';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';

import Button from '@material-ui/core/Button';

import Select from 'react-select';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import Status from '../../service/translateStatus';
import Loading from '../../components/loading/Loading';

import FlashContext from '../../components/contexts/FlashContext';

import api from '../../service/axios/api';
import { banks } from '../../service/banks';

const customStylesEdit = {
  control: provided => ({
    ...provided,
    height: 40,
    borderRadius: 10,
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  option: provided => ({
    ...provided,
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
};

function createData(bank, cod, ag, account, owner, status, all) {
  // const density = population / size;
  return {
    bank,
    cod,
    ag,
    account,
    owner,
    status,
    all,
  };
}

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  table: {
    minWidth: 1100,
  },
});

const StyledTableCell = withStyles(() => ({
  head: {
    // backgroundColor: theme.palette.common.black,
    // color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark'
        ? 'rgba(57,75,89,.5)'
        : 'rgba(206,217,224,.5)',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#137cbd',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#106ba3',
  },
});

// eslint-disable-next-line no-unused-vars
export default function BanksTable({
  docs,
  page,
  setPage,
  itens,
  setItens,
  total,
  // status,
  // setStatus,
  selected,
  setSelected,
  companyOpt,
  search,
}) {
  const { notifyError, notifySuccess } = useContext(FlashContext);
  const { t } = useTranslation();
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [menuOptions, setMenuOptions] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  // const [selected, setSelected] = React.useState([]);
  const [company, setCompany] = useState();
  const [cnabCompanyname, setCnabCompanyname] = useState('');
  const [bank, setBank] = useState('');
  const [cnabBankname, setCnabBankname] = useState('');
  const [branchNumber, setBranchNumber] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [openBank, setOpenBank] = useState(false);
  const [status, setStatus] = useState();

  const [loadingC, setLoadingC] = useState(false);
  const isSelected = id => selected.indexOf(id) !== -1;

  const handleMenu = (row, event) => {
    setMenuOptions(row);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (docs) {
      const list = [];
      docs.forEach(d => {
        list.push(
          createData(
            d.bank,
            d.bank_code,
            d.branch_number,
            d.account_number,
            d.account_holder,
            d.status,
            d,
          ),
        );
      });
      setRows(list);
    }
  }, [docs]);

  const disableCheckbox = e => {
    let myret = true;
    switch (e) {
      case 'Error':
        myret = false;
        break;
      case 'Queued':
        myret = false;
        break;
      case 'AwaitingCardStatusCheck':
        myret = false;
        break;
      default:
        myret = true;
        break;
    }
    return myret;
  };
  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => {
        if (!disableCheckbox(n.status)) {
          return n.id;
        }
        return null;
      });
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleEdit = () => {
    const find = companyOpt.find(c => c.value === menuOptions?.all?.company_id);
    if (find) setCompany(find);
    const findBank = banks.find(b => b.value === menuOptions?.all?.bank_code);
    if (findBank) setBank(findBank);
    setCnabBankname(menuOptions?.all?.cnab_bank_name);
    setCnabCompanyname(menuOptions?.all?.cnab_company_name);
    setAccountNumber(menuOptions?.all?.account_number);
    setBranchNumber(menuOptions?.all?.branch_number);
    setStatus(
      menuOptions?.all?.status === 'Active'
        ? { label: 'Ativo', value: 'Active' }
        : { label: 'Cancelado', value: 'Canceled' },
    );
    setOpenBank(true);
  };

  const editAction = e => {
    e.preventDefault();
    setLoadingC(true);
    api.banks
      .edit(
        menuOptions?.all?.id,
        company,
        bank,
        branchNumber,
        accountNumber,
        cnabCompanyname,
        cnabBankname,
        status.value,
      )
      .then(res => {
        notifySuccess(res.message);
        setCompany();
        setBank();
        setBranchNumber('');
        setAccountNumber('0');
        setCnabBankname('');
        setCnabCompanyname('');
        setOpenBank(false);
        search();
      })
      .catch(err => {
        notifyError(err?.message);
      })
      .finally(() => {
        setLoadingC(false);
      });
  };

  return (
    <>
      <Paper className={classes.root}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Banco</StyledTableCell>
                <StyledTableCell>Cod</StyledTableCell>
                <StyledTableCell>Agência</StyledTableCell>
                <StyledTableCell>Número da conta</StyledTableCell>
                <StyledTableCell>Proprietário</StyledTableCell>
                <StyledTableCell align="right">Status</StyledTableCell>
                {/* <StyledTableCell>CNPJ</StyledTableCell>
              <StyledTableCell align="right">Valor Pago</StyledTableCell> */}
                {/* <StyledTableCell>Proxy</StyledTableCell>
              <StyledTableCell>Número do cartão</StyledTableCell>
              <StyledTableCell padding="checkbox">
                <Checkbox
                  checkedIcon={<BpCheckedIcon />}
                  icon={<BpIcon />}
                  indeterminate={
                    selected.length > 0 && selected.length < rows.length
                  }
                  checked={rows.length > 0 && selected.length === rows.length}
                  onChange={handleSelectAllClick}
                  inputProps={{
                    'aria-label': 'select all desserts',
                  }}
                />
              </StyledTableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, i) => {
                // const isItemSelected = isSelected(row.id);
                // const labelId = `enhanced-table-checkbox-${i}`;

                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <StyledTableRow key={i}>
                    <StyledTableCell>{row.bank}</StyledTableCell>
                    <StyledTableCell>{row.cod}</StyledTableCell>
                    <StyledTableCell>{row.ag}</StyledTableCell>
                    <StyledTableCell>{row.account}</StyledTableCell>
                    <StyledTableCell>{row.owner}</StyledTableCell>
                    <StyledTableCell align="right">
                      {row.status === 'Active' ? 'Ativo' : 'Cancelado'}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <MoreVertIcon
                        onClick={e => handleMenu(row, e)}
                        style={{ cursor: 'pointer' }}
                      />
                    </StyledTableCell>

                    {/* <StyledTableCell>{row.proxy}</StyledTableCell>
                  <StyledTableCell>{row.cardNumber}</StyledTableCell>
                  <StyledTableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      disabled={disableCheckbox(row.status)}
                      onClick={event => handleClick(event, row.id)}
                      checked={isItemSelected}
                      inputProps={{
                        'aria-labelledby': labelId,
                      }}
                    />
                  </StyledTableCell> */}
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Menu
          id="menu-appbar"
          style={{ borderRadius: '25px' }}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            className={classes.textNotification}
            onClick={() => {
              handleEdit();
              handleClose();
            }}
          >
            Editar
          </MenuItem>
        </Menu>
        {/* <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100, 1000, 50000]}
        component="div"
        count={total}
        rowsPerPage={itens}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      /> */}
      </Paper>
      <Dialog
        onClose={() => setOpenBank(!openBank)}
        open={openBank}
        fullWidth
        maxWidth="sm"
      >
        <Loading open={loadingC} msg="Salvando..." />
        <DialogTitle id="simple-dialog-title">Editar banco</DialogTitle>
        <form onSubmit={editAction}>
          <Grid
            container
            spacing={1}
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              padding: 20,
            }}
          >
            <Grid item xs={12} sm={12}>
              <Grid
                container
                spacing={1}
                style={{
                  paddingRight: 20,
                  paddingLeft: 20,
                }}
              >
                <Grid item xs={12} sm={6}>
                  <h6>Empresa relacionada</h6>
                  <Select
                    fullWidth
                    styles={customStylesEdit}
                    classNamePrefix="select"
                    placeholder="Empresa"
                    value={company}
                    onChange={setCompany}
                    // isSearchable={false}
                    options={companyOpt}
                    menuPortalTarget={document.body}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <h6>Status</h6>
                  <Select
                    fullWidth
                    styles={customStylesEdit}
                    classNamePrefix="select"
                    placeholder="Status"
                    value={status}
                    onChange={setStatus}
                    // isSearchable={false}
                    options={[
                      { label: 'Ativo', value: 'Active' },
                      { label: 'Cancelado', value: 'Canceled' },
                    ]}
                    menuPortalTarget={document.body}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <h6>Nome da empresa no CNAB</h6>
                  <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    type="text"
                    inputProps={{ maxLength: 30 }}
                    value={cnabCompanyname}
                    onChange={e => setCnabCompanyname(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Grid
                container
                spacing={1}
                style={{
                  paddingRight: 20,
                  paddingLeft: 20,
                }}
              >
                <Grid item xs={12} sm={6}>
                  <h6>Banco</h6>
                  <Select
                    fullWidth
                    styles={customStylesEdit}
                    classNamePrefix="select"
                    placeholder="Banco"
                    value={bank}
                    onChange={setBank}
                    // isSearchable={false}
                    options={banks}
                    menuPortalTarget={document.body}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <h6>Nome do banco no CNAB</h6>
                  <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    type="text"
                    inputProps={{ maxLength: 30 }}
                    value={cnabBankname}
                    onChange={e => setCnabBankname(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <h6>Agência</h6>
                  <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    type="number"
                    inputProps={{ maxLength: 10 }}
                    value={branchNumber}
                    onChange={e => setBranchNumber(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <h6>Conta</h6>
                  <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    type="text"
                    inputProps={{ maxLength: 20 }}
                    value={accountNumber}
                    onChange={e => setAccountNumber(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* </form> */}
          <DialogActions>
            <Button onClick={() => setOpenBank(false)} color="secondary">
              Cancelar
            </Button>
            <Button type="submit" color="primary" variant="contained" autoFocus>
              Salvar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
