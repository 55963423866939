/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../components/button/MyButton';

import './LoginInform.css';

const LoginInform = function (props) {
  const { t } = useTranslation();
  const { steep, setSteep, setOpen, msg } = props;

  const handleNext = () => {
    setSteep(steep + 1);
    setOpen(false);
  };
  return (
    <>
      {/* <div className="modalbranco">
      <p className="text-1">X</p>
      <div style={{
        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
      }}
      >
        <img src="./img/checkOk.png" alt="ok" className="img-2" />
        <p className="text-3">
          {t('CodSendBy')}
          {' '}
          {msg}
        </p>
        <div className="o-k">
          <Button
            style={{
              marginBottom: 20,
              height: 60,
              width: 238,
              fontSize: 26,
            }}
            label="Ok"
            onClick={handleNext}
          />
        </div>
      </div>
    </div> */}
      <div
        className="col-sm-12"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: 100,
          marginBottom: 100,
        }}
      >
        <img src="./img/checkOk.svg" alt="ok" className="img-category-check" />
        {/* <p className="txt-category-success">Produto criado com sucesso!</p> */}
        <h5 className="text-3" style={{ marginTop: 20 }}>
          {t('CodSendBy')} {msg}
        </h5>
        <Button
          style={{
            marginTop: 20,
            marginBottom: 20,
            marginRight: 20,
            height: 40,
            width: 129,
            // fontSize: 24,
          }}
          onClick={handleNext}
          label="OK"
        />
      </div>
    </>
  );
};

export default LoginInform;
