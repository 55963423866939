import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Button from '../../../components/button/MyButton';

const useStyles = makeStyles(() =>
  createStyles({
    secondText: {
      color: '#657588',
      marginTop: -10,
    },
  }),
);
// eslint-disable-next-line react/prop-types
export default function Created({ setOpenModal }) {
  const classes = useStyles();
  return (
    <div
      className="col-sm-12"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <img
        src="./img/checkOk.svg"
        alt="ok"
        className="img-category-check"
        style={{ width: '10%', height: '10%' }}
      />
      <h5 className="txt-category-success" style={{ marginTop: 30 }}>
        Usuário incluido com sucesso!
      </h5>
      <h6 className={classes.secondText}>
        As informações de login serão enviadas por e-mail
      </h6>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Button
          style={{
            marginBottom: 20,
            marginRight: 20,
            height: 40,
            width: 140,
            fontSize: 16,
          }}
          onClick={() => setOpenModal(false)}
          label="ok"
        />
      </div>
    </div>
  );
}
