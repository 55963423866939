/* eslint-disable no-unused-vars */
import * as React from 'react';
// import Switch from '@mui/material/Switch';
// import Radio from '@mui/material/Radio';
// import RadioGroup from '@mui/material/RadioGroup';
// import FormControl from '@mui/material/FormControl';
// import FormControlLabel from '@mui/material/FormControlLabel';

import { makeStyles } from '@material-ui/core/styles';

import {
  Switch,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  TextField,
} from '@material-ui/core';

import Select from 'react-select';

const options = [
  {
    label: 'MasterCard',
    value: 1,
  },
  {
    label: 'Visa',
    value: 2,
  },
  {
    label: 'elo',
    value: 3,
  },
];

const optionsE = [
  {
    label: 'Acesso',
    value: 'acesso',
  },
  {
    label: 'Matrix',
    value: 'matrix',
  },
  {
    label: 'Cateno',
    value: 'cateno',
  },
];

const useStyles = makeStyles(() => ({
  myTextField: {
    backgroundColor: 'none',
    boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
    border: 'none',
    color: '#717171',
    fontWeight: 'bold',
    fontFamily: 'Open Sans',
  },
  text: {
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
    color: '#717171',
  },
  mySwitch: {
    '&.MuiSwitch-colorPrimary.MuiSwitch-checked': {
      color: 'green',
    },
  },
  radio: {
    color: '#9c9c9c',
    '&$checked': {
      color: '#41b490',
    },
  },
  checked: {},
  switchBase: {
    color: '#fff',
    '&$checked': {
      color: '#41b490',
    },
    '&$checked + $track': {
      backgroundColor: '#41b490',
    },
  },
  track: {},
}));
// const customStyles = {
//   control: (provided, state) => ({
//     ...provided,
//     width: 220,
//     height: 50,
//     borderRadius: 10,
//     // backgroundColor: 'none',
//     // boxShadow: 'none',
//     // border: 'none',
//     fontWeight: 'bold',
//     fontFamily: 'Open Sans',
//     fontSize: 14,
//   }),
//   indicatorSeparator: styles => ({ display: 'none' }),
//   option: (provided, state) => ({
//     ...provided,
//     fontFamily: 'Open Sans',
//     fontSize: 14,
//   }),
//   placeholder: (provided, state) => ({
//     // fontWeight: 'bold',
//     fontFamily: 'Open Sans',
//     fontSize: 14,
//     color: '#9C9C9C',
//     // marginBottom: 30,
//   }),
// };

const customStyles = {
  control: provided => ({
    ...provided,
    // maxWidth: 240,
    backgroundColor: 'none',
    // boxShadow: 'none',
    boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
    border: 'none',
    color: '#717171',
    // fontWeight: 'bold',
    fontFamily: 'Open Sans',
    // fontSize: 24,
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  option: provided => ({
    ...provided,
    // fontWeight: 'bold',
    fontFamily: 'Open Sans',
    color: '#717171',
    // color: state.data.color,
    // backgroundColor: state.data.bgcolor,
    // fontSize: 24,
  }),
  placeholder: () => ({
    // fontWeight: 'bold',
    fontFamily: 'Open Sans',
    marginTop: -20,
    color: '#717171',
    // paddingBottom: 20,
    // fontSize: 24,
    // marginBottom: 30,
  }),
};

export default function TaxCompany() {
  const classes = useStyles();
  return (
    <div className="box-new-card">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h5 className="text-box-inform">Características da Conta Digital</h5>
        <p className="text-box-inform-2">COD.: PROD_CDPP_001</p>
      </div>
      <div className="my-row-align">
        <div className="my-colunm-align" style={{ marginTop: -15 }}>
          <div className="my-row-align">
            <div>
              <p className="text-select-label ">Cartão</p>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="gender"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    // className="myLabel"
                    value="chip"
                    control={
                      <Radio
                        classes={{
                          root: classes.radio,
                          checked: classes.checked,
                        }}
                      />
                    }
                    label="Virtual"
                  />
                  <FormControlLabel
                    // className="myLabel"
                    value="nfc"
                    control={
                      <Radio
                        classes={{
                          root: classes.radio,
                          checked: classes.checked,
                        }}
                      />
                    }
                    label="Físico"
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
          <div style={{ marginTop: -5 }}>
            <p className="text-select-label ">Bandeira</p>
            <Select
              styles={customStyles}
              className="basic-single"
              classNamePrefix="select"
              placeholder="Selecione a bandeira"
              isSearchable={false}
              options={options}
            />
          </div>
          <div style={{ marginBottom: 20 }}>
            <p className="text-select-label ">Emissor</p>
            <Select
              styles={customStyles}
              className="basic-single"
              classNamePrefix="select"
              placeholder="Selecione o emissor"
              isSearchable={false}
              options={optionsE}
            />
          </div>
        </div>
        <div className="my-colunm-align">
          <div className="my-row-align">
            <div>
              <p className="text-select-label ">Cobertura</p>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="gender"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    // className="myLabel"
                    value="nacional"
                    control={
                      <Radio
                        classes={{
                          root: classes.radio,
                          checked: classes.checked,
                        }}
                      />
                    }
                    label="Nacional"
                  />
                  <FormControlLabel
                    // className="myLabel"
                    value="internacional"
                    control={
                      <Radio
                        classes={{
                          root: classes.radio,
                          checked: classes.checked,
                        }}
                      />
                    }
                    label="Internacional"
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
          <div className="my-row-align" style={{ marginTop: -1 }}>
            <div>
              <p className="text-select-label ">Tecnologia</p>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="gender"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    // className="myLabel"
                    value="chip"
                    control={
                      <Radio
                        classes={{
                          root: classes.radio,
                          checked: classes.checked,
                        }}
                      />
                    }
                    label="Chip"
                  />
                  <FormControlLabel
                    // className="myLabel"
                    value="nfc"
                    control={
                      <Radio
                        classes={{
                          root: classes.radio,
                          checked: classes.checked,
                        }}
                      />
                    }
                    label="NFC"
                  />
                  <FormControlLabel
                    // className="myLabel"
                    value="tarja"
                    control={
                      <Radio
                        classes={{
                          root: classes.radio,
                          checked: classes.checked,
                        }}
                      />
                    }
                    label="Tarja"
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
        </div>
        <div className="my-colunm-align">
          <p className="text-select-label ">Personalização do cartão</p>
          <div className="my-row-align">
            <div>
              <Switch
                classes={{
                  switchBase: classes.switchBase,
                  checked: classes.checked,
                  track: classes.track,
                }}
                defaultChecked
              />
            </div>
            <div>
              {/* <input className="my-input-account" placeholder="R$00,00" /> */}
              <TextField
                className={classes.text}
                fullWidth
                label="R$00,00"
                variant="outlined"
                size="small"
                // inputRef={search}
                InputProps={{
                  classes: {
                    notchedOutline: classes.myTextField,
                    input: classes.text,
                  },
                }}
              />
            </div>
          </div>
          <p className="text-select-label ">Inatividade</p>
          <div className="my-row-align">
            <div>
              <Switch
                classes={{
                  switchBase: classes.switchBase,
                  checked: classes.checked,
                  track: classes.track,
                }}
              />
            </div>
            <div>
              <TextField
                className={classes.text}
                fullWidth
                label="R$00,00"
                variant="outlined"
                size="small"
                // inputRef={search}
                InputProps={{
                  classes: {
                    notchedOutline: classes.myTextField,
                    input: classes.text,
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
