/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState, useEffect, useContext } from 'react';
// http://localhost:3001/userDetails/2
import { Box, Stepper, StepLabel, Step, Container } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import styles from '../../../styles/Layout.module.css';
import FlashContext from '../../../components/contexts/FlashContext';

import Loading from '../../../components/loading/Loading';
import NewContractsTable from './NewContractsTable';

import api from '../../../service/axios/api';

import MyButton from '../../../components/button/MyButton';
import SubCompanies from './SubCompanies';

const useStyles = makeStyles(() =>
  createStyles({
    icon: {
      fill: '#41B490',
    },
    text: {
      fill: 'white',
    },
  }),
);

// eslint-disable-next-line react/prop-types
export default function VincContract({ setOpenModal, cardId, search }) {
  const { notifyError, notifySuccess } = useContext(FlashContext);
  const [load, setLoad] = useState(true);
  const [msg, setMsg] = useState('Buscando contratos....');
  const steps = ['Contract', 'Contract2'];
  const [activeStep, setActiveStep] = useState(0);
  const classes = useStyles();

  const [docs, setDocs] = useState([]);
  const selected = [];
  const finalSelected = [];
  const [subComp, setSubComp] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const findSubCompanies = () => {
    setMsg('Buscando empresas vinculadas...');
    setLoad(true);
    const cancel = new AbortController();
    const arrUnique = [...new Set(selected)];
    api.contract
      .getListSubCompanies(cancel, arrUnique)
      .then(response => {
        setSubComp(response.retorno);
        // setFilteredData(response.retorno);
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => setLoad(false));
  };

  const handleNext = () => {
    if (activeStep === 0 && selected.length > 0) {
      setActiveStep(activeStep + 1);
      if (activeStep === 0) {
        findSubCompanies();
      }
    } else if (activeStep === 1) {
      setMsg('Vinculando....');
      setLoad(true);
      const final = [...new Set(finalSelected)];
      api.cards
        .associateContracts(cardId, final)
        .then(res => {
          setOpenModal(false);
          notifySuccess('Contrato associado com sucesso');
          search();
        })
        .catch(err => {
          console.log(err);
          notifyError(err.message);
        })
        .finally(() => setLoad(false));
    } else {
      notifyError('Selecione pelo menos um contrato para contiuar');
    }
  };

  useEffect(() => {
    const cancel = new AbortController();
    setMsg('Buscando contratos...');
    setLoad(true);
    api.cards
      .getContracts(cancel, cardId)
      .then(myContracts => {
        const list = [];
        api.contract
          .getAll(cancel, true)
          .then(res => {
            res[0].data.forEach(data => {
              const find = myContracts.find(
                c => c.contract_id === Number(data.id),
              );
              if (!find) {
                list.push({
                  id: data.id,
                  cnpj: data.CNPJ,
                  campaign: data.campaign,
                  company_name: data.companyName,
                  group_name: data.groupName,
                  is_active: data.status,
                  product_type: 'Card',
                });
              }
            });
            res[1].data.forEach(data => {
              const find = myContracts.find(
                c => c.contract_id === Number(data.id),
              );
              if (!find) {
                list.push({
                  id: data.id,
                  cnpj: data.CNPJ,
                  campaign: data.campaign,
                  company_name: data.companyName,
                  group_name: data.group,
                  is_active: data.status,
                  product_type: 'Transfer',
                });
              }
            });
            setDocs(list);
            setFilteredData(list);
          })
          .catch(err => console.log(err))
          .then(() => setLoad(false));
        // r.forEach(iContract => {
        //   iContract.legalEntities.forEach(entities => {
        //     list.push(entities.LegalEntity);
        //   });
        // });
        // setContracts(list);
      })
      .catch(err => console.log(err));
  }, []);

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <NewContractsTable
            docs={docs}
            filteredData={filteredData}
            selected={selected}
            setFilteredData={setFilteredData}
          />
        );
      case 1:
        return <SubCompanies finalSelected={finalSelected} subComp={subComp} />;
      default:
        throw new Error('Unknown step');
    }
  }

  const handleBack = () => {
    if (activeStep === 0) {
      setOpenModal(false);
    } else {
      setActiveStep(activeStep - 1);
    }
  };

  return (
    <>
      <Loading open={load} msg={msg} />
      <Container component="main" width={1192} height={776}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Stepper activeStep={activeStep} style={{ width: 505 }}>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel
                  StepIconProps={{
                    classes: {
                      active: classes.icon,
                      text: classes.text,
                      completed: classes.icon,
                    },
                  }}
                />
              </Step>
            ))}
          </Stepper>
          <p
            className="x"
            style={{ cursor: 'pointer' }}
            onClick={() => setOpenModal(false)}
          >
            X
          </p>
        </div>
        <p className="text-checkout-inform">Vinculando contrato</p>
        <>
          {getStepContent(activeStep)}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: 50,
              marginBottom: 30,
            }}
          >
            <h6 className={styles.textExit} onClick={() => setOpenModal(false)}>
              Sair
            </h6>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              {activeStep > 0 && (
                <MyButton
                  style={{
                    width: 120,
                    height: 40,
                    borderRadius: 10,
                    backgroundColor: '#657588',
                  }}
                  onClick={handleBack}
                  label="Voltar"
                />
              )}

              <MyButton
                style={{
                  width: 120,
                  height: 40,
                  borderRadius: 10,
                  marginLeft: 30,
                }}
                onClick={handleNext}
                label={activeStep < 1 ? 'Continuar' : 'Associar'}
              />
            </Box>
          </div>
        </>
      </Container>
    </>
  );
}
