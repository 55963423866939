/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import api from '../../service/axios/api';

function PemissionComponent({ role, children }) {
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    async function loadRoles() {
      const response = [];
      if (api.currentUser) {
        if (api.currentUser.user.role) {
          response.push(api.currentUser.user.role.name);
          const findRole = response.some(r => role?.split(',').includes(r));
          setPermissions(findRole);
        }
      }
    }

    loadRoles();
  }, []);
  return <>{permissions && children}</>;
}

export default PemissionComponent;
