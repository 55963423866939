/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import Tooltip from '@material-ui/core/Tooltip';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { getAuth, RecaptchaVerifier, PhoneAuthProvider } from 'firebase/auth';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
// import Communication from '@/types/Communication';
import { object, string } from 'yup';
import api from '../../../service/axios/api';
import {
  TextFieldOutlined as TextField,
  InputLabel,
  HelperText,
} from '../components/common/TextFieldOutlined';
// import { TwoFactorSchema } from '@/schemas/AuthSchema';
import useStyles from '../styles/AuthForm.styles';

// type formData = {
//   otp: string
// }
const TwoFactorSchema = object({
  otp: string().required(),
});

export const LoginSchema = object({
  email: string().email().required(),
  password: string().required(),
});

const AuthSchema = object().shape({
  email: string().email().required(),
  password: string().required(),
  otp: string().required(),
});

function TwoFactor({ credentials, onSuccess, email }) {
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = useState('');
  const [verifyId, setVerifyId] = useState('');
  const {
    setError,
    register,
    handleSubmit,
    formState: { isDirty, errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(TwoFactorSchema),
  });

  useEffect(() => {
    // const auth = getAuth();
    // const recaptchaVerifier = new RecaptchaVerifier(
    //   'recaptcha-container',
    //   {
    //     size: 'invisible',
    //     callback: response => {},
    //   },
    //   auth,
    // );
    // const phoneAuthProvider = new PhoneAuthProvider(auth);
    // phoneAuthProvider
    //   .verifyPhoneNumber(`+55${credentials.phone}`, recaptchaVerifier)
    //   .then(verify => {
    //     setVerifyId(verify);
    //   })
    //   .catch(error2 => {
    //     console.error(error2);
    //   });
    console.log(credentials);
    api.user
      .sen2Fa('sms', credentials.userId)
      .then(res => {
        console.log(res);
        // setLoading(false);
        // setMsg(typeLogin === 'email' ? 'SMS' : 'Email');
        // setOpenModal(true);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const onSubmit = async mydata => {
    const { otp } = mydata;
    // const authCredentials = { ...credentials, otp };

    try {
      // const response = await axios.post('/api/auth/login');
      // sms 814676 willian@itfast.com.br 214
      api.user
        .verify2fa2('sms', otp, credentials.email, credentials.userId)
        .then(response => {
          console.log(response);
          onSuccess(response);
          // setLoading(false);
          // history.push(routing.dashboard);
        })
        .catch(error => {
          // setLoading(false);
          // notifyError(error.message);
        });
      // setVerificationId(verify);
      // setLoading(false);
      // setMsg(e);
      // setOpenModal(true);
      // setSteep(steep + 1);
      // Ask user for the SMS verification code.
      // const cred = app.auth.PhoneAuthProvider.credential(verificationId, 123123);
      // const multiFactorAssertion = app.auth.PhoneMultiFactorGenerator.assertion(cred);
      // Complete sign-in.
      // return resolver.resolveSignIn(multiFactorAssertion);

      // if (response.status === 200) {
      //   const { authToken } = response.data;
      //   onSuccess(authToken);
      // }
    } catch (err) {
      const error = err;

      if (error.response?.data) {
        const { data } = error.response;

        if (data.error === 'otp') {
          setError('otp', { type: 'manual', message: 'Código inválido' });
        }
      }
    }
  };

  const handleNext = e => {
    // setSteep(steep + 1);
    // setLoadingMsg('Enviando código por SMS...');
    // setLoading(true);
  };
  // const queryUsers = firestore.collection('users');
  // const [users, loadingUsers] = useCollectionData < any > queryUsers;

  // const resetQR = async () => {
  //   try {
  //     console.log('recuperando qr', users)
  //     const user = users?.find((user) => user.email === email)

  //     console.log(user)
  //     const communication: Communication = {
  //       id: '1',
  //       object: '',
  //       text: `Baixar em ${user?.twoFactorAuth.qr}`,
  //       to: [user?.email],
  //       status: 'Enviado',
  //       subject: `Recuperar QR - Sales Ecosystem`,
  //     }
  //     const resp = await axios.post('/api/mail', communication)
  //     if (resp.status === 201) {
  //       setErrorMessage('O QR foi enviado para seu e-mail.')
  //     }
  //   } catch (err) {
  //     const error = err

  //     setErrorMessage('Error')
  //   }
  // }

  return (
    <Box component="form" width="100%" onSubmit={handleSubmit(onSubmit)}>
      <div id="recaptcha-container" name="recaptcha-container" />
      <FormControl
        fullWidth
        variant="outlined"
        color="primary"
        error={!!errors.otp}
      >
        <InputLabel style={{ pointerEvents: 'auto' }} htmlFor="otp" shrink>
          Autenticação de dois fatores
          <Tooltip
            title="Veja o seu código será enviado por SMS para o celular cadastrado em sua conta"
            placement="top"
            arrow
          >
            <InfoRoundedIcon />
          </Tooltip>
        </InputLabel>
        <TextField id="otp" type="number" {...register('otp')} />
        <HelperText>{errors?.otp?.message}</HelperText>
      </FormControl>
      {/* <HelperText component="span">
        <Typography color="primary">
          <a
            onClick={() => console.log('resetQR')}
            style={{ cursor: 'pointer' }}
          >
            Recuperar QR
          </a>
        </Typography>
      </HelperText> */}
      <Box p={4} />
      <Box p={4}>
        <HelperText error>{errorMessage}</HelperText>
      </Box>
      <Button
        type="submit"
        className={classes.formButton}
        variant="contained"
        color="primary"
        disabled={!isDirty || isSubmitting}
        fullWidth
        size="large"
      >
        Entrar
      </Button>
    </Box>
  );
}

export default TwoFactor;
