/* eslint-disable no-unused-vars */
/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */

import React, { useState, useEffect, useRef, useContext } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';

import {
  // Tooltip,
  TablePagination,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Paper,
  Radio,
  FormControl,
  RadioGroup,
  FormControlLabel,
} from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from 'date-fns/locale';
import EditIcon from '@material-ui/icons/Edit';
// import GetAppIcon from '@material-ui/icons/GetApp';

// import { useExcelDownloder } from 'react-xls';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';

import Button from '@material-ui/core/Button';

import Select from 'react-select';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import _ from 'lodash';
// import MyExcelExport from './MyExcelExport';
import Loading from '../../components/loading/Loading';

import FlashContext from '../../components/contexts/FlashContext';

import api from '../../service/axios/api';

const optionsFlag = [
  {
    label: 'Ativo',
    value: 'Active',
  },
  {
    label: 'Suspenso',
    value: 'Suspended',
  },
  {
    label: 'Cancelado',
    value: 'Canceled',
  },
];

const customStylesEdit = {
  control: provided => ({
    ...provided,
    // width: 105,
    height: 40,
    borderRadius: 10,
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  option: provided => ({
    ...provided,
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
};

function createData(
  id,
  rs,
  cnpj,
  product,
  daysReport,
  status,
  daysBilled,
  all,
) {
  // const density = population / size;
  return {
    id,
    rs,
    cnpj,
    product,
    daysReport,
    status,
    daysBilled,
    all,
  };
}

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  table: {
    minWidth: 1100,
  },
});

const StyledTableCell = withStyles(() => ({
  head: {
    // backgroundColor: theme.palette.common.black,
    // color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

// eslint-disable-next-line no-unused-vars
export function SettingsTable({
  docs,
  setDocs,
  page,
  setPage,
  itens,
  setItens,
  total,
  search,
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState([]);

  // const [dataExcel, setDataExcel] = useState();
  const [dataEdit, setDataEdit] = useState();
  const [typeSelected, setTypeSelected] = useState({
    label: 'tipo',
    value: 'value',
  });
  const [typeCardSelected, setTypeCardSelected] = useState();
  const [cardFlag, setCardFlag] = useState();

  const [msg, setMsg] = useState('Atualizando...');
  const [loading, setLoading] = useState(false);

  const { notifyError, notifySuccess } = useContext(FlashContext);
  const [selectedDate, handleDateChange] = useState();

  const [reportInterval, setReportInterval] = useState('');
  const [overdueInvoice, setOverdueInvoice] = useState('');
  const [description, setDescription] = useState('');
  const [typeDays, setTypeDays] = useState('uteis');
  const [status, setStatus] = useState({ label: 'Ativo', value: 'Active' });
  const [pgtoType, setPgtoType] = useState('true');
  const [nfValue, setNfValue] = useState('1');

  // const { ExcelDownloder, Type } = useExcelDownloder();

  useEffect(() => {
    if (dataEdit) {
      handleDateChange(new Date(`${dataEdit?.validate} 00:01:01`));
      switch (dataEdit.status) {
        case 'Active':
          setTypeSelected({
            label: 'Ativo',
            value: 'Active',
          });
          break;
        case 'AwatingIssuerLink':
          setTypeSelected({
            label: 'Aguardando Link',
            value: 'AwatingIssuerLink',
          });
          break;
        case 'AwaitingRelease':
          setTypeSelected({
            label: 'Aguardando finalizar',
            value: 'AwaitingRelease',
          });
          break;
        case 'InStock':
          setTypeSelected({
            label: 'Estoque',
            value: 'InStock',
          });
          break;
        case 'Fraud':
          setTypeSelected({
            label: 'Fraudado',
            value: 'Fraud',
          });
          break;
        case 'Canceled':
          setTypeSelected({
            label: 'Cancelado',
            value: 'Canceled',
          });
          break;
        default:
          console.log('Deu erro');
      }
      switch (dataEdit.flag) {
        case 'mastercard':
          setCardFlag({
            label: 'MasterCard',
            value: 'mastercard',
          });
          break;
        case 'cabal':
          setCardFlag({
            label: 'Cabal',
            value: 'cabal',
          });
          break;
        case 'visa':
          setCardFlag({
            label: 'Visa',
            value: 'visa',
          });
          break;
        default:
          console.log('Deu erro');
      }
      switch (dataEdit?.all?.product) {
        case 'Saque/Compra':
          setTypeCardSelected({
            label: 'Saque/Compra',
            value: true,
          });
          break;
        case 'Compra':
          setTypeCardSelected({
            label: 'Compra',
            value: false,
          });
          break;
        default:
          console.log('Deu erro');
      }
    }
  }, [dataEdit]);

  useEffect(() => {
    const list = [];
    // const listCard = [];
    docs.forEach(d => {
      list.push(
        // rs, cnpj, product, daysReport, status, daysBilled, all
        createData(
          d.id,
          d.company_name,
          d.cnpj,
          d.product === 'Card' ? 'Cartão' : 'Transferência',
          d.report_interval,
          d.status,
          d.overdue_invoice,
          d,
        ),
      );
      // listCard.push({
      //   CardId: d.id,
      //   ClientId: d.Individual && d.Individual.id,
      //   Nome: d.Individual && d.Individual.name,
      //   CPF: d.Individual && d.Individual.cpf,
      //   Status: d.status,
      //   Proxy: d.proxy,
      //   Card: d.card_number,
      //   Bandeira: d.brand,
      //   Faturado: d.Billed,
      // });
    });
    // setDataExcel({ Cartoes: listCard });
    setRows(list);
  }, [docs]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setItens(event.target.value);
  };
  const returnStatus = st => {
    switch (st) {
      case 'Active':
        return { label: 'Ativo', value: st };
      case 'Canceled':
        return { label: 'Cancelado', value: st };
      case 'Suspended':
        return { label: 'Suspenso', value: st };
      default:
        return { label: 'Error', value: st };
    }
  };

  const translateStatus = st => {
    switch (st) {
      case 'Active':
        return 'Ativo';
      case 'Canceled':
        return 'Cancelado';
      case 'Suspended':
        return 'Suspenso';
      default:
        return '';
    }
  };

  const edit = data => {
    console.log(data);
    setDataEdit(data);
    setOpen(true);
    setPgtoType(data.all.is_transfer_payment ? 'true' : 'false');
    setNfValue(data.all.invoice_type === 1 ? '1' : '2');
    setOverdueInvoice(data.all.overdue_invoice);
    setReportInterval(data.all.report_interval);
    setTypeDays(data.all.type_day);
    setStatus(returnStatus(data.all.status));
    setDescription(data.all.description);
  };

  // const mountDataExcel = d => {
  //   return <MyExcelExport allData={d} />;
  // };
  const updateConf = e => {
    setLoading(true);
    e.preventDefault();
    api.joycode
      .update(
        dataEdit.id,
        dataEdit.all.contract_id,
        reportInterval,
        nfValue,
        overdueInvoice,
        pgtoType === 'true',
        status.value,
        typeDays,
        description,
      )
      .then(res => {
        search();
        notifySuccess(res.message);
        setOpen(false);
      })
      .catch(err => {
        console.error(err);
        if (err?.problems?.length > 0) {
          err.problems.forEach(p => {
            notifyError(p.msg);
          });
        } else {
          notifyError(err.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <>
      <Paper className={classes.root}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                {/* <StyledTableCell>CardId</StyledTableCell> */}
                {/* <StyledTableCell>ClientId</StyledTableCell> */}
                <StyledTableCell>Razão Social</StyledTableCell>
                <StyledTableCell>CNPJ</StyledTableCell>
                <StyledTableCell style={{ width: 100 }}>
                  Produto
                </StyledTableCell>
                <StyledTableCell>Prazo Relatório</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell>Faturamento</StyledTableCell>
                {/* <StyledTableCell style={{ width: 120 }}>
                  Validade
                </StyledTableCell>
                <StyledTableCell>Faturado</StyledTableCell> */}
                <StyledTableCell align="right">
                  <div
                    style={{
                      display: 'flex',
                      // alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    Ação
                    {/* {dataExcel && (
                      <> */}
                    {/* <ExcelDownloder
                          data={dataExcel}
                          style={{ marginLeft: 15, marginRight: -15 }}
                          filename="Cartões"
                          type={Type.Link}
                        >
                          {console.log('Carreguei')}
                          <h6
                            style={{
                              // marginRight: 30,
                              // color: '#41B490',
                              // fontFamily: 'Open Sans',
                              // textDecoration: 'none',
                              cursor: 'pointer',
                            }}
                          >
                            <Tooltip title="Exportar para Excel" arrow>
                              <GetAppIcon />
                            </Tooltip>
                          </h6>
                        </ExcelDownloder> */}
                    {/* {dataExcel && mountDataExcel(dataExcel)} */}
                    {/* <MyExcelExport allData={dataExcel} /> */}
                    {/* </>
                    )} */}
                  </div>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <StyledTableRow key={i}>
                  {/* <StyledTableCell component="th" scope="row">
                    {row.cardId}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.clientId}
                  </StyledTableCell> */}
                  <StyledTableCell>{row.rs}</StyledTableCell>
                  <StyledTableCell>{row.cnpj}</StyledTableCell>
                  <StyledTableCell style={{ width: 100 }}>
                    {row.product}
                  </StyledTableCell>
                  <StyledTableCell>{row.daysReport}</StyledTableCell>
                  <StyledTableCell>
                    {translateStatus(row.status)}
                  </StyledTableCell>
                  <StyledTableCell>{row.daysBilled}</StyledTableCell>
                  {/* <StyledTableCell style={{ width: 120 }}>
                    {row.validate}
                  </StyledTableCell>
                  <StyledTableCell>{row.Billed}</StyledTableCell> */}
                  <StyledTableCell align="right">
                    <EditIcon
                      style={{ cursor: 'pointer' }}
                      onClick={() => edit(row)}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100, 1000, 50000]}
          component="div"
          count={total}
          rowsPerPage={itens}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      {/* MODAL DE EDIÇÃO */}
      <Dialog
        onClose={() => setOpen(!open)}
        open={open}
        fullWidth
        maxWidth="sm"
      >
        <Loading open={loading} msg={msg} />
        <DialogTitle id="simple-dialog-title">
          Edição de configuração
        </DialogTitle>
        <form onSubmit={updateConf}>
          {/* <h6>Escolha o tipo de produto</h6> */}
          <Grid
            container
            spacing={1}
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              padding: 20,
            }}
          >
            <Grid item xs={12} sm={12}>
              <Grid
                container
                spacing={1}
                style={{
                  paddingRight: 20,
                  paddingLeft: 20,
                }}
              >
                <Grid item xs={12} sm={6}>
                  <h6>Prazo do Relatório</h6>
                  <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    // type="number"
                    value={reportInterval}
                    onChange={e => setReportInterval(e.target.value)}
                    // defaultValue={dataEdit && dataEdit.cardId}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <h6>Dias</h6>
                  <FormControl component="fieldset" style={{ marginLeft: 10 }}>
                    <RadioGroup
                      row
                      aria-label="gender"
                      name="row-radio-buttons-group"
                      value={typeDays}
                      onChange={e => setTypeDays(e.target.value)}
                    >
                      <FormControlLabel
                        // className="myLabel"
                        value="corridos"
                        control={
                          <Radio
                            classes={{
                              root: classes.radio,
                              checked: classes.checked,
                            }}
                          />
                        }
                        label="CORRIDOS"
                      />
                      <FormControlLabel
                        // className="myLabel"
                        value="uteis"
                        control={
                          <Radio
                            classes={{
                              root: classes.radio,
                              checked: classes.checked,
                            }}
                          />
                        }
                        label="ÚTEIS"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Grid
                container
                spacing={1}
                style={{
                  paddingRight: 20,
                  paddingLeft: 20,
                }}
              >
                <Grid item xs={12} sm={6}>
                  <h6>Prazo de Faturamento</h6>
                  <TextField
                    // inputRef={cpf}
                    type="number"
                    size="small"
                    variant="outlined"
                    fullWidth
                    value={overdueInvoice}
                    onChange={e => setOverdueInvoice(e.target.value)}
                    // defaultValue={dataEdit && dataEdit.cpf}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <h6>Status</h6>
                  <Select
                    fullWidth
                    styles={customStylesEdit}
                    value={status}
                    onChange={setStatus}
                    classNamePrefix="select"
                    placeholder="Tipo"
                    isSearchable={false}
                    options={optionsFlag}
                    menuPortalTarget={document.body}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{
                    marginTop: 10,
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <h6>Descrição da Nota</h6>
                  <TextField
                    multiline
                    rows={3}
                    size="small"
                    variant="outlined"
                    fullWidth
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{
                    marginTop: 10,
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <h6>Valor da Nota</h6>
                  <FormControl component="fieldset" style={{ marginTop: -15 }}>
                    <RadioGroup
                      row
                      aria-label="gender"
                      name="row-radio-buttons-group"
                      value={nfValue}
                      onChange={e => setNfValue(e.target.value)}
                    >
                      <FormControlLabel
                        // className="myLabel"
                        value="1"
                        control={
                          <Radio
                            classes={{
                              root: classes.radio,
                              checked: classes.checked,
                            }}
                          />
                        }
                        label="COMPLETO"
                      />
                      <FormControlLabel
                        // className="myLabel"
                        value="2"
                        control={
                          <Radio
                            classes={{
                              root: classes.radio,
                              checked: classes.checked,
                            }}
                          />
                        }
                        label="SERVIÇO"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <h6>Forma de Pagamento</h6>
                  <FormControl component="fieldset" style={{ marginTop: -15 }}>
                    <RadioGroup
                      row
                      aria-label="gender"
                      name="row-radio-buttons-group"
                      value={pgtoType}
                      onChange={e => setPgtoType(e.target.value)}
                    >
                      <FormControlLabel
                        // className="myLabel"
                        value="false"
                        control={
                          <Radio
                            classes={{
                              root: classes.radio,
                              checked: classes.checked,
                            }}
                          />
                        }
                        label="BOLETO"
                      />
                      <FormControlLabel
                        // className="myLabel"
                        value="true"
                        control={
                          <Radio
                            classes={{
                              root: classes.radio,
                              checked: classes.checked,
                            }}
                          />
                        }
                        label="TRANSFERÊNCIA"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* </form> */}
          <DialogActions>
            <Button onClick={() => setOpen(false)} color="secondary">
              Cancelar
            </Button>
            <Button
              // onClick={() => setOpen(false)}
              type="submit"
              color="primary"
              variant="contained"
              autoFocus
            >
              Salvar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
