/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Checkbox,
} from '@material-ui/core';
import api from '../../../service/axios/api';

import Loading from '../../../components/loading/Loading';

function createData(id, companyName, cnpj, status, allowInvoice) {
  return {
    id,
    companyName,
    cnpj,
    status,
    allowInvoice,
  };
}

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  table: {
    minWidth: 1100,
  },
});

const StyledTableCell = withStyles(() => ({
  head: {
    // backgroundColor: theme.palette.common.black,
    // color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function SubCompanies({ subComp, finalSelected }) {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteId, setDeleteId] = useState('');

  useEffect(() => {
    if (subComp.length > 0) {
      const list = [];
      subComp.forEach(d => {
        list.push(
          createData(
            d.contract_id,
            d.LegalEntity.company_name,
            d.LegalEntity.cnpj,
            d.is_active,
            d.allow_invoice,
          ),
        );
      });
      console.log(list);
      setRows(list);
    }
  }, [subComp]);

  const deleteContract = id => {
    setDeleteId(id);
    setOpen(true);
  };

  const handleDelete = () => {
    setLoading(true);
    setLoading(false);
  };

  const handleSelect = (id, e) => {
    if (e.target.checked) {
      finalSelected.push(id);
    } else {
      finalSelected.splice(finalSelected.indexOf(id), 1);
    }
  };

  return (
    <>
      <Paper className={classes.root}>
        <TableContainer component={Paper} style={{ maxHeight: 400 }}>
          <Table
            className={classes.table}
            aria-label="customized table"
            style={{ maxHeight: 400 }}
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>Nome</StyledTableCell>
                <StyledTableCell>CNPJ</StyledTableCell>
                <StyledTableCell>Permite emissão de NF</StyledTableCell>
                <StyledTableCell align="right">Status</StyledTableCell>
                <StyledTableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <StyledTableRow key={row.id}>
                    <StyledTableCell>{row.companyName}</StyledTableCell>
                    <StyledTableCell>{row.cnpj}</StyledTableCell>
                    <StyledTableCell>
                      {row.allowInvoice ? 'SIM' : 'NÃO'}
                    </StyledTableCell>
                    <StyledTableCell>
                      {row.status ? 'Ativo' : 'Desativado'}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <Checkbox
                        style={{ margin: 0, padding: 0 }}
                        onChange={e => handleSelect(row.id, e)}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Dialog
        onClose={() => setOpen(!open)}
        open={open}
        fullWidth
        maxWidth="sm"
      >
        <Loading open={loading} msg="Desvinculando usuário..." />
        <DialogTitle id="simple-dialog-title">Desvincular contrato</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deseja realmente desvincular o usuário deste contrado?
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <h6
            style={{
              cursor: 'pointer',
              color: '#657588',
              fontWeight: 'bold',
              fontFamily: 'Open Sans',
              marginTop: 5,
            }}
            onClick={() => setOpen(false)}
          >
            Cancelar
          </h6>
          <Button color="primary" variant="contained" onClick={handleDelete}>
            Desvincular
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
