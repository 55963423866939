/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import axios from 'axios';
// import { useCollectionDataOnce } from 'react-firebase-hooks/firestore';
import { Box, Modal, Typography, Button } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import ListItemIcon from '@material-ui/core/ListItemIcon';
// import { useRouter } from 'next/router';
import {
  GridToolbarContainer,
  GridColumnsToolbarButton,
  GridFilterToolbarButton,
} from '@material-ui/data-grid';
import { FaEdit, FaDownload } from 'react-icons/fa';
import Table from '../../Table';
// import { getNFurl } from '@/util/invoice';
// import { firestore } from '@/lib/firebase';
import useContextMenu from '../../../hooks/useContextMenu/useContextMenu';
import useClickPreventionOnDoubleClick from '../../../hooks/useClickPreventionOnDoubleClick/useClickPreventionOnDoubleClick';
// import Invoice from '@/types/Invoice';
import { useStyles } from './CommissionSummariesListTable.styles';
import Upload from './Upload';

const columns = [
  {
    field: 'companyName',
    headerName: 'Razão Social',
    flex: 1,
  },
  {
    field: 'nfNumber',
    headerName: 'N°NF',
    flex: 1,
  },
  {
    field: 'dateReceipt',
    headerName: 'Recebimento NF',
    flex: 1,
  },
  {
    field: 'datePayment',
    headerName: 'Pagamento NF',
    flex: 1,
  },
  {
    field: 'amount',
    headerName: 'Valor NF',
    flex: 1,
  },

  {
    field: 'fileKey',
    headerName: 'Arquivo',
    flex: 1,
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
  },
  {
    field: 'createdAt',
    headerName: 'Data',
    valueFormatter: params => {
      const { value } = params;
      return value;
    },
    width: 0,
  },
];

// const modalStyles = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
// };

const rows = [
  {
    id: 1,
    companyName: 'Binary Groups',
    nf_number: '51',
    date_receipt: '20/01/2020',
    date_payment: '-',
    amount: 'R$ 1,500',
    status: 'Aguardando pagamento',
  },
];
function CustomToolbar({ selectedFixed, onClickEdit }) {
  const [selected, setSelected] = useState(false);
  const { state, onOpen, onClose } = useContextMenu();
  const onClick = e => {
    onOpen(e);
    setSelected(!selected);
  };
  return (
    <>
      <GridToolbarContainer>
        <GridColumnsToolbarButton />
        <GridFilterToolbarButton />
        {selectedFixed.length > 0 && (
          <Tooltip title="Editar" style={{ marginLeft: 'auto' }}>
            <Button
              variant="text"
              color="primary"
              startIcon={<FaEdit />}
              onClick={onClick}
            >
              Status
            </Button>
          </Tooltip>
        )}
      </GridToolbarContainer>
      {selected && (
        <Menu
          keepMounted
          open={state.mouseY !== null}
          onClose={onClose}
          anchorReference="anchorPosition"
          anchorPosition={
            state.mouseY !== null && state.mouseX !== null
              ? { top: state.mouseY, left: state.mouseX }
              : undefined
          }
        >
          <MenuItem onClick={() => onClickEdit('Aguardando pagamento')}>
            <Typography>Aguardando pagamento</Typography>
          </MenuItem>

          <MenuItem onClick={() => onClickEdit('Aguardando NF')}>
            <Typography>Aguardando NF</Typography>
          </MenuItem>
          <MenuItem onClick={() => onClickEdit('Cancelado')}>
            <Typography>Cancelado</Typography>
          </MenuItem>
          <MenuItem onClick={() => onClickEdit('Pago')}>
            <Typography>Pago</Typography>
          </MenuItem>
        </Menu>
      )}
    </>
  );
}

function CommissionSummariesListTable({ close }) {
  const classes = useStyles();
  const [selected, setSelected] = useState();
  // const router = useRouter();
  const [open, setOpen] = useState(false);
  const [selectedFixed, setSelectedFixed] = useState([]);

  const { state, onOpen, onClose } = useContextMenu();
  // const query = firestore.collection('invoices');
  // const [invoices, loading] = useCollectionDataOnce(query);

  const invoices = [];
  const loading = false;

  const handleOpen = () => {
    setSelected(undefined);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onClick = (params, e) => {
    setSelected(params.row);
    onOpen(e);
  };

  const onDoubleClick = (params, e) => {
    setSelected(params.row);
    onOpen(e);
  };

  const [click, doubleClick] = useClickPreventionOnDoubleClick(
    onClick,
    onDoubleClick,
  );

  const downloadFile = async () => {
    // window.location.href = String(await getNFurl(selected));
    window.location.href = 'www.itfast.com.br';
    handleClose();
  };

  const editInvoice = () => {
    handleClose();
    setOpen(true);
  };
  const selectFixed = async param => {
    setSelectedFixed(param.selectionModel);
    //
  };
  const bulkEdit = async status => {
    const response = await axios.post('/api/invoices/bulk', {
      invoices: selectedFixed.map(row => String(row)),
      status,
    });
    if (response.status === 201) close();
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  function MyCustomToolbar() {
    return (
      <CustomToolbar selectedFixed={selectedFixed} onClickEdit={bulkEdit} />
    );
  }

  return (
    <div>
      <Box display="flex" className={classes.container} flexDirection="column">
        <div style={{ display: 'Flex', justifyContent: 'space-between' }}>
          <Typography
            className={classes.subtitle}
            variant="h5"
            color="primary"
            paragraph
          >
            Notas Fiscais
          </Typography>
          <Button
            variant="text"
            color="primary"
            endIcon={<AddCircleIcon />}
            onClick={handleOpen}
          >
            Carregar NF
          </Button>
        </div>
        <Table
          onRowClick={click}
          onRowDoubleClick={doubleClick}
          columns={columns}
          rows={invoices || rows}
          loading={loading}
          onSelectionModelChange={selectFixed}
          selectionModel={selectedFixed}
          components={{
            Toolbar: MyCustomToolbar,
          }}
          checkboxSelection
          disableSelectionOnClick
          pageSize={6}
          rowsPerPageOptions={[6]}
          sortModel={[{ field: 'createdAt', sort: 'desc' }]}
        />
      </Box>
      {selected && (
        <Menu
          keepMounted
          open={state.mouseY !== null}
          onClose={onClose}
          anchorReference="anchorPosition"
          anchorPosition={
            state.mouseY !== null && state.mouseX !== null
              ? { top: state.mouseY, left: state.mouseX }
              : undefined
          }
        >
          <MenuItem onClick={editInvoice}>
            <ListItemIcon>
              <FaEdit />
            </ListItemIcon>
            <Typography>Editar</Typography>
          </MenuItem>

          <MenuItem onClick={downloadFile} component="a" download>
            <ListItemIcon>
              <FaDownload />
            </ListItemIcon>
            <Typography>Download</Typography>
          </MenuItem>
        </Menu>
      )}
      <Modal open={open} onClose={handleClose}>
        <Box
          display="flex"
          className={classes.container}
          flexDirection="column"
          style={{ width: '80%' }}
        >
          <Upload onClose={handleClose} selected={selected} close={close} />
        </Box>
      </Modal>
    </div>
  );
}

export default CommissionSummariesListTable;
