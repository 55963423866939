/* eslint-disable no-promise-executor-return */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import styles from '../../../styles/Layout.module.css';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 100,
    width: 600,
    justifyContent: 'center',
    alignItems: 'center',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: '#41b490',
    },
  },
}));

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

// eslint-disable-next-line react/prop-types
export default function LoadingNf({ handleNext }) {
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    async function demo() {
      await sleep(3 * 1000);
      // window.open(
      //   'https://epp-storage.s3.amazonaws.com/nf/1j9AIsLOPeDWHKRvoyeP7gP4pcEOfglaBg7TLKK9.pdf',
      //   '_blank',
      // );
      handleNext();
    }
    demo();
  }, []);
  return (
    <Grid
      container
      spacing={2}
      style={{
        // minHeight: 500,
        marginBottom: 100,
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
      }}
    >
      <Grid
        container
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Grid item>
          <div className={classes.root}>
            <LinearProgress />
          </div>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <h5 className={styles.textNfInform}>{t('newOrder.loadingNf.label')}</h5>
      </Grid>
    </Grid>
  );
}
