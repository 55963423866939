import React, { useEffect, useState } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
// import Proposal from '@/types/Proposal'
// import axios from 'axios';
import { useParams } from 'react-router-dom';
import ProposalForm from '../components/forms/ProposalForm';
import api from '../../../service/axios/api';

const useStyles = makeStyles(theme => ({
  header: {
    marginBottom: theme.spacing(2),
  },
}));

function CreateProposal() {
  // const router = useHistory();
  const classes = useStyles();
  const [valid, setValid] = useState(true);
  const { campaing } = useParams();

  useEffect(() => {
    if (campaing) {
      const cancel = new AbortController();
      api.campaign
        .isValid(cancel, campaing)
        .then(res => {
          setValid(res.retorno.active);
          // setValid(false);
        })
        .catch(err => console.log(err));
    }
  }, []);

  // const onSuccess = proposal => axios.post('/api/proposals', proposal);
  const onSuccess = proposal => api.oportunity.createPublic(proposal);

  const callback = () => {
    // router.push('/dashboard/proposals');
    console.log('nada a fazer');
  };

  return valid ? (
    <>
      <Grid
        className={classes.header}
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Typography variant="h4" component="h2" gutterBottom>
          Nova oportunidade
        </Typography>
      </Grid>
      <ProposalForm
        onSuccess={onSuccess}
        code={201}
        titleModal="Oportunidade inserida com sucesso"
        actionModal={callback}
        disabled={false}
        isPublic
        campaign={campaing}
      />
    </>
  ) : (
    <div
      style={{
        height: '80vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography variant="h4" component="h2" gutterBottom>
        Campanha expirada
      </Typography>
    </div>
  );
}

export default CreateProposal;
