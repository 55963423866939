/* eslint-disable no-plusplus */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState, useRef } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { string, object, array } from 'yup';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';

import {
  Button,
  Container,
  Grid,
  makeStyles,
  TextField,
  Typography,
  CircularProgress,
  MenuItem,
} from '@material-ui/core';
import Select from 'react-select';
import { yupResolver } from '@hookform/resolvers/yup';
import Loading from '../../../../components/loading/Loading';
import { STATES } from '../../util/constants';
import FlashContext from '../../../../components/contexts/FlashContext';
import api from '../../../../service/axios/api';
import StepperContext from '../../context/StepperContext';
// import CompanySchema from '../../schemas/CompanySchema';

const useStyles = makeStyles(theme => ({
  form: {
    minHeight: '500px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  content: {
    padding: theme.spacing(4),
  },
  section: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(8),
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(4),
    background: '#f0f0f0',
    width: '100%',
  },
}));

// eslint-disable-next-line no-unused-vars
function CompanyFormAdd({
  company,
  isPublic,
  controlE,
  edit,
  contractId,
  existIds,
  orig,
}) {
  const { t } = useTranslation();
  const { id } = useParams();
  const { notifySuccess, notifyError } = useContext(FlashContext);
  const fileXls = useRef(null);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [myFile, setMyfile] = useState();
  // const options = values.map(value => ({
  //   label: value,
  //   value,
  // }));
  const [opt, setOpt] = useState([]);
  const history = useHistory();

  const cleanCEP = dt => {
    const res = dt.replace('-', '');
    const final = res.replaceAll('.', '');
    return final.trim();
  };

  const contact = object()
    .shape({
      cpf: string()
        .required(t('newContract.company.required'))
        .matches(/[0-9]{11}/, t('validation.cpf')),
      email: string()
        .required(t('newContract.company.required'))
        .email(t('validation.email')),
      name: string().required(t('newContract.company.required')),
      role: string().required(t('newContract.company.required')),
      phone: string().required(t('newContract.company.required')),
    })
    .required();

  const companies = object()
    .shape({
      companyName: string().required(t('newContract.company.required')),
      tradingName: string().required(t('newContract.company.required')),
      cnpj: string()
        .required(t('newContract.company.required'))
        .matches(/[0-9]{14,14}/, t('validation.cnpj')),
      email: string()
        .required(t('newContract.company.required'))
        .email(t('validation.email')),
      phone: string().required(t('newContract.company.required')),
      mobile: string()
        .required()
        .matches(/[0-9]{11,11}/, '11 Digitos obrigatórios'),
      website: string().nullable().notRequired(),
      comments: string().nullable().notRequired(),
      postalCode: string().required('Campo obrigatório'),
      street_name: string().required('Campo obrigatório'),
      number: string().required('Campo obrigatório'),
      complement: string().nullable().notRequired(),
      district: string().required('Campo obrigatório'),
      city: string().required('Campo obrigatório'),
      allow_invoice: string().nullable().notRequired(),
      is_active: string().nullable().notRequired(),
      is_default: string().nullable().notRequired(),
    })
    .required();

  const CompanySchema = object({
    companies: array().compact().of(companies),
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: company,
    resolver: yupResolver(CompanySchema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'companies',
  });

  const classes = useStyles();
  const stepper = useContext(StepperContext);

  async function editCompany(data, address, idContract, cnpj) {
    // eslint-disable-next-line no-param-reassign
    address.postalCode = cleanCEP(address.postalCode);
    return api.contract.updateCompany(data, address, idContract, cnpj);
  }

  async function linkCompany(legalEntities, idContract) {
    // eslint-disable-next-line no-param-reassign
    // legalEntities[0].postalCode = cleanCEP(legalEntities[0].postalCode);
    return api.contract.addCnpjs(legalEntities, idContract);
  }

  async function deleteCompany(idContract, cnpj) {
    // eslint-disable-next-line no-param-reassign
    return api.contract.removeCnpjs(idContract, cnpj);
  }

  const submit = async data => {
    setLoading(true);
    let myError = false;
    const allData = data.companies;
    for (let x = 0; x < allData.length; x++) {
      if (allData[x].id) {
        const idx = existIds.indexOf(allData[x].cnpj);
        existIds.splice(idx, 1);

        const or = orig.find(f => f.id === allData[x].id);

        if (
          or.companyName === allData[x].companyName &&
          or.tradingName === allData[x].tradingName &&
          or.email === allData[x].email &&
          or.phone === allData[x].phone &&
          or.mobile === allData[x].mobile &&
          or.website === allData[x].website &&
          or.comments === allData[x].comments &&
          or.postalCode === allData[x].postalCode &&
          or.street_name === allData[x].street_name &&
          or.number === allData[x].number &&
          or.complement === allData[x].complement &&
          or.district === allData[x].district &&
          or.city === allData[x].city &&
          or.state === allData[x].state
        ) {
          console.log('sem atualização');
        } else {
          const myData = {
            companyName: allData[x].companyName,
            tradingName: allData[x].tradingName,
            email: allData[x].email,
            phone: allData[x].phone,
            mobile: allData[x].mobile,
            website: allData[x].website,
            comments: allData[x].comments,
          };

          const address = {
            postalCode: allData[x].postalCode,
            street: allData[x].street_name,
            number: allData[x].number,
            complement: allData[x].complement,
            district: allData[x].district,
            city: allData[x].city,
            state: allData[x].state,
          };
          try {
            // eslint-disable-next-line no-await-in-loop
            const res = await editCompany(
              myData,
              address,
              contractId,
              allData[x].cnpj,
            );
            notifySuccess(`Empresa ${allData[x].cnpj} atualizada.`);
          } catch (e) {
            myError = true;
            notifyError(e.message);
          }
        }
      } else {
        const legalEntities = [];
        legalEntities.push({
          cnpj: allData[x].cnpj,
          company_name: allData[x].companyName,
          trading_name: allData[x].tradingName,
          website: allData[x].website,
          comments: allData[x].comments,
          phone: allData[x].phone,
          mobile: allData[x].mobile,
          email: allData[x].email,
          street_name: allData[x].street_name,
          postal_code: allData[x].postalCode,
          number: allData[x].number,
          complement: allData[x].complement,
          district: allData[x].district,
          city: allData[x].city,
          state: allData[x].state,
          allow_invoice: false,
          is_active: true,
          is_default: false,
        });
        try {
          // eslint-disable-next-line no-await-in-loop
          const res = await linkCompany(legalEntities, contractId);
          notifySuccess(`Empresa ${allData[x].cnpj} vinculada.`);
        } catch (e) {
          myError = true;
          notifyError(e.message);
        }
      }
    }
    for (let x = 0; x < existIds.length; x++) {
      try {
        // eslint-disable-next-line no-await-in-loop
        const res = await deleteCompany(contractId, existIds[x]);
        notifySuccess(`Empresa ${allData[x].cnpj} desvinculada.`);
      } catch (e) {
        myError = true;
        notifyError(e.message);
      }
    }
    if (!myError) {
      history.push('/allContracts');
    }
    setLoading(false);
  };

  const addContact = () => {
    append({
      companyName: '',
      tradingName: '',
      cnpj: '',
      email: '',
      phone: '',
      mobile: '',
      website: '',
      comments: '',
      postalCode: '',
      street_name: '',
      number: '',
      complement: '',
      district: '',
      city: '',
      allow_invoice: '',
      is_active: '',
      is_default: '',
    });
  };

  useEffect(() => {
    const cancel = new AbortController();

    api.campaign
      .get(cancel, 1, 1000)
      .then(res => {
        const list = [];
        res.docs.forEach(d => {
          list.push({ label: d.name, value: d.id });
        });
        setOpt(list);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (myFile) {
      setLoading(true);
      api.contract
        .uploadContract(myFile, id)
        .then(() => {
          notifySuccess(t('newContract.company.uploadSuccess'));
        })
        .catch(err => {
          notifyError(err.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [myFile]);

  return (
    <>
      <Loading open={loading} msg="Salvando alterações" />
      <form className={classes.form} onSubmit={handleSubmit(submit)}>
        <Container className={classes.content}>
          <div className={classes.section}>
            <Typography variant="h5" component="h3" gutterBottom>
              {t('newContract.company.label')}
            </Typography>
            {fields.map((c, idx) => (
              <>
                <Typography
                  variant="h5"
                  component="h3"
                  gutterBottom
                  style={{ marginTop: idx > 0 && 10 }}
                >
                  {/* {t('newContract.contact.label')}{' '} */}
                  {fields.length > 1 ? idx + 1 : ''}
                  {fields.length > 1 && (
                    <Button
                      onClick={() => remove(idx)}
                      variant="outlined"
                      size="small"
                      style={{
                        marginLeft: 15,
                        // width: 10,
                        backgroundColor: '#ba000d',
                        color: '#fff',
                        fontSize: 14,
                        fontWeight: 'bold',
                      }}
                    >
                      {t('newContract.contact.removeBtn')}
                    </Button>
                  )}
                </Typography>
                <Grid container spacing={10}>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextField
                      inputProps={{ ...register(`companies[${idx}].cnpj`) }}
                      error={errors.contacts && !!errors.companies[idx]?.cnpj}
                      helperText={
                        errors?.companies &&
                        errors?.companies[idx]?.cnpj?.message
                      }
                      label={t('newContract.company.cnpj')}
                      variant="outlined"
                      size="small"
                      fullWidth
                      // disabled={edit}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextField
                      inputProps={{
                        ...register(`companies[${idx}].companyName`),
                      }}
                      error={
                        errors.companies && !!errors.companies[idx]?.companyName
                      }
                      helperText={
                        errors.companies &&
                        errors.companies[idx]?.companyName?.message
                      }
                      label={t('newContract.company.rz')}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextField
                      inputProps={{
                        ...register(`companies[${idx}].tradingName`),
                      }}
                      error={
                        errors.companies && !!errors.companies[idx]?.tradingName
                      }
                      helperText={
                        errors.companies &&
                        errors.companies[idx]?.tradingName?.message
                      }
                      label={t('newContract.company.tradingName')}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      inputProps={{
                        ...register(`companies[${idx}].email`),
                      }}
                      error={errors.companies && !!errors.companies[idx]?.email}
                      helperText={
                        errors.companies &&
                        errors.companies[idx]?.email?.message
                      }
                      label={t('newContract.contact.email')}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      inputProps={{ ...register(`companies[${idx}].phone`) }}
                      error={errors.companies && !!errors.companies[idx]?.phone}
                      helperText={
                        errors.companies &&
                        errors.companies[idx]?.phone?.message
                      }
                      label={t('newContract.contact.phone')}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      inputProps={{ ...register(`companies[${idx}].mobile`) }}
                      error={
                        errors.companies && !!errors.companies[idx]?.mobile
                      }
                      helperText={
                        errors.companies &&
                        errors.companies[idx]?.mobile?.message
                      }
                      label={t('newContract.contact.celPhone')}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      inputProps={{ ...register(`companies[${idx}].website`) }}
                      error={
                        errors.companies && !!errors.companies[idx]?.website
                      }
                      helperText={
                        errors.companies &&
                        errors.companies[idx]?.website?.message
                      }
                      label={t('newContract.company.website')}
                      variant="outlined"
                      size="small"
                      disabled={edit}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      inputProps={{ ...register(`companies[${idx}].comments`) }}
                      error={
                        errors.companies && !!errors.companies[idx]?.comments
                      }
                      helperText={
                        errors.companies &&
                        errors.companies[idx]?.comments?.message
                      }
                      label={t('newContract.company.comments')}
                      variant="outlined"
                      size="small"
                      disabled={edit}
                      fullWidth
                    />
                  </Grid>
                  {/* ENDEREÇO */}
                  <Grid item xs={12}>
                    <Typography variant="h6" component="h6">
                      ENDEREÇO
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      inputProps={{
                        ...register(`companies[${idx}].postalCode`),
                      }}
                      error={
                        errors.companies && !!errors.companies[idx]?.postalCode
                      }
                      helperText={
                        errors.companies &&
                        errors.companies[idx]?.postalCode?.message
                      }
                      label={t('newContract.address.postalCode')}
                      variant="outlined"
                      size="small"
                      disabled={edit}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={9} md={9}>
                    <TextField
                      inputProps={{
                        ...register(`companies[${idx}].street_name`),
                      }}
                      error={
                        errors.companies && !!errors.companies[idx]?.street_name
                      }
                      helperText={
                        errors.companies &&
                        errors.companies[idx]?.street_name?.message
                      }
                      label={t('newContract.address.address')}
                      variant="outlined"
                      size="small"
                      disabled={edit}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      inputProps={{ ...register(`companies[${idx}].number`) }}
                      error={
                        errors.companies && !!errors.companies[idx]?.number
                      }
                      helperText={
                        errors.companies &&
                        errors.companies[idx]?.number?.message
                      }
                      label={t('newContract.address.number')}
                      variant="outlined"
                      size="small"
                      disabled={edit}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      inputProps={{
                        ...register(`companies[${idx}].complement`),
                      }}
                      error={
                        errors.companies && !!errors.companies[idx]?.complement
                      }
                      helperText={
                        errors.companies &&
                        errors.companies[idx]?.complement?.message
                      }
                      label={t('newContract.address.complement')}
                      variant="outlined"
                      size="small"
                      disabled={edit}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      inputProps={{ ...register(`companies[${idx}].district`) }}
                      error={
                        errors.companies && !!errors.companies[idx]?.district
                      }
                      helperText={
                        errors.companies &&
                        errors.companies[idx]?.district?.message
                      }
                      label={t('newContract.address.district')}
                      variant="outlined"
                      size="small"
                      disabled={edit}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      inputProps={{ ...register(`companies[${idx}].city`) }}
                      error={errors.companies && !!errors.companies[idx]?.city}
                      helperText={
                        errors.companies && errors.companies[idx]?.city?.message
                      }
                      label={t('newContract.address.city')}
                      variant="outlined"
                      size="small"
                      disabled={edit}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Controller
                      control={control}
                      name={`companies[${idx}].state`}
                      render={props => (
                        <TextField
                          select
                          // onChange={e => props.field.onChange(e.target.value)}
                          inputProps={{
                            ...register(`companies[${idx}].state`),
                          }}
                          value={props.field.value}
                          error={
                            errors.companies && !!errors.companies[idx]?.state
                          }
                          helperText={
                            errors.companies &&
                            errors.companies[idx]?.state?.message
                          }
                          label={t('newContract.address.state')}
                          variant="outlined"
                          size="small"
                          fullWidth
                        >
                          {STATES.map(state => (
                            <MenuItem key={state} value={state}>
                              {state}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </Grid>
                </Grid>
              </>
            ))}
            <Grid item xs={12} style={{ marginTop: 20 }}>
              <div style={{ display: 'flex', justifyContent: 'end' }}>
                <Button
                  onClick={addContact}
                  variant="contained"
                  color="primary"
                >
                  + Empresa
                </Button>
              </div>
            </Grid>
          </div>
        </Container>
        <div className={classes.footer}>
          <Button
            onClick={() => history.push('/allContracts')}
            variant="contained"
            color="primary"
            style={{ marginRight: 10 }}
          >
            {/* {t('newContract.contact.nextBtn')} */}
            Voltar
          </Button>
          <Button type="submit" variant="contained" color="primary">
            {/* {t('newContract.contact.nextBtn')} */}
            SALVAR
          </Button>
        </div>
      </form>
      <input
        ref={fileXls}
        type="file"
        style={{
          opacity: 0,
        }}
        // accept="media_type"
        onChange={() => setMyfile(fileXls.current.files[0])}
        className="form-control"
        id="inputNumSerie"
        // placeholder="Arquivo DWG do projeto"
      />
    </>
  );
}
export default CompanyFormAdd;
