/* eslint-disable react/prop-types */
import React from 'react';
import Table from '../Table';
import { localize } from '../../util/localizations';
import CustomNoRowsOverlay from '../CustomNoRowsOverlay';
import { getCnpj, getCompanyName, getTradingName } from './getters';

function NoRowsOverlay() {
  return <CustomNoRowsOverlay text="No hay contratos registrados" />;
}

const columns = [
  {
    field: 'companyName',
    headerName: 'Razão Social',
    valueGetter: getCompanyName,
    flex: 1,
  },
  { field: 'cnpj', headerName: 'CNPJ', valueGetter: getCnpj, flex: 1 },
  {
    field: 'tradingName',
    headerName: 'Nome',
    valueGetter: getTradingName,
    flex: 1,
  },

  {
    field: 'd4signStatus',
    headerName: 'Status',
    valueFormatter: params => {
      const { value } = params;
      return localize(value);
    },
    type: 'string',
    width: 200,
  },
  {
    field: 'createdAt',
    headerName: 'Data',
    valueFormatter: params => {
      const { value } = params;
      return value;
    },
    width: 0,
  },
];

function ContractsTable({ contracts, ...others }) {
  return (
    <Table
      columns={columns}
      rows={contracts}
      components={{ NoRowsOverlay }}
      sortModel={[{ field: 'createdAt', sort: 'desc' }]}
      {...others}
    />
  );
}

export default ContractsTable;
