/* eslint-disable react/prop-types */
import React, { useState, useMemo } from 'react';

// Importing MaterialUI components
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

// eslint-disable-next-line import/no-duplicates
import { ptBR } from 'date-fns/locale';
import {
  format,
  startOfMonth,
  subMonths,
  addMonths,
  lastDayOfMonth,
  // eslint-disable-next-line import/no-duplicates
} from 'date-fns';

import makeJSDateObject from '../../../../util/makeJSDateObject';

// import { CycleMonthProps } from '../interface'

// Importing styles
import { useStyles } from './CycleMonth.styles';

function CycleMonth({ title, maxValue, minValue, onChange }) {
  const classes = useStyles();

  const [month, setMonth] = useState(startOfMonth(new Date()));
  const [anchor, setAnchor] = useState();

  const onOpen = event => {
    setAnchor(event.currentTarget);
  };

  const onClose = () => {
    setAnchor(undefined);
  };

  const selectMonth = () => {
    const current = makeJSDateObject(new Date());
    const lastDateOfMonth = lastDayOfMonth(month);
    const last = current > lastDateOfMonth ? lastDateOfMonth : current;
    const firstDate = makeJSDateObject(month);
    const lastDate = makeJSDateObject(last);

    onChange({
      firstDate,
      lastDate,
    });
  };

  const changeMonth = event => {
    const date = makeJSDateObject(month);
    const direction = event.currentTarget.dataset.direction === 'next' ? 1 : -1;
    date.setMonth(date.getMonth() + direction, 1);
    setMonth(startOfMonth(date));
  };

  const enableFirstButton = useMemo(() => {
    if (minValue) {
      const minValueControl = startOfMonth(minValue).getTime();
      const valueControl = startOfMonth(subMonths(month, 1)).getTime();
      return minValueControl <= valueControl;
    }
    return true;
  }, [minValue, month]);

  const enableLastButton = useMemo(() => {
    if (maxValue) {
      const maxValueControl = startOfMonth(maxValue).getTime();
      const valueControl = startOfMonth(addMonths(month, 1)).getTime();
      return maxValueControl >= valueControl;
    }
    return true;
  }, [maxValue, month]);

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Box ml={2} mr={2} component="span">
        <IconButton color="primary" onClick={onOpen}>
          <ExpandMoreIcon />
        </IconButton>
      </Box>
      <Typography variant="subtitle1">{title}</Typography>

      <Popover
        open={!!anchor}
        classes={{
          paper: classes.paper,
        }}
        anchorEl={anchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={onClose}
      >
        <Box p={2} component="span">
          <IconButton
            data-direction="previous"
            edge="start"
            color="primary"
            onClick={changeMonth}
            disabled={!enableFirstButton}
          >
            <ChevronLeftIcon />
          </IconButton>
        </Box>
        <Button
          variant="contained"
          className={classes.button}
          color="primary"
          onClick={selectMonth}
        >
          {format(month, 'MMMM - yyyy', {
            locale: ptBR,
          })}
        </Button>
        <Box p={2} component="span">
          <IconButton
            data-direction="next"
            edge="end"
            color="primary"
            onClick={changeMonth}
            disabled={!enableLastButton}
          >
            <ChevronRightIcon />
          </IconButton>
        </Box>
      </Popover>
    </Box>
  );
}

export default CycleMonth;
