/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/aria-role */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-restricted-globals */
import * as React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Collapse,
  Table,
  TableCell,
  TableRow,
  TableContainer,
  TableBody,
  Typography,
  Paper,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import MyButton from '../../components/button/MyButton';
import routing from '../../service/routes/routing';
import PermissionComponent from '../../components/permission/Permission';
import styles from '../../styles/Layout.module.css';

import './Notifications.css';

function createData(name, resum, iTitle, problem, order) {
  return {
    name,
    resum,
    order,
    history: [
      {
        iTitle,
        problem,
      },
    ],
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <TableRow
        sx={{ '& > *': { borderBottom: 'unset' } }}
        onClick={() => setOpen(!open)}
      >
        <TableCell component="th" scope="row" className="txt-notification">
          <div className="txt-notification">{row.name}</div>
        </TableCell>
        <TableCell align="right" className="txt-notification-description">
          <div className="txt-notification-description">{row.resum}</div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              {row.history.map((historyRow, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <React.Fragment key={i}>
                  <Typography
                    variant="p"
                    gutterBottom
                    component="div"
                    className="txt-notification-intern-title"
                  >
                    {historyRow.iTitle}
                  </Typography>
                  <div className="txt-notification-intern-description">
                    {historyRow.problem}
                  </div>
                  <div
                    className="col-sm-12"
                    style={{
                      display: 'flex',
                      justifyContent: 'end',
                      marginBottom: 20,
                      marginTop: 70,
                    }}
                  >
                    <MyButton style={{ width: 50, height: 30 }} label=">" />
                  </div>
                </React.Fragment>
              ))}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    history: PropTypes.arrayOf(
      PropTypes.shape({
        problem: PropTypes.string.isRequired,
        iTitle: PropTypes.string.isRequired,
      }),
    ).isRequired,
    name: PropTypes.string.isRequired,
    order: PropTypes.string.isRequired,
    resum: PropTypes.string.isRequired,
  }).isRequired,
};

const rows = [
  createData(
    'Ajuste os dados do pedido PT00000',
    'Descrição resumida',
    'O pedido está incorreto',
    'Os dados bancários do pedido PT00000 estão incorretos! Pedimos a gentileza de confirmar as informações a tualizar o item.',
    'PT00000',
  ),
];

export default function CollapsibleTable() {
  const history = useHistory();
  return (
    <div className="col-sm-12" style={{ marginTop: 50 }}>
      <p
        className={styles.textMenu}
        onKeyDown={() => history.push(routing.dashboard)}
        onClick={() => history.push(routing.dashboard)}
      >
        Menu Inicial
      </p>
      <div
        className="col-sm-12"
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <h4 className={styles.textTitle}>Notificações | Pendências</h4>
        <PermissionComponent role="Administrador">
          <MyButton
            style={{ marginRight: 50 }}
            onClick={() => history.push(routing.newNotifications)}
            label="Nova Mensagem"
          />
        </PermissionComponent>
      </div>
      {/* <div
    className="col-sm-12"
    style={{
      display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center',
    }}
  > */}
      <div style={{ marginTop: 15 }}>
        {/* <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableBody>
              {rows.map(row => (
                <Row key={row.order} row={row} />
              ))}
            </TableBody>
          </Table>
        </TableContainer> */}
      </div>
    </div>
  );
}
