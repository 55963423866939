/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/function-component-definition */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import { alpha, styled } from '@mui/material/styles';
// import Box from '@mui/material/Box';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
// import Toolbar from '@mui/material/Toolbar';
// import Typography from '@mui/material/Typography';
// import Paper from '@mui/material/Paper';
// import Checkbox from '@mui/material/Checkbox';
// import IconButton from '@mui/material/IconButton';
// import Tooltip from '@mui/material/Tooltip';
// import DeleteIcon from '@mui/icons-material/Delete';
// import FilterListIcon from '@mui/icons-material/FilterList';
// import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import moment from 'moment-timezone';
import DeleteIcon from '@material-ui/icons/Delete';
// import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListIcon from '@material-ui/icons/FilterList';
// import TableCell, { tableCellClasses } from '@mui/material/TableCell';

// import { withStyles, makeStyles, createStyles } from '@mui/styles';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
import MoreVertIcon from '@material-ui/icons/MoreVert';

// import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';
// import Dialog from '@mui/material/Dialog';
// import styled from 'styled-components';
// import Pagination from '@mui/material/Pagination';
import { Pagination } from '@material-ui/lab';
// import Stack from '@mui/material/Stack';
// import Grid from '@mui/material/Grid';
import {
  Grid,
  Box,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableHead,
  Toolbar,
  Typography,
  Paper,
  Checkbox,
  IconButton,
  Tooltip,
  Menu,
  MenuItem,
  Dialog,
  TableCell,
  Fade,
} from '@material-ui/core';
import {
  withStyles,
  makeStyles,
  createStyles,
  styled,
} from '@material-ui/core/styles';
// import { withStyles, makeStyles, createStyles } from '@mui/styles';
// import MoreVertIcon from '@mui/icons-material/MoreVert';

// import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';
// import Fade from '@mui/material/Fade';
import Select from 'react-select';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
// import Pagination from '@mui/material/Pagination';
// import Stack from '@mui/material/Stack';
// import Dialog from '@mui/material/Dialog';

// import Grid from '@mui/material/Grid';
// import TextField from '@mui/material/TextField';
import routing from '../../../service/routes/routing';

import Button from '../../button/MyButton';

import SelectOptions from './SelectOptions';

const useStyles = makeStyles(theme =>
  createStyles({
    textNotification: {
      minWidth: 180,
      // textAlign: 'center',
      fontFamily: 'Open Sans',
      fontWeight: 'bold',
      // lineHeight: 'auto',
      fontSize: 14,
      color: '#707070',
      '&:hover': {
        backgroundColor: '#41b490',
        color: '#fff',
      },
    },
    myLabel: {
      // padding: theme.spacing(6),
      color: '#2a3176',
      fontWeight: 'bold',
      margin: 20,
    },
    data: {
      marginTop: -20,
      color: '#717171',
      fontWeight: 500,
      fontFamily: 'Open Sans',
    },
    labelData: {
      color: '#717171',
      fontFamily: 'Open Sans',
    },
    gridItem: {
      marginTop: -40,
      '&& .MuiGrid-item': {
        paddingTop: 0,
        maxHeight: 60,
      },
    },
  }),
);

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.primary.light,
    fontFamily: 'Open Sans',
    color: theme.palette.common.white,
  },
  body: {
    color: '#717171',
    fontFamily: 'Open Sans',
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:hover': {
      backgroundColor: '#41b490',
      color: '#41b490',
    },
  },
}))(TableRow);

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark'
        ? 'rgba(57,75,89,.5)'
        : 'rgba(206,217,224,.5)',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#137cbd',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#106ba3',
  },
});

const options = [
  {
    label: '25',
    value: 25,
  },
  {
    label: '50',
    value: 50,
  },
  {
    label: '100',
    value: 100,
  },
];

const EnhancedTableHead = props => {
  const classes = useStyles();
  const { onSelectAllClick, numSelected, rowCount, onRequestSort } = props;
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <TableHead>
        <TableRow>
          <StyledTableCell component="th">Pais</StyledTableCell>
          <StyledTableCell component="th">UF</StyledTableCell>
          <StyledTableCell component="th">Cidade</StyledTableCell>
          <StyledTableCell component="th">Tipo de Licença</StyledTableCell>
          <StyledTableCell
            component="th"
            onClick={handleClick}
            style={{ cursor: 'pointer' }}
          >
            Perfil de Acesso
          </StyledTableCell>
        </TableRow>
      </TableHead>
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        sx={{ minWidth: 600 }}
      >
        <MenuItem className={classes.textNotification}>
          Master
          <Checkbox checkedIcon={<BpCheckedIcon />} icon={<BpIcon />} />
        </MenuItem>
        <MenuItem className={classes.textNotification} onClick={handleClose}>
          Administrador
          <Checkbox checkedIcon={<BpCheckedIcon />} icon={<BpIcon />} />
        </MenuItem>
        <MenuItem className={classes.textNotification} onClick={handleClose}>
          Operador
          <Checkbox checkedIcon={<BpCheckedIcon />} icon={<BpIcon />} />
        </MenuItem>
        <MenuItem className={classes.textNotification} onClick={handleClose}>
          Selecionar todos
          <Checkbox checkedIcon={<BpCheckedIcon />} icon={<BpIcon />} />
        </MenuItem>
      </Menu>
    </>
  );
};

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  // onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  // order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  // orderBy: PropTypes.string.isRequired,
  // rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = function (props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: '#fff',
          // (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable({
  notify,
  totalPages,
  setPage,
  page,
  itens,
  setItens,
  search,
  find,
  setStatus,
  setOrder,
}) {
  const [selected, setSelected] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [dataDetails, setDataDetails] = useState();

  // const handleMenu = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const handleMenu = (row, event) => {
    setDataDetails(row);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setDataDetails();
    setAnchorEl(null);
  };

  const [rows, setRows] = useState([]);
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    const listProd = [];
    notify.forEach((u, i) => {
      listProd.push({
        id: u.id,
        country: u.country,
        uf: u.uf,
        city: u.city,
        license: u.license,
        perfil: u.perfil,
        all: u,
      });
    });
    setRows(listProd);
  }, [notify]);

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const isSelected = id => selected.indexOf(id) !== -1;

  const { t } = useTranslation();

  return (
    <>
      <SelectOptions
        search={search}
        find={find}
        setStatus={setStatus}
        setOrder={setOrder}
      />
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size="medium"
            >
              <EnhancedTableHead
                numSelected={selected.length}
                onSelectAllClick={handleSelectAllClick}
                rowCount={rows.length}
                handleMenu={handleMenu}
              />
              <TableBody>
                {rows ? (
                  rows.length > 0 ? (
                    rows.map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <StyledTableRow tabIndex={-1} key={row.id}>
                          <StyledTableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            // padding="none"
                          >
                            {row.country}
                          </StyledTableCell>
                          <StyledTableCell>{row.uf}</StyledTableCell>
                          <StyledTableCell>{row.city}</StyledTableCell>
                          <StyledTableCell>{row.license}</StyledTableCell>
                          {/* <StyledTableCell>{row.created}</StyledTableCell> */}
                          {/* <StyledTableCell>{row.or}</StyledTableCell> */}
                          <StyledTableCell>{row.perfil}</StyledTableCell>
                        </StyledTableRow>
                      );
                    })
                  ) : (
                    <StyledTableRow>
                      <StyledTableCell colSpan={5} align="center">
                        Nenhum registro disponível
                      </StyledTableCell>
                    </StyledTableRow>
                  )
                ) : (
                  <StyledTableRow>
                    <StyledTableCell colSpan={5} align="center" />
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Menu
            id="menu-appbar"
            style={{ borderRadius: '25px' }}
            anchorEl={anchorEl}
            //  anchorOrigin={{
            //    vertical: 'top',
            //    horizontal: 'right',
            //  }}
            keepMounted
            //  transformOrigin={{
            //    vertical: 'top',
            //    horizontal: 'right',
            //  }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              className={classes.textNotification}
              onClick={() => setOpenModal(true)}
            >
              {/* {t('Product.creditCard.table.edit')} */}
              Detalhes
            </MenuItem>
            {/* <MenuItem
              className={classes.textNotification}
              onClick={handleClose}
            >
              {t('Product.creditCard.table.duplicate')}

            </MenuItem> */}

            {/* <MenuItem
              className={classes.textNotification}
              onClick={handleClose}
            >
              {t('Product.creditCard.table.suspend')}

            </MenuItem>
            <MenuItem
              className={classes.textNotification}
              onClick={handleClose}
            >
              {t('Product.creditCard.table.cancel')}

            </MenuItem> */}
          </Menu>
        </Paper>
      </Box>
      {totalPages && totalPages > 1 && (
        <Grid
          justifyContent="center"
          alignItems="center"
          spacing={2}
          direction="row"
        >
          <Select
            className="basic-single"
            classNamePrefix="select"
            placeholder="Itens"
            value={itens}
            onChange={setItens}
            options={options}
          />
          <Pagination
            size="large"
            count={totalPages}
            page={page}
            onChange={handleChangePage}
            variant="outlined"
            shape="rounded"
          />
        </Grid>
      )}
      <Dialog
        onClose={() => setOpenModal(false)}
        open={openModal}
        fullWidth
        // maxWidth="lg"
      >
        <Grid
          container
          // spacing={1}
          // padding={1}
          style={{
            border: '2px solid #c5c5c5',
            borderRadius: 10,
          }}
        >
          <h4 className={classes.myLabel}>Detalhes do usuário</h4>
          <Grid
            container
            className={classes.gridItem}
            spacing={1}
            style={{
              padding: 40,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <h4 className={classes.labelData}>Destinatário</h4>
              <h5 className={classes.data}>
                {dataDetails && dataDetails.all.name}
              </h5>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <h4 className={classes.labelData}>Assunto</h4>
              <h5 className={classes.data}>
                {dataDetails &&
                  dataDetails.all.LegalEntity &&
                  dataDetails.all.LegalEntity.trading_name}
              </h5>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <h4 className={classes.labelData}>Data</h4>
              <h5 className={classes.data}>
                {dataDetails && dataDetails.all.cpf}
              </h5>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <h4 className={classes.labelData}>Criado por</h4>
              <h5 className={classes.data}>
                {dataDetails &&
                  dataDetails.all.LegalEntity &&
                  dataDetails.all.LegalEntity.company_name}
              </h5>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <h4 className={classes.labelData}>Canal</h4>
              <h5 className={classes.data}>
                {dataDetails && dataDetails.all.email}
              </h5>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <h4 className={classes.labelData}>Anexo</h4>
              <h5 className={classes.data}>
                {dataDetails &&
                  dataDetails.all.LegalEntity &&
                  dataDetails.all.LegalEntity.cnpj}
              </h5>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <h4 className={classes.labelData}>Status</h4>
              <h5 className={classes.data}>
                {dataDetails && dataDetails.all.phone}
              </h5>
            </Grid>
          </Grid>
          <Grid
            container
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: 30,
              marginTop: -20,
            }}
          >
            <Button
              style={{
                height: 40,
                width: 80,
                fontSize: 14,
              }}
              onClick={() => setOpenModal(false)}
              label="ok"
            />
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
}
