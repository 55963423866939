import React from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/button/MyButton';
import styles from '../../../styles/Layout.module.css';

// eslint-disable-next-line react/prop-types
export default function Created({ handleNext }) {
  const { t } = useTranslation();
  return (
    <div
      className="col-sm-12"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <img
        src="/img/checkOk.svg"
        alt="ok"
        className="img-category-check"
        style={{ width: '15%', height: '15%' }}
      />
      <h4 className={styles.textSuccess} style={{ marginTop: 30 }}>
        {t('newOrder.nfCreated.label')}
      </h4>
      <h5 className={styles.textSuccess}>{t('newOrder.nfCreated.label2')}</h5>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: 30,
        }}
      >
        <Button
          style={{
            marginBottom: 20,
            marginRight: 20,
            height: 50,
            width: 150,
            // fontSize: 24,
          }}
          onClick={handleNext}
          label={t('newOrder.nfCreated.btnOk')}
        />
      </div>
      <Grid item style={{ marginLeft: -40 }}>
        {/* <p className={styles.labelTitleResum}>
          <img
            id="planilha"
            src="../img/boleto.svg"
            alt="logo"
            className="img-planilhas"
          />
          Boleto para pagamento
        </p> */}
      </Grid>
    </div>
  );
}
