/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import {
  DataGrid,
  GridToolbarContainer,
  GridColumnsToolbarButton,
  GridFilterToolbarButton,
  GridColumns,
  GridRowsProp,
  GridSlotsComponent,
  DataGridProps,
} from '@material-ui/data-grid';

import { dataGridLocalizations } from '../util/localizations';
import CustomNoRowsOverlay from './CustomNoRowsOverlay';

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      borderRadius: 12,
      boxShadow:
        'rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px',
      border: '1px solid #eff1f3',
      background: 'white',
      color:
        theme.palette.type === 'light'
          ? 'rgba(0,0,0,.85)'
          : 'rgba(255,255,255,0.85)',
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      WebkitFontSmoothing: 'auto',
      letterSpacing: 'normal',
      '& .MuiDataGrid-toolbar': {
        borderRadius: '12px 12px 0px 0px',
        backgroundColor: 'white',
      },
      '& .MuiDataGrid-columnsContainer': {
        backgroundColor: theme.palette.type === 'light' ? '#fff' : '#1d1d1d',
      },
      '& .MuiDataGrid-iconSeparator': {
        display: 'none',
      },
      '& .MuiDataGrid-colCell, .MuiDataGrid-cell': {
        borderRight: `1px solid ${
          theme.palette.type === 'light' ? '#f0f0f0' : '#303030'
        }`,
      },
      '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
        borderBottom: `1px solid ${
          theme.palette.type === 'light' ? '#f0f0f0' : '#303030'
        }`,
      },
      '& .MuiDataGrid-cell': {
        color:
          theme.palette.type === 'light'
            ? 'rgba(0,0,0,.85)'
            : 'rgba(255,255,255,0.65)',
      },
      '& .MuiPaginationItem-root': {
        borderRadius: 0,
      },
      '& .MuiDataGrid-row': {
        backgroundColor: '#f2f1f1',
        '&:nth-child(2n)': {
          backgroundColor: 'white',
        },
      },
    },
  }),
);

function Toolbar() {
  return (
    <GridToolbarContainer
      style={{
        padding: '0.5em',
        borderBottom: '1px solid #f0f0f0',
      }}
    >
      <GridColumnsToolbarButton />
      <GridFilterToolbarButton />
    </GridToolbarContainer>
  );
}

// export type Props = {
//   height?: number
//   columns?: GridColumns
//   rows?: GridRowsProp
//   components?: GridSlotsComponent
// } & DataGridProps

function Table({
  height = 450,
  columns = [],
  rows = [],
  components = {},
  className,
  ...others
}) {
  const classes = useStyles();

  const toolbar = rows.length > 0 ? Toolbar : undefined;

  return (
    <div style={{ height, width: '100%' }}>
      <div style={{ display: 'flex', height: '100%' }}>
        <DataGrid
          columns={columns}
          rows={rows}
          className={clsx(classes.root, className)}
          localeText={dataGridLocalizations}
          components={{
            Toolbar: toolbar,
            NoRowsOverlay: CustomNoRowsOverlay,
            ...components,
          }}
          {...others}
        />
      </div>
    </div>
  );
}

export default Table;
