/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/Menu';
import Modal from '@material-ui/core/Modal';
import { FaEdit, FaStopCircle } from 'react-icons/fa';
import api from '../../../service/axios/api';
import useContextMenu from '../../sales/hooks/useContextMenu/useContextMenu';
import useClickPreventionOnDoubleClick from '../../sales/hooks/useClickPreventionOnDoubleClick/useClickPreventionOnDoubleClick';
import Table from '../../sales/components/Table';
import CustomToolbar from './CustomToolbar';
import { useStyles } from './TutorialModal.styles';
import { Upload } from './Upload';

const rows = [
  {
    id: '1',
    title: 'Título',
    subject: 'Assunto',
    media: 'Mídia',
    status: 'Status',
  },
];

export function TutorialModal({ update }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [selected, setSelected] = useState();
  const router = useLocation();
  const [open, setOpen] = useState(false);
  const [selectedFixed, setSelectedFixed] = useState([]);

  const { state, onOpen, onClose } = useContextMenu();
  const [tutorial, setTutorial] = useState([]);
  const loading = false;

  const columns = [
    {
      field: 'title',
      headerName: t('tutorials.new.table.title'),
      flex: 1,
    },
    {
      field: 'subject',
      headerName: t('tutorials.new.table.description'),
      flex: 1,
    },
    {
      field: 'media',
      headerName: t('tutorials.new.table.midia'),
      flex: 1,
    },
    {
      field: 'status',
      headerName: t('tutorials.new.table.status'),
      flex: 1,
    },
  ];

  const searchAll = () => {
    const cancel = new AbortController();
    api.tutorials
      .getAll(cancel)
      .then(res => {
        const list = [];
        res.retorno.forEach(r => {
          list.push({
            media: r.video_url,
            title: r.title,
            status:
              r.status === '1'
                ? t('tutorials.new.new.btnVisible')
                : t('tutorials.new.new.btnHide'),
            subject: r.description,
            id: r.id,
          });
        });
        setTutorial(list);
      })
      .catch(err => {
        console.log(err);
      });
  };
  useEffect(() => {
    searchAll();
  }, []);

  const handleOpen = () => {
    setSelected(undefined);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onClick = (params, e) => {
    setSelected(params.row);
    onOpen(e);
  };

  const onDoubleClick = (params, e) => {
    setSelected(params.row);
    onOpen(e);
  };

  const [click, doubleClick] = useClickPreventionOnDoubleClick(
    onClick,
    onDoubleClick,
  );
  const selectFixed = async param => {
    setSelectedFixed(param.selectionModel);
    //
  };
  const deleteTutorial = async () => {
    await api.tutorials.delete(selected.id);
    searchAll();
    update();
    handleClose();
  };

  const editTutorial = () => {
    handleClose();
    setOpen(true);
  };

  const bulkEdit = async status => {
    const response = await api.tutorials.updateStatus(
      status,
      selectedFixed.map(row => Number(row)),
    );

    if (response.code === 'API_RES_SUCCESS') {
      searchAll();
      update();
      handleClose();
    }
  };
  // eslint-disable-next-line react/no-unstable-nested-components
  function MyCustomToolbar() {
    return (
      <CustomToolbar selectedFixed={selectedFixed} onClickEdit={bulkEdit} />
    );
  }

  return (
    <div>
      <Box display="flex" className={classes.container} flexDirection="column">
        <div style={{ display: 'Flex', justifyContent: 'space-between' }}>
          <Typography
            className={classes.subtitle}
            variant="h5"
            color="primary"
            paragraph
          >
            {t('tutorials.new.label')}
          </Typography>
          <Button
            variant="text"
            color="primary"
            endIcon={<AddCircleIcon />}
            onClick={handleOpen}
          >
            {t('tutorials.new.btnNew')}
          </Button>
        </div>
        <Table
          onRowClick={click}
          onRowDoubleClick={doubleClick}
          columns={columns}
          rows={tutorial || rows}
          loading={loading}
          onSelectionModelChange={selectFixed}
          selectionModel={selectedFixed}
          components={{
            Toolbar: MyCustomToolbar,
          }}
          checkboxSelection
          disableSelectionOnClick
          pageSize={6}
          rowsPerPageOptions={[6]}
          // sortModel={[{ field: 'createdAt', sort: 'desc' }]}
        />
      </Box>
      {selected && (
        <Menu
          keepMounted
          open={state.mouseY !== null}
          onClose={onClose}
          anchorReference="anchorPosition"
          anchorPosition={
            state.mouseY !== null && state.mouseX !== null
              ? { top: state.mouseY, left: state.mouseX }
              : undefined
          }
        >
          <MenuItem onClick={editTutorial}>
            <ListItemIcon>
              <FaEdit />
            </ListItemIcon>
            <Typography>{t('tutorials.new.new.btnEdit')}</Typography>
          </MenuItem>

          <MenuItem onClick={deleteTutorial} component="a" download>
            <ListItemIcon>
              <FaStopCircle />
            </ListItemIcon>
            <Typography>{t('tutorials.new.new.btnDelete')}</Typography>
          </MenuItem>
        </Menu>
      )}
      <Modal open={open} onClose={handleClose}>
        <Box
          display="flex"
          className={classes.container}
          flexDirection="column"
          style={{ width: '80%' }}
        >
          <Upload onClose={handleClose} selected={selected} close={() => 1} />
        </Box>
      </Modal>
    </div>
  );
}

export default TutorialModal;
