/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
// import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
// import DeleteIcon from '@material-ui/icons/Delete';
// import FilterListIcon from '@material-ui/icons/FilterList';
import { Pagination } from '@material-ui/lab';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import {
  Grid,
  Box,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableHead,
  // Toolbar,
  // Typography,
  Paper,
  // Checkbox,
  // IconButton,
  // Tooltip,
  Menu,
  MenuItem,
  Dialog,
  TableCell,
  DialogTitle,
  DialogContent,
  // TextField,
  Typography,
} from '@material-ui/core';
import { withStyles, makeStyles, createStyles } from '@material-ui/core/styles';
import Select from 'react-select';
// import api from '../../../../../service/axios/api';
// import styles from '../../../styles/Layout.module.css';

// import Button from '../../../../../components/button/MyButton';

// import routing from '../../../../../service/routes/routing';
// import Status from '../../../../../service/translateStatus';

// import SelectOptions from './SelectOptions';
import SelectOptions from './SelectOptions';

import './tableOrderItens.css';

const useStyles = makeStyles(() =>
  createStyles({
    textNotification: {
      textAlign: 'center',
      fontFamily: 'Open Sans',
      fontSize: 14,
      fontWeight: 'bold',
      lineHeight: 'auto',
      color: '#707070',
    },
  }),
);

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#005D9F',
    fontFamily: 'Open Sans',
    color: theme.palette.common.white,
  },
  body: {
    color: '#717171',
    fontFamily: 'Open Sans',
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:hover': {
      backgroundColor: '#41b490',
      color: '#41b490',
    },
  },
}))(TableRow);

const options = [
  {
    label: '25',
    value: 25,
  },
  {
    label: '50',
    value: 50,
  },
  {
    label: '100',
    value: 100,
  },
];

const EnhancedTableHead = () => {
  return (
    <TableHead>
      <TableRow>
        <StyledTableCell component="th">Razão Social</StyledTableCell>
        <StyledTableCell component="th">Emissão NF</StyledTableCell>
        <StyledTableCell component="th">Data de Pagamento</StyledTableCell>
        <StyledTableCell component="th">Valor-NF</StyledTableCell>
        <StyledTableCell component="th">Status</StyledTableCell>
        <StyledTableCell />
      </TableRow>
    </TableHead>
  );
};

export default function EnhancedTable({
  type,
  orders,
  totalPages,
  setPage,
  page,
  itens,
  setItens,
  setStatus,
  // search,
  chipData,
  setChipData,
  searchItems,
  setOrderBy,
}) {
  // const history = useHistory();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [menuOptions, setMenuOptions] = useState(null);
  // const [openTooltip, setOpenTooltip] = useState(false);

  const handleMenu = (row, event) => {
    setMenuOptions(row);
    // setMenuOptions(row);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openDetails = () => {
    setOpenModal(true);
    // onRowClick(menuOptions);
    setAnchorEl(null);
  };

  const [rows, setRows] = useState([]);

  const classes = useStyles();

  useEffect(() => {
    const listOrder = [];
    orders.forEach(or => {
      listOrder.push({
        id: or.id,
        rz: or.invoice_owner_name,
        datePayment: or.invoice_payment_date,
        invoiceIssueDate: or.invoice_issue_date,
        value: or.invoice_price,
        status: or.status,
        all: or,
      });
    });

    setRows(listOrder);
  }, [orders]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <>
      <SelectOptions
        setStatus={setStatus}
        setChipData={setChipData}
        chipData={chipData}
        find={searchItems}
        setOrderBy={setOrderBy}
        product={[]}
      />
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size="medium"
            >
              <EnhancedTableHead
                rowCount={rows.length}
                handleMenu={handleMenu}
                type={type}
              />
              <TableBody>
                {rows ? (
                  rows.length > 0 ? (
                    rows.map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <React.Fragment key={row.id}>
                          <StyledTableRow tabIndex={-1}>
                            <StyledTableCell
                              component="th"
                              id={labelId}
                              scope="row"
                            >
                              {row.rz}
                            </StyledTableCell>
                            <StyledTableCell>
                              {row.invoiceIssueDate &&
                                moment(row.invoiceIssueDate).format(
                                  'DD/MM/YYYY',
                                )}
                            </StyledTableCell>
                            <StyledTableCell>
                              {row.datePayment &&
                                moment(row.datePayment).format('DD/MM/YYYY')}
                            </StyledTableCell>
                            <StyledTableCell>
                              {new Intl.NumberFormat('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                              }).format(row.value)}
                            </StyledTableCell>
                            <StyledTableCell>{row.status}</StyledTableCell>
                            <StyledTableCell component="th" align="right">
                              <MoreVertIcon
                                onClick={e => handleMenu(row, e)}
                                style={{ cursor: 'pointer' }}
                              />
                            </StyledTableCell>
                          </StyledTableRow>
                        </React.Fragment>
                      );
                    })
                  ) : (
                    <StyledTableRow>
                      <StyledTableCell colSpan={11} align="center">
                        {t('Order.table.noResult')}
                      </StyledTableCell>
                    </StyledTableRow>
                  )
                ) : (
                  <StyledTableRow>
                    <StyledTableCell colSpan={11} align="center" />
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Menu
            id="menu-appbar"
            style={{ borderRadius: '25px' }}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              className={classes.textNotification}
              onClick={openDetails}
            >
              Detalhes
            </MenuItem>
            {/* <MenuItem
              className={classes.textNotification}
              onClick={() => setOpenModal(true)}
            >
              {t('Order.table.orderDetails')}
            </MenuItem>

            <MenuItem
              className={classes.textNotification}
              onClick={handleClose}
            >
              {t('Order.table.orderCancel')}
            </MenuItem> */}
          </Menu>
        </Paper>
      </Box>
      <Grid
        container
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: 15,
        }}
        spacing={2}
        direction="row"
      >
        <Grid item>
          <Select
            className="basic-single"
            classNamePrefix="select"
            placeholder="Itens"
            value={itens}
            onChange={setItens}
            options={options}
          />
        </Grid>
        <Grid item>
          <Pagination
            size="large"
            count={totalPages}
            page={page}
            onChange={handleChangePage}
            variant="outlined"
            shape="rounded"
          />
        </Grid>
      </Grid>
      <Dialog
        onClose={() => setOpenModal(false)}
        open={openModal}
        fullWidth
        // maxWidth="lg"
      >
        <DialogTitle>Detalhamento situação do extrado</DialogTitle>
        <DialogContent>
          <>
            {console.log(menuOptions)}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <h5 style={{ fontWeight: 'bold' }}>AGENTE: xxxxxx</h5>
            </div>
            <Box mt={0} m={6}>
              <Grid container spacing={3}>
                <Grid item lg={4} sm={6} xs={12}>
                  <Typography variant="subtitle1" color="initial">
                    Razão Social:
                  </Typography>
                  <Typography variant="body2" color="initial">
                    {menuOptions?.rz}
                  </Typography>
                </Grid>
                <Grid item lg={4} sm={6} xs={12}>
                  <Typography variant="subtitle1" color="initial">
                    Comissão Agente:
                  </Typography>
                  <Typography variant="body2" color="initial">
                    0,00%
                  </Typography>
                </Grid>
                <Grid item lg={4} sm={6} xs={12}>
                  <Typography variant="subtitle1" color="initial">
                    Valor NF:
                  </Typography>
                  <Typography variant="body2" color="initial">
                    {menuOptions?.value &&
                      new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      }).format(menuOptions.value)}
                  </Typography>
                </Grid>
                <Grid item lg={4} sm={6} xs={12}>
                  <Typography variant="subtitle1" color="initial">
                    UF:
                  </Typography>
                  <Typography variant="body2" color="initial">
                    SP
                  </Typography>
                </Grid>
                <Grid item lg={4} sm={6} xs={12}>
                  <Typography variant="subtitle1" color="initial">
                    No. Cargas:
                  </Typography>
                  <Typography variant="body2" color="initial">
                    X.XXX
                  </Typography>
                </Grid>
                <Grid item lg={4} sm={6} xs={12}>
                  <Typography variant="subtitle1" color="initial">
                    Taxa adm:
                  </Typography>
                  <Typography variant="body2" color="initial">
                    0,00%
                  </Typography>
                </Grid>
                <Grid item lg={4} sm={6} xs={12}>
                  <Typography variant="subtitle1" color="initial">
                    Cnpj:
                  </Typography>
                  <Typography variant="body2" color="initial">
                    128.425.330/0001-22
                  </Typography>
                </Grid>
                <Grid item lg={4} sm={6} xs={12}>
                  <Typography variant="subtitle1" color="initial">
                    Valor de premisção:
                  </Typography>
                  <Typography variant="body2" color="initial">
                    R$ x,00
                  </Typography>
                </Grid>
                {/* <Grid item lg={4} sm={6} xs={12} /> */}

                <Grid item lg={4} sm={6} xs={12}>
                  <Typography variant="subtitle1" color="initial">
                    Valor Comissão:
                  </Typography>
                  <Typography variant="body2" color="initial">
                    R$ X,00
                  </Typography>
                </Grid>
                <Grid item lg={4} sm={6} xs={12} />
              </Grid>
            </Box>
            {/* <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
              }}
            >
              <div>
                <h6 style={{ fontWeight: 'bold' }}>Situação da comissão</h6>
                <p>Aguardando pagamento</p>
              </div>
              <div style={{ flex: 1, marginLeft: 15 }}>
                <h6 style={{ fontWeight: 'bold' }}>Observação</h6>
                <TextField
                  id="outlined-multiline-static"
                  // style={{ backgroundColor: '#c1c1c1' }}
                  disabled
                  multiline
                  fullWidth
                  rows={4}
                  variant="outlined"
                />
              </div>
            </div> */}
          </>
        </DialogContent>
      </Dialog>
    </>
  );
}
