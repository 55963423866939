/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
// import { useTranslation } from 'react-i18next';

// import { useHistory } from 'react-router-dom';

import {
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Paper,
} from '@material-ui/core';
// import mastercard from './img/mastercard.svg';
// import moment from 'moment-timezone';

function createData(company, amount, order) {
  return {
    company,
    amount,
    order,
  };
}

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  table: {
    minWidth: 1100,
  },
});

// const StyledTableCell = withStyles(() => ({
//   head: {
//     // backgroundColor: theme.palette.common.black,
//     // color: theme.palette.common.white,
//   },
//   body: {
//     fontSize: 14,
//   },
// }))(TableCell);

// const StyledTableRow = withStyles(theme => ({
//   root: {
//     '&:nth-of-type(odd)': {
//       backgroundColor: theme.palette.action.hover,
//     },
//   },
// }))(TableRow);
const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#8884d8',
    fontFamily: 'Open Sans',
    color: theme.palette.common.white,
  },
  body: {
    color: '#717171',
    fontFamily: 'Open Sans',
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:hover': {
      backgroundColor: '#41b490',
      color: '#41b490',
    },
  },
}))(TableRow);

function EnhancedTableHead(props) {
  // eslint-disable-next-line no-unused-vars
  const { onSelectAllClick, numSelected, rowCount, onRequestSort } = props;
  // const { t } = useTranslation();

  return (
    <TableHead>
      <TableRow>
        <StyledTableCell align="left" component="th">
          CLIENTE
        </StyledTableCell>
        <StyledTableCell align="center" component="th">
          NÚMERO DE PEDIDOS
        </StyledTableCell>
        <StyledTableCell align="right" component="th">
          VOLUME FINANCEIRO
        </StyledTableCell>
      </TableRow>
    </TableHead>
  );
}

export default function TopFiveCompanies({ docs }) {
  // const { t } = useTranslation();
  const classes = useStyles();
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const list = [];
    docs.forEach(d => {
      list.push(createData(d.company, d.amount, d.order));
    });
    setRows(list);
  }, [docs]);

  return (
    <Paper className={classes.root}>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <EnhancedTableHead />
          <TableBody>
            {rows.map(row => {
              return (
                <StyledTableRow
                  tabIndex={-1}
                  key={row.id}
                  style={{ cursor: 'pointer' }}
                >
                  <StyledTableCell>{row.company}</StyledTableCell>
                  <StyledTableCell align="center">{row.order}</StyledTableCell>
                  <StyledTableCell align="right">
                    {new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(row.amount)}
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
