/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useRef, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import {
  Grid,
  Box,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import Select from 'react-select';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
// import Paper from '@material-ui/core/Paper';
import AddIcon from '@material-ui/icons/Add';
import routing from '../../service/routes/routing';
import Loading from '../../components/loading/Loading';
import api from '../../service/axios/api';
import styles from '../../styles/Layout.module.css';
import FlashContext from '../../components/contexts/FlashContext';

import CardsTable from './CardsTable';
import Checkout from './secodtab/Checkout';

const cancelStyle = {
  cursor: 'pointer',
  color: '#657588',
  fontWeight: 'bold',
  fontFamily: 'Open Sans',
  marginTop: 5,
};
const customStyles = {
  control: provided => ({
    ...provided,
    width: 170,
    height: 40,
    borderRadius: 10,
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  option: provided => ({
    ...provided,
  }),
};
const customStyles1 = {
  control: provided => ({
    ...provided,
    width: 125,
    height: 40,
    borderRadius: 10,
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  option: provided => ({
    ...provided,
  }),
};

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: 10,
    margin: 0,
  },
  chip: {
    margin: 10,
  },
  textField: {
    // width: '90%',
    // // height: '40px',
    // marginLeft: 'auto',
    // marginRight: 'auto',
    // paddingTop: '2px',
    // paddingBottom: 0,
    // marginTop: 0,
    // fontWeight: 500,
    // backgroundColor: '#fff',
  },
  myTextField: {
    backgroundColor: 'none',
    boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
    border: 'none',
    color: '#717171',
    fontWeight: 'bold',
    fontFamily: 'Open Sans',
  },
  input: {
    backgroundColor: '#fff',
    marginTop: 0,
    // color: 'white',
  },
  text: {
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
    color: '#717171',
  },
});

function BaseCards() {
  // const [filter, setFilter] = useState({ label: 'Cartão', value: 'card' });
  const history = useHistory();
  const { t } = useTranslation();
  const classes = useStyles();
  const { notifyError, notifySuccess } = useContext(FlashContext);
  const [statusSelected, setStatusSelected] = useState({
    label: t('baseCards.filters.all'),
    value: '',
  });
  const searchRef = useRef(null);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [total, setTotal] = useState(0);
  const [itens, setItens] = useState(10);
  const [searchType, setSearchType] = useState(null);

  const optionsStatus = [
    {
      label: t('status.AwaitingShipping'),
      value: 'AwaitingShipping',
    },
    {
      label: t('status.AwaitingCardRelease'),
      value: 'AwaitingCardRelease',
    },
    {
      label: t('status.AwaitingPayment'),
      value: 'AwaitingPayment',
    },
    {
      label: t('status.AwaitingIssuerLinkResponse'),
      value: 'AwaitingIssuerLinkResponse',
    },
    {
      label: t('status.Active'),
      value: 'Active',
    },
    {
      label: t('status.Blocked'),
      value: 'Blocked',
    },
    {
      label: t('status.Canceled'),
      value: 'Canceled',
    },
    {
      label: t('status.InStock'),
      value: 'InStock',
    },
    {
      label: t('status.VinculationError'),
      value: 'VinculationError',
    },
    {
      label: t('status.Fraud'),
      value: 'Fraud',
    },
    {
      label: t('baseCards.filters.all'),
      value: '',
    },
    {
      label: t('status.AwaitingIssuerLink'),
      value: 'AwaitingIssuerLink',
    },
  ];

  const optionsProduct = [
    {
      label: t('baseCards.filters.purchases'),
      value: 'compra',
    },
    {
      label: t('baseCards.filters.withdraw'),
      value: 'saque_compra',
    },
    {
      label: t('baseCards.filters.all'),
      value: '',
    },
  ];

  const [options, setOptions] = useState([
    {
      label: t('baseCards.filters.brand'),
      value: 'brand',
    },
    {
      label: t('baseCards.filters.invoiceFrom'),
      value: 'billed',
    },
    {
      label: t('baseCards.filters.campaign'),
      value: 'campaign',
    },
    {
      label: t('baseCards.filters.cpf'),
      value: 'cpf',
    },
    {
      label: t('baseCards.filters.name'),
      value: 'name',
    },
    {
      label: t('baseCards.filters.cardNumber'),
      value: 'cardNumber',
    },
    {
      label: t('baseCards.filters.proxy'),
      value: 'proxy',
    },
    {
      label: t('baseCards.filters.expirationDate'),
      value: 'validity',
    },
  ]);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState(t('baseCards.filters.searchMsg'));

  const [openCancel, setOpenCancel] = useState(false);
  const [openActiv, setOpenActiv] = useState(false);
  const [openBlock, setOpenBlock] = useState(false);
  const [selected, setSelected] = useState([]);

  const [openVinc, setOpenVinc] = useState(false);
  const [myFile, setMyfile] = useState();
  const nameVinc = useRef(null);
  const cpfVinc = useRef(null);
  const fileXls = useRef(null);

  const [open2Via, setOpen2Via] = useState(false);
  const [data2, setData2] = useState();

  const [productSelected, setProductSelected] = useState([
    {
      label: t('baseCards.filters.all'),
      value: '',
    },
  ]);

  const [chipData, setChipData] = React.useState([]);

  useEffect(() => {
    if (myFile) {
      sendBookAtiv();
    }
  }, [myFile]);

  const internSearch = btn => {
    if (searchType?.value !== '' && searchRef?.current?.value !== '') {
      addChip(btn);
    } else {
      search();
    }
  };

  const search = extra => {
    setSelected([]);
    setLoading(true);
    setMsg(t('baseCards.filters.searchMsg'));
    const cancel = new AbortController();
    setData([]);
    // filter, status, searchType, search, itens, page, cancel
    api.cards
      .get(
        extra,
        statusSelected.value,
        chipData,
        productSelected.value,
        itens,
        page,
        cancel,
      )
      .then(res => {
        console.log(res.docs);
        setData(res.docs);
        setTotal(res.total);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    search();
  }, [page, itens, statusSelected, productSelected]);

  const downloadCardsBase = () => {
    const cancel = new AbortController();
    setLoading(true);
    setMsg(t('baseCards.filters.searchMsg'));
    api.cards
      .getBase(statusSelected.value, chipData, productSelected.value, cancel)
      .then(res => {
        const url = res.retorno.link;
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'carga_template.xlsx'); // or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  };

  const sendBookAtiv = () => {
    setLoading(true);
    setMsg(t('baseCards.filters.vincMsg'));
    api.cards
      .vincBookCard(myFile)
      .then(() => {
        notifySuccess(t('baseCards.filters.vincSuccessMsg'));
        window.location.reload();
      })
      .catch(err => {
        notifyError(err.message);
        console.log(err);
      })
      .finally(() => setLoading(false));
  };

  const handleDelete = chipToDelete => () => {
    setOptions([
      ...options,
      { label: chipToDelete.by, value: chipToDelete.byType },
    ]);
    setChipData(chips => chips.filter(chip => chip.key !== chipToDelete.key));
  };
  const addChip = btn => {
    if (searchType) {
      const orig = _.cloneDeep(options);
      setChipData([
        ...chipData,
        {
          key: chipData.length + 1,
          label: searchRef.current.value,
          by: searchType.label,
          byType: searchType.value,
        },
      ]);
      const find = orig.findIndex(e => e.value === searchType.value);
      orig.splice(find, 1);
      setSearchType(null);
      setOptions(orig);
      if (btn) {
        search({
          label: searchRef.current.value,
          byType: searchType.value,
        });
      }
      searchRef.current.value = '';
    } else {
      notifyError(t('baseCards.filters.searchTypeError'));
    }
  };

  const goVinc = () => {
    if (selected.length > 1) {
      notifyError(t('baseCards.filters.minCardError'));
    } else if (selected.length === 0) {
      notifyError(t('baseCards.filters.maxCardError'));
    } else {
      const iV = data.find(e => e.id === selected[0]);
      if (iV.status === 'InStock' || iV.status === 'VinculationError') {
        setOpenVinc(true);
      } else {
        notifyError(t('baseCards.filters.errorStatusOp'));
      }
    }
  };

  const go2Via = () => {
    if (selected.length > 1) {
      notifyError(t('baseCards.filters.maxSecond'));
    } else if (selected.length === 0) {
      notifyError(t('baseCards.filters.minSecond'));
    } else {
      const found = data.find(e => e.id === selected[0]);
      setData2(found);
      setOpen2Via(true);
    }
  };

  const sendVinc = () => {
    setLoading(true);
    setMsg(t('baseCards.filters.sendRequestMsg'));
    const iV = _.cloneDeep(data);
    const found = iV.find(e => e.id === selected[0]);
    found.Individual = {};
    api.cards
      .vincCard(found.proxy, nameVinc.current.value, cpfVinc.current.value)
      .then(res => {
        found.Individual.name = res.retorno.name;
        found.Individual.cpf = res.retorno.cpf;
        found.status = 'AwaitingIssuerLink';
        setData(iV);
        setOpenVinc(false);
        notifySuccess(t('baseCards.filters.vincUnitSuccessMsg'));
        setSelected([]);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const downloadPlanModel = () => {
    const cancel = new AbortController();
    setLoading(true);
    setMsg(t('baseCards.filters.searchMsg'));
    api.cards
      .getSample(cancel)
      .then(res => {
        const url = res.retorno.link;
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'carga_template.xlsx'); // or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  };

  const teclaEnter = e => {
    if (e.key === 'Enter') {
      internSearch(true);
    }
  };

  return (
    <>
      <Loading open={loading} msg={msg} />
      <Grid container spacing={3} style={{ marginTop: '50px' }}>
        <p
          className={styles.textMenu}
          onKeyDown={() => history.push(routing.dashboard)}
          onClick={() => history.push(routing.dashboard)}
        >
          {t('initialMenu')}
        </p>
        <div
          className="col-sm-12"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <h4 className={styles.textTitle}>{t('baseCards.label')}</h4>
        </div>
        <Grid item xs={12} sm={5}>
          <Grid container spacing={0} style={{ justifyContent: 'start' }}>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ marginLeft: 15, display: 'flex' }}
            >
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Box flexGrow={1}>
                  <label>{t('baseCards.filters.status')}:</label>
                </Box>
                <Box ml={1} mt={1}>
                  <Select
                    // fullWidth
                    styles={customStyles}
                    classNamePrefix="select"
                    placeholder={t('baseCards.filters.type')}
                    isSearchable={false}
                    options={optionsStatus}
                    onChange={setStatusSelected}
                    value={statusSelected}
                    menuPortalTarget={document.body}
                    // style={{ zIndex: 999, width: 600 }}
                  />
                </Box>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Box flexGrow={1} style={{ marginLeft: 15 }}>
                  <label>{t('baseCards.filters.product')}:</label>
                </Box>
                <Box ml={1} mt={1}>
                  <Select
                    // fullWidth
                    styles={customStyles}
                    classNamePrefix="select"
                    placeholder={t('baseCards.filters.product')}
                    isSearchable={false}
                    options={optionsProduct}
                    onChange={setProductSelected}
                    value={productSelected}
                    menuPortalTarget={document.body}
                    // style={{ zIndex: 999, width: 600 }}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={1} style={{ justifyContent: 'end' }}>
            <Grid item xs={12} sm={3}>
              <Select
                fullWidth
                styles={customStyles1}
                onChange={setSearchType}
                value={searchType}
                classNamePrefix="select"
                placeholder={t('baseCards.filters.type')}
                isSearchable={false}
                options={options}
                menuPortalTarget={document.body}
                style={{ zIndex: 999 }}
              />
            </Grid>
            <Grid item xs={12} sm={6} style={{ display: 'flex' }}>
              <TextField
                onKeyUpCapture={e => teclaEnter(e)}
                inputRef={searchRef}
                color="secondary"
                width="400px"
                // margin="none"
                className={classes.textField}
                // fullWidth
                size="small"
                style={{ maxHeight: 10, minWidth: 300 }}
                // label="Outlined"
                variant="outlined"
                InputProps={{
                  className: classes.input,
                }}
              />
              <Button
                onClick={() => addChip(false)}
                variant="contained"
                style={{ marginLeft: 5, marginRight: 5 }}
              >
                <AddIcon />
              </Button>
              <Button
                // style={{ marginLeft: 10, marginRight: 5 }}
                onClick={internSearch}
                variant="contained"
              >
                {t('baseCards.filters.btnSearch')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Grid item className={classes.root}>
            {chipData.map(dt => {
              let icon;

              return (
                <li key={data.key}>
                  <Chip
                    icon={icon}
                    label={`${dt.by} | ${dt.label}`}
                    onDelete={handleDelete(dt)}
                    className={classes.chip}
                  />
                </li>
              );
            })}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12}>
          <CardsTable
            docs={data}
            setDocs={setData}
            page={page}
            setPage={setPage}
            itens={itens}
            setItens={setItens}
            total={total}
            openCancel={openCancel}
            setOpenCancel={setOpenCancel}
            openActiv={openActiv}
            setOpenActiv={setOpenActiv}
            openBlock={openBlock}
            setOpenBlock={setOpenBlock}
            setSelected={setSelected}
            selected={selected}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Grid
            container
            spacing={8}
            style={{ justifyContent: 'end', marginTop: '6px' }}
          >
            {/* <Stack direction="row" spacing={2}> */}
            <Grid item>
              <h4
                style={{
                  cursor: 'pointer',
                  color: '#657588',
                  // fontWeight: 'bold',
                  marginRight: 30,
                  // fontFamily: 'Open Sans',
                  fontSize: 18,
                }}
                onClick={() => selected.length > 0 && setOpenActiv(true)}
              >
                {t('baseCards.action.activate')}
              </h4>
            </Grid>
            <Grid item>
              <h4
                style={{
                  cursor: 'pointer',
                  color: '#657588',
                  // fontWeight: 'bold',
                  marginRight: 30,
                  fontFamily: 'Open Sans',
                  fontSize: 18,
                }}
                onClick={() => selected.length > 0 && setOpenBlock(true)}
              >
                {t('baseCards.action.blocking')}
              </h4>
            </Grid>
            <Grid item>
              <h4
                style={{
                  cursor: 'pointer',
                  color: '#657588',
                  marginRight: 30,
                  // fontWeight: 'bold',
                  fontFamily: 'Open Sans',
                  fontSize: 18,
                }}
                onClick={() => selected.length > 0 && setOpenCancel(true)}
              >
                {t('baseCards.action.cancel')}
              </h4>
            </Grid>
            <Grid item>
              <h4
                style={{
                  cursor: 'pointer',
                  marginRight: 30,
                  color: '#657588',
                  // fontWeight: 'bold',
                  fontFamily: 'Open Sans',
                  fontSize: 18,
                }}
                onClick={go2Via}
              >
                {t('baseCards.action.duplicateCard')}
              </h4>
            </Grid>
            <Grid item>
              <h4
                style={{
                  cursor: 'pointer',
                  marginRight: 30,
                  color: '#657588',
                  // fontWeight: 'bold',
                  fontFamily: 'Open Sans',
                  fontSize: 18,
                }}
                onClick={goVinc}
              >
                {t('baseCards.action.link')}
              </h4>
            </Grid>
            {/* </Stack> */}
          </Grid>
        </Grid>
        <Grid
          item
          sm={12}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'rigth',
            justifyContent: 'start',
            marginRight: 15,
            marginTop: 15,
          }}
        >
          <Grid item>
            <h4
              style={{
                marginRight: 30,
                color: '#41B490',
                fontFamily: 'Open Sans',
                fontSize: 18,
              }}
            >
              <img
                style={{ cursor: 'pointer' }}
                onClick={downloadPlanModel}
                id="planilha"
                src="../img/download.svg"
                alt="logo"
                className="img-planilhas"
              />
              <img
                onClick={() => fileXls.current.click()}
                style={{ cursor: 'pointer' }}
                id="planilha"
                src="../img/upload.svg"
                alt="logo"
                className="img-planilhas"
              />
              {t('baseCards.action.bullkLink')}
            </h4>
          </Grid>
          <Grid item>
            <h4
              style={{
                cursor: 'pointer',
                marginRight: 30,
                color: '#41B490',
                fontFamily: 'Open Sans',
                fontSize: 18,
              }}
              onClick={downloadCardsBase}
            >
              <img
                id="refresh"
                src="./img/download.svg"
                alt="logo"
                style={{ height: 20, width: 20, marginRight: 15 }}
              />
              {t('baseCards.action.base')}
            </h4>
          </Grid>
        </Grid>
      </Grid>
      <input
        ref={fileXls}
        type="file"
        style={{
          opacity: 0,
        }}
        // accept="media_type"
        onChange={() => setMyfile(fileXls.current.files[0])}
        className="form-control"
        id="inputNumSerie"
        // placeholder="Arquivo DWG do projeto"
      />
      <Dialog
        onClose={() => setOpenVinc(false)}
        open={openVinc}
        fullWidth
        maxWidth="sm"
      >
        <Loading open={loading} msg="Vinculando cartão..." />
        <DialogTitle id="simple-dialog-title">Vinculo de cartão</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className="box-new-card">
              <Grid item>
                <p className={styles.labelTitleResum}>Nome</p>
                <TextField
                  inputRef={nameVinc}
                  className={classes.text}
                  variant="outlined"
                  size="small"
                  fullWidth
                  // value={refQtdCards}
                  // onChange={e => setRefQtdCards(e.target.value)}
                  // inputRef={refQtdCards}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.myTextField,
                      input: classes.text,
                    },
                  }}
                />
              </Grid>
              <Grid item style={{ marginBottom: 10 }}>
                <p className={styles.labelTitleResum}>CPF</p>
                <TextField
                  type="number"
                  inputRef={cpfVinc}
                  className={classes.text}
                  variant="outlined"
                  size="small"
                  fullWidth
                  // value={refQtdCards}
                  // onChange={e => setRefQtdCards(e.target.value)}
                  // inputRef={refQtdCards}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.myTextField,
                      input: classes.text,
                    },
                  }}
                />
              </Grid>
            </div>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <h6 style={cancelStyle} onClick={() => setOpenVinc(false)}>
            Cancelar
          </h6>
          <Button
            style={{ height: 40 }}
            onClick={sendVinc}
            color="primary"
            variant="contained"
          >
            Vincular
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        onClose={() => setOpen2Via(false)}
        open={open2Via}
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          <Checkout
            setOpenModal={setOpen2Via}
            data={data2}
            allData={data}
            setAllData={setData}
            setSelected={setSelected}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default BaseCards;
