/* eslint-disable react/prop-types */

import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';

// import { useHistory } from 'react-router-dom';

import {
  TablePagination,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Paper,
} from '@material-ui/core';

// import routing from '../../service/routes/routing';

import Status from '../../service/translateStatus';
// import GetAppIcon from '@material-ui/icons/GetApp';

// import { useExcelDownloder } from 'react-xls';

// import Dialog from '@material-ui/core/Dialog';
// import DialogTitle from '@material-ui/core/DialogTitle';
// import DialogActions from '@material-ui/core/DialogActions';

// import Button from '@material-ui/core/Button';

// import Select from 'react-select';
// import Grid from '@material-ui/core/Grid';
// import TextField from '@material-ui/core/TextField';

// import _ from 'lodash';
// // import MyExcelExport from './MyExcelExport';
// import Loading from '../../components/loading/Loading';

// import FlashContext from '../../components/contexts/FlashContext';

// import api from '../../service/axios/api';

// const optionsStatus = [
//   {
//     label: 'Ativo',
//     value: 'Active',
//   },
//   {
//     label: 'Aguardando Link',
//     value: 'AwatingIssuerLink',
//   },
//   {
//     label: 'Aguardando finalizar',
//     value: 'AwaitingRelease',
//   },
//   {
//     label: 'Aguardando pagamento',
//     value: 'AwaitingPayment',
//   },
//   {
//     label: 'Estoque',
//     value: 'InStock',
//   },
//   {
//     label: 'Fraudado',
//     value: 'Fraud',
//   },
//   {
//     label: 'Cancelado',
//     value: 'Canceled',
//   },
// ];

// const optionsFlag = [
//   {
//     label: 'MasterCard',
//     value: 1,
//   },
//   {
//     label: 'Visa',
//     value: 2,
//   },
//   {
//     label: 'Cabal',
//     value: 3,
//   },
//   {
//     label: 'Todos',
//     value: 4,
//   },
// ];

// const customStylesEdit = {
//   control: provided => ({
//     ...provided,
//     // width: 105,
//     height: 40,
//     borderRadius: 10,
//   }),
//   indicatorSeparator: () => ({ display: 'none' }),
//   option: provided => ({
//     ...provided,
//   }),
//   menuPortal: base => ({ ...base, zIndex: 9999 }),
// };

function createData(
  nf,
  nfLink,
  id,
  cpf,
  name,
  proxy,
  nfNd,
  order,
  process,
  conciliation,
  qtd,
  value,
  status,
  all,
) {
  return {
    nf,
    nfLink,
    id,
    name,
    cpf,
    status,
    proxy,
    nfNd,
    order,
    process,
    conciliation,
    qtd,
    value,
    all,
  };
}

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  table: {
    minWidth: 1100,
  },
});

// const StyledTableCell = withStyles(() => ({
//   head: {
//     // backgroundColor: theme.palette.common.black,
//     // color: theme.palette.common.white,
//   },
//   body: {
//     fontSize: 14,
//   },
// }))(TableCell);

// const StyledTableRow = withStyles(theme => ({
//   root: {
//     '&:nth-of-type(odd)': {
//       backgroundColor: theme.palette.action.hover,
//     },
//   },
// }))(TableRow);

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#005D9F',
    fontFamily: 'Open Sans',
    color: theme.palette.common.white,
  },
  body: {
    color: '#717171',
    fontFamily: 'Open Sans',
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:hover': {
      backgroundColor: '#41b490',
      color: '#41b490',
    },
  },
}))(TableRow);

// eslint-disable-next-line no-unused-vars
export default function ReleaseMoneyTableCR({
  docs,
  // setDocs,
  page,
  setPage,
  itens,
  setItens,
  total,
}) {
  const classes = useStyles();
  // const history = useHistory();
  const [rows, setRows] = useState([]);
  // const [dataExcel, setDataExcel] = useState();
  // const [dataEdit, setDataEdit] = useState();
  // const [typeSelected, setTypeSelected] = useState({
  //   label: 'tipo',
  //   value: 'value',
  // });

  // const [msg, setMsg] = useState('');
  // const [loading, setLoading] = useState(false);

  // const { notifyError, notifySuccess } = useContext(FlashContext);

  // const name = useRef(null);
  // const cpf = useRef(null);
  // const proxy = useRef(null);
  // const cardNumber = useRef(null);

  // const { ExcelDownloder, Type } = useExcelDownloder();

  // useEffect(() => {
  //   if (dataEdit) {
  //     switch (dataEdit.status) {
  //       case 'Active':
  //         setTypeSelected({
  //           label: 'Ativo',
  //           value: 'Active',
  //         });
  //         break;
  //       case 'AwatingIssuerLink':
  //         setTypeSelected({
  //           label: 'Aguardando Link',
  //           value: 'AwatingIssuerLink',
  //         });
  //         break;
  //       case 'AwaitingRelease':
  //         setTypeSelected({
  //           label: 'Aguardando finalizar',
  //           value: 'AwaitingRelease',
  //         });
  //         break;
  //       case 'InStock':
  //         setTypeSelected({
  //           label: 'Estoque',
  //           value: 'InStock',
  //         });
  //         break;
  //       case 'Fraud':
  //         setTypeSelected({
  //           label: 'Fraudado',
  //           value: 'Fraud',
  //         });
  //         break;
  //       case 'Canceled':
  //         setTypeSelected({
  //           label: 'Cancelado',
  //           value: 'Canceled',
  //         });
  //         break;
  //       default:
  //         console.log('Deu erro');
  //     }
  //   }
  // }, [dataEdit]);

  useEffect(() => {
    const list = [];
    console.log(docs);
    docs.forEach(d => {
      list.push(
        createData(
          d.nf,
          d.nf_link,
          d.id,
          d.cpf,
          d.name,
          d.proxy,
          d.nd,
          d.pedido,
          d.processamento,
          d.conciliacao,
          d.qtd,
          d.valor,
          d.status,
          d,
        ),
      );
      // listCard.push({
      //   CardId: d.id,
      //   ClientId: d.Individual && d.Individual.id,
      //   Nome: d.Individual && d.Individual.name,
      //   CPF: d.Individual && d.Individual.cpf,
      //   Status: d.status,
      //   Proxy: d.proxy,
      //   Card: d.card_number,
      //   Bandeira: d.brand,
      //   Faturado: d.Billed,
      // });
    });
    // setDataExcel({ Cartoes: listCard });
    setRows(list);
  }, [docs]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setItens(event.target.value);
  };

  // const edit = data => {
  //   setDataEdit(data);
  //   setOpen(true);
  // };

  // const mountDataExcel = d => {
  //   return <MyExcelExport allData={d} />;
  // };

  // const updateCard = e => {
  //   e.preventDefault();
  //   setMsg('Atualizando cartão...');
  //   setLoading(true);
  //   const orig = _.cloneDeep(docs);
  //   api.product
  //     .updateCardAdmin(
  //       dataEdit.cardId,
  //       typeSelected.value,
  //       cardNumber.current.value,
  //       proxy.current.value,
  //     )
  //     .then(() => {
  //       setMsg('Atualizando cliente...');
  //       api.individual
  //         .update(dataEdit.clientId, name.current.value, cpf.current.value)
  //         .then(() => {
  //           const found = orig.indexOf(
  //             orig.find(el => el.id === dataEdit.cardId),
  //           );
  //           const dest = orig.find(el => el.id === dataEdit.cardId);

  //           dest.Individual.name = name.current.value;
  //           dest.Individual.cpf = cpf.current.value;
  //           dest.proxy = proxy.current.value;
  //           dest.card_number = cardNumber.current.value;
  //           dest.status = typeSelected.value;
  //           orig.splice(found, 1, dest);
  //           setDocs(orig);

  //           setLoading(false);
  //           notifySuccess('Atualização realizada com sucesso');
  //           setOpen(false);
  //         })
  //         .catch(err => {
  //           setLoading(false);
  //           notifyError('Erro ao atualizar. Tente novamente.');
  //           console.log(err);
  //         });
  //     })
  //     .catch(() => {
  //       setLoading(false);
  //       notifyError('Erro ao atualizar. Tente novamente.');
  //     });
  // };

  return (
    <>
      <Paper className={classes.root}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Nº do Pedido</StyledTableCell>
                <StyledTableCell>NF</StyledTableCell>
                <StyledTableCell>ND</StyledTableCell>
                <StyledTableCell>Portador</StyledTableCell>
                <StyledTableCell>CPF</StyledTableCell>
                <StyledTableCell>Proxy ou Conta</StyledTableCell>
                <StyledTableCell>Valor</StyledTableCell>
                <StyledTableCell align="right">Status</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <StyledTableRow key={i}>
                  <StyledTableCell>{row.order}</StyledTableCell>
                  <StyledTableCell>{row.nf}</StyledTableCell>
                  <StyledTableCell>{row.nfNd}</StyledTableCell>
                  <StyledTableCell>{row.name}</StyledTableCell>
                  <StyledTableCell style={{ cursor: 'pointer' }}>
                    {row.cpf}
                  </StyledTableCell>
                  <StyledTableCell>{row.proxy}</StyledTableCell>
                  <StyledTableCell>
                    {row.value && `R$${row.value}`}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {Status(row.status)}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100, 1000, 50000]}
          component="div"
          count={total}
          rowsPerPage={itens}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      {/* MODAL DE EDIÇÃO */}
    </>
  );
}
