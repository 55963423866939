/* eslint-disable prettier/prettier */
/* eslint-disable no-restricted-syntax */
const base = {
  0: { type: 0, name: 'DASHBOARD_ORDERS' },
  1: { type: 1, name: 'DASHBOARD_BASE_CARDS' },
  2: { type: 2, name: 'DASHBOARD_CONTRACTS' },
  3: { type: 3, name: 'DASHBOARD_RELEASE_PAYMENTS' },
  4: { type: 4, name: 'DASHBOARD_MOVING' },
  5: { type: 5, name: 'DASHBOARD_PANEL' },
  6: { type: 6, name: 'DASHBOARD_ACCESSGM' },
  7: { type: 7, name: 'DASHBOARD_ADMINISTRATION' },
  8: { type: 8, name: 'ORDERS_NEW' },
  9: { type: 9, name: 'ORDERS_DUPLICATE' },
  10: { type: 10, name: 'ORDERS_CANCEL' },
  11: { type: 11, name: 'ORDERS_PAYMENT' },
  12: { type: 12, name: 'ORDERS_DETAILS_PLAN' },
  13: { type: 13, name: 'ORDERS_DETAILS_ADD_ITEM' },
  14: { type: 14, name: 'ORDERS_DETAILS_CANCEL_ITEM' },
  15: { type: 15, name: 'ORDERS_DETAILS_TICKET' },
  16: { type: 16, name: 'ORDERS_DETAILS_PAYMENT' },
  17: { type: 17, name: 'CONTRACT_NEW' },
  18: { type: 18, name: 'CONTRACT_EDIT' },
  19: { type: 19, name: 'CONTRACT_DISABLE' },
  20: { type: 20, name: 'CONTRACT_DOWNLOAD' },
  21: { type: 21, name: 'PAYMENTS_RELEASE_UPLOAD' },
  22: { type: 22, name: 'PAYMENTS_RELEASE_RELEASE' },
  23: { type: 23, name: 'PAYMENTS_RELEASE_CANCEL_ITEMS' },
  24: { type: 24, name: 'MOVING_DOWNLOAD' },
  25: { type: 25, name: 'ADM_FINANCIAL' },
  26: { type: 26, name: 'ADM_GROUP_PERMISSION' },
  27: { type: 27, name: 'ADM_MANAGER_USERS' },
};

if (Object.freeze) {
  Object.freeze(base);
}

/** @type {number[]} */
const types = Object.keys(base).map(key => base[key].type);

if (Object.freeze) {
  Object.freeze(types);
}

/** @type {string[]} */
const names = Object.keys(base).map(key => base[key].name);

if (Object.freeze) {
  Object.freeze(names);
}

/**
 * @param {string} name
 * @returns {number}
 */
function getTypeFor(name) {
  for (const prop in base) {
    if (base[prop].name.toLowerCase() === name.toLowerCase()) {
      return base[prop].type;
    }
  }
  return 0;
}

/**
 * @param {number} type
 * @returns {string}
 */
function getNameFor(type) {
  for (const prop in base) {
    if (base[prop].type === type) {
      return base[prop].name;
    }
  }
  return null;
}

const UserRoles = {
  DASHBOARD_ORDERS: base[0].type,
  DASHBOARD_BASE_CARDS: base[1].type,
  DASHBOARD_CONTRACTS: base[2].type,
  DASHBOARD_RELEASE_PAYMENTS: base[3].type,
  DASHBOARD_MOVING: base[4].type,
  DASHBOARD_PANEL: base[5].type,
  DASHBOARD_ACCESSGM: base[6].type,
  DASHBOARD_ADMINISTRATION: base[7].type,
  ORDERS_NEW: base[8].type,
  ORDERS_DUPLICATE: base[9].type,
  ORDERS_CANCEL: base[10].type,
  ORDERS_PAYMENT: base[11].type,
  ORDERS_DETAILS_PLAN: base[12].type,
  ORDERS_DETAILS_ADD_ITEM: base[13].type,
  ORDERS_DETAILS_CANCEL_ITEM: base[14].type,
  ORDERS_DETAILS_TICKET: base[15].type,
  ORDERS_DETAILS_PAYMENT: base[16].type,
  CONTRACT_NEW: base[17].type,
  CONTRACT_EDIT: base[18].type,
  CONTRACT_DISABLE: base[19].type,
  CONTRACT_DOWNLOAD: base[20].type,
  PAYMENTS_RELEASE_UPLOAD: base[21].type,
  PAYMENTS_RELEASE_RELEASE: base[22].type,
  PAYMENTS_RELEASE_CANCEL_ITEMS: base[23].type,
  MOVING_DOWNLOAD: base[24].type,
  ADM_FINANCIAL: base[25].type,
  ADM_GROUP_PERMISSION: base[26].type,
  ADM_MANAGER_USERS: base[27].type,

  types,
  names,
  getTypeFor,
  getNameFor,
};

if (Object.freeze) {
  Object.freeze(UserRoles);
}

module.exports = UserRoles;
