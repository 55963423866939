/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// /* eslint-disable jsx-a11y/click-events-have-key-events */
// /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Grid, Dialog } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import routing from '../../../service/routes/routing';
import MyButton from '../../../components/button/MyButton';
import NewSafe from './newSafe/Checkout';
// import './NewProduct.css';
import Category from './NewCategory';

const useStyles = makeStyles(theme =>
  createStyles({
    myButton: {
      color: '#2a3176',
      backgroundColor: '#f4f4f4',
      fontWeight: 'bold',
      // minWidth: 200,
    },
    myGrid: {
      minWidth: 320,
      maxWidth: 320,
      minHeight: 170,
      backgroundColor: '#f4f4f4',
      margin: theme.spacing(1),
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      textAlign: 'center',
      cursor: 'pointer',
      border: '2px solid #c5c5c5',
      borderRadius: 10,
    },
    myLabel: {
      padding: theme.spacing(4),
      color: '#2a3176',
      fontWeight: 'bold',
    },
  }),
);

const Acessory = function () {
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);
  const [openModalNew, setOpenModalNew] = useState(false);
  const classes = useStyles();
  return (
    <>
      <div className="col-sm-12" style={{ marginTop: 50 }}>
        {/* <p
          className="textMenu"
          onKeyDown={() => history.push(routing.dashboard)}
          onClick={() => history.push(routing.dashboard)}
        >
          Menu Inicial
        </p> */}
        <div
          className="col-sm-12"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: 50,
          }}
        >
          <p className="newProductTitle">
            Escolha a categoria do seu acessório
          </p>
          <MyButton
            style={{ marginRight: 50 }}
            label="Element"
            // onClick={() => history.push(routing.newProduct)}
          />
        </div>
        {/* <div
        className="col-sm-12"
        style={{
          display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center',
        }}
      > */}
      </div>
      <div style={{ marginTop: '2%' }}>
        <Grid
          container
          spacing={1}
          style={{
            justifyContent: 'center',
            paddingRight: 40,
            paddingLeft: 40,
          }}
        >
          <Grid
            className={classes.myGrid}
            item
            xs={12}
            sm={8}
            md={2}
            lg={4}
            xl={2}
            onClick={() => setOpenModalNew(true)}
          >
            <p className={classes.myLabel}>Seguro</p>
          </Grid>
          <Grid
            className={classes.myGrid}
            item
            xs={12}
            sm={8}
            md={2}
            lg={4}
            xl={2}
          >
            <p className={classes.myLabel}>Empréstimo</p>
          </Grid>
          <Grid
            className={classes.myGrid}
            item
            xs={12}
            sm={8}
            md={2}
            lg={4}
            xl={2}
          >
            <p className={classes.myLabel}>Assistência</p>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          style={{
            justifyContent: 'center',
            paddingRight: 40,
            paddingLeft: 40,
          }}
        >
          <Grid
            className={classes.myGrid}
            item
            xs={12}
            sm={8}
            md={2}
            lg={4}
            xl={2}
          >
            <p className={classes.myLabel}>Contábil</p>
          </Grid>
          <Grid
            className={classes.myGrid}
            item
            xs={12}
            sm={8}
            md={2}
            lg={4}
            xl={2}
          >
            <p className={classes.myLabel}>Antecipação de Recebíveis</p>
          </Grid>
          <Grid
            className={classes.myGrid}
            item
            xs={12}
            sm={8}
            md={2}
            lg={4}
            xl={2}
            onClick={() => setOpenModal(true)}
          >
            <p className={classes.myLabel}>+ Criar nova categoria</p>
          </Grid>
        </Grid>
      </div>
      <div style={{ marginTop: '2%' }}>
        {/* <p
          className="text-new-products-all"
          onClick={() => history.push(routing.product)}
        >
          Acessar todos os produtos

        </p> */}
        <h5
          className="text-new-products-all"
          onClick={() => history.push(routing.product)}
        >
          Acessar todos os produtos
        </h5>
      </div>
      <Dialog
        onClose={() => setOpenModal(false)}
        open={openModal}
        fullWidth
        maxWidth="lg"
      >
        <Category setOpenModal={setOpenModal} />
      </Dialog>
      <Dialog
        onClose={() => setOpenModal(false)}
        open={openModalNew}
        fullWidth
        maxWidth="lg"
      >
        {/* <h1> Acessorio</h1> */}
        <NewSafe setOpenModal={setOpenModalNew} />
      </Dialog>
    </>
  );
};

export default Acessory;
