/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';
import FlashContext from '../../../components/contexts/FlashContext';
import api from '../../../service/axios/api';

import Loading from '../../../components/loading/Loading';

function createData(
  id,
  groupName,
  cnpj,
  companyName,
  campaign,
  product,
  status,
) {
  return {
    id,
    groupName,
    cnpj,
    companyName,
    campaign,
    product,
    status,
  };
}

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  table: {
    minWidth: 1100,
  },
});

const StyledTableCell = withStyles(() => ({
  head: {
    // backgroundColor: theme.palette.common.black,
    // color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function ContractsTable({ docs, search, cardId, handleClose }) {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const { notifyError, notifySuccess } = useContext(FlashContext);

  useEffect(() => {
    if (docs?.length > 0) {
      const list = [];
      docs?.forEach(d => {
        list.push(
          createData(
            d.contract_id,
            d.group_name,
            d.cnpj,
            d.company_name,
            d.campaign,
            d.product_type === 'Card' ? 'Cartão' : 'Trânferencia',
            d.is_active ? 'Ativo' : 'Desativado',
          ),
        );
      });
      setRows(list);
    }
  }, [docs]);

  const deleteContract = id => {
    setDeleteId(id);
    setOpen(true);
  };

  const handleDelete = () => {
    setLoading(true);
    api.cards
      .unbindContract(cardId, deleteId)
      .then(() => {
        notifySuccess('Contrato desvinculado com sucesso.');
        setOpen(false);
        handleClose(false);
        search();
      })
      .catch(err => {
        notifyError(err.message);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Paper className={classes.root}>
        <TableContainer component={Paper} style={{ maxHeight: 400 }}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Grupo</StyledTableCell>
                <StyledTableCell>CNPJ</StyledTableCell>
                <StyledTableCell>Razão Social</StyledTableCell>
                <StyledTableCell>Campanha</StyledTableCell>
                <StyledTableCell>Produto</StyledTableCell>
                <StyledTableCell align="right">Status</StyledTableCell>
                <StyledTableCell align="right" />
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <StyledTableRow key={row.id}>
                    <StyledTableCell>{row.groupName}</StyledTableCell>
                    <StyledTableCell>{row.cnpj}</StyledTableCell>
                    <StyledTableCell>{row.companyName}</StyledTableCell>
                    <StyledTableCell>{row.campaign}</StyledTableCell>
                    <StyledTableCell>{row.product}</StyledTableCell>
                    <StyledTableCell align="right">
                      {row.status}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <DeleteIcon
                        style={{ cursor: 'pointer' }}
                        onClick={() => deleteContract(row.id)}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Dialog
        onClose={() => setOpen(!open)}
        open={open}
        fullWidth
        maxWidth="sm"
      >
        <Loading open={loading} msg="Desvinculando usuário..." />
        <DialogTitle id="simple-dialog-title">Desvincular contrato</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deseja realmente desvincular o cartão deste contrado?
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <h6
            style={{
              cursor: 'pointer',
              color: '#657588',
              fontWeight: 'bold',
              fontFamily: 'Open Sans',
              marginTop: 5,
            }}
            onClick={() => setOpen(false)}
          >
            Cancelar
          </h6>
          <Button color="primary" variant="contained" onClick={handleDelete}>
            Desvincular
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
