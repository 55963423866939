/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState, useContext } from 'react';

import { subDays } from 'date-fns';
// import Extract from '@/types/Extract'

// import { GridFilterModelParams } from '@material-ui/data-grid'
import Table from './Table';
import Cycle from '../../common/Cycle';
import api from '../../../../../service/axios/api';

import FlashContext from '../../../../../components/contexts/FlashContext';

const options = [
  {
    value: 7,
    label: '7 dias',
  },
  {
    value: 15,
    label: '15 dias',
  },
  {
    value: 30,
    label: 'Último mês',
  },
];

function ExtracsTable({ rows }) {
  const { notifyError } = useContext(FlashContext);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [itens, setItens] = useState({ label: '25', value: 25 });

  const [orders, setOrders] = useState([
    // {
    //   id: 1,
    //   rz: 'Martoszat SA',
    //   datePayment: new Date(),
    //   nature: 'Antecipação',
    //   value: '12.00',
    //   status: 'Aguardando pagamento',
    // },
  ]);
  const [orderBy, setOrderBy] = useState({ label: 'status', value: '' });
  const [status, setStatus] = useState({ label: '', value: '' });
  const [chipData, setChipData] = useState([]);

  const [select, setSelect] = useState([]);

  const [datePicker, setDatePicker] = useState({
    firstDate: subDays(new Date(), 15),
    lastDate: new Date(),
  });

  const changeDate = value => {
    setDatePicker(value);
  };

  const searchItems = () => {
    console.log('BUSCAR');
  };

  useEffect(() => {
    const cancel = new AbortController();
    api.commercialAgent
      .getSummary(cancel, page, itens.value)
      .then(res => {
        // console.log(res.data);
        setOrders(res.data.docs);
        setTotalPage(res.data.pages);
      })
      .catch(err => {
        notifyError(err.message);
      });
  }, [page, itens]);

  return (
    <>
      <Cycle
        title=""
        options={options}
        value={datePicker}
        onChange={changeDate}
      />
      <Table
        orders={orders}
        totalPages={totalPage}
        page={page}
        itens={itens}
        setItens={setItens}
        setPage={setPage}
        setStatus={setStatus}
        chipData={chipData}
        setChipData={setChipData}
        searchItems={searchItems}
        setSelected={setSelect}
        selected={select}
        setOrderBy={setOrderBy}
      />
    </>
  );
}

export default ExtracsTable;
