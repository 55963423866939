/* eslint-disable react/prop-types */
import React from 'react';
import Table from '../Table';
// import { Commission } from '@/types/SalesCommission';
import CustomNoRowsOverlay from '../CustomNoRowsOverlay';
import { getAdministrationFee } from './getters';

function NoRowsOverlay() {
  return <CustomNoRowsOverlay text="No hay contratos registrados" />;
}

const columns = [
  {
    field: 'administrationFee',
    headerName: 'Taxa ADM (R$)',
    valueGetter: getAdministrationFee,
    width: 140,
  },
  { field: 'salesChannel', headerName: 'Tipo de Venda', flex: 1 },
  {
    field: 'loads',
    headerName: 'Commissão s/ Cargas',
    flex: 1,
    valueFormatter: params => `${params.value} %`,
  },
];

function FixedCommissionsTable({ commissions }) {
  const rows = commissions.map((commission, i) => ({
    ...commission,
    id: i,
  }));

  return (
    <Table
      height={280}
      rows={rows}
      columns={columns}
      components={{
        NoRowsOverlay,
        Pagination: () => null,
        Toolbar: () => null,
      }}
    />
  );
}

export default FixedCommissionsTable;
