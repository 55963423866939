/* eslint-disable react/no-unescaped-entities */
import * as React from 'react';
import { useTranslation } from 'react-i18next';

export default function Review() {
  const { t } = useTranslation();
  return (
    <div className="box-new-card" style={{ height: 370, overflowY: 'scroll' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          // marginTop: -15,
        }}
      >
        <h5 className="text-box-inform">
          {t('Product.creditCard.checkout.review.label1')}
        </h5>
        <p className="text-box-inform-2">COD.: PROD_CARDPP_002</p>
      </div>
      <p className="review-label-1">
        {t('Product.creditCard.checkout.review.type')}
      </p>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <p className="review-label ">
            {t('Product.creditCard.checkout.review.name')}
          </p>
          <p className="review-value ">
            {' '}
            {t('Product.creditCard.checkout.review.nameProd')}
          </p>
          <img className="review-img" src="./img/edit.svg" alt="logo" />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <p className="review-label ">
            {t('Product.creditCard.checkout.issuer')}
          </p>
          <p className="review-value "> Acesso</p>
          <img className="review-img" src="./img/edit.svg" alt="logo" />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <p className="review-label ">
            {t('Product.creditCard.checkout.technology')}
          </p>
          <p className="review-value ">
            {' '}
            {t('Product.creditCard.checkout.chip')}
          </p>
          <img className="review-img" src="./img/edit.svg" alt="logo" />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <p className="review-label ">
            {t('Product.creditCard.checkout.review.operation')}:
          </p>
          <p className="review-value ">
            {' '}
            {t('Product.creditCard.checkout.review.creditOperation')}
          </p>
          <img className="review-img" src="./img/edit.svg" alt="logo" />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'start',
          alignItems: 'center',
          marginTop: -15,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <p className="review-label ">
            {t('Product.creditCard.checkout.flag')}
          </p>
          <p className="review-value "> Mastercard</p>
          <img className="review-img" src="./img/edit.svg" alt="logo" />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginLeft: 25,
          }}
        >
          <p className="review-label ">
            {t('Product.creditCard.checkout.coverage')}:
          </p>
          <p className="review-value ">
            {' '}
            {t('Product.creditCard.checkout.international')}
          </p>
          <img className="review-img" src="./img/edit.svg" alt="logo" />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginLeft: 25,
          }}
        >
          <p className="review-label ">
            {' '}
            {t('Product.creditCard.checkout.review.function')}:
          </p>
          <p className="review-value ">
            {' '}
            {t('Product.creditCard.checkout.opt2Function')}
          </p>
          <img className="review-img" src="./img/edit.svg" alt="logo" />
        </div>
      </div>
      {/* TARIFAS EMPRESA */}
      <p className="review-label-1">
        {t('Product.creditCard.checkout.company.label1')}
      </p>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'start',
          alignItems: 'center',
          marginTop: -15,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <p className="review-label ">
            {t('Product.creditCard.checkout.company.tranferBalance')}:
          </p>
          <p className="review-value "> R$0,00</p>
          <img className="review-img" src="./img/edit.svg" alt="logo" />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginLeft: 25,
          }}
        >
          <p className="review-label ">
            {t('Product.creditCard.checkout.company.loadLess')}:
          </p>
          <p className="review-value "> R$0,00</p>
          <img className="review-img" src="./img/edit.svg" alt="logo" />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginLeft: 25,
          }}
        >
          <p className="review-label ">
            {t('Product.creditCard.checkout.company.emergenciCargo')}:
          </p>
          <p className="review-value "> R$0,00</p>
          <img className="review-img" src="./img/edit.svg" alt="logo" />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <p className="review-label ">
            {t('Product.creditCard.checkout.company.minimumLoad')}:
          </p>
          <p className="review-value "> R$0,00</p>
          <img className="review-img" src="./img/edit.svg" alt="logo" />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <p className="review-label ">
            {t('Product.creditCard.checkout.company.operatingFee')}:
          </p>
          <p className="review-value "> R$0,00</p>
          <img className="review-img" src="./img/edit.svg" alt="logo" />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <p className="review-label ">
            {t('Product.creditCard.checkout.company.personalization')}:
          </p>
          <p className="review-value "> R$0,00</p>
          <img className="review-img" src="./img/edit.svg" alt="logo" />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <p className="review-label ">
            {t('Product.creditCard.checkout.company.chargebackFee')}:
          </p>
          <p className="review-value "> R$0,00</p>
          <img className="review-img" src="./img/edit.svg" alt="logo" />
        </div>
      </div>
      {/* Tarifas OPERADOR */}
      <p className="review-label-1">
        {t('Product.creditCard.checkout.client.label1')}
      </p>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <p className="review-label ">
            {t('Product.creditCard.checkout.client.monthlyPayment')}:
          </p>
          <p className="review-value "> R$0,00</p>
          <img className="review-img" src="./img/edit.svg" alt="logo" />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <p className="review-label ">
            {t('Product.creditCard.checkout.client.chargebackTax')}:
          </p>
          <p className="review-value "> R$0,00</p>
          <img className="review-img" src="./img/edit.svg" alt="logo" />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <p className="review-label ">
            {t('Product.creditCard.checkout.client.transfer')}:
          </p>
          <p className="review-value "> R$0,00</p>
          <img className="review-img" src="./img/edit.svg" alt="logo" />
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: -15,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <p className="review-label ">{t('Order.chargeback')}:</p>
          <p className="review-value "> R$0,00</p>
          <img className="review-img" src="./img/edit.svg" alt="logo" />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <p className="review-label ">
            {t('Product.creditCard.checkout.client.withdraw')}:
          </p>
          <p className="review-value "> R$0,00</p>
          <img className="review-img" src="./img/edit.svg" alt="logo" />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <p className="review-label ">
            {t('Product.creditCard.checkout.client.iof')}
          </p>
          <p className="review-value "> R$0,00</p>
          <img className="review-img" src="./img/edit.svg" alt="logo" />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: -15,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <p className="review-label ">
            {t('Product.creditCard.checkout.client.passwordUnlock')}:
          </p>
          <p className="review-value "> R$0,00</p>
          <img className="review-img" src="./img/edit.svg" alt="logo" />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <p className="review-label ">
            {t('Product.creditCard.checkout.client.mktTax')}:
          </p>
          <p className="review-value "> R$0,00</p>
          <img className="review-img" src="./img/edit.svg" alt="logo" />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <p className="review-label ">
            {t('Product.creditCard.checkout.client.duplicateCard')}:
          </p>
          <p className="review-value "> R$0,00</p>
          <img className="review-img" src="./img/edit.svg" alt="logo" />
        </div>
      </div>
      <p className="review-label-1">
        {t('Product.creditCard.checkout.limits.label1')}
      </p>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: -15,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <p className="review-label ">
            {t('Product.creditCard.checkout.limits.transfer1')}
            <br />
            {t('Product.creditCard.checkout.limits.transfer2')}:
          </p>
          <p className="review-value "> R$0,00</p>
          <img className="review-img" src="./img/edit.svg" alt="logo" />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <p className="review-label ">
            {t('Product.creditCard.checkout.limits.balanceInCard')}:
          </p>
          <p className="review-value "> R$0,00</p>
          <img className="review-img" src="./img/edit.svg" alt="logo" />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <p className="review-label ">
            {t('Product.creditCard.checkout.limits.paymentCount')}
          </p>
          <p className="review-value "> R$0,00</p>
          <img className="review-img" src="./img/edit.svg" alt="logo" />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'start',
          alignItems: 'center',
          marginTop: -15,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <p className="review-label ">
            {t('Product.creditCard.checkout.limits.celPhone')}:
          </p>
          <p className="review-value "> R$0,00</p>
          <img className="review-img" src="./img/edit.svg" alt="logo" />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginLeft: 25,
          }}
        >
          <p className="review-label ">
            {t('Product.creditCard.checkout.limits.withdraw')}:
          </p>
          <p className="review-value "> R$0,00</p>
          <img className="review-img" src="./img/edit.svg" alt="logo" />
        </div>
        {/* <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <p className="review-label ">
            Limite de Pagamento
            <br />
            de Contas:
          </p>
          <p className="review-value "> R$0,00</p>
          <img className="review-img" src="./img/edit.svg" alt="logo" />
        </div> */}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'start',
          alignItems: 'center',
          marginTop: -15,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <p className="review-label ">
            {t('Product.creditCard.checkout.limits.purchases')}:
          </p>
          <p className="review-value "> R$0,00</p>
          <img className="review-img" src="./img/edit.svg" alt="logo" />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginLeft: 25,
          }}
        >
          <p className="review-label ">
            {t('Product.creditCard.checkout.limits.prizeInCard')}:
          </p>
          <p className="review-value "> R$0,00</p>
          <img className="review-img" src="./img/edit.svg" alt="logo" />
        </div>
        {/* <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <p className="review-label ">
            Limite de Pagamento
            <br />
            de Contas:
          </p>
          <p className="review-value "> R$0,00</p>
          <img className="review-img" src="./img/edit.svg" alt="logo" />
        </div> */}
      </div>
    </div>
  );
}
