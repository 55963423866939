/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Dialog } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import routing from '../../../service/routes/routing';
import SubjectNotification from './SubjectNotification';
import styles from '../../../styles/Layout.module.css';
import SavedsNotifications from '../saved/Saveds';

const useStyles = makeStyles(theme =>
  createStyles({
    myButton: {
      // textAlign: 'center',
      // padding: theme.spacing(6),
      // margin: theme.spacing(1),
      // color: theme.palette.text.secondary,
      color: '#2a3176',
      backgroundColor: '#f4f4f4',
      fontWeight: 'bold',
      // minWidth: 200,
    },
    myGrid: {
      minWidth: 260,
      backgroundColor: '#f4f4f4',
      margin: theme.spacing(1),
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      textAlign: 'center',
      cursor: 'pointer',
      border: '2px solid #c5c5c5',
      borderRadius: 10,
    },
    myGridSelected: {
      minWidth: 260,
      backgroundColor: '#2a3176',
      margin: theme.spacing(1),
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      textAlign: 'center',
      cursor: 'pointer',
      border: '2px solid #2a3176',
      borderRadius: 10,
    },
    myLabel: {
      padding: theme.spacing(6),
      color: '#2a3176',
      fontWeight: 'bold',
    },
    myLabelSelected: {
      padding: theme.spacing(6),
      color: '#fff',
      fontWeight: 'bold',
    },
  }),
);

export default function NewNavigation() {
  const history = useHistory();
  const classes = useStyles();
  const [selected, setSelected] = useState();
  const [openModal, setOpenModal] = useState(false);

  const handleClick = e => {
    setSelected(e);
    if (e === 'new') setOpenModal(true);
    if (e === 'save') setOpenModal(true);
  };

  return (
    <>
      <div className="col-sm-12" style={{ marginTop: 50 }}>
        <div
          className="col-sm-12"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <h6
            // style={{ cursor: 'pointer' }}
            className={styles.textMenu}
            onKeyDown={() => history.push(routing.dashboard)}
            onClick={() => history.push(routing.dashboard)}
          >
            Menu Inicial
          </h6>
          <h6
            // className="textMenu"
            onKeyDown={() => history.push(routing.newClientsGroup)}
            onClick={() => history.push(routing.newClientsGroup)}
            style={{ color: '#41b490', cursor: 'pointer' }}
          >
            Criar novo grupo de clientes
          </h6>
        </div>
        <div
          className="col-sm-12"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <h4 className={styles.textTitle}>Nova notificação</h4>
        </div>
        <Grid
          container
          spacing={1}
          style={{
            justifyContent: 'center',
            padding: 40,
          }}
        >
          <Grid
            className={
              selected === 'new' ? classes.myGridSelected : classes.myGrid
            }
            item
            xs={12}
            sm={8}
            md={2}
            lg={2}
            xl={2}
            onClick={() => handleClick('new')}
          >
            <p
              className={
                selected === 'new' ? classes.myLabelSelected : classes.myLabel
              }
            >
              Nova notificação
            </p>
          </Grid>
          <Grid
            className={
              selected === 'save' ? classes.myGridSelected : classes.myGrid
            }
            item
            xs={12}
            sm={8}
            md={2}
            lg={2}
            xl={2}
            onClick={() => handleClick('save')}
          >
            <p
              className={
                selected === 'save' ? classes.myLabelSelected : classes.myLabel
              }
            >
              Modelos salvos
            </p>
          </Grid>
        </Grid>
      </div>
      <Dialog
        onClose={() => setOpenModal(false)}
        open={openModal}
        // width={1192}
        // height={776}
        fullWidth
        maxWidth="lg"
        // maxWidth="1192"
      >
        {selected === 'new' && (
          <SubjectNotification setOpenModal={setOpenModal} />
        )}
        {selected === 'save' && (
          <SavedsNotifications setOpenModal={setOpenModal} />
          // <h1>Save</h1>
        )}
      </Dialog>
    </>
  );
}
