/* eslint-disable import/prefer-default-export */
import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    paper: {
      padding: theme.spacing(3),
    },
    button: {
      textTransform: 'uppercase',
    },
  }),
);
