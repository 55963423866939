/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/prop-types */
import React, { createContext, useState, useContext } from 'react';

const StepperContext = createContext({});

export function StepperProvider({
  onChange = () => {},
  disabled = false,
  code = 201,
  titleModal = '',
  actionModal = () => {},
  children,
  onSuccess,
}) {
  const [value, setValue] = useState({
    step: 1,
  });

  const next = data => {
    onChange(data);
    setValue(prevState => ({
      ...prevState,
      step: prevState.step + 1,
    }));
  };

  const prev = () => {
    onChange();
    setValue(prevState => ({
      ...prevState,
      step: prevState.step - 1,
    }));
  };

  const handleStep = step => {
    setValue(prevState => ({
      ...prevState,
      step: typeof step === 'number' ? step : step(prevState.step),
    }));
  };

  return (
    <StepperContext.Provider
      value={{
        ...value,
        disabled,
        onSuccess,
        next,
        prev,
        code,
        titleModal,
        actionModal,
        onChange,
        handleStep,
      }}
    >
      {children}
    </StepperContext.Provider>
  );
}

export const useStepperContext = () => useContext(StepperContext);

export default StepperContext;
