/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import api from '../../service/axios/api';

function SelectAsyncPaginate(props) {
  // const [regionName, setRegionName] = useState(null);

  // useEffect(() => {
  //   setRegionName(props.regionName);
  // }, [props.regionName]);

  // orderBy, search, status, itens, page, cancel
  // useEffect(() => {
  //   const cancel = new AbortController();
  //   api.user
  //     .getAll('', null, '', 10, 1, cancel)
  //     .then(res => console.log(res))
  //     .catch(err => console.log(err));
  // }, []);

  const loadOptions = async (searchQuery, loadedOptions, { page }) => {
    const cancel = new AbortController();

    const response = await api.user.getAllSimple(
      'ASC',
      searchQuery,
      '',
      '10',
      page,
      cancel,
    );
    const listOpt = [];
    response.docs.forEach(doc => {
      listOpt.push({ label: doc.name, value: doc.id });
    });

    return {
      options: listOpt,
      hasMore: listOpt.length < response.total,
      additional: {
        page: searchQuery ? 2 : page + 1,
      },
    };
  };

  const onChange = option => {
    if (typeof props.onChange === 'function') {
      props.onChange(option);
    }
  };

  return (
    <AsyncPaginate
      // key={JSON.stringify(regionName)}
      value={props.value || ''}
      loadOptions={loadOptions}
      // getOptionValue={option => option.name}
      // getOptionLabel={option => option.name}
      isMulti
      menuPlacement="top"
      onChange={onChange}
      isSearchable
      placeholder=""
      additional={{
        page: 1,
      }}
    />
  );
}

export default SelectAsyncPaginate;
