import { createMuiTheme } from '@material-ui/core/styles';

import { ptBR } from '@material-ui/core/locale';
import palette from './palette';
import overrides from './overrides';
import typography from './typography';

export default createMuiTheme(
  {
    palette,
    overrides,
    typography,
    spacing: 4,
    shape: {
      borderRadius: 5,
    },
    zIndex: {
      appBar: 1200,
      drawer: 1100,
    },
    MuiCssBaseline: {
      '@global': {
        html: {
          boxSizing: 'border-box',
        },
      },
    },
  },
  ptBR,
);
