/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Box,
  Paper,
  Table,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogActions,
} from '@material-ui/core';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import NotesIcon from '@material-ui/icons/Notes';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment-timezone';
import Status from '../../service/translateStatus';
import Loading from '../../components/loading/Loading';

import Flash from './Flash';

import styles from '../../styles/Layout.module.css';
import api from '../../service/axios/api';

const StyledTableCell = withStyles(() => ({
  head: {
    // backgroundColor: theme.palette.common.black,
    // color: theme.palette.common.white,
  },
  body: {
    height: 10,
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

// eslint-disable-next-line react/prop-types
export default function DetailsCards({ details }) {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [total, setTotal] = useState(0);
  const [itens, setItens] = useState(10);
  const [rows, setRows] = useState([]);
  const [cardData, setCardData] = useState();
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState(t('baseCards.details.search'));
  const [flashFile, setFlashFile] = useState('');
  const [open, setOpen] = useState(false);

  const [complete, setComplete] = useState(false);

  const [typeAddress, setTypeAddress] = useState('');
  const [address, setAddress] = useState('');
  const [numberAddress, setNumberAddress] = useState('');
  const [complement, setComplement] = useState('');
  const [district, setDistrict] = useState('');
  const [city, setCity] = useState('');
  const [ac, setAc] = useState('');
  const [cep, setCep] = useState('');
  const [uf, setUf] = useState('');

  useEffect(() => {
    if (details) {
      setMsg(t('baseCards.details.search'));
      setLoading(true);
      const cancel = new AbortController();
      api.cards
        .getMoviment(details.id, itens, page, cancel)
        .then(res => {
          setCardData(res.cardData);
          setFlashFile(res.flashFile);
          setTotal(res.orders.total);
          setRows(res.orders.docs);
        })
        .catch(err => console.log(err))
        .finally(() => setLoading(false));
    }
  }, [details, page, itens]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setItens(event.target.value);
  };

  const exportXLS = () => {
    const cancel = new AbortController();
    setMsg(t('baseCards.details.createPlanMsg'));
    setLoading(true);
    api.cards
      .exportXLS(details.id, cancel)
      .then(res => {
        const url = res.retorno.link;
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'movimento.xlsx'); // or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => console.log(err))
      .finally(() => setLoading(false));
  };

  const exportPDF = () => {
    const cancel = new AbortController();
    setMsg(t('baseCards.details.createPdfMsg'));
    setLoading(true);
    api.cards
      .exportPDF(details.id, cancel)
      .then(res => {
        const url = res.retorno.link;
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'movimento.pdf'); // or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => console.log(err))
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Loading open={loading} msg={msg} />
      <Grid item>
        <h5
          style={{
            cursor: 'pointer',
            color: '#657588',
            fontWeight: 'bold',
            fontFamily: 'Open Sans',
          }}
        >
          {t('baseCards.details.label')}
        </h5>
      </Grid>

      <div className="box-new-card">
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        />
        <Grid
          container
          direction="row"
          style={{ justifyContent: 'space-between' }}
        >
          {/* <Grid item xs={12}>
            <div style={{ display: 'flex' }}>
              <p className={styles.labelTitleResum}>Produto:</p>
              <p> Transferência</p>
            </div>
          </Grid> */}
          <Grid item>
            <div>
              <p className={styles.labelTitleResum}>
                {' '}
                {t('baseCards.details.invoiceFrom')}:
              </p>
              <p style={{ marginTop: '-15px' }}>{cardData?.Billed}</p>
            </div>
          </Grid>
          <Grid item>
            <div>
              <p className={styles.labelTitleResum}>
                {t('baseCards.details.campaign')}:
              </p>
              <p style={{ marginTop: '-15px' }}>{cardData?.campaign}</p>
            </div>
          </Grid>
          <Grid item>
            <div>
              <p className={styles.labelTitleResum}>
                {t('baseCards.details.brand')}:
              </p>
              <p style={{ marginTop: '-15px' }}>{cardData?.brand}</p>
            </div>
          </Grid>
          <Grid item>
            <div>
              <p className={styles.labelTitleResum}>
                {t('baseCards.details.product')}:
              </p>
              <p style={{ marginTop: '-15px' }}>{cardData?.product}</p>
            </div>
          </Grid>
          <Grid item>
            <div>
              <p className={styles.labelTitleResum}>
                {t('baseCards.details.expirationDate')}:
              </p>
              <p style={{ marginTop: '-15px' }}>{cardData?.good_through}</p>
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          style={{ justifyContent: 'space-between' }}
        >
          <Grid item>
            <div>
              <p className={styles.labelTitleResum}>
                {t('baseCards.details.proxy')}:
              </p>
              <p style={{ marginTop: '-15px' }}>{cardData?.proxy}</p>
            </div>
          </Grid>
          <Grid item>
            <div>
              <p className={styles.labelTitleResum}>
                {t('baseCards.details.cardNUmber')}:
              </p>
              <p style={{ marginTop: '-15px' }}>{cardData?.card_number}</p>
            </div>
          </Grid>
          <Grid item>
            <div>
              <p className={styles.labelTitleResum}>
                {t('baseCards.details.cpf')}:
              </p>
              <p style={{ marginTop: '-15px' }}>{cardData?.Individual?.cpf}</p>
            </div>
          </Grid>
          <Grid item>
            <div>
              <p className={styles.labelTitleResum}>
                {t('baseCards.details.bearer')}:
              </p>
              <p style={{ marginTop: '-15px' }}>{cardData?.Individual?.name}</p>
            </div>
          </Grid>
          <Grid item>
            <div>
              <p className={styles.labelTitleResum}>
                {t('baseCards.details.status')}:
              </p>
              <p style={{ marginTop: '-15px' }}>
                {Status(cardData?.status, t)}
              </p>
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          style={{ justifyContent: 'space-between' }}
        >
          <Grid
            item
            xs={12}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div />
            {/* <div>
              <p className={styles.labelTitleResum}>
                Entrega:
                <img src="./img/delivery1.png" height={30} width={30} alt="" />
              </p>
              <p
                style={{
                  marginTop: '-15px',
                  cursor: flashFile !== '' ? 'pointer' : 'not-allowed',
                }}
                onClick={() =>
                  flashFile !== '' && window.open(cardData?.flashFile)
                }
              >
                {flashFile !== '' ? 'Download arquivo' : 'Sem arquivo'}
              </p> */}
            {/* </div> */}
            {/* </Grid>
          <Grid item xs={12}> */}
            <div>
              <p className={styles.labelTitleResum}>
                {t('baseCards.details.balance')}:
              </p>
              <p style={{ marginTop: '-15px' }}>{cardData?.value}</p>
            </div>
          </Grid>
          <Grid item xs={12} style={{ marginBottom: '15px' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
              <Grid
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  paddingTop: '10px',
                  marginLeft: '10px',
                  marginRight: '10px',
                }}
              >
                <div>
                  <p className={styles.labelTitleResum}>
                    {t('baseCards.details.delivery')}:
                    <img
                      style={{ cursor: 'pointer' }}
                      onClick={() => setComplete(!complete)}
                      src="./img/delivery1.png"
                      height={30}
                      width={30}
                      alt=""
                    />
                  </p>
                  <p
                    style={{
                      marginTop: '-15px',
                      cursor: flashFile !== '' ? 'pointer' : 'not-allowed',
                    }}
                    // onClick={() =>
                    //   flashFile !== '' && window.open(cardData?.flashFile)
                    // }
                  >
                    {/* {flashFile !== '' ? 'Download arquivo' : 'Sem arquivo'} */}
                  </p>
                </div>
                {/* {flashFile?.status !== 'Finished' && (
                  <Button
                    onClick={() => setOpen(true)}
                    color="primary"
                    variant="contained"
                    size="small"
                    style={{ height: '40px' }}
                  >
                    Ação
                  </Button>
                )} */}
              </Grid>
              <TableContainer>
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  size="medium"
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="left">
                        {t('baseCards.details.deliveryStatus')}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {t('baseCards.details.deliveryMsg')}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {t('baseCards.details.deliveryDate')}
                      </StyledTableCell>
                      {/* <StyledTableCell align="center">Valor</StyledTableCell>
                      <StyledTableCell align="right">Status</StyledTableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {flashFile ? (
                      flashFile?.Items?.length > 0 ? (
                        flashFile?.Items?.map((row, i) => {
                          return complete ? (
                            <StyledTableRow tabIndex={-1} key={row.id}>
                              <StyledTableCell
                                component="th"
                                scope="row"
                                align="left"
                              >
                                {flashFile.status}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {row.event}
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                {row.date &&
                                  moment
                                    .utc(row.date)
                                    .format('DD/MM/yyyy HH:mm')}
                              </StyledTableCell>
                            </StyledTableRow>
                          ) : (
                            i + 1 === flashFile.Items.length && (
                              <StyledTableRow tabIndex={-1} key={row.id}>
                                <StyledTableCell
                                  component="th"
                                  scope="row"
                                  align="left"
                                >
                                  {flashFile.status}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row.event}
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                  {row.date &&
                                    moment
                                      .utc(row.date)
                                      .format('DD/MM/yyyy HH:mm')}
                                </StyledTableCell>
                              </StyledTableRow>
                            )
                          );
                        })
                      ) : (
                        <StyledTableRow>
                          <StyledTableCell colSpan={3} align="center">
                            {t('baseCards.details.notDeliveryMsg')}
                          </StyledTableCell>
                        </StyledTableRow>
                      )
                    ) : (
                      <StyledTableRow>
                        <StyledTableCell colSpan={7} align="center" />
                      </StyledTableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        </Grid>
      </div>
      <div className="box-new-card" style={{ marginTop: '15px' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        />
        <Grid
          container
          direction="row"
          style={{ justifyContent: 'space-between' }}
        >
          <Grid item xs={12}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <p className={styles.labelTitleResum}>
                  {t('baseCards.details.chargeMoviment')}
                </p>
              </div>
              <div>
                <PictureAsPdfIcon
                  onClick={exportPDF}
                  style={{ cursor: 'pointer' }}
                />
                <NotesIcon
                  onClick={exportXLS}
                  style={{ marginLeft: '15px', cursor: 'pointer' }}
                />
                {/* <p className={styles.labelTitleResum}>Downloads</p> */}
              </div>
            </div>
          </Grid>
          <Grid item xs={12} style={{ marginBottom: '10px' }}>
            <Box sx={{ width: '100%' }}>
              <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer>
                  <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size="medium"
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="center">
                          {t('baseCards.details.chargeMovimentNote')}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {t('baseCards.details.chargeMovimentOrder')}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {t('baseCards.details.chargeMovimentDate')}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {t('baseCards.details.chargeMovimentValue')}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {t('baseCards.details.chargeMovimentStatus')}
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows ? (
                        rows.length > 0 ? (
                          rows.map(row => (
                            <StyledTableRow tabIndex={-1} key={row.id}>
                              <StyledTableCell
                                component="th"
                                scope="row"
                                align="center"
                                style={{ cursor: 'pointer' }}
                                onClick={() => window.open(row.invoice_file)}
                              >
                                {row.invoice}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {row.order_number}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {row.date_processing &&
                                  moment
                                    .utc(row.date_processing)
                                    .format('DD/MM/yyyy')}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                R${row.value}
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                {Status(row.status, t)}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))
                        ) : (
                          <StyledTableRow>
                            <StyledTableCell colSpan={5} align="center">
                              {t('baseCards.details.notchargeMoviment')}
                            </StyledTableCell>
                          </StyledTableRow>
                        )
                      ) : (
                        <StyledTableRow>
                          <StyledTableCell colSpan={7} align="center" />
                        </StyledTableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50, 100, 1000, 50000]}
                  component="div"
                  count={total}
                  rowsPerPage={itens}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </div>
      <Dialog
        onClose={() => setOpen(!open)}
        open={open}
        fullWidth
        maxWidth="md"
      >
        {/* <Loading open={loadingC} msg="Cadastrando cartões..." /> */}
        <DialogTitle id="simple-dialog-title">Flash</DialogTitle>
        <form onSubmit={() => console.log('submit')}>
          <Grid
            container
            spacing={1}
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              padding: 20,
            }}
          >
            <Grid item xs={12} sm={12}>
              <Grid
                container
                spacing={1}
                style={{
                  paddingRight: 20,
                  paddingLeft: 20,
                }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <Flash
                    typeAddress={typeAddress}
                    setTypeAddress={setTypeAddress}
                    address={address}
                    setAddress={setAddress}
                    numberAddress={numberAddress}
                    setNumberAddress={setNumberAddress}
                    complement={complement}
                    setComplement={setComplement}
                    district={district}
                    setDistrict={setDistrict}
                    city={city}
                    setCity={setCity}
                    ac={ac}
                    setAc={setAc}
                    cep={cep}
                    setCep={setCep}
                    uf={uf}
                    setUf={setUf}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <DialogActions>
            <h6
              style={{
                cursor: 'pointer',
                color: '#657588',
                fontWeight: 'bold',
                fontFamily: 'Open Sans',
                marginTop: 5,
              }}
              onClick={() => setOpen(false)}
            >
              Cancelar
            </h6>
            <Button
              style={{ height: 40 }}
              type="submit"
              color="primary"
              variant="contained"
            >
              Salvar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
