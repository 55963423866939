import contact from './contact';

const company = {
  cnpj: '',
  legalName: '',
  email: '',
  website: '',
  comments: '',
  tradingName: '',
  phone: '',
  mobile: '',
  groupName: '',
  admin: contact,
};

export default company;
