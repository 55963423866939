/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, { useContext, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import StepperContext from '../../context/StepperContext';
import SuccessModal from '../SuccessModal';
import FlashContext from '../../../../components/contexts/FlashContext';
// import { CARD_BRANDS, PRODUCTS } from '../../util/constants';

const useStyles = makeStyles(theme => ({
  form: {
    minHeight: '500px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  content: {
    padding: theme.spacing(4),
  },
  section: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(8),
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(4),
    background: '#f0f0f0',
    width: '100%',
    '& > *': {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    color: blue[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

function ProposalReviewForm({ proposal }) {
  const classes = useStyles();
  const stepper = useContext(StepperContext);
  const [loading, setLoading] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  // eslint-disable-next-line no-console
  const { notifyError } = useContext(FlashContext);

  const submit = async event => {
    event.preventDefault();
    setLoading(true);

    try {
      // const response = await axios.post('/api/proposals', proposal)
      const response = await stepper.onSuccess(proposal);
      console.log(response);
      if (response.status === stepper.code) {
        console.log('Abrir sucesso');
        setOpenSuccessModal(true);
      }
    } catch (err) {
      const error = err;

      /* eslint no-console: ["error", { allow: ["error"] }] */
      console.error(error);
      notifyError(error.message);
    }

    setLoading(false);
  };

  const handleCloseModal = () => {
    setOpenSuccessModal(false);
    stepper.actionModal();
  };

  // const { company, productDetails } = proposal;
  // const { campaign } = productDetails;

  // const { commercialAgent } = productDetails;

  return (
    <>
      <form className={classes.form} onSubmit={submit}>
        <Container className={classes.content}>
          <Typography variant="h5" component="h3" gutterBottom>
            Resumo da oportunidade
          </Typography>

          <Box bgcolor="background.gray" p={6}>
            <Grid container spacing={5}>
              <Grid item lg={4} sm={6} xs={12}>
                <Typography variant="subtitle1" color="initial">
                  Razão Social:
                </Typography>
                <Typography variant="body2" color="initial">
                  {proposal.companyName}
                </Typography>
              </Grid>
              <Grid item lg={4} sm={6} xs={12}>
                <Typography variant="subtitle1" color="initial">
                  Nome Fantasia:
                </Typography>
                <Typography variant="body2" color="initial">
                  {proposal.tradingName}
                </Typography>
              </Grid>
              <Grid item lg={4} sm={6} xs={12}>
                <Typography variant="subtitle1" color="initial">
                  CNPJ:
                </Typography>
                <Typography variant="body2" color="initial">
                  {proposal.cnpj}
                </Typography>
              </Grid>
              <Grid item lg={4} sm={6} xs={12}>
                <Typography variant="subtitle1" color="initial">
                  Email:
                </Typography>
                <Typography variant="body2" color="initial">
                  {proposal.email}
                </Typography>
              </Grid>
              <Grid item lg={4} sm={6} xs={12}>
                <Typography variant="subtitle1" color="initial">
                  Telefone:
                </Typography>
                <Typography variant="body2" color="initial">
                  {proposal.phone}
                </Typography>
              </Grid>
              <Grid item lg={4} sm={6} xs={12}>
                <Typography variant="subtitle1" color="initial">
                  Celular:
                </Typography>
                <Typography variant="body2" color="initial">
                  {proposal.mobile}
                </Typography>
              </Grid>
              <Grid item lg={12} xs={12}>
                <Typography variant="subtitle1" color="initial">
                  Comentários:
                </Typography>
                <Typography variant="body2" color="initial">
                  {proposal.comments}
                </Typography>
              </Grid>
              {proposal.contacts.map((c, i) => (
                <Container className={classes.content}>
                  <Typography variant="h6" component="h3" gutterBottom>
                    Contato {proposal.contacts.length > 0 ? i + 1 : ''}
                  </Typography>
                  <Grid container spacing={5}>
                    <Grid item lg={4} sm={6} xs={12}>
                      <Typography variant="subtitle1" color="initial">
                        Nome:
                      </Typography>
                      <Typography variant="body2" color="initial">
                        {c.name}
                      </Typography>
                    </Grid>
                    <Grid item lg={4} sm={6} xs={12}>
                      <Typography variant="subtitle1" color="initial">
                        CPF:
                      </Typography>
                      <Typography variant="body2" color="initial">
                        {c.cpf}
                      </Typography>
                    </Grid>
                    <Grid item lg={4} sm={6} xs={12}>
                      <Typography variant="subtitle1" color="initial">
                        Cargo:
                      </Typography>
                      <Typography variant="body2" color="initial">
                        {c.role}
                      </Typography>
                    </Grid>
                    <Grid item lg={3} sm={6} xs={12}>
                      <Typography variant="subtitle1" color="initial">
                        Área de trabalho:
                      </Typography>
                      <Typography variant="body2" color="initial">
                        {c.department}
                      </Typography>
                    </Grid>
                    <Grid item lg={3} sm={6} xs={12}>
                      <Typography variant="subtitle1" color="initial">
                        Celular:
                      </Typography>
                      <Typography variant="body2" color="initial">
                        {c.mobile}
                      </Typography>
                    </Grid>
                    <Grid item lg={3} sm={6} xs={12}>
                      <Typography variant="subtitle1" color="initial">
                        Telefone:
                      </Typography>
                      <Typography variant="body2" color="initial">
                        {c.phone}
                      </Typography>
                    </Grid>
                    <Grid item lg={3} sm={6} xs={12}>
                      <Typography variant="subtitle1" color="initial">
                        Email:
                      </Typography>
                      <Typography variant="body2" color="initial">
                        {c.email}
                      </Typography>
                    </Grid>
                  </Grid>
                </Container>
              ))}
            </Grid>
          </Box>
        </Container>
        <div className={classes.footer}>
          <Button onClick={stepper.prev} type="button" variant="contained">
            Voltar
          </Button>
          <div className={classes.wrapper}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
            >
              Salvar proposta
            </Button>
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </div>
      </form>
      <SuccessModal
        title={stepper.titleModal}
        open={openSuccessModal}
        onClose={handleCloseModal}
      />
    </>
  );
}

export default ProposalReviewForm;
