/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';

// Importing MaterialUI components
import Typography from '@material-ui/core/Typography';
import {
  Box,
  Button,
  TextField,
  Grid,
  MenuItem,
  CircularProgress,
} from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import axios from 'axios';
// import { useRouter } from 'next/router';
// import { uploadNF } from '@/util/invoice';
import useBoolean from '../../../../hooks/useBoolean/useBoolean';
import InvoiceSchema from '../../../../schemas/InvoiceSchema';
// import Invoice from '@/types/Invoice'
import SuccessModal from '../../../SuccessModal';
// import Communication from '@/types/Communication'
import { useStyles } from './styles';

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      decimalSeparator=","
      thousandSeparator="."
      decimalScale={2}
      allowEmptyFormatting
      allowNegative={false}
      isNumericString
      prefix="R$ "
    />
  );
}
const dateNow = String(
  new Date().toLocaleDateString('ko-KR', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }),
)
  .replace('. ', '-')
  .replace('. ', '-')
  .replace('.', '');

const invoiceDefault = {
  companyName: '',
  nfNumber: '',
  datePayment: '',
  amount: '0',
  status: 'Aguardando pagamento',
  dateReceipt: dateNow,
};

function Upload({ onClose, selected, close }) {
  const classes = useStyles();
  const [file, setFile] = useState();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const edit = selected !== undefined;
  const [invoice, setInvoice] = useState(selected || invoiceDefault);
  const {
    register,
    handleSubmit,
    formState: { errors },
    // eslint-disable-next-line no-unused-vars
    setValue,
  } = useForm({
    defaultValues: { ...invoice },
    resolver: yupResolver(InvoiceSchema),
  });
  const {
    value: success,
    toTrue: isSuccess,
    toFalse: isNotSuccess,
  } = useBoolean({
    initialValue: false,
  });
  // const router = useRouter();
  const onClosed = () => {
    isNotSuccess();
    close();
  };
  const submit = async data => {
    setLoading(true);
    const newFile = file !== undefined;
    try {
      const response = await axios.post('/api/invoices', {
        ...data,
        fileKey: newFile ? file.name.replaceAll(' ', '') : invoice.fileKey,
      });
      if (response.status === 201) {
        if (newFile) {
          console.log('Upload FIle');
          // await uploadNF(file, file.name.replaceAll(' ', ''));
          // const url = `https://element-sales-ecosystem-nfs.s3.amazonaws.com/${String(
          //   file.name,
          // ).replaceAll(' ', '')}`;
          // const communication = {
          //   id: '1',
          //   object: '',
          //   text: `Baixar em ${url}`,
          //   to: [
          //     'luisdavid.colina@gmail.com',
          //     'Jianemuzel@elementmkt.com.br',
          //     'Processamento@elementmkt.com.br',
          //     'r.carpio@binarygroups.tech',
          //   ],
          //   status: 'Enviado',
          //   subject: `Uma nota fiscai foi carregadat - ${invoice.companyName} `,
          // };
          // const resp = await axios.post('/api/mail', communication);
          // if (resp.status === 201) {
          //   isSuccess();
          // }
        }
        isSuccess();
      }
    } catch (err) {
      // eslint-disable-next-line no-shadow
      const error = err;

      // eslint-disable-next-line no-console
      console.error(error);
    }
    setLoading(false);
  };

  const uploadFile = event => {
    const pattern = /.*.(pdf)/;
    const files = Array.from(event.target.files || event.dataTransfer.files);

    if (files[0].name.match(pattern)) {
      setFile(files[0]);
    } else {
      setError('Arquivo não suportado');
    }
  };
  return (
    <Box className={classes.container}>
      <form onSubmit={handleSubmit(submit)}>
        <Typography
          className={classes.subtitle}
          variant="h5"
          color="primary"
          paragraph
        >
          Carregar NF
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Razon Social"
              variant="outlined"
              size="small"
              className={classes.input}
              autoFocus
              inputProps={{
                ...register('companyName'),
              }}
              error={!!errors.companyName}
              helperText={errors.companyName?.message}
              value={invoice.companyName}
              onChange={e => {
                setInvoice({
                  ...invoice,
                  companyName: e.target.value,
                });
              }}
            />
          </Grid>
          <Grid item xs={6} style={{ pointerEvents: edit ? 'none' : 'auto' }}>
            <TextField
              fullWidth
              label="Insira N° de sua nota"
              variant="outlined"
              size="small"
              className={classes.input}
              inputProps={{
                ...register('nfNumber'),
              }}
              error={!!errors.nfNumber}
              helperText={errors.nfNumber?.message}
            />
          </Grid>
          <Grid item xs={6} style={{ pointerEvents: edit ? 'none' : 'auto' }}>
            <TextField
              fullWidth
              type="date"
              label="Recebimento"
              variant="outlined"
              size="small"
              className={classes.input}
              inputProps={{
                ...register('dateReceipt'),
              }}
              error={!!errors.dateReceipt}
              helperText={errors.dateReceipt?.message}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Valor"
              variant="outlined"
              size="small"
              className={classes.input}
              value={invoice.amount}
              onChange={e => {
                setInvoice({
                  ...invoice,
                  amount: e.target.value,
                });
              }}
              InputProps={{
                inputComponent: NumberFormatCustom,
                inputProps: {
                  ...register('amount'),
                },
              }}
              error={!!errors.amount}
              helperText={errors.amount?.message}
            />
          </Grid>
          {edit && (
            <>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  type="date"
                  label="Pagamento"
                  variant="outlined"
                  size="small"
                  className={classes.input}
                  inputProps={{
                    ...register('datePayment'),
                  }}
                  error={!!errors.datePayment}
                  helperText={errors.datePayment?.message}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  select
                  id="status"
                  size="small"
                  fullWidth
                  label="Status"
                  variant="outlined"
                  className={classes.input}
                  inputProps={{
                    ...register('status'),
                  }}
                  value={invoice.status}
                  onChange={e => {
                    setInvoice({
                      ...invoice,
                      status: e.target.value,
                    });
                  }}
                >
                  <MenuItem value="Aguardando pagamento">
                    Aguardando pagamento
                  </MenuItem>
                  <MenuItem value="Pago">Pago</MenuItem>
                  <MenuItem value="Cancelado">Cancelado</MenuItem>
                  <MenuItem value="Aguardando NF">Aguardando NF</MenuItem>
                </TextField>
              </Grid>
            </>
          )}
          {!edit && (
            <>
              <Grid item xs={2}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  component="label"
                  htmlFor="upload-file"
                >
                  {edit ? 'Carregar NF' : 'Alterar NF'}
                </Button>
              </Grid>
              <Grid item xs={6} style={{ display: 'none' }}>
                <input
                  onChange={uploadFile}
                  accept=".pdf"
                  id="upload-file"
                  type="file"
                />
              </Grid>
              <Grid item xs={10} style={{ alignSelf: 'center' }}>
                <Typography variant="caption" color="inherit">
                  {file ? file.name : error || 'Nenhum arquivo selecionado'}
                </Typography>
              </Grid>
            </>
          )}
          <div className={classes.footer}>
            <Button type="button" variant="contained" onClick={onClose}>
              Voltar
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={file === undefined && !edit}
            >
              {loading && <CircularProgress size={14} color="secondary" />}
              {!loading && 'Salvar'}
            </Button>
          </div>
        </Grid>
      </form>
      <SuccessModal
        title="Nota Fiscai carregada com sucesso"
        open={success}
        onClose={onClosed}
      />
    </Box>
  );
}

export default Upload;
