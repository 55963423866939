/* eslint-disable react/prop-types */

import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import {
  Button,
  TablePagination,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Paper,
  Dialog,
  DialogTitle,
  DialogActions,
  Grid,
} from '@material-ui/core';
import moment from 'moment-timezone';

import GetAppIcon from '@material-ui/icons/GetApp';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PermissionComponent from '../../components/permissionComponent/PermissionComponent';
import api from '../../service/axios/api';
import routing from '../../service/routes/routing';
import Loading from '../../components/loading/Loading';

import Status from '../../service/translateStatus';
import UserRoles from '../../service/acl/UserRoles';

function createData(
  id,
  cpf,
  name,
  proxy,
  account,
  nf,
  order,
  process,
  conciliation,
  qtd,
  value,
  status,
  all,
) {
  return {
    id,
    name,
    cpf,
    status,
    proxy,
    account,
    nf,
    order,
    process,
    conciliation,
    qtd,
    value,
    all,
  };
}

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  table: {
    minWidth: 1100,
  },
});

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#005D9F',
    fontFamily: 'Open Sans',
    color: theme.palette.common.white,
  },
  body: {
    color: '#717171',
    fontFamily: 'Open Sans',
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:hover': {
      backgroundColor: '#41b490',
      color: '#41b490',
    },
  },
}))(TableRow);

// eslint-disable-next-line no-unused-vars
export default function MovimentTable({
  docs,
  page,
  setPage,
  itens,
  setItens,
  total,
  generatePlan,
  product,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState([]);

  const [loadingDetails, setLoadingDetails] = useState(false);

  const [movimentDetails, setMovimentDetails] = useState([]);

  function translateStatus(id) {
    switch (id) {
      case '1':
        return t('OrderDetails.table.status.validationError');
      case '2':
        return t('OrderDetails.table.status.bullCharge');
      case '3':
        return t('OrderDetails.table.status.statusChange');
      case '4':
        return t('OrderDetails.table.status.comments');
      case '5':
        return t('OrderDetails.table.status.cnab');
      case '6':
        return t('OrderDetails.table.status.accessoToken');
      case '7':
        return t('OrderDetails.table.status.requestSent');
      case '8':
        return t('OrderDetails.table.status.errorBlock');
      default:
        return '';
    }
  }

  const searchLogs = id => {
    setLoadingDetails(true);
    const cancel = new AbortController();
    api.moviment
      .getDetails(id, cancel)
      .then(res => {
        setMovimentDetails(res);
      })
      .catch(err => console.log(err))
      .finally(() => setLoadingDetails(false));
  };

  const view = data => {
    searchLogs(data);
    setOpen(true);
  };

  useEffect(() => {
    const list = [];
    docs.forEach(d => {
      list.push(
        createData(
          d.id,
          d.cpf || d.cnpj,
          d.name || d.company_name,
          d.proxy,
          `${d.bank_number || ''} / ${d.branch_number || ''} - ${
            d.branch_number_verifier || ''
          } / ${d.account_number || ''}-${d.account_number_verifier || ''}`,
          d.invoice_number || d.nd_number,
          d.order_number,
          d.date_processing,
          d.date_conciliation,
          d.units,
          d.value,
          d.status,
          d,
        ),
      );
    });
    setRows(list);
  }, [docs]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setItens(event.target.value);
  };

  const closeDetails = () => {
    setOpen(false);
    setMovimentDetails([]);
  };

  return (
    <>
      <Paper className={classes.root}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>
                  {t('moviment.table.documents')}
                </StyledTableCell>
                <StyledTableCell>{t('moviment.table.name')}</StyledTableCell>
                <StyledTableCell>
                  {product === 'card'
                    ? t('moviment.table.proxy')
                    : 'Banco/Ag/CC'}
                </StyledTableCell>
                {api.currentUser?.user?.role?.id !== '3' && (
                  <StyledTableCell>{t('moviment.table.notes')}</StyledTableCell>
                )}
                <StyledTableCell>{t('moviment.table.order')}</StyledTableCell>
                <StyledTableCell>
                  {t('moviment.table.processing')}
                </StyledTableCell>
                <StyledTableCell>
                  {t('moviment.table.conciliation')}
                </StyledTableCell>
                <StyledTableCell>{t('moviment.table.qtd')}</StyledTableCell>
                <StyledTableCell>{t('moviment.table.value')}</StyledTableCell>
                <StyledTableCell align="right">
                  {t('moviment.table.status')}
                  <PermissionComponent role={UserRoles.MOVING_DOWNLOAD}>
                    <GetAppIcon
                      style={{ cursor: 'pointer', marginLeft: '5px' }}
                      onClick={generatePlan}
                    />
                  </PermissionComponent>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <StyledTableRow key={i}>
                  <StyledTableCell>{row.cpf}</StyledTableCell>
                  <StyledTableCell>{row.name}</StyledTableCell>
                  <StyledTableCell>
                    {product === 'card' ? row.proxy : row.account}
                  </StyledTableCell>
                  {api.currentUser?.user?.role?.id !== '3' && (
                    <StyledTableCell
                      style={{ cursor: 'pointer' }}
                      onClick={() => window.open(row.all.gcs_pdf)}
                    >
                      {row.nf}
                    </StyledTableCell>
                  )}
                  <StyledTableCell
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      history.push(routing.orderDetails(row.all.order_id))
                    }
                  >
                    {row.order}
                  </StyledTableCell>
                  <StyledTableCell>
                    {row.process &&
                      moment.utc(row.process).format('DD/MM/yyyy')}
                  </StyledTableCell>
                  <StyledTableCell>
                    {row.conciliation &&
                      moment.utc(row.conciliation).format('DD/MM/yyyy')}
                  </StyledTableCell>
                  <StyledTableCell>{row.qtd}</StyledTableCell>
                  <StyledTableCell>
                    {row.value && `R$${row.value}`}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {Status(row.status, t)}
                    <VisibilityIcon
                      style={{ cursor: 'pointer', marginLeft: '5px' }}
                      onClick={() => view(row.id)}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100, 1000, 50000]}
          component="div"
          count={total}
          rowsPerPage={itens}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      {/* MODAL DE EDIÇÃO */}
      <Dialog
        onClose={() => setOpen(!open)}
        open={open}
        fullWidth
        maxWidth="lg"
      >
        <Loading open={loadingDetails} msg="Buscando detalhes..." />
        <DialogTitle id="simple-dialog-title">
          {t('moviment.dialog.title')}
        </DialogTitle>
        <Grid
          container
          spacing={1}
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            padding: 20,
          }}
        >
          <Grid item xs={12} sm={12}>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>
                      {t('moviment.dialog.code')}
                    </StyledTableCell>
                    <StyledTableCell>
                      {t('moviment.dialog.itemCode')}
                    </StyledTableCell>
                    <StyledTableCell>
                      {t('moviment.dialog.eventCode')}
                    </StyledTableCell>
                    <StyledTableCell>
                      {t('moviment.dialog.user')}
                    </StyledTableCell>
                    <StyledTableCell>
                      {t('moviment.dialog.createdAt')}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {t('moviment.dialog.status')}
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {movimentDetails.map((row, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <StyledTableRow key={i}>
                      <StyledTableCell>{row.id}</StyledTableCell>
                      <StyledTableCell>{row.order_item_id}</StyledTableCell>
                      <StyledTableCell>
                        {translateStatus(row.log_event_id)}
                      </StyledTableCell>
                      <StyledTableCell>{row.user}</StyledTableCell>
                      <StyledTableCell>
                        {moment.utc(row.created_at).format('DD/MM/yyyy')}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {Status(row.comments, t)}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <DialogActions>
          <Button
            onClick={closeDetails}
            type="submit"
            color="primary"
            variant="contained"
            autoFocus
          >
            {t('moviment.dialog.btnOk')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
