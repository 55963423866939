/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable import/prefer-default-export */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Typography, Avatar, LinearProgress } from '@material-ui/core';
import moment from 'moment';
import 'moment/locale/pt';

import {
  FaWallet,
  FaChartBar,
  FaDollarSign,
  FaUserFriends,
  FaRegCreditCard,
  FaPiggyBank,
  FaShieldAlt,
} from 'react-icons/fa';
import { MdOutlineRequestQuote } from 'react-icons/md';
import {
  CartesianGrid,
  Legend,
  Area,
  AreaChart,
  Line,
  LineChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  Sector,
  Bar,
  BarChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import Loading from '../../components/loading/Loading';
import TopFiveTale from './TopFiveTable';
import routing from '../../service/routes/routing';
import styles from '../../styles/Layout.module.css';
import stylesDash from '../../styles/Dashboard.module.css';
import api from '../../service/axios/api';

// const pieChartData = [
//   { name: 'Contas', value: 13 },
//   { name: 'Cartones', value: 22 },
//   { name: 'Seguros', value: 7 },
//   { name: 'Licencias', value: 8 },
// ];

const DataFormater = number => {
  if (number > 1000000000) {
    return `${(number / 1000000000).toString()}B`;
  }
  if (number > 1000000) {
    return `${(number / 1000000).toString()}M`;
  }
  if (number > 1000) {
    return `${(number / 1000).toString()}K`;
  }
  return number.toString();
};

function renderActiveShape(props) {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {/* {moment(payload.name).format('dddd')} */}
        {moment(payload.name).format('DD/MM')}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >{`${value} pedidos`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {`(${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
}

function renderActiveShapeMonth(props) {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {/* {moment(payload.name).format('dddd')} */}
        {moment(payload.name).format('MMMM')}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >{`${value} pedidos`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {`(${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
}

function CustomizedLabel(props) {
  const { x, y, stroke, value } = props;

  return (
    <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
      {new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(value)}
    </text>
  );
}

export function PainelClient() {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [topFive, setTopFive] = useState([]);
  const [dataDay, setDataDay] = useState([]);
  const [dataMonth, setDataMonth] = useState([]);
  const [amauntMonth, setAmauntMonth] = useState([]);
  const [activeIndex, setActiveIndexId] = useState(0);
  const [activeIndexMonth, setActiveIndexIdMonth] = useState(0);
  const [pieChartData, setPieChartData] = useState([]);
  const [pieChartmonth, setPieChartMonth] = useState([]);
  const [amauntByProduct, setAmauntByProduct] = useState([]);

  const [amauntDay, setAmauntDay] = useState([]);
  const [amauntByProductDay, setAmauntByProductDay] = useState([]);

  // totais
  const [totalCards, setTotalCards] = useState(0);
  const [totalOrders, setTotalOrders] = useState(0);
  const [totalAmauntCards, setTotalAmauntCards] = useState(0);
  const [orders, setOrders] = useState([]);
  const [totalAmauntTransfer, setTotalAmaunTransfer] = useState(0);
  const [totalAmaunt, setTotalAmaunt] = useState(0);

  useEffect(() => {
    moment.locale('pt');
    const cancel = new AbortController();
    // if (api.currentUser.id !== '9' && api.currentUser.id !== '214') {
    //   history.push(routing.dashboard);
    // }

    api.order
      .summaryClient(cancel)
      .then(res => {
        setTotalCards(res.activeCards);
        setTotalAmauntCards(res.ticket);
      })
      .catch(err => console.log(err));

    api.order
      .get(
        // search?.current?.value,
        '',
        [],
        '',
        3,
        1,
        'DESC',
        null,
        cancel,
      )
      .then(res => {
        setOrders(res.docs);
        // setTotalPages(res.pages);
        // setOrders(res.docs);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        console.log(err);
      });
  }, []);

  return (
    <>
      <Loading
        open={loading}
        msg="Aguarde, estamos preparando as métricas...."
      />
      <Grid container spacing={3} style={{ marginTop: '50px' }}>
        <p
          className={styles.textMenu}
          onKeyDown={() => history.push(routing.dashboard)}
          onClick={() => history.push(routing.dashboard)}
        >
          Menu Inicial
        </p>
        <div
          className="col-sm-12"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <h4 className={styles.textTitle}>Painel</h4>
        </div>
        <Grid
          container
          spacing={3}
          style={{
            display: 'flex',
            justifyContent: 'center',
            cursor: 'pointer',
          }}
        >
          {orders.map(o => (
            <Grid
              key={o.id}
              item
              lg={3}
              md={4}
              sm={6}
              xs={12}
              onClick={() => history.push(routing.orderDetails(o.id))}
            >
              <div className={stylesDash.smallWidget}>
                <div>
                  <Grid container item alignItems="center">
                    <Grid item xs={8}>
                      <Typography variant="subtitle2" color="inherit">
                        {o.order_number}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      style={{ display: 'flex', justifyContent: 'end' }}
                    >
                      <Avatar
                        style={{
                          width: 55,
                          height: 55,
                          backgroundColor: '#2898FF',
                        }}
                      >
                        <MdOutlineRequestQuote />
                      </Avatar>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography variant="h4" color="inherit">
                        {new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        }).format(o.provision)}
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
        <Grid
          container
          spacing={3}
          style={{ display: 'flex', justifyContent: 'center', marginTop: 15 }}
        >
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <div className={stylesDash.smallWidget}>
              <div>
                <Grid container item alignItems="center">
                  <Grid item xs={8}>
                    <Typography variant="subtitle2" color="inherit">
                      CARTÕES ATIVOS
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    style={{ display: 'flex', justifyContent: 'end' }}
                  >
                    <Avatar
                      style={{
                        width: 55,
                        height: 55,
                        backgroundColor: '#2898FF',
                      }}
                    >
                      <FaWallet />
                    </Avatar>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="h4" color="inherit">
                      {new Intl.NumberFormat().format(totalCards)}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <div className={stylesDash.smallWidget}>
              <div>
                <Grid container item alignItems="center">
                  <Grid item xs={8}>
                    <Typography variant="subtitle2" color="inherit">
                      TICKET MÉDIO
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    style={{ display: 'flex', justifyContent: 'end' }}
                  >
                    <Avatar
                      style={{
                        width: 55,
                        height: 55,
                        backgroundColor: '#2898FF',
                      }}
                    >
                      <FaDollarSign />
                    </Avatar>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="h4" color="inherit">
                      {new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      }).format(totalAmauntCards)}
                    </Typography>
                  </Grid>
                  {/* <Grid item xs={3}>
                  <Avatar
                    style={{
                      width: 55,
                      height: 55,
                      backgroundColor: '#2898FF',
                    }}
                  >
                    <FaDollarSign />
                  </Avatar>
                </Grid> */}
                </Grid>
              </div>
            </div>
          </Grid>
        </Grid>
        {/* <Grid item lg={3} md={4} sm={6} xs={12}>
          <div className={stylesDash.smallWidget}>
            <div>
              <Grid
                container
                item
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item xs={8}>
                  <Typography variant="subtitle2" color="inherit">
                    CARGAS - TRANSFERÊNCIAS
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  style={{ display: 'flex', justifyContent: 'end' }}
                >
                  <Avatar
                    style={{
                      width: 55,
                      height: 55,
                      backgroundColor: '#2898FF',
                    }}
                  >
                    <FaDollarSign />
                  </Avatar>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="h4" color="inherit">
                    {new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(totalAmauntTransfer)}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <div className={stylesDash.smallWidget}>
            <div>
              <Grid container item alignItems="center">
                <Grid item xs={8}>
                  <Typography variant="subtitle2" color="inherit">
                    TOTAL DE PEDIDOS ANO
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  style={{ display: 'flex', justifyContent: 'end' }}
                >
                  <Avatar
                    style={{
                      width: 55,
                      height: 55,
                      backgroundColor: '#2898FF',
                    }}
                  >
                    <FaChartBar />
                  </Avatar>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="h4" color="inherit">
                    {new Intl.NumberFormat().format(totalOrders)}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid> */}
        {/* <Grid item lg={12} md={12} sm={12} xs={12}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography variant="subtitle2" color="inherit">
              VOLUME FINANCEIRO NO ANO
            </Typography>
            <Typography variant="h4" color="inherit">
              {new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              }).format(totalAmaunt)}
            </Typography>
          </div>
        </Grid> */}

        {/* <Grid item xs={12} md={4}>
          <div className={stylesDash.longWidget}>
            <Typography variant="h6" color="inherit">
              Melhor dia do mês
            </Typography>
            <ResponsiveContainer width="100%" height={260}>
              <PieChart width={200} height={200}>
                <Pie
                  activeIndex={activeIndex}
                  activeShape={renderActiveShape}
                  data={pieChartData}
                  innerRadius={60}
                  fill="#2898FF"
                  outerRadius={80}
                  dataKey="value"
                  onMouseEnter={(e, id) => setActiveIndexId(id)}
                />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </Grid>
        <Grid item xs={12} md={8}>
          <div className={stylesDash.longWidget}>
            <Typography variant="h6" color="inherit">
              Quantidade de Pedidos (Ano)
            </Typography>
            <ResponsiveContainer width="100%" height={300}>
              <AreaChart
                width={500}
                height={300}
                data={dataMonth}
                margin={{
                  top: 20,
                  right: 20,
                  bottom: 20,
                  left: 20,
                }}
              >
                <XAxis dataKey="name" />
                <YAxis />
                <Area
                  dataKey="pedidos"
                  name="Pedidos"
                  stroke="#8884d8"
                  fill="#8884d8"
                />
                <Tooltip />
                <Legend />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </Grid> */}
      </Grid>
    </>
  );
}
