import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      padding: theme.spacing(5),
    },
    input: {
      marginRight: theme.spacing(3),
    },
    subtitle: {
      fontWeight: 700,
    },
    button: {
      marginRight: theme.spacing(3),
    },
    footer: {
      display: 'flex',
      justifyContent: 'flex-end',
      width: '100%',
      '& > *': {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(2),
      },
    },
  }),
);

export default useStyles;
