/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Button,
  Container,
  Grid,
  makeStyles,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import StepperContext from '../../context/StepperContext';
// import { CommercialAgent } from '../../schemas/CommercialAgentSchema';
import SpinnerPage from '../SpinnerPage';
import FixedCommissionsTable from '../tables/FixedCommissionsTable';
import RatedCommissionsTable from '../tables/RatedCommissions';

const useStyles = makeStyles(theme => ({
  form: {
    minHeight: '500px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  content: {
    padding: theme.spacing(4),
  },
  section: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(4),
    background: '#f0f0f0',
    width: '100%',
    '& > *': {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },
}));

function AgentSalesCommissionForm({ salesCommissionId }) {
  const {
    watch,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      salesCommissionId,
    },
  });
  const classes = useStyles();
  const stepper = useContext(StepperContext);
  // const query = firestore.collection('salesCommissions');
  // const [commissions, loading] = useCollectionDataOnce(query);\

  const commissions = [];
  const loading = false;

  const submit = data => {
    stepper.next({ salesCommissionId: data.salesCommissionId });
  };

  const commissionId = watch('salesCommissionId');

  if (loading) return <SpinnerPage />;

  // TODO: add component to show empty commissions with a create button
  if (!commissions) return null;

  const salesCommission = commissions.find(
    commission => commission.id === commissionId,
  );

  return (
    <form className={classes.form} onSubmit={handleSubmit(submit)}>
      <Container className={classes.content}>
        <Typography variant="h5" component="h3" gutterBottom>
          Comissão de vendas
        </Typography>
        <div className={classes.section}>
          <Grid container spacing={10}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="salesCommissionId"
                render={props => (
                  <TextField
                    onChange={e => props.field.onChange(e.target.value)}
                    value={props.field.value}
                    variant="outlined"
                    select
                    size="small"
                    label="Tabela de comisão"
                    defaultValue=""
                    error={!!errors.salesCommissionId}
                    helperText={
                      errors.salesCommissionId?.type === 'required'
                        ? 'Campo obrigatório'
                        : ''
                    }
                    fullWidth
                  >
                    {commissions.map(commission => (
                      <MenuItem key={commission.id} value={commission.id}>
                        {commission.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            {salesCommission && (
              <>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h6" component="h4" gutterBottom>
                    Taxa Variavel
                  </Typography>
                  <RatedCommissionsTable
                    commissions={salesCommission.ratedCommissions}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h6" component="h4" gutterBottom>
                    Taxa Fixa
                  </Typography>
                  <FixedCommissionsTable
                    commissions={salesCommission.fixedCommissions}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </div>
      </Container>
      <div className={classes.footer}>
        <Button onClick={stepper.prev} type="button" variant="contained">
          Voltar
        </Button>
        <Button type="submit" variant="contained" color="primary">
          Avançar
        </Button>
      </div>
    </form>
  );
}
export default AgentSalesCommissionForm;
