/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import readXlsxFile from 'read-excel-file';
import styles from '../../../styles/Layout.module.css';
import TablePremioTransfer from '../../../components/tables/premiados/TablePremioTransfer';
import api from '../../../service/axios/api';

export default function UploadPlan({
  myFile,
  setMyfile,
  orders,
  setOrders,
  setMsg,
  setLoad,
  contract,
}) {
  const fileXls = useRef(null);
  // const [myFile, setMyfile] = useState();
  // const [orders, setOrders] = useState([]);

  const templateDownload = () => {
    setMsg('Buscando modelo de planilha....');
    setLoad(true);
    const cancel = new AbortController();
    if (contract) {
      api.contract
        .getPlanModel(cancel, contract.value)
        .then(res => {
          // const url = window.URL.createObjectURL(new Blob([res]));
          const url = res.retorno.link;
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'carga_template.xlsx'); // or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch(err => console.log(err))
        .finally(() => setLoad(false));
    }
  };

  useEffect(() => {
    if (myFile) {
      const dataTable = [];
      readXlsxFile(myFile).then(r => {
        const keys = r[0];
        r.forEach((data, i) => {
          if (i > 0) {
            dataTable.push({
              [keys[0]]: data[0],
              [keys[1]]: data[1],
              [keys[2]]: data[2],
              [keys[3]]: data[3],
              [keys[4]]: data[4],
              [keys[5]]: data[5],
              [keys[6]]: data[6],
              [keys[7]]: data[7],
              [keys[8]]: data[8],
              [keys[9]]: data[9],
              [keys[10]]: data[10],
              [keys[11]]: data[11],
              [keys[12]]: data[12],
              [keys[13]]: data[13],
            });
          }
        });
        setOrders(dataTable);
      });
    }
  }, [myFile]);

  return (
    <div className="box-new-card">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h6 className={styles.textBoxInform}>Insira os dados do pedido</h6>
        {/* <h6 className={styles.textBoxInform}>Pedido P000000</h6> */}
      </div>
      <Grid container direction="row">
        <Grid item>
          <p
            className="label-planilhas"
            styles={{ cursor: 'pointer' }}
            onClick={templateDownload}
          >
            <img
              id="planilha"
              src="../img/download.svg"
              alt="logo"
              style={{ marginRight: '0px' }}
              className="img-planilhas"
            />
          </p>
        </Grid>
        <Grid item>
          <p
            className="label-planilhas"
            styles={{ cursor: 'pointer' }}
            onClick={() => fileXls.current.click()}
          >
            <img
              id="planilha"
              src="../img/upload.svg"
              alt="logo"
              className="img-planilhas"
            />
            Planilha de Premiado
          </p>
          <input
            ref={fileXls}
            type="file"
            style={{
              opacity: 0,
            }}
            // accept="media_type"
            onChange={() => setMyfile(fileXls.current.files[0])}
            className="form-control"
            id="inputNumSerie"
            placeholder="Arquivo DWG do projeto"
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        direction="row"
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          paddingBottom: 10,
        }}
      >
        <Grid item xs={12}>
          <TablePremioTransfer
            orders={orders}
            totalPages={1}
            page={1}
            // setPage={setPage}
            itens={10}
            // setItens={setItens}
          />
        </Grid>
      </Grid>
    </div>
  );
}
