/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
import React, { useState, useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import Chip from '@material-ui/core/Chip';
import _ from 'lodash';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import locale from 'date-fns/locale/pt-BR';
import DateFnsUtils from '@date-io/date-fns';

import { TextField, Grid, Button, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment-timezone';
import AddIcon from '@material-ui/icons/Add';
import FlashContext from '../../contexts/FlashContext';

const customStyles1 = {
  control: provided => ({
    ...provided,
    width: 125,
    height: 40,
    borderRadius: 10,
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  option: provided => ({
    ...provided,
  }),
};

// const useStyles = makeStyles((theme) => createStyles({
//   myTextField: {
//     backgroundColor: 'none',
//     boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
//     border: 'none',
//     color: '#717171',
//     fontWeight: 'bold',
//     fontFamily: 'Open Sans',
//   },
//   text: {
//     fontFamily: 'Open Sans',
//     fontWeight: 'bold',
//     color: '#717171',
//   },
// }));

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: 10,
    margin: 0,
  },
  chip: {
    margin: 10,
  },
  myTextField: {
    backgroundColor: 'none',
    boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
    border: 'none',
    color: '#717171',
    fontWeight: 'bold',
    fontFamily: 'Open Sans',
  },
  text: {
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
    color: '#717171',
  },
  input: {
    backgroundColor: '#fff',
    marginTop: 0,
    // color: 'white',
  },
}));

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    // width: 200,
    backgroundColor: 'none',
    boxShadow: 'none',
    border: 'none',
    // fontWeight: 'bold',
    fontFamily: 'Open Sans',
    // fontSize: 24,
  }),
  indicatorSeparator: styles => ({ display: 'none' }),
  option: (provided, state) => ({
    ...provided,
    fontWeight: 'bold',
    fontFamily: 'Open Sans',
    // color: state.data.color,
    // backgroundColor: state.data.bgcolor,
    // fontSize: 24,
  }),
  placeholder: (provided, state) => ({
    fontWeight: 'bold',
    fontFamily: 'Open Sans',
    marginTop: -20,
    // paddingBottom: 20,
    // fontSize: 24,
    // marginBottom: 30,
  }),
};

function SelectOptionsDcp({
  setStatus,
  chipData,
  setChipData,
  find,
  status,
  // setOrderBy,
  // product,
  // setProduct,
}) {
  const { t } = useTranslation();
  const [searchType, setSearchType] = useState();
  const { notifyError, notifySuccess } = useContext(FlashContext);
  // const [chipData, setChipData] = useState([]);
  const [selectedDate, handleDateChange] = useState(new Date());
  const classes = useStyles();
  const searchRef = useRef(null);

  const internSearch = () => {
    if (searchType?.valeu !== '' && searchRef?.current?.value !== '') {
      includeSearch(true);
    } else {
      find();
    }
  };

  const [options, setOptions] = useState([
    {
      label: 'Pagador',
      value: 'name',
    },
    {
      label: 'CPF/CNPJ',
      value: 'document',
    },
    {
      label: 'Data Pagamento',
      value: 'datePayment',
    },
  ]);

  const handleDelete = chipToDelete => () => {
    setOptions([
      ...options,
      { label: chipToDelete.by, value: chipToDelete.byType },
    ]);
    setChipData(chips => chips.filter(chip => chip.key !== chipToDelete.key));
  };

  const includeSearch = btn => {
    if (searchType.value === 'datePayment') {
      const orig = _.cloneDeep(options);
      setChipData([
        ...chipData,
        {
          key: chipData.length + 1,
          label: moment(selectedDate).format('DD/MM/YYYY'),
          by: searchType.label,
          byType: searchType.value,
          date: selectedDate,
        },
      ]);
      const myfind = orig.findIndex(e => e.value === searchType.value);
      orig.splice(myfind, 1);
      setSearchType(null);
      setOptions(orig);
      if (btn) {
        find({
          label: selectedDate,
          byType: searchType.value,
          date: selectedDate,
        });
      }
    } else {
      const orig = _.cloneDeep(options);
      console.log(searchType);
      setChipData([
        ...chipData,
        {
          key: chipData.length + 1,
          label: searchRef.current.value,
          by: searchType.label,
          byType: searchType.value,
          date: selectedDate,
        },
      ]);
      const myfind = orig.findIndex(e => e.value === searchType.value);
      orig.splice(myfind, 1);
      setSearchType(null);
      setOptions(orig);
      if (btn) {
        find({
          label: searchRef.current.value,
          byType: searchType.value,
          date: selectedDate,
        });
      }
      searchRef.current.value = '';
    }
  };

  const addChip = () => {
    if (searchType) {
      includeSearch(false);
    } else {
      notifyError(t('contract.filters.typeSearchMsg'));
    }
  };

  const teclaEnter = e => {
    if (e.key === 'Enter') {
      internSearch();
    }
  };

  return (
    <>
      <Grid
        container
        style={{
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 30,
          marginTop: 15,
        }}
      >
        <Grid item xs={12} sm={4}>
          <Grid container spacing={0} style={{ justifyContent: 'start' }}>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ marginLeft: 15, display: 'flex' }}
            >
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <label>{t('contract.filters.status')}:</label>
              </Box>
              <Select
                styles={customStyles1}
                classNamePrefix="select"
                placeholder={t('contract.filters.type')}
                isSearchable={false}
                options={[
                  {
                    label: 'Aguardando Pagamento',
                    value: 'PENDING',
                  },
                  {
                    label: 'Pago',
                    value: 'RECEIVED',
                  },
                  {
                    label: 'Pagamento confirmado',
                    value: 'CONFIRMED',
                  },
                  {
                    label: 'Vencida',
                    value: 'OVERDUE',
                  },
                  {
                    label: 'Estornada',
                    value: 'REFUNDED',
                  },
                  {
                    label: 'Estorno Solicitado',
                    value: 'REFUND_REQUESTED',
                  },
                  {
                    label: 'Estorno em processamento',
                    value: 'REFUND_IN_PROGRESS',
                  },
                  {
                    label: 'Recebido chargeback',
                    value: 'CHARGEBACK_REQUESTED',
                  },
                  {
                    label: 'Em disputa de chargeback',
                    value: 'CHARGEBACK_DISPUTE',
                  },
                  {
                    label: 'Disputa vencida, aguardando repasse da adquirente',
                    value: 'AWAITING_CHARGEBACK_REVERSAL',
                  },
                  {
                    label: 'Em processo de negativação',
                    value: 'DUNNING_REQUESTED',
                  },
                  {
                    label: 'Recuperada',
                    value: 'DUNNING_RECEIVED',
                  },
                  {
                    label: 'Pagamento em análise',
                    value: 'AWAITING_RISK_ANALYSIS',
                  },
                  {
                    label: 'Todos',
                    value: '',
                  },
                ]}
                value={status}
                onChange={setStatus}
                menuPortalTarget={document.body}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} sm={8}>
          <Grid container spacing={1} style={{ justifyContent: 'end' }}>
            <Grid item>
              <Select
                fullWidth
                styles={customStyles1}
                onChange={setSearchType}
                value={searchType}
                classNamePrefix="select"
                placeholder={t('contract.filters.type')}
                isSearchable={false}
                options={options}
                menuPortalTarget={document.body}
                style={{ zIndex: 999 }}
              />
            </Grid>
            <Grid
              item
              // xs={12}
              // sm={6}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              {searchType?.value === 'datePayment' ? (
                <MuiPickersUtilsProvider locale={locale} utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    style={{ backgroundColor: '#fff' }}
                    // label="With keyboard"
                    size="small"
                    format="dd/MM/yyyy"
                    value={selectedDate}
                    InputProps={{ readOnly: true }}
                    InputAdornmentProps={{ position: 'start' }}
                    onChange={date => handleDateChange(date)}
                  />
                </MuiPickersUtilsProvider>
              ) : (
                <TextField
                  onKeyUpCapture={e => teclaEnter(e)}
                  inputRef={searchRef}
                  color="secondary"
                  width="400px"
                  className={classes.textField}
                  fullWidth
                  size="small"
                  style={{ maxHeight: 10, minWidth: 300 }}
                  variant="outlined"
                  InputProps={{
                    className: classes.input,
                  }}
                />
              )}

              <Button
                onClick={addChip}
                variant="contained"
                style={{ marginLeft: 5, marginRight: 5 }}
              >
                <AddIcon />
              </Button>
              <Button
                // style={{ marginLeft: 10, marginRight: 5 }}
                onClick={internSearch}
                variant="contained"
              >
                {t('contract.filters.btnSearch')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4} sm={6}>
        <Grid item className={classes.root}>
          {chipData.map((dt, i) => {
            let icon;

            return (
              // eslint-disable-next-line react/no-array-index-key
              <li key={i}>
                <Chip
                  icon={icon}
                  label={`${dt.by} | ${dt.label}`}
                  onDelete={handleDelete(dt)}
                  className={classes.chip}
                />
              </li>
            );
          })}
        </Grid>
      </Grid>
    </>
  );
}

export default SelectOptionsDcp;
