/* eslint-disable no-unused-vars */
/* eslint-disable no-promise-executor-return */
/* eslint-disable no-shadow */
import { GridColDef, GridValueFormatterParams } from '@material-ui/data-grid';
import { differenceInMonths, differenceInYears, format } from 'date-fns';

import { priceFormatter } from './currency';

const getMonthsBetweenDates = (dateLeft, dateRight) => {
  return differenceInMonths(dateLeft, dateRight);
};

const getYearsBetweenDates = (dateLeft, dateRight) => {
  return differenceInYears(dateLeft, dateRight);
};

const rateFormatter = params => `${params.value}%`;

const fixedFormatter = params => priceFormatter.format(Number(params.value));

const getColumnsNumber = data => {
  return data.months;
};

export const createRuleColumns = params => {
  const { commissionType } = params;

  const formatter = commissionType === 'rated' ? rateFormatter : fixedFormatter;

  const getColumnName = colIdx => {
    return `Mês ${colIdx + 1}`;
  };

  const columns = [
    {
      field: 'quantity',
      headerName: 'Quantidade',
      headerAlign: 'center',
      disableColumnMenu: true,
      align: 'center',
      width: 100,
    },
    {
      field: 'participant',
      headerName: 'Participantes',
      valueFormatter: params => params.value?.name,
      width: 150,
    },
  ];

  const size = getColumnsNumber(params);

  for (let i = 0; i < size; i += 1) {
    let columnName;
    if (size === 1) {
      columnName = 'Vitalício';
    } else {
      columnName = getColumnName(i);
    }
    columns.push({
      width: 180,
      editable: true,
      headerName: columnName,
      field: `commission.${i}`,
      valueFormatter: formatter,
    });
  }

  return columns;
};

export const createRuleRows = params => {
  const rows = [];
  const { participants } = params;
  const size = getColumnsNumber(params);

  participants.forEach(participant => {
    const rowData = {
      id: participant.id,
      participant,
      quantity: 1,
    };
    for (let i = 0; i < size; i += 1) {
      rowData[`commission.${i}`] = 0;
    }
    rows.push(rowData);
  });

  return rows;
};

export const simulateRule = ({ rows, income, columns, data }) => {
  return new Promise(resolve => {
    const { commissionType, months } = data.rule;

    let total = income * months;
    const paymentPerMonth = income;

    let totalCommission = 0;
    let newRows = rows.slice();
    let newColumns = columns.map(col => {
      if (col.field.includes('commission')) {
        return {
          ...col,
          editable: false,
          valueFormatter: fixedFormatter,
        };
      }

      return col;
    });

    const commissions = columns
      .map(col => col.field)
      .filter(field => field.includes('commission'));

    const participantsCommissions = {};

    commissions.forEach(commission => {
      let sum = 0;
      newRows = newRows.map(row => {
        let commissionRevenue;
        if (commissionType === 'rated') {
          commissionRevenue = (paymentPerMonth * Number(row[commission])) / 100;
        } else {
          commissionRevenue = Number(row[commission]);
        }

        sum += commissionRevenue;
        totalCommission += commissionRevenue;

        const { id } = row.participant;

        if (!participantsCommissions[id]) {
          participantsCommissions[id] = {
            quantity: row.quantity,
            participant: row.participant,
            commissions: [],
            commissionsPerMonth: [],
            totalCommission: 0,
          };
        }
        participantsCommissions[id].commissions.push(row[commission]);
        participantsCommissions[id].commissionsPerMonth.push(commissionRevenue);
        participantsCommissions[id].totalCommission += commissionRevenue;

        return {
          ...row,
          [commission]: commissionRevenue,
        };
      });
      total -= sum;
    });

    newRows = newRows.map(row => {
      return {
        ...row,
        totalCommission: participantsCommissions[row.participant.id],
      };
    });
    newColumns = newColumns.concat({
      width: 180,
      headerName: 'Total',
      field: 'totalCommission',
      hide: true,
      valueFormatter: fixedFormatter,
    });

    resolve({
      rows: newRows,
      revenue: total,
      columns: newColumns,
      participantsCommissions,
      paymentPerMonth,
      totalCommission,
    });
  });
};

export function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function formatProposalStatus(status) {
  const statusTranslations = {
    opened: 'Aberto',
  };

  return statusTranslations[status] || status;
}
