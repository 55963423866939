import React from 'react';
import Button from '../../../../../components/button/MyButton';

// eslint-disable-next-line react/prop-types
export default function Created({ setOpenModal }) {
  return (
    <div
      className="col-sm-12"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <img src="./img/checkOk.svg" alt="ok" className="img-category-check" />
      <h5 className="txt-category-success" style={{ marginTop: 30 }}>
        Produto criado com sucesso!
      </h5>
      <Button
        style={{
          marginTop: 30,
          marginBottom: 20,
          marginRight: 20,
          height: 40,
          width: 129,
        }}
        onClick={() => setOpenModal(false)}
        label="OK"
      />
    </div>
  );
}
