const routing = {
  acesso: '/acessotopup',
  cnab: '/cnab',
  acessoProcess: '/cardrelease',
  user: '/user',
  batch: '/batch',
  campaigns: '/campaign',
  contract: '/contract',
  commercialagent: '/commercialagent',
  order: '/order',
  servicecodes: '/servicecodes',
  dcp: '/dcp',
  gm: '/PaaSintegration',
  orderItems: '/orderItems',
  statement: '/bankStatements',
  banks: '/banks',
  company: '/company',
  product: '/product',
  notification: '/notification',
  individual: '/individual',
  invoice: '/invoice',
  service: '/service',
  joycode: '/joycode',
  card: '/card',
  rps: '/rps',
  bankly: '/bankStatus',
  tutorials: '/tutorials',
  permissions: '/permissionsGroup',
};

export default routing;
