/* eslint-disable no-param-reassign */
/* eslint-disable no-var */
/* eslint-disable default-case */
/* eslint-disable no-unused-vars */
/* eslint-disable import/prefer-default-export */
import React, { useState, useContext, useEffect, useRef } from 'react';
import { Grid, TextField, Switch } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Loading from '../../components/loading/Loading';
import MyButton from '../../components/button/MyButton';
import api from '../../service/axios/api';
import FlashContext from '../../components/contexts/FlashContext';

const useStyles = makeStyles(() => ({
  myTextField: {
    // backgroundColor: '#fff',
    // boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
    border: 'none',
    // color: '#000',
    fontWeight: 'bold',
    fontFamily: 'Open Sans',
  },
  text: {
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
    backgroundColor: '#fff',
    border: 'none',
    borderRadius: '5px',
    // color: 'black',
  },
  track: {},
}));

export function Dcp() {
  const classes = useStyles();
  const [steep, setSteep] = useState(0);
  const [email, setEmail] = useState('');
  const [cpf, setCpf] = useState('');
  const { notifyError } = useContext(FlashContext);

  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState('Gerando demonstrativo...');

  const [id, setId] = useState();
  const [linkQr, setLinkQr] = useState('');

  const [timer, setTimer] = useState(300);
  const [minute, setMinute] = useState('');
  const interval = useRef(null);

  // let timer = 60;

  function pad(num) {
    // eslint-disable-next-line prefer-template
    return ('0' + num).slice(-2);
  }
  function hhmmss(secs) {
    var minutes = Math.floor(secs / 60);
    // eslint-disable-next-line operator-assignment
    secs = secs % 60;
    // var hours = Math.floor(minutes / 60);
    // eslint-disable-next-line operator-assignment
    minutes = minutes % 60;
    setMinute(`${pad(minutes)}:${pad(secs)}`);
    return secs;
    // return pad(hours)+":"+pad(minutes)+":"+pad(secs); for old browsers
  }

  const goTimmer = () => {
    interval.current = setInterval(() => {
      updateTimer();
    }, 1000);
  };

  const finish = () => {
    interval.current = setTimeout(() => {
      setSteep(0);
      setCpf('');
      setEmail('');
    }, 10000);
  };

  useEffect(() => {
    if (id) {
      goTimmer();
    }
  }, [id]);

  const updateTimer = () => {
    setTimer(seconds => {
      if (seconds === 0) {
        clearInterval(interval.current);
        // setSteep(2);
        // cancelPayment();
        notifyError('Solicitação cancelada!');
        setSteep(0);
        return 0;
      }
      if (
        seconds === 10 ||
        seconds === 20 ||
        seconds === 30 ||
        seconds === 40 ||
        seconds === 50 ||
        seconds === 60 ||
        seconds === 70 ||
        seconds === 80 ||
        seconds === 90 ||
        seconds === 100 ||
        seconds === 110 ||
        seconds === 120 ||
        seconds === 130 ||
        seconds === 140 ||
        seconds === 150 ||
        seconds === 160 ||
        seconds === 170 ||
        seconds === 180 ||
        seconds === 190 ||
        seconds === 200 ||
        seconds === 210 ||
        seconds === 220 ||
        seconds === 230 ||
        seconds === 240 ||
        seconds === 250 ||
        seconds === 260 ||
        seconds === 270 ||
        seconds === 280 ||
        seconds === 290
      ) {
        // buscar o pedido
        api.dcp
          .getPaymentStatus(id)
          .then(response => {
            if (response.retorno === 'RECEIVED') {
              setSteep(2);
              clearInterval(interval.current);
              finish();
            }
          })
          .catch(error => {
            console.log(error);
            // if (error.length > 0 && error[0].Message) {
            //   // error.forEach(e => {

            //   // });
            // } else {
            //   Toast.show({
            //     type: 'error',
            //     text1: error,
            //   });
            // }
          });
      }
      hhmmss(seconds - 1);
      return seconds - 1;
    });
  };

  const cleanNumber = value => {
    const tmp = value.replace(/[/().\s-]+/g, '');
    return tmp;
  };

  const cpfMask = data => {
    return data
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
  };

  const cnpjMask = data => {
    return data
      .replace(/\D+/g, '') // não deixa ser digitado nenhuma letra
      .replace(/(\d{2})(\d)/, '$1.$2') // captura 2 grupos de número o primeiro com 2 digitos e o segundo de com 3 digitos, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de número
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1/$2') // captura 2 grupos de número o primeiro e o segundo com 3 digitos, separados por /
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
  };

  const handleDoc = data => {
    if (data.length > 14) {
      setCpf(cnpjMask(data));
    } else {
      setCpf(cpfMask(data));
    }
  };

  const handleNext = () => {
    setLoading(true);
    setMsg('Solicitando demonstrativo...');
    if (steep === 0) {
      api.dcp
        .extern(email, cleanNumber(cpf))
        .then(res => {
          console.log(res);
          console.log(res.retorno.pix.encodedImage);
          setId(res.retorno.id);
          setLinkQr(res.retorno.pix.encodedImage);
          setSteep(steep + 1);
          // goTimmer();
        })
        .catch(err => {
          console.log(err);
          notifyError(err?.message);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setSteep(steep + 1);
    }
  };

  const returnSteep = () => {
    switch (steep) {
      case 0:
        return (
          <div
            style={{
              width: '45%',
              backgroundColor: '#2A3176',
              padding: '1.5rem',
              borderRadius: '8px',
            }}
          >
            <h4 style={{ textAlign: 'center', color: '#fff' }}>
              Solicite seu Demonstrativo Consolidado de Premiação <br /> Ano
              base 2023
            </h4>
            <div style={{ marginTop: 30 }}>
              <p style={{ color: '#fff' }}>CPF/CNPJ</p>
              <TextField
                className={classes.text}
                variant="outlined"
                size="small"
                fullWidth
                placeholder="CPF/CNPJ"
                value={cpf}
                onChange={e => handleDoc(e.target.value)}
                InputProps={{
                  classes: {
                    notchedOutline: classes.myTextField,
                    input: classes.text,
                  },
                }}
              />
            </div>
            <div style={{ marginTop: 15 }}>
              <p style={{ color: '#fff' }}>E-mail</p>
              <TextField
                className={classes.text}
                variant="outlined"
                size="small"
                fullWidth
                placeholder="E-mail"
                value={email}
                onChange={e => setEmail(e.target.value)}
                InputProps={{
                  classes: {
                    notchedOutline: classes.myTextField,
                    input: classes.text,
                  },
                }}
              />
            </div>
            <div
              style={{
                marginTop: 30,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <MyButton
                style={{
                  marginLeft: 5,
                  // width: 80,
                  // height: 40,
                  borderRadius: 10,
                  // backgroundColor: '#657588',
                }}
                onClick={handleNext}
                label="SOLICITAR"
              />
            </div>
          </div>
        );
      case 1:
        return (
          <div
            style={{
              width: '50%',
              backgroundColor: '#2A3176',
              padding: '1.5rem',
              borderRadius: '8px',
            }}
          >
            <h4 style={{ textAlign: 'center', color: '#fff' }}>
              Realize o pagamento da taxa de R$11.90 <br /> via o PIX-QRcode
              abaixo
            </h4>
            <div
              style={{
                marginTop: 30,
                // display: 'flex',
                justifyContent: 'center',
              }}
            >
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <img
                  style={{ objectFit: 'cover', width: '30%' }}
                  src={`data:image/png;base64,${linkQr}`}
                  alt="qrcode"
                />
              </div>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: 30,
                }}
              >
                <MyButton
                  style={{
                    marginLeft: 5,
                    // width: 80,
                    // height: 40,
                    borderRadius: 10,
                    backgroundColor: '#657588',
                  }}
                  onClick={() => {
                    notifyError('Solicitação cancelada!');
                    clearInterval(interval.current);
                    setSteep(0);
                  }}
                  label="CANCELAR"
                />
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div
            style={{
              width: '50%',
              // backgroundColor: '#2A3176',
              padding: '1.5rem',
              borderRadius: '8px',
              marginTop: 30,
            }}
          >
            <div
              style={{
                position: 'absolute',
                margin: '3rem',
                width: '40%',
                height: '15rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '16px',
                backgroundColor: 'rgba(2,196,152,0.2',
              }}
            >
              <img
                alt="sucess"
                style={{ position: 'absolute', top: '-100px' }}
                src="/assets/success.svg"
              />
              <h5
                style={{
                  color: '#565656',
                  marginTop: '2%',
                  textAlign: 'center',
                }}
              >
                Pagamento realizado com sucesso. Em instantes você receberá o
                seu Demonstrativo Consolidado de Premiação no email informado.
              </h5>
            </div>
          </div>
        );
      default:
        return <h1>notfound</h1>;
    }
  };
  return (
    <div style={{ width: '100%' }}>
      <img
        style={{ objectFit: 'cover', width: '100%' }}
        src="https://elementprepagos.net.br/images/element-pre-pagos-banner-site-card.jpg"
        alt="DCP"
      />
      {/* <h1>DCP</h1> */}
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          marginTop: 20,
        }}
      >
        <Loading open={loading} msg={msg} />
        {returnSteep()}
      </div>
    </div>
  );
}
