/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import * as React from 'react';
// import Grid from '@mui/material/Grid';
// import Typography from '@mui/material/Typography';
// import TextField from '@mui/material/TextField';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
import Select from 'react-select';
// import FormGroup from '@mui/material/FormGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';

// import Radio from '@mui/material/Radio';
// import RadioGroup from '@mui/material/RadioGroup';
// import FormControl from '@mui/material/FormControl';
import { useTranslation } from 'react-i18next';

import { makeStyles, createStyles, styled } from '@material-ui/core/styles';

// import TextField from '@mui/material/TextField';

// import Grid from '@mui/material/Grid';

import {
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  FormControl,
  TextField,
} from '@material-ui/core';

const options = [
  {
    label: 'MasterCard',
    value: 1,
  },
  {
    label: 'Visa',
    value: 2,
  },
  {
    label: 'elo',
    value: 3,
  },
];

const optionsE = [
  {
    label: 'Acesso',
    value: 1,
  },
];

const useStyles = makeStyles(theme =>
  createStyles({
    myTextField: {
      backgroundColor: 'none',
      boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
      border: 'none',
      color: '#717171',
      fontWeight: 'bold',
      fontFamily: 'Open Sans',
    },
    text: {
      fontFamily: 'Open Sans',
      fontWeight: 'bold',
      color: '#717171',
    },
    radio: {
      color: '#9c9c9c',
      '&$checked': {
        color: '#41b490',
      },
    },
    checked: {},
  }),
);

// const customStyles = {
//   control: provided => ({
//     ...provided,
//     maxWidth: 240,
//     backgroundColor: 'none',
//     boxShadow: 'none',
//     borderRadius: 10,
//     // border: 'none',
//     color: '#717171',
//     fontWeight: 'bold',
//     fontFamily: 'Open Sans',
//     // fontSize: 24,
//   }),
//   indicatorSeparator: () => ({ display: 'none' }),
//   option: provided => ({
//     ...provided,
//     fontWeight: 'bold',
//     fontFamily: 'Open Sans',
//     color: '#717171',
//     // color: state.data.color,
//     // backgroundColor: state.data.bgcolor,
//     // fontSize: 24,
//   }),
//   placeholder: () => ({
//     fontWeight: 'bold',
//     fontFamily: 'Open Sans',
//     marginTop: -20,
//     color: '#717171',
//     // paddingBottom: 20,
//     // fontSize: 24,
//     // marginBottom: 30,
//   }),
// };
const customStyles = {
  control: provided => ({
    ...provided,
    maxWidth: 240,
    backgroundColor: 'none',
    // boxShadow: 'none',
    boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
    border: 'none',
    color: '#717171',
    // fontWeight: 'bold',
    fontFamily: 'Open Sans',
    // fontSize: 24,
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  option: provided => ({
    ...provided,
    // fontWeight: 'bold',
    fontFamily: 'Open Sans',
    color: '#717171',
    // color: state.data.color,
    // backgroundColor: state.data.bgcolor,
    // fontSize: 24,
  }),
  placeholder: () => ({
    // fontWeight: 'bold',
    fontFamily: 'Open Sans',
    marginTop: -20,
    color: '#717171',
    // paddingBottom: 20,
    // fontSize: 24,
    // marginBottom: 30,
  }),
};

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark'
        ? 'rgba(57,75,89,.5)'
        : 'rgba(206,217,224,.5)',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#41b490',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#41b490',
  },
});

export default function TypeCard({ setProductName, productName }) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid
      container
      spacing={1}
      style={{
        border: '2px solid #c5c5c5',
        borderRadius: 10,
        justifyContent: 'center',
        padding: 10,
      }}
    >
      <Grid
        container
        spacing={1}
        style={{
          justifyContent: 'space-between',
          padding: 1,
        }}
      >
        <h5 className="text-box-inform">
          {t('Product.creditCard.checkout.feature')}
        </h5>
        <p className="text-box-inform-2">COD.: PROD_CARDPP_002</p>
      </Grid>
      <Grid
        container
        spacing={1}
        style={{
          justifyContent: 'space-between',
          padding: 10,
        }}
      >
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          lg={4}
          style={{
            direction: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div>
            <p className="text-select-label ">
              {t('Product.creditCard.checkout.flag')}
            </p>
            <Select
              styles={customStyles}
              className="basic-single"
              classNamePrefix="select"
              placeholder={t('Product.creditCard.checkout.selectFlag')}
              isSearchable={false}
              options={options}
            />
          </div>
          <div>
            <p className="text-select-label ">
              {t('Product.creditCard.checkout.issuer')}
            </p>
            <Select
              styles={customStyles}
              className="basic-single"
              classNamePrefix="select"
              placeholder={t('Product.creditCard.checkout.selectIssuer')}
              isSearchable={false}
              options={optionsE}
            />
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          lg={4}
          style={{
            direction: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div>
            <p className="text-select-label ">
              {t('Product.creditCard.checkout.name')}
            </p>
            {/* <input className="myInput"
            placeholder={t('Product.creditCard.checkout.nameSugestion')} /> */}
            <TextField
              classes={{ notchedOutline: classes.input }}
              className={classes.text}
              // fullWidth
              value={productName}
              id="outlined-basic"
              // label={t('Product.creditCard.checkout.nameSugestion')}
              variant="outlined"
              size="small"
              onChange={e => setProductName(e.target.value)}
              // inputRef={search}
              InputProps={{
                classes: {
                  notchedOutline: classes.myTextField,
                  input: classes.text,
                },
              }}
            />
          </div>
          <div>
            <p className="text-select-label ">
              {t('Product.creditCard.checkout.function')}
            </p>
            <FormGroup className="myFormGroup">
              <FormControlLabel
                // className="myLabel"
                control={
                  <Checkbox
                    sx={{
                      '&:hover': { bgcolor: 'transparent' },
                    }}
                    disableRipple
                    color="default"
                    checkedIcon={<BpCheckedIcon />}
                    icon={<BpIcon />}
                    inputProps={{ 'aria-label': 'Checkbox demo' }}
                  />
                }
                label={t('Product.creditCard.checkout.opt1Function')}
              />
              <FormControlLabel
                // className="myLabel"
                control={
                  <Checkbox
                    sx={{
                      '&:hover': { bgcolor: 'transparent' },
                    }}
                    disableRipple
                    color="default"
                    checkedIcon={<BpCheckedIcon />}
                    icon={<BpIcon />}
                    inputProps={{ 'aria-label': 'Checkbox demo' }}
                  />
                }
                label={t('Product.creditCard.checkout.opt2Function')}
              />
            </FormGroup>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          lg={4}
          style={{
            direction: 'column',
            justifyContent: 'end',
            alignItems: 'end',
          }}
        >
          <div>
            <p className="text-select-label ">
              {t('Product.creditCard.checkout.technology')}
            </p>
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="gender"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  // className="myLabel"
                  value="chip"
                  control={
                    <Radio
                      classes={{
                        root: classes.radio,
                        checked: classes.checked,
                      }}
                    />
                  }
                  label={t('Product.creditCard.checkout.chip')}
                />
                <FormControlLabel
                  // className="myLabel"
                  value="nfc"
                  control={
                    <Radio
                      classes={{
                        root: classes.radio,
                        checked: classes.checked,
                      }}
                    />
                  }
                  label={t('Product.creditCard.checkout.nfc')}
                />
                <FormControlLabel
                  // className="myLabel"
                  value="tarja"
                  control={
                    <Radio
                      classes={{
                        root: classes.radio,
                        checked: classes.checked,
                      }}
                    />
                  }
                  label={t('Product.creditCard.checkout.tarja')}
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div>
            <p className="text-select-label ">
              {t('Product.creditCard.checkout.coverage')}
            </p>
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="gender"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  // className="myLabel"
                  value="nacional"
                  control={
                    <Radio
                      classes={{
                        root: classes.radio,
                        checked: classes.checked,
                      }}
                    />
                  }
                  label={t('Product.creditCard.checkout.national')}
                />
                <FormControlLabel
                  // className="myLabel"
                  value="internacional"
                  control={
                    <Radio
                      classes={{
                        root: classes.radio,
                        checked: classes.checked,
                      }}
                    />
                  }
                  label={t('Product.creditCard.checkout.international')}
                />
              </RadioGroup>
            </FormControl>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}
