/* eslint-disable react/prop-types */
// eslint-disable-next-line no-unused-vars
import React, { useEffect, forwardRef, useCallback, useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
// import useBoolean from '../../hooks/useBoolean/useBoolean';

const CheckboxForm = forwardRef(
  ({ name, onChange, disabled, defaultValue, ...others }, ref) => {
    // const { value: isChecked, toggle: toggleChecked } = useBoolean({
    //   initialValue: defaultValue,
    // });
    const [isChecked, setIsChecked] = useState(defaultValue);
    // const handleChange = useCallback(() => toggleChecked(), []);
    // const handleChange = () => {
    //   console.log(toggleChecked);
    // };
    useEffect(() => {
      onChange({ target: { name, value: isChecked } });
    }, [isChecked]);

    return (
      <Checkbox
        name={name}
        checked={isChecked}
        inputProps={{
          ...others,
          onChange: () => setIsChecked(!isChecked),
        }}
        inputRef={ref}
        disabled={disabled}
      />
    );
  },
);

export default CheckboxForm;
