import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStylesTextOutlined = makeStyles(() =>
  createStyles({
    root: {
      borderRadius: 10,
    },
    notchedOutline: {
      borderColor: 'transparent !important',
    },
    input: {
      backgroundColor: '#ececec !important',
      borderRadius: 10,
    },
  }),
);

const useStylesLabel = makeStyles(() =>
  createStyles({
    root: {
      fontSize: 20,
      color: '#737373',
    },
    shrink: {
      transform: 'translate(0px, -24px) scale(0.75) !important',
    },
  }),
);

const useStylesHelperText = makeStyles(() =>
  createStyles({
    root: {
      marginLeft: 0,
    },
  }),
);

export { useStylesTextOutlined, useStylesLabel, useStylesHelperText };
