import { useState, useMemo, useCallback } from 'react';

// import { useStepsParams, useStepsReturn } from './useSteps.types'

const useSteps = ({ steps, initialStep }) => {
  const defaultStep = initialStep || 0;
  const [step, setStep] = useState(defaultStep);

  const next = useCallback(() => {
    setStep(_step => _step + 1);
  }, []);

  const prev = useCallback(() => {
    setStep(_step => _step - 1);
  }, []);

  const go = useCallback(newStep => {
    setStep(newStep);
  }, []);

  const getStep = useMemo(() => steps[step], [step]);

  const completed = useMemo(() => steps.length - 1 === step, []);

  return {
    step,
    go,
    next,
    prev,
    completed,
    getStep,
  };
};

export default useSteps;
