/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
// import Grid from '@mui/material/Grid';
// import Typography from '@mui/material/Typography';
// import TextField from '@mui/material/TextField';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
import Select from 'react-select';
// import FormGroup from '@mui/material/FormGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';

// import Radio from '@mui/material/Radio';
// import RadioGroup from '@mui/material/RadioGroup';
// import FormControl from '@mui/material/FormControl';

import {
  TextField,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
  styled,
  Grid,
} from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import styles from '../../../styles/Layout.module.css';

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark'
        ? 'rgba(57,75,89,.5)'
        : 'rgba(206,217,224,.5)',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#41b490',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#41b490',
  },
});
const useStyles = makeStyles(theme =>
  createStyles({
    myButton: {
      color: '#2a3176',
      backgroundColor: '#f4f4f4',
      fontWeight: 'bold',
    },
    myGrid: {
      minWidth: 260,
      backgroundColor: '#f4f4f4',
      margin: theme.spacing(1),
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      textAlign: 'center',
      cursor: 'pointer',
      border: '2px solid #c5c5c5',
      borderRadius: 10,
    },
    myGridSelected: {
      minWidth: 260,
      backgroundColor: '#41b490',
      margin: theme.spacing(1),
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      textAlign: 'center',
      cursor: 'pointer',
      border: '2px solid #41b490',
      borderRadius: 10,
    },
    myLabel: {
      padding: theme.spacing(6),
      color: '#2a3176',
      fontWeight: 'bold',
    },
    myLabelSelected: {
      padding: theme.spacing(6),
      color: '#fff',
      fontWeight: 'bold',
    },
  }),
);

// eslint-disable-next-line react/prop-types
export default function Balance({ setCobranca, cobranca }) {
  const [selected, setSelected] = useState();
  const classes = useStyles();
  useEffect(() => {
    if (cobranca) {
      setSelected(cobranca);
    }
  }, []);

  useEffect(() => {
    if (selected) {
      setCobranca(selected);
    }
  }, [selected]);
  return (
    <div className="box-new-card">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'start',
          alignItems: 'center',
        }}
      >
        <h6 className={styles.textBoxInform}>
          Essa solicitação tem custo. Devemos cobrá-lo:
        </h6>
      </div>
      <Grid
        container
        spacing={1}
        style={{
          justifyContent: 'center',
          padding: 40,
        }}
      >
        <Grid
          className={
            selected === 'card' ? classes.myGridSelected : classes.myGrid
          }
          item
          xs={12}
          sm={8}
          md={2}
          lg={2}
          xl={2}
          onClick={() => setSelected('card')}
        >
          <p
            className={
              selected === 'card' ? classes.myLabelSelected : classes.myLabel
            }
          >
            Saldo do cartão
          </p>
        </Grid>
        <Grid
          className={
            selected === 'company' ? classes.myGridSelected : classes.myGrid
          }
          item
          xs={12}
          sm={8}
          md={2}
          lg={2}
          xl={2}
          onClick={() => setSelected('company')}
        >
          <p
            className={
              selected === 'company' ? classes.myLabelSelected : classes.myLabel
            }
          >
            Da empresa
          </p>
        </Grid>
      </Grid>
      {/* <div style={{
        display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 40,
      }}
      >
        <button id="card" type="button"
         onClick={() => setSelected('card')} className={selected === 'card' ?
         'btn-category-selected' : 'btn-category'} style={{ width: 300, height: 150 }}>
          Cartão
          <br />
          Saque/Compra
        </button>
        <button id="transfer" type="button"
        onClick={() => setSelected('compra')} className={selected
           === 'compra' ? 'btn-category-selected' : 'btn-category'}
            style={{ width: 300, height: 150, marginLeft: 15 }}>
          Cartão
          <br />
          Compra
        </button>
        <button id="account" type="button" onClick={() => s
          etSelected('present')} className={selected === 'present'
           ? 'btn-category-selected' : 'btn-category'} s
           tyle={{ width: 300, height: 150, marginLeft: 15 }}>
          Cartão
          <br />
          Presente
        </button>
      </div> */}
    </div>
  );
}
