/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-undef */
/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, TextField } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import styles from '../../../styles/Layout.module.css';
import routing from '../../../service/routes/routing';
import MyButton from '../../../components/button/MyButton';

const useStyles = makeStyles(() =>
  createStyles({
    inputLabel: {
      backgroundColor: '#F2F1F1',
      // color: '#41b490',
      fontWeight: 'bold',
    },
    myTextField: {
      backgroundColor: '#none',
      boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
      border: 'none',
      // color: '#41b490',
      // fontWeight: 'bold',
      fontFamily: 'Open Sans',
    },
    text: {
      // backgroundColor: '#F2F1F1',
      fontFamily: 'Open Sans',
      // fontWeight: 'bold',
      // color: '#41b490',
    },
  }),
);

export default function BankAccount() {
  const history = useHistory();
  const classes = useStyles();
  const [account, setAccount] = useState([
    {
      name: '',
      doc: '',
      ag: '',
      account: '',
      op: '',
      bank: '',
    },
  ]);

  const newAccount = () => {
    setAccount([
      ...account,
      {
        name: '',
        doc: '',
        ag: '',
        account: '',
        op: '',
        bank: '',
      },
    ]);
  };

  const remove = (i) => {
    const orig = _.cloneDeep(account)
    orig.splice(i, 1);
    setAccount(orig)
  }

  const dataAccount = (i, d, v) =>{
    const orig = _.cloneDeep(account)
    orig[i][d] = v;
    setAccount(orig)
  }

  return (
    <div>
      <p
        className={styles.textMenu}
        onKeyDown={() => history.push(routing.salesDash)}
        onClick={() => history.push(routing.salesDash)}
      >
        Menu Inicial
      </p>
      <div
        style={{
          width: '100%',
          border: '1px solid black',
          borderRadius: '8px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <h5 className={styles.textTitle}>Dados bancarios</h5>
          <h5
            className={styles.textTitle}
            style={{ color: '#41b490', marginRight: '2rem', cursor: 'pointer' }}
            onClick={newAccount}
          >
            + Contas
          </h5>
        </div>
        {account.map((a, idx) => (
          <>
            <Grid
              container
              spacing={2}
              style={{
                marginTop: 15,
                paddingRight: '3rem',
                paddingLeft: '3rem',
                paddingBottom: '1rem',
                // paddingTop: i > 0 && '3rem',
              }}
            >
              <Grid item xs={9}>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <div style={{ width: '100%' }}>
                    <p>Nome do titular da conta</p>
                  </div>
                  <TextField
                    className={classes.text}
                    fullWidth
                    // style={{ maxWidth: '60%' }}
                    variant="outlined"
                    size="small"
                    InputProps={{
                      classes: {
                        notchedOutline: classes.myTextField,
                        input: classes.text,
                      },
                    }}
                    value={a.name}
                    onChange={e => dataAccount(idx, 'name',e.target.value)}
                  />
                </div>
              </Grid>
              <Grid item xs={3}>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <div style={{ width: '100%' }}>
                    <p>CNPJ/CPF</p>
                  </div>
                  <TextField
                    className={classes.text}
                    fullWidth
                    // style={{ maxWidth: '60%' }}
                    variant="outlined"
                    size="small"
                    InputProps={{
                      classes: {
                        notchedOutline: classes.myTextField,
                        input: classes.text,
                      },
                    }}
                    value={a.doc}
                    onChange={e => dataAccount(idx, 'doc',e.target.value)}
                    // value={dest.title}
                    // onChange={e => setDest(e.target.value)}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              style={{
                paddingRight: '3rem',
                paddingLeft: '3rem',
                paddingBottom: '3rem',
              }}
            >
              <Grid item xs={2}>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <div style={{ width: '100%' }}>
                    <p>Agência</p>
                  </div>
                  <TextField
                    className={classes.text}
                    fullWidth
                    // style={{ maxWidth: '60%' }}
                    variant="outlined"
                    size="small"
                    InputProps={{
                      classes: {
                        notchedOutline: classes.myTextField,
                        input: classes.text,
                      },
                    }}
                    value={a.ag}
                    onChange={e => dataAccount(idx, 'ag',e.target.value)}
                    // value={dest.title}
                    // onChange={e => setDest(e.target.value)}
                  />
                </div>
              </Grid>
              <Grid item xs={3}>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <div style={{ width: '100%' }}>
                    <p>Conta</p>
                  </div>
                  <TextField
                    className={classes.text}
                    fullWidth
                    // style={{ maxWidth: '60%' }}
                    variant="outlined"
                    size="small"
                    InputProps={{
                      classes: {
                        notchedOutline: classes.myTextField,
                        input: classes.text,
                      },
                    }}
                    value={a.account}
                    onChange={e => dataAccount(idx, 'account',e.target.value)}
                    // value={dest.title}
                    // onChange={e => setDest(e.target.value)}
                  />
                </div>
              </Grid>
              <Grid item xs={2}>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <div style={{ width: '100%' }}>
                    <p>Op</p>
                  </div>
                  <TextField
                    className={classes.text}
                    fullWidth
                    // style={{ maxWidth: '60%' }}
                    variant="outlined"
                    size="small"
                    InputProps={{
                      classes: {
                        notchedOutline: classes.myTextField,
                        input: classes.text,
                      },
                    }}
                    value={a.op}
                    onChange={e => dataAccount(idx, 'op',e.target.value)}
                  />
                </div>
              </Grid>
              <Grid item xs={3}>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <div style={{ width: '100%' }}>
                    <p>Número do banco</p>
                  </div>
                  <TextField
                    className={classes.text}
                    fullWidth
                    // style={{ maxWidth: '60%' }}
                    variant="outlined"
                    size="small"
                    InputProps={{
                      classes: {
                        notchedOutline: classes.myTextField,
                        input: classes.text,
                      },
                    }}
                    value={a.bank}
                    onChange={e => dataAccount(idx, 'bank',e.target.value)}
                    // value={dest.title}
                    // onChange={e => setDest(e.target.value)}
                  />
                </div>
              </Grid>
              <Grid item xs={2}>
              <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <div style={{ width: '100%' }}>
                    <p/>
                    {/* <p>Número do banco</p> */}
                  </div>
                {account.length > 1 && (
                  <MyButton
                  style={{
                    width: 120,
                    // height: 50,
                    borderRadius: 10,
                    backgroundColor: '#ba000d',
                  }}
                  onClick={() => remove(idx)}
                  label="Apagar"
                />
                )}
                </div>
              </Grid>
            </Grid>
          </>
        ))}
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '2rem',
        }}
      >
        <MyButton
          style={{
            width: 120,
            height: 50,
            borderRadius: 10,
            backgroundColor: '#e4e4e4',
            color: '#000',
          }}
          onClick={() => setAccount([1])}
          label="Limpar"
        />
        <MyButton
          style={{
            width: 120,
            height: 50,
            borderRadius: 10,
            // backgroundColor: '#657588',
          }}
          onClick={() => setOpenModal(true)}
          label="Cadastrar"
        />
      </div>
    </div>
  );
}
