/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState, useContext } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Box, Stepper, Step, StepLabel, Container } from '@material-ui/core';
import api from '../../../../../service/axios/api';
import Loading from '../../../../../components/loading/Loading';
// import CssBaseline from '@mui/material/CssBaseline';

// import Box from '@mui/material/Box';
// import Container from '@mui/material/Container';

// import Stepper from '@mui/material/Stepper';
// import Step from '@mui/material/Step';
// import StepLabel from '@mui/material/StepLabel';
import FlashContext from '../../../../../components/contexts/FlashContext';

import TypeTransfer from './TypeTransfer';
import TaxCompany from './TaxCompany';

import Review from './Review';
import Created from './Created';

import MyButton from '../../../../../components/button/MyButton';

import './checkout.css';

const steps = ['Shipping address', 'Payment details', 'Review your order'];

const useStyles = makeStyles(() =>
  createStyles({
    icon: {
      fill: '#41B490',
    },
    text: {
      fill: 'white',
    },
  }),
);

// eslint-disable-next-line react/prop-types
export default function Checkout({ setOpenModal }) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [productName, setProductName] = useState();
  const [loading, setLoading] = useState(false);
  const { notifyError } = useContext(FlashContext);

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <TypeTransfer
            setProductName={setProductName}
            productName={productName}
          />
        );
      case 1:
        return <TaxCompany />;
      case 2:
        return <Review productName={productName} />;
      default:
        throw new Error('Unknown step');
    }
  }

  const handleNext = () => {
    if (activeStep === 2) {
      if (productName) {
        setLoading(true);
        api.product
          .newCard(productName, 'Transfer')
          .then(() => {
            setActiveStep(activeStep + 1);
          })
          .catch(err => {
            console.error(err);
          })
          .finally(() => setLoading(false));
      } else {
        notifyError('Informe o nome do produto');
      }
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep === 0) {
      setOpenModal(false);
    } else {
      setActiveStep(activeStep - 1);
    }
  };

  return (
    <>
      <Loading open={loading} msg="Cadastrando novo produto..." />

      <Container component="main" width={1192} height={776}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {/* <p className="new-category">Nova categoria de produto</p> */}
          <Stepper activeStep={activeStep} style={{ width: 305 }}>
            {steps.map(
              label =>
                activeStep < 3 && (
                  <Step key={label} style={{ color: '#41B490' }}>
                    <StepLabel
                      StepIconProps={{
                        classes: {
                          active: classes.icon,
                          text: classes.text,
                          completed: classes.icon,
                        },
                      }}
                    />
                  </Step>
                ),
            )}
          </Stepper>
          <p className="x" onClick={() => setOpenModal(false)}>
            X
          </p>
        </div>
        {activeStep < 3 && (
          <p className="text-checkout-inform">Nova Transferência</p>
        )}
        <>
          {activeStep === steps.length ? (
            <>
              <Created setOpenModal={setOpenModal} />
            </>
          ) : (
            <>
              {getStepContent(activeStep)}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginTop: 50,
                  marginBottom: 50,
                }}
              >
                <h6 onClick={() => setOpenModal(false)}>Sair</h6>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {/* {activeStep !== 0 && ( */}
                  {/* <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                  Voltar
                </Button> */}
                  {/* // )} */}
                  <MyButton
                    style={{
                      width: 120,
                      height: 40,
                      borderRadius: 10,
                      backgroundColor: '#657588',
                    }}
                    onClick={handleBack}
                    label="Voltar"
                  />
                  <MyButton
                    style={{
                      width: 120,
                      height: 40,
                      borderRadius: 10,
                      marginLeft: 30,
                    }}
                    onClick={handleNext}
                    label="Continuar"
                  />
                </Box>
              </div>
            </>
          )}
        </>
        {/* </Paper> */}
      </Container>
    </>
  );
}
