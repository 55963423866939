/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { Button, Menu, MenuItem, Fade } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import moment from 'moment-timezone';
import styles from '../../../styles/Layout.module.css';
import TableNotification from '../../../components/tables/notifications/TableNotification';
import Loading from '../../../components/loading/Loading';
import routing from '../../../service/routes/routing';
import api from '../../../service/axios/api';

const useStyles = makeStyles(() =>
  createStyles({
    textNotification: {
      minWidth: 160,
      textAlign: 'center',
      fontFamily: 'Open Sans',
      fontWeight: 'bold',
      // lineHeight: 'auto',
      fontSize: 14,
      color: '#707070',
      '&:hover': {
        backgroundColor: '#41b490',
        color: '#fff',
      },
    },
    textNotificationHeader: {
      minWidth: 160,
      textAlign: 'center',
      fontFamily: 'Open Sans',
      fontWeight: 'bold',
      // lineHeight: 'auto',
      fontSize: 14,
      color: '#707070',
    },
    item: {
      minWidth: 160,
      '&:hover': {
        backgroundColor: '#41b490',
        color: '#41b490',
      },
    },
  }),
);

export default function NewNavigation() {
  const history = useHistory();
  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itens, setItens] = useState({ label: 25, value: 25 });
  const [notify, setNotify] = useState([]);
  const [order, setOrder] = useState({ label: 'status', value: '' });

  const search = useRef(null);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // useEffect(() => {
  //   setTotalPages(1);
  //   setNotify([]);
  // }, []);

  const [status, setStatus] = useState({ label: 'status', value: '' });

  const find = () => {
    setLoading(true);
    api.notification
      .getmy(status.value, order.value, search.current.value, page, itens.value)
      .then(res => {
        const list = [];
        res.data.docs.forEach((d, i) => {
          list.push({
            id: d.id,
            destination: d.receiver_group,
            subject: d.subject,
            data: d.created_at && moment.utc(d.created_at).format('DD/MM/yyyy'),
            created_at: d.send_user,
            canal: 'Email',
            attachment: '',
            status: d.status === '1' && 'Criado',
          });
        });
        setTotalPages(res.data.pages);
        setNotify(list);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    find();
  }, []);
  return (
    <>
      <Loading open={loading} msg="Buscando notificações..." />
      <div className="col-sm-12" style={{ marginTop: 50 }}>
        <div
          className="col-sm-12"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <h6
            className={styles.textMenu}
            onKeyDown={() => history.push(routing.dashboard)}
            onClick={() => history.push(routing.dashboard)}
          >
            Menu Inicial
          </h6>
          <h6
            // className="textMenu"
            onKeyDown={() => history.push(routing.newClientsGroup)}
            onClick={() => history.push(routing.newClientsGroup)}
            style={{ color: '#41b490', cursor: 'pointer' }}
          >
            Criar novo grupo de clientes
          </h6>
        </div>
        <div
          className="col-sm-12"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <p className={styles.textTitle}>Notificações | Histórico</p>
          <Button
            id="fade-button"
            // aria-controls={open ? 'fade-menu' : undefined}
            // aria-haspopup="true"
            // aria-expanded={open ? 'true' : undefined}
            className={classes.textNotificationHeader}
            onClick={handleClick}
          >
            Nova notificação
          </Button>
          <Menu
            id="fade-menu"
            MenuListProps={{
              'aria-labelledby': 'fade-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
            sx={{ minWidth: 600 }}
          >
            <MenuItem
              className={classes.textNotification}
              onClick={() => history.push(routing.newEmailNotifications)}
            >
              Email
            </MenuItem>
            <MenuItem
              className={classes.textNotification}
              onClick={handleClose}
            >
              WhatsApp
            </MenuItem>
            <MenuItem
              className={classes.textNotification}
              onClick={handleClose}
            >
              SMS
            </MenuItem>
          </Menu>
          {/* <MyButton style={{ marginRight: 50 }} onClick={() =>
          history.push(routing.newNotifications)} label="Nova Mensagem" /> */}
        </div>
        <div className="col-sm-12">
          <TableNotification
            itens={itens}
            setItens={setItens}
            notify={notify}
            setNotify={setNotify}
            totalPages={totalPages}
            setPage={setPage}
            setStatus={setStatus}
            search={search}
            find={find}
            setOrder={setOrder}
          />
        </div>
      </div>
    </>
  );
}
