import React, { useState } from 'react';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { FaSignature, FaEdit, FaDownload } from 'react-icons/fa';
import Link from '@material-ui/core/Link';
// import Error from 'next/error';
import { useHistory } from 'react-router-dom';
import useContextMenu from '../hooks/useContextMenu/useContextMenu';
import useBoolean from '../hooks/useBoolean/useBoolean';
import useClickPreventionOnDoubleClick from '../hooks/useClickPreventionOnDoubleClick/useClickPreventionOnDoubleClick';
import ContractsTable from '../components/tables/ContractsTable';
import ContractModal from '../components/modal/ContractModal';
// import Contract from '@/types/Contract';
import SpinnerPage from '../components/SpinnerPage';

function Contracts() {
  const router = useHistory();
  // const query = firestore.collection('contracts').orderBy('createdAt', 'desc');
  // const [contracts, loading, error] = useCollectionDataOnce(query);
  const contracts = [];
  const loading = false;

  const { state, onOpen, onClose } = useContextMenu();
  const [selected, setSelected] = useState();
  const {
    value: open,
    toFalse: closeModal,
    toTrue: openModal,
  } = useBoolean({
    initialValue: false,
  });

  const onClick = params => {
    setSelected(params.row);
    openModal();
  };

  const onDoubleClick = (params, e) => {
    setSelected(params.row);
    onOpen(e);
  };

  const [click, doubleClick] = useClickPreventionOnDoubleClick(
    onClick,
    onDoubleClick,
  );

  const handleClose = () => {
    setSelected(undefined);
  };

  const downloadFile = () => {
    handleClose();
  };

  const editContract = () => {
    handleClose();
    if (selected) router.push(`/dashboard/contracts/${selected.id}/edit`);
  };

  const requestSignatures = async () => {
    handleClose();
    if (selected)
      try {
        await axios.put(`/api/contracts/${selected.id}/sign`);
        router.reload();
      } catch (err) {
        const error = err;

        // eslint-disable-next-line no-console
        console.log(error.message);
      }
  };

  if (loading) return <SpinnerPage />;

  // if (error) return <Error statusCode={500} />;

  return (
    <>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Typography variant="h4" component="h2" gutterBottom>
          Contratos
        </Typography>
        {/* <Link href="/dashboard/contracts/create?option=client"> */}
        <Link href="/sales/contracts/create">
          <Button variant="text" color="primary" endIcon={<AddCircleIcon />}>
            Novo contrato
          </Button>
        </Link>
      </Grid>
      <ContractsTable
        onRowClick={click}
        onRowDoubleClick={doubleClick}
        contracts={contracts}
      />
      {selected && (
        <Menu
          keepMounted
          open={state.mouseY !== null}
          onClose={onClose}
          anchorReference="anchorPosition"
          anchorPosition={
            state.mouseY !== null && state.mouseX !== null
              ? { top: state.mouseY, left: state.mouseX }
              : undefined
          }
        >
          <MenuItem onClick={editContract}>
            <ListItemIcon>
              <FaEdit />
            </ListItemIcon>
            <Typography>Editar</Typography>
          </MenuItem>
          <MenuItem
            onClick={requestSignatures}
            disabled={selected.d4signStatus !== 'AwaitingUploadFile'}
          >
            <ListItemIcon>
              <FaSignature />
            </ListItemIcon>
            <Typography>Pedir assinatura</Typography>
          </MenuItem>

          <MenuItem
            onClick={downloadFile}
            component="a"
            download
            href={`/api/contracts/${selected.id}/download`}
          >
            <ListItemIcon>
              <FaDownload />
            </ListItemIcon>
            <Typography>Download</Typography>
          </MenuItem>
        </Menu>
      )}
      {open && selected && (
        <ContractModal
          open={open}
          onClose={closeModal}
          contractId={selected.id}
        />
      )}
    </>
  );
}

export default Contracts;
