/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { TextField, Grid } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import styles from '../../../styles/Layout.module.css';

const stateOptions = [
  {
    label: 'AC',
    value: 'AC',
  },
  {
    label: 'AP',
    value: 'AP',
  },
  {
    label: 'AM',
    value: 'AM',
  },
  {
    label: 'BA',
    value: 'BA',
  },
  {
    label: 'CE',
    value: 'CE',
  },
  {
    label: 'DF',
    value: 'DF',
  },
  {
    label: 'ES',
    value: 'ES',
  },
  {
    label: 'GO',
    value: 'GO',
  },
  {
    label: 'MA',
    value: 'MA',
  },
  {
    label: 'MT',
    value: 'MT',
  },
  {
    label: 'MS',
    value: 'MS',
  },
  {
    label: 'MG',
    value: 'MG',
  },
  {
    label: 'PA',
    value: 'PA',
  },
  {
    label: 'PB',
    value: 'PB',
  },
  {
    label: 'PR',
    value: 'PR',
  },
  {
    label: 'PE',
    value: 'PE',
  },
  {
    label: 'PI',
    value: 'PI',
  },
  {
    label: 'RJ',
    value: 'RJ',
  },
  {
    label: 'RN',
    value: 'RN',
  },
  {
    label: 'RS',
    value: 'RS',
  },
  {
    label: 'RO',
    value: 'RO',
  },
  {
    label: 'RR',
    value: 'RR',
  },
  {
    label: 'SC',
    value: 'SC',
  },
  {
    label: 'SP',
    value: 'SP',
  },
  {
    label: 'SE',
    value: 'SE',
  },
  {
    label: 'TO',
    value: 'TP',
  },
];

const customStyles = {
  control: provided => ({
    ...provided,
    minWidth: 225,
    // width: 200,
    backgroundColor: 'none',
    // boxShadow: 'none',
    boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
    border: 'none',
    color: '#717171',
    fontWeight: 'bold',
    fontFamily: 'Open Sans',
    // fontSize: 24,
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  option: provided => ({
    ...provided,
    fontWeight: 'bold',
    fontFamily: 'Open Sans',
    color: '#717171',
    // color: state.data.color,
    // backgroundColor: state.data.bgcolor,
    // fontSize: 24,
  }),
  placeholder: () => ({
    fontWeight: 'bold',
    fontFamily: 'Open Sans',
    marginTop: -20,
    color: '#717171',
    // paddingBottom: 20,
    // fontSize: 24,
    // marginBottom: 30,
  }),
};

const useStyles = makeStyles(theme =>
  createStyles({
    myButton: {
      color: '#2a3176',
      backgroundColor: '#f4f4f4',
      fontWeight: 'bold',
    },
    myGrid: {
      minWidth: 260,
      backgroundColor: '#f4f4f4',
      margin: theme.spacing(1),
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      textAlign: 'center',
      cursor: 'pointer',
      border: '2px solid #c5c5c5',
      borderRadius: 10,
    },
    myGridSelected: {
      minWidth: 260,
      backgroundColor: '#41b490',
      margin: theme.spacing(1),
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      textAlign: 'center',
      cursor: 'pointer',
      border: '2px solid #41b490',
      borderRadius: 10,
    },
    myLabel: {
      padding: theme.spacing(6),
      color: '#2a3176',
      fontWeight: 'bold',
    },
    myLabelSelected: {
      padding: theme.spacing(6),
      color: '#fff',
      fontWeight: 'bold',
    },
    myTextField: {
      backgroundColor: 'none',
      boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
      border: 'none',
      color: '#717171',
      fontWeight: 'bold',
      fontFamily: 'Open Sans',
    },
    input: {
      backgroundColor: '#fff',
      marginTop: 0,
      // color: 'white',
    },
    text: {
      fontFamily: 'Open Sans',
      fontWeight: 'bold',
      color: '#717171',
    },
  }),
);

// eslint-disable-next-line react/prop-types
export default function SendCard({
  typeAddress,
  setTypeAddress,
  address,
  setAddress,
  numberAddress,
  setNumberAddress,
  complement,
  setComplement,
  district,
  setDistrict,
  city,
  setCity,
  ac,
  setAc,
  cep,
  setCep,
  uf,
  setUf,
}) {
  const [selected, setSelected] = useState();
  const classes = useStyles();
  useEffect(() => {
    if (typeAddress) {
      setSelected(typeAddress);
    }
  }, []);

  useEffect(() => {
    if (selected) {
      setTypeAddress(selected);
    }
  }, [selected]);
  return (
    <div className="box-new-card">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'start',
          alignItems: 'center',
        }}
      >
        <h6 className={styles.textBoxInform}>
          A 2ª via deve ser enviada para:
        </h6>
      </div>
      <Grid
        container
        spacing={1}
        style={{
          justifyContent: 'center',
          padding: 40,
        }}
      >
        <Grid
          className={
            selected === 'company' ? classes.myGridSelected : classes.myGrid
          }
          item
          xs={12}
          sm={8}
          md={2}
          lg={2}
          xl={2}
          onClick={() => setSelected('company')}
        >
          <p
            className={
              selected === 'company' ? classes.myLabelSelected : classes.myLabel
            }
          >
            Empresa
          </p>
        </Grid>
        <Grid
          className={
            selected === 'other' ? classes.myGridSelected : classes.myGrid
          }
          item
          xs={12}
          sm={8}
          md={2}
          lg={2}
          xl={2}
          onClick={() => setSelected('other')}
        >
          <p
            className={
              selected === 'other' ? classes.myLabelSelected : classes.myLabel
            }
          >
            Outro endereço
          </p>
        </Grid>
      </Grid>
      {selected === 'other' && (
        <>
          <h6 className={styles.textBoxInform}>Informe o endereço:</h6>
          <Grid
            container
            spacing={1}
            direction="column"
            style={{
              paddingRight: 40,
              paddingLeft: 40,
              // paddingBottom: 40,
              marginBottom: 15,
              border: '1px solid #c5c5c5',
              borderRadius: 10,
            }}
          >
            <Grid item>
              <p className={styles.labelTitleResum} style={{ marginBottom: 8 }}>
                Endereço
              </p>
              <TextField
                // inputRef={nameVinc}
                className={classes.text}
                variant="outlined"
                size="small"
                fullWidth
                value={address}
                onChange={v => setAddress(v.target.value)}
                // value={refQtdCards}
                // onChange={e => setRefQtdCards(e.target.value)}
                // inputRef={refQtdCards}
                InputProps={{
                  classes: {
                    notchedOutline: classes.myTextField,
                    input: classes.text,
                  },
                }}
              />
            </Grid>
            <Grid
              container
              spacing={1}
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <Grid item style={{ marginBottom: 10 }}>
                <p
                  className={styles.labelTitleResum}
                  style={{ marginBottom: 8 }}
                >
                  Número
                </p>
                <TextField
                  type="number"
                  value={numberAddress}
                  onChange={v => setNumberAddress(v.target.value)}
                  className={classes.text}
                  variant="outlined"
                  size="small"
                  fullWidth
                  InputProps={{
                    classes: {
                      notchedOutline: classes.myTextField,
                      input: classes.text,
                    },
                  }}
                />
              </Grid>
              <Grid item style={{ marginBottom: 10 }}>
                <p
                  className={styles.labelTitleResum}
                  style={{ marginBottom: 8 }}
                >
                  Complemento
                </p>
                <TextField
                  value={complement}
                  onChange={v => setComplement(v.target.value)}
                  className={classes.text}
                  variant="outlined"
                  size="small"
                  fullWidth
                  // value={refQtdCards}
                  // onChange={e => setRefQtdCards(e.target.value)}
                  // inputRef={refQtdCards}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.myTextField,
                      input: classes.text,
                    },
                  }}
                />
              </Grid>
              <Grid item style={{ marginBottom: 10 }}>
                <p
                  className={styles.labelTitleResum}
                  style={{ marginBottom: 8 }}
                >
                  Bairro
                </p>
                <TextField
                  value={district}
                  onChange={v => setDistrict(v.target.value)}
                  className={classes.text}
                  variant="outlined"
                  size="small"
                  fullWidth
                  // value={refQtdCards}
                  // onChange={e => setRefQtdCards(e.target.value)}
                  // inputRef={refQtdCards}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.myTextField,
                      input: classes.text,
                    },
                  }}
                />
              </Grid>
              <Grid item style={{ marginBottom: 10 }}>
                <p
                  className={styles.labelTitleResum}
                  style={{ marginBottom: 8 }}
                >
                  Cidade
                </p>
                <TextField
                  value={city}
                  onChange={v => setCity(v.target.value)}
                  className={classes.text}
                  variant="outlined"
                  size="small"
                  fullWidth
                  // value={refQtdCards}
                  // onChange={e => setRefQtdCards(e.target.value)}
                  // inputRef={refQtdCards}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.myTextField,
                      input: classes.text,
                    },
                  }}
                />
              </Grid>
              <Grid item style={{ marginBottom: 10 }}>
                <p
                  className={styles.labelTitleResum}
                  style={{ marginBottom: 8 }}
                >
                  CEP
                </p>
                <TextField
                  value={cep}
                  onChange={v => setCep(v.target.value)}
                  className={classes.text}
                  variant="outlined"
                  size="small"
                  fullWidth
                  // value={refQtdCards}
                  // onChange={e => setRefQtdCards(e.target.value)}
                  // inputRef={refQtdCards}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.myTextField,
                      input: classes.text,
                    },
                  }}
                />
              </Grid>
              <Grid item style={{ marginBottom: 10 }}>
                <p
                  className={styles.labelTitleResum}
                  style={{ marginBottom: 8 }}
                >
                  Estado:
                </p>
                <Select
                  // width="300"
                  styles={customStyles}
                  // value={fixedValue}
                  classNamePrefix="select"
                  placeholder=""
                  value={uf}
                  onChange={v => setUf(v)}
                  // onChange={(e, i) => mailSelected(e, i)}
                  // isSearchable={false}
                  options={stateOptions}
                />
              </Grid>
            </Grid>
            <Grid item style={{ marginBottom: 10 }}>
              <p className={styles.labelTitleResum} style={{ marginBottom: 8 }}>
                Aos cuidados de:
              </p>
              <TextField
                value={ac}
                onChange={v => setAc(v.target.value)}
                className={classes.text}
                variant="outlined"
                size="small"
                fullWidth
                // value={refQtdCards}
                // onChange={e => setRefQtdCards(e.target.value)}
                // inputRef={refQtdCards}
                InputProps={{
                  classes: {
                    notchedOutline: classes.myTextField,
                    input: classes.text,
                  },
                }}
              />
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
}
