/* eslint-disable no-promise-executor-return */
const cancellablePromise = promise => {
  let isCanceled = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      value =>
        // eslint-disable-next-line prefer-promise-reject-errors
        isCanceled ? reject({ isCanceled, value }) : resolve(value),
      // eslint-disable-next-line prefer-promise-reject-errors
      error => reject({ isCanceled, error }),
    );
  });

  return {
    promise: wrappedPromise,
    cancel: () => {
      isCanceled = true;
    },
  };
};

export const delay = n => new Promise(resolve => setTimeout(resolve, n));

export default cancellablePromise;
