/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
import * as React from 'react';

import { Grid, TextField, Box } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import Select from 'react-select';

const useStyles = makeStyles(() =>
  createStyles({
    myTextField: {
      backgroundColor: 'none',
      boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
      border: 'none',
      color: '#717171',
      fontWeight: 'bold',
      fontFamily: 'Open Sans',
    },
    text: {
      fontFamily: 'Open Sans',
      fontWeight: 'bold',
      color: '#717171',
    },
    myLabel: {
      marginRight: 10,
      fontFamily: 'Open Sans',
      fontWeight: 'bold',
      color: '#717171',
    },
  }),
);

const options = [
  {
    label: 'MasterCard',
    value: 1,
  },
  {
    label: 'Visa',
    value: 2,
  },
  {
    label: 'elo',
    value: 3,
  },
];

const optionsE = [
  {
    label: 'Acesso',
    value: 1,
  },
];

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    width: 220,
    height: 50,
    borderRadius: 10,
    // backgroundColor: 'none',
    // boxShadow: 'none',
    // border: 'none',
    fontWeight: 'bold',
    fontFamily: 'Open Sans',
    fontSize: 14,
  }),
  indicatorSeparator: styles => ({ display: 'none' }),
  option: (provided, state) => ({
    ...provided,
    fontFamily: 'Open Sans',
    fontSize: 14,
  }),
  placeholder: (provided, state) => ({
    // fontWeight: 'bold',
    fontFamily: 'Open Sans',
    fontSize: 14,
    color: '#9C9C9C',
    // marginBottom: 30,
  }),
};

export default function TaxCompany() {
  const classes = useStyles();
  return (
    // <div className="box-new-card">
    <Grid
      container
      spacing={2}
      style={{
        border: '2px solid #c5c5c5',
        borderRadius: 10,
        justifyContent: 'center',
        margin: 15,
        padding: 30,
      }}
    >
      {/* <div style={{
        display: 'flex', flexDirection: 'row',
        justifyContent: 'space-between', alignItems: 'center',
      }}
      > */}
      <Grid
        container
        spacing={2}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: 10,
        }}
      >
        <h5 className="text-box-inform">Características</h5>

        <h5 className="text-box-inform">
          <img
            id="refresh"
            src="./img/upload.svg"
            alt="logo"
            style={{ height: 20, width: 20, marginRight: 15 }}
          />
          Upload de Brochura
        </h5>
      </Grid>
      <Grid item xs={12} sm={8} md={5} lg={5} xl={5}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <label className={classes.myLabel}>Custo unitário:</label>
          <TextField
            classes={{ notchedOutline: classes.input }}
            className={classes.text}
            id="outlined-basic"
            label="R$00.00"
            // label={t('Product.creditCard.table.search')}
            variant="outlined"
            size="small"
            // inputRef={search}
            InputProps={{
              classes: {
                notchedOutline: classes.myTextField,
                input: classes.text,
              },
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={8} md={5} lg={5} xl={5}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <label className={classes.myLabel}>Faturado:</label>
          <TextField
            style={{ minWidth: 250 }}
            classes={{ notchedOutline: classes.input }}
            className={classes.text}
            id="outlined-basic"
            label="Chave empresa ou beneficiário"
            // label={t('Product.creditCard.table.search')}
            variant="outlined"
            size="small"
            // inputRef={search}
            InputProps={{
              classes: {
                notchedOutline: classes.myTextField,
                input: classes.text,
              },
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={8} md={5} lg={5} xl={5}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <label className={classes.myLabel}>Preço sugerido:</label>
          <TextField
            classes={{ notchedOutline: classes.input }}
            className={classes.text}
            id="outlined-basic"
            label="R$00.00"
            // label={t('Product.creditCard.table.search')}
            variant="outlined"
            size="small"
            // inputRef={search}
            InputProps={{
              classes: {
                notchedOutline: classes.myTextField,
                input: classes.text,
              },
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={8} md={5} lg={5} xl={5}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <label className={classes.myLabel}>Cobrança:</label>
          <TextField
            classes={{ notchedOutline: classes.input }}
            className={classes.text}
            id="outlined-basic"
            label="Recorrente ou única"
            // label={t('Product.creditCard.table.search')}
            variant="outlined"
            size="small"
            // inputRef={search}
            InputProps={{
              classes: {
                notchedOutline: classes.myTextField,
                input: classes.text,
              },
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={8} md={5} lg={5} xl={5}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <label className={classes.myLabel}>Taxa Adm:</label>
          <TextField
            classes={{ notchedOutline: classes.input }}
            className={classes.text}
            id="outlined-basic"
            label="XX% ou XX BRL/unid"
            // label={t('Product.creditCard.table.search')}
            variant="outlined"
            size="small"
            // inputRef={search}
            InputProps={{
              classes: {
                notchedOutline: classes.myTextField,
                input: classes.text,
              },
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={8} md={5} lg={5} xl={5} />
      {/* </div> */}
      {/* <div className="my-row-align">
        <div className="my-colunm-align" style={{ marginTop: -25 }}>
          <div className="my-row-align">
            <div>
              <p className="text-select-label ">Cartão</p>
              <FormControl component="fieldset">
                <RadioGroup row aria-label="gender" name="row-radio-buttons-group">
                  <FormControlLabel
                    className="myLabel"
                    value="chip"
                    control={(
                      <Radio sx={{
                        color: '#9c9c9c',
                        '&.Mui-checked': {
                          color: '#41b490',
                        },
                      }}
                      />
)}
                    label="Virtual"
                  />
                  <FormControlLabel
                    className="myLabel"
                    value="nfc"
                    control={(
                      <Radio sx={{
                        color: '#9c9c9c',
                        '&.Mui-checked': {
                          color: '#41b490',
                        },
                      }}
                      />
)}
                    label="Físico"
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
          <div style={{ marginTop: -15 }}>
            <p className="text-select-label ">Bandeira</p>
            <Select
              styles={customStyles}
              className="basic-single"
              classNamePrefix="select"
              placeholder="Selecione a bandeira"
              isSearchable={false}
              options={options}
            />
          </div>
          <div style={{ marginBottom: 20 }}>
            <p className="text-select-label ">Emissor</p>
            <Select
              styles={customStyles}
              className="basic-single"
              classNamePrefix="select"
              placeholder="Selecione o emissor"
              isSearchable={false}
              options={optionsE}
            />
          </div>
        </div>
        <div className="my-colunm-align">
          <div className="my-row-align">
            <div>
              <p className="text-select-label ">Cobertura</p>
              <FormControl component="fieldset">
                <RadioGroup row aria-label="gender" name="row-radio-buttons-group">
                  <FormControlLabel
                    className="myLabel"
                    value="nacional"
                    control={(
                      <Radio sx={{
                        color: '#9c9c9c',
                        '&.Mui-checked': {
                          color: '#41b490',
                        },
                      }}
                      />
)}
                    label="Nacional"
                  />
                  <FormControlLabel
                    className="myLabel"
                    value="internacional"
                    control={(
                      <Radio sx={{
                        color: '#9c9c9c',
                        '&.Mui-checked': {
                          color: '#41b490',
                        },
                      }}
                      />
)}
                    label="Internacional"
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
          <div className="my-row-align" style={{ marginTop: -1 }}>
            <div>
              <p className="text-select-label ">Tecnologia</p>
              <FormControl component="fieldset">
                <RadioGroup row aria-label="gender" name="row-radio-buttons-group">
                  <FormControlLabel
                    className="myLabel"
                    value="chip"
                    control={(
                      <Radio sx={{
                        color: '#9c9c9c',
                        '&.Mui-checked': {
                          color: '#41b490',
                        },
                      }}
                      />
)}
                    label="Chip"
                  />
                  <FormControlLabel
                    className="myLabel"
                    value="nfc"
                    control={(
                      <Radio sx={{
                        color: '#9c9c9c',
                        '&.Mui-checked': {
                          color: '#41b490',
                        },
                      }}
                      />
)}
                    label="NFC"
                  />
                  <FormControlLabel
                    className="myLabel"
                    value="tarja"
                    control={(
                      <Radio sx={{
                        color: '#9c9c9c',
                        '&.Mui-checked': {
                          color: '#41b490',
                        },
                      }}
                      />
)}
                    label="Tarja"
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
        </div>
        <div className="my-colunm-align">
          <p className="text-select-label ">Personalização do cartão</p>
          <div className="my-row-align">
            <div>
              <Switch color="success" defaultChecked />
            </div>
            <div>
              <input className="my-input-account" placeholder="R$00,00" />
            </div>
          </div>
          <p className="text-select-label ">Inatividade</p>
          <div className="my-row-align">
            <div>
              <Switch color="success" />
            </div>
            <div>
              <input className="my-input-account" placeholder="R$00,00" />
            </div>

          </div>
        </div>
      </div> */}
    </Grid>
    // {/* </div> */}
  );
}
