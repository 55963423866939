/* eslint-disable react/function-component-definition */
/* eslint-disable import/prefer-default-export */
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import Grid from '@material-ui/core/Grid';
import moment from 'moment-timezone';

import {
  // TimePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  // DateTimePicker,
  // KeyboardTimePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import locale from 'date-fns/locale/pt-BR';
import Button from '@material-ui/core/Button';
import api from '../../service/axios/api';
import Loading from '../../components/loading/Loading';
import { BanklyTable } from './BanklyTable';

const customStyles = {
  control: provided => ({
    ...provided,
    // width: 200,
    backgroundColor: 'none',
    boxShadow: 'none',
    border: 'none',
    // fontWeight: 'bold',
    fontFamily: 'Open Sans',
    // fontSize: 24,
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  option: provided => ({
    ...provided,
    fontWeight: 'bold',
    fontFamily: 'Open Sans',
    // color: state.data.color,
    // backgroundColor: state.data.bgcolor,
    // fontSize: 24,
  }),
  placeholder: () => ({
    fontWeight: 'bold',
    fontFamily: 'Open Sans',
    marginTop: -20,
    // paddingBottom: 20,
    // fontSize: 24,
    // marginBottom: 30,
  }),
};
export const Bankly = () => {
  const baseDate = new Date();
  const [docs, setDocs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dateIni, setDateIni] = useState(
    baseDate.setDate(baseDate.getDate() - 30),
  );
  const [dateFim, setDateFim] = useState(new Date());
  const [page, setPage] = useState(0);
  const [itens, setItens] = useState(25);
  const [total, setTotal] = useState(0);
  const [status, setStatus] = useState({ label: 'Todos', value: '' });

  const searchRps = () => {
    setLoading(true);
    const cancel = new AbortController();
    api.bankly
      .getAll(
        itens,
        page + 1,
        `${moment(dateIni).format('YYYY-MM-DD')} 00:00:01`,
        `${moment(dateFim).format('YYYY-MM-DD')} 23:59:59`,
        status?.value,
        cancel,
      )
      .then(res => {
        setTotal(res.total);
        setDocs(res.docs);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    searchRps();
  }, [page, itens, status]);

  // const ini = `${moment(new Date()).format('YYYY-MM-DD')} 00:00:01`;
  // const fim = `${moment(new Date()).format('YYYY-MM-DD')} 23:59:59`;
  return (
    <>
      <Loading open={loading} msg="Buscando rpss..." />

      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <h4>Bankly</h4>
        </Grid>
        <Grid container spacing={1} alignItems="center" justify="center">
          <Grid item>
            <Select
              width="300"
              styles={customStyles}
              className="basic-single"
              classNamePrefix="select"
              placeholder="Status"
              value={status}
              onChange={setStatus}
              isSearchable={false}
              options={[
                { label: 'Criado', value: 'Created' },
                { label: 'Processado', value: 'Processed' },
                { label: 'Todos', value: '' },
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            {/* <h6>Data Inicial</h6> */}
            <MuiPickersUtilsProvider
              utils={DateFnsUtils}
              locale={locale}
              // className={classes.margin}
            >
              <KeyboardDatePicker
                fullWidth
                size="small"
                value={dateIni}
                inputVariant="outlined"
                placeholder="Data inicial"
                onChange={dat => setDateIni(dat)}
                autoOk
                format="dd/MM/yyyy"
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm={4}>
            {/* <h6>Data Final</h6> */}
            <MuiPickersUtilsProvider
              utils={DateFnsUtils}
              locale={locale}
              // className={classes.margin}
            >
              <KeyboardDatePicker
                fullWidth
                size="small"
                value={dateFim}
                inputVariant="outlined"
                placeholder="Data final"
                onChange={dat => setDateFim(dat)}
                autoOk
                format="dd/MM/yyyy"
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              autoFocus
              onClick={searchRps}
            >
              Buscar
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} style={{ marginTop: 15 }}>
          <BanklyTable
            docs={docs}
            // setDocs={setDocs}
            page={page}
            setPage={setPage}
            itens={itens}
            setItens={setItens}
            total={total}
          />
        </Grid>
      </Grid>
    </>
  );
};
