/* eslint-disable import/prefer-default-export */
const permissions = [
  {
    name: 'Dashboard',
    itens: [
      { name: 'Pedidos', status: false },
      { name: 'Base de Cartões', status: false },
      { name: 'Contratos e taxas', status: false },
      { name: 'Liberação de pagamentos', status: false },
      { name: 'Movimentação', status: false },
      { name: 'Painel', status: false },
      { name: 'Acessos GM', status: false },
      { name: 'Área administrativa', status: false },
    ],
  },
  {
    name: 'Pedidos',
    itens: [
      { name: 'Novo Pedido', status: false },
      { name: 'Duplicar Pedido', status: false },
      { name: 'Cancelar Pedido', status: false },
      { name: 'Pagar Pedido', status: false },
    ],
  },
  {
    name: 'Detalhes do pedido',
    itens: [
      { name: 'Planilha premiados', status: false },
      { name: 'Adicionar itens', status: false },
      { name: 'Descartar itens', status: false },
      { name: 'Obter boleto', status: false },
      { name: 'Pagar', status: false },
    ],
  },
  {
    name: 'Contratos e taxas',
    itens: [
      { name: 'Novo contrato', status: false },
      { name: 'Editar contrato', status: false },
      { name: 'Desativar contrato', status: false },
      { name: 'Download', status: false },
    ],
  },
  {
    name: 'Liberação de cargas',
    itens: [
      { name: 'Upload retorno', status: false },
      { name: 'Liberar itens', status: false },
      { name: 'Cancelar itens', status: false },
    ],
  },
  {
    name: 'Movimentação',
    itens: [{ name: 'Download Movimentação', status: false }],
  },
  {
    name: 'Administração',
    itens: [
      { name: 'Financeiro', status: false },
      { name: 'Grupo de permissões', status: false },
      { name: 'Gerenciamento de usuários', status: false },
    ],
  },
];

export default permissions;
