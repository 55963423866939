import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import Table from '../../Table';

import { useStyles } from './MyContract.styles';

const columns = [
  {
    field: 'tax_adm',
    headerName: 'Taxa ADM',
    flex: 1,
  },
  {
    field: 'sell_type',
    headerName: 'Tipo de Venda',
    flex: 1,
  },
  {
    field: 'commission',
    headerName: 'Comissão s/ Cargas',
    flex: 1,
  },
];

const fixedRows = [
  {
    id: 1,
    tax_adm: 'R$10,00 ou mais',
    sell_type: 'Direta',
    commission: '0%',
  },
  {
    id: 2,
    tax_adm: 'R$6,00 ou R$9,99',
    sell_type: 'Direta',
    commission: '0%',
  },
  {
    id: 3,
    tax_adm: 'R$3,00 ou R$5,99',
    sell_type: 'Direta',
    commission: '0%',
  },
  {
    id: 4,
    tax_adm: 'R$2,00 ou R$2,99',
    sell_type: 'Direta',
    commission: '0%',
  },
];

const variableRows = [
  {
    id: 1,
    tax_adm: '>=5,00%',
    sell_type: 'Direta',
    commission: '0%',
  },
  {
    id: 2,
    tax_adm: '>=2.50% e <=4,99%',
    sell_type: 'Direta',
    commission: '0%',
  },
  {
    id: 3,
    tax_adm: '>=1.50% e <=2,49%',
    sell_type: 'Direta',
    commission: '0%',
  },
  {
    id: 4,
    tax_adm: '>=0.50% e <=1,49%',
    sell_type: 'Direta',
    commission: '0%',
  },
];

function MyContractTableGrid() {
  const classes = useStyles();

  return (
    <Grid container justify="space-evenly" spacing={3}>
      <Grid item xs={12} md={6} lg={5}>
        <Typography className={classes.subtitle} color="initial">
          Taxa Variavel:
        </Typography>
        <Table columns={columns} rows={variableRows} autoHeight />
      </Grid>
      <Grid item xs={12} md={6} lg={5}>
        <Typography className={classes.subtitle} color="initial">
          Taxa Fixa:
        </Typography>
        <Table columns={columns} rows={fixedRows} autoHeight />
      </Grid>
    </Grid>
  );
}

export default MyContractTableGrid;
