/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import InputLabel from '@material-ui/core/InputLabel';
import { Grid, Modal } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import routing from '../../service/routes/routing';
import styles from '../../styles/Layout.module.css';
import api from '../../service/axios/api';
// import { AnalyticsAndOperator } from '@aws-sdk/client-s3';
// import Tutorial from '@/types/Tutorial';
// import TutorialCategory from '@/types/TutorialCategory';
import PermissionComponent from '../../components/permissionComponent/PermissionComponent';
import TutorialGrid from './components/TutorialGrid';
import { TutorialModal } from './components/TutorialModal';
import UserRoles from '../../service/acl/UserRoles';

function Tutorials() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [categoryFilter, setCategoryFilter] = useState();
  const history = useHistory();

  const [tutorial, setTutorial] = useState([]);
  const loading = false;
  const loading2 = false;
  const tutorialCategories = []; //

  const searchAll = () => {
    const cancel = new AbortController();
    api.tutorials
      .getActives(cancel)
      .then(res => {
        const list = [];

        res.retorno.sort(function (a, b) {
          const titleA = a.title.substring(0, a.title.indexOf(')'));
          const titleB = b.title.substring(0, b.title.indexOf(')'));
          if (Number(titleA) > Number(titleB)) {
            return 1;
          }
          if (Number(titleA) < Number(titleB)) {
            return -1;
          }
          return 0;
        });
        res.retorno.forEach(r => {
          list.push({
            media: r.video_url,
            title: r.title,
            status: r.status === '1' ? 'Publicado' : 'Oculto',
            subject: r.description,
            id: r.id,
          });
        });
        setTutorial(list);
      })
      .catch(err => {
        console.log(err);
      });
  };

  useEffect(() => {
    searchAll();
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const selectCategory = e => {
    setCategoryFilter(e.target.value);
  };

  return (
    <>
      <Grid container spacing={3} style={{ marginTop: '50px' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignContent: 'space-between',
          }}
        >
          <p
            className={styles.textMenu}
            onKeyDown={() => history.push(routing.dashboard)}
            onClick={() => history.push(routing.dashboard)}
          >
            {t('initialMenu')}
          </p>
        </div>
        <div
          className="col-sm-12"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <h4 className={styles.textTitle}>{t('tutorials.label')}</h4>
          {/* <PermissionComponent role={UserRoles.DASHBOARD_ADMINISTRATION}> */}
          {api.currentUser?.user?.role?.id === '1' && (
            <Button
              variant="text"
              color="primary"
              endIcon={<AddCircleIcon />}
              onClick={handleOpen}
              style={{ float: 'right' }}
            >
              {t('tutorials.btnNew')}
            </Button>
          )}
          {/* </PermissionComponent> */}
        </div>
      </Grid>

      {tutorial.length > 0 && (
        <>
          <Divider
            variant="middle"
            style={{ backgroundColor: '#71717130', margin: '10px' }}
          />
          <TutorialGrid
            tutorials={tutorial.filter(tuto => {
              return tuto;
            })}
          />
        </>
      )}
      <Modal open={open} onClose={handleClose}>
        <TutorialModal update={searchAll} />
      </Modal>
    </>
  );
}

export default Tutorials;
