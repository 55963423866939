import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import BusinessTable from '../components/tables/Business/BusinessTable';

import BusinessModal from '../components/modal/BusinessModal';

const business = [
  {
    id: 1,
    invoice_owner_name: 'AURORA E FÁTIMA ESPORTES LTDA',
    invoice_number: 'NFB002398',
    invoice_issue_date: new Date(),
    invoice_payment_date: new Date(),
    invoice_price: 400.0,
    status: 'Pago',
  },
  {
    id: 2,
    invoice_owner_name: 'Y E SARA FILMAGENS LTDA',
    invoice_number: 'NFB002397',
    invoice_issue_date: new Date(),
    invoice_payment_date: new Date(),
    invoice_price: 500.0,
    status: 'Pago',
  },
  {
    id: 3,
    invoice_owner_name: 'ANDERSON E THEO DOCES & SALGADOS ME',
    invoice_number: 'NFB002396',
    invoice_issue_date: new Date(),
    invoice_payment_date: null,
    invoice_price: 83000.2,
    status: 'Aguardando Pagamento',
  },
  {
    id: 4,
    invoice_owner_name: 'MARTIN E ALEXANDRE COMERCIO DE BEBIDAS ME',
    invoice_number: 'NFB002395',
    invoice_issue_date: new Date(),
    invoice_payment_date: new Date(),
    invoice_price: 150,
    status: 'Pago',
  },
  {
    id: 5,
    invoice_owner_name: 'CAUÃ E RAFAEL CONSULTORIA FINANCEIRA ME',
    invoice_number: 'NFB002394',
    invoice_issue_date: new Date(),
    invoice_payment_date: null,
    invoice_price: 2300,
    status: 'Cancelado',
  },
];

function Business() {
  const [openDetails, setOpenDetails] = useState(false);

  const [selected, setSelected] = useState();

  const onClick = params => {
    console.log('click');
    setSelected(params.rowIndex);
    setOpenDetails(true);
  };

  return (
    <>
      <Typography variant="h4" component="h2" gutterBottom>
        Meus Negócios
      </Typography>
      <BusinessTable onRowClick={onClick} business={business} />
      <BusinessModal
        open={openDetails}
        onClose={() => setOpenDetails(false)}
        business={typeof selected === 'number' ? business[selected] : undefined}
      />
    </>
  );
}

export default Business;
