/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useContext } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import DescriptionIcon from '@material-ui/icons/Description';
import moment from 'moment-timezone';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';

// import  from '@material-ui/core/CheckBox';
import { styled, Checkbox } from '@material-ui/core';

import Button from '@material-ui/core/Button';

import Select from 'react-select';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import _ from 'lodash';

import {
  // TimePicker,
  // KeyboardDatePicker,
  MuiPickersUtilsProvider,
  DateTimePicker,
  // KeyboardTimePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import locale from 'date-fns/locale/pt-BR';
import FlashContext from '../../components/contexts/FlashContext';
import Loading from '../../components/loading/Loading';
import api from '../../service/axios/api';

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark'
        ? 'rgba(57,75,89,.5)'
        : 'rgba(206,217,224,.5)',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#137cbd',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#106ba3',
  },
});

const optionsStatus = [
  {
    label: 'Aguardando cartão',
    value: 'AwaitingCard',
  },
  {
    label: 'Processando',
    value: 'Processing',
  },
  {
    label: 'Aguardando pagamento',
    value: 'AwaitingPayment',
  },
  {
    label: 'Processado',
    value: 'Processed',
  },
  {
    label: 'Aguardando cartão finalizar',
    value: 'AwaitingCardRelease',
  },
  {
    label: 'Cancelado',
    value: 'Canceled',
  },
  {
    label: 'Recebido',
    value: 'Received',
  },
  {
    label: 'Rejeitado',
    value: 'Rejected',
  },
  {
    label: 'Processamento automático',
    value: 'AutoProcessing',
  },
  {
    label: 'Aguardando conciliação',
    value: 'AwaitingConciliation',
  },
  {
    label: 'Aguardando processamento',
    value: 'AwaitingProcessing',
  },
  {
    label: 'Criado',
    value: 'Created',
  },
];

const customStylesEdit = {
  control: provided => ({
    ...provided,
    // width: 105,
    height: 40,
    borderRadius: 10,
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  option: provided => ({
    ...provided,
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
};

function createData(
  id,
  order,
  status,
  value,
  created,
  date_processed,
  date_conciliation,
  name,
  cpf,
  cardStatus,
  proxy,
  cardNumber,
  flag,
  file,
) {
  return {
    id,
    order,
    status,
    value,
    created,
    date_processed,
    date_conciliation,
    name,
    cpf,
    cardStatus,
    proxy,
    cardNumber,
    flag,
    file,
  };
}

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  table: {
    minWidth: 1100,
  },
});

const StyledTableCell = withStyles(() => ({
  head: {
    // backgroundColor: theme.palette.common.black,
    // color: theme.palette.common.white,
  },
  body: {
    fontSize: 12,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

// eslint-disable-next-line no-unused-vars
export default function CardsTable({
  docs,
  setDocs,
  page,
  setPage,
  itens,
  setItens,
  total,
  searchData,
}) {
  const classes = useStyles();
  // const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [open, setOpen] = useState(false);
  const [openAll, setOpenAll] = useState(false);

  const [dateConc, setDateConc] = useState(null);
  const [dateProc, setDateProc] = useState(null);

  const [rows, setRows] = useState([]);

  const [dataEdit, setDataEdit] = useState();
  const [typeSelected, setTypeSelected] = useState({
    label: 'tipo',
    value: 'value',
  });

  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const { notifyError, notifySuccess } = useContext(FlashContext);

  const isSelected = id =>
    selected.indexOf(selected.find(e => e === id)) !== -1;

  useEffect(() => {
    const list = [];
    setSelected([]);
    docs.forEach(doc => {
      list.push(
        createData(
          doc.id,
          doc.order,
          doc.status,
          doc.value,
          moment
            .utc(doc.created_at)
            .tz('America/Sao_Paulo')
            .format('DD/MM/yyyy HH:mm'),
          doc.date_processing &&
            doc.date_processing &&
            moment
              .utc(doc.date_processing)
              .tz('America/Sao_Paulo')
              .format('DD/MM/yyyy HH:mm'),
          doc.date_conciliation &&
            doc.date_conciliation &&
            moment
              .utc(doc.date_conciliation)
              .tz('America/Sao_Paulo')
              .format('DD/MM/yyyy HH:mm'),
          doc.Card && doc.Card.Individual && doc.Card.Individual.name,
          doc.Card && doc.Card.Individual && doc.Card.Individual.cpf,
          doc.Card && doc.Card.status,
          doc.Card && doc.Card.proxy,
          doc.Card && doc.Card.card_number,
          doc.Card && doc.Card.brand,
          doc.file,
        ),
      );
    });
    setRows(list);
  }, [docs]);

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, row) => {
    // const selectedIndex = selected.indexOf(row.id);
    const selectedIndex = selected.indexOf(selected.find(s => s === row.id));
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row.id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  useEffect(() => {
    if (dataEdit) {
      switch (dataEdit.status) {
        case 'AwaitingCard':
          setTypeSelected({
            label: 'Aguardando cartão',
            value: 'AwaitingCard',
          });
          break;
        case 'Processing':
          setTypeSelected({
            label: 'Processando',
            value: 'Processing',
          });
          break;
        case 'AwaitingPayment':
          setTypeSelected({
            label: 'Aguardando pagamento',
            value: 'AwaitingPayment',
          });
          break;
        case 'Processed':
          setTypeSelected({
            label: 'Processado',
            value: 'Processed',
          });
          break;
        case 'AwaitingCardRelease':
          setTypeSelected({
            label: '',
            value: 'AwaitingCardRelease',
          });
          break;
        case 'Canceled':
          setTypeSelected({
            label: 'Cancelado',
            value: 'Canceled',
          });
          break;
        case 'Received':
          setTypeSelected({
            label: 'Recebido',
            value: 'Received',
          });
          break;
        case 'Rejected':
          setTypeSelected({
            label: 'Rejeitado',
            value: 'Rejected',
          });
          break;
        case 'AutoProcessing':
          setTypeSelected({
            label: 'Processamento automático',
            value: 'AutoProcessing',
          });
          break;
        case 'AwaitingConciliation':
          setTypeSelected({
            label: 'Aguardando conciliação',
            value: 'AwaitingConciliation',
          });
          break;
        case 'AwaitingProcessing':
          setTypeSelected({
            label: 'Aguardando processamento',
            value: 'AwaitingProcessing',
          });
          break;
        case 'Created':
          setTypeSelected({
            label: 'Criado',
            value: 'Created',
          });
          break;
        default:
          console.log('Deu erro');
      }
      if (dataEdit.processed && dataEdit.processed !== '') {
        setDateProc(new Date(dataEdit.processed));
      }
      if (dataEdit.conciliation && dataEdit.conciliation !== '') {
        setDateProc(new Date(dataEdit.conciliation));
      }
    }
  }, [dataEdit]);

  useEffect(() => {
    if (!open) {
      setDateProc(null);
      setDateConc(null);
    }
  }, [open]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setItens(+event.target.value);
    setPage(0);
  };

  const edit = data => {
    if (selected.length > 1) {
      setOpenAll(true);
    } else {
      setDataEdit(data);
      setOpen(true);
    }
  };

  const editOrder = e => {
    e.preventDefault();
    setLoading(true);
    const orig = _.cloneDeep(docs);

    api.order
      .updateItemAdmin(dataEdit.id, typeSelected.value, dateProc, dateConc)
      .then(() => {
        const found = orig.indexOf(orig.find(el => el.id === dataEdit.id));
        if (found) {
          const dest = orig.find(el => el.id === dataEdit.id);
          dest.date_conciliation = dateConc && moment.utc(dateConc);
          dest.date_processing = dateProc && moment.utc(dateProc);
          dest.status = typeSelected.value;
          orig.splice(found, 1, dest);
          setDocs(orig);
        }

        setLoading(false);
        setOpen(false);
        notifySuccess('Registro atualizado com sucesso');
        // console.log(res);
      })
      .catch(err => {
        setLoading(false);
        notifyError('Erro ao atualizar. Tente novamente.');
        console.log(err);
      });
  };

  const editOrderAll = e => {
    e.preventDefault();

    setLoading(true);
    // const orig = _.cloneDeep(docs);

    api.order
      .updateBatchItemAdmin(selected, typeSelected.value, dateProc, dateConc)
      .then(() => {
        searchData();
        setSelected([]);
        setLoading(false);
        setOpenAll(false);
        notifySuccess('Registros atualizados com sucesso');
      })
      .catch(err => {
        setLoading(false);
        notifyError('Erro ao atualizar. Tente novamente.');
        console.log(err);
      });
  };

  return (
    <>
      <Paper className={classes.root}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell style={{ width: 10, margin: 0, padding: 0 }}>
                  <Checkbox
                    // color="primary"
                    checkedIcon={<BpCheckedIcon />}
                    icon={<BpIcon />}
                    onChange={handleSelectAllClick}
                    indeterminate={
                      selected.length > 0 && selected.length < rows.length
                    }
                    checked={rows.length > 0 && selected.length === rows.length}
                    // onChange={e => handleClick(e, row)}
                    inputProps={{
                      'aria-label': 'select all desserts',
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell>Pedido</StyledTableCell>
                <StyledTableCell>Item status</StyledTableCell>
                <StyledTableCell>Valor</StyledTableCell>
                <StyledTableCell>Criado</StyledTableCell>
                <StyledTableCell>Processado</StyledTableCell>
                <StyledTableCell>Conciliado</StyledTableCell>
                <StyledTableCell>Nome</StyledTableCell>
                <StyledTableCell>CPF</StyledTableCell>
                <StyledTableCell>Status cartão</StyledTableCell>
                <StyledTableCell>Proxy</StyledTableCell>
                <StyledTableCell>Número</StyledTableCell>
                <StyledTableCell>Bandeira</StyledTableCell>
                <StyledTableCell>Arquivo</StyledTableCell>
                <StyledTableCell align="right">Ação</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, i) => {
                const isItemSelected = isSelected(row.id);
                return (
                  <StyledTableRow key={i}>
                    <StyledTableCell
                      style={{ width: 10, margin: 0, padding: 0 }}
                    >
                      <Checkbox
                        // color="primary"
                        checkedIcon={<BpCheckedIcon />}
                        icon={<BpIcon />}
                        // indeterminate={numSelected > 0 && numSelected < rowCount}
                        // checked={
                        //   rows.length > 0 && selected.length === rows.length
                        // }
                        checked={isItemSelected}
                        onChange={e => handleClick(e, row)}
                        inputProps={{
                          'aria-label': 'select all desserts',
                        }}
                      />
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {row.order}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.status}
                    </StyledTableCell>
                    <StyledTableCell>{row.value}</StyledTableCell>
                    <StyledTableCell>{row.created}</StyledTableCell>
                    <StyledTableCell>{row.date_processed}</StyledTableCell>
                    <StyledTableCell>{row.date_conciliation}</StyledTableCell>
                    <StyledTableCell>{row.name}</StyledTableCell>
                    <StyledTableCell>{row.cpf}</StyledTableCell>
                    <StyledTableCell>{row.cardStatus}</StyledTableCell>
                    <StyledTableCell>{row.proxy}</StyledTableCell>
                    <StyledTableCell>{row.cardNumber}</StyledTableCell>
                    <StyledTableCell>{row.flag}</StyledTableCell>
                    <StyledTableCell>
                      {row.file?.map(f => (
                        <DescriptionIcon
                          style={{ cursor: 'pointer' }}
                          onClick={() => window.open(f.gcs_file)}
                        />
                      ))}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <EditIcon
                        style={{ cursor: 'pointer' }}
                        onClick={() => edit(row)}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          // count={rows.length}
          count={total}
          rowsPerPage={itens}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      {/* MODAL DE EDIÇÃO */}
      <Dialog
        onClose={() => setOpen(!open)}
        open={open}
        fullWidth
        maxWidth="sm"
      >
        <Loading open={loading} msg="Atualizando registro..." />
        <DialogTitle id="simple-dialog-title">Edição do pedido</DialogTitle>
        <form onSubmit={editOrder}>
          <Grid
            container
            spacing={1}
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              padding: 20,
            }}
          >
            <Grid item xs={12} sm={12}>
              <Grid
                container
                spacing={1}
                style={{
                  paddingRight: 20,
                  paddingLeft: 20,
                }}
              >
                <Grid item xs={12} sm={12}>
                  <h6>Pedido</h6>
                  <TextField
                    disabled
                    size="small"
                    variant="outlined"
                    fullWidth
                    defaultValue={dataEdit && dataEdit.order}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Grid
                container
                spacing={1}
                style={{
                  paddingRight: 20,
                  paddingLeft: 20,
                }}
              >
                <Grid item xs={12} sm={6}>
                  <h6>Data processamento</h6>
                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={locale}
                    // className={classes.margin}
                  >
                    <DateTimePicker
                      fullWidth
                      clearable
                      size="small"
                      value={dateProc}
                      inputVariant="outlined"
                      placeholder="Data processamento"
                      onChange={dat => setDateProc(dat)}
                      autoOk
                      format="dd/MM/yyyy HH:mm"
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <h6>Data conciliação</h6>
                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={locale}
                    // className={classes.margin}
                  >
                    <DateTimePicker
                      fullWidth
                      clearable
                      size="small"
                      value={dateConc}
                      inputVariant="outlined"
                      placeholder="Data conciliação"
                      onChange={dat => setDateConc(dat)}
                      autoOk
                      format="dd/MM/yyyy HH:mm"
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Grid
                container
                spacing={1}
                style={{
                  paddingRight: 20,
                  paddingLeft: 20,
                }}
              >
                <Grid item xs={12} sm={12}>
                  <h6>Status</h6>
                  <Select
                    fullWidth
                    styles={customStylesEdit}
                    classNamePrefix="select"
                    placeholder="Tipo"
                    value={typeSelected}
                    onChange={setTypeSelected}
                    isSearchable={false}
                    options={optionsStatus}
                    menuPortalTarget={document.body}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <DialogActions>
            <Button onClick={() => setOpen(false)} color="secondary">
              Cancelar
            </Button>
            <Button type="submit" color="primary" variant="contained" autoFocus>
              Salvar alterações
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Dialog
        onClose={() => setOpenAll(!openAll)}
        open={openAll}
        fullWidth
        maxWidth="sm"
      >
        <Loading open={loading} msg="Atualizando registros..." />
        <DialogTitle id="simple-dialog-title">Edição em massa</DialogTitle>
        <form onSubmit={editOrderAll}>
          <Grid
            container
            spacing={1}
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              padding: 20,
            }}
          >
            <Grid item xs={12} sm={12}>
              <Grid
                container
                spacing={1}
                style={{
                  paddingRight: 20,
                  paddingLeft: 20,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Grid
                container
                spacing={1}
                style={{
                  paddingRight: 20,
                  paddingLeft: 20,
                }}
              >
                <Grid item xs={12} sm={6}>
                  <h6>Data processamento</h6>
                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={locale}
                    // className={classes.margin}
                  >
                    <DateTimePicker
                      fullWidth
                      clearable
                      size="small"
                      value={dateProc}
                      inputVariant="outlined"
                      placeholder="Data processamento"
                      onChange={dat => setDateProc(dat)}
                      autoOk
                      format="dd/MM/yyyy HH:mm"
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <h6>Data conciliação</h6>
                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={locale}
                    // className={classes.margin}
                  >
                    <DateTimePicker
                      fullWidth
                      clearable
                      size="small"
                      value={dateConc}
                      inputVariant="outlined"
                      placeholder="Data conciliação"
                      onChange={dat => setDateConc(dat)}
                      autoOk
                      format="dd/MM/yyyy HH:mm"
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Grid
                container
                spacing={1}
                style={{
                  paddingRight: 20,
                  paddingLeft: 20,
                }}
              >
                <Grid item xs={12} sm={12}>
                  <h6>Status</h6>
                  <Select
                    fullWidth
                    styles={customStylesEdit}
                    classNamePrefix="select"
                    placeholder="Tipo"
                    value={typeSelected}
                    onChange={setTypeSelected}
                    isSearchable={false}
                    options={optionsStatus}
                    menuPortalTarget={document.body}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <DialogActions>
            <Button onClick={() => setOpenAll(false)} color="secondary">
              Cancelar
            </Button>
            <Button type="submit" color="primary" variant="contained" autoFocus>
              Salvar alterações
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
