/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState, useContext, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Box, Stepper, Step, StepLabel, Container } from '@material-ui/core';
import _ from 'lodash';
import api from '../../../service/axios/api';
import Loading from '../../../components/loading/Loading';
// import CssBaseline from '@mui/material/CssBaseline';

// import Box from '@mui/material/Box';
// import Container from '@mui/material/Container';

// import Stepper from '@mui/material/Stepper';
// import Step from '@mui/material/Step';
// import StepLabel from '@mui/material/StepLabel';
import FlashContext from '../../../components/contexts/FlashContext';

import Balance from './Balance';
import NotMoney from './NotMoney';
import Review from './Review';

import SendCard from './SendCard';
import Created from './Created';

import MyButton from '../../../components/button/MyButton';

import './checkout.css';

const steps = [
  'Shipping address',
  'Payment details',
  'Review your order',
  'Review your order',
];

const useStyles = makeStyles(() =>
  createStyles({
    icon: {
      fill: '#41B490',
    },
    text: {
      fill: 'white',
    },
  }),
);

// eslint-disable-next-line react/prop-types
export default function Checkout({
  setOpenModal,
  data,
  allData,
  setAllData,
  setSelected,
}) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  // const [productName, setProductName] = useState();
  const [cobranca, setCobranca] = useState();
  const [notMoney, setNotMoney] = useState();
  const [typeAddress, setTypeAddress] = useState();
  const [address, setAddress] = useState();
  const [numberAddress, setNumberAddress] = useState();
  const [complement, setComplement] = useState();
  const [district, setDistrict] = useState();
  const [city, setCity] = useState();
  const [ac, setAc] = useState();
  const [cep, setCep] = useState();
  const [uf, setUf] = useState();
  const [loading, setLoading] = useState(false);
  const { notifyError, notifySuccess } = useContext(FlashContext);

  // console.log('DADOS SELECIONADOS', data);

  useEffect(() => {
    if (cobranca) {
      if (cobranca === 'company' && steps.length === 4) {
        steps.splice(2, 1);
      } else if (cobranca === 'card' && steps.length === 3) {
        steps.push('1');
      }
    }
  }, [cobranca]);

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <Balance setCobranca={setCobranca} cobranca={cobranca} />;
      case 1:
        if (steps.length > 3) {
          return <NotMoney setNotMoney={setNotMoney} notMoney={notMoney} />;
        }
        return (
          <SendCard
            address={address}
            setAddress={setAddress}
            typeAddress={typeAddress}
            setTypeAddress={setTypeAddress}
            numberAddress={numberAddress}
            setNumberAddress={setNumberAddress}
            complement={complement}
            setComplement={setComplement}
            district={district}
            setDistrict={setDistrict}
            city={city}
            setCity={setCity}
            ac={ac}
            setAc={setAc}
            cep={cep}
            setCep={setCep}
            uf={uf}
            setUf={setUf}
          />
        );
      case 2:
        if (steps.length > 3) {
          return (
            <SendCard
              address={address}
              setAddress={setAddress}
              typeAddress={typeAddress}
              setTypeAddress={setTypeAddress}
              numberAddress={numberAddress}
              setNumberAddress={setNumberAddress}
              complement={complement}
              setComplement={setComplement}
              district={district}
              setDistrict={setDistrict}
              city={city}
              setCity={setCity}
              ac={ac}
              setAc={setAc}
              cep={cep}
              setCep={setCep}
              uf={uf}
              setUf={setUf}
            />
          );
        }
        return (
          <Review
            data={data}
            address={address}
            typeAddress={typeAddress}
            numberAddress={numberAddress}
            complement={complement}
            district={district}
            city={city}
            ac={ac}
            cep={cep}
            uf={uf}
            cobranca={cobranca}
            notMoney={notMoney}
          />
        );

      case 3:
        return (
          <Review
            data={data}
            address={address}
            typeAddress={typeAddress}
            numberAddress={numberAddress}
            complement={complement}
            district={district}
            city={city}
            ac={ac}
            cep={cep}
            uf={uf}
            cobranca={cobranca}
            notMoney={notMoney}
          />
        );
      default:
        throw new Error('Unknown step');
    }
  }

  function emit2vis() {
    setLoading(true);
    api.cards
      .replaceCard(
        data.proxy,
        data?.Individual?.cpf,
        cobranca === 'company',
        notMoney,
        address,
        numberAddress,
        uf?.value,
        complement,
        district,
        city,
        cep,
        ac,
      )
      .then(res => {
        notifySuccess(res.message);
        const orig = _.cloneDeep(allData);
        const found = orig.find(e => e.id === data.id);
        found.status = 'Canceled';
        setAllData(orig);
        setSelected([]);
        setOpenModal(false);
      })
      .catch(err => {
        notifyError(err.message);
        console.log(err);
      })
      .finally(() => setLoading(false));
  }

  const handleNext = () => {
    if (activeStep === 0 && !cobranca) {
      notifyError('Selecione uma opção para continuar');
    } else if (activeStep === 1 && steps.length === 4 && !notMoney) {
      notifyError('Selecione uma opção para continuar');
    } else if (steps.length - activeStep === 1) {
      emit2vis();
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep === 0) {
      setOpenModal(false);
    } else {
      setActiveStep(activeStep - 1);
    }
  };

  return (
    <>
      <Loading open={loading} msg="Solicitando 2ª via do cartão..." />

      <Container component="main" width={1192} height={776}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {/* <p className="new-category">Nova categoria de produto</p> */}
          <Stepper activeStep={activeStep} style={{ width: 305 }}>
            {steps.map(
              label =>
                activeStep < 3 && (
                  <Step key={label} style={{ color: '#41B490' }}>
                    <StepLabel
                      StepIconProps={{
                        classes: {
                          active: classes.icon,
                          text: classes.text,
                          completed: classes.icon,
                        },
                      }}
                    />
                  </Step>
                ),
            )}
          </Stepper>
          <p className="x" onClick={() => setOpenModal(false)}>
            X
          </p>
        </div>
        {/* {activeStep < 3 && (
          <p className="text-checkout-inform">Emissão de 2 via.</p>
        )} */}
        <>
          {activeStep === steps.length ? (
            <>
              <Created setOpenModal={setOpenModal} />
            </>
          ) : (
            <>
              {getStepContent(activeStep)}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginTop: 50,
                  marginBottom: 50,
                }}
              >
                <h6 onClick={() => setOpenModal(false)}>Sair</h6>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {/* {activeStep !== 0 && ( */}
                  {/* <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                  Voltar
                </Button> */}
                  {/* // )} */}
                  <MyButton
                    style={{
                      width: 120,
                      height: 40,
                      borderRadius: 10,
                      backgroundColor: '#657588',
                    }}
                    onClick={handleBack}
                    label="Voltar"
                  />
                  <MyButton
                    style={{
                      width: 120,
                      height: 40,
                      borderRadius: 10,
                      marginLeft: 30,
                    }}
                    onClick={handleNext}
                    label={
                      steps.length - activeStep === 1
                        ? 'Emitir 2ª via'
                        : 'Continuar'
                    }
                  />
                </Box>
              </div>
            </>
          )}
        </>
        {/* </Paper> */}
      </Container>
    </>
  );
}
