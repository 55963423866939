import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { ButtonGroup } from '@material-ui/core';
import Loading from '../../components/loading/Loading';
import Entries from './components/entries/Entries';
import Invoices from './components/invoices/Invoices';
import Billing from './components/billing/Billing';

export default function FilaAcesso() {
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState('Buscando arquivos...');
  const [pageType, setPageType] = useState(0);

  return (
    <>
      <Loading open={loading} msg={msg} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ButtonGroup color="primary">
            <Button
              onClick={() => setPageType(0)}
              variant={pageType === 0 ? 'contained' : 'outlined'}
            >
              ENTRADA
            </Button>
            <Button
              onClick={() => setPageType(1)}
              variant={pageType === 1 ? 'contained' : 'outlined'}
            >
              CONTROLE NOTAS
            </Button>
            <Button
              onClick={() => setPageType(2)}
              variant={pageType === 2 ? 'contained' : 'outlined'}
            >
              FATURAMENTO
            </Button>
          </ButtonGroup>
        </Grid>
        {pageType === 0 && <Entries setLoading={setLoading} setMsg={setMsg} />}
        {pageType === 1 && <Invoices setLoading={setLoading} setMsg={setMsg} />}
        {pageType === 2 && <Billing setLoading={setLoading} setMsg={setMsg} />}
      </Grid>
    </>
  );
}
