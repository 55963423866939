import { CARD_BRANDS, PRODUCTS } from '../../util/constants';

export function getCompanyName(params) {
  const company = params.getValue('company');
  return company.legalName || '';
}

export function getCnpj(params) {
  const company = params.getValue('company');
  return company.cnpj || '';
}

export function getCpf(params) {
  const company = params.getValue('company');
  return company.admin.cpf || '';
}

export function getId(params) {
  const id = params.getValue('id');
  return String(id).substr(0, 5) || '';
}

export function getOwner(params) {
  const company = params.getValue('company');
  return company.admin.fullName || '';
}

export function getTradingName(params) {
  const company = params.getValue('company');
  return company.tradingName || '';
}

export function getLeadContacts(params) {
  let result = [];
  if (params.row.contactFullName) {
    params.row.contactFullName.forEach(contact => {
      result.push(contact.name);
    });
  } else {
    result = ['Unknown'];
  }
  return result.join(', ');
}

export function getCommercialAgent(params) {
  const productDetails = params.getValue('productDetails');
  return productDetails.commercialAgent.company.legalName || '';
}

export function getProductName(params) {
  const productDetails = params.getValue('productDetails');

  const product =
    PRODUCTS.find(p => p.value === productDetails.product)?.label || '';

  const brand =
    CARD_BRANDS.find(
      productBrand => productBrand.value === productDetails.productBrand,
    )?.label || '';

  return `${product} ${brand}`;
}

export function getProductSalesChannel(params) {
  const rule = params.getValue('rule');

  return rule?.salesChannel?.name || '';
}

export function getAdministrationFee(params) {
  const from = params.getValue('from');
  const to = params.getValue('to');
  return `${from} - ${to}`;
}
