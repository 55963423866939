import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
// import Proposal from '@/types/Proposal'
// import axios from 'axios';
import { useHistory } from 'react-router-dom';
import api from '../../../service/axios/api';
import CampaignForm from '../components/forms/CampaignForm';

const useStyles = makeStyles(theme => ({
  header: {
    marginBottom: theme.spacing(2),
  },
}));

function CreateProposal() {
  const router = useHistory();
  const classes = useStyles();

  // const onSuccess = proposal => axios.post('/api/proposals', proposal);
  const onSuccess = campaing =>
    api.campaign.new(campaing.name, campaing.expiresIn, campaing.comments);

  const callback = () => {
    router.push('/sales/campaign');
  };

  return (
    <>
      <Grid
        className={classes.header}
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Typography variant="h4" component="h2" gutterBottom>
          Nova campanha
        </Typography>
      </Grid>
      <CampaignForm
        onSuccess={onSuccess}
        code={201}
        titleModal="Campanha inserida com sucesso"
        actionModal={callback}
        disabled={false}
      />
    </>
  );
}

export default CreateProposal;
