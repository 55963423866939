/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import api from '../../../../service/axios/api';

function SelectAsyncPaginate(props) {
  const loadOptions = async (searchQuery, loadedOptions, { page }) => {
    const cancel = new AbortController();

    // const response = await api.user.getAllSimple(
    //   'ASC',
    //   searchQuery,
    //   '',
    //   '10',
    //   page,
    //   cancel,
    // );

    const response = await api.companyAgent.getAll(
      '',
      { byType: 'name', label: searchQuery },
      10,
      page,
      cancel,
      [],
    );
    const listOpt = [];

    response.docs.forEach(doc => {
      listOpt.push({ label: doc.company_name, value: doc.id });
    });

    return {
      options: listOpt,
      hasMore: listOpt.length < response.total,
      additional: {
        page: searchQuery ? 2 : page + 1,
      },
    };
  };

  const onChange = option => {
    if (typeof props.onChange === 'function') {
      props.onChange(option);
    }
  };

  return (
    <AsyncPaginate
      value={props.value || ''}
      loadOptions={loadOptions}
      // isClearable
      isMulti={false}
      menuPlacement="top"
      onChange={onChange}
      isDisabled={props.disabled}
      isSearchable
      placeholder={props.placeholder}
      additional={{
        page: 1,
      }}
    />
  );
}

export default SelectAsyncPaginate;
