import MuiButton from './MuiButton';
import MuiSwitch from './MuiSwitch';
// import MuiTable from './MuiTable'
// import MuiTableContainer from './MuiTableContainer'
// import MuiTableHead from './MuiTableHead'
// import MuiTableRow from './MuiTableRow'
import MuiDialogTitle from './MuiDialogTitle';

const custom = {
  MuiButton,
  MuiSwitch,
  // MuiTable,
  // MuiTableContainer,
  // MuiTableHead,
  // MuiTableRow,
  MuiDialogTitle,
};

export default custom;
