/* eslint-disable no-undef */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from 'react';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ComposedChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  Sector,
  Tooltip,
  XAxis,
  YAxis,
  BarChart,
  Bar,
  Cell,
} from 'recharts';
import {
  Avatar,
  Grid,
  Typography,
  LinearProgress,
  Button,
  ButtonGroup,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import {
  FaWallet,
  FaChartBar,
  FaDollarSign,
  FaUserFriends,
  FaRegCreditCard,
  FaPiggyBank,
  FaShieldAlt,
} from 'react-icons/fa';
import styles from '../styles/Dashboard.module.css';

import api from '../../../service/axios/api';
import FlashContext from '../../../components/contexts/FlashContext';

const pieChartData = [
  { name: 'Conta Digital', value: 1300 },
  { name: 'Cartões', value: 220 },
  { name: 'Trânferencia', value: 7000 },
  { name: 'QR Code', value: 100 },
  { name: 'Pix', value: 10 },
];
const lineChartData = [
  {
    name: 'Dia A',
    passado: 4000,
    atual: 2400,
    amt: 2400,
  },
  {
    name: 'Dia B',
    passado: 3000,
    atual: 1398,
    amt: 2210,
  },
  {
    name: 'Dia C',
    passado: 2000,
    atual: 9800,
    amt: 2290,
  },
  {
    name: 'Dia D',
    passado: 2780,
    atual: 3908,
    amt: 2000,
  },
  {
    name: 'Dia E',
    passado: 1890,
    atual: 4800,
    amt: 2181,
  },
  {
    name: 'Dia F',
    passado: 2390,
    atual: 3800,
    amt: 2500,
  },
  {
    name: 'Dia G',
    passado: 3490,
    atual: 4300,
    amt: 2100,
  },
  {
    name: 'Dia H',
    passado: 3990,
    atual: 8900,
    amt: 2900,
  },
];

const initialChart = [
  {
    name: 'ASPAS TURISMO VIAGEM E ASSISTÊNCIA INTERNACIONAL S/A',
    'ASPAS TURISMO VIAGEM E ASSISTÊNCIA INTERNACIONAL S/A': 1318246736,
  },
  {
    name: 'VILA VELHA SERVIÇOS LTDA',
    'VILA VELHA SERVIÇOS LTDA': 948644550,
  },
  {
    name: 'DMM LOPES & FILHOS LTDA',
    'DMM LOPES & FILHOS LTDA': 339069163,
  },
  {
    name: 'IMPORTADORA TV LAR LTDA',
    'IMPORTADORA TV LAR LTDA': 244859153,
  },
  {
    name: 'LUMIAR HEALTH BUILDERS EQUIPAMENTOS HOSPITALARES LTDA',
    'LUMIAR HEALTH BUILDERS EQUIPAMENTOS HOSPITALARES LTDA': 232836846,
  },
  {
    name: 'COMPRE BEM',
    'COMPRE BEM': 131824673,
  },
  {
    name: 'ITFAST CONSULTORIA',
    'ITFAST CONSULTORIA': 94864455,
  },
  {
    name: 'AEROESPACIAL',
    AEROESPACIAL: 33906916,
  },
  {
    name: 'UNIVAP VIRTUAL',
    'UNIVAP VIRTUAL': 24485915,
  },
  {
    name: 'EMBRAER',
    EMBRAER: 23283684,
  },
];

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    // flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

function renderActiveShape(props) {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >{`${new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(value)}`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {`(${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
}

export default function Dashboard() {
  const [activeIndex, setActiveIndexId] = useState(0);
  const [top, setTop] = useState(5);
  const [period, setPeriod] = useState('year');
  const [barChartdata, setBarChartData] = useState([]);
  const [metrics, setMetrics] = useState({});
  const { notifyError } = useContext(FlashContext);
  const classes = useStyles();

  useEffect(() => {
    const list = [];
    initialChart.forEach((d, i) => {
      if (i < top) {
        list.push(d);
      }
    });
    setBarChartData(list);
  }, [top]);

  useEffect(() => {
    const cancel = new AbortController();
    api.commercialAgent
      .getMetrics(cancel)
      .then(res => {
        setMetrics(res.data.retorno);
      })
      .catch(error => {
        notifyError(error.message);
        // console.log(error.message);
      });
  }, []);

  const handleChangeTop = e => {
    setTop(e);
  };

  const handleChangePeriod = e => {
    setPeriod(e);
  };

  const formatter = item =>
    new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(item);

  return (
    <Grid container spacing={6}>
      <Grid item lg={3} md={4} sm={6} xs={12}>
        <div className={styles.smallWidget}>
          <div>
            <Grid container item alignItems="center">
              <Grid item xs={12}>
                <Typography variant="subtitle2" color="inherit">
                  TOTAL CLIENTES
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography variant="h5" color="inherit">
                  {metrics?.clientsTotal}
                </Typography>
              </Grid>
              <Grid
                item
                xs={3}
                style={{ display: 'flex', justifyContent: 'end' }}
              >
                <Avatar
                  style={{
                    width: 55,
                    height: 55,
                    backgroundColor: '#2898FF',
                  }}
                >
                  <FaUserFriends />
                </Avatar>
              </Grid>
            </Grid>
          </div>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item xs={4}>
              <Typography variant="h6" color="primary">
                {metrics?.clientsActive}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography>Total clientes ativos</Typography>
            </Grid>
          </Grid>
        </div>
      </Grid>
      <Grid item lg={3} md={4} sm={6} xs={12}>
        <div className={styles.smallWidget}>
          <div>
            <Grid container item alignItems="center">
              <Grid item xs={12}>
                <Typography variant="subtitle2" color="inherit" noWrap>
                  TOTAL VOLUME FINANCEIRO
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography variant="h5" color="inherit" noWrap>
                  {metrics?.financialValue &&
                    new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(metrics?.financialValue)}
                </Typography>
              </Grid>
              <Grid
                item
                xs={3}
                style={{ display: 'flex', justifyContent: 'end' }}
              >
                <Avatar
                  style={{
                    width: 55,
                    height: 55,
                    backgroundColor: '#2898FF',
                  }}
                >
                  <FaUserFriends />
                </Avatar>
              </Grid>
            </Grid>
          </div>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item xs={4}>
              <Typography variant="h6" color="primary">
                {metrics?.financialValuePercentageType &&
                metrics?.financialValuePercentageType === '-'
                  ? '↓'
                  : '↑'}{' '}
                {metrics?.financialValuePercentage &&
                  `${new Intl.NumberFormat('pt-BR', {
                    style: 'decimal',
                    maximumFractionDigits: 0,
                  }).format(metrics?.financialValuePercentage)}%`}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography>Desde o último mês</Typography>
            </Grid>
          </Grid>
        </div>
      </Grid>
      <Grid item lg={3} md={4} sm={6} xs={12}>
        <div className={styles.smallWidget}>
          <div>
            <Grid container item alignItems="center">
              <Grid item xs={12} sttyle={{ margin: 0 }}>
                <Typography variant="subtitle2" color="inherit">
                  TAXA ADM
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <div style={{ display: 'flex' }}>
                  <div
                    style={{
                      // borderRight: '1px solid black',
                      alignSelf: 'flex-end',
                    }}
                  >
                    <Typography color="inherit">75.5% |</Typography>
                  </div>
                  <div>
                    <Typography color="inherit">+ alta</Typography>
                    <Typography color="inherit">80.5% |</Typography>
                  </div>
                  <div>
                    <Typography color="inherit">+ baixa</Typography>
                    <Typography color="inherit">65.5%</Typography>
                  </div>
                </div>
              </Grid>
              <Grid
                item
                xs={2}
                style={{ display: 'flex', justifyContent: 'end' }}
              >
                <Avatar
                  style={{
                    width: 55,
                    height: 55,
                    backgroundColor: '#2898FF',
                  }}
                >
                  <FaChartBar />
                </Avatar>
              </Grid>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <Grid item xs={4}>
                  <Typography variant="h6" color="primary">
                    ↓ 6%
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography>Desde o último mês</Typography>
                </Grid>
              </Grid>
              {/* <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <Grid item xs={4}>
                  <Typography variant="h6" color="primary">
                    ↓ 6%
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography>Desde o último mês</Typography>
                </Grid>
              </Grid> */}
            </Grid>
          </div>
          {/* <br />
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item xs={12}>
              <LinearProgress variant="determinate" value={75} />
            </Grid>
          </Grid> */}
        </div>
      </Grid>
      <Grid item lg={3} md={4} sm={6} xs={12}>
        <div className={styles.smallWidget}>
          <div>
            <Grid container item alignItems="center">
              <Grid item xs={12}>
                <Typography variant="subtitle2" color="inherit">
                  GANHOS TOTAIS
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography variant="h4" color="inherit" noWrap>
                  {new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(23200)}
                </Typography>
              </Grid>
              <Grid
                item
                xs={3}
                style={{ display: 'flex', justifyContent: 'end' }}
              >
                <Avatar
                  style={{
                    width: 55,
                    height: 55,
                    backgroundColor: '#2898FF',
                  }}
                >
                  <FaDollarSign />
                </Avatar>
              </Grid>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <Grid item xs={4}>
                  <Typography variant="h6" color="primary">
                    ↓ 1%
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography>Desde o último mês</Typography>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} md={8}>
        <div
          className={styles.longWidget}
          style={{ height: top === 10 && 1400 }}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6" color="inherit">
              ÚLTIMAS VENDAS
            </Typography>
            <div className={classes.root}>
              <ButtonGroup
                color="primary"
                aria-label="outlined primary button group"
              >
                <Button
                  style={{
                    backgroundColor: top === 5 && 'rgb(40, 152, 255)',
                    color: top === 5 && '#fff',
                  }}
                  onClick={() => handleChangeTop(5)}
                >
                  TOP 05
                </Button>
                <Button
                  style={{
                    backgroundColor: top === 10 && 'rgb(40, 152, 255)',
                    color: top === 10 && '#fff',
                  }}
                  onClick={() => handleChangeTop(10)}
                >
                  TOP 10
                </Button>
              </ButtonGroup>
              <ButtonGroup
                color="primary"
                aria-label="outlined primary button group"
              >
                <Button
                  style={{
                    backgroundColor: period === 'month' && 'rgb(40, 152, 255)',
                    color: period === 'month' && '#fff',
                  }}
                  onClick={() => handleChangePeriod('month')}
                >
                  MÊS
                </Button>
                <Button
                  style={{
                    backgroundColor: period === 'year' && 'rgb(40, 152, 255)',
                    color: period === 'year' && '#fff',
                  }}
                  onClick={() => handleChangePeriod('year')}
                >
                  ANO
                </Button>
              </ButtonGroup>
            </div>
          </div>
          <ResponsiveContainer width="100%" height={top === 5 ? 600 : 1200}>
            <ComposedChart
              width={1000}
              height={top === 5 ? 1000 : 2000}
              data={barChartdata}
              layout="vertical"
              margin={{
                // top: 20,
                // right: 30,
                left: 80,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis type="number" />
              <YAxis dataKey="name" type="category" scale="band" />
              <Tooltip
                formatter={formatter}
                labelFormatter={item => `Taxa ADM 12%`}
              />
              <Legend />
              <Bar
                dataKey="ASPAS TURISMO VIAGEM E ASSISTÊNCIA INTERNACIONAL S/A"
                stackId="a"
                fill="#8884d8"
                barSize={20}
              />
              <Bar
                dataKey="VILA VELHA SERVIÇOS LTDA"
                stackId="b"
                fill="#ca82b6"
                barSize={20}
              />
              <Bar
                dataKey="DMM LOPES & FILHOS LTDA"
                stackId="c"
                fill="#c5ca82"
                barSize={20}
              />
              <Bar
                dataKey="IMPORTADORA TV LAR LTDA"
                stackId="d"
                fill="#ca8e82"
                barSize={20}
              />
              <Bar
                dataKey="LUMIAR HEALTH BUILDERS EQUIPAMENTOS HOSPITALARES LTDA"
                stackId="e"
                fill="#82ca9d"
                barSize={20}
              />
              {/* MAIS 5 */}
              {/* {barChartdata.length > 5 && ( */}
              {/* <> */}
              {barChartdata.length > 5 && (
                <Bar
                  dataKey="ITFAST CONSULTORIA"
                  stackId="f"
                  fill="#17118c"
                  barSize={20}
                />
              )}
              {barChartdata.length > 5 && (
                <Bar
                  dataKey="UNIVAP VIRTUAL"
                  stackId="g"
                  fill="#cd0795"
                  barSize={20}
                />
              )}
              {barChartdata.length > 5 && (
                <Bar
                  dataKey="EMBRAER"
                  stackId="h"
                  fill="#868e13"
                  barSize={20}
                />
              )}
              {barChartdata.length > 5 && (
                <Bar
                  dataKey="COMPRE BEM"
                  stackId="i"
                  fill="#671808"
                  barSize={20}
                />
              )}
              {barChartdata.length > 5 && (
                <Bar
                  dataKey="AEROESPACIAL"
                  stackId="j"
                  fill="#00e758"
                  barSize={20}
                />
              )}
              {/* </> */}
              {/* )} */}
            </ComposedChart>

            {/* <LineChart
              width={500}
              height={300}
              data={lineChartData}
              margin={{
                top: 15,
                right: 15,
                left: 0,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="atual"
                stroke="#2898ff"
                activeDot={{ r: 8 }}
              />
              <Line type="monotone" dataKey="passado" stroke="#41b490" />
            </LineChart> */}
          </ResponsiveContainer>
        </div>
      </Grid>
      <Grid item xs={12} md={4}>
        <div className={styles.longWidgetProduct}>
          <Typography variant="h6" color="inherit">
            PRODUTOS
          </Typography>
          <ResponsiveContainer width="100%" height={260}>
            <PieChart width={200} height={200}>
              <Pie
                activeIndex={activeIndex}
                activeShape={renderActiveShape}
                data={pieChartData}
                innerRadius={60}
                fill="#2898FF"
                outerRadius={80}
                dataKey="value"
                onMouseEnter={(e, id) => setActiveIndexId(id)}
              />
            </PieChart>
          </ResponsiveContainer>
          {/* <div>
            <Grid container item alignItems="center">
              <Grid item xs={1}>
                <FaDollarSign
                  style={{
                    width: 25,
                    height: 25,
                    color: '#2898FF',
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <Typography variant="h6" color="primary">
                  22
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <FaPiggyBank
                  style={{
                    width: 25,
                    height: 25,
                    color: '#2898FF',
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <Typography variant="h6" color="primary">
                  12
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <FaRegCreditCard
                  style={{
                    width: 25,
                    height: 25,
                    color: '#2898FF',
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <Typography variant="h6" color="primary">
                  72
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <FaShieldAlt
                  style={{
                    width: 25,
                    height: 25,
                    color: '#2898FF',
                  }}
                />
              </Grid>
              <Grid item xs={1}>
                <Typography variant="h6" color="primary">
                  11
                </Typography>
              </Grid>
            </Grid>
          </div> */}
        </div>
      </Grid>
    </Grid>
  );
}
