/* eslint-disable import/prefer-default-export */
import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      width: '50%',
      marginTop: theme.spacing(5),
      backgroundColor: '#FFF',
      margin: 'auto',
      boxShadow: theme.shadows[5],
      padding: '38px',
      paddingBottom: '38px',
      display: 'flex',
      flexDirection: 'column',
      borderRadius: '10px',
    },
    buttonContainer: {
      marginTop: theme.spacing(4),
    },
    font: {
      fontSize: '16px',
      marginBottom: theme.spacing(2),
    },
    error: {
      color: '#F84A4A',
      fontSize: '14px',
      marginTop: theme.spacing(2),
    },
  }),
);
