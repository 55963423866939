/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Grid, styled } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import api from '../../../service/axios/api';
import styles from '../../../styles/Layout.module.css';

const customStyles = {
  control: provided => ({
    ...provided,
    // width: 200,
    backgroundColor: 'none',
    // boxShadow: 'none',
    boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
    border: 'none',
    color: '#717171',
    fontWeight: 'bold',
    fontFamily: 'Open Sans',
    // fontSize: 24,
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  option: provided => ({
    ...provided,
    fontWeight: 'bold',
    fontFamily: 'Open Sans',
    color: '#717171',
    // color: state.data.color,
    // backgroundColor: state.data.bgcolor,
    // fontSize: 24,
  }),
  placeholder: () => ({
    fontWeight: 'bold',
    fontFamily: 'Open Sans',
    marginTop: -20,
    color: '#717171',
    // paddingBottom: 20,
    // fontSize: 24,
    // marginBottom: 30,
  }),
};

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark'
        ? 'rgba(57,75,89,.5)'
        : 'rgba(206,217,224,.5)',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#41b490',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#41b490',
  },
});

export default function Contract2({
  type,
  setContract,
  setLoad,
  setMsg,
  contract,
  campaign,
  setCampaign,
  cnpj,
  setCnpj,
  optCompanys,
  setOptCompanys,
}) {
  // const [optCompanys, setOptCompanys] = useState([]);
  const { t } = useTranslation();
  const [options, setOptions] = useState([]);
  const [campaing, setCampaing] = useState([]);

  const handleChange = e => {
    const cancel = new AbortController();
    const intern = [];
    api.contract
      .getSubCompanies(cancel, e.all.id)
      .then(res => {
        res.retorno.forEach(c => {
          intern.push({
            label: c.LegalEntity.cnpj,
            value: c.id,
            contract: c.contract_id,
            companyName: e.label,
            campaign: e.all.campaign,
          });
        });
      })
      .catch(err => {
        console.log('ERROR', err);
      });
    // const intern = [];
    setCampaign(null);
    setCnpj(null);
    // e.all.forEach(c => {
    //   intern.push({
    //     label: c.CNPJ,
    //     value: c.id,
    //     companyName: c.companyName,
    //     campaign: c.campaign,
    //   });
    // });
    setContract({
      label: e.label,
      value: null,
      cnpj: null,
    });
    setOptions(intern);
  };

  const handleChangeCnpj = item => {
    setCnpj(item);
    console.log(item);
    setContract({
      label: item.companyName,
      value: item.contract,
      cnpj: item.label,
    });
    setCampaign(null);
    setCampaing([
      {
        label: item.campaign ? item.campaign : t('newOrder.contract.noResult'),
        value: item.campaign,
      },
    ]);
  };

  useEffect(() => {
    if (optCompanys.length === 0) {
      console.log(type);
      setCnpj(null);
      setCampaign(null);
      setMsg(t('newOrder.contract.searchCompanyMsg'));
      setLoad(true);
      const cancel = new AbortController();
      if (type !== 'service') {
        const product = type === 'pre' ? 'Card' : 'Transfer';
        api.contract
          .getByProduct(cancel, product)
          .then(res => {
            const companyList = [];
            const list = [];
            res.forEach(item => {
              // companyList.push(item.companyName);
              if (item.campaign) {
                list.push({
                  label: `${item.companyName} - ${item.campaign}`,
                  value: item.companyName,
                  all: item,
                });
              } else {
                list.push({
                  label: item.companyName,
                  value: item.companyName,
                  all: item,
                });
              }
            });
            setOptCompanys(list);
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {
            setLoad(false);
          });
      } else {
        const p1 = api.contract.getByProduct(cancel, 'Card');
        const p2 = api.contract.getByProduct(cancel, 'Transfer');
        Promise.all([p1, p2])
          .then(res => {
            const list = [];
            res.forEach(c => {
              c.forEach(item => {
                if (item.campaign) {
                  list.push({
                    label: `${item.companyName} - ${item.campaign}`,
                    value: item.companyName,
                    all: item,
                  });
                } else {
                  list.push({
                    label: item.companyName,
                    value: item.companyName,
                    all: item,
                  });
                }
              });
            });
            setOptCompanys(list);
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {
            setLoad(false);
          });
      }
    }
  }, []);

  return (
    <div className="box-new-card">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'start',
          alignItems: 'center',
        }}
      >
        <h6 className={styles.textBoxInform}>
          {t('newOrder.contract.choiceCompany')}
        </h6>
      </div>
      <Grid
        container
        spacing={1}
        style={{
          justifyContent: 'center',
          padding: 40,
          alignItems: 'center',
        }}
      >
        <Grid item xs={12} sm={12}>
          <Select
            // width="300"
            styles={customStyles}
            value={contract}
            classNamePrefix="select"
            placeholder={t('newOrder.contract.companyName')}
            onChange={handleChange}
            // isSearchable={false}
            options={optCompanys}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Select
            // width="300"
            styles={customStyles}
            value={cnpj}
            classNamePrefix="select"
            placeholder={t('newOrder.contract.cnpj')}
            onChange={handleChangeCnpj}
            // onChange={(e, i) => mailSelected(e, i)}
            isSearchable={false}
            options={options}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Select
            onChange={setCampaign}
            styles={customStyles}
            value={campaign}
            classNamePrefix="select"
            placeholder={t('newOrder.contract.campaignName')}
            isSearchable={false}
            options={campaing}
          />
        </Grid>
      </Grid>
    </div>
  );
}
