import React from 'react';
import { Grid } from '@material-ui/core';
import Button from '../../../../components/button/MyButton';

// eslint-disable-next-line react/prop-types
export default function Created({ setOpenModal }) {
  return (
    <Grid
      container
      spacing={2}
      style={{
        justifyContent: 'center',
        margin: 15,
        padding: 30,
      }}
    >
      <Grid
        container
        spacing={2}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {/* <Grid
        container
        spacing={2}
        style={{
          display: 'flex', flexDirection: 'collumn', justifyContent: 'center', alignItems: 'center',
        }}
      >
        <Grid item xs={12}> */}
        {/* <Grid item xs={12}> */}
        <img src="./img/checkOk.svg" alt="ok" className="img-category-check" />
        {/* </Grid> */}
        {/* <Grid item xs={12}> */}
        <h4 className="txt-category-success" style={{ marginTop: 30 }}>
          Acessório criado com sucesso!
        </h4>
        {/* </Grid> */}
        {/* <Grid item xs={12}> */}
        <Button
          style={{
            marginBottom: 20,
            marginRight: 20,
            height: 40,
            width: 120,
            marginTop: 30,
            // fontSize: 24,
          }}
          onClick={() => setOpenModal(false)}
          label="OK"
        />
        {/* </Grid> */}
      </Grid>
      {/* </Grid>
      // </Grid> */}
    </Grid>
  );
}
