import { makeStyles, createStyles, fade } from '@material-ui/core/styles';

const useStyles = makeStyles(theme =>
  createStyles({
    userContainer: {
      display: 'flex',
      userSelect: 'none',
      color: theme.palette.primary.contrastText,
      padding: theme.spacing(2),
      alignItems: 'center',
      borderRadius: 30,
      cursor: 'pointer',
      '& h6': {
        padding: theme.spacing(2),
        fontWeight: 600,
      },
      '& div': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
  }),
);

const useStylesDropdown = makeStyles(theme =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      marginTop: theme.spacing(5),
      minWidth: 360,
      borderRadius: 10,
      '& hr': {
        margin: theme.spacing(2, 1),
      },
    },
    contentModal: {
      maxHeight: '100vh',
    },
    item: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(2),
      borderRadius: 8,
      userSelect: 'none',
      '& h6, span': {
        marginLeft: theme.spacing(3),
      },
    },
    click: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: fade(theme.palette.divider, 0.8),
      },
    },
    profile: {
      width: theme.spacing(14),
      height: theme.spacing(14),
      backgroundColor: theme.palette.secondary.main,
    },
    icon: {
      backgroundColor: theme.palette.primary.main,
    },
  }),
);

const useStylesModal = makeStyles(theme =>
  createStyles({
    container: {
      width: '95%',
      marginTop: theme.spacing(5),
      backgroundColor: '#FFF',
      margin: 'auto',
      padding: '17px 18px 30px 30px',
      borderRadius: '4px',
      boxShadow: theme.shadows[5],
    },
    font: {
      fontSize: '20px',
      color: theme.palette.primary.main,
    },
    subtitle: {
      fontWeight: 500,
      color: theme.palette.primary.main,
    },
    column: {
      '& > *': {
        wordWrap: 'break-word',
      },
    },
    content: {
      marginTop: theme.spacing(4),
    },
  }),
);

export { useStyles, useStylesDropdown, useStylesModal };
