const custom = {
  thumb: {
    width: 14,
    height: 14,
    boxShadow: 'none',
  },
  switchBase: {
    padding: 12,
  },
};

export default custom;
