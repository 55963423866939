/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from 'react';
import {
  Grid,
  // Button,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CardActions,
  Avatar,
  IconButton,
  Typography,
  Dialog,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Paper,
  TextField,
  Button,
} from '@material-ui/core';

import { MoreVertIcon } from '@material-ui/icons';
import moment from 'moment/moment';

// import Collapse from '@material-ui/core/Collapse';
import { red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import FlashContext from '../../components/contexts/FlashContext';
import Loading from '../../components/loading/Loading';
// import Button from '../../components/button/MyButton';
import api from '../../service/axios/api';
import permissionModule from './PermissionModule';

const useStyles = makeStyles(theme => ({
  root: {
    width: 345,
    margin: 10,
    cursor: 'pointer',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  textNotification: {
    minWidth: 180,
    // textAlign: 'center',
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
    // lineHeight: 'auto',
    fontSize: 14,
    color: '#707070',
    '&:hover': {
      backgroundColor: '#41b490',
      color: '#fff',
    },
  },
  myLabel: {
    // padding: theme.spacing(6),
    color: '#2a3176',
    // fontWeight: 'bold',
    // margin: 20,
  },
  data: {
    marginTop: -20,
    color: '#717171',
    fontWeight: 500,
    fontFamily: 'Open Sans',
  },
  labelData: {
    color: '#717171',
    fontFamily: 'Open Sans',
  },
  gridItem: {
    marginTop: -40,
    '&& .MuiGrid-item': {
      paddingTop: 0,
      maxHeight: 60,
    },
  },
  myTextField: {
    backgroundColor: 'none',
    boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
    border: 'none',
    color: '#717171',
    fontWeight: 'bold',
    fontFamily: 'Open Sans',
  },
  text: {
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
    color: '#717171',
  },
}));

export default function Permissions() {
  const classes = useStyles(0);
  const { notifyError, notifySuccess } = useContext(FlashContext);
  const [group, setGroup] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openExcludModal, setOpenExcludModal] = useState(false);
  const [newGroup, setNewGroup] = useState(false);
  const [groupId, setGroupId] = useState('');
  const [groupName, setGroupName] = useState('');
  const [description, setDescription] = useState('');

  const [msg, setMsg] = useState('Buscando grupo de regras...');
  const [loading, setLoading] = useState(true);

  const [modulos, setModulos] = useState(permissionModule);

  const handleChange = (m, k, event) => {
    const orig = _.cloneDeep(modulos);
    const mod = orig.find(o => o.name === m);
    const i = mod.itens.find(mm => mm.name === k);
    i.status = event.target.checked;
    setModulos(orig);
  };

  useEffect(() => {
    const cancel = new AbortController();
    api.permissions
      .getAll(cancel)
      .then(permissions => {
        setGroup(permissions.docs);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const modalConf = (rules, id, name, iDescription) => {
    setNewGroup(false);
    setGroupId(id);
    setGroupName(name);
    setDescription(iDescription);
    setModulos([
      {
        name: 'Dashboard',
        itens: [
          { name: 'Pedidos', status: rules[0] === '1' },
          { name: 'Base de Cartões', status: rules[1] === '1' },
          { name: 'Contratos e taxas', status: rules[2] === '1' },
          { name: 'Liberação de pagamentos', status: rules[3] === '1' },
          { name: 'Movimentação', status: rules[4] === '1' },
          { name: 'Painel', status: rules[5] === '1' },
          { name: 'Área administrativa', status: rules[6] === '1' },
        ],
      },
      {
        name: 'Pedidos',
        itens: [
          { name: 'Novo Pedido', status: rules[7] === '1' },
          { name: 'Duplicar Pedido', status: rules[8] === '1' },
          { name: 'Cancelar Pedido', status: rules[9] === '1' },
          { name: 'Pagar Pedido', status: rules[10] === '1' },
        ],
      },
      {
        name: 'Detalhes do pedido',
        itens: [
          { name: 'Planilha premiados', status: rules[11] === '1' },
          { name: 'Adicionar itens', status: rules[12] === '1' },
          { name: 'Descartar itens', status: rules[13] === '1' },
          { name: 'Obter boleto', status: rules[14] === '1' },
          { name: 'Pagar', status: rules[15] === '1' },
        ],
      },
      {
        name: 'Contratos e taxas',
        itens: [
          { name: 'Novo contrato', status: rules[16] === '1' },
          { name: 'Editar contrato', status: rules[17] === '1' },
          { name: 'Desativar contrato', status: rules[18] === '1' },
          { name: 'Download', status: rules[19] === '1' },
        ],
      },
      {
        name: 'Liberação de cargas',
        itens: [
          { name: 'Upload retorno', status: rules[20] === '1' },
          { name: 'Liberar itens', status: rules[21] === '1' },
          { name: 'Cancelar itens', status: rules[22] === '1' },
        ],
      },
      {
        name: 'Movimentação',
        itens: [{ name: 'Download Movimentação', status: rules[23] === '1' }],
      },
      {
        name: 'Administração',
        itens: [
          { name: 'Financeiro', status: rules[24] === '1' },
          { name: 'Grupo de permissões', status: rules[25] === '1' },
          { name: 'Gerenciamento de usuários', status: rules[26] === '1' },
        ],
      },
    ]);
    setOpenModal(true);
  };

  const newGroupOpen = () => {
    setNewGroup(true);
    setModulos(permissionModule);
    setOpenModal(true);
    setGroupName('');
    setDescription('');
  };

  const deleteGroup = () => {
    setMsg('Excluindo grupo...');
    setLoading(true);

    api.permissions
      .delete(groupId)
      .then(() => {
        const orig = _.cloneDeep(group);
        const idx = orig.findIndex(g => g.id === groupId);
        orig.splice(idx, 1);
        setGroup(orig);
        setOpenExcludModal(false);
        setOpenModal(false);
        notifySuccess('Grupo excluido com sucesso.');
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const createNew = () => {
    if (newGroup) {
      setMsg('Criando novo grupo...');
      setLoading(true);
      let role = '';
      modulos.forEach(m => {
        m.itens.forEach(i => {
          if (i.status) {
            role += '1';
          } else {
            role += '0';
          }
        });
      });
      api.permissions
        .create(groupName, description, role)
        .then(res => {
          setGroup([...group, res.retorno]);
          setOpenModal(false);
          notifySuccess('Grupo criado com sucesso.');
        })
        .catch(err => {
          notifyError(err.message);
          if (err.problems.length > 0) {
            err.problems.forEach(p => {
              notifyError(p.msg);
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setMsg('Editando grupo...');
      setLoading(true);
      let role = '';
      modulos.forEach(m => {
        m.itens.forEach(i => {
          if (i.status) {
            role += '1';
          } else {
            role += '0';
          }
        });
      });
      api.permissions
        .update(groupName, description, role, groupId)
        .then(() => {
          const orig = _.cloneDeep(group);
          const find = orig.find(o => o.id === groupId);
          find.name = groupName;
          find.description = description;
          find.permissions = role;
          setGroup(orig);
          // setGroup([...group, res.retorno]);
          setOpenModal(false);
          notifySuccess('Grupo editado com sucesso');
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <>
      <Loading open={loading} msg={msg} />
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          sm={12}
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <h3>Grupo de permissões</h3>
          <Button
            onClick={newGroupOpen}
            color="primary"
            variant="contained"
            size="small"
            style={{ height: '40px' }}
          >
            Novo Grupo
          </Button>
        </Grid>
        <Grid container spacing={3} style={{ padding: 20 }}>
          {group.map(g => (
            <Card
              className={classes.root}
              onClick={() =>
                modalConf(g.permissions, g.id, g.name, g.description)
              }
            >
              <CardHeader
                avatar={
                  <Avatar aria-label="recipe" className={classes.avatar}>
                    {g.name[0]}
                  </Avatar>
                }
                title={g.name}
                subheader={moment(g.created_at).format('LLL')}
              />
              <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                  {g.description}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </Grid>
      </Grid>
      <Dialog
        onClose={() => setOpenModal(false)}
        open={openModal}
        fullWidth
        maxWidth="md"
      >
        <Loading open={loading} msg={msg} />
        <Grid
          container
          style={{
            borderRadius: 10,
            padding: 5,
          }}
        >
          <h4 className={classes.myLabel}>Permissões do grupo</h4>
          {/* {newGroup && ( */}
          <>
            <Grid
              item
              xs={12}
              style={{
                // backgroundColor: '#E1E1E6',
                margin: 5,
                borderRadius: 8,
                padding: 5,
              }}
            >
              <TextField
                classes={{ notchedOutline: classes.input }}
                className={classes.text}
                fullWidth
                id="outlined-basic"
                label="Nome do Grupo"
                variant="outlined"
                size="small"
                value={groupName}
                onChange={e => setGroupName(e.target.value)}
                InputProps={{
                  classes: {
                    notchedOutline: classes.myTextField,
                    input: classes.text,
                  },
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                // backgroundColor: '#E1E1E6',
                margin: 5,
                borderRadius: 8,
                padding: 5,
              }}
            >
              <TextField
                classes={{ notchedOutline: classes.input }}
                className={classes.text}
                fullWidth
                id="outlined-basic"
                label="Descrição do grupo de regras"
                variant="outlined"
                size="small"
                multiline
                value={description}
                onChange={e => setDescription(e.target.value)}
                rows={3}
                InputProps={{
                  classes: {
                    notchedOutline: classes.myTextField,
                    input: classes.text,
                  },
                }}
              />
            </Grid>
          </>
          {/* )} */}
          {modulos.map(m => (
            <Grid
              item
              xs={12}
              style={{
                backgroundColor: '#E1E1E6',
                margin: 5,
                borderRadius: 8,
                padding: 5,
              }}
            >
              <h5 className={classes.myLabel}>{m.name}</h5>
              <FormGroup
                row
                style={{
                  marginLeft: 20,
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                {m.itens.map(i => (
                  <FormControlLabel
                    style={{ minWidth: 240, marginTop: -20 }}
                    control={
                      <Checkbox
                        checked={i.status}
                        onChange={e => handleChange(m.name, i.name, e)}
                      />
                    }
                    label={i.name}
                  />
                ))}
              </FormGroup>
            </Grid>
          ))}

          <Grid
            container
            style={{
              alignItems: 'end',
              justifyContent: 'end',
              // marginBottom: 30,
              // marginTop: -20,
            }}
          >
            <Button
              style={{
                height: 40,
                width: 80,
                fontSize: 14,
                marginRight: 10,
              }}
              variant="outlined"
              onClick={() => setOpenModal(false)}
            >
              Cancelar
            </Button>
            {!newGroup && (
              <Button
                style={{
                  height: 40,
                  width: 80,
                  fontSize: 14,
                  marginRight: 10,
                  background: '#cf0e0e',
                  color: 'white',
                }}
                // color="secondary"
                variant="contained"
                onClick={() => setOpenExcludModal(true)}
              >
                Excluir
              </Button>
            )}
            <Button
              style={{
                height: 40,
                width: 80,
                fontSize: 14,
              }}
              color="primary"
              variant="contained"
              onClick={createNew}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Dialog>
      <Dialog
        onClose={() => setOpenExcludModal(false)}
        open={openExcludModal}
        fullWidth
        // maxWidth="md"
      >
        <Loading open={loading} msg={msg} />
        <Grid
          container
          style={{
            borderRadius: 10,
            padding: 5,
          }}
        >
          <h4 className={classes.myLabel}>Exclusão do grupo</h4>
          {/* {newGroup && ( */}
          <Grid item xs={12}>
            Deseja realmente apagar este grupo de regras? Essa ação não pode ser
            desfeita.
          </Grid>

          <Grid
            container
            style={{
              alignItems: 'end',
              justifyContent: 'end',
              // marginBottom: 30,
              marginTop: 15,
            }}
          >
            <Button
              style={{
                height: 40,
                width: 80,
                fontSize: 14,
                marginRight: 10,
              }}
              size="small"
              variant="outlined"
              onClick={() => setOpenExcludModal(false)}
            >
              Cancelar
            </Button>
            <Button
              style={{
                height: 40,
                width: 80,
                fontSize: 14,
                background: '#cf0e0e',
                color: 'white',
              }}
              variant="contained"
              onClick={deleteGroup}
            >
              Excluir
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
}
