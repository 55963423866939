/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-bind */
import React, { useContext, useState } from 'react';
import { Paper } from '@material-ui/core';
import StepperContext, { StepperProvider } from '../../context/StepperContext';

import CompanyFormViewer from './CompanyFormViewer';
import ContractReviewForm from './ContractReviewForm';
import DeliveryAddressFormViewer from './DeliveryAddressFormViewer';
import ProductDetailsFormViewer from './ProductDetailsFormViewer';
import company from './defaultStates/company';
import { productDetails } from './defaultStates/productDetails';
import RevenueFormViewer from './RevenueFormViewer';

const revenue = {
  calculation: 'normal',
  currency: 'brl',
  paymentMethod: 'transfer',
  isInvoiceTopup: false,
  issuingCompanyId: 1,
  bankAccountId: 1,
  serviceCodeId: 8,
  signatureDate: new Date(),
  expirationDate: new Date(),
  isSigned: false,
};
const deliveryAddress = {
  postalCode: '',
  street: '',
  number: '',
  complement: '',
  district: '',
  city: '',
  state: '',
  receiver: '',
  deliveryAgent: 'logistics',
  isCentralizedDelivery: true,
  deliveryService: 'doc+',
};

const defaultContract = {
  id: '0',
  isActive: true,
  pdfKey: '',
  company: {
    ...company,
    contacts: [
      {
        name: '',
        cpf: '',
        role: '',
        phone: '',
        mobile: '',
        email: '',
        department: '',
      },
    ],
  },
  productDetails,
  deliveryAddress,
  revenue,
};

function StepForm({ contract, control, edit, agent, agentComission }) {
  const stepper = useContext(StepperContext);

  switch (stepper.step) {
    case 1:
      return (
        <>
          <CompanyFormViewer
            company={contract.company}
            controlE={control}
            edit={edit}
          />
          <DeliveryAddressFormViewer
            deliveryAddress={contract.deliveryAddress}
          />
          <ProductDetailsFormViewer
            productDetails={contract.productDetails}
            edit={edit}
            agent={agent}
            agentComission={agentComission}
          />
          <RevenueFormViewer revenue={contract.revenue} />
        </>
      );
    case 2:
      return (
        <DeliveryAddressFormViewer deliveryAddress={contract.deliveryAddress} />
      );
    case 3:
      return (
        <ProductDetailsFormViewer
          productDetails={contract.productDetails}
          edit={edit}
        />
      );
    case 4:
      return <RevenueFormViewer revenue={contract.revenue} />;
    default:
      return <ContractReviewForm contract={contract} />;
  }
}

function ContractFormViewer({
  contract = defaultContract,
  disabled = false,
  onSuccess,
  code,
  titleModal,
  actionModal,
  control,
  edit,
  agent,
  agentComission,
}) {
  const [data, setData] = useState({
    ...contract,
    revenue: {
      ...contract.revenue,
      signatureDate: contract.revenue?.signatureDate
        ? new Date(contract.revenue?.signatureDate)
        : undefined,
      expirationDate: contract.revenue?.expirationDate
        ? new Date(contract.revenue?.expirationDate)
        : undefined,
    },
  });

  function updateData(stepData) {
    if (stepData) {
      setData(prevData => {
        return { ...prevData, ...stepData };
      });
    }
  }

  return (
    <Paper elevation={4}>
      <StepperProvider
        disabled={disabled}
        onChange={updateData}
        onSuccess={onSuccess}
        code={code}
        titleModal={titleModal}
        actionModal={actionModal}
      >
        <StepForm
          contract={data}
          control={control}
          edit={edit}
          agent={agent}
          agentComission={agentComission}
        />
      </StepperProvider>
    </Paper>
  );
}

export default ContractFormViewer;
