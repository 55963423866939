/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/function-component-definition */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import { alpha, styled } from '@mui/material/styles';
// import Box from '@mui/material/Box';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
// import Toolbar from '@mui/material/Toolbar';
// import Typography from '@mui/material/Typography';
// import Paper from '@mui/material/Paper';
// import Checkbox from '@mui/material/Checkbox';
// import IconButton from '@mui/material/IconButton';
// import Tooltip from '@mui/material/Tooltip';
// import DeleteIcon from '@mui/icons-material/Delete';
// import FilterListIcon from '@mui/icons-material/FilterList';
// import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import moment from 'moment-timezone';
import DeleteIcon from '@material-ui/icons/Delete';
// import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListIcon from '@material-ui/icons/FilterList';
// import TableCell, { tableCellClasses } from '@mui/material/TableCell';

// import { withStyles, makeStyles, createStyles } from '@mui/styles';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
import MoreVertIcon from '@material-ui/icons/MoreVert';

// import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';
// import Dialog from '@mui/material/Dialog';
// import styled from 'styled-components';
// import Pagination from '@mui/material/Pagination';
import { Pagination } from '@material-ui/lab';
// import Stack from '@mui/material/Stack';
// import Grid from '@mui/material/Grid';
import {
  Grid,
  Box,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableHead,
  Toolbar,
  Typography,
  Paper,
  Checkbox,
  IconButton,
  Tooltip,
  Menu,
  MenuItem,
  Dialog,
  styled,
  TableCell,
  Fade,
} from '@material-ui/core';
import { withStyles, makeStyles, createStyles } from '@material-ui/core/styles';
// import { withStyles, makeStyles, createStyles } from '@mui/styles';
// import MoreVertIcon from '@mui/icons-material/MoreVert';

// import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';
// import Fade from '@mui/material/Fade';
import Select from 'react-select';
import { useHistory } from 'react-router-dom';
import Button from '../../button/MyButton';

import routing from '../../../service/routes/routing';

import SelectOptions from './SelectOptions';

const useStyles = makeStyles(theme =>
  createStyles({
    textNotification: {
      textAlign: 'center',
      fontFamily: 'Open Sans',
      fontSize: 14,
      fontWeight: 'bold',
      lineHeight: 'auto',
      color: '#707070',
      '&:hover': {
        backgroundColor: '#41b490',
        color: '#fff',
      },
    },
  }),
);

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#005D9F',
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
    color: theme.palette.common.white,
  },
  body: {
    color: '#717171',
    fontFamily: 'Open Sans',
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:hover': {
      backgroundColor: '#41b490',
      color: '#41b490',
    },
  },
}))(TableRow);

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark'
        ? 'rgba(57,75,89,.5)'
        : 'rgba(206,217,224,.5)',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#137cbd',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#106ba3',
  },
});

const options = [
  {
    label: '25',
    value: 25,
  },
  {
    label: '50',
    value: 50,
  },
  {
    label: '100',
    value: 100,
  },
];

const EnhancedTableHead = props => {
  const { onSelectAllClick, numSelected, rowCount, onRequestSort } = props;

  return (
    <TableHead>
      <TableRow>
        <StyledTableCell component="th">Produto</StyledTableCell>
        <StyledTableCell component="th">Tipo</StyledTableCell>
        <StyledTableCell component="th">Bandeira</StyledTableCell>
        <StyledTableCell component="th">Fornecedor</StyledTableCell>
        <StyledTableCell component="th">Criação</StyledTableCell>
        <StyledTableCell component="th">Status</StyledTableCell>
        <StyledTableCell padding="checkbox">
          <Checkbox
            // color="primary"
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </StyledTableCell>
        <StyledTableCell component="th" align="right">
          <div />
        </StyledTableCell>
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  // onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  // order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  // orderBy: PropTypes.string.isRequired,
  // rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = function (props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: '#fff',
          // (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable({
  products,
  totalPages,
  setPage,
  page,
  itens,
  setItens,
}) {
  const [selected, setSelected] = React.useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [cancelId, setCancelId] = useState();
  const [openCancel, setOpenCancel] = useState(false);
  const [menuOptions, setMenuOptions] = useState(null);

  const handleMenu = (row, event) => {
    setMenuOptions(row);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [rows, setRows] = useState([]);
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    const listProd = [];
    products.forEach((pd, i) => {
      listProd.push({
        id: i,
        pd: '',
        cat: '',
        tp: pd.issuer,
        fn: pd.brand,
        created:
          pd.created_at && moment.utc(pd.created_at).format('DD/MM/yyyy'),
        or: '',
        status: pd.status,
      });
      // listOrder.push({
      //   id: pd.id,
      //   nf: <div
      //     style={{ cursor: 'pointer', color: 'blue' }}
      //     onClick={() => window.open(pd.Invoice.gcs_pdf, '_blank')}
      //   >
      //     {pd.Invoice.invoice_number}

      //   </div>,
      //   pgto: moment
      //     .utc(pd.created_at)
      //     .format('DD/MM/yyyy'),
      //   rs: pd.Invoice.client_name,
      //   pd: pd.order_number,
      //   product: pd.Contract.product_type,
      //   vlr: `R$ ${pd.provision}`,
      //   status: pd.status,
      // });
    });
    setRows(listProd);
  }, [products]);

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const isSelected = id => selected.indexOf(id) !== -1;

  const cancelInf = id => {
    console.log(id);
    setCancelId(id);
    setAnchorEl(null);
    setOpenCancel(true);
  };

  return (
    <>
      <SelectOptions />
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size="medium"
            >
              <EnhancedTableHead
                numSelected={selected.length}
                onSelectAllClick={handleSelectAllClick}
                rowCount={rows.length}
                handleMenu={handleMenu}
              />
              <TableBody>
                {rows ? (
                  rows.length > 0 ? (
                    rows.map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <StyledTableRow tabIndex={-1} key={row.id}>
                          <StyledTableCell
                            component="th"
                            id={labelId}
                            scope="row"
                          >
                            {row.pd}
                          </StyledTableCell>
                          <StyledTableCell>{row.cat}</StyledTableCell>
                          <StyledTableCell>{row.fn}</StyledTableCell>
                          <StyledTableCell>{row.tp}</StyledTableCell>
                          <StyledTableCell>{row.created}</StyledTableCell>
                          {/* <StyledTableCell>{row.or}</StyledTableCell> */}
                          <StyledTableCell>{row.status}</StyledTableCell>
                          <StyledTableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              onClick={event => handleClick(event, row.id)}
                              checked={isItemSelected}
                              inputProps={{
                                'aria-labelledby': labelId,
                              }}
                            />
                          </StyledTableCell>
                          <StyledTableCell component="th" align="right">
                            <MoreVertIcon
                              onClick={handleMenu}
                              style={{ cursor: 'pointer' }}
                            />
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })
                  ) : (
                    <StyledTableRow>
                      <StyledTableCell colSpan={8} align="center">
                        Nenhum registro disponível
                      </StyledTableCell>
                    </StyledTableRow>
                  )
                ) : (
                  <StyledTableRow>
                    <StyledTableCell colSpan={8} align="center" />
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Menu
            id="menu-appbar"
            style={{ borderRadius: '25px' }}
            anchorEl={anchorEl}
            //  anchorOrigin={{
            //    vertical: 'top',
            //    horizontal: 'right',
            //  }}
            keepMounted
            //  transformOrigin={{
            //    vertical: 'top',
            //    horizontal: 'right',
            //  }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              className={classes.textNotification}
              onClick={handleClose}
            >
              Editar produto
            </MenuItem>
            <MenuItem
              className={classes.textNotification}
              onClick={handleClose}
            >
              Duplicar produto
            </MenuItem>

            <MenuItem
              className={classes.textNotification}
              onClick={() => cancelInf(menuOptions.id)}
            >
              suspender produto
            </MenuItem>
            <MenuItem
              className={classes.textNotification}
              onClick={handleClose}
            >
              Cancelar produto
            </MenuItem>
          </Menu>
        </Paper>
      </Box>
      <div
        className="col-sm-12"
        style={{
          marginTop: '2%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'rigth',
          justifyContent: 'space-between',
          marginRight: 15,
        }}
      >
        <h5
          style={{
            marginRight: 30,
            color: '#41B490',
            fontFamily: 'Open Sans',
            cursor: 'pointer',
          }}
          onClick={() => history.push(routing.newProduct)}
        >
          Acessar todas as categorias
        </h5>
        <h5
          style={{
            marginRight: 30,
            color: '#41B490',
            fontFamily: 'Open Sans',
          }}
        >
          <img
            id="refresh"
            src="./img/download.svg"
            alt="logo"
            style={{ height: 15, width: 15, marginRight: 15 }}
          />
          Planilha de produtos
        </h5>
      </div>
      <Grid
        container
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: 15,
        }}
        spacing={2}
        direction="row"
      >
        <Select
          className="basic-single"
          classNamePrefix="select"
          placeholder="Itens"
          value={itens}
          onChange={setItens}
          options={options}
        />
        <Pagination
          size="large"
          count={totalPages}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
        />
      </Grid>
      <Dialog
        onClose={() => setOpenCancel(!openCancel)}
        open={openCancel}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="simple-dialog-title">Pedido</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deseja realmente cancelar/suspender o produto? Esta ação não poderá
            ser desfeita!
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <h6
            style={{
              cursor: 'pointer',
              color: '#657588',
              fontWeight: 'bold',
              fontFamily: 'Open Sans',
              marginTop: 5,
            }}
            onClick={() => setOpenCancel(false)}
          >
            Cancelar
          </h6>
          <Button
            style={{ height: 40 }}
            // onClick={cancelOrder}
            color="primary"
            variant="contained"
            label="Sim"
          />
        </DialogActions>
      </Dialog>
    </>
  );
}
