import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/button/MyButton';
import styles from '../../../styles/Layout.module.css';

// eslint-disable-next-line react/prop-types
export default function Created({ setOpenModal, setActiveStep }) {
  const { t } = useTranslation();
  const handleNext = () => {
    setActiveStep(0);
  };
  return (
    <div
      className="col-sm-12"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <img
        src="/img/checkOk.svg"
        alt="ok"
        className="img-category-check"
        style={{ width: '15%', height: '15%' }}
      />
      <h4 className={styles.textSuccess} style={{ marginTop: 30 }}>
        {t('newOrder.otherOrder.label')}
      </h4>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: 30,
        }}
      >
        <Button
          style={{
            marginBottom: 20,
            marginRight: 20,
            height: 50,
            width: 180,
            // fontSize: 24,
            backgroundColor: '#657588',
          }}
          onClick={() => setOpenModal(false)}
          label={t('newOrder.otherOrder.btnNot')}
        />
        <Button
          style={{
            marginBottom: 20,
            marginRight: 20,
            height: 50,
            width: 180,
            // fontSize: 24,
          }}
          onClick={handleNext}
          label={t('newOrder.otherOrder.btnYes')}
        />
      </div>
    </div>
  );
}
