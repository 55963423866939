/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useRef, useContext } from 'react';
import Select from 'react-select';
import moment from 'moment-timezone';
import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  DatePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from 'date-fns/locale';
// import MailOutlineIcon from '@material-ui/icons/MailOutline';
// import LockIcon from '@material-ui/icons/Lock';
import Chip from '@material-ui/core/Chip';
import locale from 'date-fns/locale/pt-BR';
import { Delete, Publish } from '@material-ui/icons';
import CardsTable from './CardsTable';
import api from '../../service/axios/api';
import Loading from '../../components/loading/Loading';
import FlashContext from '../../components/contexts/FlashContext';

const optionsStatus = [
  {
    label: 'Aguardando pagamento',
    value: 'AwaitingPaymentRelease',
  },
  {
    label: 'Aguardando Conciliação',
    value: 'AwaitingPaymentConciliation',
  },
  {
    label: 'Aguardando consulta de cartão',
    value: 'AwaitingCardStatusCheck',
  },
  {
    label: 'Aguardando liberação do cartão',
    value: 'AwaitingCardRelease',
  },
  {
    label: 'Processado com erro',
    value: 'ProcessedWithErrors',
  },
  {
    label: 'Erro',
    value: 'Error',
  },
  {
    label: 'Cartão sem topUp',
    value: 'CardReleasedWithoutTopup',
  },
  {
    label: 'Fila',
    value: 'Queued',
  },
  {
    label: 'Pausado',
    value: 'Pause',
  },
  {
    label: 'Cancelado',
    value: 'Canceled',
  },
  {
    label: 'Todos',
    value: '',
  },
];

const optionsType = [
  {
    label: 'Saque/Compra',
    value: true,
  },
  {
    label: 'Compra',
    value: false,
  },
];

const optionsFlagCad = [
  {
    label: 'MasterCard',
    value: 'mastercard',
  },
  {
    label: 'Visa',
    value: 'visa',
  },
  {
    label: 'Cabal',
    value: 'cabal',
  },
];
const optionsFlag = [
  {
    label: 'JoyCard',
    value: 0,
  },
];

const customStyles2 = {
  control: provided => ({
    ...provided,
    width: 225,
    height: 40,
    borderRadius: 10,
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  option: provided => ({
    ...provided,
  }),
};

const customStyles = {
  control: provided => ({
    ...provided,
    width: 105,
    // width: 982,
    height: 40,
    borderRadius: 10,
    // backgroundColor: 'none',
    // boxShadow: 'none',
    // border: 'none',
    // fontWeight: 'bold',
    // fontFamily: 'Open Sans',
    // fontSize: 14,
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  option: provided => ({
    ...provided,
    // fontFamily: 'Open Sans',
    // fontSize: 24,
  }),
  // placeholder: () => ({
  //   // fontWeight: 'bold',
  //   // fontFamily: 'Open Sans',
  //   // fontSize: 24,
  //   color: '#9C9C9C',
  //   // paddingT: 10,
  //   // marginBottom: 30,
  // }),
};
const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: 10,
    margin: 0,
  },
  chip: {
    margin: 10,
  },
  textField: {
    // width: '90%',
    // // height: '40px',
    // marginLeft: 'auto',
    // marginRight: 'auto',
    // paddingTop: '2px',
    // paddingBottom: 0,
    // marginTop: 0,
    // fontWeight: 500,
    // backgroundColor: '#fff',
  },
  input: {
    backgroundColor: '#fff',
    marginTop: 0,
    // color: 'white',
  },
});

// const res = {
//   code: 'API_RES_PAGINATEDLIST',
//   message: 'Lista paginada de itens de pedidos do sistema',
//   pages: 9292,
//   total: 232280,
//   docs: [
//     {
//       status: 'Active',
//       proxy: '8034302005779296',
//       card_number: '5290530205846466',
//       brand: 'mastercard',
//       created_at: '2020-05-06T14:06:58.000Z',
//       issuer: 'acesso',
//       Individual: {
//         cpf: '07675718860',
//         name: 'LUIZ ANTONIO ALVES MOREIRA',
//         mothers_name: 'IRACEMA CORAÇARI MOREIRA',
//         birthdate: '1969-12-31',
//       },
//       updated_at: '2022-01-24T19:27:36.000Z',
//       deleted_at: null,
//       date_processing: null,
//       date_conciliation: null,
//     },
//   ],
// };
const customStylesEdit = {
  control: provided => ({
    ...provided,
    // width: 105,
    height: 40,
    borderRadius: 10,
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  option: provided => ({
    ...provided,
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
};

export default function CustomizedCards() {
  const classes = useStyles();
  const [openNew, setOpenNew] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [itens, setItens] = useState(10);
  const [loading, setLoading] = useState(false);
  const [loadingC, setLoadingC] = useState(false);
  const [selected, setSelected] = useState([]);
  const [action, setAction] = useState('');
  const [statusSelected, setStatusSelected] = useState({
    label: 'Todos',
    value: '',
  });
  const [flagSelected, setFlagSelected] = useState({
    label: 'Todos',
    value: '',
  });
  const [options, setOptions] = useState([
    {
      label: 'CPF',
      value: 'cpf',
    },
    {
      label: 'Nome',
      value: 'name',
    },
    {
      label: 'Número do cartão',
      value: 'cardNumber',
    },
    {
      label: 'proxy',
      value: 'proxy',
    },
    {
      label: 'status',
      value: 'status',
    },
  ]);
  const [searchType, setSearchType] = useState();
  const [msg, setMsg] = useState('Buscando arquivos...');
  const [type, setType] = useState({ label: 'Todos', value: '' });
  const [chipData, setChipData] = useState([]);
  const searchRef = useRef(null);

  const { notifyError, notifySuccess } = useContext(FlashContext);

  // PLANILHA
  const [typeSelected, setTypeSelected] = useState();
  const [cardsFlagSelected, setCardsFlagTypeSelected] = useState();
  const [selectedDate, handleDateChange] = useState(new Date());
  const [myFile, setMyfile] = useState();
  const [myFileFront, setMyfileFront] = useState();
  const [myFileBack, setMyfileBack] = useState();
  const [name, setName] = useState('');
  const fileFull = useRef(null);
  const fileFront = useRef(null);
  const fileBack = useRef(null);

  const searchData = extra => {
    const cancel = new AbortController();
    setLoading(true);
    setMsg('Buscando cartões...');
    api.cards
      .getTypeCards(cancel)
      .then(res => setData(res.retorno))
      .catch(err => console.log(err))
      .finally(() => setLoading(false));
    // api.banks
    //   .get(cancel)
    //   .then(res => {
    //     setData(res.retorno);
    //   })
    //   .catch(err => {
    //     console.log(err);
    //   })
    //   .finally(() => setLoading(false));
  };

  const handleSearch = () => {
    if (type.value === '') {
      notifyError('Selecione o tipo de busca');
    } else {
      searchData();
    }
  };
  useEffect(() => {
    searchData();
  }, [page, itens, statusSelected, flagSelected]);

  const addChip = btn => {
    if (searchType.value === 'created_at') {
      const orig = _.cloneDeep(options);
      setChipData([
        ...chipData,
        {
          key: chipData.length + 1,
          label: moment(selectedDate).format('DD/MM/YYYY'),
          by: searchType.label,
          byType: searchType.value,
          date: selectedDate,
        },
      ]);
      const myfind = orig.findIndex(e => e.value === searchType.value);
      orig.splice(myfind, 1);
      setSearchType(null);
      setOptions(orig);
      if (btn) {
        searchData({
          label: selectedDate,
          byType: searchType.value,
          date: selectedDate,
        });
      }
    } else {
      const orig = _.cloneDeep(options);
      setChipData([
        ...chipData,
        {
          key: chipData.length + 1,
          label: searchRef.current.value,
          by: searchType.label,
          byType: searchType.value,
        },
      ]);
      const find = orig.findIndex(e => e.value === searchType.value);

      orig.splice(find, 1);
      setSearchType(null);
      // selectRef.current.select.clearValue();
      setOptions(orig);
      if (btn) {
        searchData({
          label: searchRef.current.value,
          byType: searchType.value,
        });
      }
      searchRef.current.value = '';
    }
  };

  const internSearch = () => {
    if (searchType?.value !== '' && searchRef?.current?.value !== '') {
      addChip(true);
    } else {
      searchData();
    }
  };

  const teclaEnter = e => {
    if (e.key === 'Enter') {
      internSearch();
    }
  };

  const cleanFullFile = () => {
    setMyfile(null);
    fileFull.current.value = null;
  };
  const cleanFrontFile = () => {
    setMyfileFront(null);
    fileFront.current.value = null;
  };
  const cleanBackFile = () => {
    setMyfileBack(null);
    fileBack.current.value = null;
  };

  const createCard = e => {
    e.preventDefault();
    setMsg('Criando produto...');
    setLoading(true);
    if (name !== '' && myFile && myFileBack && myFileFront) {
      api.cards
        .newPersonalizedCard(name, myFile, myFileBack, myFileFront)
        .then(r => {
          searchData();
          setOpenNew(false);
        })
        .catch(err => console.log(err))
        .finally(() => setLoading(false));
    } else {
      notifyError('Complete todas as informações para continuar');
    }
  };

  const cleanAll = () => {
    fileBack.current.value = null;
    fileFront.current.value = null;
    fileFull.current.value = null;
    setName('');
    setMyfile();
    setMyfileBack();
    setMyfileFront();
    setOpenNew(false);
  };

  return (
    <>
      <Loading open={loading} msg={msg} />
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          sm={12}
          style={{ display: 'flex', justifyContent: 'start' }}
        >
          <h3>Cartões customizados</h3>
          <Button
            color="primary"
            style={{ marginLeft: 10 }}
            variant="contained"
            onClick={() => setOpenNew(true)}
          >
            +
          </Button>
        </Grid>
        <Grid item xs={12} sm={12}>
          <CardsTable
            docs={data}
            setDocs={setData}
            page={page}
            setPage={setPage}
            itens={itens}
            setItens={setItens}
            total={total}
            setSelected={setSelected}
            selected={selected}
          />
        </Grid>
      </Grid>
      <Dialog
        onClose={() => setOpenNew(!openNew)}
        open={openNew}
        fullWidth
        maxWidth="sm"
      >
        {/* <Loading open={loadingC} msg={`${action} cartões...`} /> */}
        <Loading open={loading} msg={msg} />
        <DialogTitle id="simple-dialog-title">Novo produto</DialogTitle>
        <DialogContent>
          <form onSubmit={createCard}>
            <Grid
              container
              spacing={1}
              style={{
                alignItems: 'center',
                justifyContent: 'center',
                padding: 5,
              }}
            >
              <Grid item xs={12} sm={12}>
                <Grid
                  container
                  // spacing={1}
                  style={{
                    paddingRight: 20,
                    paddingLeft: 20,
                  }}
                >
                  <Grid item xs={12} sm={12}>
                    <h6>Base</h6>
                    <Select
                      fullWidth
                      styles={customStylesEdit}
                      // value={cardFlag}
                      // onChange={setCardFlag}
                      classNamePrefix="select"
                      placeholder="Tipo"
                      isSearchable={false}
                      options={optionsFlag}
                      menuPortalTarget={document.body}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <h6>Nome:</h6>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <TextField
                        size="small"
                        variant="outlined"
                        fullWidth
                        value={name}
                        onChange={e => setName(e.target.value)}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <h6>Imagem frente e verso</h6>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <TextField
                        disabled
                        size="small"
                        variant="outlined"
                        fullWidth
                        value={myFile?.name || ''}
                      />
                      {myFile?.name ? (
                        <Delete
                          style={{
                            marginLeft: '10px',
                            marginRight: '10px',
                            cursor: 'pointer',
                          }}
                          onClick={cleanFullFile}
                        />
                      ) : (
                        <Publish
                          style={{
                            marginLeft: '10px',
                            marginRight: '10px',
                            cursor: 'pointer',
                          }}
                          onClick={() => fileFull.current.click()}
                        />
                      )}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Grid
                  container
                  spacing={1}
                  style={{
                    paddingRight: 20,
                    paddingLeft: 20,
                  }}
                >
                  <Grid item xs={12} sm={12}>
                    <h6>Imagem frente</h6>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <TextField
                        disabled
                        size="small"
                        variant="outlined"
                        fullWidth
                        value={myFileFront?.name || ''}
                        // defaultValue={dataEdit && dataEdit.clientId}
                      />
                      {myFileFront?.name ? (
                        <Delete
                          style={{
                            marginLeft: '10px',
                            marginRight: '10px',
                            cursor: 'pointer',
                          }}
                          onClick={cleanFrontFile}
                        />
                      ) : (
                        <Publish
                          style={{
                            marginLeft: '10px',
                            marginRight: '10px',
                            cursor: 'pointer',
                          }}
                          onClick={() => fileFront.current.click()}
                        />
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <h6>Imagem verso</h6>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <TextField
                        disabled
                        size="small"
                        variant="outlined"
                        fullWidth
                        value={myFileBack?.name || ''}
                        // defaultValue={dataEdit && dataEdit.clientId}
                      />
                      {myFileBack?.name ? (
                        <Delete
                          style={{
                            marginLeft: '10px',
                            marginRight: '10px',
                            cursor: 'pointer',
                          }}
                          onClick={cleanBackFile}
                        />
                      ) : (
                        <Publish
                          style={{
                            marginLeft: '10px',
                            marginRight: '10px',
                            cursor: 'pointer',
                          }}
                          onClick={() => fileBack.current.click()}
                        />
                      )}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* </form> */}
            <DialogActions>
              <Button onClick={cleanAll} color="secondary">
                Cancelar
              </Button>
              <Button
                // onClick={() => setOpen(false)}
                type="submit"
                color="primary"
                variant="contained"
                autoFocus
              >
                Criar
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
        {/* <DialogActions>
          <h6
            style={{
              cursor: 'pointer',
              color: '#657588',
              fontWeight: 'bold',
              fontFamily: 'Open Sans',
              marginTop: 5,
            }}
            onClick={() => setOpenNew(false)}
          >
            Cancelar
          </h6>
          <Button
            style={{ height: 40 }}
            type="submit"
            color="primary"
            variant="contained"
            // onClick={executeAction}
          >
            Salvar
          </Button>
        </DialogActions> */}
      </Dialog>
      <input
        ref={fileFull}
        type="file"
        style={{
          opacity: 0,
        }}
        accept="image/jpeg, image/png"
        onChange={() => setMyfile(fileFull.current.files[0])}
        className="form-control"
        id="inputNumSerie"
        placeholder="Arquivo DWG do projeto"
      />
      <input
        ref={fileFront}
        type="file"
        style={{
          opacity: 0,
        }}
        accept="image/jpeg, image/png"
        onChange={() => setMyfileFront(fileFront.current.files[0])}
        className="form-control"
        id="inputNumSerie"
        placeholder="Arquivo DWG do projeto"
      />
      <input
        ref={fileBack}
        type="file"
        style={{
          opacity: 0,
        }}
        accept="image/jpeg, image/png"
        onChange={() => setMyfileBack(fileBack.current.files[0])}
        className="form-control"
        id="inputNumSerie"
        placeholder="Arquivo DWG do projeto"
      />
    </>
  );
}
