import React from 'react';
import MuiInputLabel from '@material-ui/core/InputLabel';

import { useStylesLabel } from './styles';

function InputLabel({ ...others }) {
  const classes = useStylesLabel();
  return <MuiInputLabel classes={classes} {...others} />;
}

export default InputLabel;
