/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */

import React, { useState, useEffect, useRef, useContext } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import moment from 'moment-timezone';

import {
  // Tooltip,
  TablePagination,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Paper,
  IconButton,
  Typography,
  Box,
  Collapse,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from 'date-fns/locale';
// import GetAppIcon from '@material-ui/icons/GetApp';

// import { useExcelDownloder } from 'react-xls';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';

import Button from '@material-ui/core/Button';

import Select from 'react-select';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import Status from '../../service/translateStatus';
import Loading from '../../components/loading/Loading';

import FlashContext from '../../components/contexts/FlashContext';

import api from '../../service/axios/api';

function createData(
  dateCreated,
  dateEnd,
  acessoStatus,
  cardSucess,
  cardReject,
  requestCards,
  sendFile,
  urlSendFile,
  fileReturn,
  urlReturnFile,
  dataCriation,
  value,
  items,
) {
  // const density = population / size;
  return {
    dateCreated,
    dateEnd,
    acessoStatus,
    cardSucess,
    cardReject,
    requestCards,
    sendFile,
    urlSendFile,
    fileReturn,
    urlReturnFile,
    dataCriation,
    value,
    items,
  };
}

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  table: {
    minWidth: 1100,
  },
});

const StyledTableCell = withStyles(() => ({
  head: {
    // backgroundColor: theme.palette.common.black,
    // color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const createDownload = url => {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'data:text/plain;charset=utf-8,arquivo.txt'); // or any other extension
  document.body.appendChild(link);
  link.click();
};

const translateStatusProc = proc => {
  switch (proc) {
    case '000':
      return 'Sucesso';
    case '100':
      return 'Cartão não existente';
    case '102':
      return 'Cartão não permitido';
    case '103':
      return 'Cartão em prevenção';
    case '200':
      return 'Erro na identificação';
    case '210':
      return 'Dado de campo inconsistente';
    case '909':
      return 'Erro genérico';
    default:
      return proc;
  }
};

const translateStatusCard = card => {
  switch (card) {
    case '00':
      return 'Ativo';
    case '01':
      return 'Bloqueado por tentativa de senha errada';
    case '02':
      return 'Inativo';
    case '03':
      return 'Cartão expirado';
    case '04':
      return 'Perdido';
    case '05':
      return 'Roubado';
    case '06':
      return 'Cancelado a pedido do cliente';
    case '07':
      return 'Cancelado a pedido do emissor';
    case '08':
      return 'Uso fraudulento';
    case '09':
      return 'Dormente';
    case '60':
      return 'Preventivo de fraude';
    case '64':
      return 'Definitivo de fraude';
    case '66':
      return 'Cancelado';
    case '99':
      return 'Não informado/não consultado';
    default:
      return card;
  }
};

function Row(props) {
  const { t } = useTranslation();
  const { row, idx } = props;
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <StyledTableRow
        key={idx}
        style={{ backgroundColor: idx % 2 !== 0 ? '#fff' : '' }}
      >
        <StyledTableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
            disabled={!row.sendFile}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </StyledTableCell>
        <StyledTableCell>
          <div>
            Qtd.: {row.requestCards}
            <br />
            Sucesso: {row.cardSucess}
            <br />
            Rejeitados: {row.cardReject}
            <br />
          </div>
        </StyledTableCell>
        <StyledTableCell
          style={{ cursor: 'pointer' }}
          onClick={() => createDownload(row.urlSendFile)}
        >
          {row.sendFile}
        </StyledTableCell>
        <StyledTableCell
          style={{ cursor: 'pointer' }}
          onClick={() => createDownload(row.urlReturnFile)}
        >
          {row.fileReturn}
        </StyledTableCell>
        <StyledTableCell>{Status(row.acessoStatus, t)}</StyledTableCell>
        <StyledTableCell>{row.value}</StyledTableCell>
        <StyledTableCell>
          <div>
            Criação: {row.dataCriation}
            <br />
            Inicio: {row.dateCreated}
            <br />
            Fim: {row.dateEnd}
            <br />
          </div>
        </StyledTableCell>
      </StyledTableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Itens do Lote
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    {/* <StyledTableCell>Acesso ID</StyledTableCell> */}
                    {/* <StyledTableCell>Card Status</StyledTableCell> */}
                    <StyledTableCell>Proxy</StyledTableCell>
                    <StyledTableCell>Valor</StyledTableCell>
                    <StyledTableCell>Status Processo</StyledTableCell>
                    <StyledTableCell align="right">
                      Última atualização
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row?.items?.map(i => (
                    <StyledTableRow key={i.id}>
                      {/* <StyledTableCell component="th" scope="row">
                        {i.acesso_topup_id}
                      </StyledTableCell> */}
                      {/* <StyledTableCell>
                        {translateStatusCard(i.card_status)}
                      </StyledTableCell> */}
                      <StyledTableCell>{i.proxy}</StyledTableCell>
                      <StyledTableCell>{i.amount}</StyledTableCell>
                      <StyledTableCell>
                        {translateStatusProc(i.processing_status)}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {moment(i.updated_at).format('DD/MM/YYYY HH:mm')}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

// eslint-disable-next-line no-unused-vars
export default function CardsTable({
  docs,
  page,
  setPage,
  itens,
  setItens,
  total,
}) {
  const classes = useStyles();

  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (docs) {
      const list = [];
      docs.forEach(d => {
        list.push(
          createData(
            d.card_release_topup?.acesso_date_created,
            d.card_release_topup?.acesso_date_ended,
            d.card_release_topup?.status,
            d.card_release_topup?.success_count,
            d.rejected_cards,
            d.request_cards,
            d.card_release_topup?.acesso_file ||
              d.file_send.substring(
                d.file_send.lastIndexOf('/') + 1,
                d.file_send.length,
              ),
            d.file_send,
            d.file_return &&
              d.file_return.substring(
                d.file_return.lastIndexOf('/') + 1,
                d.file_return.length,
              ),
            d.file_return,
            moment(d.created_at).format('DD/MM/YYYY hh:mm'),
            d.request_amount,
            d.topup_items,
          ),
        );
      });
      setRows(list);
    }
  }, [docs]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setItens(event.target.value);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              {/* <StyledTableCell>Inicio</StyledTableCell> */}
              {/* <StyledTableCell>Fim</StyledTableCell> */}
              <StyledTableCell />
              <StyledTableCell>Cartões</StyledTableCell>
              <StyledTableCell>Envio</StyledTableCell>
              <StyledTableCell>Resposta</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Valor</StyledTableCell>
              <StyledTableCell>Data</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row, i) => (
              <Row key={row.name} row={row} idx={i} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100, 1000, 50000]}
        component="div"
        count={total}
        rowsPerPage={itens}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
