/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */

import React, { useState, useEffect, useRef, useContext } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import moment from 'moment-timezone';

import {
  // Tooltip,
  TablePagination,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Paper,
  styled,
  Checkbox,
} from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from 'date-fns/locale';
// import GetAppIcon from '@material-ui/icons/GetApp';

// import { useExcelDownloder } from 'react-xls';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';

import Button from '@material-ui/core/Button';

import Select from 'react-select';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import Status from '../../service/translateStatus';
import Loading from '../../components/loading/Loading';

import FlashContext from '../../components/contexts/FlashContext';

import api from '../../service/axios/api';

function createData(
  id,
  acessoStatus,
  origin,
  status,
  cpf,
  name,
  proxy,
  cardNumber,
  // value,
) {
  // const density = population / size;
  return {
    id,
    acessoStatus,
    origin,
    status,
    cpf,
    name,
    proxy,
    cardNumber,
    // value,
  };
}

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  table: {
    minWidth: 1100,
  },
});

const StyledTableCell = withStyles(() => ({
  head: {
    // backgroundColor: theme.palette.common.black,
    // color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark'
        ? 'rgba(57,75,89,.5)'
        : 'rgba(206,217,224,.5)',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#137cbd',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#106ba3',
  },
});

// eslint-disable-next-line no-unused-vars
export default function CardsTable({
  docs,
  page,
  setPage,
  itens,
  setItens,
  total,
  selected,
  setSelected,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  // const [selected, setSelected] = React.useState([]);
  const isSelected = id => selected.indexOf(id) !== -1;

  useEffect(() => {
    if (docs) {
      const list = [];
      // status,
      // cpf,
      // name,
      // proxy,
      // cardNumber,
      docs.forEach(d => {
        list.push(
          createData(
            d.id,
            d.acesso_status,
            d.request_origin,
            d.status,
            d.cpf,
            d.name,
            d.proxy,
            d.card_number,
          ),
        );
      });
      setRows(list);
    }
  }, [docs]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setItens(event.target.value);
  };

  // const createDownload = url => {
  //   const link = document.createElement('a');
  //   link.href = url;
  //   link.setAttribute('download', 'data:text/plain;charset=utf-8,arquivo.txt'); // or any other extension
  //   document.body.appendChild(link);
  //   link.click();
  // };
  const disableCheckbox = e => {
    let myret = true;
    switch (e) {
      case 'Error':
        myret = false;
        break;
      case 'Queued':
        myret = false;
        break;
      case 'AwaitingCardStatusCheck':
        myret = false;
        break;
      default:
        myret = true;
        break;
    }
    return myret;
  };
  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => {
        if (!disableCheckbox(n.status)) {
          return n.id;
        }
        return null;
      });
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Origem</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Status-Acesso</StyledTableCell>
              <StyledTableCell>CPF</StyledTableCell>
              <StyledTableCell>Nome</StyledTableCell>
              <StyledTableCell>Proxy</StyledTableCell>
              <StyledTableCell>Número do cartão</StyledTableCell>
              <StyledTableCell padding="checkbox">
                <Checkbox
                  // color="primary"
                  checkedIcon={<BpCheckedIcon />}
                  icon={<BpIcon />}
                  indeterminate={
                    selected.length > 0 && selected.length < rows.length
                  }
                  checked={rows.length > 0 && selected.length === rows.length}
                  onChange={handleSelectAllClick}
                  inputProps={{
                    'aria-label': 'select all desserts',
                  }}
                />
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => {
              const isItemSelected = isSelected(row.id);
              const labelId = `enhanced-table-checkbox-${i}`;

              return (
                // eslint-disable-next-line react/no-array-index-key
                <StyledTableRow key={i}>
                  <StyledTableCell>{row.origin}</StyledTableCell>
                  <StyledTableCell>{Status(row.status, t)}</StyledTableCell>
                  <StyledTableCell>{row.acessoStatus}</StyledTableCell>
                  <StyledTableCell>{row.cpf}</StyledTableCell>
                  <StyledTableCell>{row.name}</StyledTableCell>
                  <StyledTableCell>{row.proxy}</StyledTableCell>
                  <StyledTableCell>{row.cardNumber}</StyledTableCell>
                  <StyledTableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      disabled={disableCheckbox(row.status)}
                      // AwaitingCardStatusCheck
                      onClick={event => handleClick(event, row.id)}
                      checked={isItemSelected}
                      inputProps={{
                        'aria-labelledby': labelId,
                      }}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100, 1000, 50000]}
        component="div"
        count={total}
        rowsPerPage={itens}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
