/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
// import Button from '@mui/material/Button';
import React from 'react';
import './BtnCss.css';

// eslint-disable-next-line react/function-component-definition
export default function MyButton(props) {
  const { label } = props;
  return (
    <div className="disabled">
      <button {...props} type="button" className="rectangle-7">
        {label}
      </button>
    </div>
  );
}
