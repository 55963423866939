/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';

// import MUIRichTextEditor from 'mui-rte';

import { Grid, TextField, Divider, Dialog } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import styles from '../../../styles/Layout.module.css';

import routing from '../../../service/routes/routing';
import Button from '../../../components/button/MyButton';
import EmailSendSuccess from './EmailSendSuccess';

const useStyles = makeStyles(() =>
  createStyles({
    inputLabel: {
      backgroundColor: '#F2F1F1',
      color: '#41b490',
      fontWeight: 'bold',
    },
    myTextField: {
      backgroundColor: '#none',
      boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
      border: 'none',
      color: '#41b490',
      fontWeight: 'bold',
      fontFamily: 'Open Sans',
    },
    text: {
      backgroundColor: '#F2F1F1',
      fontFamily: 'Open Sans',
      fontWeight: 'bold',
      color: '#41b490',
    },
  }),
);

export default function EmailTextNotification() {
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);
  const classes = useStyles();
  const refAttachment = useRef(null);

  // const save = (data) => {
  //   console.log(data);
  // };
  return (
    <>
      {/* <Loading open={loading} msg="Buscando produtos..." /> */}
      <div className="col-sm-12" style={{ marginTop: 50 }}>
        <p
          // style={{ cursor: 'pointer' }}
          className={styles.textMenu}
          onKeyDown={() => history.push(routing.dashboard)}
          onClick={() => history.push(routing.dashboard)}
        >
          Menu Inicial
        </p>
        <div
          className="col-sm-12"
          // style={{
          //   display: 'flex', flexDirection: 'row', alignItems: 'center',
          // }}
        >
          <h4 className={styles.textTitle}>Nova notificação</h4>
        </div>
        <Grid
          container
          spacing={1}
          style={{
            border: '2px solid #c5c5c5',
            borderRadius: 10,
            justifyContent: 'center',
            alignItems: 'center',
            padding: 40,
          }}
        >
          <Grid item sm={2}>
            <h6 className={styles.textMailLabels}>Assunto</h6>
          </Grid>
          <Grid item xs={10}>
            <TextField
              className={classes.text}
              fullWidth
              variant="outlined"
              size="small"
              InputProps={{
                classes: {
                  notchedOutline: classes.myTextField,
                  input: classes.text,
                },
              }}
              // value={subject}
              // onChange={e => setSubject(e.target.value)}
            />
          </Grid>
          <Grid item sm={2} style={{ marginTop: 10 }}>
            <h6 className={styles.textMailLabels}>Destinatários</h6>
          </Grid>
          <Grid item sm={10} style={{ marginTop: 10 }}>
            <TextField
              className={classes.text}
              fullWidth
              variant="outlined"
              size="small"
              InputProps={{
                classes: {
                  notchedOutline: classes.myTextField,
                  input: classes.text,
                },
              }}
              // value={dest.title}
              // onChange={e => setDest(e.target.value)}
            />
          </Grid>

          <Grid
            container
            spacing={1}
            style={{
              justifyContent: 'end',
              alignItems: 'center',
              marginTop: 10,
            }}
          >
            <Grid
              className={styles.labelattachment}
              item
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                marginRight: 30,
              }}
              onClick={() => refAttachment.current.click()}
            >
              <img
                src="./img/attachment.svg"
                alt="logo"
                style={{ width: '15%', height: '15%' }}
              />
              Anexar arquivo
            </Grid>
            <Grid className={styles.labelattachment} item>
              Enviar para um grupo de clientes
            </Grid>
          </Grid>
          <input
            ref={refAttachment}
            type="file"
            style={{
              opacity: 0,
            }}
            // accept="media_type"
            // onChange={() => setFileName(refAttachment.current.files[0].name)}
            className="form-control"
            id="inputNumSerie"
            placeholder="Arquivo DWG do projeto"
          />
          <Grid item xs={12}>
            <Divider />
            {/* <MUIRichTextEditor
              label="Digite a notificação aqui..."
              onSave={save}
              inlineToolbar
            /> */}
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: 30,
          }}
        >
          <Grid item xs={6}>
            <Button
              style={{
                height: 40,
                width: 180,
                fontSize: 14,
                backgroundColor: '#005D9F',
              }}
              // onClick={() => setOpenModal(false)}
              label="Salvar como Modelo"
            />
          </Grid>
          <Grid
            container
            xs={6}
            style={{
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'center',
            }}
          >
            <Grid>
              <h5
                style={{ marginRight: 70, cursor: 'pointer' }}
                onClick={() => history.push(routing.newNotifications)}
              >
                Cancelar
              </h5>
            </Grid>
            <Grid>
              <Button
                style={{
                  height: 40,
                  width: 120,
                  fontSize: 14,
                  backgroundColor: '#005D9F',
                  marginRight: 15,
                }}
                // onClick={() => setOpenModal(false)}
                label="Agendar Envio"
              />
            </Grid>
            <Grid>
              <Button
                style={{
                  height: 40,
                  width: 80,
                  fontSize: 14,
                }}
                onClick={() => setOpenModal(true)}
                label="Enviar"
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Dialog
        onClose={() => setOpenModal(false)}
        open={openModal}
        // width={1192}
        // height={776}
        fullWidth
        // maxWidth="1192"
      >
        <EmailSendSuccess />
        {/* <SubjectNotification setOpenModal={setOpenModal} /> */}
      </Dialog>
    </>
  );
}
