/* eslint-disable react/prop-types */
import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

// import Proposal from '@/types/Proposal'

function ProposalModal({ open, onClose, proposal }) {
  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle>Resumo</DialogTitle>
      <Box mt={0} m={6}>
        <Grid container spacing={5}>
          <Grid item lg={12} sm={12} xs={12}>
            <Typography variant="subtitle1" color="initial">
              CAMPANHA: {proposal?.campaign}
            </Typography>
            {/* <Typography variant="body2" color="initial">
              {proposal.companyName}
            </Typography> */}
          </Grid>
          <Grid item lg={4} sm={6} xs={12}>
            <Typography variant="subtitle1" color="initial">
              Razão Social:
            </Typography>
            <Typography variant="body2" color="initial">
              {proposal.companyName}
            </Typography>
          </Grid>
          <Grid item lg={4} sm={6} xs={12}>
            <Typography variant="subtitle1" color="initial">
              Nome Fantasia:
            </Typography>
            <Typography variant="body2" color="initial">
              {proposal.tradingName}
            </Typography>
          </Grid>
          <Grid item lg={4} sm={6} xs={12}>
            <Typography variant="subtitle1" color="initial">
              CNPJ:
            </Typography>
            <Typography variant="body2" color="initial">
              {proposal.cnpj}
            </Typography>
          </Grid>
          <Grid item lg={4} sm={6} xs={12}>
            <Typography variant="subtitle1" color="initial">
              Email:
            </Typography>
            <Typography variant="body2" color="initial">
              {proposal.email}
            </Typography>
          </Grid>
          <Grid item lg={4} sm={6} xs={12}>
            <Typography variant="subtitle1" color="initial">
              Telefone:
            </Typography>
            <Typography variant="body2" color="initial">
              {proposal.phone}
            </Typography>
          </Grid>
          <Grid item lg={4} sm={6} xs={12}>
            <Typography variant="subtitle1" color="initial">
              Celular:
            </Typography>
            <Typography variant="body2" color="initial">
              {proposal.mobile}
            </Typography>
          </Grid>
          <Grid item lg={12} xs={12}>
            <Typography variant="subtitle1" color="initial">
              Comentários:
            </Typography>
            <Typography variant="body2" color="initial">
              {proposal.comments}
            </Typography>
          </Grid>
          {proposal.contacts.map((c, i) => (
            // <Container className={classes.content}>
            <>
              <Typography variant="h6" component="h3" gutterBottom>
                Contato {proposal.contacts.length > 0 ? i + 1 : ''}
              </Typography>
              <Grid container spacing={5}>
                <Grid item lg={4} sm={6} xs={12}>
                  <Typography variant="subtitle1" color="initial">
                    Nome:
                  </Typography>
                  <Typography variant="body2" color="initial">
                    {c.name}
                  </Typography>
                </Grid>
                <Grid item lg={4} sm={6} xs={12}>
                  <Typography variant="subtitle1" color="initial">
                    CPF:
                  </Typography>
                  <Typography variant="body2" color="initial">
                    {c.cpf}
                  </Typography>
                </Grid>
                <Grid item lg={4} sm={6} xs={12}>
                  <Typography variant="subtitle1" color="initial">
                    Cargo:
                  </Typography>
                  <Typography variant="body2" color="initial">
                    {c.role}
                  </Typography>
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                  <Typography variant="subtitle1" color="initial">
                    Área de trabalho:
                  </Typography>
                  <Typography variant="body2" color="initial">
                    {c.department}
                  </Typography>
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                  <Typography variant="subtitle1" color="initial">
                    Celular:
                  </Typography>
                  <Typography variant="body2" color="initial">
                    {c.mobile}
                  </Typography>
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                  <Typography variant="subtitle1" color="initial">
                    Telefone:
                  </Typography>
                  <Typography variant="body2" color="initial">
                    {c.phone}
                  </Typography>
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                  <Typography variant="subtitle1" color="initial">
                    Email:
                  </Typography>
                  <Typography variant="body2" color="initial">
                    {c.email}
                  </Typography>
                </Grid>
              </Grid>
            </>
            // </Container>
          ))}
        </Grid>
        {/* <Grid container spacing={3}>
          <Grid item lg={4} sm={6} xs={12}>
            <Typography variant="subtitle1" color="initial">
              Razão Social:
            </Typography>
            <Typography variant="body2" color="initial">
              {proposal?.company.legalName || '-'}
            </Typography>
          </Grid>
          <Grid item lg={4} sm={6} xs={12}>
            <Typography variant="subtitle1" color="initial">
              Product:
            </Typography>
            <Typography variant="body2" color="initial">
              {proposal?.productDetails?.product || '-'}
            </Typography>
          </Grid>
          <Grid item lg={4} sm={6} xs={12}>
            <Typography variant="subtitle1" color="initial">
              Nome Completo:
            </Typography>
            <Typography variant="body2" color="initial">
              {proposal?.company.admin.fullName || '-'}
            </Typography>
          </Grid>
          <Grid item lg={4} sm={6} xs={12}>
            <Typography variant="subtitle1" color="initial">
              Taxa ADM:
            </Typography>
            <Typography variant="body2" color="initial">
              -
            </Typography>
          </Grid>
          <Grid item lg={4} sm={6} xs={12}>
            <Typography variant="subtitle1" color="initial">
              Email:
            </Typography>
            <Typography variant="body2" color="initial">
              {proposal?.company.admin.email || '-'}
            </Typography>
          </Grid>
          <Grid item lg={4} sm={6} xs={12}>
            <Typography variant="subtitle1" color="initial">
              Taxa ADM:
            </Typography>
            <Typography variant="body2" color="initial">
              {proposal?.productDetails.campaign.isAdministrationRate
                ? proposal?.productDetails.campaign.administrationRate
                : proposal?.productDetails.campaign.administrationFee}
            </Typography>
          </Grid>
          <Grid item lg={4} sm={6} xs={12}>
            <Typography variant="subtitle1" color="initial">
              Celular
            </Typography>
            <Typography variant="body2" color="initial">
              {proposal?.company.admin.mobile || '-'}
            </Typography>
          </Grid>
          <Grid item lg={4} sm={6} xs={12}>
            <Typography variant="subtitle1" color="initial">
              Celular
            </Typography>
            <Typography variant="body2" color="initial">
              {proposal?.company.admin.mobile || '-'}
            </Typography>
          </Grid>
          <Grid item lg={4} sm={6} xs={12}>
            <Typography variant="subtitle1" color="initial">
              Premiação Anual Estimada:
            </Typography>
            <Typography variant="body2" color="initial">
              -
            </Typography>
          </Grid>
          <Grid item lg={4} sm={6} xs={12}>
            <Typography variant="subtitle1" color="initial">
              Preço do Cartão:
            </Typography>
            <Typography variant="body2" color="initial">
              {proposal?.productDetails.issuer || '-'}
            </Typography>
          </Grid>
        </Grid> */}
      </Box>
    </Dialog>
  );
}

export default ProposalModal;
