/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState, useContext, useEffect } from 'react';
// import CssBaseline from '@mui/material/CssBaseline';

// import Box from '@mui/material/Box';
// import Container from '@mui/material/Container';
// // import Paper from '@mui/material/Paper';
// import Stepper from '@mui/material/Stepper';
// import Step from '@mui/material/Step';
// import StepLabel from '@mui/material/StepLabel';

import { StepLabel, Step, Stepper, Container, Box } from '@material-ui/core';
// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
// import { } from '@mui/material/styles';
// import { makeStyles, createStyles } from '@mui/styles';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import UserClass from './UserClass';
import UserType from './UserType';
import UserData from './UserData';
import FlashContext from '../../../components/contexts/FlashContext';
import Loading from '../../../components/loading/Loading';
import styles from '../../../styles/Layout.module.css';
// import SelectProd from './UserClass1';

import UserPermissions from './UserPermissions';
// import CardLimits from './CardLimits';
import Created from './Created';

import MyButton from '../../../components/button/MyButton';
import api from '../../../service/axios/api';

const useStyles = makeStyles(() =>
  createStyles({
    icon: {
      fill: '#41B490',
    },
    text: {
      fill: 'white',
    },
  }),
);
// import './checkout.css';

const steps = ['UserClass', 'UserType', 'UserData', 'UserPermissions'];

// eslint-disable-next-line react/prop-types
export default function Checkout({ setOpenModal }) {
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  const { notifyError } = useContext(FlashContext);

  const [userClass, setUserClass] = useState();
  const [userType, setUserType] = useState();

  // USER DATA
  const [name, setName] = useState();
  const [cpf, setCpf] = useState();
  const [email, setEmail] = useState();
  const [cargo, setCargo] = useState();
  const [phone, setPhone] = useState();
  const [cellPhone, setCellPhone] = useState();
  const [twoFaType, setTwoFaType] = useState('2');

  // USER PERMISSIONS

  const [permission, setPermission] = useState({
    consulta: false,
    liberar: false,
    pagar: false,
    inserir: false,
    vincular: false,
    status: false,
    produto: false,
    documentos: false,
  });

  useEffect(() => {
    if (userType === 'adm') {
      steps.splice(3, 1);
    }
    if (userType === 'operador') {
      if (steps.length === 3) {
        steps.push('UserPermissions');
      }
    }
  }, [userType]);

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <UserClass selected={userClass} setSelected={setUserClass} />;
      case 1:
        return (
          <UserType
            selected={userType}
            setSelected={setUserType}
            userClass={userClass}
          />
        );
      case 2:
        return (
          <UserData
            name={name}
            setName={setName}
            cpf={cpf}
            setCpf={setCpf}
            setTwoFaType={setTwoFaType}
            twoFaType={twoFaType}
            email={email}
            setEmail={setEmail}
            cargo={cargo}
            setCargo={setCargo}
            phone={phone}
            setPhone={setPhone}
            cellPhone={cellPhone}
            setCellPhone={setCellPhone}
          />
        );
      case 3:
        return <UserPermissions state={permission} setState={setPermission} />;
      default:
        throw new Error('Unknown step');
    }
  }

  const createUser = () => {
    setLoading(true);
    api.user
      .create(
        twoFaType,
        name,
        email,
        cpf,
        phone.replace(/[^0-9]+/g, ''),
        cellPhone.replace(/[^0-9]+/g, ''),
        userType === 'adm' ? (userClass === 'element' ? 1 : 9) : 3,
        userType === 'adm'
          ? userClass === 'element'
            ? '111111111111111111111111111'
            : '111111011101111000010111001'
          : '110111011101111000000111000',
      )
      .then(res => {
        console.log('return cadastro', res);
        setActiveStep(activeStep + 1);
        setLoading(false);
      })
      .catch(err => {
        console.log('error cadastro', err);
        setLoading(false);
        if (err?.problems?.length > 0) {
          err?.problems.forEach(p => {
            notifyError(p.msg);
          });
        } else if (err.params) {
          if (err.params[0].errors) {
            if (err.params[0].errors[0].message === 'email must be unique') {
              notifyError('Email já cadastrado. Utilize outro.');
            }
          } else {
            notifyError(err.message);
          }
        } else {
          notifyError(err.message);
        }
      });
  };

  const handleNext = () => {
    switch (activeStep) {
      case 0:
        if (!userClass) {
          notifyError('Escolha um tipo de usuário para continuar.');
        } else {
          setActiveStep(activeStep + 1);
        }
        break;
      case 1:
        if (!userType) {
          notifyError('Escolha um tipo de usuário para continuar.');
        } else {
          setActiveStep(activeStep + 1);
        }
        break;
      case 2:
        if (userType === 'adm') {
          createUser();
        } else {
          setActiveStep(activeStep + 1);
        }

        break;
      case 3:
        createUser();
        // setActiveStep(activeStep + 1);
        break;
      default:
        setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep === 0) {
      setOpenModal(false);
    } else {
      setActiveStep(activeStep - 1);
    }
  };

  return (
    <>
      <Loading open={loading} msg="Criando usuário" />
      {/* <Container component="main" maxWidth="sm" sx={{ mb: 4 }}> */}
      <Container component="main">
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {/* <p className="new-category">Nova categoria de produto</p> */}
          {/* sx={{ backgroundColor: '#41B490' }} */}
          <Stepper activeStep={activeStep} style={{ width: 305 }}>
            {steps.map(
              label =>
                activeStep < steps.length && (
                  <Step key={label}>
                    <StepLabel
                      StepIconProps={{
                        classes: {
                          active: classes.icon,
                          text: classes.text,
                          completed: classes.icon,
                        },
                      }}
                    />
                  </Step>
                ),
            )}
          </Stepper>
          <h6 className="x" onClick={() => setOpenModal(false)}>
            X
          </h6>
        </div>
        {activeStep < steps.length && (
          <h6 className={styles.textCheckoutInform}>Incluir usuário</h6>
        )}
        <>
          {activeStep === steps.length ? (
            <>
              <Created setOpenModal={setOpenModal} />
            </>
          ) : (
            <>
              {getStepContent(activeStep)}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginTop: 50,
                  marginBottom: 50,
                }}
              >
                <h6
                  // className="text-exit"
                  style={{ cursor: 'pointer' }}
                  onClick={() => setOpenModal(false)}
                >
                  Sair
                </h6>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {/* {activeStep !== 0 && ( */}
                  {/* <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                  Voltar
                </Button> */}
                  {/* // )} */}
                  {activeStep > 0 && (
                    <MyButton
                      style={{
                        width: 120,
                        height: 40,
                        borderRadius: 10,
                        backgroundColor: '#657588',
                      }}
                      onClick={handleBack}
                      label="Voltar"
                    />
                  )}

                  <MyButton
                    style={{
                      width: 120,
                      height: 40,
                      borderRadius: 10,
                      marginLeft: 30,
                    }}
                    onClick={handleNext}
                    label="Continuar"
                  />
                </Box>
              </div>
            </>
          )}
        </>
        {/* </Paper> */}
      </Container>
    </>
  );
}
