export default function Status(status, t) {
  switch (status) {
    case 'Active':
      return t('status.Active');
    case 'AutoProcessing':
      return t('status.AutoProcessing');
    case 'AwaitingBlock':
      return t('status.AwaitingBlock');
    case 'AwaitingCancelation':
      return t('status.AwaitingCancelation');
    case 'AwaitingCanceledInvoice':
      return t('status.AwaitingCanceledInvoice');
    case 'AwaitingCard':
      return t('status.AwaitingCard');
    case 'AwaitingCardRelease':
      return t('status.AwaitingCardRelease');
    case 'AwaitingConciliation':
      return t('status.AwaitingConciliation');
    case 'AwaitingInvoice':
      return t('status.AwaitingInvoice');
    case 'AwaitingIssuerLink':
      return t('status.AwaitingIssuerLink');
    case 'AwaitingIssuerLinkResponse':
      return t('status.AwaitingIssuerLinkResponse');
    case 'AwaitingPayment':
      return t('status.AwaitingPayment');
    case 'AwaitingBankScheduling':
      return 'Aguardando agendamento bancário';
    case 'AwaitingProcessing':
      return t('status.AwaitingProcessing');
    case 'AwaitingRelease':
      return t('status.AwaitingRelease');
    case 'AwaitingSettlement':
      return t('status.AwaitingSettlement');
    case 'AwaitingShipping':
      return t('status.AwaitingShipping');
    case 'AwaitingUnblock':
      return t('status.AwaitingUnblock');
    case 'Blocked':
      return t('status.Blocked');
    case 'Canceled':
      return t('status.Canceled');
    case 'Card':
      return t('status.Card');
    case 'Check':
      return t('status.Check');
    case 'Created':
      return t('status.Created');
    case 'Error':
      return t('status.Error');
    case 'Fraud':
      return t('status.Fraud');
    case 'InStock':
      return t('status.InStock');
    case 'InvoiceReceived':
      return t('status.InvoiceReceived');
    case 'Paid':
      return t('status.Paid');
    case 'PayableCommercialAgent':
      return t('status.PayableCommercialAgent');
    case 'Payable':
      return t('status.Payable');
    case 'Processed':
      return t('status.Processed');
    case 'ProcessedWithErrors':
      return t('status.ProcessedWithErrors');
    case 'Processing':
      return t('status.Processing');
    case 'Queued':
      return t('status.Queued');
    case 'Received':
      return t('status.Received');
    case 'Rejected':
      return t('status.Rejected');
    case 'Settled':
      return t('status.Settled');
    case 'Transfer':
      return t('status.Transfer');
    case 'Voucher':
      return t('status.Voucher');
    case 'Pause':
      return t('status.Pause');
    case 'PENDING':
      return 'Aguardando Pagamento';
    case 'RECEIVED':
      return 'Pago';
    case 'CONFIRMED':
      return 'Pagamento confirmado';
    case 'OVERDUE':
      return 'Vencido';
    case 'REFUNDED':
      return 'Estornada';
    case 'REFUND_REQUESTED':
      return 'Estorno Solicitado';
    case 'REFUND_IN_PROGRESS':
      return 'Estorno em processamento';
    case 'CHARGEBACK_REQUESTED':
      return 'CHARGEBACK_REQUESTED';
    case 'CHARGEBACK_DISPUTE':
      return 'Em disputa de chargeback';
    case 'AWAITING_CHARGEBACK_REVERSAL':
      return 'Disputa vencida, aguardando repasse da adquirente';
    case 'DUNNING_REQUESTED':
      return 'Em processo de negativação';
    case 'DUNNING_RECEIVED':
      return 'Recuperada';
    case 'AWAITING_RISK_ANALYSIS':
      return 'Pagamento em análise';
    case 'AwaitingBankStatus':
      return 'Aguardando status cartão';
    default:
      return status;
  }
}
