/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import * as React from 'react';
// import Grid from '@mui/material/Grid';
// import Typography from '@mui/material/Typography';
// import TextField from '@mui/material/TextField';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
import Select from 'react-select';
import { styled, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import api from '../../../service/axios/api';
import styles from '../../../styles/Layout.module.css';

// const options = [
//   {
//     label: 'JoyCard',
//     value: 1,
//   },
// ];

const customStyles = {
  control: provided => ({
    ...provided,
    width: 982,
    backgroundColor: 'none',
    // boxShadow: 'none',
    boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
    border: 'none',
    color: '#717171',
    fontWeight: 'bold',
    fontFamily: 'Open Sans',
    // fontSize: 24,
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  option: provided => ({
    ...provided,
    fontWeight: 'bold',
    fontFamily: 'Open Sans',
    color: '#717171',
    // color: state.data.color,
    // backgroundColor: state.data.bgcolor,
    // fontSize: 24,
  }),
  placeholder: () => ({
    fontWeight: 'bold',
    fontFamily: 'Open Sans',
    marginTop: -20,
    color: '#717171',
    // paddingBottom: 20,
    // fontSize: 24,
    // marginBottom: 30,
  }),
};
// const customStyles = {
//   control: (provided, state) => ({
//     ...provided,
//     width: 982,
//     height: 60,
//     borderRadius: 10,
//     // backgroundColor: 'none',
//     // boxShadow: 'none',
//     // border: 'none',
//     fontWeight: 'bold',
//     fontFamily: 'Open Sans',
//     fontSize: 14,
//   }),
//   indicatorSeparator: s => ({ display: 'none' }),
//   option: (provided, state) => ({
//     ...provided,
//     fontFamily: 'Open Sans',
//     fontSize: 24,
//   }),
//   placeholder: (provided, state) => ({
//     // fontWeight: 'bold',
//     fontFamily: 'Open Sans',
//     fontSize: 24,
//     color: '#9C9C9C',
//     // marginBottom: 30,
//   }),
// };

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark'
        ? 'rgba(57,75,89,.5)'
        : 'rgba(206,217,224,.5)',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#41b490',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#41b490',
  },
});

export default function CardExist({ specOrder, specCard, card }) {
  const { t } = useTranslation();
  const [options, setOptions] = React.useState([])

  React.useEffect(()=>{
    const cancel = new AbortController();
    api.cards.getTypeCards(cancel)
    .then((res)=> {
      const list = []
      res.retorno.forEach((e)=>{
        if(e.status === 'Active'){
          list.push({label: e.name, value: e.id})
        }
      })
      setOptions(list)
    })
    .catch((err)=> console.log(err))
  },[])

  return (
    <div className="box-new-card">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'start',
          alignItems: 'center',
        }}
      >
        {specOrder === 'completo' && (
          <h6 className={styles.textBoxInform}>
            {t('newOrder.cardExists.labelComplete')}
          </h6>
        )}
      </div>
      <h6 className={styles.labelGridSecond}>
        {t('newOrder.cardExists.label')}
      </h6>
      <Grid
        container
        spacing={1}
        direction="column"
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          padding: 40,
        }}
      >
        <Grid item>
          <Select
            fullWidth
            // value={{ label: 'JoyCard', value: 1 }}
            styles={customStyles}
            className="basic-single"
            classNamePrefix="select"
            value={card}
            onChange={specCard}
            placeholder={t('newOrder.cardExists.selectMsg')}
            isSearchable={false}
            options={options}
          />
        </Grid>
      </Grid>
      {/* <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'start',
          alignItems: 'center',
        }}
      >
        <p className="label-card-exist">
          Escolha um dos cartões pré-pagos registrados
        </p>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: 20,
          marginTop: 20,
        }}
      >
        <Select
          fullWidth
          styles={customStyles}
          className="basic-single"
          classNamePrefix="select"
          placeholder="SELECIONE O CARTÃO PRÉ-PAGO"
          isSearchable={false}
          options={options}
        />
      </div> */}
      {/* <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'end',
          alignItems: 'center',
        }}
      >
        <p className="label-planilhas">
          <img
            id="planilha"
            src="../img/upload.svg"
            alt="logo"
            className="img-planilhas"
          />
          Planilha de premiados
        </p>
      </div> */}
    </div>
  );
}
