/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputAdornment,
  makeStyles,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';
// import { Autocomplete } from '@material-ui/lab';

import SelectAsyncCommercialAgent from './SelectAsyncCommercialAgent';
import StepperContext from '../../context/StepperContext';
import ProductDetailsSchema from '../../schemas/ProductDetailsSchema';
// import ProductDetails from '@/types/ProductDetails';
import { NumberFormatCustom, NumberFormatRate } from '../NumberFormatCustom';

import { CALC_COMISSION, PRODUCTS } from '../../util/constants';

// import Company from '@/types/Company';
// import SpinnerPage from '../SpinnerPage';
import cardFees from './defaultStates/cardFees';
import { operatorFees } from './defaultStates/productDetails';
import { PrepaidCardLeft, PrepaidCardRight } from './PrepaidCard';
import { DigitalAccountLeft, DigitalAccountRight } from './DigitalAccount';
import { TransferLeft, TransferRight } from './Transfer';

// type ProductDetailsKeys = keyof ProductDetails

const useStyles = makeStyles(theme => ({
  form: {
    minHeight: '500px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  section: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(8),
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(4),
    background: '#f0f0f0',
    width: '100%',
    '& > *': {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },
}));

// interface Props {
//   productDetails: ProductDetails
// }

// type CommercialAgentType = {
//   id?: string
//   company: Company
// }

function ProductDetailsForm({
  productDetails,
  edit,
  agent,
  setAgent,
  agentComission,
  setAgentComission,
}) {
  const { t } = useTranslation();
  const [productDetailsState, setProductDetailsState] = useState({
    ...productDetails,
  });

  const form = useForm({
    mode: 'onChange',
    defaultValues: productDetails,
    resolver: yupResolver(ProductDetailsSchema),
  });
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors },
  } = form;
  // const commercialAgentsQuery = firestore.collection('commercialAgents');

  // const [commercialAgents, loadingCommercialAgents] =
  //   useCollectionDataOnce(commercialAgentsQuery);
  // const [agent, setAgent] = useState();
  // const commercialAgents = [{ label: 'Willian', value: 1 }];
  // const commercialAgents = [{ label: 'Janaina Mello e Araujo', value: 11 }];

  const classes = useStyles();
  const stepper = useContext(StepperContext);

  const submit = data => {
    stepper.next({ productDetails: data });
  };

  const product = watch('product');
  const { productBrand } = watch();

  useEffect(() => {
    switch (productBrand) {
      case 'mastercard':
        if (edit) {
          setValue('campaign.issueFee', productDetails.campaign.issueFee);
          setProductDetailsState({
            ...productDetailsState,
            campaign: {
              ...productDetailsState.campaign,
              issueFee: productDetails.campaign.issueFee,
            },
          });
        } else {
          setValue('campaign.issueFee', cardFees.mastercard.operatorFees.price);
          setProductDetailsState({
            ...productDetailsState,
            campaign: {
              ...productDetailsState.campaign,
              issueFee: cardFees.mastercard.operatorFees.price,
            },
          });
        }
        break;
      default:
        if (edit) {
          setValue('campaign.issueFee', productDetails.campaign.issueFee);
          setProductDetailsState({
            ...productDetailsState,
            campaign: {
              ...productDetailsState.campaign,
              issueFee: productDetails.campaign.issueFee,
            },
          });
        } else {
          setValue('campaign.issueFee', cardFees.visa.operatorFees.price);
          setProductDetailsState({
            ...productDetailsState,
            campaign: {
              ...productDetailsState.campaign,
              issueFee: cardFees.visa.operatorFees.price,
            },
          });
        }

        break;
    }
  }, [productBrand]);

  useEffect(() => {
    if (product === 'digital_account') {
      setValue('campaign.issueFee', cardFees.visa.operatorFees.price);
      setProductDetailsState({
        ...productDetailsState,
        campaign: {
          ...productDetailsState.campaign,
          issueFee: cardFees.visa.operatorFees.price,
        },
        operatorFees: {
          ...productDetailsState.operatorFees,
          ...operatorFees,
        },
      });
    }
    if (product === 'transfer') {
      setValue('productBrand', '');
      setValue('issuer', ' ');
      setProductDetailsState({
        ...productDetailsState,
        productBrand: '',
      });
    }
  }, [product]);

  const isAdministrationRate = watch('campaign.isAdministrationRate');

  const handleComission = (e, idx) => {
    const orig = _.cloneDeep(agent);
    orig[idx].agentComission = e;
    setAgent(orig);
  };

  const handleAgent = (e, idx) => {
    const { label, value } = e;
    const orig = _.cloneDeep(agent);
    orig[idx].label = label;
    orig[idx].value = value;
    setAgent(orig);
  };

  const appendAgent = () => {
    setAgent([...agent, { label: '', value: '', agentComission: '0' }]);
  };

  const removeAgent = idx => {
    const orig = _.cloneDeep(agent);
    orig.splice(idx, 1);
    setAgent(orig);
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit(submit)}>
      <Grid container>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Box p={7}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography variant="h5" component="h3">
                  {t('newContract.product.label')}
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Controller
                  control={control}
                  name="product"
                  render={props => (
                    <TextField
                      onChange={e => {
                        Object.keys(productDetails).forEach(v => {
                          const value = v;
                          setValue(value, productDetails[value]);
                        });
                        return props.field.onChange(e.target.value);
                      }}
                      value={props.field.value}
                      fullWidth
                      select
                      variant="outlined"
                      size="small"
                      label={t('newContract.product.category')}
                      error={!!errors.product}
                      helperText={errors.product?.message}
                    >
                      {PRODUCTS.map(p => (
                        <MenuItem key={p.value} value={p.value}>
                          {p.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
              {product === 'prepaid_card' && <PrepaidCardLeft form={form} />}
              {product === 'digital_account' && (
                <DigitalAccountLeft form={form} />
              )}
              {product === 'transfer' && <TransferLeft form={form} />}
              <Grid item xs={12}>
                <hr />
              </Grid>
              {/* <Grid item lg={12} md={12} sm={6} xs={12}>
                <TextField
                  inputProps={{ ...register('campaign.name') }}
                  variant="outlined"
                  size="small"
                  fullWidth
                  label="Nome de Campanha"
                  name="campaign"
                  error={!!errors.campaign?.name}
                  helperText={errors.campaign?.name?.message}
                />
              </Grid> */}
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <FormControl
                  component="fieldset"
                  size="small"
                  error={!!errors.campaign?.isAdministrationRate}
                >
                  <FormLabel component="legend">
                    {t('newContract.product.fees')}
                  </FormLabel>

                  <Controller
                    control={control}
                    name="campaign.isAdministrationRate"
                    render={props => (
                      <RadioGroup
                        onChange={e => {
                          props.field.onChange(e.target.value === 'true');
                        }}
                        value={String(props.field.value)}
                        aria-label="Possui"
                        row
                      >
                        <FormControlLabel
                          value="true"
                          control={<Radio />}
                          label={t('newContract.product.percent')}
                        />
                        <FormControlLabel
                          value="false"
                          control={<Radio />}
                          label={t('newContract.product.fixed')}
                        />
                      </RadioGroup>
                    )}
                  />
                  <FormHelperText>
                    {errors.campaign?.isAdministrationRate?.message}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item lg={6} md={6} sm={3} xs={5}>
                <Grid item xs={12}>
                  {isAdministrationRate && (
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      label={t('newContract.product.tax')}
                      value={productDetailsState.campaign.administrationRate}
                      onChange={e => {
                        setProductDetailsState({
                          ...productDetailsState,
                          campaign: {
                            ...productDetailsState.campaign,
                            administrationRate: e.target.value,
                          },
                        });
                      }}
                      error={!!errors.campaign?.administrationRate}
                      helperText={errors.campaign?.administrationRate?.message}
                      InputProps={{
                        inputComponent: NumberFormatRate,
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                        inputProps: {
                          ...register('campaign.administrationRate'),
                        },
                      }}
                    />
                  )}
                  {!isAdministrationRate && (
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      label={t('newContract.product.tax')}
                      value={productDetailsState.campaign.administrationFee}
                      onChange={e => {
                        setProductDetailsState({
                          ...productDetailsState,
                          campaign: {
                            ...productDetailsState.campaign,
                            administrationFee: e.target.value,
                          },
                        });
                      }}
                      error={!!errors.campaign?.administrationFee}
                      helperText={errors.campaign?.administrationFee?.message}
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                        inputProps: {
                          ...register('campaign.administrationFee'),
                        },
                      }}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Box marginTop={8}>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      label={t('newContract.product.award')}
                      InputLabelProps={{ shrink: true }}
                      onChange={e => {
                        setProductDetailsState({
                          ...productDetailsState,
                          campaign: {
                            ...productDetailsState.campaign,
                            annualEstimatedAward: e.target.value,
                          },
                        });
                      }}
                      value={productDetailsState.campaign.annualEstimatedAward}
                      error={!!errors.campaign?.annualEstimatedAward}
                      helperText={
                        errors.campaign?.annualEstimatedAward?.message
                      }
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                        inputProps: {
                          ...register('campaign.annualEstimatedAward'),
                        },
                      }}
                    />
                  </Box>
                </Grid>
                {product === 'prepaid_card' && (
                  <Grid item xs={12}>
                    <Box marginTop={8}>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        label={t('newContract.product.cardPrice')}
                        value={productDetailsState.campaign.issueFee}
                        onChange={e => {
                          setProductDetailsState({
                            ...productDetailsState,
                            campaign: {
                              ...productDetailsState.campaign,
                              issueFee: e.target.value,
                            },
                          });
                        }}
                        error={!!errors.campaign?.issueFee}
                        helperText={errors.campaign?.issueFee?.message}
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                          inputProps: {
                            ...register('campaign.issueFee'),
                          },
                        }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Box>
                  </Grid>
                )}
                {product === 'digital_account' && (
                  <Grid item xs={12}>
                    <Box marginTop={8}>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        label="Preço da Conta"
                        value={productDetailsState.campaign.issueFee}
                        onChange={e => {
                          setProductDetailsState({
                            ...productDetailsState,
                            campaign: {
                              ...productDetailsState.campaign,
                              issueFee: e.target.value,
                            },
                          });
                        }}
                        error={!!errors.campaign?.issueFee}
                        helperText={errors.campaign?.issueFee?.message}
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                          inputProps: {
                            ...register('campaign.issueFee'),
                          },
                        }}
                      />
                    </Box>
                  </Grid>
                )}
              </Grid>
              <Grid item lg={6} md={6} sm={3} xs={5}>
                <FormControl
                  component="fieldset"
                  size="small"
                  error={!!errors.campaign?.rechargeFrequency}
                >
                  <FormLabel component="legend">
                    {t('newContract.product.readjustment')}
                  </FormLabel>

                  <Controller
                    control={control}
                    name="campaign.rechargeFrequency"
                    render={props => (
                      <RadioGroup
                        onChange={e => {
                          props.field.onChange(e.target.value);
                        }}
                        value={props.field.value}
                        aria-label="Possui"
                        row
                      >
                        <FormControlLabel
                          // value="monthly"
                          value="2"
                          control={<Radio />}
                          label="2"
                        />
                        <FormControlLabel
                          // value="quarterly"
                          value="3"
                          control={<Radio />}
                          label="3"
                        />
                        <FormControlLabel
                          // value="yearly"
                          value="4"
                          control={<Radio />}
                          label="4"
                        />
                        <FormControlLabel
                          // value="only"
                          value="6"
                          control={<Radio />}
                          label="6"
                        />
                        <FormControlLabel
                          // value="only"
                          value="12"
                          control={<Radio />}
                          label="12"
                        />
                      </RadioGroup>
                    )}
                  />
                  <FormHelperText>
                    {errors.campaign?.rechargeFrequency?.message}
                  </FormHelperText>
                </FormControl>
              </Grid>
              {/* {commercialAgents && commercialAgents.length > 0 && ( */}
              {/* <> */}
              {/* <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Button onClick={addContact} variant="contained" color="primary">
              {t('newContract.contact.addContactBtn')}
            </Button>
          </div> */}
              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  color="primary"
                  paragraph
                  align="center"
                >
                  Agentes comerciais
                  <Button
                    onClick={appendAgent}
                    variant="text"
                    style={{ marginLeft: 10, fontSize: 20, fontWeight: 'bold' }}
                    color="primary"
                  >
                    +
                  </Button>
                </Typography>
              </Grid>
              {agent?.map((a, idx) => (
                <>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <FormLabel component="legend">Agente comercial</FormLabel>

                    <SelectAsyncCommercialAgent
                      value={a}
                      onChange={e => handleAgent(e, idx)}
                      placeholder="Selecione o agente comercial"
                      menuPlacement="auto"
                    />
                    <FormHelperText error>
                      {errors.commercialAgent?.id?.message}
                    </FormHelperText>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <FormLabel component="legend">Valor</FormLabel>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        label="Comissão do agente"
                        value={a.agentComission}
                        // onChange={e => {
                        //   setAgentComission(e.target.value);
                        // }}
                        onChange={e => {
                          handleComission(e.target.value, idx);
                        }}
                        InputProps={{
                          inputComponent: NumberFormatRate,
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          ),
                        }}
                      />
                      <Button
                        onClick={() => removeAgent(idx)}
                        variant="outlined"
                        size="small"
                        style={{
                          marginLeft: 15,
                          // width: 10,
                          backgroundColor: '#ba000d',
                          color: '#fff',
                          fontSize: 14,
                          fontWeight: 'bold',
                        }}
                      >
                        {t('newContract.contact.removeBtn')}
                      </Button>
                    </div>
                  </Grid>
                  {idx === agent.length - 1 && (
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Controller
                        control={control}
                        name="commission_calculation"
                        render={props => (
                          <TextField
                            onChange={e => props.field.onChange(e.target.value)}
                            value={props.field.value}
                            fullWidth
                            select
                            variant="outlined"
                            size="small"
                            label="Cálculo comissionamento"
                          >
                            {CALC_COMISSION.map(p => (
                              <MenuItem key={p.value} value={p.value}>
                                {p.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Grid>
                  )}
                </>
              ))}
            </Grid>
          </Box>
        </Grid>
        {product === 'prepaid_card' && <PrepaidCardRight form={form} />}
        {product === 'transfer' && <TransferRight form={form} />}
        {product === 'digital_account' && <DigitalAccountRight form={form} />}
        {/* <DigitalAccountRight form={form} /> */}
      </Grid>

      <div className={classes.footer}>
        <Button onClick={stepper.prev} type="button" variant="contained">
          Voltar
        </Button>
        <Button type="submit" variant="contained" color="primary">
          Avançar
        </Button>
      </div>
    </form>
  );
}

export default ProductDetailsForm;
