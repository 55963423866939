/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-self-compare */
import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import {
  createStyles,
  Button,
  Typography,
  Grid,
  makeStyles,
  Menu,
  Modal,
  MenuItem,
  ListItemIcon,
  Badge,
} from '@material-ui/core';

import { FaSignature, FaEdit, FaDownload, FaLink } from 'react-icons/fa';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Link from '@material-ui/core/Link';

import SelectCommercialAgent from '../components/SelectCommercialAgent/SelectCommercialAgent';
// import SpinnerPage from '../components/SpinnerPage';
import ProposalsTable from '../components/tables/ProposalsTable';
import api from '../../../service/axios/api';

import ProposalModal from '../components/modal/ProposalModal';

import useContextMenu from '../hooks/useContextMenu/useContextMenu';
import useClickPreventionOnDoubleClick from '../hooks/useClickPreventionOnDoubleClick/useClickPreventionOnDoubleClick';
import ProposalRequestModal from '../components/modal/ProposalRequestModal';

export const useStyles = makeStyles(theme =>
  createStyles({
    button: {
      marginLeft: theme.spacing(3),
    },
  }),
);
function Proposals() {
  const classes = useStyles();
  const [count, setCount] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [leads, setLeads] = useState([]);
  // const query = firestore
  //   .collection('proposals')
  //   .orderBy('proposalNumber', 'desc');

  // const queryProposalRequest = firestore.collection('proposalRequests');
  // const [proposalRequests, loadingProposalRequests] =
  //   useCollectionData < ProposalRequest > queryProposalRequest;

  // const count = Object.keys(proposalRequests || []).length;
  // const count = Object.keys(['1']).length;

  // const [proposals, loading] = useCollectionData < Proposal > query;
  const [proposals, setProposals] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const { state, onOpen, onClose } = useContextMenu();
  const [selected, setSelected] = useState(null > null);
  const [modalOpened, setModalOpened] = useState(null > null);

  useEffect(() => {
    const cancel = new AbortController();
    const leadList = [];
    const listPro = [];
    let countLead = 0;
    api.campaign
      .getLead(cancel, 1, 100)
      .then(res => {
        res.docs.forEach(dc => {
          if (dc.origin === 'web' && dc.status === 'Pending') {
            countLead += 1;
            leadList.push({
              id: dc.id,
              contactFullName: dc?.LeadContacts,
              companyRole: dc?.LeadContacts[0]?.role,
              companyArea: dc?.LeadContacts[0]?.department,
              companyLegalName: dc.company_name,
              cnpj: dc.cnpj,
              email: dc.email,
              mobile: dc.mobile,
              phone: dc.phone,
              createdAt: dc.created_at,
              all: dc,
            });
          } else {
            listPro.push({
              id: dc.id,
              tradingName: dc.trading_name,
              companyCnpj: dc.cnpj,
              companyName: dc.company_name,
              status: dc.status,
              createdAt: dc.created_at,
              all: dc,
            });
          }
        });
        setProposals(listPro);
        setLeads(leadList);
        setCount(countLead);
      })
      .catch(err => console.log(err));
  }, []);

  const onClick = params => {
    const proposal = proposals?.find(p => p.id === params.row.id);
    const pFormated = {};
    if (proposal) {
      pFormated.tradingName = proposal.tradingName;
      pFormated.cnpj = proposal.companyCnpj;
      pFormated.companyName = proposal.companyName;
      pFormated.status = proposal.status;
      pFormated.email = proposal.all.email;
      pFormated.phone = proposal.all.phone;
      pFormated.mobile = proposal.all.mobile;
      pFormated.website = proposal.all.website;
      pFormated.campaign = proposal.all.Campaign.name;
      pFormated.contacts = proposal.all.LeadContacts;
    }

    if (proposal) {
      setModalOpened('review-proposal');
      setSelected(pFormated);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const onDoubleClick = (params, e) => {
    // const proposal = proposals?.find(pp => pp.id === params.row.id);
    // if (proposal) {
    //   setSelected(proposal);
    //   onOpen(e);
    // }
  };

  const [click, doubleClick] = useClickPreventionOnDoubleClick(
    onClick,
    onDoubleClick,
  );

  function closeModal() {
    setSelected(null);
    setModalOpened(null);
  }

  function openAgentModal() {
    setModalOpened('change-agent');
  }

  function openProposalRequestModal() {
    setModalOpened('proposal-request');
  }

  // if (loading || loadingProposalRequests) {
  //   return <SpinnerPage />;
  // }
  const isProposalSelected = !!selected;
  const showProposalModal =
    modalOpened === 'review-proposal' && isProposalSelected;
  const showAgentModal = modalOpened === 'change-agent' && isProposalSelected;
  const showProposalRequestModal = modalOpened === 'proposal-request';

  return (
    <>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Typography variant="h4" component="h2" gutterBottom>
          Oportunidade
        </Typography>

        <Box display="flex" justifyContent="space-between">
          <Button
            variant="text"
            color="primary"
            onClick={openProposalRequestModal}
          >
            <Badge badgeContent={count} color="primary">
              Lead
            </Badge>
          </Button>
          <Link href="/sales/proposals/create">
            <Button
              variant="text"
              color="primary"
              endIcon={<AddCircleIcon />}
              className={classes.button}
            >
              Nova oportunidade
            </Button>
          </Link>
        </Box>
      </Grid>
      <ProposalsTable
        onRowClick={click}
        onRowDoubleClick={doubleClick}
        proposals={proposals || []}
      />

      {selected && (
        <Menu
          keepMounted
          open={state.mouseY !== null}
          onClose={onClose}
          anchorReference="anchorPosition"
          anchorPosition={
            state.mouseY !== null && state.mouseX !== null
              ? { top: state.mouseY, left: state.mouseX }
              : undefined
          }
        >
          <Link href={`/dashboard/proposals/${selected?.id}/edit`}>
            <MenuItem onClick={onClose}>
              <ListItemIcon>
                <FaEdit />
              </ListItemIcon>
              <Typography>Editar</Typography>
            </MenuItem>
          </Link>

          <Link href={`/dashboard/contracts/create/${selected?.id}`}>
            <MenuItem onClick={onClose}>
              <ListItemIcon>
                <FaSignature />
              </ListItemIcon>
              <Typography>Contratar</Typography>
            </MenuItem>
          </Link>
          <MenuItem
            onClick={onClose}
            component="a"
            download
            href={`/api/proposals/${selected.id}/download`}
          >
            <ListItemIcon>
              <FaDownload />
            </ListItemIcon>
            <Typography>Download</Typography>
          </MenuItem>

          <MenuItem onClick={openAgentModal}>
            <ListItemIcon>
              <FaLink />
            </ListItemIcon>
            <Typography>Redirecionar</Typography>
          </MenuItem>
        </Menu>
      )}
      {selected && (
        <ProposalModal
          open={showProposalModal}
          onClose={closeModal}
          proposal={selected}
        />
      )}
      {selected && (
        <Modal open={showAgentModal} onClose={closeModal}>
          <SelectCommercialAgent proposal={selected} />
        </Modal>
      )}
      <ProposalRequestModal
        open={showProposalRequestModal}
        close={closeModal}
        proposalRequest={leads}
      />
    </>
  );
}

export default Proposals;
