/* eslint-disable react/prop-types */
import React, { useState, forwardRef } from 'react';
import { useHistory } from 'react-router-dom';
import Popover from '@material-ui/core/Popover';
import Avatar from '@material-ui/core/Avatar';

import Box from '@material-ui/core/Box';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
// import { useRouter } from 'next/router';
import Modal from '@material-ui/core/Modal';
import {
  FaUser,
  FaSignOutAlt,
  FaMailBulk,
  FaFileAlt,
  FaCog,
} from 'react-icons/fa';

// import hasPermission from '@/hooks/hasPermission';
import MyContractModal from './MyContract';
import Item from './AppBar.menuItem';
import { useStylesDropdown } from './AppBar.styles';

const Dropdown = forwardRef(
  ({ anchorEl, open, onClose, logout, user }, ref) => {
    const classes = useStylesDropdown();
    // const showProfiles = hasPermission({ profiles: ['read'] });
    // const showInvitations = hasPermission({ profiles: ['create'] });
    const router = useHistory();

    const manageProfiles = () => {
      router.push('/dashboard/accounts');
      onClose();
    };

    const sendInvitations = () => {
      router.push('/dashboard/accounts/create');
      onClose();
    };

    const profileSettings = () => {
      router.push('/dashboard/profile/settings');
      onClose();
    };

    const { fullName } = user;

    // TODO: make role for users
    const role = 'admin';

    const [openModal, setOpenModal] = useState(false);

    const handleOpenModal = () => {
      setOpenModal(true);
    };

    const handleCloseModal = () => {
      setOpenModal(false);
    };

    return (
      <Popover
        ref={ref}
        anchorEl={anchorEl}
        open={open}
        PaperProps={{ className: classes.root }}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Item divider onClick={profileSettings}>
          <Avatar className={classes.profile}>
            <FaUser />
          </Avatar>
          <Box>
            <Typography variant="h6" color="inherit">
              {fullName}
            </Typography>
            <Typography variant="caption">{role}</Typography>
            <br />
            <Typography variant="caption">MEU PERFIL</Typography>
          </Box>
        </Item>

        {/* {showProfiles && ( */}
        <Item onClick={manageProfiles}>
          <Avatar className={classes.icon}>
            <FaCog />
          </Avatar>
          <Typography variant="subtitle1" color="inherit">
            Gerenciar perfis
          </Typography>
          <Box flexGrow={1} />
        </Item>
        {/* )} */}
        {/* {showInvitations && ( */}
        <Item onClick={sendInvitations}>
          <Avatar className={classes.icon}>
            <FaMailBulk />
          </Avatar>
          <Typography variant="subtitle1" color="inherit">
            Enviar convites
          </Typography>
          <Box flexGrow={1} />
        </Item>
        {/* )} */}

        <Item onClick={handleOpenModal}>
          <Avatar className={classes.icon}>
            <FaFileAlt />
          </Avatar>
          <Typography variant="subtitle1" color="inherit">
            Meu Contrato
          </Typography>
        </Item>
        <Item onClick={logout}>
          <Avatar className={classes.icon}>
            <FaSignOutAlt />
          </Avatar>
          <Typography variant="subtitle1" color="inherit">
            Sair
          </Typography>
        </Item>

        <Modal open={openModal} onClose={handleCloseModal}>
          <DialogContent className={classes.contentModal}>
            <MyContractModal onClose={handleCloseModal} />
          </DialogContent>
        </Modal>
      </Popover>
    );
  },
);

export default Dropdown;
