/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-bind */
import React, { useContext, useState } from 'react';
import { Paper } from '@material-ui/core';
import StepperContext, { StepperProvider } from '../../context/StepperContext';

import CompanyForm from './CompanyForm';
import AgentSalesCommissionForm from './AgentSalesCommissionForm';
import CommercialAgentReviewForm from './CommercialAgentReviewForm';

const contact = {
  uid: '',
  fullName: '',
  cpf: '',
  companyRole: '',
  companyArea: '',
  phone: '',
  mobile: '',
  email: '',
};

const company = {
  cnpj: '',
  legalName: '',
  email: '',
  website: '',
  comments: '',
  tradingName: '',
  phone: '',
  mobile: '',
  admin: contact,
};
const defaultCommercialAgent = {
  id: '0',
  company,
  salesCommissionId: '',
};

function StepForm({ commercialAgent }) {
  const stepper = useContext(StepperContext);

  switch (stepper.step) {
    case 1:
      return <CompanyForm company={commercialAgent.company} />;
    case 2:
      return (
        <AgentSalesCommissionForm
          salesCommissionId={commercialAgent.salesCommissionId}
        />
      );
    default:
      return <CommercialAgentReviewForm commercialAgent={commercialAgent} />;
  }
}

function CommercialAgentForm({
  commercialAgent = defaultCommercialAgent,
  onSuccess,
  code,
  titleModal,
  actionModal,
}) {
  const [data, setData] = useState(commercialAgent);

  function updateData(stepData) {
    if (stepData) {
      setData(prevData => {
        return { ...prevData, ...stepData };
      });
    }
  }

  return (
    <Paper elevation={4}>
      <StepperProvider
        onChange={updateData}
        onSuccess={onSuccess}
        code={code}
        titleModal={titleModal}
        actionModal={actionModal}
      >
        <StepForm commercialAgent={data} />
      </StepperProvider>
    </Paper>
  );
}

export default CommercialAgentForm;
