/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import _ from 'lodash';
import AddIcon from '@material-ui/icons/Add';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, TextField, Grid, Chip } from '@material-ui/core';
import styles from '../../../styles/Layout.module.css';
import Loading from '../../../components/loading/Loading';
import TableContract from '../../../components/tables/contract/SalesTableContract';
// import ContractsTable from './ContractsTable';
import api from '../../../service/axios/api';
import routing from '../../../service/routes/routing';
import MyButton from '../../../components/button/MyButton';
import PermissionComponent from '../../../components/permissionComponent/PermissionComponent';
import UserRoles from '../../../service/acl/UserRoles';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: 10,
    margin: 0,
  },
  chip: {
    margin: 10,
  },
  textField: {
    // width: '90%',
  },
  input: {
    backgroundColor: '#fff',
    marginTop: 0,
    // color: 'white',
  },
});

export default function Contract() {
  const history = useHistory();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [chipData, setChipData] = useState([]);
  const [searchType, setSearchType] = useState();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [statusSelected, setStatusSelected] = useState({
    label: t('contract.filters.all'),
    value: '',
  });
  const [productSelected, setProductSelected] = useState({
    label: t('contract.filters.all'),
    value: '',
  });
  const searchRef = useRef(null);
  const [options, setOptions] = useState([
    { label: t('contract.filters.group'), value: 'groupName' },
    { label: t('contract.filters.cnpj'), value: 'cnpj' },
    { label: t('contract.filters.rz'), value: 'billed' },
  ]);

  const [page, setPage] = useState(0);
  const [itens, setItens] = useState({ label: '50', value: 50 });

  const search = extra => {
    setLoading(true);
    const cancel = new AbortController();
    api.commercialAgent
      .getContracts(
        productSelected.value,
        statusSelected.value,
        extra,
        itens.value,
        page + 1,
        cancel,
        chipData,
      )
      .then(res => {
        console.log('ROLIM...', res);
        const list = [];
        // res?.docs?.forEach(r => {
        //   list.push({
        //     id: r?.contract_id,
        //     campaing: r?.Contract?.campaing,
        //     group: r?.Contract?.group_name,
        //     cnpj: r?.LegalEntity?.cnpj,
        //     rz: r?.LegalEntity?.company_name,
        //     product: r?.Contract?.product_type,
        //     status: r?.Contract?.is_active,
        //     contractLink: r?.Contract?.pdf_file,
        //   });
        // });
        res?.docs?.forEach(r => {
          list.push({
            id: r?.contract_id,
            agent: r?.commercialAgent_name,
            responsible: r?.responsable_name,
            cnpj: r?.LegalEntity?.cnpj,
            rz: r?.company_name,
            product: r?.product,
            status: r?.contract_status,
            contractLink: r?.Contract?.pdf_file,
          });
        });
        setTotal(Math.ceil(res.total / itens.value));
        setData(list);
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    search();
  }, [itens, page, statusSelected, productSelected]);

  return (
    <>
      <Loading open={loading} msg={t('contract.searchMsg')} />
      <div className="col-sm-12">
        <div
          className="col-sm-12"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <h4 className={styles.textTitle}>Meus Contratos</h4>
          <PermissionComponent role={UserRoles.CONTRACT_NEW}>
            <MyButton
              style={{ marginRight: 50 }}
              // onClick={() => setOpenModal(true)}
              onClick={() => history.push(routing.newContract)}
              label={t('contract.newContractBtn')}
            />
          </PermissionComponent>
        </div>
      </div>
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          sm={12}
          style={{ display: 'flex', justifyContent: 'space-between' }}
        />
        <Grid item xs={12} sm={12}>
          <TableContract
            data={data}
            setData={setData}
            totalPages={total}
            page={page}
            setPage={setPage}
            itens={itens}
            setItens={setItens}
            setStatus={setStatusSelected}
            product={productSelected}
            setProduct={setProductSelected}
            chipData={chipData}
            setChipData={setChipData}
            find={search}
          />
        </Grid>
      </Grid>
    </>
  );
}
