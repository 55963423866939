/* eslint-disable import/prefer-default-export */
import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    tableContainer: {
      height: '40%',
      marginTop: theme.spacing(10),
    },
    subtitle: {
      fontWeight: 500,
      color: theme.palette.primary.main,
    },
  }),
);
