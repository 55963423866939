/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

import React, { useState, useEffect } from 'react';
// import { alpha, styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-unused-vars
import moment from 'moment-timezone';
import VisibilityIcon from '@material-ui/icons/Visibility';

import Select from 'react-select';
import { Pagination } from '@material-ui/lab';
import {
  Grid,
  Box,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableHead,
  Paper,
  Dialog,
  TableCell,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

// import api from '../../../service/axios/api';
import Status from '../../../service/translateStatus';

import SelectOptions from './SelectOptions1';

import './tableOrder.css';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#005D9F',
    fontFamily: 'Open Sans',
    color: theme.palette.common.white,
  },
  body: {
    color: '#717171',
    fontFamily: 'Open Sans',
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:hover': {
      backgroundColor: '#41b490',
      color: '#41b490',
    },
  },
}))(TableRow);

const options = [
  {
    label: '25',
    value: 25,
  },
  {
    label: '50',
    value: 50,
  },
  {
    label: '100',
    value: 100,
  },
];

const EnhancedTableHead = () => {
  const { t: header } = useTranslation();

  return (
    <TableHead>
      <TableRow>
        <StyledTableCell component="th">Data</StyledTableCell>
        <StyledTableCell component="th">Ordem serviço</StyledTableCell>
        <StyledTableCell component="th">NF</StyledTableCell>
        <StyledTableCell component="th">Descrição</StyledTableCell>
        <StyledTableCell component="th">CPF</StyledTableCell>
        <StyledTableCell component="th">Contratante</StyledTableCell>
        <StyledTableCell component="th">
          {header('Order.table.value')}
        </StyledTableCell>
        <StyledTableCell component="th">
          {header('Order.table.status')}
        </StyledTableCell>
        <StyledTableCell component="th">Data Cancelamento</StyledTableCell>
      </TableRow>
    </TableHead>
  );
};

export default function TableFidelity({
  orders,
  totalPages,
  setPage,
  page,
  itens,
  setItens,
  setStatus,
  product,
  setProduct,
  setOrderBy,
  chipData,
  setChipData,
  find,
}) {
  const { t } = useTranslation();
  const [selected, setSelected] = React.useState([]);
  const [openModal, setOpenModal] = useState(false);

  const [rows, setRows] = useState([]);

  useEffect(() => {
    const listOrder = [];
    orders.forEach(or => {
      listOrder.push({
        id: or.id,
        name: or.dealer_name,
        nf: or.invoice_number,
        description: or.description,
        service: or.service_order,
        status: or.status,
        vlr: or.value,
        cpf: or.client_cpf,
        date: or.date,
        pdf_file: or.pdf_file,
        canceledDate: or.canceled_at
          ? moment(or.canceled_at).format('DD/MM/YYYY')
          : '',
      });
    });
    setRows(listOrder);
  }, [orders]);

  const handleChangePage = (event, newPage) => {
    setSelected([]);
    setPage(newPage);
  };

  return (
    <>
      {/* <Grid item> */}
      <SelectOptions
        setStatus={setStatus}
        setChipData={setChipData}
        chipData={chipData}
        find={find}
        setOrderBy={setOrderBy}
        product={product}
        setProduct={setProduct}
        setSelectec={setSelected}
      />
      {/* </Grid> */}
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size="medium"
            >
              <EnhancedTableHead
                numSelected={selected.length}
                // onSelectAllClick={handleSelectAllClick}
                rowCount={rows.length}
                // handleMenu={handleMenu}
              />
              <TableBody>
                {rows ? (
                  rows.length > 0 ? (
                    rows.map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <StyledTableRow tabIndex={-1} key={row.id}>
                          <StyledTableCell>
                            {moment(row.date).format('DD/MM/YYYY')}
                          </StyledTableCell>
                          <StyledTableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            // padding="none"
                          >
                            {row.service}
                            {/* <p
                              // key={r.nd_number}
                              style={{ cursor: 'pointer', color: 'blue' }}
                              className="modal-box-body-value-label"
                              // onClick={() => window.open(r.link_debit)}
                            >
                              {row.service}
                            </p> */}
                            {/* {api.currentUser?.user?.role?.id !== '1'
                              ? row.db &&
                                row.db.length > 0 &&
                                row.db.map(r => {
                                  return (
                                    <p
                                      key={r.nd_number}
                                      style={{
                                        cursor: 'pointer',
                                        color: 'blue',
                                      }}
                                      className="modal-box-body-value-label"
                                      onClick={() => window.open(r.gcs_pdf)}
                                    >
                                      {r.nd_number}
                                    </p>
                                  );
                                })
                              : api.currentUser?.user?.role?.id !== '3' &&
                                row.db &&
                                row.db.length > 0 &&
                                row.db.map(r => {
                                  if (r.gcs_pdf) {
                                    return (
                                      <p
                                        key={r.nd_number}
                                        style={{
                                          cursor: 'pointer',
                                          color: 'blue',
                                        }}
                                        className="modal-box-body-value-label"
                                        onClick={() => window.open(r.gcs_pdf)}
                                      >
                                        {r.nd_number}
                                      </p>
                                    );
                                  }
                                })} */}
                            {/* {row.nf} */}
                          </StyledTableCell>
                          <StyledTableCell>{row.nf}</StyledTableCell>
                          <StyledTableCell>{row.description}</StyledTableCell>
                          <StyledTableCell>{row.cpf}</StyledTableCell>
                          <StyledTableCell>
                            {/* {Status(row.product, t)} */}
                            {row.name}
                          </StyledTableCell>
                          <StyledTableCell>
                            {new Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL',
                            })
                              .format(row.vlr)
                              .replace(',', '.')}
                          </StyledTableCell>
                          <StyledTableCell>
                            {Status(row.status, t)}
                            {!row.canceledDate && (
                              <VisibilityIcon
                                style={{
                                  cursor: 'pointer',
                                  marginLeft: '5px',
                                }}
                                onClick={() =>
                                  window.open(row.pdf_file, '_blank')
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell>
                            {row.canceledDate}
                            {/* <VisibilityIcon
                              style={{
                                cursor: 'pointer',
                                marginLeft: '5px',
                              }}
                              onClick={() =>
                                window.open(row.pdf_file, '_blank')
                              }
                            /> */}
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })
                  ) : (
                    <StyledTableRow>
                      <StyledTableCell colSpan={9} align="center">
                        {t('Order.table.noResult')}
                      </StyledTableCell>
                    </StyledTableRow>
                  )
                ) : (
                  <StyledTableRow>
                    <StyledTableCell colSpan={9} align="center" />
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
      <Grid
        container
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: 15,
        }}
        spacing={2}
        direction="row"
      >
        <Grid item>
          <Select
            className="basic-single"
            classNamePrefix="select"
            placeholder="Itens"
            value={itens}
            onChange={setItens}
            options={options}
          />
        </Grid>
        <Grid item>
          <Pagination
            size="large"
            count={totalPages}
            page={page}
            onChange={handleChangePage}
            variant="outlined"
            shape="rounded"
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        style={{
          justifyContent: 'end',
          padding: 40,
        }}
      />
      <Dialog
        onClose={() => setOpenModal(false)}
        open={openModal}
        fullWidth
        maxWidth="lg"
      >
        <Grid
          container
          spacing={1}
          style={{
            border: '2px solid #c5c5c5',
            borderRadius: 10,
            justifyContent: 'center',
            padding: 40,
          }}
        >
          conteudo
        </Grid>
      </Dialog>
    </>
  );
}
