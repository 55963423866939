/* eslint-disable react/prop-types */
import React from 'react';
import { Toolbar } from '@material-ui/core';
// import Head from 'next/head';

import styles from './styles/Layout.module.css';

import AppBar from './components/AppBar/AppBar';
// import SideBar from './components/SideBar';

function Layout({ children }) {
  return (
    <>
      {/* <Head>
        <title>Sales Ecosystem</title>
        <link rel="icon" href="/favicon.ico" />
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
        />
      </Head> */}
      <div className={styles.container}>
        <AppBar />
        {/* <SideBar /> */}
        <main className={styles.content}>
          <Toolbar />
          {children}
        </main>
      </div>
    </>
  );
}

export default Layout;
