/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-escape */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Carousel from 'react-elastic-carousel';
import Modal from '@material-ui/core/Modal';
import { Avatar, Typography, Button, Grid } from '@material-ui/core';
import { FaPlay, FaAngleRight, FaAngleLeft } from 'react-icons/fa';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { TutorialModal } from './Modal';

function YouTubeGetID(youtubeUrl) {
  let url = youtubeUrl;
  let ID = '';
  url = String(url)
    .replace(/(>|<)/gi, '')
    .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  if (url[2] !== undefined) {
    ID = url[2].split(/[^0-9a-z_\-]/i);
    ID = String(ID[0]);
  } else {
    ID = url;
  }
  return ID;
}

const useStyles = makeStyles(() =>
  createStyles({
    div: {
      width: '100%',
      height: 200,
      backgroundColor: '#71717130',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignContent: 'center',
    },
    avatar: {
      width: 55,
      height: 55,
      backgroundColor: '#2898FF',
      transition: 'all .3s ease-in-out',
      '&:hover': {
        transform: 'scale(1.2)',
      },
    },
    icon: {
      width: 55,
      height: 55,
      color: '#2898FF',
    },
  }),
);

function Tutorial({ tutorial, onClick }) {
  const classes = useStyles();
  const idVideo = YouTubeGetID(tutorial?.media || '');
  return (
    <div style={{ width: 356 }}>
      <Typography
        variant="h6"
        component="h6"
        style={{ color: '#2898FF', textAlign: 'center' }}
      >
        {tutorial.title}
      </Typography>
      <div
        className={classes.div}
        style={{
          backgroundImage:
            idVideo.length > 1
              ? `url("https://i.ytimg.com/vi/${idVideo}/hqdefault.jpg")`
              : '',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <Avatar className={classes.avatar} onClick={onClick(tutorial)}>
          <FaPlay />
        </Avatar>
      </div>
    </div>
  );
}

function TutorialGrid({ tutorials }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState();
  const myArrow = ({ type, onClick }) => {
    return (
      <Button onClick={onClick}>
        {type === 'PREV' && <FaAngleLeft className={classes.icon} />}
        {type === 'NEXT' && <FaAngleRight className={classes.icon} />}
      </Button>
    );
  };
  const openModal = tutorial => {
    return () => {
      setOpen(true);
      setSelected(tutorial);
    };
  };
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 568, itemsToShow: 2 },
    { width: 1004, itemsToShow: 3 },
  ];

  return (
    <>
      {/* <Carousel
        isRTL={false}
        pagination={false}
        itemsToScroll={1}
        itemsToShow={3}
        breakPoints={breakPoints}
        renderArrow={myArrow}
        itemPadding={[10, 10, 10, 10]}
      > */}
      <Grid container spacing={3}>
        {tutorials.map(tut => (
          <Grid item>
            <Tutorial tutorial={tut} onClick={openModal} />
          </Grid>
        ))}
      </Grid>
      {/* </Carousel> */}
      {selected && (
        <Modal open={open} onClose={() => setOpen(false)}>
          <TutorialModal tutorial={selected} />
        </Modal>
      )}
    </>
  );
}

export default TutorialGrid;
