/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Grid } from '@material-ui/core';
import moment from 'moment-timezone';
import styles from '../../../styles/Layout.module.css';
import TablePremioTransfer from '../../../components/tables/premiados/TablePremioTransfer';

export default function UploadPlan({ orders }) {
  // const [myFile, setMyfile] = useState();
  // const [orders, setOrders] = useState([]);
  return (
    <div className="box-new-card">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Grid container>
          <Grid item>
            <h6 className={styles.textBoxInform}>Resumo e Confirmação</h6>
          </Grid>
        </Grid>
        <Grid container style={{ justifyContent: 'end' }}>
          <Grid item style={{ marginRight: 30 }}>
            <h6 className={styles.textBoxInform}>
              Data:{moment.utc(new Date()).format('DD/MM/yyyy')}
            </h6>
          </Grid>
          {/* <Grid item>
            <h6 className={styles.textBoxInform}>Pedido P000000</h6>
          </Grid> */}
        </Grid>
      </div>
      <Grid
        container
        direction="row"
        style={{ justifyContent: 'space-between' }}
      >
        <Grid item xs={12}>
          <div style={{ display: 'flex' }}>
            <p className={styles.labelTitleResum}>Produto:</p>
            <p> Conta Digital</p>
          </div>
        </Grid>
        <Grid item>
          <div style={{ display: 'flex' }}>
            <p className={styles.labelTitleResum}>Nome:</p>
            <p> Conta Digital</p>
          </div>
        </Grid>
        <Grid item>
          <div style={{ display: 'flex' }}>
            <p className={styles.labelTitleResum}>Cartão:</p>
            <p> Virtual</p>
          </div>
        </Grid>
        <Grid item>
          <div style={{ display: 'flex' }}>
            <p className={styles.labelTitleResum}>Emissor:</p>
            <p> Matrix</p>
          </div>
        </Grid>
        <Grid item>
          <div style={{ display: 'flex' }}>
            <p className={styles.labelTitleResum}>Tecnologia:</p>
            <p> CVV Online</p>
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        style={{ justifyContent: 'space-between' }}
      >
        <Grid item>
          <div style={{ display: 'flex' }}>
            <p className={styles.labelTitleResum}>Empresa:</p>
            <p> Tal</p>
          </div>
        </Grid>
        <Grid item>
          <div style={{ display: 'flex' }}>
            <p className={styles.labelTitleResum}>Contratante:</p>
            <p> Tal</p>
          </div>
        </Grid>
        <Grid item>
          <div style={{ display: 'flex' }}>
            <p className={styles.labelTitleResum}>Bandeira:</p>
            <p> Visa</p>
          </div>
        </Grid>
        <Grid item>
          <div style={{ display: 'flex' }}>
            <p className={styles.labelTitleResum}>Valor:</p>
            <p> R$00.00</p>
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        style={{
          justifyContent: 'space-between',
          marginBottom: -15,
          marginTop: 20,
        }}
      >
        <Grid item xs={12}>
          <p className={styles.labelTitleResum}>Distribuição de valoresssss</p>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        direction="row"
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          paddingBottom: 10,
        }}
      >
        <Grid item xs={12}>
          <TablePremioTransfer
            orders={orders}
            totalPages={1}
            page={1}
            // setPage={setPage}
            itens={10}
            // setItens={setItens}
          />
        </Grid>
      </Grid>
    </div>
  );
}
