/* eslint-disable react/prop-types */
import React from 'react';
// import Grid from '@mui/material/Grid';
import './checkout.css';
// import TextField from '@mui/material/TextField';
// import FormGroup from '@mui/material/FormGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';

import { Grid, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';

// import { styled } from '@mui/material/styles';

import { makeStyles, createStyles, styled } from '@material-ui/core/styles';

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark'
        ? 'rgba(57,75,89,.5)'
        : 'rgba(206,217,224,.5)',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#137cbd',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#106ba3',
  },
});

const useStyles = makeStyles(theme =>
  createStyles({
    myButton: {
      // textAlign: 'center',
      // padding: theme.spacing(6),
      // margin: theme.spacing(1),
      // color: theme.palette.text.secondary,
      color: '#2a3176',
      backgroundColor: '#f4f4f4',
      fontWeight: 'bold',
      // minWidth: 200,
    },
    myGrid: {
      backgroundColor: '#f4f4f4',
      margin: theme.spacing(1),
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      textAlign: 'center',
      cursor: 'pointer',
    },
    myLabel: {
      // padding: theme.spacing(6),
      color: '#2a3176',
      fontWeight: 'bold',
      margin: 20,
    },
    myLabelFor: {
      // padding: theme.spacing(6),
      fontFamily: 'Open Sans',
      color: '#717171',
      fontWeight: 'bold',
    },
  }),
);

// eslint-disable-next-line react/prop-types
export default function UserPermissions({ state, setState }) {
  // const [selected, setSelected] = useState();
  // const [state, setState] = useState({
  //   consulta: false,
  //   liberar: false,
  //   pagar: false,
  //   inserir: false,
  //   vincular: false,
  //   status: false,
  //   produto: false,
  //   documentos: false,
  // });
  const classes = useStyles();

  const handleChange = event => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  return (
    <Grid
      container
      spacing={1}
      style={{
        border: '2px solid #c5c5c5',
        borderRadius: 10,
      }}
    >
      <h4 className={classes.myLabel}>Configurações do operador</h4>
      <Grid container spacing={1} style={{ padding: 20 }}>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <FormGroup>
            <FormControlLabel
              className={classes.myLabelFor}
              control={
                <Checkbox
                  checked={state.consulta}
                  onChange={handleChange}
                  name="consulta"
                  checkedIcon={<BpCheckedIcon />}
                  icon={<BpIcon />}
                />
              }
              label="Consultar"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <FormGroup>
            <FormControlLabel
              className={classes.myLabelFor}
              control={
                <Checkbox
                  checked={state.liberar}
                  onChange={handleChange}
                  name="liberar"
                  checkedIcon={<BpCheckedIcon />}
                  icon={<BpIcon />}
                />
              }
              label="Liberar"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <FormGroup>
            <FormControlLabel
              className={classes.myLabelFor}
              control={
                <Checkbox
                  checked={state.pagar}
                  onChange={handleChange}
                  name="pagar"
                  checkedIcon={<BpCheckedIcon />}
                  icon={<BpIcon />}
                />
              }
              label="Pagar"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <FormGroup>
            <FormControlLabel
              className={classes.myLabelFor}
              control={
                <Checkbox
                  checked={state.inserir}
                  onChange={handleChange}
                  name="inserir"
                  checkedIcon={<BpCheckedIcon />}
                  icon={<BpIcon />}
                />
              }
              label="Inserir"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <FormGroup>
            <FormControlLabel
              className={classes.myLabelFor}
              control={
                <Checkbox
                  checked={state.bloquear}
                  onChange={handleChange}
                  name="bloquear"
                  checkedIcon={<BpCheckedIcon />}
                  icon={<BpIcon />}
                />
              }
              label="Bloquear/Desbloquear"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <FormGroup>
            <FormControlLabel
              className={classes.myLabelFor}
              control={
                <Checkbox
                  checked={state.vincular}
                  onChange={handleChange}
                  name="vincular"
                  checkedIcon={<BpCheckedIcon />}
                  icon={<BpIcon />}
                />
              }
              label="Vincular/Produtos"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <FormGroup>
            <FormControlLabel
              className={classes.myLabelFor}
              control={
                <Checkbox
                  checked={state.status}
                  onChange={handleChange}
                  name="status"
                  checkedIcon={<BpCheckedIcon />}
                  icon={<BpIcon />}
                />
              }
              label="Alterar status"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <FormGroup>
            <FormControlLabel
              className={classes.myLabelFor}
              control={
                <Checkbox
                  checked={state.produto}
                  onChange={handleChange}
                  name="produto"
                  checkedIcon={<BpCheckedIcon />}
                  icon={<BpIcon />}
                />
              }
              label="Vincular produtos"
            />
          </FormGroup>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={4}
          xl={4}
          style={{ marginBottom: 30 }}
        >
          <FormGroup>
            <FormControlLabel
              className={classes.myLabelFor}
              control={
                <Checkbox
                  checked={state.documentos}
                  onChange={handleChange}
                  name="documentos"
                  checkedIcon={<BpCheckedIcon />}
                  icon={<BpIcon />}
                />
              }
              label="Exportar documentos"
            />
          </FormGroup>
        </Grid>
      </Grid>
    </Grid>
  );
}
