/* eslint-disable import/prefer-default-export */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useRef, useContext } from 'react';
import Select from 'react-select';
import _ from 'lodash';
import AddIcon from '@material-ui/icons/Add';

import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  TextField,
  Grid,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import styles from '../../styles/Layout.module.css';
import Loading from '../../components/loading/Loading';
import FlashContext from '../../components/contexts/FlashContext';
// import ContractsTable from './ContractsTable';
import api from '../../service/axios/api';
import routing from '../../service/routes/routing';
import MyButton from '../../components/button/MyButton';
import PermissionComponent from '../../components/permissionComponent/PermissionComponent';
import UserRoles from '../../service/acl/UserRoles';
import NewCompanyForm from './NewCompanyForm';
import { TableAdminDcp } from '../../components/tables/contract/TableAdminDcp';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: 10,
    margin: 0,
  },
  chip: {
    margin: 10,
  },
  textField: {
    // width: '90%',
  },
  input: {
    backgroundColor: '#fff',
    marginTop: 0,
    // color: 'white',
  },
});

export function AdminDcp() {
  const history = useHistory();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [chipData, setChipData] = useState([]);
  const [searchType, setSearchType] = useState();

  const { notifyError, notifySuccess } = useContext(FlashContext);

  const [openNew, setOpenNew] = useState(false);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [statusSelected, setStatusSelected] = useState({
    label: t('contract.filters.all'),
    value: '',
  });
  const [productSelected, setProductSelected] = useState({
    label: t('contract.filters.all'),
    value: '',
  });
  const searchRef = useRef(null);
  const [options, setOptions] = useState([
    { label: t('contract.filters.group'), value: 'groupName' },
    { label: t('contract.filters.cnpj'), value: 'cnpj' },
    { label: t('contract.filters.rz'), value: 'billed' },
  ]);

  const [page, setPage] = useState(1);
  const [itens, setItens] = useState({ label: '10', value: 10 });

  const search = extra => {
    setLoading(true);
    const cancel = new AbortController();
    api.dcp
      .get(statusSelected.value, extra, itens.value, page, cancel, chipData)
      .then(res => {
        setTotal(Math.ceil(res.total / itens.value));
        setData(res.docs);
        console.log(res);
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        notifyError(error.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    search();
  }, [itens, page, statusSelected]);

  return (
    <>
      <div className="col-sm-12" style={{ marginTop: 0 }}>
        <Loading open={loading} msg="Buscando pagamentos..." />
        <div
          className="col-sm-12"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <h4 className={styles.textTitle}>
            Pagamentos
            <br /> Demonstrativos Consolidados
          </h4>
        </div>
      </div>
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          sm={12}
          style={{ display: 'flex', justifyContent: 'space-between' }}
        />
        <Grid item xs={12} sm={12}>
          <TableAdminDcp
            data={data}
            setData={setData}
            totalPages={total}
            page={page}
            setPage={setPage}
            itens={itens}
            setItens={setItens}
            setStatus={setStatusSelected}
            status={statusSelected}
            product={productSelected}
            setProduct={setProductSelected}
            chipData={chipData}
            setChipData={setChipData}
            find={search}
          />
        </Grid>
      </Grid>
    </>
  );
}
