/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import api from '../../../service/axios/api';
import styles from '../../../styles/Layout.module.css';
import TablePremio from '../../../components/tables/premiados/TablePremioV1';

export default function UploadPlan({
  // orders,
  orderId,
  contract,
  valueCredit,
  type,
  myCnpj,
  campaign,
}) {
  // const [myFile, setMyfile] = useState();
  const { t } = useTranslation();
  const [orders, setOrders] = useState([]);
  const [total, setTotal] = useState(0);
  const [provision, setProvision] = useState(0);
  const [infMin, setInfMin] = useState(0);
  const [taxPercent, setTaxPercent] = useState(0);
  const [sendValue, setSendValue] = useState(0);
  const [individuals, setIndividuals] = useState(0);
  // const [newCardValue, setNewCardValue] = useState(0);
  const [cardVincVlr, setCardVincVlr] = useState(0);
  const [ticket, setTicket] = useState(0);
  const [finish, setFinish] = useState(false);

  // useEffect(() => {
  //   if (valueCredit) {
  //     setTotal(Number(valueCredit));
  //   }
  // }, [valueCredit]);
  // useEffect(() => {
  //   if (orders.length > 0) {
  //     if (orders) {
  //       let vlr = 0.0;
  //       orders.forEach(o => {
  //         vlr += Number(o.Valor);
  //       });
  //       setTotal(vlr);
  //     }
  //   }
  // }, [orders]);
  useEffect(() => {
    // console.log('vamos');
    if (orderId) {
      const cancel = new AbortController();
      api.order
        .getItemsByOrderId(Number(orderId), cancel)
        .then(details => {
          // setOrders(details.docs);
          let tot = 0;
          let send = 0;
          // let card = 0;
          let prov = 0;
          let min = 0;
          let tax = 0;
          let cardVinc = 0;
          let iTicket = 0;
          const comp = [];
          const indv = [];
          details.docs.forEach(doc => {
            if (doc.indid) indv.push(doc.indid);
            if (doc.compid) comp.push(doc.compid);
            if (
              (doc.comments === 'Carga' ||
                doc.comments === 'Transferência Emergencial para CC') &&
              doc.status !== 'Rejected' &&
              !doc.is_detail
            ) {
              tot += Number(doc.value);
            }

            if (doc.comments === 'Tarifa de Postagem' && !doc.is_detail)
              send += Number(doc.value);
            if (
              doc.comments === 'Tarifa de Emissão de Boleto' &&
              !doc.is_detail
            )
              iTicket += Number(doc.value);
            if (
              (doc.comments === 'Preço cartão Com Vínculo' ||
                doc.comments === '2a. via - Fraude' ||
                doc.comments === '2a. via cartão' ||
                doc.comments === '2a. via com Descarga' ||
                doc.comments === '2a. Via Cartão por Inatividade' ||
                doc.comments === 'Estoque Cartão Sem Vínculo') &&
              doc.status === 'Received' &&
              !doc.is_detail
            ) {
              // card = Number(doc.value);
              cardVinc += Number(doc.value);
            }

            if (doc.comments === 'Provisão' && !doc.is_detail) {
              prov += Number(doc.value);
              if (doc.is_auto_provision) {
                setFinish(true);
              }
            }

            if (
              doc.comments === 'Tarifa de Carga Inferior ao Minimo' &&
              doc.status === 'Received' &&
              !doc.is_detail
            )
              min += Number(doc.value);
            if (doc.comments === 'Honorários (Percentual)' && !doc.is_detail)
              tax += Number(doc.value);
          });

          const uniqInd = [...new Set(indv)];
          const uniqComp = [...new Set(comp)];

          setIndividuals(uniqInd.length + uniqComp.length);
          setSendValue(send.toFixed(2));
          // setNewCardValue(card.toFixed(2));
          setTicket(iTicket);
          setTotal(tot);
          setProvision(prov);
          setInfMin(min);
          setTaxPercent(tax);
          setCardVincVlr(cardVinc);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [orderId]);
  return (
    <div className="box-new-card">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Grid container>
          <Grid item>
            <h6 className={styles.textBoxInform}>
              {t('newOrder.reviewCards.label')}
            </h6>
          </Grid>
        </Grid>
        <Grid container style={{ justifyContent: 'end' }}>
          <Grid item style={{ marginRight: 30 }}>
            <h6 className={styles.textBoxInform}>
              {t('newOrder.reviewCards.date')}:
              {moment.utc(new Date()).format('DD/MM/yyyy')}
            </h6>
          </Grid>
          {/* <Grid item>
            <h6 className={styles.textBoxInform}>Pedido P000000</h6>
          </Grid> */}
        </Grid>
      </div>
      <Grid
        container
        direction="row"
        style={{ justifyContent: 'space-between' }}
      >
        <Grid item>
          <div style={{ display: 'flex' }}>
            <p className={styles.labelTitleResum}>
              {t('newOrder.reviewCards.product')}:
            </p>
            <p> {t('newOrder.reviewCards.productType')}</p>
          </div>
        </Grid>
        <Grid item>
          <div style={{ display: 'flex' }}>
            {type && (
              <p className={styles.labelTitleResum}>
                {t('newOrder.reviewCards.type')}:
              </p>
            )}
            <p>
              {type === 'compra'
                ? t('newOrder.reviewCards.purchases')
                : type === 'presente'
                ? t('newOrder.reviewCards.gift')
                : type
                ? t('newOrder.reviewCards.withdraw')
                : ''}
            </p>
          </div>
        </Grid>
        <Grid item>
          <div style={{ display: 'flex' }}>
            <p className={styles.labelTitleResum}>
              {t('newOrder.reviewCards.brand')}:
            </p>
            <p> MasterCard</p>
          </div>
        </Grid>
        <Grid item>
          <div style={{ display: 'flex' }}>
            <p className={styles.labelTitleResum}>
              {t('newOrder.reviewCards.issuer')}:
            </p>
            <p> Acesso</p>
          </div>
        </Grid>
        <Grid item>
          <div style={{ display: 'flex' }}>
            <p className={styles.labelTitleResum}>
              {t('newOrder.reviewCards.technology')}:
            </p>
            <p> {t('newOrder.reviewCards.chip')}</p>
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        style={{ justifyContent: 'space-between' }}
      >
        <Grid item>
          <div style={{ display: 'flex' }}>
            <p className={styles.labelTitleResum}>
              {t('newOrder.reviewCards.contractor')}:
            </p>
            <p>{contract && contract.label}</p>
          </div>
        </Grid>
        <Grid item>
          <div style={{ display: 'flex' }}>
            <p className={styles.labelTitleResum}>
              {t('newOrder.reviewCards.cnpjContractor')}:
            </p>
            <p>
              {myCnpj?.label?.replace(
                /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                '$1.$2.$3/$4-$5',
              )}
            </p>
          </div>
        </Grid>
        {campaign && (
          <Grid item>
            <div style={{ display: 'flex' }}>
              <p className={styles.labelTitleResum}>
                {t('newOrder.reviewCards.campaign')}:
              </p>
              <p> {campaign?.label}</p>
            </div>
          </Grid>
        )}
        {individuals > 0 && (
          <Grid item>
            <div style={{ display: 'flex' }}>
              <p className={styles.labelTitleResum}>Quantidade de premiados:</p>
              <p> {individuals}</p>
            </div>
          </Grid>
        )}

        {/* <Grid item>
          <div style={{ display: 'flex' }}>
            <p className={styles.labelTitleResum}>Valor total de carga:</p>
            <p> R${total}</p>
          </div>
        </Grid> */}
        {/* <Grid item xs={12}>
          <p className={styles.labelTitleResum}>
            Tipo do Produto: Cartão Pré-Pago
          </p>
        </Grid> */}
      </Grid>
      <Grid
        container
        direction="row"
        spacing={5}
        style={{ justifyContent: 'start' }}
      >
        {valueCredit && (
          <Grid item>
            <div style={{ display: 'flex' }}>
              <p className={styles.labelTitleResum}>Crédito:</p>
              <p>
                {new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })
                  .format(valueCredit)
                  .replace(',', '.')}
              </p>
            </div>
          </Grid>
        )}
        {total > 0 && (
          <Grid item>
            <div style={{ display: 'flex' }}>
              <p className={styles.labelTitleResum}>
                {t('newOrder.reviewCards.valueCharge')}:
              </p>
              <p>
                {new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })
                  .format(total)
                  .replace(',', '.')}
              </p>
            </div>
          </Grid>
        )}

        {/* <Grid item>
          <div style={{ display: 'flex' }}>
            <p className={styles.labelTitleResum}>
              {t('newOrder.reviewCards.valueCard')}
            </p>
            <p>
              {new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })
                .format(newCardValue)
                .replace(',', '.')}
            </p>
          </div>
        </Grid> */}
        {cardVincVlr > 0 && (
          <Grid item>
            <div style={{ display: 'flex' }}>
              <p className={styles.labelTitleResum}>Total cartão:</p>
              <p>
                {new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })
                  .format(cardVincVlr)
                  .replace(',', '.')}
              </p>
            </div>
          </Grid>
        )}

        {sendValue > 0 && (
          <Grid item>
            <div style={{ display: 'flex' }}>
              <p className={styles.labelTitleResum}>
                {t('newOrder.reviewCards.valueSend')}:
              </p>
              <p>
                {new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })
                  .format(sendValue)
                  .replace(',', '.')}
              </p>
            </div>
          </Grid>
        )}
        {infMin > 0 && (
          <Grid item>
            <div style={{ display: 'flex' }}>
              <p className={styles.labelTitleResum}>Carga mínima:</p>
              <p>
                {new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })
                  .format(infMin)
                  .replace(',', '.')}
              </p>
            </div>
          </Grid>
        )}
        {ticket > 0 && (
          <Grid item>
            <div style={{ display: 'flex' }}>
              <p className={styles.labelTitleResum}>Tarifa boleto:</p>
              <p>
                {new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })
                  .format(ticket)
                  .replace(',', '.')}
              </p>
            </div>
          </Grid>
        )}
        {taxPercent > 0 && (
          <Grid item>
            <div style={{ display: 'flex' }}>
              <p className={styles.labelTitleResum}>Honorários:</p>
              <p>
                {new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })
                  .format(taxPercent)
                  .replace(',', '.')}
              </p>
            </div>
          </Grid>
        )}
        {provision > 0 && (
          <Grid item>
            <div style={{ display: 'flex' }}>
              <p className={styles.labelTitleResum}>Total da NF:</p>
              <p>
                {new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })
                  .format(
                    finish
                      ? provision
                      : Number(provision) +
                          Number(ticket) +
                          Number(sendValue) +
                          Number(infMin) +
                          Number(taxPercent),
                  )
                  .replace(',', '.')}
              </p>
            </div>
          </Grid>
        )}
      </Grid>
      <Grid
        container
        spacing={1}
        direction="row"
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          paddingBottom: 10,
        }}
      >
        {/* {orders.length > 0 && (
          <Grid item xs={12}>
            <TablePremio
              orders={orders}
              totalPages={1}
              page={1}
              isCredit={!!valueCredit}
              // setPage={setPage}
              itens={10}
              // setItens={setItens}
            />
          </Grid>
        )} */}
      </Grid>
    </div>
  );
}
