/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';

// Importing MaterialUI components
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

// eslint-disable-next-line import/no-duplicates
import { differenceInDays, subDays, isSameDay } from 'date-fns';

import makeJSDateObject from '../../../../util/makeJSDateObject';

function CycleStatic({ options, value, onChange }) {
  const daysBetween = useMemo(() => {
    if (value.firstDate && value.lastDate) {
      const first = makeJSDateObject(value.firstDate);
      const last = makeJSDateObject(value.lastDate);
      return differenceInDays(last, first);
    }
    return 0;
  }, [value]);

  const handleRadio = event => {
    // eslint-disable-next-line no-shadow
    const { value } = event.target;
    const lastDate = makeJSDateObject(new Date());
    const firstDate = subDays(lastDate, +value);

    onChange({
      firstDate,
      lastDate,
    });
  };

  const isSame = useMemo(() => {
    if (value.lastDate) {
      const currentDay = makeJSDateObject(new Date());
      return isSameDay(currentDay, value.lastDate);
    }
    return false;
  }, [value.lastDate]);

  return (
    <RadioGroup value={isSame && daysBetween} onChange={handleRadio} row>
      {options.map(({ label, value: currentValue }) => {
        return (
          <FormControlLabel
            key={`radio.${currentValue}`}
            value={currentValue}
            control={<Radio color="primary" size="small" />}
            label={label}
          />
        );
      })}
    </RadioGroup>
  );
}

export default CycleStatic;
