/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { Chip, Grid, TextField } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import React, { useState, useEffect } from 'react';
import Downshift from 'downshift';

// import "./styles.css";
const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        maxHeight: '100px',
        overflowY: 'scroll',
      },
    },
    inputLabel: {
      backgroundColor: '#F2F1F1',
      color: '#41b490',
      fontWeight: 'bold',
    },
    myTextField: {
      // backgroundColor: '#none',
      // boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
      // border: 'none',
      // color: '#41b490',
      // fontWeight: 'bold',
      fontFamily: 'Open Sans',
    },
    text: {
      // backgroundColor: '#F2F1F1',
      fontFamily: 'Open Sans',
      // fontWeight: 'bold',
      // color: '#41b490',
    },
    chip: {
      margin: theme.spacing(0.5, 0.25),
    },
  }),
);
export default function MultiEmail({ state, setState }) {
  const classes = useStyles();
  // const [state, setState] = useState({
  //   items: [],
  //   value: '',
  //   error: null,
  // });

  const handleKeyDown = evt => {
    if (['Enter', 'Tab', ','].includes(evt.key)) {
      evt.preventDefault();

      const value = state.value.trim();

      if (value && isValid(value)) {
        setState({
          ...state,
          items: [...state.items, state.value],
          value: '',
        });
      }
    }
  };

  const handleChange = evt => {
    setState({
      ...state,
      value: evt.target.value,
      error: null,
    });
  };

  // eslint-disable-next-line no-unused-vars
  const handleDelete = item => {
    setState({
      ...state,
      items: state.items.filter(i => i !== item),
    });
  };

  const handlePaste = evt => {
    evt.preventDefault();

    const paste = evt.clipboardData.getData('text');
    const emails = paste.match(/[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/g);

    if (emails) {
      const toBeAdded = emails.filter(email => !isInList(email));

      setState({
        ...state,
        items: [...state.items, ...toBeAdded],
      });
    }
  };

  function isValid(email) {
    let error = null;

    if (isInList(email)) {
      error = `${email} já foi adicionado.`;
    }

    if (!isEmail(email)) {
      error = `${email} não é um endereço de email válido.`;
    }

    if (error) {
      setState({ ...state, error });

      return false;
    }

    return true;
  }

  function isInList(email) {
    return state.items.includes(email);
  }

  function isEmail(email) {
    return /[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/.test(email);
  }

  return (
    <>
      <TextField
        className={classes.text}
        fullWidth
        variant="outlined"
        placeholder="Informe o email e pressione [Enter]..."
        size="small"
        multiline
        InputProps={{
          classes: {
            notchedOutline: classes.myTextField,
            input: classes.text,
          },
          // startAdornment: (
          //   <Grid
          //     style={{
          //       display: 'flex',
          //       maxWidth: '800px',
          //       maxHeight: '150px',
          //       overflowY: 'scroll',
          //     }}
          //   >
          //     {state.items.map(dt => {
          //       return (
          //         <Grid item>
          //           <Chip
          //             label={dt}
          //             onDelete={() => handleDelete(dt)}
          //             className={classes.chip}
          //           />
          //         </Grid>
          //       );
          //     })}
          //   </Grid>
          // ),
        }}
        value={state.value}
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        onPaste={handlePaste}
      />
      {state.error && <p className="error">{state.error}</p>}
      {/* <Grid container style={{ display: 'flex' }}>
        {state.items.map(dt => {
          return (
            <Grid item>
              <Chip
                label={dt}
                onDelete={() => handleDelete(dt)}
                className={classes.chip}
              />
            </Grid>
          );
        })}
      </Grid> */}
    </>
  );
}
