/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';

import {
  CARD_BRANDS_PRODUCT,
  KIND_ACCOUNT,
  CLASS_ACCOUNT,
} from '../../../util/constants';

function PrepaidCardLeft({ form }) {
  const {
    control,
    setValue,
    formState: { dirtyFields, errors },
  } = form;

  useEffect(() => {
    if (!dirtyFields.isInternationalCard) {
      setValue('isInternationalCard', true);
    }
  }, []);

  return (
    <>
      <Grid item lg={6} md={6} sm={6} xs={12}>
        <Controller
          control={control}
          name="kindAccount"
          render={props => (
            <TextField
              select
              fullWidth
              size="small"
              variant="outlined"
              label="Tipo de conta"
              value={props.field.value}
              error={!!errors.kindAccount}
              helperText={errors.kindAccount?.message}
              onChange={e => props.field.onChange(e.target.value)}
            >
              {KIND_ACCOUNT.map(({ value, label }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={control}
          name="productBrand"
          render={props => (
            <TextField
              select
              fullWidth
              size="small"
              defaultValue=""
              label="Bandeira"
              variant="outlined"
              value={props.field.value}
              error={!!errors.productBrand}
              helperText={errors.productBrand?.message}
              onChange={e => props.field.onChange(e.target.value)}
            >
              {CARD_BRANDS_PRODUCT.digitalAccount.map(brand => (
                <MenuItem key={brand.id} value={brand.value}>
                  {brand.label}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          control={control}
          name="classAccount"
          render={props => (
            <TextField
              select
              fullWidth
              size="small"
              variant="outlined"
              label="Classe da conta"
              value={props.field.value}
              error={!!errors.productBrand}
              helperText={errors.productBrand?.message}
              onChange={e => props.field.onChange(e.target.value)}
            >
              {CLASS_ACCOUNT.map(({ label, value }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <FormControl
          component="fieldset"
          size="small"
          error={!!errors.isInternationalCard}
        >
          <FormLabel component="legend">Cartão</FormLabel>

          <Controller
            control={control}
            name="isInternationalCard"
            render={props => (
              <RadioGroup
                row
                aria-label="Possui"
                value={String(props.field.value)}
                onChange={e => {
                  props.field.onChange(e.target.value === 'true');
                }}
              >
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label="Internacional"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label="Nacional"
                />
              </RadioGroup>
            )}
          />
          <FormHelperText>{errors.isInternationalCard?.message}</FormHelperText>
        </FormControl>
      </Grid>
    </>
  );
}

export default PrepaidCardLeft;
