/* eslint-disable react/prop-types */
import React, { useState } from 'react';

import IconButton from '@material-ui/core/IconButton';

import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';

import locale from 'date-fns/locale/pt-BR';
import DateFnsUtils from '@date-io/date-fns';

import clsx from 'clsx';

import { format, isSameDay, isWithinInterval } from 'date-fns';
import useStyles from './DateRangePicker.styles';
// export default useStyles;

function makeJSDateObject(date) {
  if (date instanceof Date) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }

  throw new Error('Cannot properly parse argument passed to cloneCrossUtils');
}

function DatePickerRanger({ value, onChange, minDate, maxDate, ...others }) {
  const classes = useStyles();
  const [state, setState] = useState({
    lastChanged: 'last',
  });

  const getStateToChange = date => {
    if (state.lastChanged === 'last') {
      return {
        value: { ...value, firstDate: makeJSDateObject(date) },
        ...state,
        lastChanged: 'first',
      };
    }
    return {
      value: { ...value, lastDate: makeJSDateObject(date) },
      ...state,
      lastChanged: 'last',
    };
  };

  const disabledDates = date => {
    if (maxDate && date > makeJSDateObject(maxDate)) return false;
    if (minDate && date < makeJSDateObject(minDate)) return false;
    return true;
  };

  const handleWeekChange = date => {
    const { firstDate, lastDate } = value;

    if (!firstDate && !lastDate) {
      setState(_state => ({ ..._state, lastChanged: 'first' }));
      onChange({
        firstDate: makeJSDateObject(date),
        lastDate: makeJSDateObject(date),
      });
    } else if (
      !firstDate ||
      !lastDate ||
      (lastDate >= date && firstDate <= date)
    ) {
      // eslint-disable-next-line no-shadow
      const { value: newValue, ...others } = getStateToChange(date);
      setState(others);
      onChange(newValue);
    } else if (lastDate < date) {
      setState(_state => ({ ..._state, lastChanged: 'last' }));
      onChange({ ...value, lastDate: makeJSDateObject(date) });
    } else if (firstDate > date) {
      setState(_state => ({ ..._state, lastChanged: 'first' }));
      onChange({
        ...value,
        firstDate: makeJSDateObject(date),
      });
    }
  };

  const renderWrappedWeekDay = (date, selectedDate, dayInCurrentMonth) => {
    const dateClone = makeJSDateObject(date);

    let start = makeJSDateObject(selectedDate);
    let end = makeJSDateObject(selectedDate);

    let dayIsBetween = false;
    let isFirstDay = false;
    let isLastDay = false;

    if (value && value.firstDate && value.lastDate) {
      start = makeJSDateObject(value.firstDate);
      end = makeJSDateObject(value.lastDate);

      dayIsBetween = isWithinInterval(dateClone, { start, end });
      isFirstDay = isSameDay(dateClone, start);
      isLastDay = isSameDay(dateClone, end);
    } else if (value && value.firstDate) {
      start = makeJSDateObject(value.firstDate);

      isFirstDay = isSameDay(dateClone, start);
      isLastDay = isSameDay(dateClone, start);
      dayIsBetween = isWithinInterval(dateClone, { start, end });
    } else if (value && value.lastDate) {
      end = makeJSDateObject(value.lastDate);

      isFirstDay = isSameDay(dateClone, end);
      isLastDay = isSameDay(dateClone, end);
      dayIsBetween = isWithinInterval(dateClone, { start, end });
    }

    const wrapperClassName = clsx({
      [classes.highlight]: dayIsBetween,
      [classes.firstHighlight]: isFirstDay,
      [classes.endHighlight]: isLastDay,
    });

    const dayClassName = clsx(classes.day, {
      [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
      [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween,
    });

    return (
      <div className={wrapperClassName}>
        <IconButton
          className={dayClassName}
          disabled={!disabledDates(dateClone)}
        >
          <span> {format(dateClone, 'd')} </span>
        </IconButton>
      </div>
    );
  };

  return (
    <MuiPickersUtilsProvider locale={locale} utils={DateFnsUtils}>
      <DatePicker
        {...others}
        value={
          state.lastChanged === 'first' ? value?.firstDate : value?.lastDate
        }
        onChange={handleWeekChange}
        renderDay={renderWrappedWeekDay}
        maxDate={maxDate}
        minDate={minDate}
      />
    </MuiPickersUtilsProvider>
  );
}

export default DatePickerRanger;
