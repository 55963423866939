/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';

import {
  Button,
  CircularProgress,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';
// import Contract from '@/types/Contract';
import { localize } from '../../util/localizations';

function ContractModal({ open, onClose, contractId }) {
  const [updating, setUpdating] = useState(false);

  // const query = firestore.collection('contracts').doc(contractId);
  // const [contract, loading, error] = useDocumentData < Contract > query;
  const contract = '';
  const loading = false;
  const error = '';

  async function requestSignatures() {
    setUpdating(true);
    try {
      await axios.put(`/api/contracts/${contractId}/sign`);
    } catch (err) {
      const error1 = err;

      // eslint-disable-next-line no-console
      console.log(error1.message);
    }

    setUpdating(false);
  }

  if (loading) {
    return (
      <Dialog fullWidth maxWidth="lg" open={open} onClose={onClose}>
        <Box
          height="400px"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      </Dialog>
    );
  }

  if (!contract || error) {
    return (
      <Dialog fullWidth maxWidth="lg" open={open} onClose={onClose}>
        <DialogContent>
          <DialogContentText>
            <Box
              height="400px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              color="red"
            >
              <p>Houve um erro, por favor tente novamente mais tarde </p>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle>Resumo do Contrato</DialogTitle>
      <DialogContent>
        <Box mt={0} m={6}>
          <Grid container spacing={3}>
            <Grid item lg={4} sm={6} xs={12}>
              <Typography variant="subtitle1" color="initial">
                Razão Social:
              </Typography>
              <Typography variant="body2" color="initial">
                {contract.company.legalName}
              </Typography>
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
              <Typography variant="subtitle1" color="initial">
                Produto:
              </Typography>
              <Typography variant="body2" color="initial">
                {localize(contract.productDetails.product)}
              </Typography>
            </Grid>
            {contract.productDetails.productBrand === 'Card' && (
              <Grid item lg={4} sm={6} xs={12}>
                <Typography variant="subtitle1" color="initial">
                  Preço do Cartão:
                </Typography>
                <Typography variant="body2" color="initial">
                  {contract.productDetails.issuer}
                </Typography>
              </Grid>
            )}
            <Grid item lg={4} sm={6} xs={12}>
              <Typography variant="subtitle1" color="initial">
                Parceiros Comercial do Contrato:
              </Typography>
              <Typography variant="body2" color="initial">
                <Typography variant="body2" color="initial">
                  {contract.productDetails.commercialAgent.company.legalName}
                </Typography>
              </Typography>
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
              <Typography variant="subtitle1" color="initial">
                Nome do Produto:
              </Typography>
              <Typography variant="body2" color="initial">
                {contract.productDetails.productBrand}
              </Typography>
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
              <Typography variant="subtitle1" color="initial">
                Nº do Contrato:
              </Typography>
              <Typography variant="body2" color="initial">
                {contract.id}
              </Typography>
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
              <Typography variant="subtitle1" color="initial">
                Nome da Campanha:
              </Typography>
              <Typography variant="body2" color="initial">
                {contract.productDetails.campaign.name}
              </Typography>
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
              <Typography variant="subtitle1" color="initial">
                Taxa ADM:
              </Typography>
              <Typography variant="body2" color="initial">
                {contract.productDetails.campaign.isAdministrationRate
                  ? contract.productDetails.campaign.administrationRate
                  : contract.productDetails.campaign.administrationFee}
              </Typography>
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
              <Typography variant="subtitle1" color="initial">
                CNPJ:
              </Typography>
              <Typography variant="body2" color="initial">
                {contract.company.cnpj}
              </Typography>
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
              <Typography variant="subtitle1" color="initial">
                Status:
              </Typography>
              <Typography variant="body2" color="initial">
                {localize(contract.d4signStatus || '')}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <DialogActions>
          {updating && <CircularProgress />}
          {contract.d4signStatus === 'AwaitingUploadFile' && !updating && (
            <Button
              onClick={requestSignatures}
              variant="contained"
              color="primary"
            >
              Solicitar assinaturas
            </Button>
          )}
          {contract.d4signStatus === 'AwaitingDocumentSign' && (
            <Button disabled variant="contained" color="primary">
              Assinaturas solicitadas
            </Button>
          )}
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

export default ContractModal;
