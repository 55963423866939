/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import { Grid, Box } from '@material-ui/core';

import MyButton from '../../../components/button/MyButton';
import TableDestinations from '../../../components/tables/destinations/TableDestinations';

// import { makeStyles, createStyles } from '@mui/styles';

// const useStyles = makeStyles((theme) => createStyles({
//   myButton: {
//     // textAlign: 'center',
//     // padding: theme.spacing(6),
//     // margin: theme.spacing(1),
//     // color: theme.palette.text.secondary,
//     color: '#2a3176',
//     backgroundColor: '#f4f4f4',
//     fontWeight: 'bold',
//     // minWidth: 200,
//   },
//   myGrid: {
//     backgroundColor: '#f4f4f4',
//     margin: theme.spacing(1),
//     alignItems: 'center',
//     justifyContent: 'center',
//     display: 'flex',
//     textAlign: 'center',
//     cursor: 'pointer',
//   },
//   myGridSelected: {
//     backgroundColor: '#41b490',
//     margin: theme.spacing(1),
//     alignItems: 'center',
//     justifyContent: 'center',
//     display: 'flex',
//     textAlign: 'center',
//     cursor: 'pointer',
//   },
//   myLabel: {
//     padding: theme.spacing(6),
//     color: '#2a3176',
//     fontWeight: 'bold',
//   },
//   myLabelSelected: {
//     padding: theme.spacing(6),
//     color: '#fff',
//     fontWeight: 'bold',
//   },
// }));

export default function Destination() {
  // const classes = useStyles();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itens, setItens] = useState({ label: 25, value: 25 });
  const [notify, setNotify] = useState([]);
  const [order, setOrder] = useState({ label: 'status', value: '' });
  const [status, setStatus] = useState('');

  const search = useRef(null);

  useEffect(() => {
    setTotalPages(1);
    setNotify([
      {
        id: 1,
        destination: 'Empresa X',
        subject: '',
        data: '20/07/2020',
        created_at: 'Janaina Mello',
        canal: 'WhatsApp',
        attachment: '',
        status: 'Criado',
      },
    ]);
  }, []);

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'end',
          alignItems: 'center',
          marginBottom: 20,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <MyButton
            style={{
              width: 150,
              height: 40,
              borderRadius: 10,
              marginLeft: 30,
            }}
            // onClick={handleNext}
            label="Selecionar grupo"
          />
        </Box>
      </div>
      <Grid
        container
        spacing={1}
        style={{
          border: '2px solid #c5c5c5',
          borderRadius: 10,
          justifyContent: 'center',
          padding: 40,
          // width: 1192,
        }}
      >
        <Grid item xs={12}>
          {/* <Item sx={selected === 'element' && { backgroundColor:
          '#41b490', color: '#fff' }}>ADM</Item> */}
          {/* <Button className={classes.myButton} variant="text">ADM</Button> */}
          <TableDestinations
            itens={itens}
            setItens={setItens}
            notify={notify}
            totalPages={totalPages}
            setPage={setPage}
            setStatus={setStatus}
            search={search}
            // find={find}
            setOrder={setOrder}
          />
        </Grid>
      </Grid>
    </>
  );
}
