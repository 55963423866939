/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';

import { Grid, TextField } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Button from '../../../components/button/MyButton';

import './category.css';

const useStyles = makeStyles(() =>
  createStyles({
    myTextField: {
      backgroundColor: 'none',
      boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
      border: 'none',
      color: '#717171',
      fontWeight: 'bold',
      fontFamily: 'Open Sans',
    },
    text: {
      fontFamily: 'Open Sans',
      fontWeight: 'bold',
      color: '#717171',
    },
  }),
);

function Category({ setOpenModal }) {
  const [created, setCreated] = useState(false);
  const classes = useStyles();
  return (
    <div>
      {!created ? (
        <Grid
          container
          spacing={1}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 30,
          }}
        >
          <Grid
            container
            spacing={1}
            style={{
              justifyContent: 'space-between',
              padding: 30,
            }}
          >
            <h5 className="new-category">Nova categoria de produto</h5>
            <p className="x" onClick={() => setOpenModal(false)}>
              X
            </p>
          </Grid>
          <Grid
            container
            spacing={2}
            style={{
              border: '2px solid #c5c5c5',
              borderRadius: 10,
              justifyContent: 'center',
              margin: 10,
              padding: 30,
            }}
          >
            <Grid item xs={12}>
              <TextField
                classes={{ notchedOutline: classes.input }}
                className={classes.text}
                fullWidth
                id="outlined-basic"
                label="CAT_009"
                // label={t('Product.creditCard.table.search')}
                variant="outlined"
                size="small"
                // inputRef={search}
                InputProps={{
                  classes: {
                    notchedOutline: classes.myTextField,
                    input: classes.text,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                classes={{ notchedOutline: classes.input }}
                className={classes.text}
                fullWidth
                id="outlined-basic"
                label="Nome da categoria"
                // label={t('Product.creditCard.table.search')}
                variant="outlined"
                size="small"
                // inputRef={search}
                InputProps={{
                  classes: {
                    notchedOutline: classes.myTextField,
                    input: classes.text,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                classes={{ notchedOutline: classes.input }}
                className={classes.text}
                fullWidth
                id="outlined-basic"
                label="Tipo do produto"
                // label={t('Product.creditCard.table.search')}
                variant="outlined"
                size="small"
                // inputRef={search}
                InputProps={{
                  classes: {
                    notchedOutline: classes.myTextField,
                    input: classes.text,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} onClick={() => setCreated(true)}>
              <TextField
                classes={{ notchedOutline: classes.input }}
                className={classes.text}
                fullWidth
                id="outlined-basic"
                label="Fornecedor"
                // label={t('Product.creditCard.table.search')}
                variant="outlined"
                size="small"
                // inputRef={search}
                InputProps={{
                  classes: {
                    notchedOutline: classes.myTextField,
                    input: classes.text,
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={1}
            style={{
              justifyContent: 'space-between',
              padding: 40,
            }}
          >
            <h6
              className="txt-category-back"
              style={{ cursor: 'pointer' }}
              onClick={() => setOpenModal(false)}
            >
              Voltar para a lista de categorias
            </h6>
            <Button
              style={{
                // marginRight: 20,
                height: 40,
                width: 100,
              }}
              onClick={() => setCreated(true)}
              label="Criar"
            />
          </Grid>
        </Grid>
      ) : (
        <div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'end',
              alignItems: 'center',
            }}
          >
            <p className="x" onClick={() => setOpenModal(false)}>
              X
            </p>
          </div>
          <div
            className="col-sm-12"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: 60,
              marginBottom: 40,
            }}
          >
            <img
              src="./img/checkOk.svg"
              alt="ok"
              className="img-category-check"
            />
            <h4 className="txt-category-success" style={{ marginTop: 30 }}>
              Categoria criada com sucesso!
            </h4>
            <Button
              style={{
                marginTop: 30,
                marginBottom: 20,
                marginRight: 20,
                height: 40,
                width: 109,
                // fontSize: 24,
              }}
              onClick={() => setOpenModal(false)}
              label="OK"
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default Category;
