/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
/* eslint-disable react/style-prop-object */
/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable no-multi-spaces */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable max-len */
import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
// import { Dialog } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import routing from '../../service/routes/routing';
// import MyButton from '../../components/button/MyButton';
import styles from '../../styles/Layout.module.css';
// import TableOrder from '../../components/tables/orders/TableOrder';
// import TableOrder from '../../components/tables/orders/TableOrder';
// import NewOrder from './newOrder/NewOrder';
import Loading from '../../components/loading/Loading';
import PemissionComponent from '../../components/permissionComponent/PermissionComponent';

import api from '../../service/axios/api';

import './orderMain.css';
import UserRoles from '../../service/acl/UserRoles';
import { TableReport } from '../../components/tables/reports/TableReport';

// eslint-disable-next-line react/prop-types
const ReportMain = function ({
  handleDrawerClose /* , openModal, setOpenModal */,
}) {
  const history = useHistory();
  const { t } = useTranslation();
  // const [openModal, setOpenModal] = useState(false);
  const [orders, setOrders] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [msg, setMsg] = useState('Buscando pedidos...');
  const [itens, setItens] = useState({
    label: '25',
    value: 25,
  });
  const [orderBy, setOrderBy] = useState({ label: 'status', value: '' });
  const [loading, setLoading] = useState(false);

  const [status, setStatus] = useState({ label: '', value: '' });
  const [product, setProduct] = useState({ label: '', value: '' });
  const search = useRef(null);
  const [chipData, setChipData] = React.useState([]);

  const find = extra => {
    setOrders([]);
    const cancel = new AbortController();
    setMsg('Buscando relatórios...');
    setLoading(true);
    // setLoading(false);
    api.joycode
      .get(
        // search?.current?.value,
        extra,
        chipData,
        status.value,
        itens.value,
        page,
        // orderBy.value,
        product.value,
        cancel,
      )
      .then(res => {
        console.log(res.docs);
        setTotalPages(res.pages);
        setOrders(res.docs);
        setLoading(false);
      })
      .catch(err => console.log(err))
      .finally(() => {
        setLoading(false);
      });
    // api.order
    //   .get(
    //     // search?.current?.value,
    //     extra,
    //     chipData,
    //     status.value,
    //     itens.value,
    //     page,
    //     orderBy.value,
    //     product.value,
    //     cancel,
    //   )
    //   .then(res => {
    //     console.log(res.docs);
    //     setTotalPages(res.pages);
    //     setOrders(res.docs);
    //     setLoading(false);
    //   })
    //   .catch(err => {
    //     setLoading(false);
    //     console.log(err);
    //   });
  };
  useEffect(() => {
    find();
  }, [page, itens, status, orderBy, product]);

  return (
    <div onClick={handleDrawerClose}>
      <Loading open={loading} msg={msg} />
      <div className="col-sm-12" style={{ marginTop: 50 }}>
        <div
          className="col-sm-12"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'start',
          }}
        >
          <div>
            <p
              className={styles.textMenu}
              onKeyDown={() => history.push(routing.dashboard)}
              onClick={() => history.push(routing.dashboard)}
            >
              {t('Order.initialMenu')}
            </p>
          </div>
        </div>
        <div
          className="col-sm-12"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <h4 className={styles.textTitle}>Histórico de relatórios</h4>
          <PemissionComponent role={UserRoles.ORDERS_NEW}>
            <div />
          </PemissionComponent>
        </div>
        <div>
          <TableReport
            setLoading={setLoading}
            setMsg={setMsg}
            orders={orders}
            totalPages={totalPages}
            page={page}
            setPage={setPage}
            setOrderBy={setOrderBy}
            itens={itens}
            setItens={setItens}
            setStatus={setStatus}
            product={product}
            setProduct={setProduct}
            chipData={chipData}
            setChipData={setChipData}
            find={find}
          />
        </div>
      </div>
    </div>
  );
};

export default ReportMain;
