/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect, useRef, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import RichTextEditor from 'react-rte';
// import MUIRichTextEditor from 'mui-rte';
// import { stateToHTML } from 'draft-js-export-html';

import {
  Grid,
  TextField,
  Divider,
  Dialog,
  Checkbox,
  Chip,
} from '@material-ui/core';
import { makeStyles, createStyles, styled } from '@material-ui/core/styles';
import styles from '../../../styles/Layout.module.css';
import routing from '../../../service/routes/routing';
import Button from '../../../components/button/MyButton';
import EmailSendSuccess from './EmailSendSuccess';
import api from '../../../service/axios/api';
import Loading from '../../../components/loading/Loading';
import FlashContext from '../../../components/contexts/FlashContext';
import MultiEmail from './MultiEmail';

const useStyles = makeStyles(() =>
  createStyles({
    inputLabel: {
      backgroundColor: '#F2F1F1',
      color: '#41b490',
      fontWeight: 'bold',
    },
    myTextField: {
      // backgroundColor: '#none',
      // boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
      // border: 'none',
      // color: '#41b490',
      // fontWeight: 'bold',
      fontFamily: 'Open Sans',
    },
    text: {
      // backgroundColor: '#F2F1F1',
      fontFamily: 'Open Sans',
      // fontWeight: 'bold',
      // color: '#41b490',
    },
  }),
);

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark'
        ? 'rgba(57,75,89,.5)'
        : 'rgba(206,217,224,.5)',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#137cbd',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#106ba3',
  },
});

const toolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: [
    'INLINE_STYLE_BUTTONS',
    'BLOCK_TYPE_BUTTONS',
    // 'LINK_BUTTONS',
    'BLOCK_TYPE_DROPDOWN',
    'HISTORY_BUTTONS',
    // 'IMAGE_BUTTON',
  ],
  INLINE_STYLE_BUTTONS: [
    { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
    { label: 'Italic', style: 'ITALIC' },
    { label: 'Underline', style: 'UNDERLINE' },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: 'Normal', style: 'unstyled' },
    { label: 'Heading Large', style: 'header-one' },
    { label: 'Heading Medium', style: 'header-two' },
    { label: 'Heading Small', style: 'header-three' },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: 'UL', style: 'unordered-list-item' },
    { label: 'OL', style: 'ordered-list-item' },
  ],
};

export default function EmailTextNotification(props) {
  // eslint-disable-next-line no-unused-vars
  const classes = useStyles();
  const { setOpenModal } = props;
  const [loading, setLoading] = useState(false);
  const [dest, setDest] = useState('');
  const [fileName, setFileName] = useState('');
  const history = useHistory();
  const [myModal, setOpenMyModal] = useState(false);
  const [msg, setMsg] = useState(RichTextEditor.createEmptyValue());
  const [subject, setSubject] = useState('');
  const rteRef = useRef(null);
  const refAttachment = useRef(null);
  const { notifyError } = useContext(FlashContext);
  const [disable, setDisable] = useState(false);

  const [userBase, setUserBase] = useState(false);
  const [sendCredential, setSendCredential] = useState(false);
  const [systemLink, setSystemLink] = useState(false);

  const [state, setState] = useState({
    items: [],
    value: '',
    error: null,
  });

  useEffect(() => {
    if (state.items.length > 0) {
      setDisable(true);
      setUserBase(false);
      setSendCredential(false);
      setSystemLink(false);
    } else {
      setDisable(false);
    }
  }, [state]);

  const save = () => {
    const html = msg.toString('html');
    if (html && subject) {
      setLoading(true);
      api.notification
        .send(
          subject,
          html,
          state.items,
          refAttachment.current.files[0],
          sendCredential,
          systemLink,
          userBase,
        )
        .then(() => {
          setOpenMyModal(true);
        })
        .catch(err => notifyError(err?.message))
        .finally(() => setLoading(false));
    } else {
      notifyError('Informe todos os campos para envio de email');
    }
  };

  const handleDelete = item => {
    setState({
      ...state,
      items: state.items.filter(i => i !== item),
    });
  };

  const cleanFile = () => {
    refAttachment.current.files = null;
    setFileName('');
  };
  return (
    <>
      <Loading open={loading} msg="Enviando notificação..." />
      <div
        className="col-sm-12"
        style={{ padding: 40, paddingRight: 80, paddingLeft: 80 }}
      >
        <Grid
          container
          spacing={1}
          style={{
            border: '2px solid #c5c5c5',
            borderRadius: 10,
            justifyContent: 'center',
            alignItems: 'center',
            padding: 40,
          }}
        >
          <Grid item sm={2}>
            <h6 className={styles.textMailLabels}>Assunto</h6>
          </Grid>
          <Grid item sm={10}>
            <TextField
              className={classes.text}
              fullWidth
              variant="outlined"
              size="small"
              InputProps={{
                classes: {
                  notchedOutline: classes.myTextField,
                  input: classes.text,
                },
              }}
              value={subject}
              onChange={e => setSubject(e.target.value)}
            />
          </Grid>
          <Grid item sm={2} style={{ marginTop: 10 }}>
            <h6 className={styles.textMailLabels}>Destinatários</h6>
          </Grid>
          <Grid item sm={10} style={{ marginTop: 10 }}>
            <MultiEmail state={state} setState={setState} />
          </Grid>
          <Grid container>
            {state.items.map(dt => {
              return (
                <Grid item>
                  <Chip
                    label={dt}
                    onDelete={() => handleDelete(dt)}
                    className={classes.chip}
                  />
                </Grid>
              );
            })}
          </Grid>

          <Grid
            container
            spacing={1}
            style={{
              justifyContent: 'end',
              alignItems: 'center',
              marginTop: 10,
            }}
          >
            <Grid>
              <Checkbox
                checked={userBase}
                onChange={() => setUserBase(!userBase)}
                checkedIcon={<BpCheckedIcon />}
                icon={<BpIcon />}
                disabled={disable}
              />
              BD Usuários
            </Grid>
            <Grid>
              <Checkbox
                checked={sendCredential}
                onChange={() => setSendCredential(!sendCredential)}
                checkedIcon={<BpCheckedIcon />}
                icon={<BpIcon />}
                disabled={disable}
              />
              Credencial
            </Grid>
            <Grid>
              <Checkbox
                checked={systemLink}
                onChange={() => setSystemLink(!systemLink)}
                checkedIcon={<BpCheckedIcon />}
                icon={<BpIcon />}
                disabled={disable}
              />
              Link sistema
            </Grid>

            {fileName ? (
              <Grid
                className={styles.labelattachment}
                item
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                  marginRight: 30,
                  color: '#b44141',
                }}
                onClick={cleanFile}
              >
                {/* <img
                  src="./img/attachment.svg"
                  alt="logo"
                  style={{ width: '15%', height: '15%' }}
                /> */}
              </Grid>
            ) : (
              <Grid
                className={styles.labelattachment}
                item
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                  marginRight: 30,
                }}
                onClick={() => refAttachment.current.click()}
              >
                <img
                  src="./img/attachment.svg"
                  alt="logo"
                  style={{ width: '15%', height: '15%' }}
                />
                Anexar arquivo
              </Grid>
            )}
            {/* <Grid className={styles.labelattachment} item>
              Enviar para um grupo de clientes
            </Grid> */}
          </Grid>
          <input
            ref={refAttachment}
            type="file"
            style={{
              opacity: 0,
            }}
            // accept="media_type"
            onChange={() => setFileName(refAttachment.current.files[0].name)}
            className="form-control"
            id="inputNumSerie"
            placeholder="Arquivo DWG do projeto"
          />
          <Grid item sm={12}>
            {fileName && (
              <>
                Anexo: {fileName}{' '}
                <p
                  style={{
                    cursor: 'pointer',
                    fontWeight: 'bold',
                    color: '#b44141',
                  }}
                  onClick={cleanFile}
                >
                  Apagar anexo
                </p>
              </>
            )}
            <Divider />
            <RichTextEditor
              toolbarConfig={toolbarConfig}
              value={msg}
              onChange={e => setMsg(e)}
              editorStyle={{ minHeight: 250 }}
            />
            {/* <MUIRichTextEditor
              ref={rteRef}
              label="Digite a notificação aqui..."
              onSave={save}
              inlineToolbar
              onChange={(e) => setMsg(e)}
            /> */}
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: 30,
          }}
        >
          {/* <Grid>
            <Button
              style={{
                height: 40,
                width: 180,
                fontSize: 14,
                backgroundColor: '#005D9F',
                marginRight: 10,
              }}
              // onClick={save}
              label="Salvar como Modelo"
            />
          </Grid> */}
          <Grid iten xs={12} style={{ textAlign: 'end', marginTop: -20 }}>
            <p>*Até 10 emails envio imediato, acima em até 30 horas.</p>
          </Grid>
          <Grid
            container
            style={{
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'center',
            }}
          >
            <Grid />
            <Grid>
              <h6
                style={{ marginRight: 30, cursor: 'pointer' }}
                onClick={() => setOpenModal(false)}
              >
                Cancelar
              </h6>
            </Grid>
            <Grid>
              <Button
                style={{
                  height: 40,
                  width: 80,
                  // fontSize: 14,
                  marginRight: 10,
                }}
                onClick={save}
                label="Enviar"
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Dialog
        onClose={() => setOpenMyModal(false)}
        open={myModal}
        // width={1192}
        // height={776}
        fullWidth
        // maxWidth="1192"
      >
        <EmailSendSuccess
          setOpenMyModal={setOpenMyModal}
          setOpenModal={setOpenModal}
          state={state}
        />
        {/* <SubjectNotification setOpenModal={setOpenModal} /> */}
      </Dialog>
    </>
  );
}
