/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
// import { useTranslation } from 'react-i18next';
import {
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Paper,
} from '@material-ui/core';
import FlashContext from '../../components/contexts/FlashContext';
// import { useHistory } from 'react-router-dom';

// import mastercard from './img/mastercard.svg';
// import moment from 'moment-timezone';
import Loading from '../../components/loading/Loading';
import api from '../../service/axios/api';

function createData(nameDoc, safeName, statusName, uuidDoc) {
  return {
    nameDoc,
    safeName,
    statusName,
    uuidDoc,
  };
}

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  table: {
    minWidth: 1100,
  },
});

// const StyledTableCell = withStyles(() => ({
//   head: {
//     // backgroundColor: theme.palette.common.black,
//     // color: theme.palette.common.white,
//   },
//   body: {
//     fontSize: 14,
//   },
// }))(TableCell);

// const StyledTableRow = withStyles(theme => ({
//   root: {
//     '&:nth-of-type(odd)': {
//       backgroundColor: theme.palette.action.hover,
//     },
//   },
// }))(TableRow);
const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#8884d8',
    fontFamily: 'Open Sans',
    color: theme.palette.common.white,
  },
  body: {
    color: '#717171',
    fontFamily: 'Open Sans',
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:hover': {
      backgroundColor: '#41b490',
      color: '#41b490',
    },
  },
}))(TableRow);

function EnhancedTableHead(props) {
  // eslint-disable-next-line no-unused-vars
  const { onSelectAllClick, numSelected, rowCount, onRequestSort } = props;
  // const { t } = useTranslation();

  return (
    <TableHead>
      <TableRow>
        <StyledTableCell align="left" component="th">
          Nome Arquivo
        </StyledTableCell>
        <StyledTableCell align="center" component="th">
          Cofre Doc4Sign
        </StyledTableCell>
        <StyledTableCell align="right" component="th">
          Status
        </StyledTableCell>
      </TableRow>
    </TableHead>
  );
}

export default function Contratos4Sign({ docs }) {
  // const { t } = useTranslation();
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const { notifyError } = useContext(FlashContext);

  useEffect(() => {
    const list = [];
    docs.forEach((d, i) => {
      if (i !== 0) {
        list.push(createData(d.nameDoc, d.safeName, d.statusName, d.uuidDoc));
      }
    });
    setRows(list);
  }, [docs]);

  const requestContract = uid => {
    setLoading(true);
    const contractCancel = new AbortController();
    api.contract
      .getContract4Sign(contractCancel, uid)
      .then(res => {
        if (res.url) {
          window.open(res.url, '_blank');
        }
      })
      .catch(err => {
        notifyError(err.message);
        // console.log(err);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Loading open={loading} msg="Buscando contrato na Doc4Sign...." />
      <Paper className={classes.root}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <EnhancedTableHead />
            <TableBody>
              {rows.map(row => {
                return (
                  <StyledTableRow
                    tabIndex={-1}
                    key={row.uuidDoc}
                    style={{ cursor: 'pointer' }}
                  >
                    <StyledTableCell>
                      <p
                        style={{
                          cursor: 'pointer',
                          textDecoration: 'underline',
                        }}
                        onClick={() => requestContract(row.uuidDoc)}
                      >
                        {row.nameDoc}
                      </p>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.safeName}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.statusName}
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}
