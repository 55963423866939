/* eslint-disable no-await-in-loop */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/function-component-definition */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
// import { alpha, styled } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import moment from 'moment-timezone';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import Select from 'react-select';
import MaterialSelect from '@material-ui/core/Select';
import { Pagination } from '@material-ui/lab';
import {
  Grid,
  Box,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableHead,
  Toolbar,
  Typography,
  Paper,
  Checkbox,
  IconButton,
  Tooltip,
  Menu,
  MenuItem,
  Dialog,
  TableCell,
  styled,
  InputBase,
} from '@material-ui/core';
import { withStyles, makeStyles, createStyles } from '@material-ui/core/styles';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import locale from 'date-fns/locale/pt-BR';
import DateFnsUtils from '@date-io/date-fns';
import _ from 'lodash';
import PermissionComponent from '../../permissionComponent/PermissionComponent';
import api from '../../../service/axios/api';
import Status from '../../../service/translateStatus';
import Button from '../../button/MyButton';
import Loading from '../../loading/Loading';
import FlashContext from '../../contexts/FlashContext';
import routing from '../../../service/routes/routing';

import SelectOptions from './SelectOptions1';

import './tableOrder.css';
import UserRoles from '../../../service/acl/UserRoles';

const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    width: 240,
    backgroundColor: 'none',
    boxShadow: 'none',
    // border: 'none',
    // fontWeight: 'bold',
    fontFamily: 'Open Sans',
    // fontSize: 24,
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  option: (provided, state) => ({
    ...provided,
    fontWeight: 'bold',
    fontFamily: 'Open Sans',
    // color: state.data.color,
    // backgroundColor: state.data.bgcolor,
    // fontSize: 24,
  }),
  placeholder: (provided, state) => ({
    fontWeight: 'bold',
    fontFamily: 'Open Sans',
    marginTop: -20,
    // paddingBottom: 20,
    // fontSize: 24,
    // marginBottom: 30,
  }),
  menuPortal: base => ({ ...base, zIndex: 99999 }),
};

const useStyles = makeStyles(theme =>
  createStyles({
    textNotification: {
      textAlign: 'center',
      fontFamily: 'Open Sans',
      fontSize: 14,
      fontWeight: 'bold',
      lineHeight: 'auto',
      color: '#707070',
      '&:hover': {
        backgroundColor: '#41b490',
        color: '#fff',
      },
    },
  }),
);

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#005D9F',
    fontFamily: 'Open Sans',
    color: theme.palette.common.white,
  },
  body: {
    color: '#717171',
    fontFamily: 'Open Sans',
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:hover': {
      backgroundColor: '#41b490',
      color: '#41b490',
    },
  },
}))(TableRow);

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark'
        ? 'rgba(57,75,89,.5)'
        : 'rgba(206,217,224,.5)',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#137cbd',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#106ba3',
  },
});

const options = [
  {
    label: '25',
    value: 25,
  },
  {
    label: '50',
    value: 50,
  },
  {
    label: '100',
    value: 100,
  },
];

const EnhancedTableHead = props => {
  const { t: header } = useTranslation();
  const { onSelectAllClick, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <StyledTableCell component="th">
          {header('Order.table.order')}
        </StyledTableCell>
        <StyledTableCell component="th">
          {header('Order.table.numberNf')}
        </StyledTableCell>
        <StyledTableCell component="th">
          {header('Order.table.payment')}
        </StyledTableCell>
        <StyledTableCell component="th">
          {header('Order.table.rz')}
        </StyledTableCell>
        <StyledTableCell component="th">
          {header('Order.table.orderName')}
        </StyledTableCell>
        <StyledTableCell component="th">
          {header('Order.table.product')}
        </StyledTableCell>
        <StyledTableCell component="th">
          {header('Order.table.value')}
        </StyledTableCell>
        <StyledTableCell component="th">
          {header('Order.table.created')}
        </StyledTableCell>
        <StyledTableCell component="th">
          {header('Order.table.status')}
        </StyledTableCell>
        <StyledTableCell padding="checkbox">
          {/* <Checkbox
            // color="primary"
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          /> */}
        </StyledTableCell>
        <StyledTableCell component="th" align="right">
          {/* <MoreVertIcon /> */}
        </StyledTableCell>
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  // onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  // order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  // orderBy: PropTypes.string.isRequired,
  // rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = function (props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: '#f3f3f3',
          // (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable({
  orders,
  totalPages,
  setPage,
  page,
  itens,
  setItens,
  setStatus,
  product,
  setProduct,
  setOrderBy,
  chipData,
  setChipData,
  find,
  setLoading,
  setMsg,
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const { notifyError, notifySuccess } = useContext(FlashContext);
  const [selected, setSelected] = React.useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openDuplicate, setOpenDuplicate] = useState(false);
  const [openCancel, setOpenCancel] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [menuOptions, setMenuOptions] = useState(null);

  const [duplicateId, setDuplicateId] = useState();
  const [cancelId, setCancelId] = useState();

  const [openModalMultiPayment, setOpenModalMultiPayment] = useState(false);
  const [openModalPayment, setOpenModalPayment] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [selectedDate, handleDateChange] = useState(new Date());
  const [bankId, setBankId] = useState(0);
  const [banks, setBanks] = useState([]);
  const [orderForPayment, setOrderForPayment] = useState();
  const [orderForMultPayment, setOrderForMultPayment] = useState([]);
  const [errors, setErrors] = useState([]);

  const [allBanks, setAllBanks] = useState(false);
  const [allDate, setAllDate] = useState(false);

  async function multiPayment(payOrders) {
    for (const pay in payOrders) {
      try {
        await api.order.payment(
          payOrders[pay].id,
          payOrders[pay].datePayment,
          payOrders[pay].bank,
        );
        setSelected([]);
        notifySuccess(`NF ${payOrders[pay].nf} paga!`);
      } catch (e) {
        notifyError(`Erro ao informar pagamento da NF: ${payOrders[pay].nf}`);
        setErrors([...errors, e]);
      }
    }
    find();
    setAllBanks(false);
    setAllDate(false);
    setOpenModalMultiPayment(false);
    setPaymentLoading(false);
  }

  const informPayment = order => {
    if (selected.length > 1) {
      const list = [];
      selected.forEach(s => {
        list.push({
          nf: s?.invoiceNumber,
          pd: s.pd,
          id: s.id,
          datePayment: new Date(),
          bank: { label: '', value: 0 },
        });
        setOrderForMultPayment(list);
      });
      setOpenModalMultiPayment(true);
    } else {
      setOrderForPayment(order);
      setOpenModalPayment(true);
    }
  };

  const handleMenu = (row, event) => {
    setMenuOptions(row);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const duplicateInf = id => {
    setDuplicateId(id);
    setAnchorEl(null);
    setOpenDuplicate(true);
  };

  const cancelInf = id => {
    setCancelId(id);
    setAnchorEl(null);
    setOpenCancel(true);
  };

  const duplicateOrder = () => {
    if (duplicateId) {
      api.order
        .duplicate(duplicateId)
        .then(res => {
          setDuplicateId();
          setOpenDuplicate(false);
        })
        .catch(err => console.log(err))
        .finally(() => window.location.reload());
    }
  };

  const cancelOrder = () => {
    if (cancelId) {
      setCancelLoading(true);
      api.order
        .cancel(cancelId)
        .then(res => {
          setCancelId();
          setOpenCancel(false);
        })
        .catch(err => {
          notifyError(err?.message);
        })
        .finally(() => {
          setCancelLoading(false);
          find();
        });
      // .finally(() => window.location.reload());
    }
  };

  const [rows, setRows] = useState([]);

  const classes = useStyles();

  const paymentOrder = () => {
    if (orderForPayment && orderForPayment.pd) {
      setPaymentLoading(true);
      api.order
        .payment(orderForPayment.id, selectedDate, bankId)
        .then(r => {
          setPaymentLoading(false);
          setOpenModalPayment(false);
          setSelected([]);
          find();
        })
        .catch(err => {
          notifyError(err.message);
          setPaymentLoading(false);
        });
    }
  };

  const paymentMultiOrder = () => {
    if (orderForMultPayment.length > 0) {
      // setPaymentLoading(true);
      multiPayment(orderForMultPayment);
    }
  };

  useEffect(() => {
    const cancel = new AbortController();
    const query = '?status=Active';
    api.banks
      .get(cancel, query)
      .then(res => {
        const list = [];
        res.retorno.forEach((b, i) => {
          if (i === 0) setBankId({ label: b.bank, value: b.id });
          list.push({ label: b.bank, value: b.id });
        });
        setBanks(list);
      })
      .catch(err => console.log(err));
  }, []);

  useEffect(() => {
    const listOrder = [];
    orders.forEach(or => {
      listOrder.push({
        id: or.id,
        name: or.order_name,
        nf: or.gcs_pdf && (
          <div
            style={{ cursor: 'pointer', color: 'blue' }}
            onClick={() => window.open(or.gcs_pdf, '_blank')}
          >
            {or.invoice_number}
          </div>
        ),
        pgto:
          or.date_payment && moment.utc(or.date_payment).format('DD/MM/yyyy'),
        rs: or.company_name,
        pd: or.order_number,
        product: or.product_type,
        vlr: `R$ ${or.provision}`,
        status: or.status,
        db: or.debitNotes,
        all: or,
        invoiceNumber: or.invoice_number,
        created: or.created_at,
      });
    });
    setRows(listOrder);
  }, [orders]);

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, row) => {
    // const selectedIndex = selected.indexOf(row.id);
    const selectedIndex = selected.indexOf(selected.find(s => s.id === row.id));
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setSelected([]);
    setPage(newPage);
  };

  const isSelected = id => selected.indexOf(id) !== -1;

  const handleChangeMultPayment = (date, nf) => {
    const orig = _.cloneDeep(orderForMultPayment);
    const myfind = orig.find(o => o.nf === nf);
    if (myfind) {
      myfind.datePayment = date;
    }
    setOrderForMultPayment(orig);
  };

  const handleChangeMultPaymentBank = (bank, nf) => {
    const orig = _.cloneDeep(orderForMultPayment);
    const myfind = orig.find(o => o.nf === nf);
    if (myfind) {
      myfind.bank = bank.target.value;
    }
    setOrderForMultPayment(orig);
  };

  const handleAllBanks = e => {
    if (e.target.checked) {
      const { bank } = orderForMultPayment[0];
      const tmp = [];
      orderForMultPayment.forEach((o, i) => {
        if (i > 0) {
          tmp.push({
            bank,
            datePayment: o.datePayment,
            nf: o.nf,
            pd: o.pd,
            id: o.id,
          });
        } else {
          tmp.push(o);
        }
      });
      setOrderForMultPayment(tmp);
    }
    setAllBanks(e.target.checked);
  };

  const handleAllDate = e => {
    if (e.target.checked) {
      const { datePayment } = orderForMultPayment[0];
      const tmp = [];
      orderForMultPayment.forEach((o, i) => {
        if (i > 0) {
          tmp.push({
            bank: o.bank,
            datePayment,
            nf: o.nf,
            pd: o.pd,
            id: o.id,
          });
        } else {
          tmp.push(o);
        }
      });
      setOrderForMultPayment(tmp);
    }
    setAllDate(e.target.checked);
  };

  const downloadPlan = url => {
    setAnchorEl(null);
    setMsg('Buscando XML da nota fiscal....');
    setLoading(true);
    const nameFinal = url.replace(
      'https://epp-storage.s3.amazonaws.com/nf/',
      '',
    );
    api.invoice
      .xmlReport(nameFinal)
      .then(res => {
        const element = document.createElement('a');
        element.setAttribute(
          'href',
          `data:text/xml;charset=utf-8,${encodeURIComponent(res.data.data)}`,
        );
        element.setAttribute('download', nameFinal);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      {/* <Grid item> */}
      <SelectOptions
        setStatus={setStatus}
        setChipData={setChipData}
        chipData={chipData}
        find={find}
        setOrderBy={setOrderBy}
        product={product}
        setProduct={setProduct}
        setSelectec={setSelected}
      />
      {/* </Grid> */}
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size="medium"
            >
              <EnhancedTableHead
                numSelected={selected.length}
                onSelectAllClick={handleSelectAllClick}
                rowCount={rows.length}
                handleMenu={handleMenu}
              />
              <TableBody>
                {rows ? (
                  rows.length > 0 ? (
                    rows.map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <StyledTableRow tabIndex={-1} key={row.id}>
                          <StyledTableCell>{row.pd}</StyledTableCell>
                          <StyledTableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            // padding="none"
                          >
                            <p
                              // key={r.nd_number}
                              style={{ cursor: 'pointer', color: 'blue' }}
                              className="modal-box-body-value-label"
                              // onClick={() => window.open(r.link_debit)}
                            >
                              {row.nf}
                            </p>
                            {/* {api.currentUser?.user?.role?.id !== '1' &&
                              row.db &&
                              row.db.length > 0 &&
                              row.db.map(r => {
                                return (
                                  <p
                                    key={r.nd_number}
                                    style={{ cursor: 'pointer' }}
                                    className="modal-box-body-value-label"
                                    onClick={() => window.open(r.link_debit)}
                                  >
                                    {r.nd_number}
                                  </p>
                                );
                              })} */}
                            {api.currentUser?.user?.role?.id !== '1'
                              ? row.db &&
                                row.db.length > 0 &&
                                row.db.map(r => {
                                  return (
                                    <p
                                      key={r.nd_number}
                                      style={{
                                        cursor: 'pointer',
                                        color: 'blue',
                                      }}
                                      className="modal-box-body-value-label"
                                      onClick={() => window.open(r.gcs_pdf)}
                                    >
                                      {r.nd_number}
                                    </p>
                                  );
                                })
                              : api.currentUser?.user?.role?.id !== '3' &&
                                row.db &&
                                row.db.length > 0 &&
                                row.db.map(r => {
                                  if (r.gcs_pdf) {
                                    return (
                                      <p
                                        key={r.nd_number}
                                        style={{
                                          cursor: 'pointer',
                                          color: 'blue',
                                        }}
                                        className="modal-box-body-value-label"
                                        onClick={() => window.open(r.gcs_pdf)}
                                      >
                                        {r.nd_number}
                                      </p>
                                    );
                                  }
                                })}
                          </StyledTableCell>
                          <StyledTableCell>{row.pgto}</StyledTableCell>
                          <StyledTableCell>{row.rs}</StyledTableCell>
                          <StyledTableCell>{row.name}</StyledTableCell>
                          <StyledTableCell>
                            {Status(row.product, t)}
                          </StyledTableCell>
                          <StyledTableCell>{row.vlr}</StyledTableCell>
                          <StyledTableCell>
                            {row.created &&
                              moment
                                .utc(row.created)
                                // .tz('America/Sao_Paulo')
                                .format('DD/MM/YYYY HH:mm')}
                          </StyledTableCell>
                          <StyledTableCell>
                            {Status(row.status, t)}
                          </StyledTableCell>
                          <StyledTableCell padding="checkbox">
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              {row.status === 'AwaitingPayment' && (
                                <PermissionComponent
                                  role={UserRoles.ORDERS_PAYMENT}
                                >
                                  <Button
                                    style={{
                                      width: 50,
                                      height: 30,
                                      backgroundColor: '#009BEA',
                                    }}
                                    label={t('OrderDetails.paidOut')}
                                    onClick={() => informPayment(row)}
                                  />
                                  <Checkbox
                                    // color="primary"
                                    checkedIcon={<BpCheckedIcon />}
                                    icon={<BpIcon />}
                                    // indeterminate={numSelected > 0 && numSelected < rowCount}
                                    // checked={rowCount > 0 && numSelected === rowCount}
                                    onChange={e => handleClick(e, row)}
                                    inputProps={{
                                      'aria-label': 'select all desserts',
                                    }}
                                  />
                                </PermissionComponent>
                              )}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell component="th" align="right">
                            <MoreVertIcon
                              onClick={e => handleMenu(row, e)}
                              fontSize="small"
                              style={{ cursor: 'pointer' }}
                            />
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })
                  ) : (
                    <StyledTableRow>
                      <StyledTableCell colSpan={9} align="center">
                        {t('Order.table.noResult')}
                      </StyledTableCell>
                    </StyledTableRow>
                  )
                ) : (
                  <StyledTableRow>
                    <StyledTableCell colSpan={9} align="center" />
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Menu
            id="menu-appbar"
            style={{ borderRadius: '25px' }}
            anchorEl={anchorEl}
            //  anchorOrigin={{
            //    vertical: 'top',
            //    horizontal: 'right',
            //  }}
            keepMounted
            //  transformOrigin={{
            //    vertical: 'top',
            //    horizontal: 'right',
            //  }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <PermissionComponent role={UserRoles.ORDERS_DUPLICATE}>
              <MenuItem
                className={classes.textNotification}
                onClick={() => duplicateInf(menuOptions.id)}
              >
                {t('Order.table.duplicateOrder')}
              </MenuItem>
            </PermissionComponent>
            <MenuItem
              className={classes.textNotification}
              // onClick={() => setOpenModal(true)}
              onClick={() => history.push(routing.orderDetails(menuOptions.id))}
            >
              {t('Order.table.orderDetails')}
            </MenuItem>
            <PermissionComponent role={UserRoles.ORDERS_CANCEL}>
              <MenuItem
                // disabled={!menuOptions?.all?.Invoice?.invoice_number}
                // disabled={
                //   !menuOptions?.invoiceNumber &&
                //   menuOptions?.status !== 'Created' &&
                //   menuOptions?.db?.length === 0
                // }
                className={classes.textNotification}
                onClick={() => cancelInf(menuOptions.id)}
              >
                {t('Order.table.orderCancel')}
              </MenuItem>
            </PermissionComponent>
            {menuOptions?.all?.gcs_xml && (
              <MenuItem
                className={classes.textNotification}
                onClick={() => downloadPlan(menuOptions.all.gcs_xml)}
              >
                XML da Nota Fiscal
              </MenuItem>
            )}
          </Menu>
        </Paper>
      </Box>
      <Grid
        container
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: 15,
        }}
        spacing={2}
        direction="row"
      >
        <Grid item>
          <Select
            className="basic-single"
            classNamePrefix="select"
            placeholder="Itens"
            value={itens}
            onChange={setItens}
            options={options}
          />
        </Grid>
        <Grid item>
          <Pagination
            size="large"
            count={totalPages}
            page={page}
            onChange={handleChangePage}
            variant="outlined"
            shape="rounded"
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        style={{
          justifyContent: 'end',
          padding: 40,
        }}
      />
      <Dialog
        onClose={() => setOpenModal(false)}
        open={openModal}
        fullWidth
        maxWidth="lg"
      >
        <Grid
          container
          spacing={1}
          style={{
            border: '2px solid #c5c5c5',
            borderRadius: 10,
            justifyContent: 'center',
            padding: 40,
          }}
        >
          conteudo
        </Grid>
      </Dialog>
      <Dialog
        onClose={() => setOpenDuplicate(!openDuplicate)}
        open={openDuplicate}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="simple-dialog-title">
          {t('Order.table.duplicateTitle')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('Order.table.duplicateMsg')}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <h6
            style={{
              cursor: 'pointer',
              color: '#657588',
              fontWeight: 'bold',
              fontFamily: 'Open Sans',
              marginTop: 5,
            }}
            onClick={() => setOpenDuplicate(false)}
          >
            {t('Order.table.duplicateCancelBtn')}
          </h6>
          <Button
            style={{ height: 40 }}
            onClick={duplicateOrder}
            color="primary"
            variant="contained"
            label={t('Order.table.duplicateConfirmBtn')}
          />
        </DialogActions>
      </Dialog>
      <Dialog
        onClose={() => setOpenCancel(!openCancel)}
        open={openCancel}
        fullWidth
        maxWidth="sm"
      >
        <Loading open={cancelLoading} msg="Cancelando pedido..." />
        <DialogTitle id="simple-dialog-title">
          {t('Order.table.orderCancelTitle')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('Order.table.orderCancelMsg')}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <h6
            style={{
              cursor: 'pointer',
              color: '#657588',
              fontWeight: 'bold',
              fontFamily: 'Open Sans',
              marginTop: 5,
            }}
            onClick={() => setOpenCancel(false)}
          >
            {t('Order.table.orderCancelBtn')}
          </h6>
          <Button
            style={{ height: 40 }}
            onClick={cancelOrder}
            color="primary"
            variant="contained"
            label={t('Order.table.orderCancelConfirmBtn')}
          />
        </DialogActions>
      </Dialog>
      <Dialog
        onClose={() => setOpenModalPayment(!openModalPayment)}
        open={openModalPayment}
        fullWidth
        maxWidth="sm"
      >
        <Loading open={paymentLoading} msg={t('OrderDetails.loadPaymentMsg')} />
        <DialogTitle id="simple-dialog-title">
          <Grid
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Grid>{`${t('OrderDetails.modalTitle')}: ${
              orderForPayment?.pd
            }`}</Grid>
            <Grid>{orderForPayment && orderForPayment.nf}</Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid container>
              <Grid item xs={12}>
                {t('OrderDetails.paymentMsg')}
              </Grid>
              <Grid
                container
                style={{
                  marginTop: 25,
                }}
              >
                <Grid
                  item
                  xs={6}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <p>Data do Pagamento:</p>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <MuiPickersUtilsProvider locale={locale} utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      style={{ backgroundColor: '#fff' }}
                      // label="With keyboard"
                      size="small"
                      format="dd/MM/yyyy"
                      value={selectedDate}
                      InputProps={{ readOnly: true }}
                      InputAdornmentProps={{ position: 'start' }}
                      onChange={date => handleDateChange(date)}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
              <Grid
                container
                style={{
                  marginTop: 25,
                }}
              >
                <Grid
                  item
                  xs={6}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <p>Banco de Entrada:</p>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <MaterialSelect
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    variant="outlined"
                    size="small"
                    style={{ width: 240 }}
                    input={<BootstrapInput />}
                    value={bankId}
                    onChange={bank => setBankId(bank.target.value)}
                  >
                    {banks.map(bank => (
                      <MenuItem value={bank.value}>{bank.label}</MenuItem>
                    ))}
                  </MaterialSelect>
                  {/* <Select
                    fullWidth
                    styles={customStyles}
                    className="basic-single"
                    classNamePrefix="select"
                    placeholder="Banco"
                    menuPortalTarget={document.body}
                    value={bankId}
                    onChange={setBankId}
                    isSearchable={false}
                    options={banks}
                  /> */}
                </Grid>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <h6
            style={{
              cursor: 'pointer',
              color: '#657588',
              fontWeight: 'bold',
              fontFamily: 'Open Sans',
              marginTop: 5,
            }}
            onClick={() => setOpenModalPayment(false)}
          >
            {t('OrderDetails.completeCancelBtn')}
          </h6>
          <Button
            style={{ height: 40 }}
            onClick={paymentOrder}
            color="primary"
            variant="contained"
            label={t('OrderDetails.completeConfirmBtn')}
          />
        </DialogActions>
      </Dialog>
      <Dialog
        onClose={() => setOpenModalMultiPayment(!openModalMultiPayment)}
        open={openModalMultiPayment}
        fullWidth
        maxWidth="md"
        style={{ minHeight: 600 }}
      >
        <Loading open={paymentLoading} msg={t('OrderDetails.loadPaymentMsg')} />
        <DialogTitle id="simple-dialog-title">
          <Grid
            style={{
              display: 'flex',
              justifyContent: 'start',
            }}
          >
            <Grid>Multi-Pagamentos</Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid container>
              <Grid item xs={12}>
                {/* {t('OrderDetails.paymentMsg')} */}
                Deseja realmente informar o pagamento desses pedidos? Esta ação
                não poderá ser desfeita.
              </Grid>
              {orderForMultPayment.map((p, i) => (
                <Grid
                  key={p.nf}
                  container
                  style={{
                    marginTop: 25,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <p style={{ color: 'blue', marginRight: 5 }}>{p.nf}:</p>
                  <Grid
                    item
                    // xs={3}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <p style={{ marginRight: 5 }}>Data Pgt:</p>
                  </Grid>
                  <Grid
                    item
                    // xs={3}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <MuiPickersUtilsProvider
                      locale={locale}
                      utils={DateFnsUtils}
                    >
                      <KeyboardDatePicker
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        style={{
                          backgroundColor: '#fff',
                          width: 185,
                        }}
                        disabled={i > 0 && allDate}
                        size="small"
                        format="dd/MM/yyyy"
                        value={p.datePayment}
                        InputProps={{ readOnly: true }}
                        InputAdornmentProps={{ position: 'start' }}
                        onChange={date => handleChangeMultPayment(date, p.nf)}
                      />
                    </MuiPickersUtilsProvider>
                    {i > 0 ? (
                      <Checkbox style={{ visibility: 'hidden' }} />
                    ) : (
                      <Checkbox
                        checkedIcon={<BpCheckedIcon />}
                        icon={<BpIcon />}
                        // checked={rowCount > 0 && numSelected === rowCount}
                        checked={allDate}
                        onChange={e => handleAllDate(e)}
                        inputProps={{
                          'aria-label': 'select all desserts',
                        }}
                      />
                    )}
                  </Grid>
                  <Grid
                    item
                    // xs={3}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <p style={{ marginLeft: 5, marginRight: 5 }}>
                      Banco de Entrada:
                    </p>
                  </Grid>
                  <Grid
                    item
                    // xs={3}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {/* <Select
                      fullWidth
                      styles={customStyles}
                      menuPlacement="auto"
                      isDisabled={i > 0 && allBanks}
                      className="basic-single"
                      classNamePrefix="select"
                      placeholder="Banco"
                      value={p.bank}
                      onChange={bank => handleChangeMultPaymentBank(bank, p.nf)}
                      isSearchable={false}
                      options={banks}
                    /> */}
                    <MaterialSelect
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      variant="outlined"
                      size="small"
                      style={{ width: 240 }}
                      disabled={i > 0 && allBanks}
                      input={<BootstrapInput />}
                      value={p.bank}
                      onChange={bank => handleChangeMultPaymentBank(bank, p.nf)}
                    >
                      {banks.map(bank => (
                        <MenuItem value={bank.value}>{bank.label}</MenuItem>
                      ))}
                    </MaterialSelect>
                  </Grid>
                  {i > 0 ? (
                    <Checkbox style={{ visibility: 'hidden' }} />
                  ) : (
                    <Checkbox
                      checkedIcon={<BpCheckedIcon />}
                      icon={<BpIcon />}
                      checked={allBanks}
                      // checked={rowCount > 0 && numSelected === rowCount}
                      onChange={e => handleAllBanks(e)}
                      inputProps={{
                        'aria-label': 'select all desserts',
                      }}
                    />
                  )}
                </Grid>
              ))}
            </Grid>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <h6
            style={{
              cursor: 'pointer',
              color: '#657588',
              fontWeight: 'bold',
              fontFamily: 'Open Sans',
              marginTop: 5,
            }}
            onClick={() => setOpenModalMultiPayment(false)}
          >
            {t('OrderDetails.completeCancelBtn')}
          </h6>
          <Button
            style={{ height: 40 }}
            onClick={paymentMultiOrder}
            color="primary"
            variant="contained"
            label={t('OrderDetails.completeConfirmBtn')}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}
