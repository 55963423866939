/* eslint-disable react/prop-types */
import React from 'react';
// import Grid from '@mui/material/Grid';
import './checkout.css';
// import TextField from '@mui/material/TextField';

// import { TextField, Grid } from '@material-ui/core';
import {
  Grid,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  TextField,
} from '@material-ui/core';

import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme =>
  createStyles({
    myButton: {
      // textAlign: 'center',
      // padding: theme.spacing(6),
      // margin: theme.spacing(1),
      // color: theme.palette.text.secondary,
      color: '#2a3176',
      backgroundColor: '#f4f4f4',
      fontWeight: 'bold',
      // minWidth: 200,
    },
    myGrid: {
      backgroundColor: '#f4f4f4',
      margin: theme.spacing(1),
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      textAlign: 'center',
      cursor: 'pointer',
    },
    myLabel: {
      // padding: theme.spacing(6),
      color: '#2a3176',
      fontWeight: 'bold',
      margin: 20,
    },
    myTextField: {
      border: '1px solid #fff',
      borderRadius: 10,
      backgroundColor: '#f4f4f4',
      fontWeight: 'bold',
    },
    mySwitch: {
      '&.MuiSwitch-colorPrimary.MuiSwitch-checked': {
        color: 'green',
      },
    },
    radio: {
      color: '#9c9c9c',
      '&$checked': {
        color: '#41b490',
      },
    },
    checked: {},
    switchBase: {
      color: '#fff',
      '&$checked': {
        color: '#41b490',
      },
      '&$checked + $track': {
        backgroundColor: '#41b490',
      },
    },
    track: {},
  }),
);

export default function UserData({
  name,
  setName,
  cpf,
  setCpf,
  email,
  setEmail,
  cargo,
  setCargo,
  phone,
  setPhone,
  cellPhone,
  setCellPhone,
  twoFaType,
  setTwoFaType,
}) {
  // const [selected, setSelected] = useState();
  const classes = useStyles();

  const handleRadioChange = event => {
    setTwoFaType(event.target.value);
  };
  return (
    <Grid
      container
      spacing={1}
      style={{
        border: '2px solid #c5c5c5',
        borderRadius: 10,
      }}
    >
      <h6 className={classes.myLabel}>Dados do usuário</h6>
      <Grid container spacing={1} style={{ padding: 20 }}>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <TextField
            defaultValue={name}
            onChange={e => setName(e.target.value)}
            className={classes.myTextField}
            fullWidth
            size="small"
            id="outlined-basic"
            label="Nome"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <TextField
            defaultValue={cpf}
            onChange={e => setCpf(e.target.value)}
            className={classes.myTextField}
            fullWidth
            size="small"
            id="outlined-basic"
            label="CPF"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <TextField
            defaultValue={email}
            onChange={e => setEmail(e.target.value)}
            className={classes.myTextField}
            fullWidth
            size="small"
            id="outlined-basic"
            label="E-mail"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <TextField
            defaultValue={cargo}
            onChange={e => setCargo(e.target.value)}
            className={classes.myTextField}
            fullWidth
            size="small"
            id="outlined-basic"
            label="Cargo"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <TextField
            defaultValue={phone}
            onChange={e => setPhone(e.target.value)}
            className={classes.myTextField}
            fullWidth
            size="small"
            id="outlined-basic"
            label="Telefone"
            variant="outlined"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
          xl={6}
          // style={{ marginBottom: 30 }}
        >
          <TextField
            defaultValue={cellPhone}
            onChange={e => setCellPhone(e.target.value)}
            className={classes.myTextField}
            fullWidth
            size="small"
            id="outlined-basic"
            label="Celular"
            variant="outlined"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
          xl={6}
          style={{ marginBottom: 30 }}
        >
          <FormControl component="fieldset">
            <p style={{ marginBottom: -10 }}>Duplo fator</p>
            <RadioGroup
              row
              aria-label="gender"
              name="row-radio-buttons-group"
              value={twoFaType}
              onChange={handleRadioChange}
            >
              <FormControlLabel
                // className="myLabel"
                value="2"
                control={
                  <Radio
                    classes={{
                      root: classes.radio,
                      checked: classes.checked,
                    }}
                  />
                }
                label="SMS"
              />
              <FormControlLabel
                // className="myLabel"
                value="1"
                control={
                  <Radio
                    classes={{
                      root: classes.radio,
                      checked: classes.checked,
                    }}
                  />
                }
                label="Email"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  );
}
