/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';

// import { useHistory } from 'react-router-dom';
// import routing from '../../../services/routes/routing';

import { Grid } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import styles from '../../../styles/Layout.module.css';

const useStyles = makeStyles(theme =>
  createStyles({
    myButton: {
      // textAlign: 'center',
      // padding: theme.spacing(6),
      // margin: theme.spacing(1),
      // color: theme.palette.text.secondary,
      color: '#2a3176',
      backgroundColor: '#f4f4f4',
      fontWeight: 'bold',
      // minWidth: 200,
    },
    myGrid: {
      minWidth: 260,
      backgroundColor: '#f4f4f4',
      margin: theme.spacing(1),
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      textAlign: 'center',
      cursor: 'pointer',
      border: '2px solid #c5c5c5',
      borderRadius: 10,
    },
    myGridSelected: {
      minWidth: 260,
      backgroundColor: '#2a3176',
      margin: theme.spacing(1),
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      textAlign: 'center',
      cursor: 'pointer',
      border: '2px solid #2a3176',
      borderRadius: 10,
    },
    myLabel: {
      padding: theme.spacing(6),
      color: '#2a3176',
      fontWeight: 'bold',
    },
    myLabelSelected: {
      padding: theme.spacing(6),
      color: '#fff',
      fontWeight: 'bold',
    },
  }),
);

export default function ModelTypes() {
  const classes = useStyles();
  // const history = useHistory();
  const [selected, setSelected] = useState();

  const handleClick = e => {
    setSelected(e);
  };
  return (
    <div className="col-sm-12">
      {/* <div
        className="col-sm-12"
        style={{
          display: 'flex', flexDirection: 'row',
          alignItems: 'center', justifyContent: 'space-between',
        }}
      >

        <h4
          className="textMenu"
          onKeyDown={() => history.push(routing.dashboard)}
          onClick={() => history.push(routing.dashboard)}
        >
          Menu Inicial

        </h4>
        <h4
      // className="textMenu"
          onKeyDown={() => history.push(routing.dashboard)}
          onClick={() => history.push(routing.dashboard)}
          style={{ color: '#41b490' }}
        >
          Criar novo grupo de clientes

        </h4>
      </div> */}
      <div
        className="col-sm-12"
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <h5 className={styles.textTitle}>Escolha o modelo</h5>
      </div>
      <Grid
        container
        spacing={1}
        style={{
          justifyContent: 'center',
          padding: 40,
        }}
      >
        <Grid
          className={
            selected === 'contract' ? classes.myGridSelected : classes.myGrid
          }
          item
          xs={12}
          sm={8}
          md={2}
          lg={2}
          xl={2}
          onClick={() => handleClick('contract')}
        >
          <p
            className={
              selected === 'contract'
                ? classes.myLabelSelected
                : classes.myLabel
            }
          >
            Atualização dos contratos
          </p>
        </Grid>
        <Grid
          className={
            selected === 'new' ? classes.myGridSelected : classes.myGrid
          }
          item
          xs={12}
          sm={8}
          md={2}
          lg={2}
          xl={2}
          onClick={() => handleClick('new')}
        >
          <p
            className={
              selected === 'new' ? classes.myLabelSelected : classes.myLabel
            }
          >
            Novo produto
          </p>
        </Grid>
        <Grid
          className={
            selected === 'process' ? classes.myGridSelected : classes.myGrid
          }
          item
          xs={12}
          sm={8}
          md={2}
          lg={2}
          xl={2}
          onClick={() => handleClick('process')}
        >
          <p
            className={
              selected === 'process' ? classes.myLabelSelected : classes.myLabel
            }
          >
            Alteração de processos
          </p>
        </Grid>
      </Grid>
    </div>
  );
}
