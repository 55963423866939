/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-lonely-if */
/* eslint-disable react/prop-types */
/* eslint-disable import/prefer-default-export */
/* eslint-disable prettier/prettier */
import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import readXlsxFile from 'read-excel-file';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { RiDeleteBin2Fill } from "react-icons/ri";
import {
  Grid,
  TextField,
  List,
  RadioGroup,
  Radio,
  FormControl,
  FormControlLabel,
} from '@material-ui/core';

import { Pagination } from '@material-ui/lab';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MuiListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TablePremio from "../tables/premiados/TablePremio";
import api from '../../service/axios/api';
import { BpCheckedIcon, BpIcon } from '../../styles/utilstyles';
import MyButton from '../button/MyButton';

const ListItem = withStyles({
  root: {
    '&$selected': {
      backgroundColor: 'red',
      color: 'white',
      '& .MuiListItemIcon-root': {
        color: 'white',
      },
    },
    '&$selected:hover': {
      backgroundColor: 'purple',
      color: 'white',
      '& .MuiListItemIcon-root': {
        color: 'white',
      },
    },
    '&:hover': {
      backgroundColor: '#41b490',
      // color: "white",
      '& .MuiListItemIcon-root': {
        color: 'white',
      },
    },
  },
  selected: {},
})(MuiListItem);

function not(a, b) {
  return a.filter(value => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter(value => b.indexOf(value) !== -1);
}

export function ListTransfer({
  contract,
  right,
  setRight,
  setAllPf,
  allPf,
  setAllPj,
  allPj,
  setMyfile,
  myFile,
}) {
  const fileXls = useRef(null);
  // const [allPf, setAllPf] = useState(false)
  // const [allPj, setAllPj] = useState(false)
  const useStyles = makeStyles(theme => ({
    root: {
      margin: 'auto',
      width: '100%',
      justifyContent: 'space-around',
    },
    paper: {
      width: '100%',
      height: 230,
      overflow: 'auto',
    },
    button: {
      margin: theme.spacing(0.5, 0),
    },
    buttonPf: {
      margin: theme.spacing(0.5, 0),
      backgroundColor: allPf && '#41b490',
      color: allPf && '#fff',
    },
    buttonPj: {
      margin: theme.spacing(0.5, 0),
      backgroundColor: allPj && '#41b490',
      color: allPj && '#fff',
    },
    myTextField: {
      backgroundColor: 'none',
      boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
      border: 'none',
      color: '#717171',
      fontWeight: 'bold',
      fontFamily: 'Open Sans',
    },
    text: {
      fontFamily: 'Open Sans',
      fontWeight: 'bold',
      color: '#717171',
    },
    mySwitch: {
      '&.MuiSwitch-colorPrimary.MuiSwitch-checked': {
        color: 'green',
      },
    },
    checked: {},
    switchBase: {
      color: '#fff',
      '&$checked': {
        color: '#41b490',
      },
      '&$checked + $track': {
        backgroundColor: '#41b490',
      },
    },
    track: {},
  }));
  const classes = useStyles();
  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState([]);
  // const [right, setRight] = useState([]);
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [typeClient, setTypeClient] = useState('individual');
  const [search, setSearch] = useState('');
  const [orders, setOrders] = useState([])

  const cpfMask = data => {
    return data
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
  };

  const cnpjMask = data => {
    return data
      .replace(/\D+/g, '') // não deixa ser digitado nenhuma letra
      .replace(/(\d{2})(\d)/, '$1.$2') // captura 2 grupos de número o primeiro com 2 digitos e o segundo de com 3 digitos, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de número
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1/$2') // captura 2 grupos de número o primeiro e o segundo com 3 digitos, separados por /
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
  };

  const handleTypeClient = e => {
    setPage(1);
    setTypeClient(e);
  };

  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const searchClients = () => {
    setLeft([]);
    api.contract
      .searchClients(contract.value, page, 50, typeClient, {
        filter: typeClient,
        filterValue: search,
      })
      .then(res => {
        const list = [];
        if (typeClient === 'individual') {
          if (!allPf) {
            res.retorno.forEach(client => {
              const find = right.findIndex(i => i.id === client.id);
              if (find === -1) {
                list.push({
                  id: client.id,
                  name: client.name,
                  cpf: cpfMask(client.cpf),
                  type: 'individual',
                });
              }
            });
          }
        } else {
          if (!allPj) {
            res.retorno.forEach(client => {
              const find = right.findIndex(i => i.id === client.id);
              if (find === -1) {
                list.push({
                  id: client.id,
                  name: client.company_name,
                  cpf: cnpjMask(client.cnpj),
                  type: 'company',
                });
              }
            });
          }
        }
        setLeft(list);
        setTotal(Math.ceil(Number(res.total) / 50) || 0);
      })
      .catch(err => console.log(err));
  };

  useEffect(() => {
    if (!allPf || !allPj) {
      searchClients();
    }
  }, [page, typeClient, allPf, allPj]);

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    const list = [];
    if (typeClient === 'individual') {
      rightChecked.forEach(r => {
        if (r.type === 'individual') {
          list.push(r);
        }
      });
    } else {
      rightChecked.forEach(r => {
        if (r.type === 'company') {
          list.push(r);
        }
      });
    }
    setLeft(left.concat(list));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    const findIdx = right.findIndex(
      r =>
        r.name === 'TODOS PJ DO CONTRATO' || r.name === 'TODOS PF DO CONTRATO',
    );
    if (findIdx !== -1) {
      setAllPf(false);
      setAllPj(false);
    } else {
      setLeft(left.concat(right));
    }
    setRight([]);
  };

  const handleAllPj = () => {
    if (allPj) {
      setAllPj(false);
      const findIndex = right.findIndex(f => f.name === 'TODOS PJ DO CONTRATO');
      if (findIndex !== -1) {
        const orig = _.cloneDeep(right);
        orig.splice(findIndex, 1);
        setRight(orig);
      }
    } else {
      setAllPj(true);
      const list = [];
      right.forEach(r => {
        if (r.type !== 'company') {
          list.push(r);
        }
      });
      if (typeClient === 'individual') {
        setRight([
          ...list,
          { id: 'all', type: 'company', name: 'TODOS PJ DO CONTRATO', cpf: '' },
        ]);
      } else {
        setRight([
          ...list,
          { id: 'all', type: 'company', name: 'TODOS PJ DO CONTRATO', cpf: '' },
        ]);
        setLeft([]);
      }
    }
  };

  const handleAllPf = () => {
    if (allPf) {
      setAllPf(false);
      const findIndex = right.findIndex(f => f.name === 'TODOS PF DO CONTRATO');
      if (findIndex !== -1) {
        const orig = _.cloneDeep(right);
        orig.splice(findIndex, 1);
        setRight(orig);
      }
    } else {
      setAllPf(true);
      const list = [];
      right.forEach(r => {
        if (r.type !== 'individual') {
          list.push(r);
        }
      });
      if (typeClient === 'individual') {
        setRight([
          ...list,
          {
            id: 'all',
            type: 'individual',
            name: 'TODOS PF DO CONTRATO',
            cpf: '',
          },
        ]);
        setLeft([]);
      } else {
        setRight([
          ...list,
          {
            id: 'all',
            type: 'individual',
            name: 'TODOS PF DO CONTRATO',
            cpf: '',
          },
        ]);
      }
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const customList = items => (
    <Paper className={classes.paper}>
      <List dense component="div" role="list">
        {items.map(value => {
          const labelId = `transfer-list-item-${value}-label`;

          return (
            <ListItem
              style={classes.item}
              key={value.id}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checkedIcon={<BpCheckedIcon />}
                  icon={<BpIcon />}
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={value.name}
                secondary={value.cpf}
              />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Paper>
  );

  useEffect(() => {
    if (myFile) {
      const dataTable = [];
      readXlsxFile(myFile).then(r => {
        const keys = r[0];
        r.forEach((data, i) => {
          if (i > 0) {
            dataTable.push({
              [keys[0]]: data[0],
              [keys[1]]: data[1],
              [keys[2]]: data[2],
              [keys[3]]: data[3],
              [keys[4]]: data[4],
              [keys[5]]: data[5],
              [keys[6]]: data[6],
              [keys[7]]: data[7],
              [keys[8]]: data[8],
              [keys[9]]: data[9],
              [keys[10]]: data[10],
              [keys[11]]: data[11],
              [keys[12]]: data[12],
              [keys[13]]: data[13],
            });
          }
        });
        setOrders(dataTable);
      });
    }
  }, [myFile]);

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      alignItems="center"
      className={classes.root}
    >
      <Grid
        item
        xs={12}
        style={{ display: 'flex', justifyContent: 'end', alignItems: 'end' }}
      >
        {orders.length === 0 ? (
          <p
          className="label-planilhas"
          styles={{ cursor: 'pointer' }}
          onClick={() => fileXls.current.click()}
        >
          <img
            id="planilha"
            src="../img/upload.svg"
            alt="logo"
            className="img-planilhas"
          />
          Planilha
        </p>
        ):(
          <p
          styles={{ cursor: 'pointer', color: 'red' }}
          onClick={() => {
            setOrders([])
            setMyfile()
            fileXls.current.value = ''
          }}
        ><RiDeleteBin2Fill color='red' size={25} style={{ cursor: 'pointer'}} /> <span style={{fontWeight: 'bold', color: 'red', cursor: 'pointer'}}>Apagar planilha</span></p>
        )}
        
      </Grid>
      {myFile ? (
        <Grid item xs={11}>
        <TablePremio
          orders={orders}
          totalPages={1}
          page={1}
          // setPage={setPage}
          itens={orders.length}
          // setItens={setItens}
        />
      </Grid>
      ) : (
        <>
        <Grid item style={{ width: '45%', marginTop: -20 }}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <p className="text-select-label " style={{ marginRight: 10 }}>
            Tipo de cliente:
          </p>
          <FormControl component="fieldset">
            <RadioGroup
              row
              aria-label="gender"
              name="row-radio-buttons-group"
              value={typeClient}
              onChange={e => handleTypeClient(e.target.value)}
            >
              <FormControlLabel
                value="individual"
                control={
                  <Radio
                    classes={{
                      root: classes.radio,
                      checked: classes.checked,
                    }}
                  />
                }
                label="PF"
              />
              <FormControlLabel
                value="company"
                control={
                  <Radio
                    classes={{
                      root: classes.radio,
                      checked: classes.checked,
                    }}
                  />
                }
                label="PJ"
              />
            </RadioGroup>
          </FormControl>
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div>Clientes</div>
          <div style={{ marginBottom: 5, display: 'flex' }}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              placeholder="Nome ou documento"
              value={search}
              onChange={e => setSearch(e.target.value)}
            />
            <MyButton
              style={{
                marginLeft: 5,
                width: 80,
                height: 40,
                borderRadius: 10,
                backgroundColor: '#657588',
              }}
              onClick={searchClients}
              label="Buscar"
            />
          </div>
        </div>
        <div>{customList(left)}</div>
        <Pagination
          style={{ marginTop: 10 }}
          size="large"
          count={total}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
        />
      </Grid>
      <Grid item style={{ width: '10%' }}>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleAllRight}
            disabled={left.length === 0}
            aria-label="move all right"
          >
            ≫
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleAllLeft}
            disabled={right.length === 0}
            aria-label="move all left"
          >
            ≪
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.buttonPf}
            onClick={handleAllPf}
            aria-label="move all left"
          >
            TODOS PF
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.buttonPj}
            onClick={handleAllPj}
            aria-label="move all left"
          >
            TODOS PJ
          </Button>
        </Grid>
      </Grid>
      <Grid item style={{ width: '45%' }}>
        <div>Demonstrativo para</div>
        <div>{customList(right)}</div>
      </Grid>
        </>

      )}
      <input
        ref={fileXls}
        type="file"
        style={{
          opacity: 0,
        }}
        // accept="media_type"
        // value={myFile?.name || ''}
        onChange={() => setMyfile(fileXls.current.files[0])}
        className="form-control"
        id="inputNumSerie"
        placeholder="Arquivo DWG do projeto"
      />
    </Grid>
  );
}
