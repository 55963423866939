import { string, object, date } from 'yup';

const CampaignSchema = object({
  name: string().required('Campo obrigatório'),
  expiresIn: date()
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr)),
  comments: string().nullable(),
});

export default CampaignSchema;
