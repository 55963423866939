import { string, object } from 'yup';

const InvoiceSchema = object().shape({
  companyName: string().required('Campo obrigatório'),
  nfNumber: string().required('Campo obrigatório'),
  dateReceipt: string(),
  datePayment: string(),
  amount: string().required('Campo obrigatório'),
  status: string(),
  fileKey: string(),
  id: string(),
});

// export type Invoice = TypeOf<typeof InvoiceSchema>

export default InvoiceSchema;
