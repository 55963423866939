const contact = {
  uid: '',
  fullName: '',
  cpf: '',
  companyRole: '',
  companyArea: '',
  phone: '',
  mobile: '',
  email: '',
};

export default contact;
