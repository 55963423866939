/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import {
  Avatar,
  Grid,
  Typography /* LinearProgress */,
} from '@material-ui/core';
import {
  FaWallet,
  // FaChartBar,
  FaRegCreditCard,
  // FaDollarSign,
  // FaUserFriends,
  // BsCreditCard2Back,
  // FaRegCreditCard,
  // FaPiggyBank,
  // FaShieldAlt,
} from 'react-icons/fa';

import Tooltip from '@material-ui/core/Tooltip';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';

import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import Button from '@material-ui/core/Button';

import { MdOutlineCreditCardOff } from 'react-icons/md';

import { BsCreditCard2Back } from 'react-icons/bs';
// import { GrDocumentUser } from 'react-icons/gr';
import styles from '../../styles/Dashboard.module.css';

import api from '../../service/axios/api';

export default function DashAdmin() {
  const history = useHistory();
  // eslint-disable-next-line no-unused-vars
  const [check, setCheck] = useState(true);
  const [association, setAssociation] = useState(true);
  const [recharge, setRecharge] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [cardTrans, setCardTrans] = useState(true);
  const [block, setBlock] = useState(true);
  const [open, setOpen] = useState(false);

  const [tooltip, setTooltip] = useState(false);
  const [func, setFunc] = useState();
  const [expirationCards, setExpirationCards] = useState({});

  useEffect(() => {
    api.service
      .getAll()
      .then(res => {
        res.retorno.forEach(s => {
          switch (s.name) {
            case 'charge':
              setRecharge(s.status === 1);
              break;
            case 'block':
              setBlock(s.status === 1);
              break;
            case 'card_association':
              setAssociation(s.status === 1);
              break;
            case 'card_check':
              setCheck(s.status === 1);
              break;
            case 'card_transfer':
              setCardTrans(s.status === 1);
              break;
            default:
              console.log('DEU ERRO');
          }
        });
      })
      .catch(err => {
        console.log(err);
      });
    const cancel = new AbortController();
    api.cards
      .expirationSummary(cancel)
      .then(res => {
        setExpirationCards(res.retorno);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const alterOperation = e => {
    setFunc(e);
    if (!check && e === 'association') {
      console.log('nada fazer');
    } else {
      setOpen(true);
    }
  };

  const editOperation = () => {
    switch (func) {
      case 'check':
        api.service.update(1, !check ? 1 : 0);
        setCheck(!check);
        setFunc();
        break;
      case 'block':
        api.service.update(3, !block ? 1 : 0);
        setBlock(!block);
        setFunc();
        break;
      case 'charge':
        api.service.update(2, !recharge ? 1 : 0);
        setRecharge(!recharge);
        setFunc();
        break;
      case 'association':
        if (check) {
          api.service.update(4, !association ? 1 : 0);
          setAssociation(!association);
          setFunc();
        }
        break;
      case 'cardTransfer':
        api.service.update(5, !cardTrans ? 1 : 0);
        setCardTrans(!cardTrans);
        setFunc();
        break;
      default:
        console.log('DEU ERRO');
    }
    setOpen(false);
  };

  const handleClose = () => {
    setTooltip(false);
  };

  const handleOpen = () => {
    if (!check) {
      setTooltip(true);
    }
  };

  return (
    <>
      {/* <Typography variant="h4" color="inherit">
        Modo de operação
      </Typography> */}
      <Grid item xs={12} sm={12} style={{ marginTop: 30 }}>
        <h4> Modo de operação</h4>
      </Grid>
      <Grid container spacing={6}>
        <Grid
          item
          lg={3}
          md={4}
          sm={6}
          xs={12}
          onClick={() => alterOperation('check')}
        >
          <div
            className={check ? styles.smallWidgetOk : styles.smallWidgetError}
          >
            <div>
              <Grid container item alignItems="center">
                <Grid item xs={12}>
                  <Typography variant="subtitle2" color="inherit">
                    CHECK CARTÃO
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography color="inherit">
                    {!check
                      ? 'Checagem automática de cartão desativada'
                      : 'Checagem automática de cartão ativada'}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Avatar
                    style={{
                      width: 55,
                      height: 55,
                      backgroundColor: !check ? '#B51B00' : '#00b58d',
                    }}
                  >
                    <BsCreditCard2Back size="30" />
                  </Avatar>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
        <Grid
          item
          lg={3}
          md={4}
          sm={6}
          xs={12}
          onClick={() => alterOperation('association')}
        >
          <Tooltip
            title="Quando a checagem de cartão está desabilitada a função de vinculo é desabilitada automáticamente."
            placement="top-end"
            arrow
            open={tooltip}
            onClose={handleClose}
            onOpen={handleOpen}
          >
            <div
              style={{ cursor: !check ? 'not-allowed' : 'pointer' }}
              className={
                !association
                  ? styles.smallWidgetError
                  : !check
                  ? styles.smallWidgetError
                  : styles.smallWidgetOk
              }
            >
              <div>
                <Grid container item alignItems="center">
                  <Grid item xs={12}>
                    <Typography variant="subtitle2" color="inherit">
                      VÍNCULO
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography color="inherit">
                      {!association
                        ? 'Vínculo de cartão automático desativado'
                        : !check
                        ? 'Vínculo de cartão automático desativado'
                        : 'Vínculo de cartão automático ativado'}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Avatar
                      style={{
                        width: 55,
                        height: 55,
                        backgroundColor: !association
                          ? '#B51B00'
                          : !check
                          ? '#B51B00'
                          : '#00b58d',
                      }}
                    >
                      <FaRegCreditCard size="30" />
                    </Avatar>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Tooltip>
        </Grid>
        <Grid
          item
          lg={3}
          md={4}
          sm={6}
          xs={12}
          onClick={() => alterOperation('charge')}
        >
          <div
            className={
              !recharge ? styles.smallWidgetError : styles.smallWidgetOk
            }
          >
            <div>
              <Grid container item alignItems="center">
                <Grid item xs={12}>
                  <Typography variant="subtitle2" color="inherit">
                    RECARGA
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography color="inherit">
                    {!recharge
                      ? 'Recarga automática de cartãos desativada'
                      : 'Recarga automática de cartãos ativa'}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Avatar
                    style={{
                      width: 55,
                      height: 55,
                      backgroundColor: !recharge ? '#B51B00' : '#00b58d',
                    }}
                  >
                    <FaWallet size="30" />
                  </Avatar>
                </Grid>
              </Grid>
            </div>
            <br />
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid item xs={12}>
                {/* <LinearProgress variant="determinate" value={75} /> */}
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid
          item
          lg={3}
          md={4}
          sm={6}
          xs={12}
          onClick={() => alterOperation('block')}
        >
          <div
            className={!block ? styles.smallWidgetError : styles.smallWidgetOk}
          >
            <div>
              <Grid container item alignItems="center">
                <Grid item xs={12}>
                  <Typography variant="subtitle2" color="inherit">
                    BLOQUEIO
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography color="inherit">
                    {!block
                      ? 'Bloqueio automático de cartões desativado'
                      : 'Bloqueio automático de cartões ativo'}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Avatar
                    style={{
                      width: 55,
                      height: 55,
                      backgroundColor: !block ? '#B51B00' : '#00b58d',
                    }}
                  >
                    <MdOutlineCreditCardOff size="30" />
                  </Avatar>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
        <Grid
          item
          lg={3}
          md={4}
          sm={6}
          xs={12}
          onClick={() => alterOperation('cardTransfer')}
        >
          <div
            className={
              cardTrans ? styles.smallWidgetError : styles.smallWidgetOk
            }
          >
            <div>
              <Grid container item alignItems="center">
                <Grid item xs={12}>
                  <Typography variant="subtitle2" color="inherit">
                    CARTÃO (MODO DE CARGA)
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography color="inherit">
                    {cardTrans
                      ? 'Processo de carga de cartões será convertida para tranferência'
                      : 'Processo normal de operação. Carga será realizada no cartão'}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Avatar
                    style={{
                      width: 55,
                      height: 55,
                      backgroundColor: cardTrans ? '#B51B00' : '#00b58d',
                    }}
                  >
                    {cardTrans ? (
                      <MdOutlineCreditCardOff size="30" />
                    ) : (
                      <FaRegCreditCard size="30" />
                    )}
                  </Avatar>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} style={{ marginTop: 30 }}>
        <h4> Cartões próximos a validade</h4>
      </Grid>
      <Grid
        item
        lg={3}
        md={4}
        sm={6}
        xs={12}
        // onClick={() => alterOperation('check')}
      >
        <div
          className={
            expirationCards.monthly > 0
              ? styles.smallWidgetError
              : expirationCards.bimonthly > 0
              ? styles.smallWidgetTwoAlert
              : expirationCards.quarterly > 0
              ? styles.smallWidgetAlert
              : styles.smallWidgetOk
          }
        >
          <div>
            <Grid container item alignItems="center">
              <Grid
                item
                xs={12}
                style={{ cursor: 'pointer' }}
                onClick={() => history.push('/allCardsAdmin?30')}
              >
                <Typography color="inherit">
                  <BsCreditCard2Back size="30" style={{ marginRight: 10 }} />
                  VENCIMENTO EM 30 DIAS: {expirationCards.monthly}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ cursor: 'pointer' }}
                onClick={() => history.push('/allCardsAdmin?60')}
              >
                <Typography color="inherit">
                  <BsCreditCard2Back size="30" style={{ marginRight: 10 }} />
                  VENCIMENTO EM 60 DIAS: {expirationCards.bimonthly}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ cursor: 'pointer' }}
                onClick={() => history.push('/allCardsAdmin?90')}
              >
                <Typography color="inherit">
                  <BsCreditCard2Back size="30" style={{ marginRight: 10 }} />
                  VENCIMENTO EM 90 DIAS: {expirationCards.quarterly}
                </Typography>
              </Grid>
            </Grid>
          </div>
        </div>
      </Grid>
      <Dialog
        onClose={() => setOpen(!open)}
        open={open}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="simple-dialog-title">Modo de operação</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {func === 'cardTransfer' &&
              !cardTrans &&
              'Deseja realmente operar os cartões como transferência?'}
            {func === 'cardTransfer' &&
              cardTrans &&
              'Deseja realmente voltar a operar os cartões normalmente?'}
            {func === 'check' &&
              check &&
              'Deseja realmente operar a checagem de cartões de forma offline?'}
            {func === 'check' &&
              !check &&
              'Deseja voltar a operar a checagem de cartões de forma online?'}
            {func === 'block' &&
              block &&
              'Deseja realmente operar o bloqueio de cartões de forma offline?'}
            {func === 'block' &&
              !block &&
              'Deseja voltar a operar o bloqueio de cartões de forma online?'}
            {func === 'charge' &&
              recharge &&
              'Deseja realmente operar a recarga de cartões de forma offline?'}
            {func === 'charge' &&
              !recharge &&
              'Deseja voltar a operar a recarga de cartões de forma online?'}
            {func === 'association' &&
              association &&
              'Deseja realmente operar a associação de cartões de forma offline?'}
            {func === 'association' &&
              !association &&
              'Deseja voltar a operar a associação de cartões de forma online?'}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setOpen(false)} color="secondary">
            Cancelar
          </Button>
          <Button onClick={editOperation} color="primary" variant="contained">
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
