/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useRef, useContext } from 'react';
import Select from 'react-select';
import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import BanksTable from './BanksTable';
import api from '../../service/axios/api';
import Loading from '../../components/loading/Loading';
import FlashContext from '../../components/contexts/FlashContext';
import { banks } from '../../service/banks';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: 10,
    margin: 0,
  },
  chip: {
    margin: 10,
  },
  textField: {},
  input: {
    backgroundColor: '#fff',
    marginTop: 0,
  },
});

const customStylesEdit = {
  control: provided => ({
    ...provided,
    height: 40,
    borderRadius: 10,
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  option: provided => ({
    ...provided,
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
};

export default function Banks() {
  const classes = useStyles();
  const [openNewBank, setOpenNewBank] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [itens, setItens] = useState(10);
  const [loading, setLoading] = useState(false);
  const [loadingC, setLoadingC] = useState(false);
  const [selected, setSelected] = useState([]);
  const [companyOpt, setCompanyOpt] = useState([]);
  // const [status, setStatus] = useState();

  const [company, setCompany] = useState();
  const [cnabCompanyname, setCnabCompanyname] = useState('');
  const [bank, setBank] = useState('');
  const [cnabBankname, setCnabBankname] = useState('');
  const [branchNumber, setBranchNumber] = useState('');
  const [accountNumber, setAccountNumber] = useState('');

  const [msg, setMsg] = useState('Buscando arquivos...');
  const { notifyError, notifySuccess } = useContext(FlashContext);
  const searchData = extra => {
    const cancel = new AbortController();
    setLoading(true);
    setMsg('Buscando bancos...');
    api.banks
      .get(cancel)
      .then(res => {
        setData(res.retorno);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  };

  const searchCompanies = extra => {
    const cancel = new AbortController();
    setLoading(true);
    setMsg('Buscando empresas...');
    api.company
      .get(cancel, itens, 1, '', null, [])
      .then(res => {
        const list = [];
        res?.docs?.forEach(d => {
          list.push({ label: d.company_name, value: d.id });
        });
        setCompanyOpt(list);
        // console.log(res.docs);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    searchData();
    searchCompanies();
  }, [page, itens]);

  const executeAction = e => {
    e.preventDefault();
    setLoadingC(true);
    api.banks
      .new(
        company,
        bank,
        branchNumber,
        accountNumber,
        cnabCompanyname,
        cnabBankname,
      )
      .then(res => {
        notifySuccess(res.message);
        setCompany();
        setBank();
        setBranchNumber('');
        setAccountNumber('0');
        setCnabBankname('');
        setCnabCompanyname('');
        setOpenNewBank(false);
      })
      .catch(err => {
        notifyError(err?.message);
      })
      .finally(() => {
        setLoadingC(false);
      });
  };

  return (
    <>
      <Loading open={loading} msg={msg} />
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          sm={12}
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <h3>Bancos</h3>
          <Button
            onClick={() => setOpenNewBank(true)}
            color="primary"
            variant="contained"
            size="small"
            style={{ height: '40px' }}
          >
            + Banco
          </Button>
        </Grid>
        <Grid item xs={12} sm={12}>
          <BanksTable
            docs={data}
            setDocs={setData}
            page={page}
            setPage={setPage}
            itens={itens}
            setItens={setItens}
            total={total}
            setSelected={setSelected}
            selected={selected}
            companyOpt={companyOpt}
            search={searchData}
            // status={status}
            // setStatus={setStatus}
          />
        </Grid>
      </Grid>
      <Dialog
        onClose={() => setOpenNewBank(!openNewBank)}
        open={openNewBank}
        fullWidth
        maxWidth="sm"
      >
        <Loading open={loadingC} />
        <DialogTitle id="simple-dialog-title">Novo banco</DialogTitle>
        <form onSubmit={executeAction}>
          <Grid
            container
            spacing={1}
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              padding: 20,
            }}
          >
            <Grid item xs={12} sm={12}>
              <Grid
                container
                spacing={1}
                style={{
                  paddingRight: 20,
                  paddingLeft: 20,
                }}
              >
                <Grid item xs={12} sm={6}>
                  <h6>Empresa relacionada</h6>
                  <Select
                    fullWidth
                    styles={customStylesEdit}
                    classNamePrefix="select"
                    placeholder="Empresa"
                    value={company}
                    onChange={setCompany}
                    // isSearchable={false}
                    options={companyOpt}
                    menuPortalTarget={document.body}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <h6>Nome da empresa no CNAB</h6>
                  <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    type="text"
                    inputProps={{ maxLength: 30 }}
                    value={cnabCompanyname}
                    onChange={e => setCnabCompanyname(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Grid
                container
                spacing={1}
                style={{
                  paddingRight: 20,
                  paddingLeft: 20,
                }}
              >
                <Grid item xs={12} sm={6}>
                  <h6>Banco</h6>
                  <Select
                    fullWidth
                    styles={customStylesEdit}
                    classNamePrefix="select"
                    placeholder="Banco"
                    value={bank}
                    onChange={setBank}
                    // isSearchable={false}
                    options={banks}
                    menuPortalTarget={document.body}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <h6>Nome do banco no CNAB</h6>
                  <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    type="text"
                    inputProps={{ maxLength: 30 }}
                    value={cnabBankname}
                    onChange={e => setCnabBankname(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <h6>Agência</h6>
                  <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    type="number"
                    inputProps={{ maxLength: 10 }}
                    value={branchNumber}
                    onChange={e => setBranchNumber(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <h6>Conta</h6>
                  <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    type="text"
                    inputProps={{ maxLength: 20 }}
                    value={accountNumber}
                    onChange={e => setAccountNumber(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* </form> */}
          <DialogActions>
            <Button onClick={() => setOpenNewBank(false)} color="secondary">
              Cancelar
            </Button>
            <Button type="submit" color="primary" variant="contained" autoFocus>
              Cadastrar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
