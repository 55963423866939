/* eslint-disable import/prefer-default-export */

/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useRef, useContext } from 'react';
import Select from 'react-select';
import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import api from '../../service/axios/api';
import Loading from '../../components/loading/Loading';
import FlashContext from '../../components/contexts/FlashContext';
import { CompanyBaseTable } from './CompanyBaseTable';

const optionsStatus = [
  {
    label: 'Ativo',
    value: true,
  },
  {
    label: 'Inativo ',
    value: false,
  },
  {
    label: 'Todas ',
    value: '',
  },
];

const UFsOpt = [
  { label: 'AC', value: 'AC' },
  { label: 'RO', value: 'RO' },
  { label: 'AM', value: 'AM' },
  { label: 'RR', value: 'RR' },
  { label: 'AP', value: 'AP' },
  { label: 'PA', value: 'PA' },
  { label: 'TO', value: 'TO' },
  { label: 'MA', value: 'MA' },
  { label: 'PI', value: 'PI' },
  { label: 'BA', value: 'BA' },
  { label: 'SE', value: 'SE' },
  { label: 'AL', value: 'AL' },
  { label: 'PE', value: 'PE' },
  { label: 'PB', value: 'PB' },
  { label: 'RN', value: 'RN' },
  { label: 'CE', value: 'CE' },
  { label: 'MT', value: 'MT' },
  { label: 'MS', value: 'MS' },
  { label: 'GO', value: 'GO' },
  { label: 'MG', value: 'MG' },
  { label: 'ES', value: 'ES' },
  { label: 'RJ', value: 'RJ' },
  { label: 'SP', value: 'SP' },
  { label: 'PR', value: 'PR' },
  { label: 'SC', value: 'SC' },
  { label: 'RS', value: 'RS' },
];

const customStyles = {
  control: provided => ({
    ...provided,
    width: 105,
    height: 40,
    borderRadius: 10,
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  option: provided => ({
    ...provided,
  }),
};
const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      listStyle: 'none',
      padding: 10,
      margin: 0,
    },
    chip: {
      margin: 10,
    },
    textField: {},
    input: {
      backgroundColor: '#fff',
      marginTop: 0,
    },
    myGrid: {
      minWidth: 260,
      maxHeight: 100,
      backgroundColor: '#f4f4f4',
      margin: theme.spacing(1),
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      textAlign: 'center',
      cursor: 'pointer',
      border: '2px solid #c5c5c5',
      borderRadius: 10,
    },
    myGridSelected: {
      minWidth: 260,
      maxHeight: 100,
      backgroundColor: '#41b490',
      margin: theme.spacing(1),
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      textAlign: 'center',
      cursor: 'pointer',
      border: '2px solid #41b490',
      borderRadius: 10,
    },
    myLabel: {
      padding: theme.spacing(2),
      color: '#2a3176',
      fontWeight: 'bold',
    },
    myLabelSelected: {
      padding: theme.spacing(2),
      color: '#fff',
      fontWeight: 'bold',
    },
  }),
);

const customStylesEdit = {
  control: provided => ({
    ...provided,
    height: 40,
    borderRadius: 10,
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  option: provided => ({
    ...provided,
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
};

export function CompanyBase() {
  const classes = useStyles();
  const [openNew, setOpenNew] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [itens, setItens] = useState(10);
  const [loading, setLoading] = useState(false);
  const [principal, setPrincipal] = useState(false);
  const [statusSelected, setStatusSelected] = useState({
    label: 'Todas',
    value: '',
  });
  const [options, setOptions] = useState([
    { label: 'Razão Social', value: 'name' },
    { label: 'CNPJ', value: 'cnpj' },
  ]);
  const [searchType, setSearchType] = useState();
  const [msg, setMsg] = useState('Buscando configurações...');
  const [chipData, setChipData] = useState([]);
  const searchRef = useRef(null);

  const { notifyError, notifySuccess } = useContext(FlashContext);
  const [loadingNew, setLoadingNew] = useState(false);
  const [companyName, setCompanyName] = useState(null);
  const [cnpj, setCnpj] = useState('');
  const [ie, setIe] = useState('');
  const [im, setIm] = useState('');
  const [prefix, setPrefix] = useState('');
  const [address, setAddress] = useState('');
  const [number, setNumber] = useState('');
  const [district, setDistrict] = useState('');
  const [city, setCity] = useState('');
  const [complement, setComplement] = useState('');
  const [uf, setUf] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [certName, setCertName] = useState('');

  const searchData = extra => {
    const cancel = new AbortController();
    setLoading(true);
    setMsg('Buscando empresas...');
    api.company
      .get(
        cancel,
        itens,
        page === 0 ? 1 : page,
        statusSelected.value,
        extra,
        chipData,
      )
      .then(res => {
        setTotal(res.pages);
        setData(res.docs);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    searchData();
  }, [page, itens, statusSelected]);

  const handleDelete = chipToDelete => () => {
    setOptions([
      ...options,
      { label: chipToDelete.by, value: chipToDelete.byType },
    ]);
    setChipData(chips => chips.filter(chip => chip.key !== chipToDelete.key));
  };

  const addChip = btn => {
    if (searchType) {
      const orig = _.cloneDeep(options);
      setChipData([
        ...chipData,
        {
          key: chipData.length + 1,
          label: searchRef.current.value,
          by: searchType.label,
          byType: searchType.value,
        },
      ]);
      const find = orig.findIndex(e => e.value === searchType.value);

      orig.splice(find, 1);
      setSearchType(null);
      setOptions(orig);
      if (btn) {
        searchData({
          label: searchRef.current.value,
          byType: searchType.value,
        });
      }
      searchRef.current.value = '';
    } else {
      notifyError('Informe o tipo de busca');
    }
  };

  const internSearch = () => {
    if (searchType?.value !== '' && searchRef?.current?.value !== '') {
      addChip(true);
    } else {
      searchData();
    }
  };

  const teclaEnter = e => {
    if (e.key === 'Enter') {
      internSearch();
    }
  };

  const newConf = e => {
    e.preventDefault();
    setLoadingNew(true);
    api.company
      .new(
        companyName,
        cnpj,
        im,
        ie,
        address,
        number,
        uf?.value,
        postalCode,
        complement,
        prefix,
        district,
        city,
        principal,
        certName,
      )
      .then(res => {
        notifySuccess(res.message);
        setOpenNew(false);
        setCompanyName('');
        setCnpj('');
        setIe('');
        setIm('');
        setAddress('');
        setNumber('');
        setUf('');
        setPostalCode('');
        setComplement('');
        setPrefix('');
        setDistrict('');
        setPrincipal(false);
        setCertName('');
        searchData();
      })
      .catch(err => {
        if (err?.problems?.length > 0) {
          err.problems.forEach(p => {
            notifyError(p.msg);
          });
        } else {
          notifyError(err.message);
        }
      })
      .finally(() => setLoadingNew(false));
  };

  return (
    <>
      <Loading open={loading} msg={msg} />
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          sm={12}
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <h3>Empresa base control</h3>
          <Grid item>
            <Button
              onClick={() => setOpenNew(true)}
              color="primary"
              variant="contained"
              size="small"
              style={{ height: '40px' }}
            >
              Nova Empresa
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={7}>
          <Grid container spacing={0} style={{ justifyContent: 'start' }}>
            <Grid item xs={12} sm={4} style={{ marginLeft: 15 }}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <label style={{ marginRight: 10 }}>Filtrar por status:</label>
                <Select
                  styles={customStyles}
                  classNamePrefix="select"
                  placeholder="Tipo"
                  isSearchable={false}
                  options={optionsStatus}
                  onChange={setStatusSelected}
                  menuPortalTarget={document.body}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={5}>
          <Grid container spacing={1} style={{ justifyContent: 'end' }}>
            <Grid item xs={12} sm={3}>
              <Select
                fullWidth
                styles={customStyles}
                onChange={setSearchType}
                value={searchType}
                classNamePrefix="select"
                placeholder="Tipo"
                isSearchable={false}
                options={options}
                menuPortalTarget={document.body}
                style={{ zIndex: 999 }}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <TextField
                onKeyUpCapture={e => teclaEnter(e)}
                inputRef={searchRef}
                color="secondary"
                className={classes.textField}
                fullWidth
                size="small"
                style={{ maxHeight: 10 }}
                variant="outlined"
                InputProps={{
                  className: classes.input,
                }}
              />
            </Grid>
            <Button
              onClick={() => addChip(false)}
              variant="contained"
              size="small"
              style={{ marginLeft: 5, marginRight: 5 }}
            >
              <AddIcon />
            </Button>
            <Button onClick={() => internSearch(true)} variant="contained">
              Buscar
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Grid item className={classes.root}>
            {chipData.map(dt => {
              let icon;
              return (
                <li key={data.key}>
                  <Chip
                    icon={icon}
                    label={`${dt.by} | ${dt.label}`}
                    onDelete={handleDelete(dt)}
                    className={classes.chip}
                  />
                </li>
              );
            })}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12}>
          <CompanyBaseTable
            docs={data}
            setDocs={setData}
            page={page}
            setPage={setPage}
            itens={itens}
            setItens={setItens}
            total={total}
            search={searchData}
          />
        </Grid>
      </Grid>
      {/* MODAL DE NOVA CONFIGURAÇÃO */}
      <Dialog
        onClose={() => setOpenNew(!openNew)}
        open={openNew}
        fullWidth
        maxWidth="sm"
      >
        <Loading open={loadingNew} msg="Salvando nova configuração..." />
        <DialogTitle id="simple-dialog-title">Nova empresa</DialogTitle>
        <form onSubmit={newConf}>
          <Grid
            container
            spacing={1}
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              padding: 20,
            }}
          >
            <Grid item xs={12} sm={12}>
              <Grid
                container
                spacing={1}
                style={{
                  paddingRight: 20,
                  paddingLeft: 20,
                }}
              >
                <Grid item xs={12} sm={12}>
                  <h6>Razão social</h6>
                  <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    type="text"
                    value={companyName}
                    onChange={e => setCompanyName(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Grid
                container
                spacing={1}
                style={{
                  paddingRight: 20,
                  paddingLeft: 20,
                }}
              >
                <Grid item xs={12} sm={6}>
                  <h6>CNPJ</h6>
                  <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    type="number"
                    value={cnpj}
                    onChange={e => setCnpj(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <h6>IE</h6>
                  <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    type="text"
                    value={ie}
                    onChange={e => setIe(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <h6>IM</h6>
                  <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    type="number"
                    value={im}
                    onChange={e => setIm(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <h6>Prefixo NF</h6>
                  <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    type="text"
                    value={prefix}
                    onChange={e => setPrefix(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Grid
                container
                spacing={1}
                style={{
                  paddingRight: 20,
                  paddingLeft: 20,
                }}
              >
                <Grid item xs={12} sm={12}>
                  <h6>Endereço</h6>
                  <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    type="text"
                    value={address}
                    onChange={e => setAddress(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Grid
                container
                spacing={1}
                style={{
                  paddingRight: 20,
                  paddingLeft: 20,
                }}
              >
                <Grid item xs={12} sm={4}>
                  <h6>Número</h6>
                  <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    type="text"
                    value={number}
                    onChange={e => setNumber(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <h6>Bairro</h6>
                  <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    type="text"
                    value={district}
                    onChange={e => setDistrict(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <h6>Cidade</h6>
                  <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    type="text"
                    value={city}
                    onChange={e => setCity(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Grid
                container
                spacing={1}
                style={{
                  paddingRight: 20,
                  paddingLeft: 20,
                  alignItems: 'end',
                }}
              >
                <Grid item xs={12} sm={12}>
                  <h6>Complemento</h6>
                  <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    type="text"
                    value={complement}
                    onChange={e => setComplement(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <h6>Estado</h6>
                  <Select
                    fullWidth
                    styles={customStylesEdit}
                    classNamePrefix="select"
                    placeholder="Tipo"
                    value={uf}
                    onChange={setUf}
                    isSearchable={false}
                    options={UFsOpt}
                    menuPortalTarget={document.body}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <h6>CEP</h6>
                  <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    type="text"
                    value={postalCode}
                    onChange={e => setPostalCode(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div>
                    <h6>Nome certificado</h6>
                    <TextField
                      size="small"
                      variant="outlined"
                      placeholder="Ex: Certificado.pfx"
                      fullWidth
                      type="text"
                      value={certName}
                      onChange={e => setCertName(e.target.value)}
                    />
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  style={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={principal}
                          onChange={e => setPrincipal(e.target.checked)}
                          name="principal"
                          color="primary"
                        />
                      }
                      label="Tornar empresa principal"
                    />
                  </FormGroup>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* </form> */}
          <DialogActions>
            <Button onClick={() => setOpenNew(false)} color="secondary">
              Cancelar
            </Button>
            <Button type="submit" color="primary" variant="contained" autoFocus>
              Salvar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
