import { string, object } from 'yup';

const TutorialSchema = object({
  // id: string(),
  title: string().required('Campo obrigatório'),
  // section: string(),
  subject: string().required('Campo obrigatório'),
  media: string().required('Campo obrigatório'),
  // data: string(),
  // status: string(),
  // attachments: array(),
});

// export type Tutorial = TypeOf<typeof TutorialSchema>

export default TutorialSchema;
