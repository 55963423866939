/* eslint-disable react/prop-types */
import React from 'react';
import { Box, Typography, Grid, Button } from '@material-ui/core';
// import MyContractTableGrid from '@/components/tables/MyContractTableGrid';
import MyContractTableGrid from '../tables/MyContractTableGrid';

import { useStylesModal } from './AppBar.styles';

function MyContractModal({ onClose }) {
  const classes = useStylesModal();

  return (
    <div>
      <Box className={classes.container}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Typography
            className={classes.font}
            variant="h4"
            color="textSecondary"
          >
            Resumo do Contrato
          </Typography>

          <Button onClick={onClose}>X</Button>
        </Grid>
        <Box className={classes.content}>
          <Grid container spacing={5} wrap="wrap">
            <Grid item xs={6} sm={4} md={4} lg={3} className={classes.column}>
              <Typography className={classes.subtitle} color="initial">
                Razão Social:
              </Typography>
              <Typography color="initial">Sales Ecosystem</Typography>
            </Grid>
            <Grid item xs={6} sm={4} md={4} lg={3} className={classes.column}>
              <Typography className={classes.subtitle} color="initial">
                Nome:
              </Typography>
              <Typography color="initial">Ramon Carpio</Typography>
            </Grid>
            <Grid item xs={6} sm={4} md={4} lg={3} className={classes.column}>
              <Typography className={classes.subtitle} color="initial">
                E-mail:
              </Typography>
              <Typography color="initial">
                r.carpio@binarygroups.tech
              </Typography>
            </Grid>
            <Grid item xs={6} sm={4} md={4} lg={3} className={classes.column}>
              <Typography className={classes.subtitle} color="initial">
                CNPJ:
              </Typography>
              <Typography color="initial">21.785.060/0001-37</Typography>
            </Grid>
            <Grid item xs={6} sm={4} md={4} lg={3} className={classes.column}>
              <Typography className={classes.subtitle} color="initial">
                CPF:
              </Typography>
              <Typography color="initial">243.079.818-20</Typography>
            </Grid>
            <Grid item xs={6} sm={4} md={4} lg={3} className={classes.column}>
              <Typography className={classes.subtitle} color="initial">
                Telefone:
              </Typography>
              <Typography color="initial"> </Typography>
            </Grid>
            <Grid item xs={6} sm={4} md={4} lg={3} className={classes.column}>
              <Typography className={classes.subtitle} color="textSecondary">
                Celular:
              </Typography>
              <Typography color="initial">11 98176-3545</Typography>
            </Grid>
            <Grid item xs={6} sm={4} md={4} lg={3} className={classes.column}>
              <Typography className={classes.subtitle} color="initial">
                UF:
              </Typography>
              <Typography color="initial">SP</Typography>
            </Grid>
          </Grid>
        </Box>
        <MyContractTableGrid />
      </Box>
    </div>
  );
}

export default MyContractModal;
