/* eslint-disable max-len */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
// import CssBaseline from '@mui/material/CssBaseline';

// import { styled } from '@mui/material/styles';
// import styled from 'styled-components';
import {
  makeStyles,
  createStyles,
  withStyles,
  // styled,
  // createTheme,
  // ThemeProvider,
} from '@material-ui/core/styles';

import { Box, Container, TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import styles from '../../../styles/Layout.module.css';
import Created from './Created';
import MyButton from '../../../components/button/MyButton';
import Destination from './Destination';
import routing from '../../../service/routes/routing';

// import Paper from '@mui/material/Paper';
// import Stepper from '@mui/material/Stepper';
// import Step from '@mui/material/Step';
// import StepLabel from '@mui/material/StepLabel';
// import { useTranslation } from 'react-i18next';
// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';

const useStyles = makeStyles(() =>
  createStyles({
    myLabel: {
      color: '#657588',
      fontWeight: 'bold',
      fontFamily: 'Open Sans',
    },
    myLabelFinal: {
      color: '#41b490',
      fontWeight: 'bold',
      fontFamily: 'Open Sans',
      cursor: 'pointer',
    },
    myDiv: {
      marginTop: 50,
      marginBottom: 50,
    },
    labelDiv: {
      fontFamily: 'Open Sans',
      color: '#657588',
    },
  }),
);

// const CssTextField = styled(TextField)({
//   color: '#657588',
//   '& label.Mui-focused': {
//     color: 'green',
//   },
//   '& .MuiInput-underline:after': {
//     borderBottomColor: 'green',
//   },
//   '& .MuiOutlinedInput-root': {
//     color: '#000',
//     '& fieldset': {
//       borderColor: '#F2F1F1',
//       // backgroundColor: '#F2F1F1',
//       color: '#000',
//     },
//     '&:hover fieldset': {
//       borderColor: '#F2F1F1',
//     },
//     '&.Mui-focused fieldset': {
//       borderColor: '#F2F1F1',
//     },
//   },
// });

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'green',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiOutlinedInput-root': {
      color: '#000',
      '& fieldset': {
        borderColor: '#F2F1F1',
        // backgroundColor: '#F2F1F1',
        color: '#000',
      },
      '&:hover fieldset': {
        borderColor: '#F2F1F1',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#F2F1F1',
      },
    },
  },
})(TextField);

const steps = ['Subject', 'Destination'];
// const theme = createTheme();
export default function subjectNotification({ setOpenModal }) {
  const history = useHistory();
  const [activeStep, setActiveStep] = useState(0);
  const classes = useStyles();

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <div className={classes.myDiv}>
            <h5 className={classes.labelDiv}>Assunto da notificação</h5>
            {/* <TextField fullWidth id="outlined-basic" variant="outlined" /> */}
            <CssTextField
              variant="outlined"
              fullWidth
              id="custom-css-outlined-input"
            />
          </div>
        );
      case 1:
        return <Destination />;
      default:
        throw new Error('Unknown step');
    }
  }
  return (
    // <ThemeProvider theme={theme}>
    //   <CssBaseline />
    <Container component="main" style={{ padding: 30 }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'end',
          alignItems: 'center',
        }}
      >
        <p className="x" onClick={() => setOpenModal(false)}>
          X
        </p>
      </div>
      {activeStep < 2 && (
        <h6 className={styles.textCheckoutInform} style={{ marginTop: -20 }}>
          {activeStep === 0 ? 'Criar notificação' : 'Destinatários'}
        </h6>
      )}
      <>
        {activeStep === steps.length ? (
          <Created setOpenModal={setOpenModal} />
        ) : (
          <>
            {getStepContent(activeStep)}
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'end',
                alignItems: 'center',
                marginBottom: 20,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                {/* <MyButton
                    style={{
                      width: 150, height: 40, borderRadius: 10, backgroundColor: '#657588',
                    }}
                    onClick={handleBack}
                    label="Cancelar"
                  /> */}
                {activeStep < 1 ? (
                  <>
                    <h6
                      style={{ cursor: 'pointer' }}
                      className={classes.myLabel}
                      onClick={() => setOpenModal(false)}
                    >
                      Cancelar
                    </h6>
                    <MyButton
                      style={{
                        width: 150,
                        height: 40,
                        borderRadius: 10,
                        marginLeft: 30,
                      }}
                      onClick={handleNext}
                      label="Avançar"
                    />
                  </>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: 15,
                    }}
                  >
                    <h6
                      className={classes.myLabelFinal}
                      onClick={() => history.push(routing.newClientsGroup)}
                    >
                      Criar grupo +
                    </h6>
                    <MyButton
                      style={{
                        width: 150,
                        height: 40,
                        borderRadius: 10,
                        marginLeft: 30,
                      }}
                      onClick={handleNext}
                      label="Criar mensagem"
                    />
                  </div>
                )}
              </Box>
            </div>
          </>
        )}
      </>
      {/* </Paper> */}
    </Container>
    // </ThemeProvider>
  );
}
