/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */

import React, { useState, useEffect, useRef, useContext } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';

import {
  // Tooltip,
  Checkbox,
  TablePagination,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Paper,
  styled,
} from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from 'date-fns/locale';
import EditIcon from '@material-ui/icons/Edit';
// import GetAppIcon from '@material-ui/icons/GetApp';

// import { useExcelDownloder } from 'react-xls';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';

import Button from '@material-ui/core/Button';

import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import _ from 'lodash';
import MyExcelExport from './MyExcelExport';
import Loading from '../../components/loading/Loading';

import FlashContext from '../../components/contexts/FlashContext';

import api from '../../service/axios/api';

const optionsStatus = [
  {
    label: 'Ativo',
    value: 'Active',
  },
  {
    label: 'Aguardando Link',
    value: 'AwatingIssuerLink',
  },
  {
    label: 'Aguardando finalizar',
    value: 'AwaitingRelease',
  },
  {
    label: 'Aguardando pagamento',
    value: 'AwaitingPayment',
  },
  {
    label: 'Estoque',
    value: 'InStock',
  },
  {
    label: 'Fraudado',
    value: 'Fraud',
  },
  {
    label: 'Cancelado',
    value: 'Canceled',
  },
];

const optionsFlag = [
  {
    label: 'MasterCard',
    value: 'mastercard',
  },
  {
    label: 'Visa',
    value: 'visa',
  },
  {
    label: 'Cabal',
    value: 'cabal',
  },
];

const optionsCardType = [
  {
    label: 'Saque/Compra',
    value: true,
  },
  {
    label: 'Compra',
    value: false,
  },
];

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark'
        ? 'rgba(57,75,89,.5)'
        : 'rgba(206,217,224,.5)',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#137cbd',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#106ba3',
  },
});

const customStylesEdit = {
  control: provided => ({
    ...provided,
    // width: 105,
    height: 40,
    borderRadius: 10,
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  option: provided => ({
    ...provided,
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
};

function createData(
  cardId,
  clientId,
  name,
  cpf,
  status,
  proxy,
  cardNum,
  flag,
  validate,
  Billed,
  all,
) {
  // const density = population / size;
  return {
    cardId,
    clientId,
    name,
    cpf,
    status,
    proxy,
    cardNum,
    flag,
    validate,
    Billed,
    all,
  };
}

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  table: {
    minWidth: 1100,
  },
});

const StyledTableCell = withStyles(() => ({
  head: {
    // backgroundColor: theme.palette.common.black,
    // color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

// eslint-disable-next-line no-unused-vars
export default function CardsTable({
  docs,
  setDocs,
  searchData,
  page,
  setPage,
  itens,
  setItens,
  total,
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);
  const [rows, setRows] = useState([]);
  const [optionsClients, setOptionsClients] = useState([]);

  const [dataExcel, setDataExcel] = useState();
  const [dataEdit, setDataEdit] = useState();
  const [typeSelected, setTypeSelected] = useState({
    label: 'tipo',
    value: 'value',
  });
  const [typeCardSelected, setTypeCardSelected] = useState();
  const [cardFlag, setCardFlag] = useState();

  const [msg, setMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(100);
  const [myOpt, setMyOpt] = useState([]);

  const [contractOpt, setContractOpt] = useState([]);
  const [billed, setBilled] = useState();
  const [portador, setPortador] = useState();

  const { notifyError, notifySuccess } = useContext(FlashContext);

  const name = useRef(null);
  const cpf = useRef(null);
  const proxy = useRef(null);
  const cardNumber = useRef(null);
  const emissor = useRef(null);

  const [selected, setSelected] = useState([]);

  // const { ExcelDownloder, Type } = useExcelDownloder();

  // CHECKBOX
  // const { onSelectAllClick, numSelected, rowCount, onRequestSort } = props;

  const isSelected = id =>
    selected.indexOf(selected.find(e => e === id)) !== -1;

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.cardId);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, row) => {
    // const selectedIndex = selected.indexOf(row.id);
    const selectedIndex = selected.indexOf(
      selected.find(s => s === row.cardId),
    );
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row.cardId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  // useEffect(() => {
  //   console.log(selected);
  // }, [selected]);

  useEffect(() => {
    const cancel = new AbortController();
    api.individual
      .getAll()
      .then(res => {
        const list = [];
        res.retorno.forEach((e, i) => {
          list.push({ label: e.name, value: e.id });
        });
        setOptionsClients(list);
      })
      .catch(err => console.log(err));

    api.contract
      .getByProduct(cancel, 'Card', false)
      .then(res => {
        const list = [];
        res.forEach(e => {
          list.push({ label: e.companyName, value: e.id });
        });
        setContractOpt(list);
      })
      .catch(err => console.log(err));
  }, []);
  useEffect(() => {
    if (dataEdit) {
      switch (dataEdit.status) {
        case 'Active':
          setTypeSelected({
            label: 'Ativo',
            value: 'Active',
          });
          break;
        case 'AwatingIssuerLink':
          setTypeSelected({
            label: 'Aguardando Link',
            value: 'AwatingIssuerLink',
          });
          break;
        case 'AwaitingRelease':
          setTypeSelected({
            label: 'Aguardando finalizar',
            value: 'AwaitingRelease',
          });
          break;
        case 'InStock':
          setTypeSelected({
            label: 'Estoque',
            value: 'InStock',
          });
          break;
        case 'Fraud':
          setTypeSelected({
            label: 'Fraudado',
            value: 'Fraud',
          });
          break;
        case 'Canceled':
          setTypeSelected({
            label: 'Cancelado',
            value: 'Canceled',
          });
          break;
        case 'AwaitingIssuerLinkResponse':
          setTypeSelected({
            label: 'Aguardando vinculação',
            value: 'AwaitingIssuerLinkResponse',
          });
          break;
        default:
          console.log('Deu erro');
      }
      if (dataEdit.Billed) {
        const find = contractOpt.find(e => e.label === dataEdit.Billed);
        setBilled(find);
      }
      if (dataEdit.name) {
        const find = optionsClients.find(e => e.label === dataEdit.name);
        setPortador(find);
      }
    }
  }, [dataEdit]);

  useEffect(() => {
    const list = [];
    const listCard = [];
    setSelected([]);
    docs.forEach(d => {
      list.push(
        createData(
          d.id,
          d.Individual && d.Individual.id,
          d.name,
          d.cpf,
          d.status,
          d.proxy,
          d.card_number,
          d.brand,
          d.good_through,
          d.billed,
          d,
        ),
      );
      listCard.push({
        CardId: d.id,
        ClientId: d.Individual && d.Individual.id,
        Nome: d.Individual && d.Individual.name,
        CPF: d.Individual && d.Individual.cpf,
        Status: d.status,
        Proxy: d.proxy,
        Card: d.card_number,
        Bandeira: d.brand,
        Faturado: d.Billed,
      });
    });
    setDataExcel({ Cartoes: listCard });
    setRows(list);
  }, [docs]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setItens(event.target.value);
  };

  const edit = data => {
    if (selected.length > 1) {
      setOpenStatus(true);
    } else {
      setDataEdit(data);
      setOpen(true);
    }
  };

  const updateCard = e => {
    e.preventDefault();
    setMsg('Atualizando cartão...');
    setLoading(true);
    const orig = _.cloneDeep(docs);
    api.product
      .updateCardAdminTotal(
        dataEdit.cardId,
        typeSelected.value,
        cardNumber.current.value,
        billed.value,
        portador.value,
      )
      .then(() => {
        const find = orig.find(el => el.id === dataEdit.cardId);
        find.status = typeSelected.value;
        find.card_number = cardNumber.current.value;
        find.billed = billed.label;
        find.name = portador.label;
        setDocs(orig);
        setLoading(false);
        notifySuccess('Atualização realizada com sucesso');
        setOpen(false);
      })
      .catch(ee => {
        setLoading(false);
        notifyError(ee.message);
      });
  };

  const updateCardStatus = e => {
    e.preventDefault();
    // setMsg('Atualizando cartão...');
    setLoading(true);
    const orig = _.cloneDeep(docs);
    console.log('Atualização em massa', selected, typeSelected.value);
    api.product
      .updateCardAdminBatch(selected, typeSelected.value)
      .then(() => {
        searchData();
        setLoading(false);
        setSelected([]);
        notifySuccess('Atualização realizada com sucesso');
        setOpenStatus(false);
      })
      .catch(ee => {
        setLoading(false);
        notifyError(ee.message);
      });
    // api.product
    //   .updateCardAdminTotal(
    //     dataEdit.cardId,
    //     typeSelected.value,
    //     cardNumber.current.value,
    //     billed.value,
    //     portador.value,
    //   )
    //   .then(() => {
    //     const find = orig.find(el => el.id === dataEdit.cardId);
    //     find.status = typeSelected.value;
    //     find.card_number = cardNumber.current.value;
    //     find.billed = billed.label;
    //     find.name = portador.label;
    //     setDocs(orig);
    //     setLoading(false);
    //     notifySuccess('Atualização realizada com sucesso');
    //     setOpen(false);
    //   })
    //   .catch(ee => {
    //     setLoading(false);
    //     notifyError(ee.message);
    //   });
  };

  async function callApi(value) {
    if (value) {
      const data = optionsClients.filter(i =>
        i.label.toLowerCase().includes(value.toLowerCase()),
      );
      return data;
    }
    const dataLimit = [];
    optionsClients.forEach((e, i) => {
      if (i < limit + 1000) dataLimit.push(e);
    });
    return dataLimit;
  }

  const mountDataExcel = d => {
    return <MyExcelExport allData={d} />;
  };

  return (
    <>
      <Paper className={classes.root}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell style={{ width: 10, margin: 0, padding: 0 }}>
                  <Checkbox
                    // color="primary"
                    checkedIcon={<BpCheckedIcon />}
                    icon={<BpIcon />}
                    onChange={handleSelectAllClick}
                    indeterminate={
                      selected.length > 0 && selected.length < rows.length
                    }
                    // checked={rowCount > 0 && numSelected === rowCount}
                    // onChange={e => handleClick(e, row)}
                    inputProps={{
                      'aria-label': 'select all desserts',
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell>Nome</StyledTableCell>
                <StyledTableCell>CPF</StyledTableCell>
                <StyledTableCell style={{ width: 100 }}>Status</StyledTableCell>
                <StyledTableCell>Proxy</StyledTableCell>
                <StyledTableCell>Número do cartão</StyledTableCell>
                <StyledTableCell>Bandeira</StyledTableCell>
                <StyledTableCell>Validade</StyledTableCell>
                <StyledTableCell>Faturado</StyledTableCell>
                <StyledTableCell align="right">
                  <div
                    style={{
                      display: 'flex',
                      // alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    Ação
                    {dataExcel && (
                      <>
                        {dataExcel && mountDataExcel(dataExcel)}
                        {/* <MyExcelExport allData={dataExcel} /> */}
                      </>
                    )}
                  </div>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, i) => {
                const isItemSelected = isSelected(row.cardId);
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <StyledTableRow key={i}>
                    <StyledTableCell
                      style={{ width: 10, margin: 0, padding: 0 }}
                    >
                      <Checkbox
                        // color="primary"
                        checkedIcon={<BpCheckedIcon />}
                        icon={<BpIcon />}
                        // indeterminate={numSelected > 0 && numSelected < rowCount}
                        // checked={
                        //   rows.length > 0 && selected.length === rows.length
                        // }
                        checked={isItemSelected}
                        onChange={e => handleClick(e, row)}
                        inputProps={{
                          'aria-label': 'select all desserts',
                        }}
                      />
                    </StyledTableCell>
                    <StyledTableCell>{row.name}</StyledTableCell>
                    <StyledTableCell>{row.cpf}</StyledTableCell>
                    <StyledTableCell style={{ width: 100 }}>
                      {row.status}
                    </StyledTableCell>
                    <StyledTableCell>{row.proxy}</StyledTableCell>
                    <StyledTableCell>{row.cardNum}</StyledTableCell>
                    <StyledTableCell>{row.flag}</StyledTableCell>
                    <StyledTableCell>{row.validate}</StyledTableCell>
                    <StyledTableCell>{row.Billed}</StyledTableCell>
                    <StyledTableCell align="right">
                      <EditIcon
                        style={{ cursor: 'pointer' }}
                        onClick={() => edit(row)}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100, 1000, 50000]}
          component="div"
          count={total}
          rowsPerPage={itens}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      {/* MODAL DE EDIÇÃO */}
      <Dialog
        onClose={() => setOpen(!open)}
        open={open}
        fullWidth
        maxWidth="sm"
      >
        <Loading open={loading} msg={msg} />
        <DialogTitle id="simple-dialog-title">Edição de cartão</DialogTitle>
        <form onSubmit={updateCard}>
          <Grid
            container
            spacing={1}
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              padding: 20,
            }}
          >
            <Grid item xs={12} sm={12}>
              <Grid
                container
                spacing={1}
                style={{
                  paddingRight: 20,
                  paddingLeft: 20,
                }}
              >
                <Grid item xs={12} sm={12}>
                  <h6>Portador</h6>
                  <AsyncSelect
                    // cacheOptions
                    // options={myOpt}
                    placeholder="Portador"
                    loadOptions={callApi}
                    // onInputChange={data => {
                    //   console.log(data);
                    // }}
                    value={portador}
                    // onMenuScrollToBottom={callApi}
                    onChange={setPortador}
                    defaultOptions
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Grid
                container
                spacing={1}
                style={{
                  paddingRight: 20,
                  paddingLeft: 20,
                }}
              >
                <Grid item xs={12} sm={12}>
                  <h6>Contrato</h6>
                  <Select
                    fullWidth
                    styles={customStylesEdit}
                    classNamePrefix="select"
                    placeholder="Contrato"
                    value={billed}
                    onChange={setBilled}
                    // isSearchable={false}
                    options={contractOpt}
                    menuPortalTarget={document.body}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Grid
                container
                spacing={1}
                style={{
                  paddingRight: 20,
                  paddingLeft: 20,
                }}
              >
                <Grid item xs={12} sm={12}>
                  <h6>Status</h6>
                  <Select
                    fullWidth
                    styles={customStylesEdit}
                    classNamePrefix="select"
                    placeholder="Tipo"
                    value={typeSelected}
                    onChange={setTypeSelected}
                    isSearchable={false}
                    options={optionsStatus}
                    menuPortalTarget={document.body}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Grid
                container
                spacing={1}
                style={{
                  paddingRight: 20,
                  paddingLeft: 20,
                }}
              >
                <Grid item xs={12} sm={12}>
                  <h6>Número do cartão</h6>
                  <TextField
                    inputRef={cardNumber}
                    size="small"
                    variant="outlined"
                    fullWidth
                    defaultValue={dataEdit && dataEdit.cardNum}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={1}
              style={{
                paddingRight: 20,
                paddingLeft: 20,
              }}
            />
          </Grid>
          {/* </form> */}
          <DialogActions>
            <Button onClick={() => setOpen(false)} color="secondary">
              Cancelar
            </Button>
            <Button
              // onClick={() => setOpen(false)}
              type="submit"
              color="primary"
              variant="contained"
              autoFocus
            >
              Salvar alterações
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
        onClose={() => setOpenStatus(!openStatus)}
        open={openStatus}
        fullWidth
        maxWidth="sm"
      >
        <Loading open={loading} msg={msg} />
        <DialogTitle id="simple-dialog-title">
          Atualização STATUS em massa
        </DialogTitle>
        <form onSubmit={updateCardStatus}>
          <Grid
            container
            spacing={1}
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              padding: 20,
            }}
          >
            <Grid item xs={12} sm={12}>
              <Grid
                container
                spacing={1}
                style={{
                  paddingRight: 20,
                  paddingLeft: 20,
                }}
              >
                <Grid item xs={12} sm={12}>
                  <h6>Status</h6>
                  <Select
                    fullWidth
                    styles={customStylesEdit}
                    classNamePrefix="select"
                    placeholder="Tipo"
                    value={typeSelected}
                    onChange={setTypeSelected}
                    isSearchable={false}
                    options={optionsStatus}
                    menuPortalTarget={document.body}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* </form> */}
          <DialogActions>
            <Button onClick={() => setOpenStatus(false)} color="secondary">
              Cancelar
            </Button>
            <Button
              // onClick={() => setOpen(false)}
              type="submit"
              color="primary"
              variant="contained"
              autoFocus
            >
              Salvar alterações
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
