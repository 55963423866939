/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types */
import React, { useContext, useState } from 'react';
import { Paper } from '@material-ui/core';
import StepperContext, { StepperProvider } from '../../context/StepperContext';

import CampaignNewForm from './CampaignNewForm';
// import ProductDetailsForm from './ProductDetailsForm';
import CampaingReviewForm from './CampaingReviewForm';
// import defaultProposal from './defaultStates/proposal';

function StepForm({ campaing }) {
  const stepper = useContext(StepperContext);

  switch (stepper.step) {
    case 1:
      return <CampaignNewForm campaing={campaing} />;
    // case 2:
    //   return <ProductDetailsForm productDetails={proposal.productDetails} />;
    default:
      return <CampaingReviewForm campaing={campaing} />;
  }
}

function CampaignForm({
  // proposal = defaultProposal,
  campaign = { name: '', expiresIn: '', comments: '' },
  disabled,
  onSuccess,
  code,
  titleModal,
  actionModal,
}) {
  const [data, setData] = useState(campaign);
  function updateData(stepData) {
    if (stepData) {
      setData(prevData => {
        return { ...prevData, ...stepData };
      });
    }
  }

  // useEffect(() => {
  //   if (campaign) {
  //     console.log('Campaign', campaign);
  //     setData(campaign);
  //   }
  // }, [campaign]);

  return (
    <Paper elevation={4}>
      <StepperProvider
        disabled={disabled}
        onChange={updateData}
        onSuccess={onSuccess}
        code={code}
        titleModal={titleModal}
        actionModal={actionModal}
      >
        <StepForm campaing={data} />
      </StepperProvider>
    </Paper>
  );
}

export default CampaignForm;
