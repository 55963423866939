/* eslint-disable react/prop-types */
import React from 'react';

import clsx from 'clsx';

// Importing Material UI components
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';

import { useStylesDropdown } from './AppBar.styles';

function Item({ divider = false, onClick, children, ...others }) {
  const classes = useStylesDropdown();

  return (
    <>
      <Box
        className={clsx(classes.item, {
          [classes.click]: onClick,
        })}
        onClick={onClick}
        {...others}
      >
        {children}
      </Box>
      {divider && <Divider />}
    </>
  );
}

export default Item;
