/* eslint-disable react/prop-types */
import React from 'react';
import Table from '../Table';
// import { RenderCellExpand } from './RenderCell';

import { getLeadContacts } from './getters';

const columns = [
  {
    field: 'contactFullName',
    headerName: 'Nome Completo',
    valueGetter: getLeadContacts,
    // renderCell: getLeadContacts,
    flex: 1,
  },
  {
    field: 'companyRole',
    headerName: 'Cargo',
    flex: 1,
  },
  {
    field: 'companyArea',
    headerName: 'Área',
    flex: 1,
  },
  {
    field: 'companyLegalName',
    headerName: 'Razão Social',
    flex: 1,
  },
  {
    field: 'cnpj',
    headerName: 'CNPJ',
    flex: 1,
  },
  {
    field: 'email',
    headerName: 'Email',
    flex: 1,
  },
  {
    field: 'mobile',
    headerName: 'Celular',
    flex: 1,
  },
  {
    field: 'phone',
    headerName: 'Telefone',
    flex: 1,
  },
  {
    field: 'createdAt',
    headerName: 'Data',
    valueFormatter: params => {
      const { value } = params;
      return value;
    },
    width: 0,
  },
];

function ProposalRequestTable({ proposal, ...others }) {
  return (
    <Table
      columns={columns}
      rows={proposal}
      sortModel={[{ field: 'createdAt', sort: 'desc' }]}
      {...others}
    />
  );
}

export default ProposalRequestTable;
