/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import moment from 'moment-timezone';
import styles from '../../../styles/Layout.module.css';
import TablePremioTransfer from '../../../components/tables/premiados/TablePremioTransfer';
import api from '../../../service/axios/api';

export default function UploadPlan({
  contract,
  orderId,
  myCnpj,
  valueCredit,
  specOrder,
}) {
  const [total, setTotal] = useState(0);
  const [orders, setOrders] = useState([]);
  const [taxPercent, setTaxPercent] = useState(0);
  const [provision, setProvision] = useState(0);
  const [qtd, setQtd] = useState(0);
  const [ticket, setTicket] = useState(0);
  const [finish, setFinish] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (orderId) {
      const cancel = new AbortController();
      api.order
        .getItemsByOrderId(Number(orderId), cancel)
        .then(details => {
          setOrders(details.docs);
          let tot = 0;
          let iQtd = 0;
          let tax = 0;
          let prov = 0;
          let iTicket = 0;

          details.docs.forEach(doc => {
            if (
              doc.comments === 'Carga' &&
              doc.status === 'Received' &&
              !doc.is_detail
            ) {
              tot += Number(doc.value);
              iQtd += 1;
            }
            if (doc.comments === 'Honorários (Percentual)' && !doc.is_detail)
              tax += Number(doc.value);
            if (
              doc.comments === 'Tarifa de Emissão de Boleto' &&
              !doc.is_detail
            )
              iTicket += Number(doc.value);
            if (doc.comments === 'Provisão' && !doc.is_detail) {
              prov += Number(doc.value);
              if (doc.is_auto_provision) {
                setFinish(true);
              }
            }
          });
          setTotal(tot);
          setQtd(iQtd);
          setTaxPercent(tax);
          setProvision(prov);
          setTicket(iTicket);
        })
        .catch(err => {
          console.log(err);
        });
    }
    setTaxPercent(3);
    setProvision(106);
    setTicket(3);
  }, [orderId]);

  return (
    <div className="box-new-card">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Grid container>
          <Grid item>
            <h6 className={styles.textBoxInform}>
              {t('newOrder.transferSummary.label')}
            </h6>
          </Grid>
        </Grid>
        <Grid container style={{ justifyContent: 'end' }}>
          <Grid item style={{ marginRight: 30 }}>
            <h6 className={styles.textBoxInform}>
              {t('newOrder.transferSummary.date')}
              {moment.utc(new Date()).format('DD/MM/yyyy')}
            </h6>
          </Grid>
        </Grid>
      </div>
      <Grid
        container
        direction="row"
        style={{ justifyContent: 'space-between' }}
      >
        <Grid item xs={12}>
          <div style={{ display: 'flex' }}>
            <p className={styles.labelTitleResum}>
              {t('newOrder.transferSummary.product')}:
            </p>
            <p>
              {t('newOrder.transferSummary.transfer')}
              {specOrder === 'DCP'
                ? ' (DEMONSTRATIVO CONSOLIDADO DE PREMIAÇÃO)'
                : ''}
            </p>
          </div>
        </Grid>
        <Grid item>
          <div style={{ display: 'flex' }}>
            <p className={styles.labelTitleResum}>
              {t('newOrder.transferSummary.contractor')}:
            </p>
            <p> {contract && contract.label}</p>
          </div>
        </Grid>
        <Grid item>
          <div style={{ display: 'flex' }}>
            <p className={styles.labelTitleResum}>
              {t('newOrder.reviewCards.cnpjContractor')}:
            </p>
            <p>
              {myCnpj?.label?.replace(
                /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                '$1.$2.$3/$4-$5',
              )}
            </p>
          </div>
        </Grid>
        {qtd > 0 && (
          <Grid item>
            <div style={{ display: 'flex' }}>
              <p className={styles.labelTitleResum}>
                Quantidade de transferências:
              </p>
              <p>{qtd}</p>
            </div>
          </Grid>
        )}
        {ticket > 0 && (
          <Grid item>
            <div style={{ display: 'flex' }}>
              <p className={styles.labelTitleResum}>Tarifa boleto:</p>
              <p>
                {new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })
                  .format(ticket)
                  .replace(',', '.')}
              </p>
            </div>
          </Grid>
        )}
        {taxPercent > 0 && (
          <Grid item>
            <div style={{ display: 'flex' }}>
              <p className={styles.labelTitleResum}>Honorários:</p>
              <p>
                {new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })
                  .format(taxPercent)
                  .replace(',', '.')}
              </p>
            </div>
          </Grid>
        )}
        {total > 0 && (
          <Grid item>
            <div style={{ display: 'flex' }}>
              <p className={styles.labelTitleResum}>Total transferências:</p>
              <p>
                {new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })
                  .format(total)
                  .replace(',', '.')}
              </p>
            </div>
          </Grid>
        )}
        {valueCredit > 0 && (
          <Grid item>
            <div style={{ display: 'flex' }}>
              <p className={styles.labelTitleResum}>Total Crédito:</p>
              <p>
                {new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })
                  .format(valueCredit)
                  .replace(',', '.')}
              </p>
            </div>
          </Grid>
        )}
        {provision > 0 && (
          <Grid item>
            <div style={{ display: 'flex' }}>
              <p className={styles.labelTitleResum}>Total da NF:</p>
              <p>
                {new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })
                  .format(
                    finish
                      ? provision
                      : Number(provision) + Number(ticket) + Number(taxPercent),
                  )
                  .replace(',', '.')}
              </p>
            </div>
          </Grid>
        )}
      </Grid>
      <Grid
        container
        direction="row"
        style={{
          justifyContent: 'space-between',
          marginBottom: -15,
          marginTop: 20,
        }}
      />
      <Grid
        container
        spacing={1}
        direction="row"
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          paddingBottom: 10,
        }}
      >
        <Grid item xs={12}>
          {valueCredit === '' && (
            <TablePremioTransfer
              orders={orders}
              totalPages={1}
              page={1}
              itens={10}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
}
