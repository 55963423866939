/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState, useContext } from 'react';
// import CssBaseline from '@mui/material/CssBaseline';

// import Box from '@mui/material/Box';
// import Container from '@mui/material/Container';
// import Paper from '@mui/material/Paper';
// import Stepper from '@mui/material/Stepper';
// import Step from '@mui/material/Step';
// import StepLabel from '@mui/material/StepLabel';
import { useTranslation } from 'react-i18next';
// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
// import { createTheme, ThemeProvider } from '@mui/material/styles';
// import { makeStyles, createStyles } from '@mui/styles';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Box, Stepper, Step, StepLabel, Container } from '@material-ui/core';
import FlashContext from '../../../../../components/contexts/FlashContext';
import api from '../../../../../service/axios/api';
import Loading from '../../../../../components/loading/Loading';

import TypeCard from './TypeCard';
import TaxCompany from './TaxCompany';
import TaxOwner from './TaxOwner';
import CardLimits from './CardLimits';
import Review from './Review';
import Created from './Created';

import styles from '../../../../../styles/Layout.module.css';

import MyButton from '../../../../../components/button/MyButton';

import './checkout.css';

const useStyles = makeStyles(() =>
  createStyles({
    icon: {
      fill: '#41B490',
    },
    text: {
      fill: 'white',
    },
  }),
);

const steps = [
  'Shipping address',
  'Payment details',
  'Review your order',
  'TaxOwner',
  CardLimits,
];

// eslint-disable-next-line react/prop-types
export default function Checkout({ setOpenModal }) {
  const [activeStep, setActiveStep] = React.useState(0);
  const classes = useStyles();
  const [productName, setProductName] = useState();
  const [loading, setLoading] = useState(false);
  const { notifyError } = useContext(FlashContext);

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <TypeCard setProductName={setProductName} productName={productName} />
        );
      case 1:
        return <TaxCompany />;
      case 2:
        return <TaxOwner />;
      case 3:
        return <CardLimits />;
      case 4:
        return <Review />;
      default:
        throw new Error('Unknown step');
    }
  }

  const { t } = useTranslation();
  const handleNext = () => {
    if (activeStep === 4) {
      if (productName) {
        setLoading(true);
        api.product
          .newCard(productName, 'Card')
          .then(() => {
            setActiveStep(activeStep + 1);
          })
          .catch(err => {
            console.error(err);
          })
          .finally(() => setLoading(false));
      } else {
        notifyError('Informe o nome do produto');
      }
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep === 0) {
      setOpenModal(false);
    } else {
      setActiveStep(activeStep - 1);
    }
  };

  return (
    <>
      <Loading open={loading} msg="Cadastrando novo produto..." />
      <Container component="main" width={1192} height={776}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {/* <p className="new-category">Nova categoria de produto</p> */}
          <Stepper activeStep={activeStep} style={{ width: 505 }}>
            {steps.map(
              label =>
                activeStep < 5 && (
                  <Step key={label} style={{ color: '#41B490' }}>
                    <StepLabel
                      StepIconProps={{
                        classes: {
                          active: classes.icon,
                          text: classes.text,
                          completed: classes.icon,
                        },
                      }}
                    />
                  </Step>
                ),
            )}
          </Stepper>
          <p className="x" onClick={() => setOpenModal(false)}>
            X
          </p>
        </div>
        {activeStep < 5 && (
          <p className="text-checkout-inform">
            {t('Product.creditCard.checkout.label1')}
          </p>
        )}
        <>
          {activeStep === steps.length ? (
            <>
              <Created setOpenModal={setOpenModal} />
            </>
          ) : (
            <>
              {getStepContent(activeStep)}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginTop: 50,
                  marginBottom: 50,
                }}
              >
                <h6
                  className={styles.textExit}
                  onClick={() => setOpenModal(false)}
                >
                  Sair
                </h6>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {/* {activeStep !== 0 && ( */}
                  {/* <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                  Voltar
                </Button> */}
                  {/* // )} */}
                  <MyButton
                    style={{
                      width: 120,
                      height: 40,
                      borderRadius: 10,
                      backgroundColor: '#657588',
                    }}
                    onClick={handleBack}
                    label="Voltar"
                  />
                  <MyButton
                    style={{
                      width: 120,
                      height: 40,
                      borderRadius: 10,
                      marginLeft: 30,
                    }}
                    onClick={handleNext}
                    label="Continuar"
                  />
                </Box>
              </div>
            </>
          )}
        </>
        {/* </Paper> */}
      </Container>
    </>
  );
}
