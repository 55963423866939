/* eslint-disable react/prop-types */
import React, { useState } from 'react';

// Importing MaterialUI components
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';

import DateRangeIcon from '@material-ui/icons/DateRange';

// eslint-disable-next-line import/no-duplicates
import { ptBR } from 'date-fns/locale';
// eslint-disable-next-line import/no-duplicates
import { format } from 'date-fns';

import DateRangePicker from '../../DateRangePicker/DateRangePicker';

function CyclePick({ maxValue, minValue, value, onChange }) {
  const [anchor, setAnchor] = useState();

  const onOpen = event => {
    setAnchor(event.currentTarget);
  };

  const onClose = () => {
    setAnchor(undefined);
  };

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Box mr={2} ml={2} component="span">
        <IconButton color="primary" onClick={onOpen}>
          <DateRangeIcon />
        </IconButton>
      </Box>
      <Typography variant="subtitle1">
        {`${
          value.firstDate
            ? format(value.firstDate, 'd MMMM, yyyy', {
                locale: ptBR,
              })
            : null
        } -
            ${
              value.lastDate
                ? format(value.lastDate, 'd MMMM, yyyy', {
                    locale: ptBR,
                  })
                : null
            }`}
      </Typography>
      <Popover
        open={!!anchor}
        anchorEl={anchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={onClose}
      >
        <DateRangePicker
          label="Range picker"
          variant="static"
          value={value}
          onChange={onChange}
          minDate={minValue}
          maxDate={maxValue}
        />
      </Popover>
    </Box>
  );
}

export default CyclePick;
