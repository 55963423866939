/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/function-component-definition */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import moment from 'moment-timezone';
import {
  Grid,
  Box,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableHead,
  Toolbar,
  Typography,
  Paper,
  Checkbox,
  IconButton,
  Tooltip,
  Menu,
  MenuItem,
  Dialog,
  TableCell,
  styled,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import routing from '../../../service/routes/routing';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#005D9F',
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
    color: theme.palette.common.white,
  },
  body: {
    color: '#717171',
    fontFamily: 'Open Sans',
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:hover': {
      backgroundColor: '#41b490',
      color: '#41b490',
    },
  },
}))(TableRow);

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark'
        ? 'rgba(57,75,89,.5)'
        : 'rgba(206,217,224,.5)',
  },
}));

const EnhancedTableHead = props => {
  const { t } = useTranslation();
  const { isCredit } = props;
  return (
    <TableHead>
      <TableRow>
        {!isCredit && (
          <>
            <StyledTableCell component="th">
              {t('newOrder.reviewCards.table.name')}
            </StyledTableCell>
            <StyledTableCell component="th">
              {t('newOrder.reviewCards.table.cpf')}
            </StyledTableCell>
            <StyledTableCell component="th">
              {t('newOrder.reviewCards.table.proxy')}
            </StyledTableCell>
          </>
        )}
        <StyledTableCell component="th">
          {t('newOrder.reviewCards.table.description')}
        </StyledTableCell>
        <StyledTableCell component="th">
          {t('newOrder.reviewCards.table.qtd')}
        </StyledTableCell>
        <StyledTableCell component="th" align="right">
          {t('newOrder.reviewCards.table.value')}
        </StyledTableCell>
      </TableRow>
    </TableHead>
  );
};

const EnhancedTableToolbar = function (props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: '#f3f3f3',
          // (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable({ orders, isCredit }) {
  const [rows, setRows] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    const listOrder = [];
    orders.forEach((or, i) => {
      listOrder.push({
        id: i,
        name: or.individual_name,
        cpf: or.individual_cpf,
        proxy: or?.Card?.proxy,
        description: or.product_description,
        qtd: or.units,
        vlr: or.value,
      });
    });
    setRows(listOrder);
  }, [orders]);

  // useEffect(() => {
  //   const listOrder = [];
  //   orders.forEach((or, i) => {
  //     listOrder.push({
  //       id: i,
  //       name: or['Nome do Premiado'],
  //       cpf: or.CPF,
  //       proxy: or.Proxy,
  //       qtd: 1,
  //       vlr: or.Valor,
  //     });
  //   });
  //   setRows(listOrder);
  // }, [orders]);

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer style={{ borderRadius: 10 }}>
          <Table aria-labelledby="tableTitle" size="medium">
            <EnhancedTableHead isCredit={isCredit} />
            <TableBody>
              {rows ? (
                rows.length > 0 ? (
                  rows.map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <StyledTableRow tabIndex={-1} key={row.id}>
                        {!isCredit && (
                          <>
                            <StyledTableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              // padding="none"
                            >
                              {row.name}
                            </StyledTableCell>
                            <StyledTableCell>{row.cpf}</StyledTableCell>
                            <StyledTableCell>{row.proxy}</StyledTableCell>
                          </>
                        )}

                        <StyledTableCell>{row.description}</StyledTableCell>
                        <StyledTableCell>{row.qtd}</StyledTableCell>
                        <StyledTableCell align="right">
                          R${row.vlr}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })
                ) : (
                  <StyledTableRow>
                    <StyledTableCell colSpan={6} align="center">
                      {t('newOrder.reviewCards.table.msg')}
                    </StyledTableCell>
                  </StyledTableRow>
                )
              ) : (
                <StyledTableRow>
                  <StyledTableCell colSpan={6} align="center" />
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}
