/* eslint-disable prettier/prettier */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      Welcome: 'Welcome to',
      Login: 'Login to continue',
      User: 'User',
      Password: 'Password',
      InvalidPassword: 'Invalid password',
      Forgot: 'I forgot my password',
      Continue: 'Continue',
      Label1: 'Get the code by',
      Label2: 'Two-step verification',
      CodSendBy: 'Code send by',
      Label3: 'Enter the code sent',
      Code: 'Code',
      Label4: 'Log In',
      Loading: 'Checking User and Password...',
      Logging: 'Logging into the system...',
      MessageSend: 'Sending code by SMS...',
      MessageSendEmail: 'Sending link by email...',
      MessageSendEmailSuccess: 'Log in using the link sent to your email.',
      MessageSendEmailError:
        'You are not registered in this authentication mode.',
      initialMenu: 'Home',
      administration: 'Administration',
      conf: 'Account setup',
      confUser: 'Manage users',
      notification: 'Notifications',
      tutorial: 'Tutorials',
      general: 'General',
      DashBoard: {
        orders: 'Orders',
        dashBoard: 'Dashboard',
        contract: 'Contracts and fees',
        products: 'Products',
        baseCards: 'Base of cards',
        movement: 'Movement',
        release: 'Charge release',
      },
      ForgotPassword: {
        recover: 'Recover password',
        label1: 'Enter the registered email',
        send: 'Send',
      },
      Product: {
        product: 'Products',
        newProduct: 'New product',
        initialMenu: 'Home',
        table: {
          product: 'Product',
          category: 'Category',
          type: 'Type',
          provider: 'Provider',
          created: 'Creation',
          origin: 'Origin',
          status: 'Status',
          edit: 'Edit product',
          duplicate: 'Duplicate product',
          suspend: 'Suspend product',
          cancel: 'Cancel product',
        },
        category: {
          label1: 'Choose your product category',
          card: 'Prepaid card',
          account: 'Digital account',
          transfer: 'Transfer',
          qr: 'Voucher QR Code',
          cripto: 'Voucher Cripto',
          plus: '+ Create new category',
        },
        accessAll: 'Access all products',
        acessory: 'Accessory',
        changeProduct: 'Choose the product',
        newInCategory: 'Create new product in this category',
        creditCard: {
          new: 'New Card',
          label1: 'Prepaid card',
          table: {
            product: 'Product',
            type: 'Type',
            flag: 'Flag',
            provider: 'Provider',
            created: 'Creation',
            status: 'Status',
            edit: 'Edit product',
            duplicate: 'Duplicate product',
            suspend: 'Suspend product',
            cancel: 'Cancel product',
            planProducts: 'Product spreadsheet',
            allCategory: 'Access all categories',
            search: 'Search',
            selectStatus: {
              label: 'Status',
              status: {
                opt1: 'Created',
                opt2: 'Awaiting payment',
                opt3: 'Processed',
                opt4: 'Processed with error',
              },
            },
            selectViewer: {
              label: 'Visualization',
              status: {
                opt1: 'All',
              },
            },
            selectOrder: {
              label: 'Order',
              status: {
                opt1: 'Growing',
                opt2: 'Descending',
              },
            },
          },
          checkout: {
            label1: 'New Prepaid Card',
            feature: 'Card Features',
            flag: 'Flag',
            selectFlag: 'Select the flag',
            name: 'Products name',
            nameSugestion: 'Prepaid card',
            issuer: 'Issuer',
            selectIssuer: 'Select issuer',
            function: 'Transactional function',
            opt1Function: 'Gift voucher',
            opt2Function: 'Withdrawal and Purchases',
            technology: 'Technology',
            chip: 'Chip',
            nfc: 'NFC',
            tarja: 'Stripe',
            coverage: 'Roof',
            national: 'National',
            international: 'International',
            exit: 'Exit',
            company: {
              label1: 'Company Operating Tariffs',
              tranferBalance: 'Balance transfer',
              loadLess: 'Less than minimum charge',
              emergenciCargo: 'Emergency load',
              chargebackFee: 'Chargeback fee',
              minimumLoad: 'Minimum load value',
              operatingFee: 'Card handling',
              personalization: 'Personalization',
            },
            client: {
              label1: 'Carrier Operating Tariffs',
              monthlyPayment: 'Monthly payment',
              passwordUnlock: 'Password unlock',
              withdraw: 'ATM withdrawal',
              transfer: 'CC transfer',
              duplicateCard: '2. Via card',
              chargebackFee: 'Chargeback fee',
              chargebackTax: 'Chargeback fee',
              mktTax: "Int'I Mark-up Fee",
              iof: 'IOF/IVA',
            },
            limits: {
              label1: 'Limits',
              transfer1: 'Load limit and',
              transfer2: 'transfers between cards',
              purchases: 'Purchase limit',
              withdraw: 'Withdrawal limit',
              paymentCount: 'Bill payment limit',
              celPhone: 'Cell phone recharge',
              balanceInCard: 'Card balance limit',
              prizeInCard: 'Availability of the reward on the card',
            },
            review: {
              label1: 'Review and confirmation',
              type: 'Product Type: Card',
              name: 'Name: ',
              nameProd: 'Prepaid card',
              operation: 'Operation',
              creditOperation: 'Credit',
              function: 'Functions',
            },
          },
        },
      },
      Order: {
        order: 'Orders',
        orderHistoric: 'Order History',
        newOrder: 'New Order',
        initialMenu: 'Home',
        chargeback: 'Charge back',
        menu: {
          orders: 'Orders',
          order: 'Order',
          history: 'Orders History',
          contract: 'Contracts and fees',
          dashBoard: 'Dashboard',
        },
        filters: {
          status: {
            awaitingSettlement: 'Awaiting settlement',
            awaitingPayment: 'Awaiting payment',
            canceled: 'Canceled',
            created: 'Created',
            error: 'Error',
            errors: 'Errors',
            settled: 'Settled',
          },
          product: {
            card: 'Card',
            transfer: 'Transfer',
          },
          search: {
            nf: 'Nf',
            nd: 'ND',
            payDay: 'Payment Date',
            invoiceFrom: 'Invoiced',
            order: 'Order',
            orderName: 'Order Name',
            value: 'Value',
          },
          btn: 'Search',
          type: 'Type',
          statusLabel: 'Status',
          productLabel: 'Product',
          searchErrorMessage: 'Enter the type of search',
        },
       table: {
          numberNf:  'Nº da NF/ND',
          payment: 'Payment',
          rz: 'Corporate Name',
          orderName: 'Order Name',
          order: 'Order',
          product: 'Product',
          value: 'Value',
          status: 'Status',
          noResult: 'No record available',
          duplicateOrder: 'Duplicate order',
          duplicateMsg: 'Do you really want to duplicate the order?',
          duplicateTitle: 'Order',
          duplicateCancelBtn: 'Cancel',
          duplicateConfirmBtn: 'Yes',
          orderDetails: 'View order',
          orderCancel: 'Cancel order',
          orderCancelTitle: 'Order',
          orderCancelMsg:
            'Do you really want to cancel the order? This action cannot be undone!',
          orderCancelBtn: 'Cancel',
          orderCancelConfirmBtn: 'Yes',
          created: 'Created at'
        },
      },
      OrderDetails: {
        title: 'Order',
        searchMsg: 'Looking for order details...',
        modelPlanMsg: 'Looking for spreadsheet template....',
        creatingMsg: 'Creating',
        completeErrorMsg: 'Error completing order.',
        paymentErrorMsg: 'Error reporting order payment.',
        cancelingMsg: 'Canceling order...',
        productLabel: 'Product',
        cancelingErrorMsg: 'You must select at least one item to be deleted.',
        savingOrderMsg: 'Saving order',
        reviewPlanMsg:
          'Some cards were not added to the order. Review the worksheet.',
        transfer: 'Transfer',
        card: 'Card Joycard',
        summary: 'Order Summary',
        orderNumber: 'Request number',
        nf: 'Invoice',
        db: 'Debit note',
        notContainMsg: 'DOES NOT CONTAIN',
        product: 'Product',
        productName: 'Shopping Withdrawal Card',
        rz: 'Corporate Name',
        status: 'Order status',
        orderBy: 'Performed by:',
        paymentDate: 'Pay day',
        orderDate: 'Request Date',
        bearers: 'Carriers',
        cnpj: 'CNPJ',
        notification: 'Notification',
        planAwarded: 'Award winning spreadsheet',
        ticket: 'Pay order',
        addItem: '+ Add item',
        discardItem: 'Discard item',
        goBack: 'Come back',
        cancelOrder: 'Cancel order',
        finalizeOrder: 'Finalize order',
        paidOut: 'Paid out',
        modalTitle: 'Order',
        modalCompleteMsg: 'Do you really want to complete the order?',
        completeCancelBtn: 'Cancel',
        completeConfirmBtn: 'Yes',
        modalRemoveItemMsg:
          'Do you really want to remove the selected items from the order?',
        modalCancelMsg:
          ' Do you really want to cancel the order? This action cannot be undone.',
        paymentMsg:
          'Do you really want to report payment for this order? This action cannot be undone.',
        loadPaymentMsg: 'Informing payment of order...',
        addItensToOrderMsg: 'Adding items to order...',
        newOrderLabel: 'Insert order',
        exit: 'Exit',
        btnContinue: 'Continue',
        btnSalve: 'Save order',
        table: {
          name: 'Name',
          docs: 'CPF/CNPJ',
          proxy: 'Proxy',
          bank: 'Banco',
          ag: 'Agency',
          account: 'Account',
          description: 'Descriptive',
          qtd: 'The amount',
          value: 'Value',
          dateOf: 'Date of',
          processing: 'processing',
          conciliation: 'conciliation',
          statusLabel: 'status',
          status: {
            validationError: 'Data validation error',
            bullCharge: 'Previous system bulk load',
            statusChange: 'Status change',
            comments: 'Comment',
            cnab: 'CNAB return code',
            accessoToken: 'Acesso return code',
            requestSent: 'Request sent',
            errorBlock: 'Card blocking/unblocking error',
          },
          descriptionItens: 'Description of order items',
          total: 'Total',
        },
      },
      newOrder: {
        changeProduct: 'Choose the product',
        card: 'Card',
        cardType: 'Prepaid',
        transfer: 'Transfer',
        createMsg: 'Creating order...',
        uploadFileMsg: 'Upload the file.',
        cnpjMsg: 'Enter CNPJ to continue',
        companyMsg: 'Choose company to continue',
        typeErrorMsg: 'Enter order type to continue',
        addItensMsg: 'Adding items...',
        editOrderMsg: 'Editing order...',
        upLoadFileMsg: 'Upload the file.',
        reviewPlanMsg:
          'Some cards were not added to the order. Review the worksheet.',
        contract: {
          choiceCompany: 'Choose the company to bill',
          companyName: 'Company Name',
          cnpj: 'Company CNPJ',
          campaignName: 'Campaign name',
          noResult: 'Does not have campaign',
          searchCompanyMsg: 'Looking for companies...',
        },
        typeProduct: {
          label: 'Choose product type',
          withdraw: 'Saque/Compra',
          gift: 'Withdrawal/Purchase',
        },
        tranferPlan: {
          label: 'Enter order data',
          search: 'Looking for spreadsheet template....',
          planAwarded: 'Award winning worksheet',
          nfLabel: ' Invoice',
          ticket: 'Ticket',
          expirationDate: 'Due date',
          orderName: 'Order Name',
          comments: 'Invoice Comments',
          plan: {
            name: 'Name',
            cpf: 'CPF',
            bank: 'Nombre',
            ag: 'Agency',
            account: 'Account',
            description: 'Descriptive',
            value: 'Value',
            msg: 'Upload the Award Spreadsheet',
          },
        },
        transferSummary: {
          label: 'Summary and Confirmation',
          date: 'Date: ',
          product: 'Product',
          transfer: 'Transfer',
          company: 'Company',
          contractor: 'Contractor',
          coverage: 'Coverage',
          international: 'International',
          value: 'Value',
        },
        specOrder: {
          label: 'Choose order type',
          complete: 'Card Load',
          inventory: 'Stock card',
          noCharge: 'Card with bond and no charge',
          credit: 'Credit',
        },
        created: {
          nfMsg: 'Generating Invoice...',
          msg: 'Your order has been registered successfully!',
          btnSalve: 'Save to continue later',
          btnNf: 'Issue invoice',
        },
        cardExists: {
          label: 'Choose one of the registered prepaid cards',
          labelComplete: 'Complete the order',
          selectMsg: 'SELECT PREPAID CARD',
        },
        uploadPlan: {
          searchMsg: 'Searching spreadsheet template....',
          label: 'Enter order data',
          planAwarded: 'Award winning worksheet',
          nfLabel: ' Invoice',
          ticket: 'Ticket',
          expirationDate: 'Due date',
          orderName: 'Order Name',
          comments: 'Comments of the Fiscal Note',
          plan: {
            name: 'Name',
            cpf: 'CPF',
            proxy: 'Proxy',
            qtd: 'Quantity',
            value: 'Value',
            msg: 'Upload the Award Spreadsheet',
          },
        },
        salesCredit: {
          label: 'Buy credit',
          nfLabel: ' Invoice',
          ticket: 'Ticket',
          expirationDate: 'Data de vencimento',
          orderName: 'Order Name',
          comments: 'Invoice Comments',
          valueLabel: 'Credit amount',
        },
        cardVinc: {
          label: 'Buy cards and link',
          cpfError: 'Enter a valid CPF',
          nfLabel: ' Invoice',
          ticket: 'Ticket',
          expirationDate: 'Due date',
          orderName: 'Order Name',
          comments: 'Invoice Comments',
          name: 'Name',
          cpf: 'CPF',
          btnAdd: 'Add',
          action: 'action',
          tableMsg: 'No rewards added',
        },
        loadingNf: {
          label: 'Requesting invoice...',
        },
        qtdCards: {
          label: 'Buy card',
          nfLabel: ' Invoice',
          ticket: 'Ticket',
          expirationDate: 'Due date',
          orderName: 'Order Name',
          comments: 'Invoice Comments',
          qtdCards: 'Number of cards',
        },
        reviewCards: {
          label: 'Summary and Confirmation',
          date: 'Request Date',
          product: 'Product',
          productType: 'Prepaid card',
          type: 'Product type',
          purchases: 'Shopping',
          gift: 'Gifts',
          withdraw: 'Withdrawal and Purchases',
          brand: 'Flag',
          issuer: 'Issuer',
          technology: 'Technology',
          chip: 'Chip',
          contractor: 'Contractor',
          cnpjContractor: 'CNPJ Contractor',
          campaign: 'Campaign',
          valueCharge: 'Total cargo value',
          valueCard: 'Card value',
          valueSend: 'Shipping value',
          table: {
            name: 'Name',
            cpf: 'CPF',
            proxy: 'Proxy',
            description: 'Descriptive',
            qtd: 'The amount',
            value: 'Value',
            msg: 'Upload the Award Spreadsheet',
          },
        },
        nfCreated: {
          label: 'Request made successfully',
          label2: 'Awaiting payment of Invoice for processing',
          btnOk: 'Ok',
        },
        reviewStoke: {
          label: 'Summary and Confirmation',
          name: 'Name',
          function: 'Function',
          card: 'Card',
          international: 'International',
          coverage: 'Coverage',
          type: 'Product type',
          purchases: 'Shopping',
          gift: 'Gifts',
          withdraw: 'Withdrawal and Purchases',
          brand: 'Flag',
          issuer: 'Issuer',
          technology: 'Technology',
          chip: 'Chip',
          contractor: 'Contractor',
          cnpjContractor: 'CNPJ Contractor',
          campaign: 'Campaign',
          valueCharge: 'Total cargo value',
          valueCard: 'Card value',
          valueSend: 'Shipping value',
          qtd: 'Number of cards',
          table: {
            description: 'Descriptive',
            qtd: 'The amount',
            value: 'Value Unitário',
            msg: 'Upload the Award Spreadsheet',
          },
        },
        otherOrder: {
          label: 'Would you like to place another order?',
          btnNot: 'No thank you',
          btnYes: 'Yes',
        },
      },
      baseCards: {
        label: 'Base Cards',
        action: {
          activate: 'Activate',
          blocking: 'Block',
          cancel: 'Cancel',
          duplicateCard: 'Issue duplicate',
          link: 'Link',
          bullkLink: 'Batch Link',
          base: 'Base of cards',
        },
        table: {
          brand: 'Flag',
          name: 'Name',
          cpf: 'CPF',
          proxy: 'Proxy',
          note: 'Note',
          status: 'Status',
          actionCancelMsg: 'Action canceled',
          blockCardMsg: 'Blocking card...',
          blockCardSeccessMsg: 'Lock Sent Successfully',
          ativCardMsg: 'Activating card...',
          ativCardSuccessMsg: 'Activation successfully sent',
          cancelCardMsg: 'Canceling card...',
          cancelCardSuccessMsg: 'Cancellation sent successfully',
          textCancel: 'You confirm the "CANCELLATION" of the Proxy card',
          textCancel1: 'belongs to ',
          textCancel2: 'CPF',
          textAtiv: 'You confirm the "ACTIVATION" of the Proxy card',
          textBlock: 'Do you confirm the "BLOCKING" of the Proxy card',
          cancelTitle: 'Cancel card',
          cancelBtn: 'Cancel',
          yesBtn: 'Yes',
          ativTitle: 'Activate card',
          blockTitle: 'Block card',
          okBtn: 'Ok',
          product: 'Product'
        },
        details: {
          invoiceFrom: 'Invoiced',
          label: 'Card details',
          search: 'Looking for movement...',
          createPlanMsg: 'Generating worksheet...',
          createPdfMsg: 'Generating PDF...',
          campaign: 'Campaign',
          brand: 'Flag',
          product: 'Product',
          expirationDate: 'Validity',
          proxy: 'Proxy',
          cardNumber: 'Card number',
          cpf: 'CPF',
          bearer: 'Carrier',
          status: 'Status',
          balance: 'Balance',
          delivery: 'Delivery',
          deliveryStatus: 'Status',
          deliveryMsg: 'Message',
          deliveryDate: 'Processing Date',
          notDeliveryMsg: 'No flash record available',
          chargeMoviment: 'Cargo handling',
          chargeMovimentNote: 'Note',
          chargeMovimentOrder: 'Order',
          chargeMovimentDate: 'Processing Date',
          chargeMovimentValue: 'Value',
          chargeMovimentStatus: 'Status',
          notchargeMoviment: 'No record available',
        },
        filters: {
          status: 'Status',
          all: 'All',
          product: 'Product',
          withdraw: 'Purchase Withdrawal',
          purchases: 'Purchase',
          type: 'Type',
          brand: 'Flag',
          invoiceFrom: 'Invoiced',
          campaign: 'Campaign',
          cpf: 'CPF',
          name: 'Name',
          note: 'Note',
          cardNumber: 'Card number',
          proxy: 'Proxy',
          btnSearch: 'Search',
          expirationDate: 'Validity',
          searchMsg: 'Looking for data...',
          vincMsg: 'Sending link request...',
          vincSuccessMsg: 'Batch link request sent successfully',
          searchTypeError: 'Enter the type of search',
          minCardError: 'Selecione apenas 1 cartão para vincular.',
          maxCardError: 'Select only 1 card to link.',
          errorStatusOp:
            'Only cards in stock or with a linking error can be linked',
          minSecond: 'Select at least 1 card to request a duplicate.',
          maxSecond: 'Select only 1 card to request a duplicate.',
          sendRequestMsg: 'Sending link request...',
          vincUnitSuccessMsg: 'Binding request sent successfully.',
        },
      },
      releaseMoney: {
        label: 'Charge release',
        releaseLabel: 'Release',
        cancelItensLabel: 'Cancel Items',
        uploadLabel: 'Upload return',
        searchMsg: 'Seeking data...',
        filters: {
          type: 'Type',
          product: 'Product',
          all: 'All',
          card: 'Card',
          transfer: 'Transfer',
          cpf: 'CPF',
          invoiceFrom: 'Invoiced',
          bearers: 'Company',
          nf: 'Invoice number',
          nd: 'Debit note number',
          paymentDate: 'Payment Date',
          searchBtn: 'Search',
          orderNumber: 'Order number',
          value: 'Subtotal',
          errorSearch: 'Enter the type of search',
          uploadErrorMsg: 'Upload the bank return file to be sent',
          sendSuccessMsg: 'File uploaded successfully',
          bankErrorMsg: 'Inform which bank this file is being sent from.',
        },
        dialog: {
          title: 'Return files',
          sendMsg: 'Sending file...',
          selectTitle: 'Bank...',
          btnCancel: 'Cancel',
          btnSalve: 'To save',
          cancelMsg: 'Canceling...',
          cancelTitle: 'Cancel items',
          cancelBodyMsg:
            'Do you really want to cancel the selected items? This action cannot be undone!',
          btnCancelAction: 'Cancel action',
          btnOk: 'Ok',
        },
        table: {
          nf: 'Invoice',
          nd: 'Debit notes',
          product: 'Product',
          payment: 'Payment',
          invoiceFrom: 'Invoiced',
          bearers: 'Company',
          cpf: 'CPF',
          value: 'Value',
        },
        releaseTable: {
          sendMsg: 'Sending file...',
          sendSuccessMsg: 'File uploaded successfully',
          createFile: 'Generating file...',
          nf: 'Invoice',
          nd: 'Debit notes',
          product: 'Product',
          payment: 'Payment',
          invoiceFrom: 'Invoiced',
          bearers: 'Company',
          cpf: 'CPF/CNPJ',
          value: 'Value',
          dialog: {
            providers: 'Providers',
            countCharger: 'Number of Loads',
            downloadMsg: 'Download Shipping',
            value: 'Value',
            bank: 'Bank',
            btnOk: 'Ok',
          },
        },
      },
      moviment: {
        label: 'Movement',
        searchMsg: 'Looking for data...',
        filters: {
          productLabel: 'Filter by',
          statusLabel: 'Filter by status',
          type: 'Type',
          searchBtn: 'Search',
          transfer: 'Transfer',
          card: 'Card',
          proxy: 'Proxy',
          name: 'Name',
          cpf: 'CPF',
          cardNumber: 'Card',
          orderNumber: 'Order number',
          notes: 'NF/ND',
          maxErrorMsg:
            'Survey too large to generate spreadsheet. Apply some filters.',
          errorTypeSearch: 'Enter the type of search',
        },
        table: {
          documents: 'CPF/CNPJ',
          name: 'Name',
          proxy: 'Proxy',
          notes: 'NF/ND',
          order: 'Order',
          processing: 'Processing',
          conciliation: 'Conciliation',
          qtd: 'The amount',
          value: 'Value',
          status: 'Status',
        },
        dialog: {
          title: 'Movement',
          code: 'Code',
          itemCode: 'Item code',
          eventCode: 'Event code',
          user: 'User',
          createdAt: 'Creation date',
          status: 'Status',
          btnOk: 'ok',
        },
      },
      contract: {
        label: 'Contracts and fees',
        searchMsg: 'Looking for contracts...',
        newContractBtn: 'New contract',
        filters: {
          group: 'Group',
          cnpj: 'CNPJ',
          rz: 'Corporate Name',
          all: 'All',
          disabled: 'Disabled',
          active: 'Active',
          transfer: 'Transfer',
          card: 'Card',
          btnSearch: 'Search',
          type: 'Type',
          product: 'Product',
          status: 'Status',
          typeSearchMsg: 'Enter the type of search',
        },
        table: {
          group: 'Group',
          rz: 'Corporate Name',
          cnpj: 'CNPJ',
          product: 'Product',
          campaign: 'Campaign',
          status: 'Status',
          notRegistre: 'No records available',
          edit: 'Edit contract',
          contract: 'Contract',
          download: 'Contract download',
        },
        dialog: {
          title: 'Contract',
          bodyMsg1: 'Really want',
          active: 'activate',
          disabled: 'deactivate',
          bodyMsg2: 'the company contract',
          bodyMsg3: 'Cancel all cards linked to this agreement',
          btnCancel: 'Cancel',
          btnYes: 'Yes',
        },
      },
      newContract: {
        label: 'New contract',
        company: {
          label: 'Business Data',
          cnpj: 'CNPJ',
          rz: 'Corporate Name',
          tradingName: 'Fantasy name',
          email: 'email',
          phone: 'Telephone',
          celPhone: 'Cell',
          website: 'Website URL',
          comments: 'Comments',
          campaign: 'Campaign',
          required: 'Required',
          uploadSuccess: 'Contract successfully saved',
        },
        contact: {
          label: 'Responsible contact',
          removeBtn: 'Remove',
          name: 'Full name',
          cpf: 'CPF',
          role: 'Office',
          department: 'Workspace',
          phone: 'Telephone',
          celPhone: 'Cell',
          email: 'email',
          addContactBtn: '+ Contact',
          nextBtn: 'Next',
        },
        address: {
          label: 'Endereço de faturamento',
          postalCode: 'Zip code',
          chkLabel: 'Centralized delivery',
          address: 'Address',
          district: 'Neighborhood',
          city: 'City',
          state: 'State',
          number: 'Number',
          complement: 'Complement',
          deliveryAgent: 'Delivery agent',
          deliveryService: 'Delivery service',
          receiver: 'Receiver',
          goBackBtn: 'Come back',
          nextBtn: 'Next',
        },
        product: {
          label: 'Product Details',
          label1: 'Company Operating Tariffs',
          label2: 'Carrier Operating Tariffs',
          category: 'Product Category',
          card: 'Card',
          transfer: 'Transfer',
          issuer: 'Issuer',
          brand: 'Flag',
          has: 'It has',
          chip: 'Chip',
          channel: 'Sales channel',
          international: 'International',
          national: 'National',
          gift: 'Gift voucher',
          fees: 'Fees in',
          percent: 'Percentage',
          fixed: 'Fixed',
          tax: 'Adm Fee',
          award: 'Estimated Annual Award',
          cardPrice: 'Card Price',
          readjustment: 'Readjustment Period (months)',
          cTransferFee: 'Card Balance Transfer',
          cMinCharge: 'Minimum Load Value',
          cMinChargeFee: 'Load less than Minimum',
          cEmergencyCharge: 'Emergency Charge',
          cHandling: 'Handling',
          cChargebackFee: 'Chargeback Fee',
          cRejectTransferFee: 'Transaction Rejected',
          oMonthlyPayment: 'Monthly payment',
          oUnlock: 'Password unlock',
          oDuplicate: 'Issuance of duplicate',
          oChargeback: 'Reversal',
          oWithdraw: 'Withdraw',
          oMarkupFee: 'Markup Rate',
        },
        revenue: {
          label: 'Invoicing',
          calculation: 'Calculation',
          normal: 'Normal',
          inverted: 'Inverted',
          paymentType: 'Form of payment',
          ticket: 'Ticket',
          transfer: 'Transfer',
          note: 'Allow issuance of Invoice',
          currencyType: 'Currency Type',
        },
        summary: {
          label: 'Contract Summary',
          rz: 'Corporate Name',
          product: 'Product',
          transfer: 'Transfer',
          cardValue: 'Card Value',
          tradingName: 'Fantasy name',
          tax: 'ADM Fee',
          cnpj: 'CNPJ',
          campaign: 'Campaign name',
          salesAgents: 'Commercial Agents',
          goBackBtn: 'Come back',
          btnSalve: 'Save contract',
        },
        success: {
          btnConclude: 'Conclude',
          successMsg: 'Contract entered successfully',
        },
      },
      editContract: {
        label: 'Edit Contract',
        successMsg: 'Contact edited successfully',
        uploadContract: 'Upload Contract',
      },
      status: {
        Active: 'Active',
        Disabled: 'Disabled',
        AutoProcessing: 'Automatic processing',
        AwaitingBlock: 'Waiting for lock',
        AwaitingCancelation: 'Awaiting Cancellation',
        AwaitingCanceledInvoice: 'Awaiting Cancellation (RPS created)',
        AwaitingCard: 'Awaiting Card',
        AwaitingCardRelease: 'Awaiting Card Release',
        AwaitingConciliation: 'Awaiting Conciliation',
        AwaitingInvoice: 'Waiting for the Invoice',
        AwaitingIssuerLink: 'Pending Binding',
        AwaitingIssuerLinkResponse: 'Awaiting Issuer Response',
        AwaitingPayment: 'Awaiting payment',
        AwaitingProcessing: 'Awaiting Processing',
        AwaitingRelease: 'Awaiting Release',
        AwaitingSettlement: 'Awaiting Settlement',
        AwaitingShipping: 'Waiting to be sent',
        AwaitingUnblock: 'Awaiting Unlock',
        Blocked: 'Blocked',
        Canceled: 'Canceled',
        Card: 'Card',
        Check: 'Check',
        Created: 'Created',
        Error: 'Erro',
        Fraud: 'Fraud',
        InStock: 'In stock',
        InvoiceReceived: 'Invoice Received',
        Paid: 'Paid out',
        PayableCommercialAgent: 'Receivable',
        Payable: 'To pay',
        Processed: 'Processed',
        ProcessedWithErrors: 'Processed with Errors',
        Processing: 'Processing',
        Queued: 'In line',
        Received: 'Received',
        Rejected: 'Rejected',
        Settled: 'Settled',
        Transfer: 'Transfer',
        Voucher: 'Voucher',
        Pause: 'Paused',
        growing: 'Growing',
        descending: 'Descending',
        all: 'All',
        search: 'Search',
        VinculationError: 'Binding error',
      },
      validation: {
        cpf: 'Enter a valid 11-digit CPF',
        email: 'Field must be a valid email',
        cnpj: 'Enter a valid 14-digit CNPJ',
      },
      tutorials: {
        label: 'Tutorials',
        btnNew: 'Load Tutorial',
        new: {
          label: 'Tutorials',
          btnNew: 'New Content',
          table: {
            title: 'Title',
            description: 'Description',
            midia: 'Media',
            status: 'Status',
          },
          new: {
            label: 'New Content',
            title: 'Title',
            description: 'Description',
            midia: 'Video url',
            btnCancel: 'Back',
            btnSalve: 'Save',
            msgSalve: 'Content successfully saved',
            msgEdit: 'Tutorial successfully edited',
            btnEdit: 'Edit',
            btnDelete: 'Delete',
            btnVisible: 'Published',
            btnHide: 'Hidden',
          },
        },
      },
    },
  },
  pt: {
    translation: {
      Welcome: 'Bem-vindo ao',
      Login: 'Faça login para continuar',
      User: 'Usuário',
      Password: 'Senha',
      InvalidPassword: 'Senha inválida',
      Forgot: 'Esqueci a minha senha',
      Continue: 'Continuar',
      Label1: 'Receber o código por:',
      Label2: 'Verificação em duas etapas',
      CodSendBy: 'Código enviado por',
      Label3: 'Digite o código enviado',
      Code: 'Código',
      Label4: 'Entrar',
      Loading: 'Verificando usuário e senha...',
      Logging: 'Logando no sistema...',
      MessageSend: 'Enviando código por SMS...',
      MessageSendEmail: 'Enviando link por email...',
      MessageSendEmailSuccess:
        'Faça o login através do link enviado em seu email.',
      MessageSendEmailError:
        'Você não está cadastrado nessa modalidade de autenticação.',
      initialMenu: 'Menu Inicial',
      administration: 'Administração',
      conf: 'Configurações de conta',
      confUser: 'Gerenciar Usuários',
      notification: 'Notificações',
      tutorial: 'Tutoriais',
      general: 'Geral',
      DashBoard: {
        orders: 'Pedidos',
        dashBoard: 'Painel',
        contract: 'Contratos e taxas',
        products: 'Produtos',
        baseCards: 'Base de cartões',
        movement: 'Movimentação',
        release: 'Liberação de cargas',
      },
      ForgotPassword: {
        recover: 'Recuperar senha',
        label1: 'Digite o e-mail cadastrado',
        send: 'Enviar',
      },
      Product: {
        product: 'Produtos',
        newProduct: 'Novo Produto',
        initialMenu: 'Menu Inicial',
        table: {
          product: 'Produto',
          category: 'Categoria',
          type: 'Tipo',
          provider: 'Fornecedor',
          created: 'Criação',
          origin: 'Origem',
          status: 'Status',
          edit: 'Editar produto',
          duplicate: 'Duplicar produto',
          suspend: 'Suspender produto',
          cancel: 'Cancelar produto',
        },
        category: {
          label1: 'Escolha a categoria do seu produto',
          card: 'Cartão Pré-Pago',
          account: 'Conta Digital',
          transfer: 'Transferência',
          qr: 'Voucher QR Code',
          cripto: 'Voucher Cripto',
          plus: '+ Criar novo categoria',
        },
        accessAll: 'Acessar todos os produtos',
        acessory: 'Acessório',
        changeProduct: 'Escolha o produto',
        newInCategory: 'Criar novo produto nesta categoria',
        creditCard: {
          new: 'Novo Cartão',
          label1: 'Cartão Pré-Pago',
          table: {
            product: 'Produto',
            type: 'Tipo',
            flag: 'Bandeira',
            provider: 'Fornecedor',
            created: 'Criação',
            status: 'Status',
            edit: 'Editar produto',
            duplicate: 'Duplicar produto',
            suspend: 'Suspender produto',
            cancel: 'Cancelar produto',
            planProducts: 'Planilha de produtos',
            allCategory: 'Acessar todas as categorias',
            search: 'Busca',
            selectStatus: {
              label: 'Status',
              status: {
                opt1: 'Criado',
                opt2: 'Aguardando pagamento',
                opt3: 'Processado',
                opt4: 'Processado com erro',
              },
            },
            selectViewer: {
              label: 'Visualização',
              status: {
                opt1: 'Todos',
              },
            },
            selectOrder: {
              label: 'Ordenar',
              status: {
                opt1: 'Crescente',
                opt2: 'Decrescente',
              },
            },
          },
          checkout: {
            label1: 'Novo Cartão Pré-Pago',
            feature: 'Características do Cartão',
            flag: 'Bandeira',
            selectFlag: 'Selecione a bandeira',
            name: 'Nome do produto',
            nameSugestion: 'Cartão Pré-Pago',
            issuer: 'Emissor',
            selectIssuer: 'Selecione o emissor',
            function: 'Função transacional',
            opt1Function: 'Vale Presente',
            opt2Function: 'Saque e Compras',
            technology: 'Tecnologia',
            chip: 'Chip',
            nfc: 'NFC',
            tarja: 'Tarja',
            coverage: 'Cobertura',
            national: 'Nacional',
            international: 'Internacional',
            exit: 'Sair',
            company: {
              label1: 'Tarifas Operacionais Empresa',
              tranferBalance: 'Transferência saldo',
              loadLess: 'Carga inferior ao mínimo',
              emergenciCargo: 'Carga emergencial',
              chargebackFee: 'Taxa de estorno',
              minimumLoad: 'Valor carga mínima',
              operatingFee: 'Manuseio por cartão',
              personalization: 'Personalização',
            },
            client: {
              label1: 'Tarifas Operacionais Portador',
              monthlyPayment: 'Mensalidade',
              passwordUnlock: 'Desbloqueio de senha',
              withdraw: 'Saque ATM',
              transfer: 'Transferência CC',
              duplicateCard: '2. Via cartão',
              chargebackFee: 'Tarifa de estorno',
              chargebackTax: 'Taxa de estorno',
              mktTax: "Taxa Mark-up Int'I",
              iof: 'IOF/IVA',
            },
            limits: {
              label1: 'Limites',
              transfer1: 'Limite de cargas e',
              transfer2: 'transferências entre cartões',
              purchases: 'Limite de compras',
              withdraw: 'Limite de saque',
              paymentCount: 'Limite de pagamento de contas',
              celPhone: 'Recarga de celular',
              balanceInCard: 'Limite de saldo no cartão',
              prizeInCard: 'Disponibilidade do prêmio no cartão',
            },
            review: {
              label1: 'Revisão e confirmação',
              type: 'Tipo do Produto: Cartão',
              name: 'Nome: ',
              nameProd: 'Cartão Pré-Pago',
              operation: 'Operação',
              creditOperation: 'Crédito',
              function: 'Funções',
            },
          },
        },
      },
      Order: {
        order: 'Pedidos',
        orderHistoric: 'Histórico de Pedidos',
        newOrder: 'Novo Pedido',
        initialMenu: 'Menu Inicial',
        chargeback: 'Estorno',
        menu: {
          orders: 'Pedidos',
          order: 'Pedido',
          history: 'Histórico de pedidos',
          contract: 'Contratos e taxas',
          dashBoard: 'Dashboard',
        },
        filters: {
          status: {
            awaitingSettlement: 'Aguardando liquidação',
            awaitingPayment: 'Aguardando pagamento',
            canceled: 'Cancelado',
            created: 'Criado',
            error: 'Erro',
            errors: 'Erros',
            settled: 'Liquidado',
          },
          product: {
            card: 'Cartão',
            transfer: 'Transferência',
          },
          search: {
            nf: 'Nf',
            nd: 'ND',
            payDay: 'Data Pagamento',
            invoiceFrom: 'Faturado',
            order: 'Pedido',
            orderName: 'Nome do Pedido',
            value: 'Valor',
          },
          btn: 'Buscar',
          type: 'Tipo',
          statusLabel: 'Status',
          productLabel: 'Produto',
          searchErrorMessage: 'Informe o tipo de busca',
        },
        table: {
          numberNf: 'Nº da NF/ND',
          payment: 'Pagamento',
          rz: 'Razão Social',
          orderName: 'Nome do Pedido',
          order: 'Pedido',
          product: 'Produto',
          value: 'Valor',
          status: 'Status',
          noResult: 'Nenhum registro disponível',
          duplicateOrder: 'Duplicar pedido',
          duplicateMsg: 'Deseja realmente duplicar o pedido?',
          duplicateTitle: 'Pedido',
          duplicateCancelBtn: 'Cancelar',
          duplicateConfirmBtn: 'Sim',
          orderDetails: 'Visualizar pedido',
          orderCancel: 'Cancelar pedido',
          orderCancelTitle: 'Pedido',
          orderCancelMsg:
            'Deseja realmente cancelar o pedido? Esta ação não poderá ser desfeita!',
          orderCancelBtn: 'Cancelar',
          orderCancelConfirmBtn: 'Sim',
          created: 'Criação'
        },
      },
      OrderDetails: {
        title: 'Pedido',
        searchMsg: 'Buscando detalhes do pedido...',
        modelPlanMsg: 'Buscando modelo de planilha....',
        creatingMsg: 'Criando',
        completeErrorMsg: 'Erro ao completar pedido.',
        paymentErrorMsg: 'Erro ao informar pagamento do pedido.',
        cancelingMsg: 'Cancelando pedido...',
        productLabel: 'Produto',
        cancelingErrorMsg:
          'Você deve selecionar no mínimo um item para ser excluído.',
        savingOrderMsg: 'Salvando pedido...',
        reviewPlanMsg:
          'Alguns cartões não foram adicionados ao pedido. Revise a planilha.',
        transfer: 'Transferência',
        card: 'Cartão Joycard',
        summary: 'Resumo do Pedido',
        orderNumber: 'Nº do pedido',
        nf: 'Nota Fiscal',
        db: 'Nota(s) débito',
        notContainMsg: 'NÃO CONTEM',
        product: 'Produto',
        productName: 'Cartão Saque Compras',
        rz: 'Razão Social',
        status: 'Status do pedido',
        orderBy: 'Realizado por:',
        paymentDate: 'Data do pagamento',
        orderDate: 'Data do pedido',
        bearers: 'Portadores',
        cnpj: 'CNPJ',
        notification: 'Notificação',
        planAwarded: 'Planilha de premiados',
        ticket: 'Boleto para pagamento',
        addItem: '+ Adicionar item',
        discardItem: 'Descartar item',
        goBack: 'Voltar',
        cancelOrder: 'Cancelar pedido',
        finalizeOrder: 'Finalizar pedido',
        paidOut: 'Pagar',
        modalTitle: 'Pedido',
        modalCompleteMsg: 'Deseja realmente completar o pedido?',
        completeCancelBtn: 'Cancelar',
        completeConfirmBtn: 'Sim',
        modalRemoveItemMsg:
          'Deseja realmente remover os itens selecionados do pedido?',
        modalCancelMsg:
          ' Deseja realmente cancelar o pedido? Esta ação não poderá ser desfeita.',
        paymentMsg:
          'Deseja realmente informar o pagamento desse pedido? Esta ação não poderá ser desfeita.',
        loadPaymentMsg: 'Informando pagamento do pedido...',
        addItensToOrderMsg: 'Adicionando itens ao pedido...',
        newOrderLabel: 'Inserir pedido',
        exit: 'Sair',
        btnContinue: 'Continuar',
        btnSalve: 'Salvar pedido',
        table: {
          name: 'Nome',
          docs: 'CPF/CNPJ',
          proxy: 'Proxy',
          bank: 'Banco',
          ag: 'Agência',
          account: 'Conta',
          description: 'Descritivo',
          qtd: 'Qtd',
          value: 'Valor',
          dateOf: 'Data de',
          processing: 'processamento',
          conciliation: 'conciliação',
          statusLabel: 'status',
          status: {
            validationError: 'Erro de validação de dados',
            bullCharge: 'Carga em massa de sistema anterior',
            statusChange: 'Mudança de status',
            comments: 'Comentário',
            cnab: 'Código de retorno CNAB',
            accessoToken: 'Código de retorno Acesso',
            requestSent: 'Solicitação enviada',
            errorBlock: 'Erro no bloqueio/desbloqueio do cartão',
          },
          descriptionItens: 'Descrição dos itens do pedido',
          total: 'Total',
        },
      },
      newOrder: {
        insert: 'Inserir pedido',
        changeProduct: 'Escolha o produto',
        card: 'Cartão',
        cardType: 'Pré-Pago',
        transfer: 'Transferência',
        createMsg: 'Criando pedido...',
        uploadFileMsg: 'Faça o upload do arquivo.',
        cnpjMsg: 'Informe o CNPJ para continuar',
        companyMsg: 'Escolha a empresa para continuar',
        typeErrorMsg: 'Informe o tipo de pedido para continuar',
        addItensMsg: 'Adicionando items...',
        editOrderMsg: 'Editando pedido...',
        upLoadFileMsg: 'Faça o upload do arquivo.',
        reviewPlanMsg:
          'Alguns cartões não foram adicionados ao pedido. Revise a planilha.',
        contract: {
          choiceCompany: 'Escolha a empresa a faturar',
          companyName: 'Nome da empresa',
          cnpj: 'CNPJ da empresa',
          campaignName: 'Nome da campanha',
          noResult: 'Não possui campanha',
          searchCompanyMsg: 'Buscando empresas...',
        },
        typeProduct: {
          label: 'Escolha o tipo do produto',
          withdraw: 'Saque/Compra',
          gift: 'Compra/ Presente',
        },
        tranferPlan: {
          label: 'Insira os dados do pedido',
          search: 'Buscando modelo de planilha....',
          planAwarded: 'Planilha de premiado',
          nfLabel: ' Nota Fiscal',
          ticket: 'Boleto',
          expirationDate: 'Data de vencimento',
          orderName: 'Nome do pedido',
          comments: 'Comentários da Nota Fiscal',
          plan: {
            name: 'Nome',
            cpf: 'CPF',
            bank: 'Banco',
            ag: 'Agência',
            account: 'Conta',
            description: 'Descritivo',
            value: 'Valor',
            msg: 'Faça o upload da Planilha de Premiado',
          },
        },
        transferSummary: {
          label: 'Resumo e Confirmação',
          date: 'Data: ',
          product: 'Produto',
          transfer: 'Transferência',
          company: 'Empresa',
          contractor: 'Contratante',
          coverage: 'Cobertura',
          international: 'Internacional',
          value: 'Valor',
        },
        specOrder: {
          label: 'Escolha o tipo de pedido',
          complete: 'Carga Cartão',
          inventory: 'Cartão para estoque',
          noCharge: 'Cartão com vínculo e sem carga',
          credit: 'Crédito',
        },
        created: {
          nfMsg: 'Gerando NF...',
          msg: 'Seu pedido foi registrado com sucesso!',
          btnSalve: 'Salvar para continuar depois',
          btnNf: 'Emitir nota fiscal',
        },
        cardExists: {
          label: 'Escolha um dos cartões pré-pagos registrados',
          labelComplete: 'Realizar o pedido completo',
          selectMsg: 'SELECIONE O CARTÃO PRÉ-PAGO',
        },
        uploadPlan: {
          searchMsg: 'Buscando modelo de planilha....',
          label: 'Insira os dados do pedido',
          planAwarded: 'Planilha de premiado',
          nfLabel: ' Nota Fiscal',
          ticket: 'Boleto',
          expirationDate: 'Data de vencimento',
          orderName: 'Nome do pedido',
          comments: 'Comentários da Nota Fiscal',
          plan: {
            name: 'Nome',
            cpf: 'CPF',
            proxy: 'Proxy',
            qtd: 'Qtd',
            value: 'Valor',
            msg: 'Faça o upload da Planilha de Premiado',
          },
        },
        salesCredit: {
          label: 'Comprar crédito',
          nfLabel: ' Nota Fiscal',
          ticket: 'Boleto',
          expirationDate: 'Data de vencimento',
          orderName: 'Nome do pedido',
          comments: 'Comentários da Nota Fiscal',
          valueLabel: 'Valor do crédito',
        },
        cardVinc: {
          label: 'Comprar cartões e vincular',
          cpfError: 'Informe um CPF válido',
          nfLabel: ' Nota Fiscal',
          ticket: 'Boleto',
          expirationDate: 'Data de vencimento',
          orderName: 'Nome do pedido',
          comments: 'Comentários da Nota Fiscal',
          name: 'Nome',
          cpf: 'CPF',
          btnAdd: 'Adicionar',
          action: 'ação',
          tableMsg: 'Nenhum prêmiado adicionado',
        },
        loadingNf: {
          label: 'Solicitando nota fiscal...',
        },
        qtdCards: {
          label: 'Comprar cartão',
          nfLabel: ' Nota Fiscal',
          ticket: 'Boleto',
          expirationDate: 'Data de vencimento',
          orderName: 'Nome do pedido',
          comments: 'Comentários da Nota Fiscal',
          qtdCards: 'Quantidade de cartões',
        },
        reviewCards: {
          label: 'Resumo e Confirmação',
          date: 'Data do pedido',
          product: 'Produto',
          productType: 'Cartão Pré-Pago',
          type: 'Tipo do produto',
          purchases: 'Compras',
          gift: 'Presentes',
          withdraw: 'Saque e Compras',
          brand: 'Bandeira',
          issuer: 'Emissor',
          technology: 'Tecnologia',
          chip: 'Chip',
          contractor: 'Contratante',
          cnpjContractor: 'CNPJ Contratante',
          campaign: 'Campanha',
          valueCharge: 'Total de carga',
          valueCard: 'Valor Unitário de Cartão',
          valueSend: 'Envio',
          table: {
            name: 'Nome',
            cpf: 'CPF',
            proxy: 'Proxy',
            description: 'Descritivo',
            qtd: 'Qtd',
            value: 'Valor',
            msg: 'Faça o upload da Planilha de Premiado',
          },
        },
        nfCreated: {
          label: 'Pedido efetuado com sucesso',
          label2: 'Aguardando pagamento da Nota fiscal para processamento',
          btnOk: 'Ok',
        },
        reviewStoke: {
          label: 'Resumo e Confirmação',
          name: 'Nome',
          function: 'Função',
          card: 'Cartão',
          international: 'Internacional',
          coverage: 'Cobertura',
          type: 'Tipo do produto',
          purchases: 'Compras',
          gift: 'Presentes',
          withdraw: 'Saque e Compras',
          brand: 'Bandeira',
          issuer: 'Emissor',
          technology: 'Tecnologia',
          chip: 'Chip',
          contractor: 'Contratante',
          cnpjContractor: 'CNPJ Contratante',
          campaign: 'Campanha',
          valueCharge: 'Total de carga',
          valueCard: 'Valor Unitário de Cartão',
          valueSend: 'Envio',
          qtd: 'Quantidade de cartões',
          table: {
            description: 'Descritivo',
            qtd: 'Qtd',
            value: 'Valor',
            msg: 'Faça o upload da Planilha de Premiado',
          },
        },
        otherOrder: {
          label: 'Gostaria de realizar outro pedido?',
          btnNot: 'Não, obrigado.',
          btnYes: 'Sim',
        },
      },
      baseCards: {
        label: 'Base Cartões',
        action: {
          activate: 'Ativar',
          blocking: 'Bloquear',
          cancel: 'Cancelar',
          duplicateCard: 'Emitir 2ª via',
          link: 'Vincular',
          bullkLink: 'Vínculo em lote',
          base: 'Base de cartões',
        },
        table: {
          brand: 'Bandeira',
          name: 'Nome',
          cpf: 'CPF',
          proxy: 'Proxy',
          note: 'Nota',
          status: 'Status',
          actionCancelMsg: 'Ação cancelada',
          blockCardMsg: 'Bloqueando cartão...',
          blockCardSeccessMsg: 'Bloqueio enviado com sucesso',
          ativCardMsg: 'Ativando cartão...',
          ativCardSuccessMsg: 'Ativação enviada com sucesso',
          cancelCardMsg: 'Cancelando cartão...',
          cancelCardSuccessMsg: 'Cancelamento enviado com sucesso',
          textCancel: 'Você confirma o "CANCELAMENTO" do cartão de Proxy',
          textCancel1: 'pertencente a ',
          textCancel2: 'CPF',
          textAtiv: 'Você confirma o "ATIVAÇÃO" do cartão de Proxy',
          textBlock: 'Você confirma o "BLOQUEIO" do cartão de Proxy',
          cancelTitle: 'Cancelar cartão',
          cancelBtn: 'Cancelar',
          yesBtn: 'Sim',
          ativTitle: 'Ativar cartão',
          blockTitle: 'Bloquear cartão',
          okBtn: 'Ok',
          product: 'Produto'
        },
        details: {
          invoiceFrom: 'Faturado',
          label: 'Detalhamento do cartão',
          search: 'Buscando movimento...',
          createPlanMsg: 'Gerando planilha...',
          createPdfMsg: 'Gerando PDF...',
          campaign: 'Campanha',
          brand: 'Bandeira',
          product: 'Produto',
          expirationDate: 'Validade',
          proxy: 'Proxy',
          cardNumber: 'Nº do cartão',
          cpf: 'CPF',
          bearer: 'Portador',
          status: 'Status',
          balance: 'Saldo',
          delivery: 'Entrega',
          deliveryStatus: 'Status',
          deliveryMsg: 'Mensagem',
          deliveryDate: 'Data Processamento',
          notDeliveryMsg: 'Nenhum registro de flash disponível',
          chargeMoviment: 'Movimentação de carga',
          chargeMovimentNote: 'Nota',
          chargeMovimentOrder: 'Pedido',
          chargeMovimentDate: 'Data Processamento',
          chargeMovimentValue: 'Valor',
          chargeMovimentStatus: 'Status',
          notchargeMoviment: 'Nenhum registro disponível',
        },
        filters: {
          status: 'Status',
          all: 'Todos',
          product: 'Produto',
          withdraw: 'Saque Compra',
          purchases: 'Compra',
          type: 'Tipo',
          brand: 'Bandeira',
          invoiceFrom: 'Faturado',
          campaign: 'Campanha',
          cpf: 'CPF',
          name: 'Nome',
          note: 'Nota',
          cardNumber: 'Nº cartão',
          proxy: 'Proxy',
          btnSearch: 'Buscar',
          expirationDate: 'Validade',
          searchMsg: 'Buscando dados...',
          vincMsg: 'Enviando solicitação de vínculo...',
          vincSuccessMsg: 'Solicitação de vínculo em lote enviado com sucesso',
          searchTypeError: 'Informe o tipo de busca',
          minCardError: 'Selecione apenas 1 cartão para vincular.',
          maxCardError: 'Selecione pelo menos 1 cartão para vincular.',
          errorStatusOp:
            'Somente cartões em estoque ou com erro de vinculação podem ser vinculados',
          minSecond:
            'Selecione pelo menos 1 cartão para solicitar segunda via.',
          maxSecond: 'Selecione apenas 1 cartão para solicitar segunda via.',
          sendRequestMsg: 'Enviando solicitação de vínculo...',
          vincUnitSuccessMsg: 'Solicitação de vinculação enviada com sucesso.',
        },
      },
      releaseMoney: {
        label: 'Liberação de cargas',
        releaseLabel: 'Liberar',
        cancelItensLabel: 'Cancelar Itens',
        uploadLabel: 'Upload retorno',
        searchMsg: 'Buscando dados...',
        filters: {
          type: 'Tipo',
          product: 'Produto',
          all: 'Todos',
          card: 'Cartão',
          transfer: 'Transferência',
          cpf: 'CPF',
          invoiceFrom: 'Faturado',
          bearers: 'Portador',
          nf: 'Número da nota fiscal',
          nd: 'Número da nota débito',
          paymentDate: 'Data Pagamento',
          searchBtn: 'Buscar',
          orderNumber: 'Número do pedido',
          value: 'Subtotal',
          errorSearch: 'Informe o tipo de busca',
          uploadErrorMsg: 'Suba o arquivo de retorno do banco para ser enviado',
          sendSuccessMsg: 'Arquivo enviado com sucesso',
          bankErrorMsg:
            'Informe de qual banco é esse arquivo que está sendo enviado.',
        },
        dialog: {
          title: 'Arquivos de retorno',
          sendMsg: 'Enviando arquivo...',
          selectTitle: 'Banco...',
          btnCancel: 'Cancelar',
          btnSalve: 'Salvar',
          cancelMsg: 'Cancelando...',
          cancelTitle: 'Cancelar itens',
          cancelBodyMsg:
            'Deseja realmente cancelar os itens selecionados? Esta ação não poderá ser desfeita!',
          btnCancelAction: 'Cancelar ação',
          btnOk: 'Ok',
        },
        table: {
          nf: 'NF',
          nd: 'NDs',
          product: 'Produto',
          payment: 'Pagamento',
          invoiceFrom: 'Faturado',
          bearers: 'Portador',
          cpf: 'CPF',
          value: 'Valor',
        },
        releaseTable: {
          sendMsg: 'Enviando arquivo...',
          sendSuccessMsg: 'Arquivo enviado com sucesso',
          createFile: 'Gerando aquivo...',
          nf: 'NF',
          nd: 'NDs',
          product: 'Produto',
          payment: 'Pagamento',
          invoiceFrom: 'Faturado',
          bearers: 'Portador',
          cpf: 'CPF/CNPJ',
          value: 'Valor',
          dialog: {
            providers: 'Fornecedores',
            countCharger: 'Nº de Cargas',
            downloadMsg: 'Download Remessa',
            value: 'Valor',
            bank: 'Banco',
            btnOk: 'Ok',
          },
        },
      },
      moviment: {
        label: 'Movimentação',
        searchMsg: 'Buscando dados...',
        filters: {
          productLabel: 'Filtrar por',
          statusLabel: 'Status',
          type: 'Tipo',
          searchBtn: 'Buscar',
          transfer: 'Transferência',
          card: 'Cartão',
          proxy: 'Proxy',
          name: 'Nome',
          cpf: 'CPF',
          cardNumber: 'Cartão',
          orderNumber: 'Número do pedido',
          notes: 'NF/ND',
          maxErrorMsg:
            'Pesquisa muito grande para gerar a planilha. Aplique alguns filtros.',
          errorTypeSearch: 'Informe o tipo de busca',
        },
        table: {
          documents: 'CPF/CNPJ',
          name: 'Nome',
          proxy: 'Proxy',
          notes: 'NF/ND',
          order: 'Pedido',
          processing: 'Processamento',
          conciliation: 'Conciliação',
          qtd: 'Qtd',
          value: 'Valor',
          status: 'Status',
        },
        dialog: {
          title: 'Movimentação',
          code: 'Código',
          itemCode: 'Código do item',
          eventCode: 'Código do envento',
          user: 'Usuário',
          createdAt: 'Data de Criação',
          status: 'Status',
          btnOk: 'ok',
        },
      },
      contract: {
        label: 'Contratos e taxas',
        searchMsg: 'Buscando contratos...',
        newContractBtn: 'Novo contrato',
        filters: {
          group: 'Grupo',
          cnpj: 'CNPJ',
          rz: 'Razão Social',
          all: 'Todos',
          disabled: 'Desabilitado',
          active: 'Ativo',
          transfer: 'Tranferência',
          card: 'Cartão',
          btnSearch: 'Buscar',
          type: 'Tipo',
          product: 'Produto',
          status: 'Status',
          typeSearchMsg: 'Informe o tipo de busca',
        },
        table: {
          group: 'Grupo',
          rz: 'Razão Social',
          cnpj: 'CNPJ',
          product: 'Produto',
          campaign: 'Campanha',
          status: 'Status',
          notRegistre: 'Nenhum registro disponível',
          edit: 'Editar contrato',
          contract: 'Contrato',
          download: 'Download contrato',
        },
        dialog: {
          title: 'Contrato',
          bodyMsg1: 'Deseja realmente',
          active: 'ativar',
          disabled: 'desativar',
          bodyMsg2: 'o contrato da empresa',
          bodyMsg3: 'Cancelar todos os cartões vinculados a esse contrato',
          btnCancel: 'Cancelar',
          btnYes: 'Sim',
        },
      },
      newContract: {
        label: 'Novo Contrato',
        company: {
          label: 'Dados Empresáriais',
          cnpj: 'CNPJ',
          rz: 'Razão Social',
          tradingName: 'Nome Fantasia',
          email: 'email',
          phone: 'Telefone',
          celPhone: 'Celular',
          website: 'Website URL',
          comments: 'Comentários',
          campaign: 'Campanha',
          required: 'Campo obrigatório',
          uploadSuccess: 'Contrato salvo com sucesso',
        },
        contact: {
          label: 'Contato responsável',
          removeBtn: 'Remover',
          name: 'Nome Completo',
          cpf: 'CPF',
          role: 'Cargo',
          department: 'Área de trabalho',
          phone: 'Telefone',
          celPhone: 'Celular',
          email: 'email',
          addContactBtn: '+ Contato',
          nextBtn: 'Avançar',
        },
        address: {
          label: 'Endereço de faturamento',
          postalCode: 'CEP',
          chkLabel: 'Entrega centralizada',
          address: 'Logradouro',
          district: 'Bairro',
          city: 'Cidade',
          state: 'Estado',
          number: 'Número S/N',
          complement: 'Complemento',
          deliveryAgent: 'Agente de entrega',
          deliveryService: 'Serviço de entrega',
          receiver: 'Recebedor',
          goBackBtn: 'Voltar',
          nextBtn: 'Avançar',
        },
        product: {
          label: 'Detalhes do Produto',
          label1: 'Tarifas Operacionais Empresa',
          label2: 'Tarifas Operacionais Portador',
          category: 'Categoria do Produto',
          card: 'Cartão',
          transfer: 'Transferência',
          issuer: 'Emissor',
          brand: 'Bandeira',
          has: 'Possui',
          chip: 'Chip',
          channel: 'Canal de vendas',
          international: 'Internacional',
          national: 'Nacional',
          gift: 'Vale Presente',
          fees: 'Honorários em',
          percent: 'Percentual',
          fixed: 'Fixo',
          tax: 'Taxa Adm',
          award: 'Premiação Anual Estimada',
          cardPrice: 'Preço do Cartão',
          readjustment: 'Período de Reajuste (meses)',
          cTransferFee: 'Transferência de Saldo de Cartão',
          cMinCharge: 'Valor de Carga Mínima',
          cMinChargeFee: 'Carga inferior ao Mínimo',
          cEmergencyCharge: 'Carga de Emergência',
          cHandling: 'Manuseio',
          cChargebackFee: 'Taxa de Estorno',
          cRejectTransferFee: 'Transação Rejeitada',
          oMonthlyPayment: 'Mensalidade',
          oUnlock: 'Desbloqueio de senha',
          oDuplicate: 'Emissão de 2ª Via',
          oChargeback: 'Estorno',
          oWithdraw: 'Saque',
          oMarkupFee: 'Taxa de Markup',
        },
        revenue: {
          label: 'Faturamento',
          calculation: 'Cálculo',
          normal: 'Normal',
          inverted: 'Invertido',
          paymentType: 'Forma de Pagamento',
          ticket: 'Boleto',
          transfer: 'Transferência',
          note: 'Permitir emissão de Nota Fiscal',
          currencyType: 'Tipo de Moeda',
        },
        summary: {
          label: 'Resumo de Contrato',
          rz: 'Razão Social',
          product: 'Produto',
          transfer: 'Transferência',
          cardValue: 'Valor Unitário de Cartão',
          tradingName: 'Nome Fantasia',
          tax: 'Taxa ADM',
          cnpj: 'CNPJ',
          campaign: 'Nome da Campanha',
          salesAgents: 'Agentes Comerciais',
          goBackBtn: 'Voltar',
          btnSalve: 'Salvar contrato',
        },
        success: {
          btnConclude: 'Concluir',
          successMsg: 'Contrato inserido com sucesso',
        },
      },
      editContract: {
        label: 'Editar Contrato',
        successMsg: 'Contato editado com sucesso',
        uploadContract: 'Upload Contrato',
      },
      status: {
        Active: 'Ativo',
        Disabled: 'Desativado',
        AutoProcessing: 'Processamento automático',
        AwaitingBlock: 'Aguardando Bloqueio',
        AwaitingCancelation: 'Aguardando Cancelamento',
        AwaitingCanceledInvoice: 'Aguardando Cancelamento (RPS criado)',
        AwaitingCard: 'Aguardando Cartão',
        AwaitingCardRelease: 'Aguardando Liberação do Cartão',
        AwaitingConciliation: 'Aguardando Conciliação',
        AwaitingInvoice: 'Aguardando a Nota Fiscal',
        AwaitingIssuerLink: 'Vinculação Pendente',
        AwaitingIssuerLinkResponse: 'Aguardando Resposta do Emissor',
        AwaitingPayment: 'Aguardando Pagamento',
        AwaitingProcessing: 'Aguardando Processamento',
        AwaitingRelease: 'Aguardando Liberação',
        AwaitingSettlement: 'Aguardando Liquidação',
        AwaitingShipping: 'Aguardando Envio',
        AwaitingUnblock: 'Aguardando Desbloqueio',
        Blocked: 'Bloqueado',
        Canceled: 'Cancelado',
        Card: 'Cartão',
        Check: 'Cheque',
        Created: 'Criado',
        Error: 'Erro',
        Fraud: 'Fraude',
        InStock: 'Em Estoque',
        InvoiceReceived: 'Nota fiscal recebida',
        Paid: 'Pago',
        PayableCommercialAgent: 'A receber',
        Payable: 'A Pagar',
        Processed: 'Processado',
        ProcessedWithErrors: 'Processado com Erros',
        Processing: 'Processando',
        Queued: 'Em fila',
        Received: 'Recebido',
        Rejected: 'Rejeitado',
        Settled: 'Liquidado',
        Transfer: 'Transferência',
        Voucher: 'Voucher',
        Pause: 'Pausado',
        growing: 'Crescente',
        descending: 'Decrecente',
        all: 'Todos',
        search: 'Busca',
        VinculationError: 'Erro de vinculação',
      },
      validation: {
        cpf: 'Insira um cpf valido de 11 dígitos',
        email: 'Campo deve ser um email válido',
        cnpj: 'Insira um cnpj valido de 14 dígitos',
      },
      tutorials: {
        label: 'Tutoriais',
        btnNew: 'Carregar Tutorial',
        new: {
          label: 'Tutoriais',
          btnNew: 'Novo Conteúdo',
          table: {
            title: 'Título',
            description: 'Descrição',
            midia: 'Mídia',
            status: 'Status',
          },
          new: {
            label: 'Novo Conteúdo',
            title: 'Título',
            description: 'Descrição',
            midia: 'Url do vídeo',
            btnCancel: 'Voltar',
            btnSalve: 'Salvar',
            msgSalve: 'Conteúdo salvo com sucesso',
            msgEdit: 'Tutorial editado com sucesso',
            btnEdit: 'Edição',
            btnDelete: 'Exclusão',
            btnVisible: 'Publicado',
            btnHide: 'Oculto',
          },
        },
      },
    },
  },
  es: {
    translation: {
      Welcome: 'Bienvenido al',
      Login: 'Iniciar sesión para continuar',
      User: 'Usuario',
      Password: 'Contraseña',
      InvalidPassword: 'Contraseña invalida',
      Forgot: 'Olvidé mi contraseña',
      Continue: 'Continuar',
      Label1: 'Obtener el código por',
      Label2: 'Verificación de dos pasos',
      CodSendBy: 'Código enviado por',
      Label3: 'Introduce el código enviado',
      Code: 'Código',
      Label4: 'Iniciar sesión',
      Loading: 'Comprobación de usuario y contraseña...',
      Logging: 'Ingresando al sistema...',
      MessageSend: 'Enviando código por SMS...',
      MessageSendEmail: 'Envío de enlace por correo electrónico...',
      MessageSendEmailSuccess:
        'Inicie sesión utilizando el enlace enviado a su correo electrónico.',
      MessageSendEmailError:
        'No está registrado en este modo de autenticación.',
      initialMenu: 'Menu Inicial',
      administration: 'Administración',
      conf: 'Configuraciones de la cuenta',
      confUser: 'Administrar usuarios',
      notification: 'Notificaciones',
      tutorial: 'Tutoriales',
      general: 'General',
      DashBoard: {
        orders: 'Peticiones',
        dashBoard: 'Tablero de mandos',
        contract: 'Contratos y tarifas',
        products: 'Productos',
        baseCards: 'Bases de tarjetas',
        movement: 'Movimienot',
        release: 'liberación de carga',
      },
      ForgotPassword: {
        recover: 'Recuperar contraseña',
        label1: 'Ingrese el correo electrónico registrado',
        send: 'Mandar',
      },
      Product: {
        product: 'Productos',
        newProduct: 'Nuevo producto',
        initialMenu: 'Menú Inicio',
        table: {
          product: 'Producto',
          category: 'Categoría',
          type: 'los tipos',
          provider: 'Proveedor',
          created: 'Creación',
          origin: 'Origen',
          status: 'Estado',
          edit: 'Editar producto',
          duplicate: 'Producto duplicado',
          suspend: 'Suspender producto',
          cancel: 'Cancelar producto',
        },
        category: {
          label1: 'Elija su categoría de producto',
          card: 'Tarjeta de prepago',
          account: 'Cuenta digital',
          transfer: 'Transferir',
          qr: 'Código QR del cupón',
          cripto: 'Voucher Cripto',
          plus: '+ Crear nueva categoría',
        },
        accessAll: 'Accede a todos los productos',
        acessory: 'Accesorio',
        changeProduct: 'Elige el producto',
        newInCategory: 'Crear nuevo producto en esta categoría',
        creditCard: {
          new: 'Nueva tarjeta',
          label1: 'Tarjeta de prepago',
          table: {
            product: 'Producto',
            type: 'Tipos',
            flag: 'Bandera',
            provider: 'Proveedor',
            created: 'Creación',
            status: 'Estado',
            edit: 'Editar producto',
            duplicate: 'Producto duplicado',
            suspend: 'Suspender producto',
            cancel: 'Cancelar producto',
            planProducts: 'Hoja de cálculo del producto',
            allCategory: 'Accede a todas las categorías',
            search: 'Búsqueda',
            selectStatus: {
              label: 'Estado',
              status: {
                opt1: 'Creado',
                opt2: 'En espera de pago',
                opt3: 'Procesada',
                opt4: 'Procesado con error',
              },
            },
            selectViewer: {
              label: 'Visualización',
              status: {
                opt1: 'Todos',
              },
            },
            selectOrder: {
              label: 'Ordenar',
              status: {
                opt1: 'Creciente',
                opt2: 'Descendente',
              },
            },
          },
          checkout: {
            label1: 'Nueva Tarjeta Prepago',
            feature: 'Características de la tarjeta',
            flag: 'Bandera',
            selectFlag: 'Seleccione la bandera',
            name: 'Nombre del producto',
            nameSugestion: 'Tarjeta de prepago',
            issuer: 'Editor',
            selectIssuer: 'Seleccionar emisor',
            function: 'Función transaccional',
            opt1Function: 'Vale regalo',
            opt2Function: 'Retiro y Compras',
            technology: 'Tecnología',
            chip: 'Chip',
            nfc: 'NFC',
            tarja: 'Raya',
            coverage: 'Techo',
            national: 'Nacional',
            international: 'Internacional',
            exit: 'Salir',
            company: {
              label1: 'Tarifas Operativas de la Empresa',
              tranferBalance: 'Transferencia de saldo',
              loadLess: 'Menos del cargo mínimo',
              emergenciCargo: 'Carga de emergencia',
              chargebackFee: 'Tarifa de devolución de cargo',
              minimumLoad: 'Valor mínimo de carga',
              operatingFee: 'Manejo de tarjetas',
              personalization: 'Personalización',
            },
            client: {
              label1: 'Tarifas Operativas del Transportista',
              monthlyPayment: 'Mensualidad',
              passwordUnlock: 'Desbloqueo de contraseña',
              withdraw: 'Retiro en cajero automático',
              transfer: 'Transferencia CC',
              duplicateCard: '2. Mediante tarjeta',
              chargebackFee: 'Tarifa de devolución de cargo',
              chargebackTax: 'Tarifa de devolución de cargo',
              mktTax: 'Tarifa de margen de beneficio internacional',
              iof: 'IOF/IVA',
            },
            limits: {
              label1: 'Límites',
              transfer1: 'Límite de carga y',
              transfer2: 'transferencias entre tarjetas',
              purchases: 'Límite de compra',
              withdraw: 'Retirada Límite',
              paymentCount: 'Límite de pago de facturas',
              celPhone: 'Recarga de celular',
              balanceInCard: 'Límite de saldo de la tarjeta',
              prizeInCard: 'Disponibilidad de la recompensa en la tarjeta',
            },
            review: {
              label1: 'Revisión y confirmación',
              type: 'Tipo de producto: Tarjeta',
              name: 'Nombre: ',
              nameProd: 'Tarjeta de prepago',
              operation: 'Operación',
              creditOperation: 'Crédito',
              function: 'Funciones',
            },
          },
        },
      },
      Order: {
        order: 'Pedidos',
        orderHistoric: 'Historial de pedidos',
        newOrder: 'Nuevo pedido',
        initialMenu: 'Menu Inicial',
        chargeback: 'Devolución de cargo',
        menu: {
          orders: 'Pedidos',
          order: 'Pedido',
          history: 'Historial de pedidos',
          contract: 'Contratos y tarifas',
          dashBoard: 'Tablero',
        },
        filters: {
          status: {
            awaitingSettlement: 'Aguardando liquidación',
            awaitingPayment: 'Aguardando pago',
            canceled: 'Cancelado',
            created: 'Creado',
            error: 'Error',
            errors: 'Errors',
            settled: 'Establecido',
          },
          product: {
            card: 'Tarjeta',
            transfer: 'Transferir',
          },
          search: {
            nf: 'Nf',
            nd: 'ND',
            payDay: 'Fecha de pago',
            invoiceFrom: 'Facturado',
            order: 'Solicitud',
            orderName: 'Nombre del pedido',
            value: 'Value',
          },
          btn: 'Búsqueda',
          type: 'Tipo',
          statusLabel: 'Estado',
          productLabel: 'Producto',
          searchErrorMessage: 'Introduce el tipo de búsqueda',
        },
        table: {
          numberNf: 'Nº da NF/ND',
          payment: 'Pago',
          rz: 'Razón social',
          orderName: 'Nombre del pedido',
          order: 'Solicitud',
          product: 'Producto',
          value: 'Valor',
          status: 'Estado',
          noResult: 'Ningún registro disponible',
          duplicateOrder: 'Duplicar la orden',
          duplicateMsg: '¿Realmente quieres duplicar el pedido?',
          duplicateTitle: 'Solicitud',
          duplicateCancelBtn: 'Cancelar',
          duplicateConfirmBtn: 'Sí',
          orderDetails: 'Ver pedido',
          orderCancel: 'Cancelar orden',
          orderCancelTitle: 'Solicitud',
          orderCancelMsg:
            '¿Realmente desea cancelar el pedido? ¡Esta acción no se puede deshacer!',
          orderCancelBtn: 'Cancelar',
          orderCancelConfirmBtn: 'Sí',
          created: 'Creación'
        },
      },
      OrderDetails: {
        title: 'Solicitud',
        searchMsg: 'Buscando detalles del pedido...',
        modelPlanMsg: 'Buscando plantilla de hoja de cálculo....',
        creatingMsg: 'Creando',
        completeErrorMsg: 'Error al completar el pedido.',
        paymentErrorMsg: 'Error al informar el pago del pedido.',
        cancelingMsg: 'Cancelando pedido...',
        productLabel: 'Producto',
        cancelingErrorMsg:
          'Debe seleccionar al menos un elemento para eliminarlo.',
        savingOrderMsg: 'Guardar orden',
        reviewPlanMsg:
          'Algunas tarjetas no se agregaron al pedido. Revise la hoja de trabajo.',
        transfer: 'Transferir',
        card: 'Tarjeta Joycard',
        summary: 'Resumen del pedido',
        orderNumber: 'Numéro del pedido',
        nf: 'Nota Fiscal',
        db: 'Nota de débito',
        notContainMsg: 'NO CONTIENE',
        product: 'Producto',
        productName: 'Tarjeta de retiro de compras',
        rz: 'Razón social',
        status: 'Estado del pedido',
        orderBy: 'Realizado por:',
        paymentDate: 'Día de pago',
        orderDate: 'Fecha de solicitud',
        bearers: 'Transportistas',
        cnpj: 'CNPJ',
        notification: 'Notificación',
        planAwarded: 'Hoja de cálculo premiada',
        ticket: 'Orden de pago',
        addItem: '+ Añadir artículo',
        discardItem: 'Descartar artículo',
        goBack: 'Regresar',
        cancelOrder: 'Cancelar orden',
        finalizeOrder: 'Finalizar pedido',
        paidOut: 'Pagado',
        modalTitle: 'Solicitud',
        modalCompleteMsg: '¿Realmente desea completar el pedido?',
        completeCancelBtn: 'Cancelar',
        completeConfirmBtn: 'Sí',
        modalRemoveItemMsg:
          '¿Realmente desea eliminar los artículos seleccionados del pedido?',
        modalCancelMsg:
          ' ¿Realmente desea cancelar el pedido? Esta acción no se puede deshacer.',
        paymentMsg:
          '¿Realmente desea informar el pago de este pedido? Esta acción no se puede deshacer.',
        loadPaymentMsg: 'Informando pago de pedido...',
        addItensToOrderMsg: 'Agregando artículos al pedido...',
        newOrderLabel: 'Insertar pedido',
        exit: 'Salir',
        btnContinue: 'Continuar',
        btnSalve: 'Guardar orden',
        table: {
          name: 'Nombre',
          docs: 'CPF/CNPJ',
          proxy: 'Proxy',
          bank: 'Banco',
          ag: 'Agencia',
          account: 'Cuenta',
          description: 'Descriptivo',
          qtd: 'La cantidad',
          value: 'Valor',
          dateOf: 'Fecha',
          processing: 'procesando',
          conciliation: 'conciliación',
          statusLabel: 'estado',
          status: {
            validationError: 'Error de validación de datos',
            bullCharge: 'Carga masiva del sistema anterior',
            statusChange: 'Cambio de estado',
            comments: 'Comentário',
            cnab: 'Código de retorno CNAB',
            accessoToken: 'Código de retorno Acesso',
            requestSent: 'Solicitud enviada',
            errorBlock: 'Error de bloqueo/desbloqueo de tarjeta',
          },
          descriptionItens: 'Descripción de los artículos del pedido',
          total: 'Total',
        },
      },
      newOrder: {
        changeProduct: 'Elige el producto',
        card: 'Tarjeta',
        cardType: 'Pagado por adelantado',
        transfer: 'Transferir',
        createMsg: 'Creando orden...',
        uploadFileMsg: 'Cargue el archivo.',
        cnpjMsg: 'Ingrese CNPJ para continuar',
        companyMsg: 'Elegir empresa para continuar',
        typeErrorMsg: 'Introduzca el tipo de orden para continuar',
        addItensMsg: 'Agregando elementos...',
        editOrderMsg: 'Editando orden...',
        upLoadFileMsg: 'Cargue el archivo.',
        reviewPlanMsg:
          'Algunas tarjetas no se agregaron al pedido. Revise la hoja de trabajo.',
        contract: {
          choiceCompany: 'Elige la empresa a facturar',
          companyName: 'Nombre de la empresa',
          cnpj: 'Empresa CNPJ',
          campaignName: 'Nombre de campaña',
          noResult: 'No tiene campaña',
          searchCompanyMsg: 'Buscando empresas...',
        },
        typeProduct: {
          label: 'Elija el tipo de producto',
          withdraw: 'Retiro/Compra',
          gift: 'Compra/ Presente',
        },
        tranferPlan: {
          label: 'Introduce los datos del pedido',
          search: 'Buscando plantilla de hoja de cálculo....',
          planAwarded: 'Hoja de trabajo premiada',
          nfLabel: ' Nota Fiscal',
          ticket: 'Boleto',
          expirationDate: 'Fecha de vencimiento',
          orderName: 'Nombre del pedido',
          comments: 'Comentarios de la factura',
          plan: {
            name: 'Nombre',
            cpf: 'CPF',
            bank: 'Banco',
            ag: 'Agencia',
            account: 'Cuenta',
            description: 'Descriptivo',
            value: 'Valor',
            msg: 'Cargue la hoja de cálculo del premio',
          },
        },
        transferSummary: {
          label: 'Resumen y confirmación',
          date: 'Fecha: ',
          product: 'Producto',
          transfer: 'Transferir',
          company: 'Empresa',
          contractor: 'Contratista',
          coverage: 'Techo',
          international: 'Internacional',
          value: 'Valor',
        },
        specOrder: {
          label: 'Elija el tipo de orden',
          complete: 'Carga Tarjeta',
          inventory: 'Tarjeta de valores',
          noCharge: 'Tarjeta con bono y sin cargo',
          credit: 'Crédito',
        },
        created: {
          nfMsg: 'Generación de factura...',
          msg: '¡Tu pedido ha sido registrado con éxito!',
          btnSalve: 'Guardar para continuar más tarde',
          btnNf: 'Emitir factura',
        },
        cardExists: {
          label: 'Elija una de las tarjetas prepago registradas',
          labelComplete: 'Completar el pedido',
          selectMsg: 'SELECCIONA TARJETA PREPAGO',
        },
        uploadPlan: {
          searchMsg: 'Plantilla de hoja de cálculo de búsqueda....',
          label: 'Introduce los datos del pedido',
          planAwarded: 'Hoja de trabajo premiada',
          nfLabel: ' Nota Fiscal',
          ticket: 'Boleto',
          expirationDate: 'Fecha de vencimiento',
          orderName: 'Nombre del pedido',
          comments: 'Comentarios de la Nota Fiscal',
          plan: {
            name: 'Nombre',
            cpf: 'CPF',
            proxy: 'Proxy',
            qtd: 'La cantidad',
            value: 'Valor',
            msg: 'Cargue la hoja de cálculo del premio',
          },
        },
        salesCredit: {
          label: 'Compra crédito',
          nfLabel: ' Nota Fiscal',
          ticket: 'Boleto',
          expirationDate: 'Fecha de vencimiento',
          orderName: 'Nombre do pedido',
          comments: 'Comentarios de la factura',
          valueLabel: 'Monto do crédito',
        },
        cardVinc: {
          label: 'Comprar tarjetas y enlace',
          cpfError: 'Introduce un CPF válido',
          nfLabel: ' Nota Fiscal',
          ticket: 'Boleto',
          expirationDate: 'Fecha de vencimiento',
          orderName: 'Nombre do pedido',
          comments: 'Comentarios de la factura',
          name: 'Nombre',
          cpf: 'CPF',
          btnAdd: 'Agregar',
          action: 'Acción',
          tableMsg: 'No se agregaron recompensas',
        },
        loadingNf: {
          label: 'Solicitando factura...',
        },
        qtdCards: {
          label: 'Comprar tarjeta',
          nfLabel: ' Nota Fiscal',
          ticket: 'Boleto',
          expirationDate: 'Fecha de vencimiento',
          orderName: 'Nombre do pedido',
          comments: 'Comentarios de la factura',
          qtdCards: 'Número de tarjetas',
        },
        reviewCards: {
          label: 'Resumen y confirmación',
          date: 'Fecha de solicitud',
          product: 'Producto',
          productType: 'Tarjeta de prepago',
          type: 'Tipo de producto',
          purchases: 'Compras',
          gift: 'Regalos',
          withdraw: 'Retiro y Compras',
          brand: 'Bandera',
          issuer: 'Editor',
          technology: 'Tecnología',
          chip: 'Chip',
          contractor: 'Contratista',
          cnpjContractor: 'CNPJ Contratista',
          campaign: 'Campaña',
          valueCharge: 'Valor total de la carga',
          valueCard: 'Valor de la tarjeta',
          valueSend: 'Valor de envío',
          table: {
            name: 'Nombre',
            cpf: 'CPF',
            proxy: 'Proxy',
            description: 'Descriptivo',
            qtd: 'La cantidad',
            value: 'Valor',
            msg: 'Cargue la hoja de cálculo del premio',
          },
        },
        nfCreated: {
          label: 'Solicitud realizada con éxito',
          label2: 'En espera de pago de la factura para el procesamiento',
          btnOk: 'Ok',
        },
        reviewStoke: {
          label: 'Resumen y confirmación',
          name: 'Nombre',
          function: 'Ocupación',
          card: 'Tarjeta',
          international: 'Internacional',
          coverage: 'Techo',
          type: 'Tipo de producto',
          purchases: 'Compras',
          gift: 'Regalos',
          withdraw: 'Retiro y Compras',
          brand: 'Bandera',
          issuer: 'Editor',
          technology: 'Tecnología',
          chip: 'Chip',
          contractor: 'Contratista',
          cnpjContractor: 'CNPJ Contratista',
          campaign: 'Campaña',
          valueCharge: 'Valor total de la carga',
          valueCard: 'Valor de la tarjeta',
          valueSend: 'Valor de envío',
          qtd: 'Número de tarjetas',
          table: {
            description: 'Descriptivo',
            qtd: 'La cntidad',
            value: 'Valor',
            msg: 'Cargue la hoja de cálculo del premio',
          },
        },
        otherOrder: {
          label: '¿Le gustaría hacer otro pedido?',
          btnNot: 'No gracias.',
          btnYes: 'Sí',
        },
      },
      baseCards: {
        label: 'Tarjetas base',
        action: {
          activate: 'Activar',
          blocking: 'Bloquear',
          cancel: 'Cancelar',
          duplicateCard: 'Emitir segunda copia',
          link: 'Enlace',
          bullkLink: 'Enlace por lotes',
          base: 'Base de cartas',
        },
        table: {
          brand: 'Bandera',
          name: 'Nombre',
          cpf: 'CPF',
          proxy: 'Proxy',
          note: 'Nota',
          status: 'Estado',
          actionCancelMsg: 'Acción cancelada',
          blockCardMsg: 'Tarjeta de bloqueo...',
          blockCardSeccessMsg: 'Bloqueo enviado con éxito',
          ativCardMsg: 'Activando tarjeta...',
          ativCardSuccessMsg: 'Activación enviada con éxito',
          cancelCardMsg: 'cancelación de tarjeta...',
          cancelCardSuccessMsg: 'Cancelación enviada con éxito',
          textCancel: '¿Confirmas la "CANCELACIÓN" de la tarjeta Proxy',
          textCancel1: 'pertenece a ',
          textCancel2: 'CPF',
          textAtiv: '¿Confirmas la "ACTIVACIÓN" de la tarjeta Proxy',
          textBlock: '¿Confirmas el "BLOQUEO" de la tarjeta Proxy',
          cancelTitle: 'Cancelar tarjeta',
          cancelBtn: 'Cancelar',
          yesBtn: 'Sí',
          ativTitle: 'Activar tarjeta',
          blockTitle: 'Bloquear tarjeta',
          okBtn: 'Ok',
          product: 'Producto'
        },
        details: {
          invoiceFrom: 'Facturado',
          label: 'Detalles de tarjeta',
          search: 'Buscando movimiento...',
          createPlanMsg: 'Generando hoja de trabajo...',
          createPdfMsg: 'Generando PDF...',
          campaign: 'Campaña',
          brand: 'Bandera',
          product: 'Producto',
          expirationDate: 'Validez',
          proxy: 'Proxy',
          cardNumber: 'Numero de tarjeta',
          cpf: 'CPF',
          bearer: 'Transportador',
          status: 'Estado',
          balance: 'Balance',
          delivery: 'Entrega',
          deliveryStatus: 'Estado',
          deliveryMsg: 'Mensaje',
          deliveryDate: 'Fecha de procesamiento',
          notDeliveryMsg: 'No hay registro flash disponible',
          chargeMoviment: 'Movimentación de carga',
          chargeMovimentNote: 'Nota',
          chargeMovimentOrder: 'Solicitud',
          chargeMovimentDate: 'Fecha de procesamiento',
          chargeMovimentValue: 'Valor',
          chargeMovimentStatus: 'Estado',
          notchargeMoviment: 'Ningún registro disponible',
        },
        filters: {
          status: 'Estado',
          all: 'Todos',
          product: 'Producto',
          withdraw: 'Retiro de compra',
          purchases: 'Compra',
          type: 'Escribe',
          brand: 'Bandera',
          invoiceFrom: 'Facturado',
          campaign: 'Campaña',
          cpf: 'CPF',
          name: 'Nombre',
          note: 'Nota',
          cardNumber: 'Numero de tarjeta',
          proxy: 'Proxy',
          btnSearch: 'Búsqueda',
          expirationDate: 'Validez',
          searchMsg: 'Buscando datos...',
          vincMsg: 'Enviando solicitud de enlace...',
          vincSuccessMsg: 'Solicitud de enlace por lotes enviada correctamente',
          searchTypeError: 'Introduce el tipo de búsqueda',
          minCardError: 'Seleccione solo 1 tarjeta para vincular.',
          maxCardError: 'Selecione pelo menos 1 cartão para vincular.',
          errorStatusOp:
            'Solo se pueden vincular tarjetas en stock o con error de vinculación',
          minSecond:
            'Seleccione al menos 1 tarjeta para solicitar un duplicado.',
          maxSecond: 'Seleccione solo 1 tarjeta para solicitar un duplicado.',
          sendRequestMsg: 'Enviando solicitud de enlace...',
          vincUnitSuccessMsg: 'Solicitud de vinculación enviada con éxito.',
        },
      },
      releaseMoney: {
        label: 'Liberación de carga',
        releaseLabel: 'Liberar',
        cancelItensLabel: 'Cancelar artículos',
        uploadLabel: 'Subir volver',
        searchMsg: 'Buscando datos...',
        filters: {
          type: 'Tipo',
          product: 'Producto',
          all: 'Todos',
          card: 'Tarjeta',
          transfer: 'Transferir',
          cpf: 'CPF',
          invoiceFrom: 'Facturado',
          bearers: 'Transportador',
          nf: 'Número de factura',
          nd: 'Número da nota débito',
          paymentDate: 'Fecha de pago',
          searchBtn: 'Búsqueda',
          orderNumber: 'Numéro del pedido',
          value: 'Total parcial',
          errorSearch: 'Introduce el tipo de búsqueda',
          uploadErrorMsg: 'Sube el archivo de la declaración bancaria a enviar',
          sendSuccessMsg: 'El archivo ha subido correctamente',
          bankErrorMsg: 'Informa de qué banco se envía este archivo.',
        },
        dialog: {
          title: 'Devolver archivos',
          sendMsg: 'Enviando archivo...',
          selectTitle: 'Banco...',
          btnCancel: 'Cancelar',
          btnSalve: 'Ahorrar',
          cancelMsg: 'Cancelando...',
          cancelTitle: 'Cancelar artículos',
          cancelBodyMsg:
            '¿Realmente desea cancelar los elementos seleccionados? ¡Esta acción no se puede deshacer!',
          btnCancelAction: 'Cancelar acción',
          btnOk: 'Ok',
        },
        table: {
          nf: 'Nota fiscal',
          nd: 'Notas debito',
          product: 'Producto',
          payment: 'Pagado',
          invoiceFrom: 'Facturado',
          bearers: 'Transportador',
          cpf: 'CPF',
          value: 'Valor',
        },
        releaseTable: {
          sendMsg: 'Enviando archivo...',
          sendSuccessMsg: 'El archivo ha subido correctamente',
          createFile: 'Generar archivo...',
          nf: 'Nota fiscal',
          nd: 'Notas debito',
          product: 'Producto',
          payment: 'Pagado',
          invoiceFrom: 'Facturado',
          bearers: 'Transportador',
          cpf: 'CPF/CNPJ',
          value: 'Valor',
          dialog: {
            providers: 'Proveedores',
            countCharger: 'Número de Cargas',
            downloadMsg: 'Descargar Envío',
            value: 'Valor',
            bank: 'Banco',
            btnOk: 'Ok',
          },
        },
      },
      moviment: {
        label: 'Movimienot',
        searchMsg: 'Buscando datos...',
        filters: {
          productLabel: 'Filtrado por',
          statusLabel: 'Filtrado por estado',
          type: 'Tipo',
          searchBtn: 'Búsqueda',
          transfer: 'Transferir',
          card: 'Tarjeta',
          proxy: 'Proxy',
          name: 'Nombre',
          cpf: 'CPF',
          cardNumber: 'Tarjeta',
          orderNumber: 'Numéro del pedido',
          notes: 'NF/ND',
          maxErrorMsg:
            'Encuesta demasiado grande para generar una hoja de cálculo. Aplicar algunos filtros.',
          errorTypeSearch: 'Introduce el tipo de búsqueda',
        },
        table: {
          documents: 'CPF/CNPJ',
          name: 'Nombre',
          proxy: 'Proxy',
          notes: 'NF/ND',
          order: 'Solicitud',
          processing: 'Procesando',
          conciliation: 'Conciliación',
          qtd: 'La cantidad',
          value: 'Valor',
          status: 'Estado',
        },
        dialog: {
          title: 'Movimienot',
          code: 'Código',
          itemCode: 'Código del objeto',
          eventCode: 'Código de envento',
          user: 'Usuario',
          createdAt: 'Fecha de creación',
          status: 'Estado',
          btnOk: 'ok',
        },
      },
      contract: {
        label: 'Contratos y tarifas',
        searchMsg: 'Buscando contratos...',
        newContractBtn: 'Nuevo contracto',
        filters: {
          group: 'Grupo',
          cnpj: 'CNPJ',
          rz: 'Razón Social',
          all: 'Todos',
          disabled: 'Deshabilitado',
          active: 'Activo',
          transfer: 'Transferir',
          card: 'Tarjeta',
          btnSearch: 'Búsqueda',
          type: 'Escribe',
          product: 'Producto',
          status: 'Estato',
          typeSearchMsg: 'Introduce el tipo de búsqueda',
        },
        table: {
          group: 'Grupo',
          rz: 'Razón social',
          cnpj: 'CNPJ',
          product: 'Producto',
          campaign: 'Campaña',
          status: 'Estado',
          notRegistre: 'No hay registros disponibles',
          edit: 'Editar contracto',
          contract: 'Contracto',
          download: 'Descarga de contrato',
        },
        dialog: {
          title: 'Contracto',
          bodyMsg1: 'Realmente quiero',
          active: 'activar',
          disabled: 'desactivar',
          bodyMsg2: 'el contrato de empresa',
          bodyMsg3: 'Cancelar todas las tarjetas vinculadas a este acuerdo',
          btnCancel: 'Cancelar',
          btnYes: 'Sí',
        },
      },
      newContract: {
        label: 'Nuevo contracto',
        company: {
          label: 'Datos comerciales',
          cnpj: 'CNPJ',
          rz: 'Razón Social',
          tradingName: 'Nombre de fantasía',
          email: 'email',
          phone: 'Teléfono',
          celPhone: 'Teléfono celular',
          website: 'URL del sitio web',
          comments: 'Comentarios',
          campaign: 'Campaña',
          required: 'Campo obligatorio',
          uploadSuccess: 'Contrato guardado con éxito',
        },
        contact: {
          label: 'Contacto responsable',
          removeBtn: 'Para eliminar',
          name: 'Nombre completo',
          cpf: 'CPF',
          role: 'Oficina',
          department: 'Área de trabajo',
          phone: 'Teléfono',
          celPhone: 'Teléfono celular',
          email: 'email',
          addContactBtn: '+ Contacto',
          nextBtn: 'Ventaja',
        },
        address: {
          label: 'Dirección de Envio',
          postalCode: 'Código postal',
          chkLabel: 'Entrega centralizada',
          address: 'Dirección',
          district: 'Vecindario',
          city: 'Ciudad',
          state: 'Estado',
          number: 'Número',
          complement: 'Complementar',
          deliveryAgent: 'Agente de entrega',
          deliveryService: 'Servicio de entrega',
          receiver: 'Receptor',
          goBackBtn: 'Regresar',
          nextBtn: 'Ventaja',
        },
        product: {
          label: 'Detalles del producto',
          label1: 'Tarifas Operativas de la Empresa',
          label2: 'Tarifas Operativas del Transportista',
          category: 'Categoria de producto',
          card: 'Tarjeta',
          transfer: 'Transferir',
          issuer: 'Editor',
          brand: 'Bandera',
          has: 'Tiene',
          chip: 'Chip',
          channel: 'Canal de ventas',
          international: 'Internacional',
          national: 'Nacional',
          gift: 'Vale regalo',
          fees: 'Tarifas en',
          percent: 'Porcentaje',
          fixed: 'Fijado',
          tax: 'Tarifa de administración',
          award: 'Premio Anual Estimado',
          cardPrice: 'Precio de la tarjeta',
          readjustment: 'Período de reajuste (meses)',
          cTransferFee: 'Transferencia de saldo de tarjeta',
          cMinCharge: 'Valor mínimo de carga',
          cMinChargeFee: 'Cargar menos del mínimo',
          cEmergencyCharge: 'Cargo de emergencia',
          cHandling: 'Manejo',
          cChargebackFee: 'Tarifa de devolución de cargo',
          cRejectTransferFee: 'Transacción rechazada',
          oMonthlyPayment: 'Mensualidad',
          oUnlock: 'Desbloqueo de contraseña',
          oDuplicate: 'Emisión de duplicado',
          oChargeback: 'Inversión',
          oWithdraw: 'Retirar',
          oMarkupFee: 'Tasa de marcado',
        },
        revenue: {
          label: 'Invoicing',
          calculation: 'Cálculo',
          normal: 'Normal',
          inverted: 'Invertido',
          paymentType: 'Forma de pago',
          ticket: 'Boleto',
          transfer: 'Transferir',
          note: 'Permitir emisión de Factura',
          currencyType: 'Tipo de cambio monetario',
        },
        summary: {
          label: 'Resumen del contrato',
          rz: 'Razón Social',
          product: 'Producto',
          transfer: 'Transferir',
          cardValue: 'Valor de la tarjeta',
          tradingName: 'Nombre de fantasía',
          tax: 'Tarifa ADM',
          cnpj: 'CNPJ',
          campaign: 'Nombre de campaña',
          salesAgents: 'Agentes Comerciales',
          goBackBtn: 'Regresar',
          btnSalve: 'Guardar contracto',
        },
        success: {
          btnConclude: 'Concluir',
          successMsg: 'Contrato ingresado exitosamente',
        },
      },
      editContract: {
        label: 'Editar Contrato',
        successMsg: 'Contacto editado con éxito',
        uploadContract: 'Subir Contrato',
      },
      status: {
        Active: 'Activo',
        Disabled: 'Desactivado',
        AutoProcessing: 'Procesamiento automático',
        AwaitingBlock: 'Esperando el bloqueo',
        AwaitingCancelation: 'En espera de la cancelación',
        AwaitingCanceledInvoice: 'En espera de cancelación (RPS creado)',
        AwaitingCard: 'Tarjeta en espera',
        AwaitingCardRelease: 'En espera de liberación de tarjeta',
        AwaitingConciliation: 'En espera de conciliación',
        AwaitingInvoice: 'Esperando la Factura',
        AwaitingIssuerLink: 'Encuadernación pendiente',
        AwaitingIssuerLinkResponse: 'En espera de respuesta del emisor',
        AwaitingPayment: 'En espera de pago',
        AwaitingProcessing: 'En espera de procesamiento',
        AwaitingRelease: 'En espera de lanzamiento',
        AwaitingSettlement: 'En espera de liquidación',
        AwaitingShipping: 'Esperando a ser enviado',
        AwaitingUnblock: 'En espera de desbloqueo',
        Blocked: 'Obstruido',
        Canceled: 'Cancelado',
        Card: 'Tarjeta',
        Check: 'Controlar',
        Created: 'Creado',
        Error: 'Erro',
        Fraud: 'Fraude',
        InStock: 'En stock',
        InvoiceReceived: 'Factura recebida',
        Paid: 'Pagado',
        PayableCommercialAgent: 'A recibir',
        Payable: 'Para pagar',
        Processed: 'Procesada',
        ProcessedWithErrors: 'Procesado con errores',
        Processing: 'Procesando',
        Queued: 'En fila',
        Received: 'Recibió',
        Rejected: 'Rechazado',
        Settled: 'Establecido',
        Transfer: 'Transferir',
        Voucher: 'Vale',
        Pause: 'En pausa',
        growing: 'Creciente',
        descending: 'Descendente',
        all: 'Todos',
        search: 'Búsqueda',
        VinculationError: 'Error de enlace',
      },
      validation: {
        cpf: 'Ingrese un CPF válido de 11 dígitos',
        email: 'El campo debe ser un correo electrónico válido',
        cnpj: 'Ingrese un CNPJ válido de 14 dígitos',
      },
      tutorials: {
        label: 'Tutoriales',
        btnNew: 'Cargar Tutorial',
        new: {
          label: 'Tutoriales',
          btnNew: 'Contenido nuevo',
          table: {
            title: 'Título',
            description: 'Descripción',
            midia: 'Medios',
            status: 'Estado',
          },
          new: {
            label: 'Contenido nuevo',
            title: 'Título',
            description: 'Descripción',
            midia: 'URL de video',
            btnCancel: 'Atrás',
            btnSalve: 'Guardar',
            msgSalve: 'Contenido guardado con éxito',
            msgEdit: 'Tutorial editado con éxito',
            btnEdit: 'Editar',
            btnDelete: 'Eliminar',
            btnVisible: 'Publicado',
            btnHide: 'Oculto',
          },
        },
      },
    },
  },
  fr: {
    translation: {
      Welcome: 'Bienvenue à',
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem('language') || 'pt', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
