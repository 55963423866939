/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-undef */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  Grid,
  TextField,
  Checkbox,
  FormGroup,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormControl,
} from '@material-ui/core';
import { makeStyles, styled } from '@material-ui/core/styles';
import styles from '../../styles/Layout.module.css';
import routing from '../../service/routes/routing';

import Loading from '../../components/loading/Loading';
import MyButton from '../../components/button/MyButton';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: 10,
    margin: 0,
  },
  chip: {
    margin: 10,
  },
  myTextField: {
    backgroundColor: 'none',
    boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
    border: 'none',
    color: '#717171',
    fontWeight: 'bold',
    fontFamily: 'Open Sans',
  },
  text: {
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
    color: '#717171',
  },
  input: {
    backgroundColor: '#fff',
    marginTop: 0,
    // color: 'white',
  },
  label: {
    fontSize: '12px',
  },
}));

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark'
        ? 'rgba(57,75,89,.5)'
        : 'rgba(206,217,224,.5)',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#005D9F',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#005D9F',
  },
});

export default function ContractElement() {
  const history = useHistory();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [value, setValue] = useState('60');

  const handleChange = event => {
    setValue(event.target.value);
  };

  return (
    <div className="col-sm-12" style={{ marginTop: 50 }}>
      <Loading open={loading} msg="Buscando pedidos..." />
      <div
        className="col-sm-12"
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'start',
        }}
      >
        <div>
          <p
            className={styles.textMenu}
            onKeyDown={() => history.push(routing.fidelitydashboard)}
            onClick={() => history.push(routing.fidelitydashboard)}
          >
            {t('Order.initialMenu')}
          </p>
        </div>
      </div>
      <div
        className="col-sm-12"
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <h4 className={styles.textTitle}>Contrate Element-B</h4>
      </div>
      <Grid
        container
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ textAlign: 'start', width: '48%' }}>
          <p className={styles.textTitle}>Dados da concessionária</p>
          <Grid container spacing={2}>
            <Grid item xs={10} md={5} style={{ marginTop: 25 }}>
              <label className={styles.textTitle}>Razão Social</label>
              <TextField
                color="secondary"
                width="400px"
                className={classes.textField}
                fullWidth
                size="small"
                style={{
                  maxHeight: 10,
                  marginLeft: '50px',
                  marginTop: '5px',
                }}
                variant="outlined"
                InputProps={{
                  className: classes.input,
                }}
              />
            </Grid>

            <Grid item xs={10} md={5} style={{ marginTop: 25 }}>
              <label className={styles.textTitle}>CNPJ</label>
              <TextField
                color="secondary"
                width="400px"
                className={classes.textField}
                fullWidth
                size="small"
                style={{
                  maxHeight: 10,
                  marginLeft: '50px',
                  marginTop: '5px',
                }}
                variant="outlined"
                InputProps={{
                  className: classes.input,
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={10} md={5} style={{ marginTop: 25 }}>
              <label className={styles.textTitle}>E-mail</label>
              <TextField
                color="secondary"
                width="400px"
                className={classes.textField}
                fullWidth
                size="small"
                style={{
                  maxHeight: 10,
                  marginLeft: '50px',
                  marginTop: '5px',
                }}
                variant="outlined"
                InputProps={{
                  className: classes.input,
                }}
              />
            </Grid>

            <Grid item xs={10} md={5} style={{ marginTop: 25 }}>
              <label className={styles.textTitle}>Telefone</label>
              <TextField
                color="secondary"
                width="400px"
                className={classes.textField}
                fullWidth
                size="small"
                style={{
                  maxHeight: 10,
                  marginLeft: '50px',
                  marginTop: '5px',
                }}
                variant="outlined"
                InputProps={{
                  className: classes.input,
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={10} md={5} style={{ marginTop: 25 }}>
              <label className={styles.textTitle}>CEP</label>
              <TextField
                color="secondary"
                width="400px"
                className={classes.textField}
                fullWidth
                size="small"
                style={{
                  maxHeight: 10,
                  marginLeft: '50px',
                  marginTop: '5px',
                }}
                variant="outlined"
                InputProps={{
                  className: classes.input,
                }}
              />
            </Grid>

            <Grid item xs={10} md={5} />
          </Grid>
        </div>
        <div style={{ width: '0.15rem', backgroundColor: '#005D9F' }} />
        <div style={{ textAlign: 'start', width: '48%' }}>
          <p className={styles.textTitle}>Dados dos gestor financeiro</p>
          <Grid container spacing={2}>
            <Grid item xs={10} md={5} style={{ marginTop: 25 }}>
              <label className={styles.textTitle}>Nome</label>
              <TextField
                color="secondary"
                width="400px"
                className={classes.textField}
                fullWidth
                size="small"
                style={{
                  maxHeight: 10,
                  marginLeft: '50px',
                  marginTop: '5px',
                }}
                variant="outlined"
                InputProps={{
                  className: classes.input,
                }}
              />
            </Grid>

            <Grid item xs={10} md={5} style={{ marginTop: 25 }}>
              <label className={styles.textTitle}>CPF</label>
              <TextField
                color="secondary"
                width="400px"
                className={classes.textField}
                fullWidth
                size="small"
                style={{
                  maxHeight: 10,
                  marginLeft: '50px',
                  marginTop: '5px',
                }}
                variant="outlined"
                InputProps={{
                  className: classes.input,
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={10} md={5} style={{ marginTop: 25 }}>
              <label className={styles.textTitle}>E-mail</label>
              <TextField
                color="secondary"
                width="400px"
                className={classes.textField}
                fullWidth
                size="small"
                style={{
                  maxHeight: 10,
                  marginLeft: '50px',
                  marginTop: '5px',
                }}
                variant="outlined"
                InputProps={{
                  className: classes.input,
                }}
              />
            </Grid>

            <Grid item xs={10} md={5} style={{ marginTop: 25 }}>
              <label className={styles.textTitle}>Telefone</label>
              <TextField
                color="secondary"
                width="400px"
                className={classes.textField}
                fullWidth
                size="small"
                style={{
                  maxHeight: 10,
                  marginLeft: '50px',
                  marginTop: '5px',
                }}
                variant="outlined"
                InputProps={{
                  className: classes.input,
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={10} md={5} style={{ marginTop: 25 }}>
              <label className={styles.textTitle}>CEP</label>
              <TextField
                color="secondary"
                width="400px"
                className={classes.textField}
                fullWidth
                size="small"
                style={{
                  maxHeight: 10,
                  marginLeft: '50px',
                  marginTop: '5px',
                }}
                variant="outlined"
                InputProps={{
                  className: classes.input,
                }}
              />
            </Grid>

            <Grid item xs={12} md={5} />
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={10} style={{ marginTop: 25 }}>
              <FormControl component="fieldset" style={{ marginLeft: 50 }}>
                <RadioGroup
                  row
                  aria-label="gender"
                  name="row-radio-buttons-group"
                  value={value}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    // className="myLabel"
                    value="30"
                    control={
                      <Radio
                        classes={{
                          root: classes.radio,
                          checked: classes.checked,
                        }}
                      />
                    }
                    label="Responsável do Grupo empresarial"
                  />
                  <FormControlLabel
                    // className="myLabel"
                    value="60"
                    control={
                      <Radio
                        classes={{
                          root: classes.radio,
                          checked: classes.checked,
                        }}
                      />
                    }
                    label="Responsável da unidade"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          {value === '60' && (
            <>
              <Grid container spacing={2}>
                <Grid item xs={10} md={5} style={{ marginTop: 25 }}>
                  <label className={styles.textTitle}>Razão Social</label>
                  <TextField
                    color="secondary"
                    width="400px"
                    className={classes.textField}
                    fullWidth
                    size="small"
                    style={{
                      maxHeight: 10,
                      marginLeft: '50px',
                      marginTop: '5px',
                    }}
                    variant="outlined"
                    InputProps={{
                      className: classes.input,
                    }}
                  />
                </Grid>

                <Grid item xs={10} md={5} style={{ marginTop: 25 }}>
                  <label className={styles.textTitle}>CNPJ</label>
                  <TextField
                    color="secondary"
                    width="400px"
                    className={classes.textField}
                    fullWidth
                    size="small"
                    style={{
                      maxHeight: 10,
                      marginLeft: '50px',
                      marginTop: '5px',
                    }}
                    variant="outlined"
                    InputProps={{
                      className: classes.input,
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={10} md={5} style={{ marginTop: 25 }}>
                  <label className={styles.textTitle}>E-mail</label>
                  <TextField
                    color="secondary"
                    width="400px"
                    className={classes.textField}
                    fullWidth
                    size="small"
                    style={{
                      maxHeight: 10,
                      marginLeft: '50px',
                      marginTop: '5px',
                    }}
                    variant="outlined"
                    InputProps={{
                      className: classes.input,
                    }}
                  />
                </Grid>

                <Grid item xs={10} md={5} style={{ marginTop: 25 }}>
                  <label className={styles.textTitle}>Telefone</label>
                  <TextField
                    color="secondary"
                    width="400px"
                    className={classes.textField}
                    fullWidth
                    size="small"
                    style={{
                      maxHeight: 10,
                      marginLeft: '50px',
                      marginTop: '5px',
                    }}
                    variant="outlined"
                    InputProps={{
                      className: classes.input,
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={10} md={5} style={{ marginTop: 25 }}>
                  <label className={styles.textTitle}>Celular</label>
                  <TextField
                    color="secondary"
                    width="400px"
                    className={classes.textField}
                    fullWidth
                    size="small"
                    style={{
                      maxHeight: 10,
                      marginLeft: '50px',
                      marginTop: '5px',
                    }}
                    variant="outlined"
                    InputProps={{
                      className: classes.input,
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={5} />
              </Grid>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={10}
                  style={{ display: 'flex', alignItems: 'end', marginTop: 25 }}
                >
                  <FormGroup style={{ marginLeft: 50 }}>
                    <FormControlLabel
                      classes={{
                        label: classes.label, // Pass your override css here
                      }}
                      // className="myLabel"
                      // style={{ fontSize: 14 }}
                      control={
                        <Checkbox
                          // sx={{
                          //   '&:hover': { bgcolor: 'transparent' },
                          // }}
                          disableRipple
                          color="default"
                          checkedIcon={<BpCheckedIcon />}
                          icon={<BpIcon />}
                          inputProps={{ 'aria-label': 'Checkbox demo' }}
                        />
                      }
                      label="Autorizo contato telefônico ou por e-mail para avaliarcontratação"
                    />
                  </FormGroup>
                </Grid>
              </Grid>
            </>
          )}
        </div>

        <div style={{ textAlign: 'start', width: '10%' }} />
        <div
          style={{
            width: '80%',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '2rem',
          }}
        >
          <MyButton
            style={{ backgroundColor: '#005D9F' }}
            label="Solicitar contato"
          />
        </div>
        <div style={{ textAlign: 'start', width: '10%' }} />
      </Grid>
    </div>
  );
}
