/* eslint-disable react/no-array-index-key */
/* eslint-disable no-await-in-loop */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/function-component-definition */
import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';

import Select from 'react-select';
import { Pagination } from '@material-ui/lab';
import {
  Grid,
  Box,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableHead,
  Paper,
  TableCell,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import api from '../../../service/axios/api';
import FlashContext from '../../contexts/FlashContext';
import routing from '../../../service/routes/routing';

import SelectOptions from './SelectOptions1';

import './tableAccess.css';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#005D9F',
    fontFamily: 'Open Sans',
    color: theme.palette.common.white,
  },
  body: {
    color: '#717171',
    fontFamily: 'Open Sans',
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:hover': {
      backgroundColor: '#41b490',
      color: '#41b490',
    },
  },
}))(TableRow);

const options = [
  {
    label: '25',
    value: 25,
  },
  {
    label: '50',
    value: 50,
  },
  {
    label: '100',
    value: 100,
  },
];

const EnhancedTableHead = props => {
  return (
    <TableHead>
      <TableRow>
        <StyledTableCell component="th">CPF</StyledTableCell>
        <StyledTableCell component="th">NOME</StyledTableCell>
        <StyledTableCell component="th">PONTUAÇÃO ATUAL</StyledTableCell>
        <StyledTableCell component="th" align="right">
          ÚLTIMO ACESSO
        </StyledTableCell>
      </TableRow>
    </TableHead>
  );
};

export default function TableAccess({
  orders,
  totalPages,
  setPage,
  page,
  itens,
  setItens,
  setStatus,
  product,
  setProduct,
  setOrderBy,
  chipData,
  setChipData,
  find,
}) {
  const { t } = useTranslation();
  const [selected, setSelected] = React.useState([]);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const listOrder = [];
    orders.forEach(or => {
      listOrder.push({
        cpf: or.cpf,
        name: or.name,
        score: new Intl.NumberFormat('pt-BR').format(or.score).toString(),
        lastLogin: moment
          .utc(or.lastLogin)
          .tz('America/Sao_Paulo')
          .format('DD/MM/yyyy HH:mm'),
      });
    });
    setRows(listOrder);
  }, [orders]);

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setSelected([]);
    setPage(newPage);
  };

  return (
    <>
      <SelectOptions
        setStatus={setStatus}
        setChipData={setChipData}
        chipData={chipData}
        find={find}
        setOrderBy={setOrderBy}
        product={product}
        setProduct={setProduct}
        setSelectec={setSelected}
      />
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size="medium"
            >
              <EnhancedTableHead
                numSelected={selected.length}
                onSelectAllClick={handleSelectAllClick}
                rowCount={rows.length}
                // handleMenu={handleMenu}
              />
              <TableBody>
                {rows ? (
                  rows.length > 0 ? (
                    rows.map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <StyledTableRow tabIndex={-1} key={index}>
                          <StyledTableCell>{row.cpf}</StyledTableCell>
                          <StyledTableCell
                            component="th"
                            id={labelId}
                            scope="row"
                          >
                            {row.name}
                          </StyledTableCell>
                          <StyledTableCell>{row.score}</StyledTableCell>
                          <StyledTableCell align="right">
                            {row.lastLogin}
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })
                  ) : (
                    <StyledTableRow>
                      <StyledTableCell colSpan={9} align="center">
                        {t('Order.table.noResult')}
                      </StyledTableCell>
                    </StyledTableRow>
                  )
                ) : (
                  <StyledTableRow>
                    <StyledTableCell colSpan={9} align="center" />
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
      <Grid
        container
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: 15,
        }}
        spacing={2}
        direction="row"
      >
        <Grid item>
          <Select
            className="basic-single"
            classNamePrefix="select"
            placeholder="Itens"
            value={itens}
            onChange={setItens}
            options={options}
          />
        </Grid>
        <Grid item>
          <Pagination
            size="large"
            count={totalPages}
            page={page}
            onChange={handleChangePage}
            variant="outlined"
            shape="rounded"
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        style={{
          justifyContent: 'end',
          padding: 40,
        }}
      />
    </>
  );
}
