/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */

import React, { useState, useEffect, useRef, useContext } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import {
  IconButton,
  styled,
  Button,
  TablePagination,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Paper,
  Dialog,
  // DialogTitle,
  DialogActions,
  Grid,
  Checkbox,
} from '@material-ui/core';
import _ from 'lodash';
import Select from 'react-select';
// import { useHistory } from 'react-router-dom';

import moment from 'moment-timezone';
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { saveAs } from 'file-saver';
import Loading from '../../components/loading/Loading';
import FlashContext from '../../components/contexts/FlashContext';
// import VisibilityIcon from '@material-ui/icons/Visibility';
// import { set } from 'react-hook-form';
import api from '../../service/axios/api';
// import routing from '../../service/routes/routing';
// import Loading from '../../components/loading/Loading';

// eslint-disable-next-line no-unused-vars
import Status from '../../service/translateStatus';

function createData(
  id,
  nf,
  nfLink,
  nd,
  ndLink,
  user,
  product,
  paymentDate,
  faturado,
  client,
  cpf,
  value,
  status,
  all,
) {
  return {
    id,
    nd,
    ndLink,
    cpf,
    status,
    user,
    nf,
    nfLink,
    product,
    process,
    paymentDate,
    faturado,
    client,
    value,
    all,
  };
}

const bankOpt = [
  {
    label: 'Acesso',
    value: '000',
  },
  {
    label: 'Santander',
    value: '033',
  },
  {
    label: 'Bradesco',
    value: '237',
  },
  {
    label: 'Itau',
    value: '341',
  },
];

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark'
        ? 'rgba(57,75,89,.5)'
        : 'rgba(206,217,224,.5)',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#137cbd',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#106ba3',
  },
});

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  table: {
    minWidth: 1100,
  },
});

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#005D9F',
    fontFamily: 'Open Sans',
    color: theme.palette.common.white,
  },
  body: {
    color: '#717171',
    fontFamily: 'Open Sans',
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:hover': {
      backgroundColor: '#41b490',
      color: '#41b490',
    },
  },
}))(TableRow);

const customStyles1 = {
  control: provided => ({
    ...provided,
    width: 140,
    height: 40,
    borderRadius: 10,
    textAlign: 'start',
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  option: provided => ({
    ...provided,
  }),
};

// eslint-disable-next-line no-unused-vars
export default function ReleaseMoneyTable({
  docs,
  setDisabled,
  open,
  setOpen,
  page,
  setPage,
  itens,
  setItens,
  total,
  selected,
  setSelected,
  search,
}) {
  const { notifyError, notifySuccess } = useContext(FlashContext);
  const classes = useStyles();
  const { t } = useTranslation();
  // const history = useHistory();
  // const [open, setOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const fileC = useRef(null);
  const fileB = useRef(null);
  const fileI = useRef(null);
  const fileS = useRef(null);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState('');
  // const [selected, setSelected] = useState([]);
  const isSelected = id =>
    selected.indexOf(selected.find(e => e.id === id)) !== -1;

  const [cargas, setCargas] = useState([]);
  const [fileUpload, setFileUpload] = useState();
  const [allSelected, setAllSelected] = useState(false);

  const [bAcesso, setBAcesso] = useState({ label: 'Acesso', value: '000' });
  const [bSantander, setBSantander] = useState({
    label: 'Santander',
    value: '033',
  });
  const [bBradesco, setBBradesco] = useState({
    label: 'Bradesco',
    value: '237',
  });
  const [bItau, setBItau] = useState({ label: 'Itaú', value: '341' });

  useEffect(() => {
    const list = [];
    docs.forEach(d => {
      list.push(
        createData(
          d.id,
          d.nf,
          d.nf_link,
          d.nd,
          d.nd_link,
          d.user,
          d.product,
          d.date_payment,
          d.Billed,
          d.name,
          d.cpf,
          d.valor,
          d.status,
          d,
        ),
      );
    });
    setRows(list);
  }, [docs]);

  useEffect(() => {
    if (fileUpload) {
      setMsg(t('releaseMoney.releaseTable.sendMsg'));
      setLoading(true);
      api.paymentReleases
        .reconcilie(fileUpload.file, fileUpload.bank)
        .then(() => {
          notifySuccess(t('releaseMoney.releaseTable.sendSuccessMsg'));
        })
        .catch(err => {
          notifyError(err.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [fileUpload]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setItens(event.target.value);
  };

  const closeDetails = () => {
    setOpen(false);
    fileC.current.file = null;
    fileB.current.file = null;
    fileI.current.file = null;
    fileS.current.file = null;
    setFileUpload(null);
  };

  const handleClick = (event, id, vlr, bank) => {
    const selectedIndex = selected.indexOf(selected.find(e => e.id === id));
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, { id, vlr, bank });
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
    if (newSelected.length === 0) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };

  const handleSelectAll = () => {
    if (allSelected) {
      setSelected([]);
      setAllSelected(false);
      setDisabled(true);
    } else {
      const allItens = [];
      rows.forEach(item => {
        const find = selected.find(s => s.id === item.id);
        if (!find) {
          allItens.push({
            id: item?.id,
            vlr: item?.value,
            bank: item?.all?.bank_number,
          });
        }
      });
      setSelected([...selected, ...allItens]);
      setAllSelected(true);
      setDisabled(false);
    }
  };

  function atualBank(bk) {
    switch (bk) {
      case '000':
        return bAcesso.value;
      case '033':
        return bSantander.value;
      case '237':
        return bBradesco.value;
      case '341':
        return bItau.value;
      default:
        return bAcesso.value;
    }
  }

  const getMyfile = bank => {
    // let myBank = '000';
    // console.log(bank);
    setMsg(t('releaseMoney.releaseTable.createFile'));
    setLoading(true);
    const ord = [];
    selected.forEach(e => {
      if (e.bank === bank || !e.bank) {
        ord.push(e.id);
      } else if (
        bank === '033' &&
        e.bank !== '000' &&
        e.bank !== '237' &&
        e.bank !== '341'
      ) {
        ord.push(e.id);
      }
    });

    const orderItems = JSON.stringify(ord);

    api.paymentReleases
      .getFile(orderItems, atualBank(bank))
      .then(res => {
        notifySuccess('Arquivo gerado com sucesso.');
        const url = res.retorno.linkToDownload;
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'carga_template'); // or any other extension
        link.download = 'carga_template';
        document.body.appendChild(link);
        link.click();
      })
      .catch(error => {
        if (error.message) {
          notifyError(error.message);
        } else {
          const myError = error?.toJSON();
          if (myError?.code === 'ECONNABORTED') {
            notifyError('Tempo excedido. Baixe o arquivo na área ADM.');
          } else {
            notifyError(error.message);
          }
        }
      })
      .finally(() => setLoading(false));
  };

  const getMyfileUser = () => {
    setMsg('Solicitando liberação...');
    setLoading(true);
    const ord = [];
    selected.forEach(e => {
      ord.push(e.id);
    });
    const orderItems = JSON.stringify(ord);
    api.paymentReleases
      .getFile(orderItems, '000')
      .then(res => {
        notifySuccess(res.message);
        search();
        setOpen(false);
      })
      .catch(error => {
        notifyError(error.message);
        console.log(error);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    let vlrC = 0;
    let countC = 0;
    let vlrB = 0;
    let countB = 0;
    let vlrI = 0;
    let countI = 0;
    let vlrS = 0;
    let countS = 0;
    const bnk = [];

    selected.forEach(el => {
      if (el.bank) {
        switch (el.bank) {
          case '341':
            vlrI += Number(el.vlr);
            countI += 1;
            bnk.push(el.bank);
            break;
          case '237':
            vlrB += Number(el.vlr);
            countB += 1;
            bnk.push(el.bank);
            break;
          case '033':
            vlrS += Number(el.vlr);
            countS += 1;
            bnk.push(el.bank);
            break;
          case '000':
            vlrC += Number(el.vlr);
            countC += 1;
            bnk.push('ACE');
            break;
          default:
            vlrS += Number(el.vlr);
            countS += 1;
            bnk.push('033');
          // vlrC += Number(el.vlr);
          // countC += 1;
          // bnk.push('ACE');
        }
      } else {
        vlrC += Number(el.vlr);
        countC += 1;
        bnk.push('ACE');
      }
    });
    setCargas([
      {
        id: 1,
        bank: 'ACE - Acesso',
        qtd: countC,
        vlr: `R$${vlrC.toFixed(2)}`,
      },
      {
        id: 2,
        bank: '033 - Banco Santander',
        qtd: countS,
        vlr: `R$${vlrS.toFixed(2)}`,
      },
      {
        id: 3,
        bank: '237 - Banco Bradesco',
        qtd: countB,
        vlr: `R$${vlrB.toFixed(2)}`,
      },
      {
        id: 4,
        bank: '341 - Banco Itau',
        qtd: countI,
        vlr: `R$${vlrI.toFixed(2)}`,
      },
    ]);
  }, [selected]);

  const upMyFile = bank => {
    switch (bank) {
      case '341 - Banco Itau':
        fileI.current.click();
        break;
      case '237 - Banco Bradesco':
        fileB.current.click();
        break;
      case '033 - Banco Santander':
        fileS.current.click();
        break;
      default:
        fileC.current.click();
    }
  };

  const setMyfile = (file, id, bank) => {
    const intern = _.cloneDeep(cargas);
    intern[id].file = file;
    intern[id].fileName = file.name;
    setCargas(intern);
    setFileUpload({ file, bank });
  };

  const cleanFile = dt => {
    switch (dt.bank) {
      case 'ACE - Acesso':
        fileC.current.file = null;
        break;
      case '237 - Banco Bradesco':
        fileB.current.file = null;
        break;
      case '341 - Banco Itau':
        fileI.current.file = null;
        break;
      default:
        fileS.current.file = null;
    }
    // eslint-disable-next-line no-param-reassign
    dt.file = null;
    setFileUpload(null);
  };

  const valueTotal = all => {
    let tot = 0.0;
    all.forEach(value => {
      tot += Number(value.vlr);
    });
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(tot);
  };

  return (
    <>
      <Paper className={classes.root}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell
                  align="left"
                  style={{ width: '20px', padding: '0px' }}
                >
                  <Checkbox
                    checkedIcon={<BpCheckedIcon />}
                    icon={<BpIcon />}
                    indeterminate={
                      selected.length > 0 && rows.length > selected.length
                    }
                    checked={allSelected}
                    onChange={handleSelectAll}
                    inputProps={{
                      'aria-label': 'select all desserts',
                    }}
                  />
                </StyledTableCell>
                {/* {api.currentUser?.user?.role?.id !== '3' && ( */}
                <>
                  <StyledTableCell align="left">
                    {t('releaseMoney.releaseTable.nf')}
                  </StyledTableCell>
                  <StyledTableCell>
                    {t('releaseMoney.releaseTable.nd')}
                  </StyledTableCell>
                </>
                {/* // )} */}
                {/* <StyledTableCell>Usuário</StyledTableCell> */}
                <StyledTableCell>
                  {t('releaseMoney.releaseTable.product')}
                </StyledTableCell>
                <StyledTableCell>
                  {t('releaseMoney.releaseTable.payment')}
                </StyledTableCell>
                <StyledTableCell>
                  {t('releaseMoney.releaseTable.bearers')}
                </StyledTableCell>
                <StyledTableCell>
                  {t('releaseMoney.releaseTable.invoiceFrom')}
                </StyledTableCell>
                <StyledTableCell>
                  {t('releaseMoney.releaseTable.cpf')}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {t('releaseMoney.releaseTable.value')}
                </StyledTableCell>
                {/* <StyledTableCell align="right">Status</StyledTableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, i) => {
                const isItemSelected = isSelected(row.id);

                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <StyledTableRow key={i}>
                    <StyledTableCell
                      align="left"
                      style={{ width: '20px', padding: '0px' }}
                    >
                      <Checkbox
                        color="primary"
                        onClick={event =>
                          handleClick(
                            event,
                            row.id,
                            row.value,
                            row.all.bank_number,
                          )
                        }
                        checked={isItemSelected}
                        // inputProps={{
                        //   'aria-labelledby': labelId,
                        // }}
                      />
                    </StyledTableCell>
                    {/* {api.currentUser?.user?.role?.id !== '3' && ( */}
                    <>
                      <StyledTableCell
                        align="left"
                        style={{ cursor: row.nfLink ? 'pointer' : '' }}
                        onClick={() => row.nfLink && window.open(row.nfLink)}
                      >
                        {row.nf}
                      </StyledTableCell>
                      <StyledTableCell
                        align="left"
                        style={{ cursor: row.ndLink ? 'pointer' : '' }}
                        onClick={() => row.ndLink && window.open(row.ndLink)}
                      >
                        {row.nd}
                      </StyledTableCell>
                    </>
                    {/* )} */}
                    {/* <StyledTableCell>{row.user}</StyledTableCell> */}
                    <StyledTableCell>
                      {row.product === 'Card' ? 'Cartão' : 'Transferência'}
                    </StyledTableCell>
                    <StyledTableCell>
                      {row.paymentDate &&
                        moment.utc(row.paymentDate).format('DD/MM/yyyy')}
                    </StyledTableCell>
                    <StyledTableCell>{row.client}</StyledTableCell>
                    <StyledTableCell>{row.faturado}</StyledTableCell>
                    <StyledTableCell>{row.cpf}</StyledTableCell>
                    <StyledTableCell align="right">
                      {row.value && `R$${row.value}`}
                    </StyledTableCell>
                    {/* <StyledTableCell align="right">
                      {Status(row.status)}
                    </StyledTableCell> */}
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100, 1000]}
          component="div"
          count={total}
          rowsPerPage={itens}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>

      <Dialog open={open} fullWidth maxWidth="lg">
        <Loading open={loading} msg={msg} />
        <Grid
          container
          spacing={1}
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            padding: 20,
          }}
        >
          {api.currentUser?.user?.role?.id !== '3' ? (
            <Grid item xs={12} sm={12}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>
                        {t('releaseMoney.releaseTable.dialog.providers')}
                      </StyledTableCell>
                      <StyledTableCell>
                        {t('releaseMoney.releaseTable.dialog.countCharger')}
                      </StyledTableCell>
                      <StyledTableCell>
                        {t('releaseMoney.releaseTable.dialog.value')}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {t('releaseMoney.releaseTable.dialog.downloadMsg')}
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {cargas.map((row, i) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <StyledTableRow key={i}>
                        <StyledTableCell>{row.bank}</StyledTableCell>
                        <StyledTableCell>{row.qtd}</StyledTableCell>
                        <StyledTableCell>{row.vlr}</StyledTableCell>
                        {i === 0 ? (
                          <StyledTableCell align="center">
                            <div
                              style={{ display: 'flex', justifyContent: 'end' }}
                            >
                              <Select
                                styles={customStyles1}
                                className="basic-single"
                                classNamePrefix="select"
                                placeholder="Banco"
                                // defaultValue={{ label: 'Acesso', value: '000' }}
                                value={bAcesso}
                                onChange={setBAcesso}
                                menuPortalTarget={document.body}
                                options={bankOpt}
                              />
                              <IconButton
                                size="small"
                                // disabled={!bnk.find(e => e === 'ACE')}
                              >
                                <GetAppIcon
                                  style={{ cursor: 'not-pointer' }}
                                  onClick={() => getMyfile('000')}
                                />
                              </IconButton>
                            </div>
                          </StyledTableCell>
                        ) : i === 1 ? (
                          <StyledTableCell align="center">
                            <div
                              style={{ display: 'flex', justifyContent: 'end' }}
                            >
                              <Select
                                styles={customStyles1}
                                className="basic-single"
                                classNamePrefix="select"
                                placeholder={t(
                                  'releaseMoney.releaseTable.dialog.bank',
                                )}
                                value={bSantander}
                                onChange={setBSantander}
                                menuPortalTarget={document.body}
                                options={bankOpt}
                              />
                              <IconButton size="small">
                                <GetAppIcon
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => getMyfile('033')}
                                />
                              </IconButton>
                            </div>
                          </StyledTableCell>
                        ) : i === 2 ? (
                          <StyledTableCell align="center">
                            <div
                              style={{ display: 'flex', justifyContent: 'end' }}
                            >
                              <Select
                                styles={customStyles1}
                                className="basic-single"
                                classNamePrefix="select"
                                placeholder={t(
                                  'releaseMoney.releaseTable.dialog.bank',
                                )}
                                value={bBradesco}
                                onChange={setBBradesco}
                                menuPortalTarget={document.body}
                                options={bankOpt}
                              />
                              <IconButton size="small">
                                <GetAppIcon
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => getMyfile('237')}
                                />
                              </IconButton>
                            </div>
                          </StyledTableCell>
                        ) : (
                          <StyledTableCell align="center">
                            <div
                              style={{ display: 'flex', justifyContent: 'end' }}
                            >
                              <Select
                                styles={customStyles1}
                                className="basic-single"
                                classNamePrefix="select"
                                placeholder={t(
                                  'releaseMoney.releaseTable.dialog.bank',
                                )}
                                value={bItau}
                                onChange={setBItau}
                                menuPortalTarget={document.body}
                                options={bankOpt}
                              />
                              <IconButton size="small">
                                <GetAppIcon
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => getMyfile('341')}
                                />
                              </IconButton>
                            </div>
                          </StyledTableCell>
                        )}
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          ) : (
            <Grid item xs={12} sm={12}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>
                        {/* {t('releaseMoney.releaseTable.dialog.providers')} */}
                        Quantidade de itens selecionados
                      </StyledTableCell>
                      <StyledTableCell>
                        {/* {t('releaseMoney.releaseTable.dialog.countCharger')} */}
                        Valor total da liberação
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell>{selected.length}</StyledTableCell>
                      <StyledTableCell>{valueTotal(selected)}</StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}
        </Grid>
        <DialogActions>
          {api.currentUser?.user?.role?.id === '3' && (
            <Button
              onClick={closeDetails}
              type="submit"
              color="primary"
              variant="contained"
              autoFocus
            >
              Voltar
            </Button>
          )}
          <Button
            onClick={
              api.currentUser?.user?.role?.id !== '3'
                ? closeDetails
                : getMyfileUser
            }
            type="submit"
            color="primary"
            variant="contained"
            autoFocus
          >
            {api.currentUser?.user?.role?.id !== '3'
              ? t('releaseMoney.releaseTable.dialog.btnOk')
              : 'Liberar'}
          </Button>
        </DialogActions>
        <Grid item style={{ display: 'flex', height: '1px' }}>
          <input
            ref={fileC}
            type="file"
            style={{
              opacity: 0,
            }}
            onChange={() => setMyfile(fileC.current.files[0], 0, 'ACE')}
            className="form-control"
            id="inputNumSerie"
          />
          <input
            ref={fileS}
            type="file"
            style={{
              opacity: 0,
            }}
            onChange={() => setMyfile(fileS.current.files[0], 1, '033')}
            className="form-control"
            id="inputNumSerie"
          />
          <input
            ref={fileB}
            type="file"
            style={{
              opacity: 0,
            }}
            onChange={() => setMyfile(fileB.current.files[0], 2, '237')}
            className="form-control"
            id="inputNumSerie"
          />
          <input
            ref={fileI}
            type="file"
            style={{
              opacity: 0,
            }}
            onChange={() => setMyfile(fileI.current.files[0], 3, '341')}
            className="form-control"
            id="inputNumSerie"
          />
        </Grid>
      </Dialog>
    </>
  );
}
