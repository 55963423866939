/* eslint-disable react/prop-types */
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import isAuthenticated from './auth';
import api from '../axios/api';

const PrivateRoute = function ({ ...props }) {
  const authenticated = isAuthenticated();
  const myRole = api.currentUser?.user?.role?.name;
  const canAccess = props?.role?.indexOf(myRole);
  // console.log(props, myRole, canAccess);
  if (authenticated && canAccess > -1) {
    return <Route {...props} />;
  }
  return (
    <Redirect
      to={{
        pathname: '/login',
        // state: { from: location },
      }}
    />
  );
};

export default PrivateRoute;
