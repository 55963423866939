import React from 'react';
import { Router } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import history from './service/routes/history';
import MyRoutes from './service/routes/Routes';
import theme from './theme';

import { FlashProvider } from './components/contexts/FlashContext';

import './App.css';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <FlashProvider>
        <CssBaseline />
        <Router history={history}>
          <MyRoutes />
        </Router>
      </FlashProvider>
    </ThemeProvider>
  );
}

export default App;
