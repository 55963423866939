import { string, object, boolean } from 'yup';

const DeliveryAddressSchema = object().shape({
  postalCode: string().required('Campo obrigatório'),
  street: string().required('Campo obrigatório'),
  number: string().required('Campo obrigatório'),
  complement: string().nullable().notRequired(),
  district: string().required('Campo obrigatório'),
  city: string().required('Campo obrigatório'),
  state: string().required('Campo obrigatório'),
  receiver: string().required('Campo obrigatório'),
  deliveryAgent: string().required('Campo obrigatório'),
  isCentralizedDelivery: boolean().required('Campo obrigatório'),
  deliveryService: string().required('Campo obrigatório'),
});

// export type DeliveryAddress = TypeOf<typeof DeliveryAddressSchema>

export default DeliveryAddressSchema;
