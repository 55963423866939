/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/prop-types */
import React from 'react';
import { Typography, Box, Grid, Link } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';
import AttachFile from '@material-ui/icons/AttachFile';
import { Video } from '../../../../components/Video';

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      width: '90%',
      marginTop: theme.spacing(5),
      backgroundColor: '#FFF',
      margin: 'auto',
      boxShadow: theme.shadows[5],
      padding: '15px',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  }),
);

export function TutorialModal({ tutorial }) {
  const classes = useStyles();
  return (
    <div>
      <Box display="flex" className={classes.container} flexDirection="column">
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6" component="h6">
              {tutorial?.section}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h5"
              component="h5"
              style={{ color: '#2898FF' }}
            >
              {tutorial?.title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <div style={{ minHeight: '500px', margin: '10px 0' }}>
              <Video url={tutorial?.media} />
            </div>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">{tutorial?.subject}</Typography>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default TutorialModal;
