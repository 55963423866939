import { operatorFees, companyOperatingFees } from './productDetails';

// MASTERCARD
const operatorFeesMastercard = {
  ...operatorFees,
  // only for cards
  monthlyFee: 'R$ 4,90',
  unlockFee: 'R$ 10,00',
  reissueFee: 'R$ 10,00',
  chargebackFee: 'R$ 10,00',
  atmWithdrawFee: 'R$ 9,00',
  markupRate: '11,38',
  rechargePortalFee: 'R$ 0,00',
  rechargeInvoiceFee: 'R$ 0,00',
  p2pTransferFee: 'R$ 9,75',
  // only for transfers
  transferFee: 'R$ 0,00',
  rejectedTransactionFee: 'R$ 0,00',
  // only for checks
  checkFee: 'R$ 0,00',
  // only for vouchers
  voucherFee: 'R$ 0,00',
  taxa: '0,00',
  price: 'R$ 8,50',
};

const companyOperatingFeesMastercard = {
  ...companyOperatingFees,
  balanceTransferFee: 'R$ 4,50',
  minimumLoadAmount: 'R$ 50,00',
  belowMinimumLoadFee: 'R$ 4,50',
  emergencyLoadFee: 'R$ 4,50',
  specialHandlingFee: 'R$ 5,00',
  chargebackRate: '2,00',
};

// VISA
const operatorFeesVisa = {
  ...operatorFees,
  // only for cards
  monthlyFee: 'R$ 5,00',
  unlockFee: 'R$ 10,00',
  reissueFee: 'R$ 10,00',
  chargebackFee: 'R$ 0,00',
  atmWithdrawFee: 'R$ 9,00',
  markupRate: '11,38',
  rechargePortalFee: 'R$ 0,00',
  rechargeInvoiceFee: 'R$ 0,00',
  p2pTransferFee: 'R$ 9,75',
  // only for transfers
  transferFee: 'R$ 0,00',
  rejectedTransactionFee: 'R$ 0,00',
  // only for checks
  checkFee: 'R$ 0,00',
  // only for vouchers
  voucherFee: 'R$ 0,00',
  taxa: '2,00',
  price: 'R$ 8,50',
};

const companyOperatingFeesVisa = {
  ...companyOperatingFees,
  balanceTransferFee: 'R$ 4,50',
  minimumLoadAmount: 'R$ 100,00',
  belowMinimumLoadFee: 'R$ 4,50',
  emergencyLoadFee: 'R$ 4,50',
  specialHandlingFee: 'R$ 5,00',
  chargebackRate: '0,00',
};

const cardFees = {
  mastercard: {
    operatorFees: operatorFeesMastercard,
    companyOperatingFees: companyOperatingFeesMastercard,
  },
  visa: {
    operatorFees: operatorFeesVisa,
    companyOperatingFees: companyOperatingFeesVisa,
  },
  default: {
    operatorFees,
    companyOperatingFees,
  },
};

export default cardFees;
