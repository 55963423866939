/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types */
import React, { useContext, useState, useEffect } from 'react';
import { Paper } from '@material-ui/core';
import StepperContext, { StepperProvider } from '../../context/StepperContext';

import CompanyForm from './CompanyForm';
// import ProductDetailsForm from './ProductDetailsForm';
import ProposalReviewForm from './ProposalReviewForm';
// import defaultProposal from './defaultStates/proposal';
const defaultProposal = {
  tradingName: '',
  cnpj: '',
  companyName: '',
  status: '',
  email: '',
  phone: '',
  mobile: '',
  website: '',
  comments: '',
  campaign: { label: 'Campanha', value: null },
  contacts: [
    {
      name: '',
      cpf: '',
      role: '',
      phone: '',
      mobile: '',
      email: '',
      department: '',
    },
  ],
};

// id: dc.id,
//               tradingName: dc.trading_name,
//               companyCnpj: dc.cnpj,
//               companyName: dc.company_name,
//               status: dc.status,
//               createdAt: dc.created_at,
//               all: dc,

function StepForm({ proposal, setProposal, isPublic }) {
  const stepper = useContext(StepperContext);

  switch (stepper.step) {
    case 1:
      return (
        <CompanyForm
          company={proposal}
          setProposal={setProposal}
          isPublic={isPublic}
        />
      );
    default:
      return <ProposalReviewForm proposal={proposal} />;
  }
}

function ProposalForm({
  proposal = defaultProposal,
  disabled,
  onSuccess,
  code,
  titleModal,
  actionModal,
  isPublic,
  campaign,
}) {
  const [data, setData] = useState(proposal);
  function updateData(stepData) {
    if (stepData) {
      setData(prevData => {
        return { ...prevData, ...stepData };
      });
    }
  }

  useEffect(() => {
    if (campaign) {
      defaultProposal.campaign = {
        label: 'Campanha',
        value: campaign.toString(),
      };
      setData(defaultProposal);
    }
  }, []);

  return (
    <Paper elevation={4}>
      <StepperProvider
        disabled={disabled}
        onChange={updateData}
        onSuccess={onSuccess}
        code={code}
        titleModal={titleModal}
        actionModal={actionModal}
      >
        <StepForm proposal={data} setProposal={setData} isPublic={isPublic} />
      </StepperProvider>
    </Paper>
  );
}

export default ProposalForm;
