import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      width: '90%',
      marginTop: theme.spacing(5),
      backgroundColor: '#FFF',
      margin: 'auto',
      boxShadow: theme.shadows[5],
      padding: '15px',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    subtitle: {
      fontWeight: 700,
    },
  }),
);

export default useStyles;
