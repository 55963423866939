/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';

import { Container, Box } from '@material-ui/core';
// import {  } from '@mui/material/styles';
import {
  makeStyles,
  createStyles,
  // ThemeProvider,
} from '@material-ui/core/styles';
// import TextField from '@mui/material/TextField';
import ModelTypes from './ModelTypes';
import SelectDestination from './SelectDestination';
import EmailTextNotification from './EmailTextNotification';
// import Created from './Created';
import MyButton from '../../../components/button/MyButton';
// import Destination from './Destination';

// import Paper from '@mui/material/Paper';
// import Stepper from '@mui/material/Stepper';
// import Step from '@mui/material/Step';
// import StepLabel from '@mui/material/StepLabel';
// import { useTranslation } from 'react-i18next';
// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';

const useStyles = makeStyles(() =>
  createStyles({
    myLabel: {
      color: '#657588',
      fontWeight: 'bold',
      fontFamily: 'Open Sans',
      cursor: 'pointer',
    },
    myLabelFinal: {
      color: '#41b490',
      fontWeight: 'bold',
      fontFamily: 'Open Sans',
    },
    myDiv: {
      marginTop: 50,
      marginBottom: 50,
    },
    labelDiv: {
      fontFamily: 'Open Sans',
      color: '#657588',
    },
  }),
);

// const CssTextField = styled(TextField)({
//   color: '#657588',
//   '& label.Mui-focused': {
//     color: 'green',
//   },
//   '& .MuiInput-underline:after': {
//     borderBottomColor: 'green',
//   },
//   '& .MuiOutlinedInput-root': {
//     color: '#000',
//     '& fieldset': {
//       borderColor: '#F2F1F1',
//       // backgroundColor: '#F2F1F1',
//       color: '#000',
//     },
//     '&:hover fieldset': {
//       borderColor: '#F2F1F1',
//     },
//     '&.Mui-focused fieldset': {
//       borderColor: '#F2F1F1',
//     },
//   },
// });

const steps = ['Subject', 'Destination'];

export default function Saveds(props) {
  const { setOpenModal } = props;
  const [activeStep, setActiveStep] = useState(0);
  const [dest, setDest] = useState();
  const classes = useStyles();

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <ModelTypes />;
      // return <h1>ModelTypes</h1>;
      case 1:
        return <SelectDestination setDest={setDest} />;
      // return <h1>SelectDestination</h1>;
      default:
        throw new Error('Unknown step');
    }
  }
  return (
    // <ThemeProvider theme={theme}>
    //   <CssBaseline />
    <Container component="main">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'end',
          alignItems: 'center',
        }}
      >
        <p
          className="x"
          style={{ cursor: 'pointer' }}
          onClick={() => setOpenModal(false)}
        >
          X
        </p>
      </div>
      {/* {activeStep < 2 && <h3 className="text-checkout-inform" style={{ marginTop: -20 }}>
        {activeStep === 0 ? 'Criar notificação' : 'Destinatários'}</h3>} */}
      <>
        {activeStep === steps.length ? (
          <EmailTextNotification
            destination={dest}
            setOpenModal={setOpenModal}
          />
        ) : (
          // <h1> EMAIL</h1>
          // <EmailTextNotification
          //   destination={dest}
          //   setOpenModal={setOpenModal}
          // />
          <>
            {getStepContent(activeStep)}
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'end',
                alignItems: 'center',
                marginBottom: 20,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                {/* <MyButton
                    style={{
                      width: 150, height: 40, borderRadius: 10, backgroundColor: '#657588',
                    }}
                    onClick={handleBack}
                    label="Cancelar"
                  /> */}
                {activeStep < 1 ? (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <p
                      style={{ marginTop: 10 }}
                      className={classes.myLabel}
                      onClick={() => setOpenModal(false)}
                    >
                      Cancelar
                    </p>
                    <MyButton
                      style={{
                        width: 150,
                        height: 40,
                        borderRadius: 10,
                        marginLeft: 30,
                      }}
                      onClick={handleNext}
                      label="Criar notificação"
                    />
                  </div>
                ) : (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p
                      style={{ marginTop: 10 }}
                      className={classes.myLabel}
                      onClick={() => setOpenModal(false)}
                    >
                      Cancelar
                    </p>
                    <MyButton
                      style={{
                        width: 150,
                        height: 40,
                        borderRadius: 10,
                        marginLeft: 30,
                      }}
                      onClick={handleNext}
                      label="Confirmar"
                    />
                  </div>
                )}
              </Box>
            </div>
          </>
        )}
      </>
      {/* </Paper> */}
    </Container>
    // </ThemeProvider>
  );
}
