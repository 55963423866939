/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */

import React, { useState, useEffect, useRef, useContext } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import moment from 'moment-timezone';

import {
  TablePagination,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Paper,
  Tooltip,
} from '@material-ui/core';

import _ from 'lodash';
import { GetApp } from '@material-ui/icons';

function createData(
  invoice,
  order,
  billed,
  description,
  value,
  paymentDate,
  createdAt,
  bank,
  cnpj,
) {
  return {
    invoice,
    order,
    billed,
    description,
    value,
    paymentDate,
    createdAt,
    bank,
    cnpj,
  };
}

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  table: {
    minWidth: 1100,
  },
});

const StyledTableCell = withStyles(() => ({
  head: {
    // backgroundColor: theme.palette.common.black,
    // color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

// eslint-disable-next-line no-unused-vars
export default function BillingTable({
  docs,
  page,
  setPage,
  itens,
  setItens,
  total,
  download,
}) {
  const classes = useStyles();
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (docs) {
      const list = [];

      docs.forEach(d => {
        list.push(
          createData(
            d.invoice_number,
            d.order_number,
            d.billed,
            d.type,
            d.value,
            d.payment_at,
            d.created_at,
            d.bank,
            d.cnpj_element,
          ),
        );
      });
      setRows(list);
    }
  }, [docs]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setItens(event.target.value);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Nota</StyledTableCell>
              <StyledTableCell>Pedido</StyledTableCell>
              <StyledTableCell>CNPJ Element</StyledTableCell>
              <StyledTableCell>Emissão</StyledTableCell>
              <StyledTableCell>Recebimento</StyledTableCell>
              <StyledTableCell>Banco</StyledTableCell>

              <StyledTableCell>Valor</StyledTableCell>
              <StyledTableCell>Cliente</StyledTableCell>
              <StyledTableCell align="right">
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                  Descrição
                  <Tooltip title="Exportar para Excel" arrow>
                    <GetApp style={{ cursor: 'pointer' }} onClick={download} />
                  </Tooltip>
                </div>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => {
              return (
                <StyledTableRow key={row.createdAt}>
                  <StyledTableCell>{row.invoice}</StyledTableCell>
                  <StyledTableCell>{row.order}</StyledTableCell>
                  <StyledTableCell>{row.cnpj}</StyledTableCell>
                  <StyledTableCell align="right">
                    {moment(row.createdAt).format('DD/MM/YYYY')}
                  </StyledTableCell>
                  <StyledTableCell>
                    {row.paymentDate &&
                      moment(row.paymentDate).format('DD/MM/YYYY')}
                  </StyledTableCell>
                  <StyledTableCell>{row.bank}</StyledTableCell>
                  <StyledTableCell>
                    {new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })
                      .format(row.value)
                      .replace(',', '.')}
                  </StyledTableCell>
                  <StyledTableCell>{row.billed}</StyledTableCell>
                  <StyledTableCell>{row.description}</StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100, 1000, 50000]}
        component="div"
        count={total}
        rowsPerPage={itens}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
