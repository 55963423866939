/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useRef, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
} from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import locale from 'date-fns/locale/pt-BR';
import DateFnsUtils from '@date-io/date-fns';
import Status from '../../../service/translateStatus';

import api from '../../../service/axios/api';
import FlashContext from '../../../components/contexts/FlashContext';
import routing from '../../../service/routes/routing';
import styles from '../../../styles/Layout.module.css';
import MyButton from '../../../components/button/MyButton';
import TableContract from '../../../components/tables/contract/SalesInvoiceTableContract';

const useStyles = makeStyles(() =>
  createStyles({
    inputLabel: {
      backgroundColor: '#F2F1F1',
      // color: '#41b490',
      fontWeight: 'bold',
    },
    myTextField: {
      backgroundColor: '#none',
      boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
      border: 'none',
      // color: '#41b490',
      // fontWeight: 'bold',
      fontFamily: 'Open Sans',
    },
    text: {
      // backgroundColor: '#F2F1F1',
      fontFamily: 'Open Sans',
      // fontWeight: 'bold',
      // color: '#41b490',
    },
  }),
);

export default function UploadInvoice() {
  const history = useHistory();
  const classes = useStyles();
  const filePdf = useRef(null);
  const [myFile, setMyfile] = useState();
  const { notifyError } = useContext(FlashContext);
  const { t } = useTranslation();

  const [sendDate, setSendDate] = useState();
  const [amount, setAmount] = useState();
  const [invoiceNumber, setInvoiceNumber] = useState();
  const [emissionDate, setEmissionDate] = useState();

  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [itens, setItens] = useState({ label: '25', value: 25 });
  // eslint-disable-next-line no-unused-vars
  const [total, setTotal] = useState(0);
  const [statusSelected, setStatusSelected] = useState({
    label: 'Todos',
    value: '',
  });
  const [productSelected, setProductSelected] = useState({
    label: 'Todos',
    value: '',
  });

  const [rz, setRz] = useState();
  const [chipData, setChipData] = useState([]);

  const [openModal, setOpenModal] = useState(false);
  const search = () => {
    const cancel = new AbortController();
    api.commercialAgent
      .getInvoices(cancel, 1, 25)
      .then(res => {
        setTotal(res.data.pages);
        const list = [];
        res.data?.docs?.forEach(r => {
          list.push({
            id: r.id,
            rz: r.commercialAgent_name,
            nf: r.nf_number,
            nf_file: r.gcs_nf,
            created_at: r.created_at,
            payment_at: r.payed_at,
            value: r.amount,
            status: Status(r.status, t),
          });
        });
        setData(list);
      })
      .catch(err => {
        notifyError(err.message);
      });
  };

  useEffect(() => {
    search();
  }, []);

  const sendInvoice = () => {
    console.log(
      'Sending Invoice',
      invoiceNumber,
      amount,
      emissionDate,
      sendDate,
      rz,
    );
  };

  return (
    <>
      <div>
        <p
          className={styles.textMenu}
          onKeyDown={() => history.push(routing.salesDash)}
          onClick={() => history.push(routing.salesDash)}
        >
          Menu Inicial
        </p>
        <div
          className="col-sm-12"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
          }}
        >
          <h4 className={styles.textTitle}>Nota Fiscal</h4>
          <h6>Saldo aguardando nota para pagamento</h6>
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: 60,
              borderRadius: '8px',
              // padding: 5,
              color: '#fff',
              justifyContent: 'space-around',
              alignItems: 'center',
              // fontWeight: 'bold',
              backgroundColor: '#005D9F',
            }}
          >
            <h5 style={{ fontWeight: 'bold' }}>Crédito xxxxx</h5>
            <h5 style={{ fontWeight: 'bold' }}>Débito xxxxx</h5>
            <h5 style={{ fontWeight: 'bold' }}>Total xxxxx</h5>
          </div>
          <div
            style={{
              display: 'flex',
              marginTop: '2rem',
              width: '100%',
              justifyContent: 'space-around',
              alignItems: 'center',
            }}
          >
            <h4 className={styles.textTitle}>Valor do saque:</h4>
            <TextField
              className={classes.text}
              fullWidth
              disabled
              style={{ maxWidth: '30%' }}
              variant="outlined"
              size="small"
              placeholder="R$ XXX.XX"
              InputProps={{
                classes: {
                  notchedOutline: classes.myTextField,
                  input: classes.text,
                },
              }}
              value="R$ 120,00"
              // onChange={e => setDest(e.target.value)}
            />
            <MyButton
              style={{
                width: 220,
                height: 50,
                borderRadius: 10,
                // backgroundColor: '#657588',
              }}
              onClick={() => setOpenModal(true)}
              label="Enviar Nota Fiscal"
            />
          </div>
          <div style={{ width: '100%', marginTop: '3rem' }}>
            <TableContract
              data={data}
              setData={setData}
              totalPages={total}
              page={page}
              setPage={setPage}
              itens={itens}
              setItens={setItens}
              setStatus={setStatusSelected}
              product={productSelected}
              setProduct={setProductSelected}
              chipData={chipData}
              setChipData={setChipData}
              find={search}
            />
          </div>
        </div>
      </div>
      <Dialog
        onClose={() => setOpenModal(false)}
        open={openModal}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle
          style={{
            backgroundColor: '#005D9F',
            textAlign: 'end',
            color: '#fff',
          }}
        >
          Ciclo Fevereiro 2023
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <h5 style={{ fontWeight: 'bold' }}>AGENTE: xxxxxx</h5>
          </div>
          <Grid container>
            <Grid item xs={2}>
              <h4 className={styles.textTitle}>Carregar NF</h4>
            </Grid>
            <Grid item xs={8}>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <div style={{ width: '60%' }}>
                  <p>Razão Social</p>
                </div>
                <TextField
                  className={classes.text}
                  fullWidth
                  style={{ maxWidth: '60%' }}
                  variant="outlined"
                  size="small"
                  InputProps={{
                    classes: {
                      notchedOutline: classes.myTextField,
                      input: classes.text,
                    },
                  }}
                  value={rz}
                  onChange={e => setRz(e.target.value)}
                />
              </div>
            </Grid>
            <Grid item xs={2} />
          </Grid>
          <Grid container spacing={2} style={{ marginTop: 15 }}>
            <Grid item xs={3}>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <div style={{ width: '100%' }}>
                  <p>Número da nota</p>
                </div>
                <TextField
                  className={classes.text}
                  fullWidth
                  // style={{ maxWidth: '60%' }}
                  variant="outlined"
                  size="small"
                  InputProps={{
                    classes: {
                      notchedOutline: classes.myTextField,
                      input: classes.text,
                    },
                  }}
                  value={invoiceNumber}
                  onChange={e => setInvoiceNumber(e.target.value)}
                />
              </div>
            </Grid>
            <Grid item xs={3}>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <div style={{ width: '100%' }}>
                  <p>Valor da nota</p>
                </div>
                <TextField
                  className={classes.text}
                  fullWidth
                  // style={{ maxWidth: '60%' }}
                  variant="outlined"
                  size="small"
                  InputProps={{
                    classes: {
                      notchedOutline: classes.myTextField,
                      input: classes.text,
                    },
                  }}
                  value={amount}
                  onChange={e => setAmount(e.target.value)}
                />
              </div>
            </Grid>
            <Grid item xs={3}>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <div style={{ width: '100%' }}>
                  <p>Data de emissão</p>
                </div>
                <MuiPickersUtilsProvider locale={locale} utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    style={{ backgroundColor: '#fff' }}
                    // label="With keyboard"
                    size="small"
                    format="dd/MM/yyyy"
                    value={emissionDate}
                    InputProps={{ readOnly: true }}
                    InputAdornmentProps={{ position: 'end' }}
                    onChange={date => setEmissionDate(date)}
                  />
                </MuiPickersUtilsProvider>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <div style={{ width: '100%' }}>
                  <p>Data de envio</p>
                </div>
                <MuiPickersUtilsProvider locale={locale} utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    style={{ backgroundColor: '#fff' }}
                    // label="With keyboard"
                    size="small"
                    format="dd/MM/yyyy"
                    value={sendDate}
                    InputProps={{ readOnly: true }}
                    InputAdornmentProps={{ position: 'end' }}
                    onChange={date => setSendDate(date)}
                  />
                </MuiPickersUtilsProvider>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginTop: 15 }}>
            <Grid item xs={12}>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'start',
                }}
              >
                <div style={{ width: '40%' }}>
                  <p>Nota fiscal</p>
                </div>
                <TextField
                  className={classes.text}
                  fullWidth
                  style={{ maxWidth: '40%' }}
                  variant="outlined"
                  size="small"
                  InputProps={{
                    classes: {
                      notchedOutline: classes.myTextField,
                      input: classes.text,
                    },
                  }}
                  disabled
                  onClick={() => filePdf.current.click()}
                  value={myFile?.name}
                  // onChange={e => setDest(e.target.value)}
                />
              </div>
            </Grid>
          </Grid>
          <div
            className="col-sm-12"
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginTop: '2rem',
            }}
          >
            <div>
              <MyButton
                style={{
                  width: 120,
                  height: 40,
                  borderRadius: 10,
                  backgroundColor: '#657588',
                }}
                onClick={() => setOpenModal(false)}
                label="Voltar"
              />
            </div>
            <MyButton
              style={{
                width: 120,
                height: 40,
                borderRadius: 10,
                // backgroundColor: '#657588',
              }}
              onClick={sendInvoice}
              label="Enviar"
            />
          </div>
        </DialogContent>
      </Dialog>
      <input
        ref={filePdf}
        type="file"
        accept="application/pdf"
        style={{
          opacity: 0,
        }}
        // accept="media_type"
        onChange={() => setMyfile(filePdf.current.files[0])}
        className="form-control"
        id="inputNumSerie"
      />
    </>
  );
}
