/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState, useRef } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { string, object, array } from 'yup';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  Button,
  Container,
  Grid,
  makeStyles,
  TextField,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import Select from 'react-select';
import { yupResolver } from '@hookform/resolvers/yup';
import FlashContext from '../../../../components/contexts/FlashContext';
import api from '../../../../service/axios/api';
import StepperContext from '../../context/StepperContext';
// import CompanySchema from '../../schemas/CompanySchema';

const useStyles = makeStyles(theme => ({
  form: {
    minHeight: '500px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  content: {
    padding: theme.spacing(4),
  },
  section: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(8),
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(4),
    background: '#f0f0f0',
    width: '100%',
  },
}));

// eslint-disable-next-line no-unused-vars
function CompanyForm({ company, isPublic, controlE, edit }) {
  const { t } = useTranslation();
  const { id } = useParams();
  const { notifySuccess, notifyError } = useContext(FlashContext);
  const fileXls = useRef(null);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [myFile, setMyfile] = useState();
  // const options = values.map(value => ({
  //   label: value,
  //   value,
  // }));
  const [opt, setOpt] = useState([]);

  const contact = object()
    .shape({
      cpf: string()
        .required(t('newContract.company.required'))
        .matches(/[0-9]{11}/, t('validation.cpf')),
      email: string()
        .required(t('newContract.company.required'))
        .email(t('validation.email')),
      name: string().required(t('newContract.company.required')),
      role: string().required(t('newContract.company.required')),
      phone: string().required(t('newContract.company.required')),
    })
    .required();

  const CompanySchema = object({
    companyName: string().required(t('newContract.company.required')),
    tradingName: string().required(t('newContract.company.required')),
    cnpj: string()
      .required(t('newContract.company.required'))
      .matches(/[0-9]{14,14}/, t('validation.cnpj')),
    email: string()
      .required(t('newContract.company.required'))
      .email(t('validation.email')),
    phone: string().required(t('newContract.company.required')),
    mobile: string(),
    website: string().nullable().notRequired(),
    comments: string().nullable().notRequired(),
    contacts: array().compact().of(contact),
    campaign: object({}),
    // contacts: UserSchema.required(),
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: company,
    resolver: yupResolver(CompanySchema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'contacts',
  });

  // useEffect(() => {
  //   // if (isPublic) {
  //   //   register('campaign', {
  //   //     label: null,
  //   //     value: company.campaign.value,
  //   //   });
  //   // }
  // }, []);

  // const mySubmit = e => {
  //   e.preventDefault();
  //   console.log(company.campaign.value, isPublic);
  //   if (isPublic) {
  //     register('campaign', {
  //       label: 'Campaign',
  //       value: company.campaign.value,
  //     });
  //   }
  //   handleSubmit(submit);
  // };

  const classes = useStyles();
  const stepper = useContext(StepperContext);

  const submit = data => {
    if (isPublic) {
      data.campaign.value = company.campaign.value;
    }
    if (controlE) {
      stepper.next({ company: data });
    } else {
      stepper.next(data);
    }
  };

  const addContact = () => {
    append({
      name: '',
      cpf: '',
      role: '',
      phone: '',
      mobile: '',
      email: '',
      department: '',
    });
  };

  useEffect(() => {
    const cancel = new AbortController();

    api.campaign
      .get(cancel, 1, 1000)
      .then(res => {
        const list = [];
        res.docs.forEach(d => {
          list.push({ label: d.name, value: d.id });
        });
        setOpt(list);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (myFile) {
      setLoading(true);
      api.contract
        .uploadContract(myFile, id)
        .then(() => {
          notifySuccess(t('newContract.company.uploadSuccess'));
        })
        .catch(err => {
          notifyError(err.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [myFile]);

  return (
    <>
      <form className={classes.form} onSubmit={handleSubmit(submit)}>
        <Container className={classes.content}>
          <div className={classes.section}>
            <Typography variant="h5" component="h3" gutterBottom>
              {t('newContract.company.label')}
            </Typography>
            <Grid container spacing={10}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  inputProps={{ ...register('cnpj') }}
                  error={!!errors.cnpj}
                  helperText={errors.cnpj?.message}
                  label={t('newContract.company.cnpj')}
                  variant="outlined"
                  size="small"
                  fullWidth
                  disabled={edit}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  inputProps={{ ...register('companyName') }}
                  error={!!errors.companyName}
                  helperText={errors.companyName?.message}
                  label={t('newContract.company.rz')}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  inputProps={{ ...register('tradingName') }}
                  error={!!errors.tradingName}
                  helperText={errors.tradingName?.message}
                  label={t('newContract.company.tradingName')}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  inputProps={{ ...register('email') }}
                  error={!!errors.email}
                  helperText={errors.email?.message}
                  label={t('newContract.company.email')}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  inputProps={{ ...register('phone') }}
                  error={!!errors.phone}
                  helperText={errors.phone?.message}
                  label={t('newContract.company.phone')}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  inputProps={{ ...register('mobile') }}
                  error={!!errors.mobile}
                  helperText={errors.mobile?.message}
                  label={t('newContract.company.celPhone')}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  inputProps={{ ...register('website') }}
                  error={!!errors.website}
                  helperText={errors.website?.message}
                  label={t('newContract.company.website')}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  inputProps={{ ...register('comments') }}
                  error={!!errors.comments}
                  helperText={errors.comments?.message}
                  label={t('newContract.company.comments')}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  inputProps={{ ...register('groupName') }}
                  // error={!!errors.comments}
                  // helperText={errors.comments?.message}
                  label="Grupo"
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </Grid>
              {!isPublic && (
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Controller
                    name="campaign"
                    control={control}
                    error={!!errors.campaign?.value}
                    helperText={errors.campaign?.value.message}
                    render={({ field: { value, onChange, onBlur } }) => {
                      return (
                        <Select
                          width="300"
                          className="basic-single"
                          classNamePrefix="select"
                          placeholder={t('newContract.company.campaign')}
                          onChange={opti => onChange(opti)}
                          isDisabled={edit}
                          onBlur={onBlur}
                          value={value}
                          isSearchable
                          options={opt}
                        />
                      );
                    }}
                  />
                  {errors.campaign && (
                    <span style={{ color: '#e53935' }}>
                      {t('newContract.company.required')}
                    </span>
                  )}
                </Grid>
              )}
              {edit && (
                <>
                  <Grid item xs={12} sm={6} md={4} lg={4} />
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Button
                      onClick={() => fileXls.current.click()}
                      size="small"
                      variant="outlined"
                      disabled={loading}
                      color="primary"
                      loading
                    >
                      {loading && <CircularProgress size={20} />}
                      {!loading && t('editContract.uploadContract')}
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextField
                      inputProps={{ ...register('FourSign') }}
                      // error={!!errors.comments}
                      // helperText={errors.comments?.message}
                      label="UIID Contrato DOC4SING"
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </div>
          <div className={classes.section}>
            {fields.map((c, idx) => (
              <>
                <Typography
                  variant="h5"
                  component="h3"
                  gutterBottom
                  style={{ marginTop: idx > 0 && 10 }}
                >
                  {t('newContract.contact.label')}{' '}
                  {fields.length > 1 ? idx + 1 : ''}
                  {fields.length > 1 && (
                    <Button
                      onClick={() => remove(idx)}
                      variant="outlined"
                      size="small"
                      style={{
                        marginLeft: 15,
                        // width: 10,
                        backgroundColor: '#ba000d',
                        color: '#fff',
                        fontSize: 14,
                        fontWeight: 'bold',
                      }}
                    >
                      {t('newContract.contact.removeBtn')}
                    </Button>
                  )}
                </Typography>
                <Grid container spacing={10}>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      inputProps={{ ...register(`contacts[${idx}].name`) }}
                      error={errors.contacts && !!errors.contacts[idx]?.name}
                      helperText={
                        errors?.contacts && errors?.contacts[idx]?.name?.message
                      }
                      label={t('newContract.contact.name')}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      inputProps={{ ...register(`contacts[${idx}].cpf`) }}
                      error={errors.contacts && !!errors.contacts[idx]?.cpf}
                      helperText={
                        errors.contacts && errors.contacts[idx]?.cpf?.message
                      }
                      label={t('newContract.contact.cpf')}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      inputProps={{ ...register(`contacts[${idx}].role`) }}
                      error={errors.contacts && !!errors.contacts[idx]?.role}
                      helperText={
                        errors.contacts && errors.contacts[idx]?.role?.message
                      }
                      label={t('newContract.contact.role')}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      inputProps={{
                        ...register(`contacts[${idx}].department`),
                      }}
                      error={
                        errors.contacts && !!errors.contacts[idx]?.department
                      }
                      helperText={
                        errors.contacts &&
                        errors.contacts[idx]?.department?.message
                      }
                      label={t('newContract.contact.department')}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      inputProps={{ ...register(`contacts[${idx}].phone`) }}
                      error={errors.contacts && !!errors.contacts[idx]?.phone}
                      helperText={
                        errors.contacts && errors.contacts[idx]?.phone?.message
                      }
                      label={t('newContract.contact.phone')}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      inputProps={{ ...register(`contacts[${idx}].mobile`) }}
                      error={errors.contacts && !!errors.contacts[idx]?.mobile}
                      helperText={
                        errors.contacts && errors.contacts[idx]?.mobile?.message
                      }
                      label={t('newContract.contact.celPhone')}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      inputProps={{ ...register(`contacts[${idx}].email`) }}
                      error={errors.contacts && !!errors.contacts[idx]?.email}
                      helperText={
                        errors.contacts && errors.contacts[idx]?.email?.message
                      }
                      label={t('newContract.contact.email')}
                      variant="outlined"
                      size="small"
                      disabled={edit}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </>
            ))}
          </div>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Button onClick={addContact} variant="contained" color="primary">
              {t('newContract.contact.addContactBtn')}
            </Button>
          </div>
        </Container>
        <div className={classes.footer}>
          <Button type="submit" variant="contained" color="primary">
            {t('newContract.contact.nextBtn')}
          </Button>
        </div>
      </form>
      <input
        ref={fileXls}
        type="file"
        style={{
          opacity: 0,
        }}
        // accept="media_type"
        onChange={() => setMyfile(fileXls.current.files[0])}
        className="form-control"
        id="inputNumSerie"
        // placeholder="Arquivo DWG do projeto"
      />
    </>
  );
}
export default CompanyForm;
