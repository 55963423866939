/* eslint-disable no-return-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Grid,
  TextField,
  Typography,
  InputAdornment,
  makeStyles,
} from '@material-ui/core';
import { NumberFormatCustom, NumberFormatRate } from '../../NumberFormatCustom';
import cardFees from '../defaultStates/cardFees';

const useStyles = makeStyles({
  commissions: {
    background: 'white',
  },
});

function PrepaidCardRight({ form }) {
  const {
    register,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = form;
  const classes = useStyles();
  const { t } = useTranslation();

  // console.log('TARIFAS operatorFees', getValues('operatorFees'));
  // console.log('TARIFAS DEFAULT', cardFees.default.operatorFees);

  const { productBrand } = watch();

  // const [operatorFee, setOperatorFee] = useState(cardFees.default.operatorFees);

  const [operatorFee, setOperatorFee] = useState(getValues('operatorFees'));
  const [companyOperatingFee, setCompanyOperatingFee] = useState(
    getValues('companyOperatingFees'),
  );

  // useMemo(() => {
  //   console.log("User has changed");
  //   return props.user;
  // }, [props])

  function handleChange(op) {
    if (op === 'companyOperatingFees.balanceTransferFee') {
      return e => {
        setCompanyOperatingFee({
          ...companyOperatingFee,
          balanceTransferFee: String(e.target.value),
        });
      };
    }
    if (op === 'companyOperatingFees.minimumLoadAmount') {
      return e => {
        setCompanyOperatingFee({
          ...companyOperatingFee,
          minimumLoadAmount: String(e.target.value),
        });
      };
    }
    if (op === 'companyOperatingFees.belowMinimumLoadFee') {
      return e => {
        setCompanyOperatingFee({
          ...companyOperatingFee,
          belowMinimumLoadFee: String(e.target.value),
        });
      };
    }
    if (op === 'companyOperatingFees.emergencyLoadFee') {
      return e => {
        setCompanyOperatingFee({
          ...companyOperatingFee,
          emergencyLoadFee: String(e.target.value),
        });
      };
    }
    if (op === 'companyOperatingFees.specialHandlingFee') {
      return e => {
        setCompanyOperatingFee({
          ...companyOperatingFee,
          specialHandlingFee: String(e.target.value),
        });
      };
    }
    if (op === 'companyOperatingFees.ir_fee') {
      return e => {
        setCompanyOperatingFee({
          ...companyOperatingFee,
          ir_fee: String(e.target.value),
        });
      };
    }
    if (op === 'operatorFees.monthlyFee') {
      return e => {
        setOperatorFee({
          ...operatorFee,
          monthlyFee: String(e.target.value),
        });
      };
    }
    if (op === 'operatorFees.unlockFee') {
      return e => {
        setOperatorFee({
          ...operatorFee,
          unlockFee: String(e.target.value),
        });
      };
    }
    if (op === 'operatorFees.reissueFee') {
      return e => {
        setOperatorFee({
          ...operatorFee,
          reissueFee: String(e.target.value),
        });
      };
    }
    if (op === 'operatorFees.chargebackFee') {
      return e => {
        setOperatorFee({
          ...operatorFee,
          chargebackFee: String(e.target.value),
        });
      };
    }
    if (op === 'operatorFees.atmWithdrawFee') {
      return e => {
        setOperatorFee({
          ...operatorFee,
          atmWithdrawFee: String(e.target.value),
        });
      };
    }
    if (op === 'operatorFees.rechargePortalFee') {
      return e => {
        setOperatorFee({
          ...operatorFee,
          rechargePortalFee: String(e.target.value),
        });
      };
    }
    if (op === 'operatorFees.rechargeInvoiceFee') {
      return e => {
        setOperatorFee({
          ...operatorFee,
          rechargeInvoiceFee: String(e.target.value),
        });
      };
    }
    if (op === 'operatorFees.p2pTransferFee') {
      return e => {
        setOperatorFee({
          ...operatorFee,
          p2pTransferFee: String(e.target.value),
        });
      };
    }
    return e => {
      setOperatorFee({
        ...operatorFee,
      });
    };
  }

  useEffect(() => {
    switch (productBrand) {
      case 'mastercard':
        // setValue('operatorFees', cardFees.mastercard.operatorFees);
        if (operatorFee.isChanged) {
          setValue('operatorFees', operatorFee);
          setOperatorFee(getValues('operatorFees'));
        } else {
          setValue('operatorFees', cardFees.mastercard.operatorFees);
          setOperatorFee(cardFees.mastercard.operatorFees);
        }

        if (companyOperatingFee.isChanged) {
          setCompanyOperatingFee(getValues('companyOperatingFees'));
          setValue('companyOperatingFees', companyOperatingFee);
        } else {
          setCompanyOperatingFee(cardFees.mastercard.companyOperatingFees);
          setValue(
            'companyOperatingFees',
            cardFees.mastercard.companyOperatingFees,
          );
        }
        // setOperatorFee(cardFees.mastercard.operatorFees);
        // setOperatorFee(cardFees.mastercard.operatorFees);
        break;
      case 'visa':
        if (operatorFee.isChanged) {
          setValue('operatorFees', operatorFee);
          setOperatorFee(operatorFee);
        } else {
          setValue('operatorFees', cardFees.visa.operatorFees);
          setOperatorFee(cardFees.visa.operatorFees);
        }
        if (companyOperatingFee.isChanged) {
          setValue('companyOperatingFees', companyOperatingFee);
          setCompanyOperatingFee(companyOperatingFee);
        } else {
          setValue('companyOperatingFees', cardFees.visa.companyOperatingFees);
          setCompanyOperatingFee(cardFees.visa.companyOperatingFees);
        }
        break;
      default:
        if (operatorFee.isChanged) {
          setValue('operatorFees', operatorFee);
        } else {
          setValue('operatorFees', cardFees.default.operatorFees);
          setOperatorFee(cardFees.default.operatorFees);
        }

        if (companyOperatingFee.isChanged) {
          setValue('companyOperatingFees', companyOperatingFee);
          setCompanyOperatingFee(companyOperatingFee);
        } else {
          setValue(
            'companyOperatingFees',
            cardFees.default.companyOperatingFees,
          );
          setCompanyOperatingFee(cardFees.visa.companyOperatingFees);
        }
        break;
    }
  }, [productBrand]);

  const changeStatus = () => {
    setValue('operatorFees.isChanged', true);
    setOperatorFee({
      ...operatorFee,
      isChanged: true,
    });
  };

  const changeCompanyStatus = () => {
    setValue('companyOperatingFees.isChanged', true);
    setCompanyOperatingFee({
      ...companyOperatingFee,
      isChanged: true,
    });
  };

  return (
    <Grid className={classes.commissions} item lg={6} md={6} sm={12} xs={12}>
      <Box
        p={{ xs: 3, sm: 3, md: 4, lg: 5 }}
        mb={{ xs: 3, sm: 3, md: 4, lg: 5 }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              color="primary"
              paragraph
              align="center"
            >
              {t('newContract.product.label1')}
            </Typography>
          </Grid>
          <Grid item lg={4} md={4} sm={3} xs={6}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              error={!!errors.companyOperatingFees?.balanceTransferFee}
              helperText={
                errors.companyOperatingFees?.balanceTransferFee?.message ||
                t('newContract.product.cTransferFee')
              }
              value={companyOperatingFee.balanceTransferFee}
              onChange={handleChange('companyOperatingFees.balanceTransferFee')}
              onClick={changeCompanyStatus}
              InputProps={{
                inputComponent: NumberFormatCustom,
                inputProps: {
                  ...register('companyOperatingFees.balanceTransferFee'),
                },
              }}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={3} xs={6}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              error={!!errors.companyOperatingFees?.minimumLoadAmount}
              helperText={
                errors.companyOperatingFees?.minimumLoadAmount?.message ||
                t('newContract.product.cMinCharge')
              }
              value={companyOperatingFee.minimumLoadAmount}
              onChange={handleChange('companyOperatingFees.minimumLoadAmount')}
              onClick={changeCompanyStatus}
              InputProps={{
                inputComponent: NumberFormatCustom,
                inputProps: {
                  ...register('companyOperatingFees.minimumLoadAmount'),
                },
              }}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={3} xs={6}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              error={!!errors.companyOperatingFees?.belowMinimumLoadFee}
              helperText={
                errors.companyOperatingFees?.belowMinimumLoadFee?.message ||
                t('newContract.product.cMinChargeFee')
              }
              value={companyOperatingFee.belowMinimumLoadFee}
              onChange={handleChange(
                'companyOperatingFees.belowMinimumLoadFee',
              )}
              onClick={changeCompanyStatus}
              InputProps={{
                inputComponent: NumberFormatCustom,
                inputProps: {
                  ...register('companyOperatingFees.belowMinimumLoadFee'),
                },
              }}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={3} xs={6}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              error={!!errors.companyOperatingFees?.emergencyLoadFee}
              helperText={
                errors.companyOperatingFees?.emergencyLoadFee?.message ||
                t('newContract.product.cEmergencyCharge')
              }
              value={companyOperatingFee.emergencyLoadFee}
              onChange={handleChange('companyOperatingFees.emergencyLoadFee')}
              onClick={changeCompanyStatus}
              InputProps={{
                inputComponent: NumberFormatCustom,
                inputProps: {
                  ...register('companyOperatingFees.emergencyLoadFee'),
                },
              }}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={3} xs={6}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              error={!!errors.companyOperatingFees?.specialHandlingFee}
              helperText={
                errors.companyOperatingFees?.specialHandlingFee?.message ||
                t('newContract.product.cHandling')
              }
              value={companyOperatingFee.specialHandlingFee}
              onChange={handleChange('companyOperatingFees.specialHandlingFee')}
              onClick={changeCompanyStatus}
              InputProps={{
                inputComponent: NumberFormatCustom,
                inputProps: {
                  ...register('companyOperatingFees.specialHandlingFee'),
                },
              }}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={3} xs={6}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              error={!!errors.companyOperatingFees?.chargebackRate}
              helperText={
                errors.companyOperatingFees?.chargebackRate?.message ||
                t('newContract.product.cChargebackFee')
              }
              InputProps={{
                inputComponent: NumberFormatRate,
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                inputProps: {
                  ...register('companyOperatingFees.chargebackRate'),
                },
              }}
              value={companyOperatingFee.chargebackRate}
              onChange={e => {
                setCompanyOperatingFee({
                  ...companyOperatingFee,
                  chargebackRate: e.target.value,
                });
              }}
              onClick={changeCompanyStatus}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={3} xs={6}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              error={!!errors.companyOperatingFees?.ir_fee}
              helperText={errors.companyOperatingFees?.ir_fee?.message || 'DCP'}
              value={companyOperatingFee.ir_fee}
              onChange={handleChange('companyOperatingFees.ir_fee')}
              onClick={changeCompanyStatus}
              InputProps={{
                inputComponent: NumberFormatCustom,
                inputProps: {
                  ...register('companyOperatingFees.ir_fee'),
                },
              }}
            />
          </Grid>
        </Grid>
      </Box>

      <Box p={{ xs: 3, sm: 3, md: 4, lg: 5 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              color="primary"
              paragraph
              align="center"
            >
              {t('newContract.product.label2')}
            </Typography>
          </Grid>
          <Grid item lg={4} md={4} sm={3} xs={6}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              error={!!errors.operatorFees?.monthlyFee}
              helperText={
                errors.operatorFees?.monthlyFee?.message ||
                t('newContract.product.oMonthlyPayment')
              }
              value={operatorFee.monthlyFee}
              onChange={handleChange('operatorFees.monthlyFee')}
              onClick={changeStatus}
              InputProps={{
                inputComponent: NumberFormatCustom,
                inputProps: {
                  ...register('operatorFees.monthlyFee'),
                },
              }}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={3} xs={6}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              error={!!errors.operatorFees?.unlockFee}
              helperText={
                errors.operatorFees?.unlockFee?.message ||
                t('newContract.product.oUnlock')
              }
              value={operatorFee.unlockFee}
              onChange={handleChange('operatorFees.unlockFee')}
              onClick={changeStatus}
              InputProps={{
                inputComponent: NumberFormatCustom,
                inputProps: {
                  ...register('operatorFees.unlockFee'),
                },
              }}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={3} xs={6}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              error={!!errors.operatorFees?.reissueFee}
              helperText={
                errors.operatorFees?.reissueFee?.message ||
                t('newContract.product.oDuplicate')
              }
              value={operatorFee.reissueFee}
              onChange={handleChange('operatorFees.reissueFee')}
              onClick={changeStatus}
              InputProps={{
                inputComponent: NumberFormatCustom,
                inputProps: {
                  ...register('operatorFees.reissueFee'),
                },
              }}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={3} xs={6}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              error={!!errors.operatorFees?.chargebackFee}
              helperText={
                errors.operatorFees?.chargebackFee?.message ||
                t('newContract.product.oChargeback')
              }
              value={operatorFee.chargebackFee}
              onChange={handleChange('operatorFees.chargebackFee')}
              onClick={changeStatus}
              InputProps={{
                inputComponent: NumberFormatCustom,
                inputProps: {
                  ...register('operatorFees.chargebackFee'),
                },
              }}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={3} xs={6}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              error={!!errors.operatorFees?.atmWithdrawFee}
              helperText={
                errors.operatorFees?.atmWithdrawFee?.message ||
                t('newContract.product.oWithdraw')
              }
              value={operatorFee.atmWithdrawFee}
              onChange={handleChange('operatorFees.atmWithdrawFee')}
              onClick={changeStatus}
              InputProps={{
                inputComponent: NumberFormatCustom,
                inputProps: {
                  ...register('operatorFees.atmWithdrawFee'),
                },
              }}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={3} xs={6}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              error={!!errors.operatorFees?.markupRate}
              helperText={
                errors.operatorFees?.markupRate?.message ||
                t('newContract.product.oMarkupFee')
              }
              InputProps={{
                inputComponent: NumberFormatRate,
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                inputProps: {
                  ...register('operatorFees.markupRate'),
                },
              }}
              value={operatorFee.markupRate}
              onChange={e => {
                setOperatorFee({
                  ...operatorFee,
                  markupRate: e.target.value,
                });
              }}
              onClick={changeStatus}
            />
          </Grid>
          {/* <Grid item lg={4} md={4} sm={3} xs={6}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              error={!!errors.operatorFees?.rechargePortalFee}
              helperText={
                errors.operatorFees?.rechargePortalFee?.message ||
                'Recarga pelo Portal'
              }
              value={operatorFee.rechargePortalFee}
              onChange={handleChange('operatorFees.rechargePortalFee')}
              onClick={changeStatus}
              InputProps={{
                inputComponent: NumberFormatCustom,
                inputProps: {
                  ...register('operatorFees.rechargePortalFee'),
                },
              }}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={3} xs={6}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              error={!!errors.operatorFees?.rechargeInvoiceFee}
              helperText={
                errors.operatorFees?.rechargeInvoiceFee?.message ||
                'Recarga por N.F.'
              }
              value={operatorFee.rechargeInvoiceFee}
              onChange={handleChange('operatorFees.rechargeInvoiceFee')}
              onClick={changeStatus}
              InputProps={{
                inputComponent: NumberFormatCustom,
                inputProps: {
                  ...register('operatorFees.rechargeInvoiceFee'),
                },
              }}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={3} xs={6}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              error={!!errors.operatorFees?.p2pTransferFee}
              helperText={
                errors.operatorFees?.p2pTransferFee?.message || 'Transferência'
              }
              value={operatorFee.p2pTransferFee}
              onChange={handleChange('operatorFees.p2pTransferFee')}
              onClick={changeStatus}
              InputProps={{
                inputComponent: NumberFormatCustom,
                inputProps: {
                  ...register('operatorFees.p2pTransferFee'),
                },
              }}
            />
          </Grid> */}
        </Grid>
      </Box>
    </Grid>
  );
}

export default PrepaidCardRight;
