// import { GridLocaleText } from '@material-ui/data-grid';

const localizations = {
  prepaid_card: 'Cartão',
  Card: 'Cartão',
  Check: 'Cheque',
  Transfer: 'Transferência',
  Processed: 'Processado',
  AwaitingSettlement: 'Aguardando Liquidação',
  AwaitingInvoice: 'Aguardando Fatura',
  Created: 'Criado',
  AwaitingPayment: 'Aguardando Pagamento',
  Settled: 'Resolvido',
  Errors: 'Erros',
  digital: 'Digital',
  direct: 'Direta',
  set: 'Conjunta',
  AwaitingBlock: 'Aguardando Bloco',
  Canceled: 'Cancelado',
  InStock: 'Em Estoque',
  Blocked: 'Bloqueado',
  Active: 'Ativo',
  AwaitingRelease: 'Aguardando Lançamento',
  AwaitingShipping: 'Aguardando envio',
  AwaitingIssuerLink: 'Aguardando link do emissor',
  AwaitingIssuerLinkResponse: 'Aguardando resposta do link do emissor',
  AwaitingUnblock: 'Aguardando desbloqueio',
  AwaitingCardRelease: 'Aguardando liberação do cartão',
  AwaitingProcessing: 'Aguardando Processamento',
  AwaitingUploadFile: 'Aguardando Envio',
  AwaitingDocumentSign: 'Aguardando Assinatura',
  Processing: 'Em processamento',
  AwaitingConciliation: 'Aguardando Conciliação',
  AwaitingCard: 'Aguardando Cartão',
  Rejected: 'Rejeitado',
  Payable: 'A Pagar',
  Paid: 'Pago',
  AwaitingCancelation: 'Aguardando Cancelamento',
  AwaitingCanceledInvoice: 'Aguardando fatura cancelada',
  LeadOpen: 'Aberto',
  logistics: 'logística',
  post_office: 'Correios',
  by_hand: 'à mão',
  client: 'cliente',
};

export const dataGridLocalizations = {
  toolbarColumns: 'Colunas',
  toolbarFilters: 'Filtros',
  toolbarFiltersLabel: 'Mostrar filtros',
  toolbarFiltersTooltipHide: 'Ocultar filtros',
  toolbarFiltersTooltipShow: 'Mostrar filtros',
  toolbarFiltersTooltipActive: count =>
    count !== 1 ? `${count} filtros ativos` : `${count} filtro ativo`,
  columnsPanelTextFieldLabel: 'Localizar coluna',
  columnsPanelTextFieldPlaceholder: 'Título da coluna',
  columnsPanelHideAllButton: 'Esconda tudo',
  columnsPanelShowAllButton: 'Mostrar tudo',
  columnMenuShowColumns: 'Mostrar colunas',
  columnMenuFilter: ' Filtro',
  columnMenuHideColumn: 'Ocultar',
  columnMenuUnsort: 'Não ordenar',
  columnMenuSortAsc: 'Classificar por ASC',
  columnMenuSortDesc: 'Classificar por DESC',
  filterPanelAddFilter: 'Adicionar filtro',
  filterPanelDeleteIconLabel: 'Excluir',
  filterPanelOperators: 'Operadores',
  filterPanelOperatorAnd: 'E',
  filterPanelOperatorOr: 'Ou',
  filterPanelColumns: 'Colunas',
  filterPanelInputLabel: 'Valor',
  filterPanelInputPlaceholder: 'Valor do filtro',
  filterOperatorContains: 'contém',
  filterOperatorEquals: 'é igual a',
  filterOperatorStartsWith: 'começa com',
  filterOperatorEndsWith: 'termina com',
  filterOperatorIs: 'é',
  filterOperatorNot: 'não é',
  filterOperatorAfter: 'é depois',
  filterOperatorOnOrAfter: 'está ligado ou depois',
  filterOperatorBefore: 'é antes',
  filterOperatorOnOrBefore: 'está ligado ou antes',
  rootGridLabel: '',
  noRowsLabel: '',
  errorOverlayDefaultLabel: '',
  toolbarDensity: undefined,
  toolbarDensityLabel: '',
  toolbarDensityCompact: '',
  toolbarDensityStandard: '',
  toolbarDensityComfortable: '',
  toolbarColumnsLabel: '',
  toolbarExport: undefined,
  toolbarExportLabel: '',
  toolbarExportCSV: undefined,
  columnsPanelDragIconLabel: '',
  filterValueAny: '',
  filterValueTrue: '',
  filterValueFalse: '',
  columnMenuLabel: '',
  columnHeaderFiltersTooltipActive: count =>
    count !== 1 ? `${count} filtros ativos` : `${count} filtro ativo`,
  columnHeaderFiltersLabel: '',
  columnHeaderSortIconLabel: '',
  footerRowSelected: count =>
    count !== 1 ? `${count} linhas selecionadas` : `${count} linha selecionada`,
  footerTotalRows: undefined,
  checkboxSelectionHeaderName: '',
  booleanCellTrueLabel: '',
  booleanCellFalseLabel: '',
};

export const localize = str => {
  return localizations[str] || str;
};

export default localizations;
