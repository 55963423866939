/* eslint-disable no-unused-vars */
import * as React from 'react';

import { Grid, TextField } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    myTextField: {
      backgroundColor: 'none',
      boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
      border: 'none',
      color: '#717171',
      fontWeight: 'bold',
      fontFamily: 'Open Sans',
    },
    text: {
      fontFamily: 'Open Sans',
      fontWeight: 'bold',
      color: '#717171',
    },
  }),
);

export default function TypeCard() {
  const classes = useStyles();
  return (
    <Grid
      container
      spacing={2}
      style={{
        border: '2px solid #c5c5c5',
        borderRadius: 10,
        justifyContent: 'center',
        margin: 15,
        padding: 30,
      }}
    >
      <Grid item xs={12}>
        <TextField
          classes={{ notchedOutline: classes.input }}
          className={classes.text}
          fullWidth
          id="outlined-basic"
          label="ACESS_SAUDE_002"
          // label={t('Product.creditCard.table.search')}
          variant="outlined"
          size="small"
          // inputRef={search}
          InputProps={{
            classes: {
              notchedOutline: classes.myTextField,
              input: classes.text,
            },
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          classes={{ notchedOutline: classes.input }}
          className={classes.text}
          fullWidth
          id="outlined-basic"
          label="Nome"
          // label={t('Product.creditCard.table.search')}
          variant="outlined"
          size="small"
          // inputRef={search}
          InputProps={{
            classes: {
              notchedOutline: classes.myTextField,
              input: classes.text,
            },
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          classes={{ notchedOutline: classes.input }}
          className={classes.text}
          fullWidth
          id="outlined-basic"
          label="Tipo de seguro"
          // label={t('Product.creditCard.table.search')}
          variant="outlined"
          size="small"
          // inputRef={search}
          InputProps={{
            classes: {
              notchedOutline: classes.myTextField,
              input: classes.text,
            },
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          classes={{ notchedOutline: classes.input }}
          className={classes.text}
          fullWidth
          id="outlined-basic"
          label="Fornecedor"
          // label={t('Product.creditCard.table.search')}
          variant="outlined"
          size="small"
          // inputRef={search}
          InputProps={{
            classes: {
              notchedOutline: classes.myTextField,
              input: classes.text,
            },
          }}
        />
      </Grid>
    </Grid>
    // <div className="box-category">
    //   <input className="input-category"
    //  style={{ marginBottom: 15 }} placeholder="ACESS_SAUDE_002" />
    //   <input className="input-category" style={{ marginBottom: 15 }} placeholder="Nome" />
    //   <input className="input-category"
    // style={{ marginBottom: 15 }} placeholder="Tipo de Seguro" />
    //   <input className="input-category" style={{ marginBottom: 15 }} placeholder="Fornecedor" />
    // </div>
  );
}
