/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';

import { Button, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  iconButton: {
    minWidth: 0,
    minHeight: 0,
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  flipped: {
    transform: 'rotateY(180deg)',
  },
}));

const propTypes = {
  tooltip: PropTypes.string.isRequired,
  Icon: PropTypes.elementType.isRequired,
  flipIcon: PropTypes.bool,
};

export default function TooltipIconButton({
  tooltip,
  Icon,
  flipIcon,
  ...rest
}) {
  const classes = useStyles();

  return (
    <Tooltip title={tooltip} arrow>
      <span>
        <Button
          className={classes.iconButton}
          color="inherit"
          // variant="outlined"
          {...rest}
        >
          <Icon className={clsx({ [classes.flipped]: flipIcon })} />
        </Button>
      </span>
    </Tooltip>
  );
}

TooltipIconButton.propTypes = propTypes;
