/* eslint-disable no-var */
/* eslint-disable import/no-mutable-exports */
/* eslint-disable vars-on-top */
/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/require-default-props */
/* eslint-disable react/display-name */
import React, { createContext, useCallback } from 'react';
import PropTypes from 'prop-types';

import CancelIcon from '@material-ui/icons/Cancel';

import { SnackbarProvider, useSnackbar } from 'notistack';
import snackbarConfig from './snackbar';
import TooltipIconButton from './TooltipIconButton';

const initialState = {
  notifySuccess: (message = 'Sucesso') => {
    console.info(message);
  },
  notifyWarning: (message = 'Aviso') => {
    console.warn(message);
  },
  notifyError: (message = 'Erro') => {
    console.error(message);
  },
};

const FlashContext = createContext(initialState);

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

const SnackbarConsumerAndFlashProvider = function ({ children }) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const snackbarOptions = useCallback(
    variant => ({
      variant,
      ...snackbarConfig.styling,
      key: new Date().getTime() + Math.random(),
      action: key => (
        <TooltipIconButton
          tooltip="Dispensar"
          Icon={CancelIcon}
          aria-label="dispensar mensagem"
          onClick={() => closeSnackbar(key)}
        />
      ),
    }),
    [closeSnackbar],
  );

  const notifySuccess = useCallback(
    (message = 'Sucesso') => {
      enqueueSnackbar(message, snackbarOptions('success'));
    },
    [enqueueSnackbar, snackbarOptions],
  );

  const notifyWarning = useCallback(
    (message = 'Atenção') => {
      enqueueSnackbar(message, snackbarOptions('warning'));
    },
    [enqueueSnackbar, snackbarOptions],
  );

  const notifyError = useCallback(
    (message = 'Erro') => {
      enqueueSnackbar(message, snackbarOptions('error'));
    },
    [enqueueSnackbar, snackbarOptions],
  );

  return (
    <FlashContext.Provider
      value={{ notifySuccess, notifyWarning, notifyError }}
    >
      {children}
    </FlashContext.Provider>
  );
};

SnackbarConsumerAndFlashProvider.propTypes = propTypes;

export var FlashProvider = function ({ children }) {
  return (
    <SnackbarProvider
      maxSnack={snackbarConfig.logic.max}
      preventDuplicate
      hideIconVariant
      autoHideDuration={30000}
    >
      <SnackbarConsumerAndFlashProvider>
        {children}
      </SnackbarConsumerAndFlashProvider>
    </SnackbarProvider>
  );
};

FlashProvider.propTypes = propTypes;

export default FlashContext;
