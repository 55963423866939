import cancellablePromise, { delay } from '../../util/cancellablePromise';
import useCancellablePromises from './useCancellablePromises';

const useClickPreventionOnDoubleClick = (onClick, onDoubleClick) => {
  const api = useCancellablePromises();

  const handleClick = async (...args) => {
    api.clearPendingPromises();
    const waitForClick = cancellablePromise(delay(300));
    api.appendPendingPromise(waitForClick);

    return waitForClick.promise
      .then(() => {
        api.removePendingPromise(waitForClick);
        onClick(...args);
      })
      .catch(errorInfo => {
        api.removePendingPromise(waitForClick);
        if (!errorInfo.isCanceled) {
          throw errorInfo.error;
        }
      });
  };

  const handleDoubleClick = (...args) => {
    api.clearPendingPromises();
    onDoubleClick(...args);
  };

  return [handleClick, handleDoubleClick];
};

export default useClickPreventionOnDoubleClick;
