/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable camelcase */
import React from 'react';
import { Grid } from '@material-ui/core';
// import { makeStyles, createStyles } from '@mui/styles';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MyButton from '../../../components/button/MyButton';
import routing from '../../../service/routes/routing';

// const useStyles = makeStyles((theme) => createStyles({
//   myButton: {
//     // textAlign: 'center',
//     // padding: theme.spacing(6),
//     // margin: theme.spacing(1),
//     // color: theme.palette.text.secondary,
//     color: '#2a3176',
//     backgroundColor: '#f4f4f4',
//     fontWeight: 'bold',
//     // minWidth: 200,
//   },
//   myGridSelected: {
//     minWidth: 300,
//     maxWidth: 300,
//     minHeight: 100,
//     backgroundColor: '#2A3176',
//     margin: theme.spacing(1),
//     alignItems: 'center',
//     justifyContent: 'center',
//     display: 'flex',
//     textAlign: 'center',
//     cursor: 'pointer',
//     border: '2px solid #2A3176',
//     borderRadius: 10,
//   },
//   myLabel: {
//     padding: theme.spacing(6),
//     color: '#2a3176',
//     fontWeight: 'bold',
//   },
//   myLabelSelected: {
//     padding: theme.spacing(6),
//     color: '#fff',
//     fontWeight: 'bold',
//   },
// }));

function ProductType() {
  // eslint-disable-next-line camelcase
  const { product_type } = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  // const classes = useStyles();

  const handleNew = () => {
    switch (product_type) {
      case 'prepaidCard':
        history.push(routing.creditCard);
        break;
      case 'digitalAccount':
        history.push(routing.account);
        break;
      case 'transfer':
        history.push(routing.transfer);
        break;
      default:
        console.log('Nada fazer');
    }
  };
  return (
    <>
      <h5
        className="newProductTitle"
        onClick={() => history.push(routing.newProduct)}
        style={{ cursor: 'pointer', marginTop: 40 }}
      >
        {t('Product.changeProduct')}
      </h5>
      <Grid
        container
        spacing={1}
        style={{
          justifyContent: 'center',
          padding: 40,
          alignItems: 'center',
        }}
      >
        <Grid className="typePMyGridS" item xs={3} onClick={handleNew}>
          {product_type === 'prepaidCard' && (
            <p className="typepmylabel" style={{ marginTop: 25 }}>
              [{t('Product.category.card')}]
            </p>
          )}
          {product_type === 'digitalAccount' && (
            <p className="typepmylabel" style={{ marginTop: 25 }}>
              [Conta Digital]
            </p>
          )}
          {product_type === 'transfer' && (
            <p className="typepmylabel" style={{ marginTop: 25 }}>
              [Transferência]
            </p>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <MyButton
            style={{ minWidth: 450, marginTop: 30 }}
            label={t('Product.newInCategory')}
            onClick={() => history.push(routing.newProduct)}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default ProductType;
