/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, MenuItem, TextField } from '@material-ui/core';

import { SALES_CHANELS } from '../../../util/constants';

function PrepaidCardLeft({ form }) {
  const {
    register,
    formState: { errors },
  } = form;

  const { t } = useTranslation();
  return (
    <Grid item lg={6} md={6} sm={6} xs={12}>
      <TextField
        select
        fullWidth
        size="small"
        variant="outlined"
        label={t('newContract.product.channel')}
        error={!!errors.salesChannel}
        helperText={errors.salesChannel?.message}
        SelectProps={{
          ...register('salesChannel'),
        }}
      >
        {SALES_CHANELS.map(({ value, label }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </TextField>
    </Grid>
  );
}

export default PrepaidCardLeft;
