/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputAdornment,
  makeStyles,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';
// import { Autocomplete } from '@material-ui/lab';
import StepperContext from '../../context/StepperContext';
import ProductDetailsSchema from '../../schemas/ProductDetailsSchema';
// import ProductDetails from '@/types/ProductDetails';
import { NumberFormatCustom, NumberFormatRate } from '../NumberFormatCustom';

import { CALC_COMISSION, PRODUCTS } from '../../util/constants';

import SelectAsyncCommercialAgent from './SelectAsyncCommercialAgent';
// import Company from '@/types/Company';
// import SpinnerPage from '../SpinnerPage';
import cardFees from './defaultStates/cardFees';
import { operatorFees } from './defaultStates/productDetails';
import { PrepaidCardLeftViewer, PrepaidCardRightViewer } from './PrepaidCard';
import {
  DigitalAccountLeftViewer,
  DigitalAccountRightViewer,
} from './DigitalAccount';
import { TransferLeftViewer, TransferRightViewer } from './Transfer';

// type ProductDetailsKeys = keyof ProductDetails

const useStyles = makeStyles(theme => ({
  form: {
    minHeight: '500px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  section: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(8),
  },
  content: {
    padding: theme.spacing(1),
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(4),
    background: '#f0f0f0',
    width: '100%',
    '& > *': {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },
}));

// interface Props {
//   productDetails: ProductDetails
// }

// type CommercialAgentType = {
//   id?: string
//   company: Company
// }

function ProductDetailsFormViewer({
  productDetails,
  edit,
  agent,
  agentComission = 0,
}) {
  const { t } = useTranslation();
  const [productDetailsState, setProductDetailsState] = useState({
    ...productDetails,
  });

  const form = useForm({
    mode: 'onChange',
    defaultValues: productDetails,
    resolver: yupResolver(ProductDetailsSchema),
  });
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors },
  } = form;

  const classes = useStyles();
  const stepper = useContext(StepperContext);

  const submit = data => {
    stepper.next({ productDetails: data });
  };

  const product = watch('product');
  const { productBrand } = watch();

  useEffect(() => {
    switch (productBrand) {
      case 'mastercard':
        if (edit) {
          setValue('campaign.issueFee', productDetails.campaign.issueFee);
          setProductDetailsState({
            ...productDetailsState,
            campaign: {
              ...productDetailsState.campaign,
              issueFee: productDetails.campaign.issueFee,
            },
          });
        } else {
          setValue('campaign.issueFee', cardFees.mastercard.operatorFees.price);
          setProductDetailsState({
            ...productDetailsState,
            campaign: {
              ...productDetailsState.campaign,
              issueFee: cardFees.mastercard.operatorFees.price,
            },
          });
        }
        break;
      default:
        if (edit) {
          setValue('campaign.issueFee', productDetails.campaign.issueFee);
          setProductDetailsState({
            ...productDetailsState,
            campaign: {
              ...productDetailsState.campaign,
              issueFee: productDetails.campaign.issueFee,
            },
          });
        } else {
          setValue('campaign.issueFee', cardFees.visa.operatorFees.price);
          setProductDetailsState({
            ...productDetailsState,
            campaign: {
              ...productDetailsState.campaign,
              issueFee: cardFees.visa.operatorFees.price,
            },
          });
        }

        break;
    }
  }, [productBrand]);

  useEffect(() => {
    if (product === 'digital_account') {
      setValue('campaign.issueFee', cardFees.visa.operatorFees.price);
      setProductDetailsState({
        ...productDetailsState,
        campaign: {
          ...productDetailsState.campaign,
          issueFee: cardFees.visa.operatorFees.price,
        },
        operatorFees: {
          ...productDetailsState.operatorFees,
          ...operatorFees,
        },
      });
    }
    if (product === 'transfer') {
      setValue('productBrand', '');
      setProductDetailsState({
        ...productDetailsState,
        productBrand: '',
      });
    }
  }, [product]);

  const isAdministrationRate = watch('campaign.isAdministrationRate');

  // if (loadingCommercialAgents) {
  //   return <SpinnerPage />;
  // }

  return (
    <form className={classes.form} onSubmit={handleSubmit(submit)}>
      <Container className={classes.content}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box p={7}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography variant="h5" component="h3">
                  {t('newContract.product.label')}
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Controller
                  control={control}
                  name="product"
                  render={props => (
                    <TextField
                      onChange={e => {
                        Object.keys(productDetails).forEach(v => {
                          const value = v;
                          setValue(value, productDetails[value]);
                        });
                        return props.field.onChange(e.target.value);
                      }}
                      value={props.field.value}
                      fullWidth
                      select
                      variant="outlined"
                      size="small"
                      label={t('newContract.product.category')}
                      error={!!errors.product}
                      helperText={errors.product?.message}
                      disabled
                    >
                      {PRODUCTS.map(p => (
                        <MenuItem key={p.value} value={p.value}>
                          {p.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
              {product === 'prepaid_card' && (
                <PrepaidCardLeftViewer form={form} />
              )}
              {product === 'digital_account' && (
                <DigitalAccountLeftViewer form={form} />
              )}
              {product === 'transfer' && <TransferLeftViewer form={form} />}

              <Grid item xs={12}>
                <hr />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <FormControl
                  component="fieldset"
                  size="small"
                  error={!!errors.campaign?.isAdministrationRate}
                >
                  <FormLabel component="legend">
                    {t('newContract.product.fees')}
                  </FormLabel>

                  <Controller
                    control={control}
                    name="campaign.isAdministrationRate"
                    render={props => (
                      <RadioGroup
                        onChange={e => {
                          props.field.onChange(e.target.value === 'true');
                        }}
                        value={String(props.field.value)}
                        aria-label="Possui"
                        row
                      >
                        <FormControlLabel
                          value="true"
                          control={<Radio disabled />}
                          label={t('newContract.product.percent')}
                        />
                        <FormControlLabel
                          value="false"
                          control={<Radio disabled />}
                          label={t('newContract.product.fixed')}
                        />
                      </RadioGroup>
                    )}
                  />
                  <FormHelperText>
                    {errors.campaign?.isAdministrationRate?.message}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item lg={6} md={6} sm={3} xs={5}>
                <Grid item xs={12}>
                  {isAdministrationRate && (
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      label={t('newContract.product.tax')}
                      value={productDetailsState.campaign.administrationRate}
                      onChange={e => {
                        setProductDetailsState({
                          ...productDetailsState,
                          campaign: {
                            ...productDetailsState.campaign,
                            administrationRate: e.target.value,
                          },
                        });
                      }}
                      error={!!errors.campaign?.administrationRate}
                      helperText={errors.campaign?.administrationRate?.message}
                      InputProps={{
                        inputComponent: NumberFormatRate,
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                        inputProps: {
                          ...register('campaign.administrationRate'),
                        },
                      }}
                      disabled
                    />
                  )}
                  {!isAdministrationRate && (
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      label={t('newContract.product.tax')}
                      value={productDetailsState.campaign.administrationFee}
                      onChange={e => {
                        setProductDetailsState({
                          ...productDetailsState,
                          campaign: {
                            ...productDetailsState.campaign,
                            administrationFee: e.target.value,
                          },
                        });
                      }}
                      error={!!errors.campaign?.administrationFee}
                      helperText={errors.campaign?.administrationFee?.message}
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                        inputProps: {
                          ...register('campaign.administrationFee'),
                        },
                      }}
                      disabled
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Box marginTop={8}>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      label={t('newContract.product.award')}
                      InputLabelProps={{ shrink: true }}
                      onChange={e => {
                        setProductDetailsState({
                          ...productDetailsState,
                          campaign: {
                            ...productDetailsState.campaign,
                            annualEstimatedAward: e.target.value,
                          },
                        });
                      }}
                      value={productDetailsState.campaign.annualEstimatedAward}
                      error={!!errors.campaign?.annualEstimatedAward}
                      helperText={
                        errors.campaign?.annualEstimatedAward?.message
                      }
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                        inputProps: {
                          ...register('campaign.annualEstimatedAward'),
                        },
                      }}
                      disabled
                    />
                  </Box>
                </Grid>
                {product === 'prepaid_card' && (
                  <Grid item xs={12}>
                    <Box marginTop={8}>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        label={t('newContract.product.cardPrice')}
                        value={productDetailsState.campaign.issueFee}
                        onChange={e => {
                          setProductDetailsState({
                            ...productDetailsState,
                            campaign: {
                              ...productDetailsState.campaign,
                              issueFee: e.target.value,
                            },
                          });
                        }}
                        error={!!errors.campaign?.issueFee}
                        helperText={errors.campaign?.issueFee?.message}
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                          inputProps: {
                            ...register('campaign.issueFee'),
                          },
                        }}
                        InputLabelProps={{ shrink: true }}
                        disabled
                      />
                    </Box>
                  </Grid>
                )}
                {product === 'digital_account' && (
                  <Grid item xs={12}>
                    <Box marginTop={8}>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        label="Preço da Conta"
                        value={productDetailsState.campaign.issueFee}
                        onChange={e => {
                          setProductDetailsState({
                            ...productDetailsState,
                            campaign: {
                              ...productDetailsState.campaign,
                              issueFee: e.target.value,
                            },
                          });
                        }}
                        error={!!errors.campaign?.issueFee}
                        helperText={errors.campaign?.issueFee?.message}
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                          inputProps: {
                            ...register('campaign.issueFee'),
                          },
                        }}
                        disabled
                      />
                    </Box>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  component="fieldset"
                  size="small"
                  error={!!errors.campaign?.rechargeFrequency}
                >
                  <FormLabel component="legend">
                    {t('newContract.product.readjustment')}
                  </FormLabel>

                  <Controller
                    control={control}
                    name="campaign.rechargeFrequency"
                    render={props => (
                      <RadioGroup
                        onChange={e => {
                          props.field.onChange(e.target.value);
                        }}
                        value={props.field.value}
                        aria-label="Possui"
                        row
                      >
                        <FormControlLabel
                          // value="monthly"
                          value="2"
                          control={<Radio disabled />}
                          label="2"
                        />
                        <FormControlLabel
                          // value="quarterly"
                          value="3"
                          control={<Radio disabled />}
                          label="3"
                        />
                        <FormControlLabel
                          // value="yearly"
                          value="4"
                          control={<Radio disabled />}
                          label="4"
                        />
                        <FormControlLabel
                          // value="only"
                          value="6"
                          control={<Radio disabled />}
                          label="6"
                        />
                        <FormControlLabel
                          // value="only"
                          value="12"
                          control={<Radio disabled />}
                          label="12"
                        />
                      </RadioGroup>
                    )}
                  />
                  <FormHelperText>
                    {errors.campaign?.rechargeFrequency?.message}
                  </FormHelperText>
                </FormControl>
              </Grid>
              {agent && (
                <>
                  <Grid item xs={12}>
                    <FormLabel component="legend">Agente comercial</FormLabel>
                    {/* <Box p={{ xs: 3, sm: 3, md: 4, lg: 5 }}> */}
                    <SelectAsyncCommercialAgent
                      value={agent}
                      // onChange={setAgent}
                      placeholder="Agente comercial"
                      menuPlacement="auto"
                      disabled
                    />
                    {/* </Box> */}
                    <FormHelperText error>
                      {errors.commercialAgent?.id?.message}
                    </FormHelperText>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Controller
                      control={control}
                      name="commission_calculation"
                      render={props => (
                        <TextField
                          onChange={e => props.field.onChange(e.target.value)}
                          value={props.field.value}
                          fullWidth
                          select
                          variant="outlined"
                          size="small"
                          label="Cálculo comissionamento"
                          disabled
                          // error={!!errors.product}
                          // helperText={errors.product?.message}
                        >
                          {CALC_COMISSION.map(p => (
                            <MenuItem key={p.value} value={p.value}>
                              {p.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      // label={t('newContract.product.tax')}
                      label="Comissão do agente"
                      value={agentComission}
                      // onChange={e => {
                      //   setProductDetailsState({
                      //     ...productDetailsState,
                      //     campaign: {
                      //       ...productDetailsState.campaign,
                      //       administrationRate: e.target.value,
                      //     },
                      //   });
                      // }}
                      // error={!!errors.campaign?.administrationRate}
                      // helperText={errors.campaign?.administrationRate?.message}
                      InputProps={{
                        inputComponent: NumberFormatRate,
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                        // inputProps: {
                        //   ...register('campaign.administrationRate'),
                        // },
                      }}
                      disabled
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        </Grid>
        {product === 'prepaid_card' && <PrepaidCardRightViewer form={form} />}
        {product === 'transfer' && <TransferRightViewer form={form} />}
        {product === 'digital_account' && (
          <DigitalAccountRightViewer form={form} />
        )}
      </Container>

      {/* <div className={classes.footer}>
        <Button onClick={stepper.prev} type="button" variant="contained">
          Voltar
        </Button>
        <Button type="submit" variant="contained" color="primary">
          Avançar
        </Button>
      </div> */}
    </form>
  );
}

export default ProductDetailsFormViewer;
