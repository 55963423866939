/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Dialog } from '@material-ui/core';
import MyButton from '../../components/button/MyButton';
import TableUser from '../../components/tables/users/TableUsers';
import NewUser from './newUser/NewUser';
import styles from '../../styles/Layout.module.css';
import routing from '../../service/routes/routing';
import Loading from '../../components/loading/Loading';

import api from '../../service/axios/api';

export default function Users() {
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itens, setItens] = useState({ label: 25, value: 25 });
  const [users, setUsers] = useState([]);
  const [order, setOrder] = useState({ label: 'status', value: '' });
  const history = useHistory();

  const search = useRef(null);

  const [status, setStatus] = useState({ label: 'status', value: '' });

  const find = () => {
    setLoading(true);
    const cancel = new AbortController();
    api.user
      .getAll(
        order.value,
        search.current.value,
        status.value,
        itens.value,
        page,
        cancel,
      )
      .then(res => {
        setLoading(false);
        setTotalPages(res.pages);
        setUsers(res.docs);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };
  useEffect(() => {
    find();
  }, [status, page, itens, order]);

  return (
    <>
      <Loading open={loading} msg="Buscando usuários..." />
      <div className="col-sm-12" style={{ marginTop: 50 }}>
        <div
          className="col-sm-12"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'start',
          }}
        >
          <div>
            <p
              className={styles.textMenu}
              // onKeyDown={() => history.push(routing.dashboard)}
              onClick={() => history.push(routing.dashboard)}
            >
              Menu Incial
            </p>
          </div>
        </div>
        <div
          className="col-sm-12"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <h4 className={styles.textTitle}>Usuários</h4>
          <MyButton
            style={{ marginRight: 50, height: 40, width: 140 }}
            onClick={() => setOpenModal(true)}
            label="Novo Usuário"
          />
        </div>
        <div>
          <TableUser
            itens={itens}
            setItens={setItens}
            users={users}
            totalPages={totalPages}
            setPage={setPage}
            setStatus={setStatus}
            search={search}
            find={find}
            setOrder={setOrder}
          />
        </div>
      </div>
      <Dialog
        onClose={() => setOpenModal(false)}
        open={openModal}
        fullWidth
        maxWidth="lg"
      >
        <NewUser setOpenModal={setOpenModal} />
      </Dialog>
    </>
  );
}
