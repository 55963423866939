/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-unescaped-entities */
import * as React from 'react';

// eslint-disable-next-line no-unused-vars

import { Grid, Box } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
// import TextField from '@mui/material/TextField';

const useStyles = makeStyles(() =>
  createStyles({
    myTextField: {
      backgroundColor: 'none',
      boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
      border: 'none',
      color: '#717171',
      fontWeight: 'bold',
      fontFamily: 'Open Sans',
    },
    text: {
      fontFamily: 'Open Sans',
      fontWeight: 'bold',
      color: '#717171',
    },
    myLabel: {
      marginRight: 10,
      fontFamily: 'Open Sans',
      fontWeight: 'bold',
      color: '#717171',
    },
    response: {
      marginRight: 10,
      fontFamily: 'Open Sans',
      color: '#717171',
    },
  }),
);

export default function Review() {
  const classes = useStyles();
  return (
    // <div className="box-new-card" style={{ height: 370, overflowY: 'scroll' }}>
    <Grid
      container
      spacing={2}
      style={{
        border: '2px solid #c5c5c5',
        borderRadius: 10,
        justifyContent: 'center',
        margin: 15,
        padding: 30,
      }}
    >
      {/* <div style={{
        display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
         alignItems: 'center', marginTop: -15, marginBottom: -5,
      }}
      > */}
      <Grid
        container
        spacing={2}
        style={{
          justifyContent: 'start',
          // margin: 15,
          // padding: 30,
        }}
      >
        {/* <Grid
      container
      spacing={2}
      style={{
        border: '2px solid #c5c5c5', borderRadius: 10,
        justifyContent: 'center', margin: 15, padding: 30,
      }}
    > */}
        <h5 className="text-box-inform">Revisão e confirmação</h5>
        {/* <p className="text-box-inform-2">COD.: PROD_CDPP_00</p> */}
        {/* </div> */}
      </Grid>
      <Grid item xs={12} sm={8} md={5} lg={5} xl={5}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
          }}
        >
          <label className={classes.myLabel}>Categoria:</label>
          <label className={classes.response}>Acessório</label>
        </Box>
      </Grid>
      <Grid item xs={12} sm={8} md={5} lg={5} xl={5}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
          }}
        >
          <label className={classes.myLabel}>Tipo:</label>
          <label className={classes.response}>Seguro Dental</label>
        </Box>
      </Grid>
      <Grid item xs={12} sm={8} md={5} lg={5} xl={5}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
          }}
        >
          <label className={classes.myLabel}>Produto:</label>
          <label className={classes.response}>XXXX</label>
        </Box>
      </Grid>
      <Grid item xs={12} sm={8} md={5} lg={5} xl={5}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
          }}
        >
          <label className={classes.myLabel}>Faturado:</label>
          <label className={classes.response}>
            Chave empresa ou beneficiário
          </label>
        </Box>
      </Grid>
      <Grid item xs={12} sm={8} md={5} lg={5} xl={5}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
          }}
        >
          <label className={classes.myLabel}>Cobrança:</label>
          <label className={classes.response}>Recorrente ou única</label>
        </Box>
      </Grid>
      <Grid item xs={12} sm={8} md={5} lg={5} xl={5}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
          }}
        >
          <label className={classes.myLabel}>Custo unitário:</label>
          <label className={classes.response}>R$00.00</label>
        </Box>
      </Grid>
      <Grid item xs={12} sm={8} md={5} lg={5} xl={5}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
          }}
        >
          <label className={classes.myLabel}>Preço unitário:</label>
          <label className={classes.response}>R$00.00</label>
        </Box>
      </Grid>
      <Grid item xs={12} sm={8} md={5} lg={5} xl={5}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
          }}
        >
          <label className={classes.myLabel}>Taxa Adm:</label>
          <label className={classes.response}>XX% ou XX BRL/unid</label>
        </Box>
      </Grid>

      <Grid item xs={12} sm={8} md={5} lg={5} xl={5} />
      {/* <p className="review-label-1">Conta Digital</p>
      <div style={{
        display: 'flex', flexDirection: 'row', justifyContent:
        'space-between', alignItems: 'center', marginTop: -50,
      }}
      >
        <div className="my-colunm-align ">
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <p className="review-label ">Nome:</p>
            <p className="review-value "> Cartão Pré-Pago</p>
            <img className="review-img" src="./img/edit.svg" alt="logo" />
          </div>
          <div style={{
            display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: -30,
          }}
          >
            <p className="review-label ">Recarga:</p>
            <p className="review-label ">Sistema:</p>
            <p className="review-value "> R$00.00</p>
            <img className="review-img" src="./img/edit.svg" alt="logo" />
          </div>
          <div style={{
            display: 'flex', flexDirection: 'row', alignItems:
             'center', marginTop: -35, marginLeft: 40,
          }}
          >
            <p className="review-label " />
            <p className="review-label ">PDV:</p>
            <p className="review-value "> R$00.00</p>
            <img className="review-img" src="./img/edit.svg" alt="logo" />
          </div>
        </div>
        <div className="my-colunm-align ">
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <p className="review-label ">Adesão conta:</p>
            <p className="review-value "> R$00.00</p>
            <img className="review-img" src="./img/edit.svg" alt="logo" />
          </div>
          <div style={{
            display: 'flex', flexDirection: 'row', alignItems:
            'center', marginTop: -30, marginLeft: -80,
          }}
          >
            <p className="review-label ">Saques:</p>
            <p className="review-label ">QR:</p>
            <img className="review-img" src="./img/edit.svg" alt="logo" />
          </div>
          <div style={{
            display: 'flex', flexDirection: 'row', alignItems:
            'center', marginTop: -35, marginLeft: 25,
          }}
          >
            <p className="review-label " />
            <p className="review-label ">Loterica:</p>
            <img className="review-img" src="./img/edit.svg" alt="logo" />
          </div>
          <div style={{
            display: 'flex', flexDirection: 'row', alignItems:
             'center', marginTop: -35, marginLeft: -5,
          }}
          >
            <p className="review-label " />
            <p className="review-label ">PDV:</p>
            <img className="review-img" src="./img/edit.svg" alt="logo" />
          </div>
        </div>
        <div className="my-colunm-align ">
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <p className="review-label ">Mensalidade:</p>
            <p className="review-value "> R$00.00</p>
            <img className="review-img" src="./img/edit.svg" alt="logo" />
          </div>
          <div style={{
            display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: -30,
          }}
          >
            <p className="review-label ">Recarga:</p>
            <p className="review-label ">Sistema:</p>
            <p className="review-value "> R$00.00</p>
            <img className="review-img" src="./img/edit.svg" alt="logo" />
          </div>
        </div>
        <div className="my-colunm-align ">
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <p className="review-label ">Transferência TED:</p>
            <p className="review-value "> R$00.00</p>
            <img className="review-img" src="./img/edit.svg" alt="logo" />
          </div>
          <div style={{
            display: 'flex', flexDirection: 'row', alignItems:
             'center', marginTop: -35, marginLeft: 135,
          }}
          >
            <p className="review-label " />
            <p className="review-label ">Pix(PF):</p>
            <p className="review-value "> R$00.00</p>
            <img className="review-img" src="./img/edit.svg" alt="logo" />
          </div>
          <div style={{
            display: 'flex', flexDirection: 'row', alignItems:
            'center', marginTop: -35, marginLeft: 135,
          }}
          >
            <p className="review-label " />
            <p className="review-label ">PIX(PJ):</p>
            <p className="review-value "> R$00.00</p>
            <img className="review-img" src="./img/edit.svg" alt="logo" />
          </div>
        </div>
      </div>
      <p className="review-label-1">Cartão</p>
      <div style={{
        display: 'flex', flexDirection: 'row', justifyContent:
        'space-between', alignItems: 'center', marginTop: -40,
      }}
      >
        <div className="my-colunm-align ">
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <p className="review-label ">Cartão:</p>
            <p className="review-value "> Virtual</p>
            <img className="review-img" src="./img/edit.svg" alt="logo" />
          </div>
          <div style={{
            display: 'flex', flexDirection: 'row', alignItems:
            'center', marginTop: -30, marginLeft: 50,
          }}
          >
            <p className="review-value "> Físico</p>
            <img className="review-img" src="./img/edit.svg" alt="logo" />
          </div>
        </div>
        <div className="my-colunm-align ">
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <p className="review-label ">Bandeira:</p>
            <p className="review-value "> Mastercard</p>
            <img className="review-img" src="./img/edit.svg" alt="logo" />
          </div>
        </div>
        <div className="my-colunm-align ">
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <p className="review-label ">Emissor:</p>
            <p className="review-value "> Acesso</p>
            <img className="review-img" src="./img/edit.svg" alt="logo" />
          </div>
        </div>
        <div className="my-colunm-align ">
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <p className="review-label ">Personalização:</p>
            <p className="review-value "> Sim/Não</p>
            <img className="review-img" src="./img/edit.svg" alt="logo" />
          </div>
        </div>
        <div className="my-colunm-align ">
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <p className="review-label ">Cobertura:</p>
            <p className="review-value "> Internacional</p>
            <img className="review-img" src="./img/edit.svg" alt="logo" />
          </div>
        </div>
        <div className="my-colunm-align ">
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <p className="review-label ">Função:</p>
            <p className="review-value "> Crédito</p>
            <img className="review-img" src="./img/edit.svg" alt="logo" />
          </div>
        </div>
      </div>
      <p className="review-label-1">Cartão físico</p>
      <div style={{
        display: 'flex', flexDirection: 'row', justifyContent:
        'start', alignItems: 'center', marginTop: -40,
      }}
      >
        <div className="my-colunm-align ">
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <p className="review-label ">Valor da emissão:</p>
            <p className="review-value "> R$00.00</p>
            <img className="review-img" src="./img/edit.svg" alt="logo" />
          </div>
        </div>
        <div className="my-colunm-align ">
          <div style={{
            display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 30,
          }}
          >
            <p className="review-label ">Tarifa portador:</p>
            <p className="review-value "> R$00.00</p>
            <img className="review-img" src="./img/edit.svg" alt="logo" />
          </div>
        </div>
        <div className="my-colunm-align ">
          <div style={{
            display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 30,
          }}
          >
            <p className="review-label ">Limites:</p>
            <p className="review-value "> R$00.00</p>
            <img className="review-img" src="./img/edit.svg" alt="logo" />
          </div>
        </div>

      </div> */}
      {/* </div> */}
    </Grid>
  );
}
