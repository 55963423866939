/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import * as React from 'react';
// import Grid from '@mui/material/Grid';
// import Typography from '@mui/material/Typography';
// import TextField from '@mui/material/TextField';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
import Select from 'react-select';
import { styled, Grid, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styles from '../../../styles/Layout.module.css';

const options = [
  {
    label: 'JoyCard',
    value: 1,
  },
];

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark'
        ? 'rgba(57,75,89,.5)'
        : 'rgba(206,217,224,.5)',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#41b490',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#41b490',
  },
});

export default function DisplayError({ errors, specOrder, setModalError, hasTrim, resend }) {
  const { t } = useTranslation();
  // const params= [
	// 	{
	// 		"item": "0",
	// 		"message": "Cod. da operação 128877 com tamanho maior que 4"
	// 	},
	// 	{
	// 		"item": "0",
	// 		"message": "Cod. verificador da conta 123 com tamanho maior que 1"
	// 	},{
	// 		"item": "0",
	// 		"message": "Cod. da operação 128877 com tamanho maior que 4"
	// 	},
	// 	{
	// 		"item": "0",
	// 		"message": "Cod. verificador da conta 123 com tamanho maior que 1"
	// 	},{
	// 		"item": "0",
	// 		"message": "Cod. da operação 128877 com tamanho maior que 4"
	// 	},
	// 	{
	// 		"item": "0",
	// 		"message": "Cod. verificador da conta 123 com tamanho maior que 1"
	// 	},{
	// 		"item": "0",
	// 		"message": "Cod. da operação 128877 com tamanho maior que 4"
	// 	},
	// 	{
	// 		"item": "0",
	// 		"message": "Cod. verificador da conta 123 com tamanho maior que 1"
	// 	},{
	// 		"item": "0",
	// 		"message": "Cod. da operação 128877 com tamanho maior que 4"
	// 	},
	// 	{
	// 		"item": "0",
	// 		"message": "Cod. verificador da conta 123 com tamanho maior que 1"
	// 	},{
	// 		"item": "0",
	// 		"message": "Cod. da operação 128877 com tamanho maior que 4"
	// 	},
	// 	{
	// 		"item": "0",
	// 		"message": "Cod. verificador da conta 123 com tamanho maior que 1"
	// 	},{
	// 		"item": "0",
	// 		"message": "Cod. da operação 128877 com tamanho maior que 4"
	// 	},
	// 	{
	// 		"item": "0",
	// 		"message": "Cod. verificador da conta 123 com tamanho maior que 1"
	// 	},{
	// 		"item": "0",
	// 		"message": "Cod. da operação 128877 com tamanho maior que 4"
	// 	},
	// 	{
	// 		"item": "0",
	// 		"message": "Cod. verificador da conta 123 com tamanho maior que 1"
	// 	},{
	// 		"item": "0",
	// 		"message": "Cod. da operação 128877 com tamanho maior que 4"
	// 	},
	// 	{
	// 		"item": "0",
	// 		"message": "Cod. verificador da conta 123 com tamanho maior que 1"
	// 	},{
	// 		"item": "0",
	// 		"message": "Cod. da operação 128877 com tamanho maior que 4"
	// 	},
	// 	{
	// 		"item": "0",
	// 		"message": "Cod. verificador da conta 123 com tamanho maior que 1"
	// 	},{
	// 		"item": "0",
	// 		"message": "Cod. da operação 128877 com tamanho maior que 4"
	// 	},
	// 	{
	// 		"item": "0",
	// 		"message": "Cod. verificador da conta 123 com tamanho maior que 1"
	// 	},{
	// 		"item": "0",
	// 		"message": "Cod. da operação 128877 com tamanho maior que 4"
	// 	},
	// 	{
	// 		"item": "0",
	// 		"message": "Cod. verificador da conta 123 com tamanho maior que 1"
	// 	},{
	// 		"item": "0",
	// 		"message": "Cod. da operação 128877 com tamanho maior que 4"
	// 	},
	// 	{
	// 		"item": "0",
	// 		"message": "Cod. verificador da conta 123 com tamanho maior que 1"
	// 	},{
	// 		"item": "0",
	// 		"message": "Cod. da operação 128877 com tamanho maior que 4"
	// 	},
	// 	{
	// 		"item": "0",
	// 		"message": "Cod. verificador da conta 123 com tamanho maior que 1"
	// 	},{
	// 		"item": "0",
	// 		"message": "Cod. da operação 128877 com tamanho maior que 4"
	// 	},
	// 	{
	// 		"item": "0",
	// 		"message": "Cod. verificador da conta 123 com tamanho maior que 1"
	// 	},{
	// 		"item": "0",
	// 		"message": "Cod. da operação 128877 com tamanho maior que 4"
	// 	},
	// 	{
	// 		"item": "0",
	// 		"message": "Cod. verificador da conta 123 com tamanho maior que 1"
	// 	},{
	// 		"item": "0",
	// 		"message": "Cod. da operação 128877 com tamanho maior que 4"
	// 	},
	// 	{
	// 		"item": "0",
	// 		"message": "Cod. verificador da conta 123 com tamanho maior que 1"
	// 	},{
	// 		"item": "0",
	// 		"message": "Cod. da operação 128877 com tamanho maior que 4"
	// 	},
	// 	{
	// 		"item": "0",
	// 		"message": "Cod. verificador da conta 123 com tamanho maior que 1"
	// 	},{
	// 		"item": "0",
	// 		"message": "Cod. da operação 128877 com tamanho maior que 4"
	// 	},
	// 	{
	// 		"item": "0",
	// 		"message": "Cod. verificador da conta 123 com tamanho maior que 1"
	// 	},{
	// 		"item": "0",
	// 		"message": "Cod. da operação 128877 com tamanho maior que 4"
	// 	},
	// 	{
	// 		"item": "0",
	// 		"message": "Cod. verificador da conta 123 com tamanho maior que 1"
	// 	},{
	// 		"item": "0",
	// 		"message": "Cod. da operação 128877 com tamanho maior que 4"
	// 	},
	// 	{
	// 		"item": "0",
	// 		"message": "Cod. verificador da conta 123 com tamanho maior que 1"
	// 	},{
	// 		"item": "0",
	// 		"message": "Cod. da operação 128877 com tamanho maior que 4"
	// 	},
	// 	{
	// 		"item": "0",
	// 		"message": "Cod. verificador da conta 123 com tamanho maior que 1"
	// 	},{
	// 		"item": "0",
	// 		"message": "Cod. da operação 128877 com tamanho maior que 4"
	// 	},
	// 	{
	// 		"item": "0",
	// 		"message": "Cod. verificador da conta 123 com tamanho maior que 1"
	// 	},{
	// 		"item": "0",
	// 		"message": "Cod. da operação 128877 com tamanho maior que 4"
	// 	},
	// 	{
	// 		"item": "0",
	// 		"message": "Cod. verificador da conta 123 com tamanho maior que 1"
	// 	},{
	// 		"item": "0",
	// 		"message": "Cod. da operação 128877 com tamanho maior que 4"
	// 	},
	// 	{
	// 		"item": "0",
	// 		"message": "Cod. verificador da conta 123 com tamanho maior que 1"
	// 	},{
	// 		"item": "0",
	// 		"message": "Cod. da operação 128877 com tamanho maior que 4"
	// 	},
	// 	{
	// 		"item": "0",
	// 		"message": "Cod. verificador da conta 123 com tamanho maior que 1"
	// 	},{
	// 		"item": "0",
	// 		"message": "Cod. da operação 128877 com tamanho maior que 4"
	// 	},
	// 	{
	// 		"item": "0",
	// 		"message": "Cod. verificador da conta 123 com tamanho maior que 1"
	// 	},{
	// 		"item": "0",
	// 		"message": "Cod. da operação 128877 com tamanho maior que 4"
	// 	},
	// 	{
	// 		"item": "0",
	// 		"message": "Cod. verificador da conta 123 com tamanho maior que 1"
	// 	},{
	// 		"item": "0",
	// 		"message": "Cod. da operação 128877 com tamanho maior que 4"
	// 	},
	// 	{
	// 		"item": "0",
	// 		"message": "Cod. verificador da conta 123 com tamanho maior que 1"
	// 	},{
	// 		"item": "0",
	// 		"message": "Cod. da operação 128877 com tamanho maior que 4"
	// 	},
	// 	{
	// 		"item": "0",
	// 		"message": "Cod. verificador da conta 123 com tamanho maior que 1"
	// 	},{
	// 		"item": "0",
	// 		"message": "Cod. da operação 128877 com tamanho maior que 4"
	// 	},
	// 	{
	// 		"item": "0",
	// 		"message": "Cod. verificador da conta 123 com tamanho maior que 1"
	// 	}
	// ]
  return (
    <div style={{maxHeight: '400px'}}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'start',
          alignItems: 'center',
        }}
      >
        {specOrder === 'completo' && (
          <h6 className={styles.textBoxInform}>
            {t('newOrder.cardExists.labelComplete')}
          </h6>
        )}
      </div>
      <h6 className={styles.labelGridSecond} style={{color: 'red', marginLeft: 15}}>
        Os seguintes erros foram encontrados em sua planilha:
      </h6>
      <Grid
        container
        spacing={1}
        direction="column"
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          padding: 40,
        }}
      >
        <Grid item>
          {errors?.map((p)=>(
						p.message === 'Produto não informado. Nenhum item da planilha será processado' ?(
							<p>{p.message}</p>
						):(
							<p>Linha {Number(p.item)+1}: {p.message}</p>
						)
          ))}
        </Grid>
      </Grid>
      {hasTrim && (<h6 className={styles.labelGridSecond} style={{ marginLeft: 15}}>
        Retiramos alguns espaços em branco e/ou a formatação do campo CPF/CNPJ que constavam na planilha. Deseja seguir com estas alterações?
      </h6>)}
      <div style={{ display: 'flex', justifyContent: 'end', margin: 15}}>
        {hasTrim ? (
          <>
          <Button color="primary" variant='outlined' style={{color: 'red', borderColor: 'red'}} onClick={()=> setModalError(false)}>
            CANCELAR
          </Button>
          <Button style={{marginLeft: 10}} color="primary" variant='contained' onClick={resend}>
            SEGUIR
          </Button>
      </>
        ):(
          <Button color="primary" variant='contained' onClick={()=> setModalError(false)}>
          OK
        </Button>
        )}
      </div>
    </div>
  );
}
