import { string, number, object, boolean } from 'yup';
// import CompanySchema from './CompanySchema';

// const campaign = object({
//   name: string().required('Campo obrigatório'),
//   rechargeFrequency: string().required('Campo obrigatório'),
//   annualEstimatedAward: string(),
//   totalCommissionRate: number()
//     .min(0, 'Não pode ser negativo')
//     .typeError('Deve ser um número'),
//   isAdministrationRate: boolean().required('Campo obrigatório'),
//   // only when is_administration_rate is true
//   administrationRate: string(),
//   // only when is_administration_rate is false
//   administrationFee: string(),
//   // only when product category is a card
//   issueFee: string(),
// }).required();

const operatorFees = object({
  // For virtual_card and transfer
  emitionTicket: string(),
  pixPf: string(),
  pixPj: string(),
  lotteryDeposit: string(),
  lotteryWithdrawal: string(),
  PDV: string(),
  aditionalCard: string(),
  expiredCard: string(),
  codeQR: string(),
  TED: string(),
  SMS: string(),
  // only for prepaid_cards
  monthlyFee: string(),
  unlockFee: string(),
  reissueFee: string(),
  chargebackFee: string(),
  atmWithdrawFee: string(),
  markupRate: string(),
  rechargePortalFee: string(),
  rechargeInvoiceFee: string(),
  p2pTransferFee: string(),
  // only for transfers
  transferFee: string(),
  rejectedTransactionFee: string(),
  // only for checks
  checkFee: string(),
  // only for vouchers
  voucherFee: string(),
}).required();

const companyOperatingFees = object({
  // For virtual_card and prepaid_card
  KYC: string(),
  // Only for prepaid_card
  balanceTransferFee: string(),
  minimumLoadAmount: string(),
  belowMinimumLoadFee: string(),
  emergencyLoadFee: string(),
  specialHandlingFee: string(),
  chargebackRate: string(),
  rejectedTransactionFee: string(),
});

// For virtual_card and transfer
const cardFees = object({
  physicalCard: string(),
  sendCard: string(),
  cardWithdrawal: string(),
});

const ProductDetailsSchema = object({
  operatorFees: operatorFees.required(),
  // campaign: campaign.required(),
  product: string().required('Campo obrigatório'),
  productBrand: string(),
  // commercialAgent: object({
  //   id: string().required('Selecione um agente comercial'),
  //   company: CompanySchema,
  // }).required('Selecione um agente comercial'),
  // only for cards
  companyOperatingFees,
  salesChannel: string(),
  cardFees,
  classAccount: string(),
  isGiftCard: boolean(),
  kindAccount: string(),
  isInternationalCard: boolean(),
  cardQuantity: number()
    .min(1, 'Deve ser uma quantidade positiva')
    .typeError('Deve ser um número'),
  isChipCard: boolean(),
  issuer: string(),
});

export default ProductDetailsSchema;
