/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Grid,
  Box,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
} from '@material-ui/core';
import Select from 'react-select';
import moment from 'moment-timezone';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import PublishIcon from '@material-ui/icons/Publish';
import Chip from '@material-ui/core/Chip';
import _ from 'lodash';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import locale from 'date-fns/locale/pt-BR';
import DateFnsUtils from '@date-io/date-fns';
import UserRoles from '../../service/acl/UserRoles';
import PermissionComponent from '../../components/permissionComponent/PermissionComponent';
import routing from '../../service/routes/routing';
import Loading from '../../components/loading/Loading';
import api from '../../service/axios/api';
import styles from '../../styles/Layout.module.css';
import ReleaseMoneyTable from './ReleaseMoneyTable';
import ReleaseMoneyTableCR from './ReleaseMoneyTableCR';
import FlashContext from '../../components/contexts/FlashContext';

const customStyles1 = {
  control: provided => ({
    ...provided,
    width: 125,
    height: 40,
    borderRadius: 10,
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  option: provided => ({
    ...provided,
  }),
};

const customStyles2 = {
  control: provided => ({
    ...provided,
    marginRight: 10,
    width: 135,
    height: 45,
    borderRadius: 5,
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  option: provided => ({
    ...provided,
  }),
};

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: 10,
    margin: 0,
  },
  chip: {
    margin: 10,
  },
  textField: {
    // width: '90%',
    // // height: '40px',
    // marginLeft: 'auto',
    // marginRight: 'auto',
    // paddingTop: '2px',
    // paddingBottom: 0,
    // marginTop: 0,
    // fontWeight: 500,
    // backgroundColor: '#fff',
  },
  input: {
    backgroundColor: '#fff',
    marginTop: 0,
    // color: 'white',
  },
  mySwitch: {
    '&.MuiSwitch-colorPrimary.MuiSwitch-checked': {
      color: 'green',
    },
  },
  radio: {
    color: '#9c9c9c',
    '&$checked': {
      color: '#41b490',
    },
  },
  checked: {},
  switchBase: {
    color: '#fff',
    '&$checked': {
      color: '#41b490',
    },
    '&$checked + $track': {
      backgroundColor: '#41b490',
    },
  },
  track: {},
});

function ReleaseMoney() {
  const { t } = useTranslation();
  const { notifyError, notifySuccess } = useContext(FlashContext);
  const [filter, setFilter] = useState({
    label: t('releaseMoney.filters.all'),
    value: '',
  });
  const classes = useStyles();
  const [statusSelected, setStatusSelected] = useState({
    label: 'Aguardando liberação do cartão ou processamento',
    value: '',
  });
  const [bankSelected, setBankSelected] = useState({
    label: 'Todos',
    value: '',
  });
  const searchRef = useRef(null);
  const fileC = useRef(null);
  const history = useHistory();
  const [selectedDate, handleDateChange] = useState(new Date());

  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [total, setTotal] = useState(0);
  const [itens, setItens] = useState(10);
  const optionsProduct = [
    {
      label: t('releaseMoney.filters.card'),
      value: 'card',
    },
    {
      label: t('releaseMoney.filters.transfer'),
      value: 'transfer',
    },
    {
      label: t('releaseMoney.filters.all'),
      value: '',
    },
  ];
  const [searchType, setSearchType] = useState({
    label: t('releaseMoney.filters.cpf'),
    value: 'cpf',
  });
  const [loading, setLoading] = useState(false);
  const [loadingCancel, setLoadingCancel] = useState(false);
  const [loadingReturn, setLoadingReturn] = useState(false);
  const [chipData, setChipData] = useState([]);
  const [options, setOptions] = useState([
    {
      label: t('releaseMoney.filters.cpf'),
      value: 'cpf',
    },
    {
      label: t('releaseMoney.filters.invoiceFrom'),
      value: 'billed',
    },
    {
      label: t('releaseMoney.filters.bearers'),
      value: 'name',
    },
    {
      label: t('releaseMoney.filters.nf'),
      value: 'invoice',
    },
    {
      label: t('releaseMoney.filters.nd'),
      value: 'debitNote',
    },
    {
      label: t('releaseMoney.filters.paymentDate'),
      value: 'datePayment',
    },
  ]);
  const [open, setOpen] = useState(false);
  const [openCancel, setOpenCancel] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [selected, setSelected] = useState([]);
  const [file, setFile] = useState();
  const [bankReturn, setBankReturn] = useState();

  // const [nfnd, setNfnd] = useState('nf');

  // useEffect(() => {
  //   console.log(nfnd);
  // }, [nfnd]);

  useEffect(() => {
    if (
      statusSelected.value === 'Canceled' &&
      chipData.length + options.length === 4
    ) {
      setChipData([]);
      setSearchType({ label: t('releaseMoney.filters.cpf'), value: 'cpf' });
      setOptions([
        { label: t('releaseMoney.filters.card'), value: 'orderNumber' },
        { label: t('releaseMoney.filters.nd'), value: 'debitNote' },
        { label: t('releaseMoney.filters.nf'), value: 'invoice' },
        { label: t('releaseMoney.filters.invoiceFrom'), value: 'billed' },
        { label: t('releaseMoney.filters.bearers'), value: 'name' },
        { label: t('releaseMoney.filters.cpf'), value: 'cpf' },
        { label: t('releaseMoney.filters.value'), value: 'value' },
      ]);
    } else if (
      statusSelected.value !== 'Canceled' &&
      chipData.length + options.length === 7
    ) {
      setChipData([]);
      setSearchType({ label: t('releaseMoney.filters.cpf'), value: 'cpf' });
      setOptions([
        { label: t('releaseMoney.filters.cpf'), value: 'cpf' },
        { label: t('releaseMoney.filters.invoiceFrom'), value: 'billed' },
        { label: t('releaseMoney.filters.bearers'), value: 'name' },
        { label: t('releaseMoney.filters.nf'), value: 'invoice' },
      ]);
    }
  }, [statusSelected]);
  const search = extra => {
    setSelected([]);
    let internItens = null;
    let internPage = null;
    setLoading(true);
    const cancel = new AbortController();
    if (extra) {
      if (extra.byType === 'invoice' || extra.byType === 'debitNote') {
        internItens = 1000;
        internPage = 0;
        setPage(0);
      }
    }
    if (chipData.find(c => c.byType === 'invoice' || 'debitNote')) {
      internItens = 5000;
      internPage = 0;
      setPage(0);
    }
    api.paymentReleases
      .get(
        extra,
        bankSelected.value,
        filter.value,
        statusSelected.value,
        chipData,
        // searchRef.current.value,
        internItens || itens,
        internPage || page,
        cancel,
      )
      .then(res => {
        setData(res.docs);
        setTotal(res.total);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    search();
  }, [page, itens, statusSelected, filter, bankSelected]);
  const internSearch = () => {
    if (searchType?.value !== '' && searchRef?.current?.value !== '') {
      addChip(true);
    } else {
      search();
    }
  };

  const sendCancel = () => {
    setLoadingCancel(true);
    const cancel = new AbortController();
    const ids = [];
    selected.forEach(e => {
      ids.push(e.id);
    });
    api.paymentReleases
      .cancel(ids)
      .then(res => {
        notifySuccess(res.message);
        setOpenCancel(false);
        const orig = _.cloneDeep(data);
        selected.forEach(e => {
          const find = orig.findIndex(o => o.id === e.id);
          if (find) {
            orig.splice(find, 1);
          }
        });
        setData(orig);
        setSelected([]);
        setDisabled(true);
        // setTotal(res.total);
      })
      .catch(err => {
        notifyError(err.message);
      })
      .finally(() => setLoadingCancel(false));
  };

  const addChip = btn => {
    if (searchType) {
      if (searchType.value === 'datePayment') {
        const orig = _.cloneDeep(options);
        setChipData([
          ...chipData,
          {
            key: chipData.length + 1,
            label: moment(selectedDate).format('DD/MM/YYYY'),
            by: searchType.label,
            byType: searchType.value,
            date: selectedDate,
          },
        ]);
        const myfind = orig.findIndex(e => e.value === searchType.value);
        orig.splice(myfind, 1);
        setSearchType(null);
        setOptions(orig);
        if (btn) {
          search({
            label: selectedDate,
            byType: searchType.value,
            date: selectedDate,
          });
        }
      } else {
        const orig = _.cloneDeep(options);
        setChipData([
          ...chipData,
          {
            key: chipData.length + 1,
            label: searchRef.current.value,
            by: searchType.label,
            byType: searchType.value,
          },
        ]);
        const find = orig.findIndex(e => e.value === searchType.value);

        orig.splice(find, 1);
        setSearchType(null);
        // selectRef.current.select.clearValue();
        setOptions(orig);
        if (btn) {
          search({
            label: searchRef.current.value,
            byType: searchType.value,
          });
        }
        searchRef.current.value = '';
      }
    } else {
      notifyError(t('releaseMoney.filters.errorSearch'));
    }
  };

  const handleDelete = chipToDelete => () => {
    setOptions([
      ...options,
      { label: chipToDelete.by, value: chipToDelete.byType },
    ]);
    setChipData(chips => chips.filter(chip => chip.key !== chipToDelete.key));
  };

  const teclaEnter = e => {
    if (e.key === 'Enter') {
      internSearch();
    }
  };

  const cleanFile = () => {
    setFile(null);
    fileC.current.file = null;
    setOpenUpload(false);
  };

  const sendFileConciliation = () => {
    if (!file) {
      notifyError(t('releaseMoney.filters.uploadErrorMsg'));
    } else if (bankReturn) {
      setLoadingReturn(true);
      if (bankReturn.label !== 'Acesso') {
        api.paymentReleases
          .reconcileVerify(file)
          .then(r => {
            console.log(r);
            api.paymentReleases
              .reconcilie(
                file,
                bankReturn.value,
                r.retorno.cnab_id,
                r.retorno.cnab_type,
              )
              .then(res => {
                // notifySuccess(t('releaseMoney.filters.sendSuccessMsg'));
                notifySuccess(res.message);
              })
              .catch(err => {
                notifyError(err.message);
              })
              .finally(() => {
                setLoadingReturn(false);
              });
          })
          .catch(err => {
            notifyError(err.message);
          })
          .finally(() => {
            setLoadingReturn(false);
          });
      } else {
        api.paymentReleases
          .reconcilie(file, bankReturn.value)
          .then(res => {
            // notifySuccess(t('releaseMoney.filters.sendSuccessMsg'));
            notifySuccess(res.message);
          })
          .catch(err => {
            notifyError(err.message);
          })
          .finally(() => {
            setLoadingReturn(false);
          });
      }
    } else {
      notifyError(t('releaseMoney.filters.bankErrorMsg'));
    }
  };

  return (
    <>
      <Loading open={loading} msg={t('releaseMoney.searchMsg')} />
      <Grid container spacing={3} style={{ marginTop: 50 }}>
        <p
          className={styles.textMenu}
          onKeyDown={() => history.push(routing.dashboard)}
          onClick={() => history.push(routing.dashboard)}
        >
          {t('initialMenu')}
        </p>
        <div
          className="col-sm-12"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <h4 className={styles.textTitle}> {t('releaseMoney.label')}</h4>
        </div>
        <Grid item xs={12} sm={5}>
          <Grid container spacing={0} style={{ justifyContent: 'start' }}>
            <Grid
              item
              // xs={12}
              // sm={4}
              style={{ marginLeft: 15, display: 'flex' }}
            >
              {api.currentUser?.user?.role?.id !== '3' && (
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Box flexGrow={1} style={{ marginLeft: 15 }}>
                    <label>{t('releaseMoney.filters.product')}:</label>
                  </Box>
                  <Box ml={1} mt={1}>
                    <Select
                      // fullWidth
                      styles={customStyles1}
                      classNamePrefix="select"
                      placeholder={t('releaseMoney.filters.product')}
                      isSearchable={false}
                      options={optionsProduct}
                      onChange={setFilter}
                      value={filter}
                      menuPortalTarget={document.body}
                      // style={{ zIndex: 999, width: 600 }}
                    />
                  </Box>
                </Box>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={1} style={{ justifyContent: 'end' }}>
            <Grid item xs={12} sm={3}>
              <Select
                fullWidth
                styles={customStyles1}
                onChange={setSearchType}
                value={searchType}
                classNamePrefix="select"
                placeholder={t('releaseMoney.filters.type')}
                isSearchable={false}
                options={options}
                menuPortalTarget={document.body}
                style={{ zIndex: 999 }}
              />
            </Grid>
            <Grid item xs={12} sm={6} style={{ display: 'flex' }}>
              {searchType?.value === 'datePayment' ? (
                <MuiPickersUtilsProvider locale={locale} utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    style={{ backgroundColor: '#fff' }}
                    // label="With keyboard"
                    size="small"
                    format="dd/MM/yyyy"
                    value={selectedDate}
                    InputProps={{ readOnly: true }}
                    InputAdornmentProps={{ position: 'start' }}
                    onChange={date => handleDateChange(date)}
                  />
                </MuiPickersUtilsProvider>
              ) : (
                <TextField
                  onKeyUpCapture={e => teclaEnter(e)}
                  inputRef={searchRef}
                  color="secondary"
                  width="400px"
                  className={classes.textField}
                  size="small"
                  style={{ maxHeight: 10, minWidth: 300 }}
                  variant="outlined"
                  InputProps={{
                    className: classes.input,
                  }}
                />
              )}
              <Button
                onClick={() => addChip(false)}
                variant="contained"
                style={{ marginLeft: 5, marginRight: 5 }}
              >
                <AddIcon />
              </Button>
              <Button
                // style={{ marginLeft: 10, marginRight: 5 }}
                onClick={() => internSearch(true)}
                variant="contained"
              >
                {t('releaseMoney.filters.searchBtn')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Grid item className={classes.root}>
            {chipData.map(dt => {
              let icon;

              return (
                <li key={data.key}>
                  <Chip
                    icon={icon}
                    label={`${dt.by} | ${dt.label}`}
                    onDelete={handleDelete(dt)}
                    className={classes.chip}
                  />
                </li>
              );
            })}
          </Grid>
        </Grid>

        {/* NOVA POSIÇÃO */}
        {statusSelected.value !== 'Canceled' && (
          <Grid
            item
            sm={12}
            style={{
              display: 'flex',
              flexDirection: 'row',
              // alignItems: 'rigth',
              alignContent: 'center',
              justifyContent: 'space-between',
              marginRight: 15,
              marginTop: 15,
            }}
          >
            <Grid
              item
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'rigth',
                justifyContent: 'start',
                marginRight: 15,
                marginTop: 15,
              }}
            >
              <Grid item>
                <PermissionComponent role={UserRoles.PAYMENTS_RELEASE_RELEASE}>
                  <Button
                    onClick={() => setOpen(true)}
                    variant="contained"
                    style={{ marginLeft: 5, marginRight: 5 }}
                    disabled={selected.length === 0}
                  >
                    {t('releaseMoney.releaseLabel')}
                  </Button>
                </PermissionComponent>
              </Grid>
              <Grid item>
                <PermissionComponent
                  role={UserRoles.PAYMENTS_RELEASE_CANCEL_ITEMS}
                >
                  <Button
                    onClick={() => setOpenCancel(true)}
                    variant="contained"
                    style={{ marginLeft: 5, marginRight: 5 }}
                    disabled={disabled}
                  >
                    <HighlightOffIcon />
                    {t('releaseMoney.cancelItensLabel')}
                  </Button>
                </PermissionComponent>
              </Grid>
            </Grid>
            <Grid item style={{ display: 'flex', alignItems: 'end' }}>
              {/* {file ? (
                <HighlightOffIcon
                  style={{ cursor: 'pointer' }}
                  // onClick={() => cleanFile(row)}
                />
              ) : ( */}

              <PermissionComponent role={UserRoles.PAYMENTS_RELEASE_UPLOAD}>
                <PublishIcon
                  style={{ cursor: 'pointer' }}
                  onClick={() => setOpenUpload(true)}
                  // onClick={() => upMyFile(row.bank)}
                  // onClick={() => cleanFile(row)}
                />
                {t('releaseMoney.uploadLabel')}
              </PermissionComponent>

              {/* )} */}
            </Grid>
            {/* <Grid item>
              <Button
                onClick={cleanAll}
                variant="contained"
                style={{ marginLeft: 5, marginRight: 5 }}
              >
                <DeleteForeverIcon />
                Limpar todos os itens selecionados
              </Button>
            </Grid> */}
          </Grid>
        )}
        {/* FIM NOVA */}

        <Grid item xs={12} sm={12}>
          {statusSelected.value !== 'Canceled' ? (
            <ReleaseMoneyTable
              search={search}
              open={open}
              setOpen={setOpen}
              docs={data}
              setDocs={setData}
              page={page}
              setPage={setPage}
              itens={itens}
              setItens={setItens}
              total={total}
              disabled={disabled}
              setDisabled={setDisabled}
              setSelected={setSelected}
              selected={selected}
            />
          ) : (
            <ReleaseMoneyTableCR
              docs={data}
              setDocs={setData}
              page={page}
              setPage={setPage}
              itens={itens}
              setItens={setItens}
              total={total}
              setSelected={setSelected}
              selected={selected}
            />
          )}
        </Grid>
      </Grid>

      <>
        <Dialog
          onClose={() => setOpenUpload(false)}
          open={openUpload}
          fullWidth
          maxWidth="sm"
        >
          <Loading
            open={loadingReturn}
            msg={t('releaseMoney.dialog.sendMsg')}
          />
          <DialogTitle id="simple-dialog-title">
            {t('releaseMoney.dialog.title')}
          </DialogTitle>
          <Grid
            container
            spacing={1}
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              // padding: 20,
            }}
          >
            <DialogContent>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Select
                  styles={customStyles2}
                  classNamePrefix="select"
                  placeholder={t('releaseMoney.dialog.selectTitle')}
                  isSearchable={false}
                  options={[
                    {
                      label: 'Acesso',
                      value: 'ACE',
                    },
                    {
                      label: 'Santander',
                      value: '033',
                    },
                    {
                      label: 'Bradesco',
                      value: '237',
                    },
                    {
                      label: 'Itau',
                      value: '341',
                    },
                  ]}
                  onChange={setBankReturn}
                  value={bankReturn}
                  menuPortalTarget={document.body}
                />
                <TextField
                  disabled
                  fullWidth
                  id="outlined-basic"
                  style={{ marginBottom: -5 }}
                  variant="outlined"
                  size="small"
                  onClick={() => fileC.current.click()}
                  value={file && file.name}
                />
                <PublishIcon
                  style={{ cursor: 'pointer' }}
                  onClick={() => fileC.current.click()}
                />
              </div>
            </DialogContent>
          </Grid>
          <DialogActions>
            <h6
              style={{
                cursor: 'pointer',
                color: '#657588',
                fontWeight: 'bold',
                fontFamily: 'Open Sans',
                marginTop: 5,
              }}
              onClick={() => cleanFile()}
            >
              {t('releaseMoney.dialog.btnCancel')}
            </h6>
            <Button
              onClick={sendFileConciliation}
              type="submit"
              color="primary"
              variant="contained"
              autoFocus
            >
              {t('releaseMoney.dialog.btnSalve')}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          onClose={() => setOpenCancel(false)}
          open={openCancel}
          fullWidth
          maxWidth="sm"
        >
          <Loading
            open={loadingCancel}
            msg={t('releaseMoney.dialog.cancelMsg')}
          />
          <DialogTitle id="simple-dialog-title">
            {t('releaseMoney.dialog.cancelTitle')}
          </DialogTitle>
          <Grid
            container
            spacing={1}
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              // padding: 20,
            }}
          >
            <DialogContent>
              <DialogContentText>
                {t('releaseMoney.dialog.cancelBodyMsg')}
              </DialogContentText>
            </DialogContent>
            {/* <Grid item xs={12} sm={12}>
                <p>
                  Deseja realmente cancelar os itens selecionados? Essa ação não
                  pode ser desfeita.
                </p>
              </Grid> */}
          </Grid>
          {/* </form> */}
          <DialogActions>
            {/* <Button onClick={() => setOpen(false)} color="secondary">
              Cancelar
            </Button> */}
            <h6
              style={{
                cursor: 'pointer',
                color: '#657588',
                fontWeight: 'bold',
                fontFamily: 'Open Sans',
                marginTop: 5,
              }}
              onClick={() => setOpenCancel(false)}
            >
              {t('releaseMoney.dialog.btnCancelAction')}
            </h6>
            <Button
              onClick={sendCancel}
              type="submit"
              color="primary"
              variant="contained"
              autoFocus
            >
              {t('releaseMoney.dialog.btnOk')}
            </Button>
          </DialogActions>
        </Dialog>
      </>
      <input
        ref={fileC}
        type="file"
        style={{
          opacity: 0,
        }}
        onChange={() => setFile(fileC.current.files[0])}
        className="form-control"
        id="inputNumSerie"
      />
    </>
  );
}

export default ReleaseMoney;
