/* eslint-disable no-self-compare */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';

import Link from '@material-ui/core/Link';

import Box from '@material-ui/core/Box';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';

import { FaSignature } from 'react-icons/fa';
// import SpinnerPage from '../SpinnerPage';

// import ProposalRequest from '@/types/ProposalRequest'
import useContextMenu from '../../hooks/useContextMenu/useContextMenu';
import ProposalRequestTable from '../tables/ProposalRequestTable';

function ProposalRequestModal({ open, close, proposalRequest }) {
  // const queryProposalRequest = firestore.collection('proposalRequests');

  // const [proposalRequests, loadingProposalRequests] =
  //   useCollectionData < ProposalRequest > queryProposalRequest;

  const [selectedRequest, setSelectedRequest] = useState(null > null);
  const { state, onOpen, onClose } = useContextMenu();

  const onDoubleClick = (params, e) => {
    const prop = proposalRequest?.find(
      proposalReq => proposalReq.id === params.row.id,
    );

    if (prop) {
      setSelectedRequest(prop);
      onOpen(e);
    }
  };

  // if (loadingProposalRequests) {
  //   return <SpinnerPage />;
  // }
  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      onClose={close}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle>Pedido de Proposta</DialogTitle>
      <Box mt={0} m={6}>
        <ProposalRequestTable
          onRowDoubleClick={onDoubleClick}
          // proposal={[]}
          proposal={proposalRequest || []}
        />
      </Box>
      <Menu
        keepMounted
        open={state.mouseY !== null}
        onClose={onClose}
        anchorReference="anchorPosition"
        anchorPosition={
          state.mouseY !== null && state.mouseX !== null
            ? { top: state.mouseY, left: state.mouseX }
            : undefined
        }
      >
        <Link href={`/sales/proposals/create/${selectedRequest?.id}`}>
          <MenuItem onClick={onClose}>
            <ListItemIcon>
              <FaSignature />
            </ListItemIcon>
            <Typography>Gerar Oportunidade</Typography>
          </MenuItem>
        </Link>
      </Menu>
    </Dialog>
  );
}

export default ProposalRequestModal;
