/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  TextField as MuiTextField,
  Typography,
  InputAdornment,
  makeStyles,
} from '@material-ui/core';

import cardFees from '../defaultStates/cardFees';
import { NumberFormatCustom, NumberFormatRate } from '../../NumberFormatCustom';
import { operatorFees } from '../defaultStates/productDetails';

function TextField(props) {
  return <MuiTextField variant="outlined" size="small" fullWidth {...props} />;
}

const useStyles = makeStyles({
  commissions: {
    background: 'white',
  },
});

function DigitalAccountRightViewer({ form }) {
  const {
    register,
    watch,
    setValue,
    formState: { errors },
    getValues,
  } = form;
  const classes = useStyles();

  const { productBrand } = watch();

  const [operatorFee, setOperatorFee] = useState({ ...operatorFees });

  const [cardFee, setCardFee] = useState(getValues('cardFees'));

  const [companyOperatingFee, setCompanyOperatingFee] = useState(
    cardFees.default.companyOperatingFees,
  );

  function handleChange(op) {
    if (op === 'operatorFees.monthlyFee') {
      return e => {
        setOperatorFee({
          ...operatorFee,
          monthlyFee: String(e.target.value),
        });
      };
    }
    if (op === 'companyOperatingFees.KYC') {
      return e => {
        setCompanyOperatingFee({
          ...companyOperatingFee,
          KYC: String(e.target.value),
        });
      };
    }
    if (op === 'operatorFees.emitionTicket') {
      return e => {
        setOperatorFee({
          ...operatorFee,
          emitionTicket: String(e.target.value),
        });
      };
    }
    if (op === 'companyOperatingFees.pixPf') {
      return e => {
        setOperatorFee({
          ...operatorFee,
          pixPf: String(e.target.value),
        });
      };
    }
    if (op === 'operatorFees.lotteryDeposit') {
      return e => {
        setOperatorFee({
          ...operatorFee,
          lotteryDeposit: String(e.target.value),
        });
      };
    }
    if (op === 'operatorFees.lotteryWithdrawal') {
      return e => {
        setOperatorFee({
          ...operatorFee,
          lotteryWithdrawal: String(e.target.value),
        });
      };
    }
    if (op === 'operatorFees.aditionalCard') {
      return e => {
        setOperatorFee({
          ...operatorFee,
          aditionalCard: String(e.target.value),
        });
      };
    }
    if (op === 'operatorFees.expiredCard') {
      return e => {
        setOperatorFee({
          ...operatorFee,
          expiredCard: String(e.target.value),
        });
      };
    }
    if (op === 'operatorFees.codeQR') {
      return e => {
        setOperatorFee({
          ...operatorFee,
          codeQR: String(e.target.value),
        });
      };
    }
    if (op === 'operatorFees.TED') {
      return e => {
        setOperatorFee({
          ...operatorFee,
          TED: String(e.target.value),
        });
      };
    }
    if (op === 'operatorFees.SMS') {
      return e => {
        setOperatorFee({
          ...operatorFee,
          SMS: String(e.target.value),
        });
      };
    }
    return e => {
      e.preventDefault();
      setOperatorFee({
        ...operatorFee,
      });
    };
  }

  useEffect(() => {
    switch (productBrand) {
      case 'visa':
        setValue('operatorFees', cardFees.visa.operatorFees);
        setValue('companyOperatingFees', cardFees.visa.companyOperatingFees);
        setOperatorFee(cardFees.visa.operatorFees);
        setOperatorFee({
          ...operatorFee,
          monthlyFee: 'R$ 8,00',
        });
        setCompanyOperatingFee(cardFees.visa.companyOperatingFees);
        break;
      default:
        setValue('productBrand', 'visa');
        break;
    }
  }, [productBrand]);

  return (
    <Grid className={classes.commissions} item lg={12} md={12} sm={12} xs={12}>
      <Box
        p={{ xs: 3, sm: 3, md: 4, lg: 5 }}
        mb={{ xs: 3, sm: 3, md: 4, lg: 5 }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              color="primary"
              paragraph
              align="center"
            >
              Tarifas Operacionais Empresa
            </Typography>
          </Grid>
          <Grid item lg={4} md={4} sm={3} xs={6}>
            <TextField
              error={!!errors.companyOperatingFees?.KYC}
              helperText={
                errors.companyOperatingFees?.KYC?.message || 'KYC PF/PJ'
              }
              value={companyOperatingFee.KYC}
              onChange={handleChange('companyOperatingFees.KYC')}
              InputProps={{
                inputComponent: NumberFormatCustom,
                inputProps: {
                  ...register('companyOperatingFees.KYC'),
                },
              }}
              disabled
            />
          </Grid>
        </Grid>
      </Box>

      <Box p={{ xs: 3, sm: 3, md: 4, lg: 5 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              color="primary"
              paragraph
              align="center"
            >
              Tarifas Operacionais Portador
            </Typography>
          </Grid>
          <Grid item lg={4} md={4} sm={3} xs={6}>
            <TextField
              error={!!errors.operatorFees?.monthlyFee}
              helperText={
                errors.operatorFees?.monthlyFee?.message || 'Mensalidade'
              }
              value={operatorFee.monthlyFee}
              onChange={handleChange('operatorFees.monthlyFee')}
              InputProps={{
                inputComponent: NumberFormatCustom,
                inputProps: {
                  ...register('operatorFees.monthlyFee'),
                },
              }}
              disabled
            />
          </Grid>
          <Grid item lg={4} md={4} sm={3} xs={6}>
            <TextField
              error={!!errors.operatorFees?.emitionTicket}
              helperText={
                errors.operatorFees?.emitionTicket?.message || 'Emissão Boleto'
              }
              value={operatorFee.emitionTicket}
              onChange={handleChange('operatorFees.emitionTicket')}
              InputProps={{
                inputComponent: NumberFormatCustom,
                inputProps: {
                  ...register('operatorFees.emitionTicket'),
                },
              }}
              disabled
            />
          </Grid>

          <Grid item lg={4} md={4} sm={3} xs={6}>
            <TextField
              error={!!errors.operatorFees?.lotteryDeposit}
              helperText={
                errors.operatorFees?.lotteryDeposit?.message ||
                'Depósito Lotérica'
              }
              value={operatorFee.lotteryDeposit}
              onChange={handleChange('operatorFees.lotteryDeposit')}
              InputProps={{
                inputComponent: NumberFormatCustom,
                inputProps: {
                  ...register('operatorFees.lotteryDeposit'),
                },
              }}
              disabled
            />
          </Grid>
          <Grid item lg={4} md={4} sm={3} xs={6}>
            <TextField
              error={!!errors.operatorFees?.TED}
              helperText={errors.operatorFees?.TED?.message || 'TED'}
              value={operatorFee.TED}
              onChange={handleChange('operatorFees.TED')}
              InputProps={{
                inputComponent: NumberFormatCustom,
                inputProps: {
                  ...register('operatorFees.TED'),
                },
              }}
              disabled
            />
          </Grid>
          <Grid item lg={4} md={4} sm={3} xs={6}>
            <TextField
              error={!!errors.operatorFees?.pixPf}
              helperText={errors.operatorFees?.pixPf?.message || 'Pix PF'}
              value={operatorFee.pixPf}
              onChange={handleChange('operatorFees.pixPf')}
              InputProps={{
                inputComponent: NumberFormatCustom,
                inputProps: {
                  ...register('operatorFees.pixPf'),
                },
              }}
              disabled
            />
          </Grid>
          <Grid item lg={4} md={4} sm={3} xs={6}>
            <TextField
              error={!!errors.operatorFees?.pixPj}
              helperText={errors.operatorFees?.pixPj?.message || 'Pix PJ'}
              value={operatorFee.pixPj}
              onChange={handleChange('operatorFees.pixPj')}
              InputProps={{
                inputComponent: NumberFormatCustom,
                inputProps: {
                  ...register('operatorFees.pixPj'),
                },
              }}
              disabled
            />
          </Grid>

          <Grid item lg={4} md={4} sm={3} xs={6}>
            <TextField
              error={!!errors.operatorFees?.lotteryWithdrawal}
              helperText={
                errors.operatorFees?.lotteryWithdrawal?.message ||
                'Saque Lotérica'
              }
              value={operatorFee.lotteryWithdrawal}
              onChange={handleChange('operatorFees.lotteryWithdrawal')}
              InputProps={{
                inputComponent: NumberFormatCustom,
                inputProps: {
                  ...register('operatorFees.lotteryWithdrawal'),
                },
              }}
              disabled
            />
          </Grid>
          <Grid item lg={4} md={4} sm={3} xs={6}>
            <TextField
              error={!!errors.operatorFees?.PDV}
              helperText={errors.operatorFees?.PDV?.message || 'Saque PDV'}
              InputProps={{
                inputComponent: NumberFormatRate,
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                inputProps: {
                  ...register('operatorFees.PDV'),
                },
              }}
              value={operatorFee.PDV}
              onChange={e => {
                setOperatorFee({
                  ...operatorFee,
                  PDV: e.target.value,
                });
              }}
              disabled
            />
          </Grid>
          <Grid item lg={4} md={4} sm={3} xs={6}>
            <TextField
              error={!!errors.operatorFees?.codeQR}
              helperText={errors.operatorFees?.codeQR?.message || 'QR Code'}
              value={operatorFee.codeQR}
              onChange={handleChange('operatorFees.codeQR')}
              InputProps={{
                inputComponent: NumberFormatCustom,
                inputProps: {
                  ...register('operatorFees.codeQR'),
                },
              }}
              disabled
            />
          </Grid>

          <Grid item lg={4} md={4} sm={3} xs={6}>
            <TextField
              error={!!errors.operatorFees?.SMS}
              helperText={errors.operatorFees?.SMS?.message || 'SMS'}
              value={operatorFee.SMS}
              onChange={handleChange('operatorFees.SMS')}
              InputProps={{
                inputComponent: NumberFormatCustom,
                inputProps: {
                  ...register('operatorFees.SMS'),
                },
              }}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              color="primary"
              paragraph
              align="center"
            >
              Tarifas Cartão Físico
            </Typography>
          </Grid>
          <Grid item lg={4} md={4} sm={3} xs={6}>
            <TextField
              disabled
              error={!!errors.cardFees?.physicalCard}
              helperText={
                errors.cardFees?.physicalCard?.message || 'Cartão Físico'
              }
              InputProps={{
                inputComponent: NumberFormatCustom,
                inputProps: {
                  ...register('cardFees.physicalCard'),
                },
              }}
              value={cardFee.physicalCard}
              onChange={e => {
                setCardFee({
                  ...cardFee,
                  physicalCard: e.target.value,
                });
              }}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={3} xs={6}>
            <TextField
              error={!!errors.cardFees?.sendCard}
              helperText={
                errors.cardFees?.sendCard?.message || 'Envio de Cartão'
              }
              InputProps={{
                inputComponent: NumberFormatCustom,
                inputProps: {
                  ...register('cardFees.sendCard'),
                },
              }}
              value={cardFee.sendCard}
              onChange={e => {
                setCardFee({
                  ...cardFee,
                  sendCard: e.target.value,
                });
              }}
              disabled
            />
          </Grid>
          <Grid item lg={4} md={4} sm={3} xs={6}>
            <TextField
              error={!!errors.cardFees?.cardWithdrawal}
              helperText={
                errors.cardFees?.cardWithdrawal?.message || 'Saque ATM (B24H)'
              }
              InputProps={{
                inputComponent: NumberFormatCustom,
                inputProps: {
                  ...register('cardFees.cardWithdrawal'),
                },
              }}
              value={cardFee.cardWithdrawal}
              onChange={e => {
                setCardFee({
                  ...cardFee,
                  cardWithdrawal: e.target.value,
                });
              }}
              disabled
            />
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
}

export default DigitalAccountRightViewer;
