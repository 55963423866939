/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState, useRef } from 'react';
import Select from 'react-select';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import _ from 'lodash';
import AddIcon from '@material-ui/icons/Add';
import Chip from '@material-ui/core/Chip';
import OrdersTable from './OrderTable';
import api from '../../service/axios/api';
import Loading from '../../components/loading/Loading';

const customStyles1 = {
  control: provided => ({
    ...provided,
    width: 125,
    height: 40,
    borderRadius: 10,
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  option: provided => ({
    ...provided,
  }),
};

const optionsStatus = [
  {
    label: 'Aguardando cartão',
    value: 'AwaitingCard',
  },
  {
    label: 'Processando',
    value: 'Processing',
  },
  {
    label: 'Aguardando pagamento',
    value: 'AwaitingPayment',
  },
  {
    label: 'Processado',
    value: 'Processed',
  },
  {
    label: 'Aguardando cartão finalizar',
    value: 'AwaitingCardRelease',
  },
  {
    label: 'Aguardando status cartão',
    value: 'AwaitingBankStatus',
  },
  {
    label: 'Cancelado',
    value: 'Canceled',
  },
  {
    label: 'Recebido',
    value: 'Received',
  },
  {
    label: 'Rejeitado',
    value: 'Rejected',
  },
  {
    label: 'Processamento automático',
    value: 'AutoProcessing',
  },
  {
    label: 'Aguardando conciliação',
    value: 'AwaitingConciliation',
  },
  {
    label: 'Aguardando processamento',
    value: 'AwaitingProcessing',
  },
  {
    label: 'Criado',
    value: 'Created',
  },
  {
    label: 'Todos',
    value: '',
  },
];

const customStyles = {
  control: provided => ({
    ...provided,
    width: 205,
    // width: 982,
    height: 40,
    borderRadius: 10,
    // backgroundColor: 'none',
    // boxShadow: 'none',
    // border: 'none',
    // fontWeight: 'bold',
    // fontFamily: 'Open Sans',
    // fontSize: 14,
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  option: provided => ({
    ...provided,
    // fontFamily: 'Open Sans',
    // fontSize: 24,
  }),
  // placeholder: () => ({
  //   // fontWeight: 'bold',
  //   // fontFamily: 'Open Sans',
  //   // fontSize: 24,
  //   color: '#9C9C9C',
  //   // paddingT: 10,
  //   // marginBottom: 30,
  // }),
};
const useStyles = makeStyles({
  textField: {
    // width: '90%',
    // // height: '40px',
    // marginLeft: 'auto',
    // marginRight: 'auto',
    // paddingTop: '2px',
    // paddingBottom: 0,
    // marginTop: 0,
    // fontWeight: 500,
    // backgroundColor: '#fff',
  },
  input: {
    backgroundColor: '#fff',
    marginTop: 0,
    // color: 'white',
  },
});

export default function OrdersAdmin() {
  const classes = useStyles();
  const [docs, setDocs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [itens, setItens] = useState(10);
  const [total, setTotal] = useState(0);
  const [statusSelected, setStatusSelected] = useState({
    label: 'Todos',
    value: '',
  });
  const [searchType, setSearchType] = useState();
  const [options, setOptions] = useState([
    { label: 'Pedido', value: 'orderNumber' },
    { label: 'Proxy', value: 'proxy' },
  ]);
  const [chipData, setChipData] = useState([]);

  const order = useRef(null);

  const searchData = extra => {
    setLoading(true);
    api.order
      .getAdmin(itens, page + 1, statusSelected.value, chipData, extra)
      .then(res => {
        console.log(res);
        setDocs(res.docs);
        setTotal(res.total);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };

  const includeSearch = btn => {
    const orig = _.cloneDeep(options);
    setChipData([
      ...chipData,
      {
        key: chipData.length + 1,
        label: order.current.value,
        by: searchType.label,
        byType: searchType.value,
        // date: selectedDate,
      },
    ]);
    const myfind = orig.findIndex(e => e.value === searchType.value);
    orig.splice(myfind, 1);
    setSearchType(null);
    setOptions(orig);
    if (btn) {
      searchData({
        label: order.current.value,
        byType: searchType.value,
      });
    }
    order.current.value = '';
  };

  const addChip = () => {
    if (searchType) {
      includeSearch(false);
    } else {
      // notifyError(t('contract.filters.typeSearchMsg'));
    }
  };
  const handleDelete = chipToDelete => () => {
    setOptions([
      ...options,
      { label: chipToDelete.by, value: chipToDelete.byType },
    ]);
    setChipData(chips => chips.filter(chip => chip.key !== chipToDelete.key));
  };

  useEffect(() => {
    searchData();
  }, [itens, page, statusSelected]);

  const teclaEnter = e => {
    if (e.key === 'Enter') {
      // internSearch();
      includeSearch(true);
      // searchData({
      //   label: order.current.value,
      //   byType: searchType.value,
      // });
    }
  };
  return (
    <>
      <Loading open={loading} msg="Buscando pedidos..." />

      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <h1>Itens dos Pedidos</h1>
        </Grid>
        <Grid item xs={12} sm={7}>
          <Grid container spacing={0} style={{ justifyContent: 'start' }}>
            <Grid item xs={12} sm={4} style={{ marginLeft: 15 }}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Box flexGrow={1}>
                  <label>Filtrar por status:</label>
                </Box>
                <Box ml={1} mt={1}>
                  <Select
                    // fullWidth
                    onChange={setStatusSelected}
                    styles={customStyles}
                    classNamePrefix="select"
                    placeholder="Tipo"
                    isSearchable={false}
                    options={optionsStatus}
                    menuPortalTarget={document.body}
                    // style={{ zIndex: 999, width: 600 }}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} sm={5}>
          <Grid container spacing={1} style={{ justifyContent: 'end' }}>
            <Grid item>
              <Select
                fullWidth
                styles={customStyles1}
                onChange={setSearchType}
                value={searchType}
                classNamePrefix="select"
                placeholder="Tipo"
                isSearchable={false}
                options={options}
                menuPortalTarget={document.body}
                style={{ zIndex: 999 }}
              />
            </Grid>
            <Grid
              item
              // xs={12}
              // sm={6}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <TextField
                onKeyUpCapture={e => teclaEnter(e)}
                inputRef={order}
                color="secondary"
                width="400px"
                className={classes.textField}
                fullWidth
                size="small"
                style={{ maxHeight: 10, minWidth: 300 }}
                variant="outlined"
                InputProps={{
                  className: classes.input,
                }}
              />

              <Button
                onClick={addChip}
                variant="contained"
                style={{ marginLeft: 5, marginRight: 5 }}
              >
                <AddIcon />
              </Button>
              <Button
                // style={{ marginLeft: 10, marginRight: 5 }}
                onClick={() => includeSearch(true)}
                variant="contained"
              >
                Buscar
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} sm={6}>
          <Grid
            item
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexWrap: 'wrap',
              listStyle: 'none',
              padding: 10,
              margin: 0,
              gap: 5,
            }}
          >
            {chipData.map((dt, i) => {
              let icon;

              return (
                // eslint-disable-next-line react/no-array-index-key
                <li key={i}>
                  <Chip
                    icon={icon}
                    label={`${dt.by} | ${dt.label}`}
                    onDelete={handleDelete(dt)}
                    className={classes.chip}
                  />
                </li>
              );
            })}
          </Grid>
        </Grid>
        {/* <Grid item xs={12} sm={5}>
          <Grid container spacing={1} style={{ justifyContent: 'end' }}>
            <Grid item xs={12} sm={5}>
              <TextField
                color="secondary"
                inputRef={order}
                onKeyPress={e => e.key === 'Enter' && searchData()}
                className={classes.textField}
                fullWidth
                size="small"
                style={{ maxHeight: 10 }}
                label="Número do pedido"
                variant="outlined"
                InputProps={{
                  className: classes.input,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Button variant="contained" onClick={searchData}>
                Buscar
              </Button>
            </Grid>
          </Grid>
        </Grid> */}
        <Grid item xs={12} sm={12}>
          <OrdersTable
            docs={docs}
            setDocs={setDocs}
            page={page}
            setPage={setPage}
            itens={itens}
            setItens={setItens}
            total={total}
            searchData={searchData}
          />
        </Grid>
      </Grid>
    </>
  );
}
