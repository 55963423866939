/* eslint-disable react/prop-types */
import React from 'react';
import moment from 'moment-timezone';
import Table from '../Table';

// import { formatProposalStatus } from '../../util/helpers';

// import {
//   // getProductName,
//   getTradingName,
//   getCompanyName,
//   getCnpj,
//   // getCommercialAgent,
// } from './getters';

const columns = [
  {
    field: 'name',
    headerName: 'Nome',
    // valueGetter: getTradingName,
    flex: 1,
  },
  {
    field: 'expires',
    headerName: 'Validade',
    valueFormatter: params => {
      const { value } = params;
      if (value) {
        return moment(value).format('DD-MM-YYYY');
      }
      return null;
    },
    flex: 1,
  },
  {
    field: 'comments',
    headerName: 'Comentários',
    // valueGetter: getCompanyName,
    flex: 1,
  },
  {
    field: 'createdAt',
    headerName: 'Data criação',
    valueFormatter: params => {
      const { value } = params;
      return moment(value).format('DD-MM-YYYY HH:mm');
    },
    flex: 1,
    // width: 0,
  },
];

function CampaingTable({ campaings, ...others }) {
  return (
    <Table
      columns={columns}
      rows={campaings}
      sortModel={[{ field: 'createdAt', sort: 'desc' }]}
      {...others}
    />
  );
}

export default CampaingTable;
