/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/require-default-props */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// import Grid from '@mui/material/Grid';

import clsx from 'clsx';
// import Box from '@mui/material/Box';
// import CssBaseline from '@mui/material/CssBaseline';
// import Drawer from '@mui/material/Drawer';

import { useLocation, useHistory } from 'react-router-dom';

// import Toolbar from '@mui/material/Toolbar';
// import List from '@mui/material/List';
// import Typography from '@mui/material/Typography';
// import Divider from '@mui/material/Divider';
// import IconButton from '@mui/material/IconButton';
// import MenuIcon from '@mui/icons-material/Menu';

import MenuIcon from '@material-ui/icons/Menu';
import {
  Box,
  CssBaseline,
  Drawer,
  Toolbar,
  List,
  Typography,
  IconButton,
  Divider,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@material-ui/core';

// import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import ListItem from '@mui/material/ListItem';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import ListItemText from '@mui/material/ListItemText';
// import InboxIcon from '@mui/icons-material/MoveToInbox';
import InboxIcon from '@material-ui/icons/Inbox';
// import MailIcon from '@mui/icons-material/Mail';
import MailIcon from '@material-ui/icons/Mail';
import { makeStyles, styled, useTheme } from '@material-ui/core/styles';
import PermissionComponent from '../components/permissionComponent/PermissionComponent';
import routing from '../service/routes/routing';

import './DashboardLayout.css';
import TopBarElement from './TopBarElement';

import UserRoles from '../service/acl/UserRoles';

const drawerWidth = 0;

const Main = styled('main', { shouldForwardProp: prop => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const useStyles = makeStyles(() => ({
  labels: {
    marginTop: 5,
  },
  labelplus: {
    marginTop: 5,
    marginLeft: 10,
    marginBottom: 10,
  },
}));

export default function DashboardLayout({
  title = '',
  children,
  handleDrawerOpen,
  handleDrawerClose,
  open,
  setOpen,
  setOpenModal,
}) {
  const { t } = useTranslation();
  const history = useHistory();
  // const [open, setOpen] = useState(false);
  const theme = useTheme();
  const location = useLocation();
  const classes = useStyles();

  useEffect(() => {
    if (location.pathname !== '/orders') setOpen(false);
  }, [location]);

  const handleOpens = () => {
    setOpenModal(true);
    setOpen(false);
  };

  const handleHistory = () => {
    setOpen(false);
    history.push(routing.orders);
  };
  // const handleDrawerOpen = () => {
  //   if (location.pathname === '/orders') {
  //     setOpen(!open);
  //   }
  // };

  // const handleDrawerClose = () => {
  //   setOpen(false);
  // };
  // const container = document.getElementById('main');
  // console.log(container?.clientHeight);
  // useEffect(() => {
  //   console.log(container?.scrollHeight);
  // }, [container]);

  return (
    <Box sx={{ display: 'flex', position: 'relative', minHeight: '100vh' }}>
      <CssBaseline />
      <TopBarElement
        title={title}
        sideMenuOpen={open}
        onToggleSideMenu={() => handleDrawerOpen(location.pathname)}
      />
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {/* <ListItem button>
            <ListItemIcon>
              <div className={classes.labels}>
                <img src="./img/pedidos.svg" alt="logo" />
              </div>
            </ListItemIcon>
            <ListItemText
              className="menufont"
              primary={t('Order.menu.orders')}
            />
          </ListItem> */}
          <ListItem button onClick={handleHistory}>
            <ListItemIcon>
              <div className={classes.labels}>
                <img src="./img/hpedidos.svg" alt="logo" />
              </div>
            </ListItemIcon>
            <ListItemText
              className={
                location.pathname === '/orders' ? 'menufontS' : 'menufont'
              }
              primary={t('Order.menu.history')}
            />
          </ListItem>
          <PermissionComponent role={UserRoles.ORDERS_NEW}>
            <ListItem button onClick={handleOpens}>
              <ListItemIcon>
                <h3 className={classes.labelplus}>+</h3>
              </ListItemIcon>
              <ListItemText
                className="menufont"
                primary={t('Order.menu.order')}
              />
            </ListItem>
          </PermissionComponent>
          {/* <ListItem button onClick={() => history.push(routing.product)}>
            <ListItemIcon>
              <div className={classes.labels}>
                <img src="./img/produtos.svg" alt="logo" />
              </div>
            </ListItemIcon>
            <ListItemText className="menufont" primary="Produtos" />
          </ListItem> */}
          <PermissionComponent role={UserRoles.DASHBOARD_CONTRACTS}>
            <ListItem button onClick={() => history.push(routing.allContracts)}>
              <ListItemIcon>
                <div className={classes.labels}>
                  <img src="./img/contratos.svg" alt="logo" />
                </div>
              </ListItemIcon>
              <ListItemText
                className="menufont"
                primary={t('Order.menu.contract')}
              />
            </ListItem>
          </PermissionComponent>
          <ListItem button onClick={() => history.push(routing.dashboard)}>
            <ListItemIcon>
              <div className={classes.labels}>
                <img src="./img/dashboard.svg" alt="logo" />
              </div>
            </ListItemIcon>
            <ListItemText
              className="menufont"
              primary={t('Order.menu.dashBoard')}
            />
          </ListItem>
        </List>
      </Drawer>
      <Main
        open={open}
        handleDrawerOpen={handleDrawerOpen}
        style={{ marginTop: 30, paddingBottom: '2.5rem' }}
        id="main"
      >
        {children}
        <footer
          style={{
            textAlign: 'center',
            position: 'absolute',
            bottom: 0,
            height: '2.5rem',
            width: '100%',
            // marginTop: '100vh -20px',
            // left: 0,
          }}
        >
          <h6>Todos direitos reservados para Promotech</h6>
        </footer>
      </Main>
    </Box>
  );
}

// DashboardLayout.propTypes = propTypes;
