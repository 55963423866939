/* eslint-disable react/no-unescaped-entities */
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Grid, TextField } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    myTextField: {
      backgroundColor: 'none',
      boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
      border: 'none',
      color: '#717171',
      fontWeight: 'bold',
      fontFamily: 'Open Sans',
    },
    text: {
      fontFamily: 'Open Sans',
      fontWeight: 'bold',
      color: '#717171',
    },
  }),
);

export default function CardLimits() {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Grid
      container
      spacing={1}
      style={{
        border: '2px solid #c5c5c5',
        borderRadius: 10,
        justifyContent: 'center',
        padding: 10,
      }}
    >
      <Grid
        container
        spacing={1}
        style={{
          justifyContent: 'space-between',
          padding: 1,
          alignItems: 'center',
        }}
      >
        <h5 className="text-box-inform">
          {t('Product.creditCard.checkout.limits.label1')}
        </h5>
        <p className="text-box-inform-2">COD.: PROD_CARDPP_002</p>
      </Grid>
      <Grid
        container
        spacing={1}
        style={{
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: 1,
        }}
      >
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <p className="text-select-label ">
            {`${t('Product.creditCard.checkout.limits.transfer1')}
            ${t('Product.creditCard.checkout.limits.transfer2')}`}
          </p>
          <TextField
            classes={{ notchedOutline: classes.input }}
            className={classes.text}
            // fullWidth
            id="outlined-basic"
            label="R$ 00,00"
            variant="outlined"
            size="small"
            // inputRef={search}
            InputProps={{
              classes: {
                notchedOutline: classes.myTextField,
                input: classes.text,
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <p className="text-select-label ">
            {t('Product.creditCard.checkout.limits.purchases')}
          </p>
          <TextField
            classes={{ notchedOutline: classes.input }}
            className={classes.text}
            // fullWidth
            id="outlined-basic"
            label="R$ 00,00"
            variant="outlined"
            size="small"
            // inputRef={search}
            InputProps={{
              classes: {
                notchedOutline: classes.myTextField,
                input: classes.text,
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <p className="text-select-label ">
            {t('Product.creditCard.checkout.limits.withdraw')}
          </p>
          <TextField
            classes={{ notchedOutline: classes.input }}
            className={classes.text}
            // fullWidth
            id="outlined-basic"
            label="R$ 00,00"
            variant="outlined"
            size="small"
            // inputRef={search}
            InputProps={{
              classes: {
                notchedOutline: classes.myTextField,
                input: classes.text,
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <p className="text-select-label ">
            {t('Product.creditCard.checkout.limits.paymentCount')}
          </p>
          <TextField
            classes={{ notchedOutline: classes.input }}
            className={classes.text}
            // fullWidth
            id="outlined-basic"
            label="R$ 00,00"
            variant="outlined"
            size="small"
            // inputRef={search}
            InputProps={{
              classes: {
                notchedOutline: classes.myTextField,
                input: classes.text,
              },
            }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        style={{ justifyContent: 'start', alignItems: 'center', padding: 1 }}
      >
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <p className="text-select-label ">
            {t('Product.creditCard.checkout.limits.celPhone')}
          </p>
          <TextField
            classes={{ notchedOutline: classes.input }}
            className={classes.text}
            // fullWidth
            id="outlined-basic"
            label="R$ 00,00"
            variant="outlined"
            size="small"
            // inputRef={search}
            InputProps={{
              classes: {
                notchedOutline: classes.myTextField,
                input: classes.text,
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <p className="text-select-label ">
            {t('Product.creditCard.checkout.limits.balanceInCard')}
          </p>
          <TextField
            classes={{ notchedOutline: classes.input }}
            className={classes.text}
            // fullWidth
            id="outlined-basic"
            label="R$ 00,00"
            variant="outlined"
            size="small"
            // inputRef={search}
            InputProps={{
              classes: {
                notchedOutline: classes.myTextField,
                input: classes.text,
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <p className="text-select-label ">
            {t('Product.creditCard.checkout.limits.prizeInCard')}
          </p>
          <TextField
            classes={{ notchedOutline: classes.input }}
            className={classes.text}
            // fullWidth
            id="outlined-basic"
            label="R$ 00,00"
            variant="outlined"
            size="small"
            // inputRef={search}
            InputProps={{
              classes: {
                notchedOutline: classes.myTextField,
                input: classes.text,
              },
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
