/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import './checkout.css';
// import Grid from '@mui/material/Grid';
// import { styled } from '@material-ui/core/styles';
// import Box from '@mui/material/Box';
// import Paper from '@mui/material/Paper';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Grid, Paper } from '@material-ui/core';

const useStyles = makeStyles(theme =>
  createStyles({
    myButton: {
      // textAlign: 'center',
      // padding: theme.spacing(6),
      // margin: theme.spacing(1),
      // color: theme.palette.text.secondary,
      color: '#2a3176',
      backgroundColor: '#f4f4f4',
      fontWeight: 'bold',
      // minWidth: 200,
    },
    myGrid: {
      backgroundColor: '#f4f4f4',
      margin: theme.spacing(1),
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      textAlign: 'center',
      cursor: 'pointer',
    },
    myGridSelected: {
      backgroundColor: '#41b490',
      margin: theme.spacing(1),
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      textAlign: 'center',
      cursor: 'pointer',
    },
    myLabel: {
      padding: theme.spacing(6),
      color: '#2a3176',
      fontWeight: 'bold',
    },
    myLabelSelected: {
      padding: theme.spacing(6),
      color: '#fff',
      fontWeight: 'bold',
    },
  }),
);

export default function UserClass({ selected, setSelected }) {
  const classes = useStyles();
  // const Item = styled(Paper)(({ theme }) => ({
  //   ...theme.typography.body2,
  //   padding: theme.spacing(3),
  //   textAlign: 'center',
  //   // color: theme.palette.text.secondary,
  //   color: '#2a3176',
  //   fontWeight: 'bold',
  //   backgroundColor: '#f4f4f4',
  //   cursor: 'pointer',
  // }));

  return (
    <Grid
      container
      spacing={1}
      style={{
        border: '2px solid #c5c5c5',
        borderRadius: 10,
        justifyContent: 'center',
        padding: 40,
      }}
    >
      <Grid
        item
        className={
          selected === 'element' ? classes.myGridSelected : classes.myGrid
        }
        xs={12}
        sm={8}
        md={2}
        lg={2}
        xl={2}
        onClick={() => setSelected('element')}
      >
        <p
          className={
            selected === 'element' ? classes.myLabelSelected : classes.myLabel
          }
        >
          Element
        </p>
        {/* <Item
          styled={
            selected === 'element' && {
              backgroundColor: '#41b490',
              color: '#fff',
            }
          }
        >
          Element
        </Item> */}
      </Grid>
      {/* <Grid
        item
        xs={12}
        sm={8}
        md={2}
        lg={2}
        xl={2}
        onClick={() => setSelected('franquia')}
      >
        <Item
          sx={
            selected === 'franquia' && {
              backgroundColor: '#41b490',
              color: '#fff',
            }
          }
        >
          Franquia
        </Item>
      </Grid> */}
      <Grid
        item
        className={
          selected === 'cliente' ? classes.myGridSelected : classes.myGrid
        }
        xs={12}
        sm={8}
        md={2}
        lg={2}
        xl={2}
        onClick={() => setSelected('cliente')}
      >
        <p
          className={
            selected === 'cliente' ? classes.myLabelSelected : classes.myLabel
          }
        >
          Cliente
        </p>
        {/* <Item
          sx={
            selected === 'cliente' && {
              backgroundColor: '#41b490',
              color: '#fff',
            }
          }
        >
          Cliente
        </Item> */}
      </Grid>
      <Grid
        item
        className={
          selected === 'agente' ? classes.myGridSelected : classes.myGrid
        }
        xs={12}
        sm={8}
        md={2}
        lg={2}
        xl={2}
        onClick={() => setSelected('agente')}
      >
        {/* <Item
          sx={
            selected === 'agente' && {
              backgroundColor: '#41b490',
              color: '#fff',
            }
          }
        >
          Agente Comercial
        </Item> */}
        <p
          className={
            selected === 'agente' ? classes.myLabelSelected : classes.myLabel
          }
        >
          Agente Comercial
        </p>
      </Grid>
      {/* <Grid
        item
        xs={12}
        sm={8}
        md={2}
        lg={2}
        xl={2}
        onClick={() => setSelected('fornecedor')}
      >
        <Item
          sx={
            selected === 'fornecedor' && {
              backgroundColor: '#41b490',
              color: '#fff',
            }
          }
        >
          Fornecedor
        </Item>
      </Grid> */}
    </Grid>
  );
}
