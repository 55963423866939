/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import axios from 'axios';
import {
  Button,
  CircularProgress,
  Box,
  TextField,
  Typography,
} from '@material-ui/core';

import { Controller, useForm } from 'react-hook-form';
import { Autocomplete } from '@material-ui/lab';
// import CommercialAgent from '@/types/CommercialAgent';
// import Proposal from '@/types/Proposal';

import { useStyles } from './SelectCommercialAgent.styles';

function SelectCommercialAgent({ proposal }) {
  const classes = useStyles();

  // const commercialAgentsQuery = firestore.collection('commercialAgents');
  const [showSuccessText, setShowSuccessText] = useState(false);
  const [showErrorText, setShowErrorText] = useState(false);

  const { control, handleSubmit } = useForm({
    mode: 'all',
    defaultValues: {
      commercialAgentId: '',
    },
  });

  // const [commercialAgents, loadingCommercialAgents] =
  //   useCollectionDataOnce < CommercialAgent > commercialAgentsQuery;

  const submit = async data => {
    const endpoint = `/api/proposals/${proposal.id}/commercial-agent`;

    try {
      const response = await axios.put(endpoint, data);

      if (response.status === 200) {
        setShowSuccessText(true);
      }
    } catch (err) {
      const error = err;

      setShowErrorText(true);
    }
  };

  if (false) {
    return (
      <Box className={classes.container}>
        <Box
          height="400px"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      </Box>
    );
  }

  // if (!commercialAgents || commercialAgents.length === 0) {
  //   return (
  //     <Box className={classes.container}>
  //       <Typography className={classes.font} variant="h4" color="textSecondary">
  //         Nenhum agente comercial disponível
  //       </Typography>
  //     </Box>
  //   );
  // }

  return (
    <Box className={classes.container}>
      <Typography className={classes.font} variant="h4" color="textSecondary">
        Redirecionar Agente
      </Typography>

      {!showSuccessText && (
        <form onSubmit={handleSubmit(submit)}>
          <Controller
            control={control}
            name="commercialAgentId"
            render={props => (
              <Autocomplete
                {...props}
                options={[]}
                getOptionLabel={option => option.company.legalName}
                onChange={(_, agent) => {
                  if (agent) {
                    props.field.onChange(agent.id);
                    setShowErrorText(false);
                  } else {
                    props.field.onChange(null);
                    setShowErrorText(true);
                  }
                }}
                blurOnSelect
                disableCloseOnSelect
                limitTags={3}
                size="small"
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Associar parceiro comercial"
                  />
                )}
              />
            )}
          />
          {showErrorText && (
            <Typography
              variant="h5"
              className={classes.error}
              align="center"
              paragraph
            >
              Selecione um Agente
            </Typography>
          )}

          <Box
            display="flex"
            flex-direction="row"
            justifyContent="flex-end"
            className={classes.buttonContainer}
          >
            <Button variant="contained" type="submit" color="primary">
              Enviar
            </Button>
          </Box>
        </form>
      )}

      {showSuccessText && (
        <Box
          p={10}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              pb={5}
            >
              <img src="/assets/success.svg" alt="success" />
            </Box>
            <Box p={5}>
              <Typography variant="h5" color="primary" align="center" paragraph>
                Redirecionado com sucesso
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default SelectCommercialAgent;
