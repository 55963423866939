/* eslint-disable react/prop-types */
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Button from '../../../components/button/MyButton';
import styles from '../../../styles/Layout.module.css';
import FlashContext from '../../../components/contexts/FlashContext';

import routing from '../../../service/routes/routing';
import api from '../../../service/axios/api';

export default function Created({
  setOpenModal,
  activeStep,
  setActiveStep,
  orderId,
  setLoad,
  setMsg,
}) {
  const { t } = useTranslation();
  const { notifyError } = useContext(FlashContext);
  const [openError, setOpenError] = useState(false);
  const [descontoError, setDescontoError] = useState('');
  const history = useHistory();
  const handleNext = () => {
    setMsg(t('newOrder.created.nfMsg'));
    setLoad(true);
    api.order
      .complete(orderId)
      .then(() => {
        setActiveStep(activeStep + 1);
      })
      .catch(err => {
        notifyError(err.message);
        if (err.params.length > 0) {
          setDescontoError(err.params[0].message);
          setOpenError(true);
        }
      })
      .finally(() => setLoad(false));
  };
  return (
    <>
      <div
        className="col-sm-12"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <img
          src="/img/checkOk.svg"
          alt="ok"
          className="img-category-check"
          style={{ width: '15%', height: '15%' }}
        />
        <h4 className={styles.textSuccess} style={{ marginTop: 30 }}>
          {t('newOrder.created.msg')}
        </h4>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 30,
          }}
        >
          <Button
            style={{
              marginBottom: 20,
              marginRight: 20,
              height: 50,
              width: 280,
              // fontSize: 24,
              backgroundColor: '#657588',
            }}
            onClick={() => setOpenModal(false)}
            label={t('newOrder.created.btnSalve')}
          />
          <Button
            style={{
              marginBottom: 20,
              marginRight: 20,
              height: 50,
              width: 280,
              // fontSize: 24,
            }}
            onClick={handleNext}
            label={t('newOrder.created.btnNf')}
          />
        </div>
      </div>
      <Dialog
        onClose={() => setOpenError(false)}
        open={openError}
        // width={1192}
        // height={776}
        fullWidth
        // maxWidth="1192"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {descontoError}
            <p>
              <p>
                <br />
              </p>
              Clique no botão abaixo editar o pedido. Exclua o item de desconto
              e informe um desconto válido.
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ height: 40 }}
            // onClick={() => setOpenError(false)}
            onClick={() => history.push(routing.orderDetails(orderId))}
            color="primary"
            variant="contained"
            label="EDITAR"
          />
        </DialogActions>
      </Dialog>
    </>
  );
}
