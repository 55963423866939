/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import {
  Button,
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { withStyles, makeStyles, createStyles } from '@material-ui/core/styles';
import moment from 'moment/moment';
import _ from 'lodash';
import FlashContext from '../../components/contexts/FlashContext';
import routing from '../../service/routes/routing';
import styles from '../../styles/Layout.module.css';
import permissionModule from '../permissions/PermissionModule';
import ContractsTable from './ContractsTable';

import Loading from '../../components/loading/Loading';

import api from '../../service/axios/api';
import VincContract from './VincContract';

const customStyles1 = {
  control: provided => ({
    ...provided,
    marginLeft: 10,
    width: 250,
    height: 40,
    borderRadius: 10,
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  option: provided => ({
    ...provided,
  }),
};

const customStyles = {
  control: provided => ({
    ...provided,
    marginTop: -10,
    width: 150,
    height: 40,
    borderRadius: 10,
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  option: provided => ({
    ...provided,
  }),
};

const useStyles = makeStyles(theme =>
  createStyles({
    textNotification: {
      textAlign: 'center',
      fontFamily: 'Open Sans',
      fontWeight: 'bold',
      // lineHeight: 'auto',
      fontSize: 14,
      color: '#707070',
    },
    myLabel: {
      // padding: theme.spacing(6),
      color: '#2a3176',
      fontWeight: 'bold',
      marginLeft: 20,
      marginBottom: 20,
    },
    data: {
      marginTop: -10,
      color: '#717171',
      fontWeight: 500,
      fontFamily: 'Open Sans',
    },
    labelData: {
      color: '#717171',
      fontFamily: 'Open Sans',
    },
    gridItem: {
      marginTop: -40,
      '&& .MuiGrid-item': {
        paddingTop: 0,
        maxHeight: 60,
      },
    },
  }),
);

export default function Users() {
  const classes = useStyles();
  const { notifySuccess, notifyError } = useContext(FlashContext);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);
  const [user, setUser] = useState();
  const [allGroupPermissions, setAllGroupPermissions] = useState([]);
  const [modulos, setModulos] = useState(permissionModule);
  const [groupOpt, setGroupOpt] = useState([]);
  const [groupSelected, setGroupSelected] = useState({
    label: 'Personalizado',
    value: 'Personalizado',
  });
  const { id } = useParams();
  const [msg, setMsg] = useState('Buscando detalhes...');

  const [edit, setEdit] = useState(false);

  const [newStatus, setNewStatus] = useState('');
  const [typeUser, setTypeUser] = useState('');
  const [newPhone, setNewPhone] = useState('');
  const [newMobile, setNewMobile] = useState('');
  const [newCpf, setNewCpf] = useState('');
  const [twoFaType, setTwoFaType] = useState('');

  const [open, setOpen] = useState(false);

  const autoChangeGroup = role => {
    let end = '';
    allGroupPermissions.forEach(g => {
      const group = g.permissions.split('');
      try {
        group.forEach((p, i) => {
          if (p !== role[i]) {
            // eslint-disable-next-line no-undef
            throw new Exception('Time to end the loop');
          }
        });
        end = { label: g.name, value: g.name };
      } catch (e) {
        console.log('parar');
      }
    });
    if (end === '') {
      setGroupSelected({ label: 'Personalizado', value: 'Personalizado' });
    } else {
      setGroupSelected(end);
    }
  };

  const handleChange = (m, k, event) => {
    const orig = _.cloneDeep(modulos);
    const mod = orig.find(o => o.name === m);
    const i = mod.itens.find(mm => mm.name === k);
    i.status = event.target.checked;
    setModulos(orig);

    let geralRole = '';
    orig.forEach(om => {
      om.itens.forEach(oi => {
        if (oi.status) {
          geralRole += '1';
        } else {
          geralRole += '0';
        }
      });
    });
    autoChangeGroup(geralRole);
  };

  const find = () => {
    if (id) {
      setLoading(true);
      const cancel = new AbortController();
      api.user
        .getById(id, cancel)
        .then(res => {
          setLoading(false);
          if (res.retorno.permission) modulosConf(res.retorno.permission);
          setUser(res.retorno);
          setNewCpf(res.retorno.cpf);
          setNewMobile(res.retorno.mobile);
          setNewPhone(res.retorno.phone);
          setTypeUser(
            res.retorno.role_id === '1'
              ? { label: 'ADM Element', value: '1' }
              : res.retorno.role_id === '9'
              ? { label: 'ADM Cliente', value: '9' }
              : { label: 'Cliente', value: '3' },
          );

          // { label: 'Cliente ADM', value: '9' },
          // { label: 'Cliente', value: '3' },
          // { label: 'ADM Element', value: '1' },
          setNewStatus(
            res.retorno.is_active
              ? { label: 'Ativo', value: true }
              : { label: 'Desativado', value: false },
          );
          setTwoFaType(
            res.retorno.two_fa_type === '1'
              ? { label: 'Email', value: '1' }
              : { label: 'SMS', value: '2' },
          );
        })
        .catch(err => {
          console.log(err);
          setLoading(false);
        });

      // twoFaType
    }
  };
  useEffect(() => {
    find();
  }, []);

  const modulosConf = rules => {
    setModulos([
      {
        name: 'Dashboard',
        itens: [
          { name: 'Pedidos', status: rules[0] === '1' },
          { name: 'Base de Cartões', status: rules[1] === '1' },
          { name: 'Contratos e taxas', status: rules[2] === '1' },
          { name: 'Liberação de pagamentos', status: rules[3] === '1' },
          { name: 'Movimentação', status: rules[4] === '1' },
          { name: 'Painel', status: rules[5] === '1' },
          { name: 'Acessos GM', status: rules[6] === '1' },
          { name: 'Área administrativa', status: rules[7] === '1' },
        ],
      },
      {
        name: 'Pedidos',
        itens: [
          { name: 'Novo Pedido', status: rules[8] === '1' },
          { name: 'Duplicar Pedido', status: rules[9] === '1' },
          { name: 'Cancelar Pedido', status: rules[10] === '1' },
          { name: 'Pagar Pedido', status: rules[11] === '1' },
        ],
      },
      {
        name: 'Detalhes do pedido',
        itens: [
          { name: 'Planilha premiados', status: rules[12] === '1' },
          { name: 'Adicionar itens', status: rules[13] === '1' },
          { name: 'Descartar itens', status: rules[14] === '1' },
          { name: 'Obter boleto', status: rules[15] === '1' },
          { name: 'Pagar', status: rules[16] === '1' },
        ],
      },
      {
        name: 'Contratos e taxas',
        itens: [
          { name: 'Novo contrato', status: rules[17] === '1' },
          { name: 'Editar contrato', status: rules[18] === '1' },
          { name: 'Desativar contrato', status: rules[19] === '1' },
          { name: 'Download', status: rules[20] === '1' },
        ],
      },
      {
        name: 'Liberação de cargas',
        itens: [
          { name: 'Upload retorno', status: rules[21] === '1' },
          { name: 'Liberar itens', status: rules[22] === '1' },
          { name: 'Cancelar itens', status: rules[23] === '1' },
        ],
      },
      {
        name: 'Movimentação',
        itens: [{ name: 'Download Movimentação', status: rules[24] === '1' }],
      },
      {
        name: 'Administração',
        itens: [
          { name: 'Financeiro', status: rules[25] === '1' },
          {
            name: 'Grupo de permissões',
            status: rules[26] === '1',
          },
          { name: 'Gerenciamento de usuários', status: rules[27] === '1' },
        ],
      },
    ]);
  };

  function compareRole(groupRole) {
    groupRole.forEach(g => {
      const group = g.permissions.split('');
      try {
        group.forEach((p, i) => {
          if (p !== user.permission[i]) {
            // eslint-disable-next-line no-undef
            throw new Exception('Time to end the loop');
          }
        });
        setGroupSelected({ label: g.name, value: g.name });
      } catch (e) {
        console.log('parar');
      }
    });
  }

  useEffect(() => {
    if (user) {
      const cancel = new AbortController();
      api.permissions
        .getAll(cancel)
        .then(res => {
          const list = [];
          res.docs?.forEach(d => {
            list.push({ label: d.name, value: d.name });
          });
          list.push({ label: 'Personalizado', value: 'Personalizado' });
          setGroupOpt(list);
          compareRole(res.docs);
          setAllGroupPermissions(res.docs);
          // setTotalPages(res.pages);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [user]);

  const handleGroup = event => {
    const iFind = allGroupPermissions.find(a => a.name === event.label);
    if (iFind) modulosConf(iFind.permissions);
    setGroupSelected(event);
  };

  const updatePermissions = () => {
    setMsg('Atualizando permissões...');
    setLoading(true);
    let iRule = '';
    modulos.forEach(om => {
      om.itens.forEach((oi, i) => {
        if (oi.status) {
          iRule += '1';
        } else {
          iRule += '0';
        }
      });
    });
    api.user
      .editPermission(id, iRule)
      .then(res => notifySuccess('Permissões editadas com sucesso'))
      .catch(err => notifyError(err.msg))
      .finally(() => setLoading(false));
  };

  const updateUser = () => {
    setMsg('Atualizando usuário...');
    setLoading(true);
    api.user
      .editUser(
        user?.email,
        newCpf,
        newStatus.value,
        newMobile,
        newPhone,
        twoFaType.value,
        typeUser.value,
      )
      .then(() => {
        notifySuccess('Usuário editado com sucesso');
        find();
        setEdit(false);
      })
      .catch(err => notifyError(err.msg))
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Loading open={loading} msg={msg} />
      <div className="col-sm-12" style={{ marginTop: 50 }}>
        <div
          className="col-sm-12"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <h4 className={styles.textTitle}>{user?.name}</h4>
          <Button
            color="primary"
            variant="contained"
            onClick={() => history.push(routing.users)}
          >
            Voltar
          </Button>
        </div>
        <Grid
          container
          className={classes.gridItem}
          spacing={1}
          style={{
            marginTop: 15,
            padding: 40,
            alignItems: 'center',
            justifyContent: 'start',
            backgroundColor: '#E1E1E6',
            borderRadius: 8,
            paddingBottom: 10,
          }}
        >
          <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
            <h6 className={classes.labelData}>CPF</h6>
            {!edit ? (
              <p className={classes.data}>{user?.cpf}</p>
            ) : (
              <TextField
                value={newCpf}
                onChange={e => setNewCpf(e.target.value)}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
            <h6 className={classes.labelData}>E-mail</h6>
            <p className={classes.data}>{user?.email}</p>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
            <h6 className={classes.labelData}>Telefone</h6>
            {!edit ? (
              <p className={classes.data}>
                {user?.phone === 'null' ? 'Não cadastrado' : user?.phone}
              </p>
            ) : (
              <TextField
                value={newPhone}
                onChange={e => setNewPhone(e.target.value)}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
            <h6 className={classes.labelData}>Celular</h6>
            {!edit ? (
              <p className={classes.data}>{user?.mobile}</p>
            ) : (
              <TextField
                value={newMobile}
                onChange={e => setNewMobile(e.target.value)}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
            <h6 className={classes.labelData}>Status</h6>
            {/* <p className={classes.data}>
              {user?.is_active ? 'Ativo' : 'Desativado'}
            </p> */}
            {!edit ? (
              <p className={classes.data}>
                {user?.is_active ? 'Ativo' : 'Desativado'}
              </p>
            ) : (
              // <TextField value={user?.is_active ? 'Ativo' : 'Desativado'} />
              <Select
                width="300"
                styles={customStyles}
                className="basic-single"
                classNamePrefix="select"
                placeholder="Status"
                value={newStatus}
                onChange={setNewStatus}
                isSearchable={false}
                options={[
                  { label: 'Ativo', value: true },
                  { label: 'Desativado', value: false },
                ]}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
            <h6 className={classes.labelData}>Tipo usuário</h6>
            {/* <p className={classes.data}>
              {user?.role_id === '1' ? 'Administrador' : 'Cliente'}
            </p> */}
            {!edit ? (
              <p className={classes.data}>
                {user?.role_id === '1'
                  ? 'ADM Element'
                  : user?.role_id === '9'
                  ? 'ADM Cliente'
                  : 'Cliente'}
              </p>
            ) : api.currentUser.user.role.id !== '9' ? (
              <Select
                width="300"
                styles={customStyles}
                className="basic-single"
                classNamePrefix="select"
                placeholder="Status"
                value={typeUser}
                onChange={setTypeUser}
                isSearchable={false}
                options={[
                  { label: 'ADM Cliente', value: '9' },
                  { label: 'Cliente', value: '3' },
                  { label: 'ADM Element', value: '1' },
                ]}
              />
            ) : (
              <p className={classes.data}>
                {user?.role_id === '1'
                  ? 'ADM Element'
                  : user?.role_id === '9'
                  ? 'ADM Cliente'
                  : 'Cliente'}
              </p>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
            <h6 className={classes.labelData}>Duplo Fator</h6>
            {!edit ? (
              <p className={classes.data}>
                {user?.two_fa_type === '1' ? 'Email' : 'SMS'}
              </p>
            ) : (
              <Select
                width="300"
                styles={customStyles}
                className="basic-single"
                classNamePrefix="select"
                placeholder="Status"
                value={twoFaType}
                onChange={setTwoFaType}
                isSearchable={false}
                options={[
                  { label: 'Email', value: '1' },
                  { label: 'SMS', value: '2' },
                ]}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
            <h6 className={classes.labelData}>Criado em</h6>
            <p className={classes.data}>
              {user && user.created_at && moment(user.created_at).format('LLL')}
            </p>
          </Grid>
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'end' }}>
            <Button
              color="primary"
              style={{ background: !edit ? '' : 'rgb(181, 27, 0)' }}
              variant="contained"
              onClick={() => setEdit(!edit)}
            >
              {!edit ? 'Editar' : 'Cancelar'}
            </Button>
            {edit && (
              <Button
                color="primary"
                style={{ marginLeft: 10 }}
                variant="contained"
                onClick={updateUser}
              >
                Salvar
              </Button>
            )}
          </Grid>
        </Grid>
        {api?.currentUser?.user?.role?.id !== '9' && (
          <>
            <div style={{ display: 'flex', marginTop: '10px' }}>
              <h4 className={styles.textTitle}>Contratos associados</h4>
              <Button
                color="primary"
                style={{ marginLeft: 10 }}
                variant="contained"
                onClick={() => setOpen(true)}
              >
                +
              </Button>
            </div>
            <Grid
              container
              className={classes.gridItem}
              spacing={1}
              style={{
                marginTop: 15,
                padding: 10,
                alignItems: 'center',
                justifyContent: 'start',
                backgroundColor: '#E1E1E6',
                borderRadius: 8,
                paddingBottom: 10,
              }}
            >
              <ContractsTable docs={user?.LegalEntity} search={find} />
            </Grid>
          </>
        )}

        <Grid container spacing={3}>
          <div style={{ display: 'flex', alignItems: 'center', padding: 10 }}>
            <h4 className={styles.textTitle}>Permissões</h4>
            {api.currentUser.user.role.id !== '9' && (
              <Select
                styles={customStyles1}
                classNamePrefix="select"
                placeholder="Grupo"
                isSearchable={false}
                options={groupOpt}
                onChange={handleGroup}
                value={groupSelected}
                menuPortalTarget={document.body}
              />
            )}
          </div>
          {modulos.map(m => (
            <Grid
              item
              xs={12}
              style={{
                backgroundColor: '#E1E1E6',
                margin: 5,
                borderRadius: 8,
                padding: 5,
              }}
            >
              <h5 className={classes.myLabel}>{m.name}</h5>
              <FormGroup
                row
                style={{
                  marginLeft: 20,
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                {m.itens.map(i => {
                  if (
                    api.currentUser.user.role.id === '9' &&
                    m.name === 'Dashboard' &&
                    i.name === 'Área administrativa'
                  ) {
                    return null;
                  }
                  if (
                    api.currentUser.user.role.id === '9' &&
                    m.name === 'Pedidos' &&
                    i.name === 'Pagar Pedido'
                  ) {
                    return null;
                  }
                  if (
                    api.currentUser.user.role.id === '9' &&
                    m.name === 'Detalhes do pedido' &&
                    i.name === 'Pagar'
                  ) {
                    return null;
                  }
                  if (
                    api.currentUser.user.role.id === '9' &&
                    m.name === 'Contratos e taxas' &&
                    (i.name === 'Novo contrato' ||
                      i.name === 'Editar contrato' ||
                      i.name === 'Desativar contrato')
                  ) {
                    return null;
                  }
                  if (
                    api.currentUser.user.role.id === '9' &&
                    m.name === 'Liberação de cargas' &&
                    i.name === 'Upload retorno'
                  ) {
                    return null;
                  }
                  if (
                    api.currentUser.user.role.id === '9' &&
                    m.name === 'Administração' &&
                    (i.name === 'Financeiro' ||
                      i.name === 'Grupo de permissões')
                  ) {
                    return null;
                  }
                  return (
                    <FormControlLabel
                      style={{ minWidth: 240, marginTop: -20 }}
                      control={
                        <Checkbox
                          checked={i.status}
                          onChange={e => handleChange(m.name, i.name, e)}
                        />
                      }
                      label={i.name}
                    />
                  );
                })}
              </FormGroup>
            </Grid>
          ))}
        </Grid>
        <div
          style={{
            display: 'flex',
            justifyContent: 'end',
            marginTop: 15,
          }}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={updatePermissions}
          >
            Salvar
          </Button>
        </div>
      </div>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={() => setOpen(false)}
      >
        <VincContract setOpenModal={setOpen} userId={id} search={find} />
      </Dialog>
    </>
  );
}
