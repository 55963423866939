import React, { useState } from 'react';
import {
  Button,
  Grid,
  Typography,
  Modal,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import ExtracsTable from '../components/tables/Extract/ExtractsTableV1';
// import CommissionSummariesListTable from '@/components/tables/CommissionSummariesListTable/CommissionSummariesListTable';
import CommissionSummariesListTable from '../components/tables/CommissionSummariesListTable/CommissionSummariesListTable';
// import makeJSDateObject from '../util/makeJSDateObject';
import routing from '../../../service/routes/routing';
// import FlashContext from '../../../components/contexts/FlashContext';
// import api from '../../../service/axios/api';

const useStyles = makeStyles(theme =>
  createStyles({
    tableBody: {
      '& .MuiTableCell-body': {
        borderBottomColor: theme.palette.grey[500],
      },
      '& .MuiTableRow-root:nth-last-child(2) .MuiTableCell-body': {
        borderBottomColor: 'transparent',
      },
      '& .MuiTableRow-root:last-child': {
        '& .MuiTableCell-body': {
          borderBottomColor: 'transparent',
          color: 'white',
          fontWeight: 600,
          backgroundColor: theme.palette.primary.main,
        },
        '& .MuiTableCell-body:first-child': {
          borderTopLeftRadius: theme.spacing(1),
          borderBottomLeftRadius: theme.spacing(1),
        },
        '& .MuiTableCell-body:last-child': {
          borderTopRightRadius: theme.spacing(1),
          borderBottomRightRadius: theme.spacing(1),
        },
      },
    },
  }),
);

const defaultTotalExtract = [
  {
    id: '1',
    name: 'Créditos',
    value: 'R$ X,00',
  },
  {
    id: '2',
    name: 'Débitos',
    value: 'R$ X,00',
  },
  {
    id: '3',
    name: 'Total Ciclo',
    value: 'R$ 10X,00',
  },
];

function TotalExtract() {
  const classes = useStyles();

  return (
    <TableContainer>
      <Table aria-label="spanning table" size="small">
        <TableBody className={classes.tableBody}>
          {defaultTotalExtract.map(row => (
            <TableRow key={`total_${row.id}`}>
              <TableCell align="left">{row.name}</TableCell>
              <TableCell align="center">{row.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

// eslint-disable-next-line no-unused-vars
// const rows = [
//   {
//     id: '1',
//     createdAt: makeJSDateObject(new Date()),
//     nature: 'Comissão',
//     description: 'Campanha',
//     price: 'R$ 1,00',
//     status: 'Crédito',
//     companyName: 'Mello & Araujo',
//   },
//   {
//     id: '2',
//     createdAt: makeJSDateObject(new Date()),
//     description: 'Campanha',
//     nature: 'Premiação',
//     price: 'R$ X,00',
//     status: 'Crédito',
//     companyName: 'Mello & Araujo',
//   },
//   {
//     id: '3',
//     createdAt: makeJSDateObject(new Date()),
//     nature: 'Antecipação',
//     companyName: 'Mello & Araujo',
//     description: 'Campanha',
//     price: 'R$ X,00',
//     status: 'Débito',
//   },
//   {
//     id: '4',
//     createdAt: makeJSDateObject(new Date()),
//     description: 'Campanha',
//     companyName: 'Mello & Araujo',
//     nature: 'Estornos',
//     price: 'R$ X,00',
//     status: 'Débito',
//   },
// ];

function Extracts() {
  const [open, setOpen] = useState(false);
  // const [rows, setRows] = useState([]);
  const history = useHistory();
  // const { notifyError } = useContext(FlashContext);

  // useEffect(() => {
  //   const cancel = new AbortController();
  //   api.commercialAgent
  //     .getSummary(cancel)
  //     .then(res => setRows(res.data.docs))
  //     .catch(err => {
  //       notifyError(err.message);
  //     });
  // }, []);
  // const handleOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid item lg={4}>
          <Typography variant="h4" component="h2" gutterBottom>
            Extratos
          </Typography>
          <Grid container alignItems="center">
            <Grid item lg={5}>
              <Typography>Ciclo de Apuração</Typography>
            </Grid>
            <Grid item lg={5}>
              <Button
                variant="text"
                color="primary"
                endIcon={<AddCircleIcon />}
                // onClick={handleOpen}
                onClick={() => history.push(routing.salesSendInvoice)}
              >
                Carregar NF
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item lg={3}>
          <Grid container justify="flex-end">
            <TotalExtract />
          </Grid>
        </Grid>
      </Grid>
      <ExtracsTable />
      <Modal open={open} onClose={handleClose}>
        <CommissionSummariesListTable close={handleClose} />
      </Modal>
    </>
  );
}

export default Extracts;
