/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */
import * as React from 'react';

export default function Review({ productName }) {
  return (
    <div className="box-new-card" style={{ height: 370, overflowY: 'scroll' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: -15,
        }}
      >
        <p className="text-box-inform">Resumo e confirmação</p>
        <p className="text-box-inform-2">COD.: PROD_TRANSF_002</p>
      </div>
      <p className="review-label-1">Características do produto</p>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <p className="review-label ">Nome: {productName}</p>
          <p className="review-value "> </p>
          {/* <img className="review-img" src="./img/edit.svg" alt="logo" /> */}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <p className="review-label ">Banco:</p>
          <p className="review-value "> </p>
          {/* <img className="review-img" src="./img/edit.svg" alt="logo" /> */}
        </div>
        <div className="my-colunm-align ">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <p className="review-label ">Tecnologias disponíveis:</p>
            <p className="review-value "> TED</p>
            <img className="review-img" src="./img/edit.svg" alt="logo" />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: -10,
              marginLeft: 185,
            }}
          >
            <p className="review-value "> PIX</p>
            <img className="review-img" src="./img/edit.svg" alt="logo" />
          </div>
        </div>
      </div>
      <p className="review-label-1">Transação Nacional</p>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: -40,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <p className="review-label ">Transferência via TED:</p>
          <p className="review-value ">R$00.00 </p>
          <img className="review-img" src="./img/edit.svg" alt="logo" />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <p className="review-label ">Transferência via PIX:</p>
          <p className="review-value ">R$00.00 </p>
          <img className="review-img" src="./img/edit.svg" alt="logo" />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <p className="review-label ">Transação Rejeitada:</p>
          <p className="review-value ">R$00.00 </p>
          <img className="review-img" src="./img/edit.svg" alt="logo" />
        </div>
      </div>
      <p className="review-label-1">Transação Internacional</p>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: -40,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <p className="review-label ">Taxa Swift:</p>
          <p className="review-value ">R$00.00 </p>
          <img className="review-img" src="./img/edit.svg" alt="logo" />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <p className="review-label ">Free:</p>
          <p className="review-value ">USD </p>
          <img className="review-img" src="./img/edit.svg" alt="logo" />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <p className="review-label ">Câmbio:</p>
          <p className="review-value ">R$00.00 </p>
          <img className="review-img" src="./img/edit.svg" alt="logo" />
        </div>
      </div>
    </div>
  );
}
