/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-useless-fragment */
import * as React from 'react';

// import Grid from '@mui/material/Grid';
// import CssBaseline from '@mui/material/CssBaseline';

// import Box from '@mui/material/Box';
// import Container from '@mui/material/Container';
// import Paper from '@mui/material/Paper';
// import Stepper from '@mui/material/Stepper';
// import Step from '@mui/material/Step';
// import StepLabel from '@mui/material/StepLabel';
// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import { Grid, Box, Stepper, Step, StepLabel } from '@material-ui/core';
// import { createTheme } from '@mui/material/styles';
import SafeSettings from './SafeSettings';
import TypeSafe from './TypeSafe';
import Review from './Review';
import Created from './Created';

import MyButton from '../../../../components/button/MyButton';

import './checkout.css';

const useStyles = makeStyles(() =>
  createStyles({
    icon: {
      fill: '#41B490',
    },
    text: {
      fill: 'white',
    },
  }),
);

const steps = ['Shipping address', 'Payment details', 'Review your order'];

function getStepContent(step) {
  switch (step) {
    case 0:
      return <SafeSettings />;
    case 1:
      return <TypeSafe />;
    case 2:
      return <Review />;
    default:
      throw new Error('Unknown step');
  }
}

// const theme = createTheme();

// eslint-disable-next-line react/prop-types
export default function Checkout({ setOpenModal }) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    if (activeStep === 0) {
      setOpenModal(false);
    } else {
      setActiveStep(activeStep - 1);
    }
  };

  return (
    // <ThemeProvider theme={theme}>
    // <CssBaseline />
    // {/* <Container component="main" maxWidth="sm" sx={{ mb: 4 }}> */}
    // <Container component="main">
    <Grid container spacing={2} style={{ padding: 30 }}>
      <Grid
        container
        spacing={2}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: 20,
        }}
      >
        {/* <div style={{
        display: 'flex', flexDirection: 'row', justifyContent:
         'space-between', alignItems: 'center',
      }}
      > */}
        {/* <p className="new-category">Nova categoria de produto</p> */}
        <Stepper activeStep={activeStep} style={{ width: 305 }}>
          {steps.map(
            label =>
              activeStep < 3 && (
                <Step key={label} style={{ color: '#41B490' }}>
                  <StepLabel
                    StepIconProps={{
                      classes: {
                        active: classes.icon,
                        text: classes.text,
                        completed: classes.icon,
                      },
                    }}
                  />
                </Step>
              ),
          )}
        </Stepper>
        <p className="x" onClick={() => setOpenModal(false)}>
          X
        </p>
        {/* </div> */}
      </Grid>
      {activeStep < 3 && (
        <h5 className="text-checkout-inform">Novo acessório em Seguro</h5>
      )}
      <>
        {activeStep === steps.length ? (
          <>
            <Created setOpenModal={setOpenModal} />
          </>
        ) : (
          <>
            {getStepContent(activeStep)}
            {/* <div style={{
              display: 'flex', flexDirection: 'row', justifyContent:
              'space-between', alignItems: 'center', marginTop: 50, marginBottom: 50,
            }}
            > */}
            <Grid
              container
              spacing={2}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: 30,
                padding: 10,
              }}
            >
              <h6
                style={{ marginLeft: 20 }}
                onClick={() => setOpenModal(false)}
              >
                Sair
              </h6>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                {/* {activeStep !== 0 && ( */}
                {/* <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                  Voltar
                </Button> */}
                {/* // )} */}
                <MyButton
                  style={{
                    width: 120,
                    height: 40,
                    borderRadius: 10,
                    backgroundColor: '#657588',
                  }}
                  onClick={handleBack}
                  label="Voltar"
                />
                <MyButton
                  style={{
                    width: 120,
                    height: 40,
                    borderRadius: 10,
                    marginLeft: 30,
                  }}
                  onClick={handleNext}
                  label="Continuar"
                />
              </Box>
            </Grid>
          </>
        )}
      </>
    </Grid>
    // {/* </Paper> */}
    // </Container>
    // </ThemeProvider>
  );
}
