/* eslint-disable react/function-component-definition */
/* eslint-disable import/prefer-default-export */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
// import EditIcon from '@material-ui/icons/Edit';
import DescriptionIcon from '@material-ui/icons/Description';
import moment from 'moment-timezone';

// import Dialog from '@material-ui/core/Dialog';
// import DialogTitle from '@material-ui/core/DialogTitle';
// import DialogActions from '@material-ui/core/DialogActions';

// import Button from '@material-ui/core/Button';

// import Select from 'react-select';
// import Grid from '@material-ui/core/Grid';
// import TextField from '@material-ui/core/TextField';

// import _ from 'lodash';

// import {
// TimePicker,
// KeyboardDatePicker,
// MuiPickersUtilsProvider,
// DateTimePicker,
// KeyboardTimePicker,
// } from '@material-ui/pickers';
// import DateFnsUtils from '@date-io/date-fns';
// import locale from 'date-fns/locale/pt-BR';
// import FlashContext from '../contexts/FlashContext';
// import Loading from '../loading/Loading';
// import api from '../../service/axios/api';

// const optionsStatus = [
//   {
//     label: 'Aguardando cartão',
//     value: 'AwaitingCard',
//   },
//   {
//     label: 'Processando',
//     value: 'Processing',
//   },
//   {
//     label: 'Aguardando pagamento',
//     value: 'AwaitingPayment',
//   },
//   {
//     label: 'Processado',
//     value: 'Processed',
//   },
//   {
//     label: 'Aguardando cartão finalizar',
//     value: 'AwaitingCardRelease',
//   },
//   {
//     label: 'Cancelado',
//     value: 'Canceled',
//   },
//   {
//     label: 'Recebido',
//     value: 'Received',
//   },
//   {
//     label: 'Rejeitado',
//     value: 'Rejected',
//   },
//   {
//     label: 'Processamento automático',
//     value: 'AutoProcessing',
//   },
//   {
//     label: 'Aguardando conciliação',
//     value: 'AwaitingConciliation',
//   },
//   {
//     label: 'Aguardando processamento',
//     value: 'AwaitingProcessing',
//   },
//   {
//     label: 'Criado',
//     value: 'Created',
//   },
// ];

// const customStylesEdit = {
//   control: provided => ({
//     ...provided,
//     // width: 105,
//     height: 40,
//     borderRadius: 10,
//   }),
//   indicatorSeparator: () => ({ display: 'none' }),
//   option: provided => ({
//     ...provided,
//   }),
//   menuPortal: base => ({ ...base, zIndex: 9999 }),
// };

function createData(date_send, file_send, status, date_processed) {
  return {
    date_send,
    file_send,
    status,
    date_processed,
  };
}

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  table: {
    minWidth: 1100,
  },
});

const StyledTableCell = withStyles(() => ({
  head: {
    // backgroundColor: theme.palette.common.black,
    // color: theme.palette.common.white,
  },
  body: {
    fontSize: 12,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

// eslint-disable-next-line no-unused-vars
export const BanklyTable = ({
  docs,
  // setDocs,
  page,
  setPage,
  itens,
  setItens,
  total,
}) => {
  const classes = useStyles();

  const [rows, setRows] = useState([]);
  useEffect(() => {
    const list = [];
    docs.forEach(doc => {
      list.push(
        createData(
          doc.created_at &&
            moment.utc(doc.created_at).format('DD/MM/yyyy HH:mm'),
          doc.gcs_file,
          doc.status,
          doc.updated_at &&
            moment.utc(doc.updated_at).format('DD/MM/yyyy HH:mm'),
        ),
      );
    });
    setRows(list);
  }, [docs]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setItens(+event.target.value);
    setPage(0);
  };

  const returnStatus = st => {
    switch (st) {
      case 'Processed':
        return 'Processado';
      case 'Created':
        return 'Criado';
      default:
        return st;
    }
  };

  return (
    <>
      <Paper className={classes.root}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Data Envio</StyledTableCell>
                <StyledTableCell>Arquivo Enviado</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell>Data Processamento</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, i) => (
                <StyledTableRow key={i}>
                  <StyledTableCell>{row.date_send}</StyledTableCell>
                  <StyledTableCell>
                    {row.file_send && (
                      <DescriptionIcon
                        style={{ cursor: 'pointer' }}
                        onClick={() => window.open(row.file_send, '_blank')}
                      />
                    )}
                  </StyledTableCell>
                  <StyledTableCell>{returnStatus(row.status)}</StyledTableCell>
                  <StyledTableCell>{row.date_processed}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          // count={rows.length}
          count={total}
          rowsPerPage={itens}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      {/* MODAL DE EDIÇÃO */}
    </>
  );
};
