/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
import React, { useContext, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  Container,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  // TimePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  // DateTimePicker,
  // KeyboardTimePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import locale from 'date-fns/locale/pt-BR';
import { yupResolver } from '@hookform/resolvers/yup';
import StepperContext from '../../context/StepperContext';
import CampaingSchema from '../../schemas/CampaingSchema';

const useStyles = makeStyles(theme => ({
  form: {
    minHeight: '500px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  content: {
    padding: theme.spacing(4),
  },
  section: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(8),
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(4),
    background: '#f0f0f0',
    width: '100%',
  },
}));

function CampaignForm({ campaing }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    defaultValues: campaing,
    resolver: yupResolver(CampaingSchema),
  });
  const classes = useStyles();
  const stepper = useContext(StepperContext);
  const [date, setDate] = useState(null);

  const submit = data => {
    stepper.next(data);
  };

  const value = getValues('expiresIn');
  useEffect(() => {
    register('expiresIn');
  }, [register]);
  useEffect(() => {
    setDate(value || null);
  }, [setDate, value]);

  return (
    <form className={classes.form} onSubmit={handleSubmit(submit)}>
      <Container className={classes.content}>
        <div className={classes.section}>
          <Typography variant="h5" component="h3" gutterBottom>
            Dados da campanha
          </Typography>
          <Grid container spacing={10}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <TextField
                inputProps={{ ...register('name') }}
                error={!!errors.name}
                helperText={errors.name?.message}
                label="Nome"
                variant="outlined"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              {/* <TextField
                inputProps={{ ...register('expiresIn') }}
                error={!!errors.expiresIn}
                helperText={errors.expiresIn?.message}
                label="Validade"
                variant="outlined"
                size="small"
                fullWidth
              /> */}
              <MuiPickersUtilsProvider
                utils={DateFnsUtils}
                locale={locale}
                // className={classes.margin}
              >
                <KeyboardDatePicker
                  fullWidth
                  size="small"
                  value={date}
                  disablePast
                  // inputRef={{ ...register('expiresIn') }}
                  inputVariant="outlined"
                  placeholder="Validade"
                  // onChange={dat => setExpiresIn(dat)}
                  // onChange={val => {
                  //   register('expiresIn', val);
                  // }}
                  onChange={dated =>
                    setValue('expiresIn', dated, {
                      shouldValidate: true,
                      shouldDirty: true,
                    })
                  }
                  autoOk
                  format="dd/MM/yyyy"
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField
                inputProps={{ ...register('comments') }}
                error={!!errors.comments}
                helperText={errors.comments?.message}
                label="Comentários"
                variant="outlined"
                size="small"
                multiline
                rows={4}
                fullWidth
              />
            </Grid>
          </Grid>
        </div>
        {/* <div className={classes.section}>
          <Typography variant="h5" component="h3" gutterBottom>
            Dados Pessoais
          </Typography>
          <Grid container spacing={10}>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                inputProps={{ ...register('admin.fullName') }}
                error={!!errors.admin?.fullName}
                helperText={errors.admin?.fullName?.message}
                label="Nome Completo"
                variant="outlined"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                inputProps={{ ...register('admin.cpf') }}
                error={!!errors.admin?.cpf}
                helperText={errors.admin?.cpf?.message}
                label="CPF"
                variant="outlined"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                inputProps={{ ...register('admin.companyRole') }}
                error={!!errors.admin?.companyRole}
                helperText={errors.admin?.companyRole?.message}
                label="Cargo"
                variant="outlined"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                inputProps={{ ...register('admin.companyArea') }}
                error={!!errors.admin?.companyArea}
                helperText={errors.admin?.companyArea?.message}
                label="Área de trabalho"
                variant="outlined"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                inputProps={{ ...register('admin.phone') }}
                error={!!errors.admin?.phone}
                helperText={errors.admin?.phone?.message}
                label="Telefone"
                variant="outlined"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                inputProps={{ ...register('admin.mobile') }}
                error={!!errors.admin?.mobile}
                helperText={errors.admin?.mobile?.message}
                label="Celular"
                variant="outlined"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                inputProps={{ ...register('admin.email') }}
                error={!!errors.admin?.email}
                helperText={errors.admin?.email?.message}
                label="Email"
                variant="outlined"
                size="small"
                fullWidth
              />
            </Grid>
          </Grid>
        </div> */}
      </Container>
      <div className={classes.footer}>
        <Button type="submit" variant="contained" color="primary">
          Avançar
        </Button>
      </div>
    </form>
  );
}
export default CampaignForm;
