/* eslint-disable react/prop-types */
import React, { useState } from 'react';

import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { CircularProgress, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  buttonMargin: {
    marginTop: theme.spacing(3),
  },
}));
function SuccessModal({ title, open, onClose, onDownload }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [downloading, setDownloading] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const handleDownload = async () => {
    if (onDownload) {
      setDownloading(true);
      await onDownload();
      setDownloading(false);
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={onClose}
      aria-labelledby="max-width-dialog-title"
    >
      <Box
        p={10}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            pb={5}
          >
            <img src="/assets/success.svg" alt="success" />
          </Box>
          <Box p={5}>
            <Typography variant="h5" color="primary" align="center" paragraph>
              {title}
            </Typography>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-around"
        >
          {/* {onDownload && !downloading && (
            <Button
              onClick={handleDownload}
              variant="contained"
              color="primary"
            >
              <span>Baixar</span>
            </Button>
          )} */}

          {onDownload && downloading && (
            <Box display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          )}
          <Button
            onClick={e => onClose(e, 'click close')}
            variant="contained"
            color="primary"
            className={classes.buttonMargin}
          >
            <span>{t('newContract.success.btnConclude')}</span>
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}

export default SuccessModal;
