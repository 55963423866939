/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Pagination } from '@material-ui/lab';

import {
  Grid,
  Box,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableHead,
  Toolbar,
  Typography,
  Paper,
  Checkbox,
  IconButton,
  Tooltip,
  Menu,
  MenuItem,
  Dialog,
  TableCell,
  styled,
  DialogActions,
  DialogTitle,
} from '@material-ui/core';
import { withStyles, makeStyles, createStyles } from '@material-ui/core/styles';
import Select from 'react-select';
import api from '../../../service/axios/api';
import Loading from '../../loading/Loading';
import styles from '../../../styles/Layout.module.css';

import Button from '../../button/MyButton';

import routing from '../../../service/routes/routing';
import Status from '../../../service/translateStatus';

// import SelectOptions from './SelectOptions';
import SelectOptions1 from './SelectOptions1';

import './tableOrderItens.css';

const useStyles = makeStyles(() =>
  createStyles({
    textNotification: {
      textAlign: 'center',
      fontFamily: 'Open Sans',
      fontSize: 14,
      fontWeight: 'bold',
      lineHeight: 'auto',
      color: '#707070',
    },
  }),
);

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#005D9F',
    fontFamily: 'Open Sans',
    color: theme.palette.common.white,
  },
  body: {
    color: '#717171',
    fontFamily: 'Open Sans',
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:hover': {
      backgroundColor: '#41b490',
      color: '#41b490',
    },
  },
}))(TableRow);

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark'
        ? 'rgba(57,75,89,.5)'
        : 'rgba(206,217,224,.5)',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#137cbd',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#106ba3',
  },
});

const options = [
  {
    label: '25',
    value: 25,
  },
  {
    label: '50',
    value: 50,
  },
  {
    label: '100',
    value: 100,
  },
];

const EnhancedTableHead = props => {
  const { t } = useTranslation();
  const { onSelectAllClick, numSelected, rowCount } = props;

  return (
    <TableHead>
      <TableRow>
        <StyledTableCell component="th">
          {t('OrderDetails.table.name')}
        </StyledTableCell>
        <StyledTableCell component="th">
          {t('OrderDetails.table.docs')}
        </StyledTableCell>
        {props.type === 'Card' && (
          <StyledTableCell component="th">
            {t('OrderDetails.table.proxy')}
          </StyledTableCell>
        )}
        {props.type === 'Transfer' && (
          <StyledTableCell component="th">
            {t('OrderDetails.table.bank')}
          </StyledTableCell>
        )}
        {props.type === 'Transfer' && (
          <StyledTableCell component="th">
            {t('OrderDetails.table.ag')}
          </StyledTableCell>
        )}
        {props.type === 'Transfer' && (
          <StyledTableCell component="th">
            {t('OrderDetails.table.account')}
          </StyledTableCell>
        )}
        <StyledTableCell component="th">
          {t('OrderDetails.table.description')}
        </StyledTableCell>
        <StyledTableCell component="th">
          {t('OrderDetails.table.qtd')}
        </StyledTableCell>
        <StyledTableCell component="th">
          {t('OrderDetails.table.value')}
        </StyledTableCell>
        <StyledTableCell component="th">
          {t('OrderDetails.table.dateOf')} <br />{' '}
          {t('OrderDetails.table.processing')}
        </StyledTableCell>
        <StyledTableCell component="th">
          {t('OrderDetails.table.dateOf')} <br />{' '}
          {t('OrderDetails.table.conciliation')}
        </StyledTableCell>
        <StyledTableCell component="th">
          Última <br /> atualização
        </StyledTableCell>
        <StyledTableCell component="th">
          {t('OrderDetails.table.statusLabel')}
        </StyledTableCell>
        <StyledTableCell padding="checkbox">
          <Checkbox
            // color="primary"
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </StyledTableCell>
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
};

const EnhancedTableToolbar = function (props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: '#fff',
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable({
  type,
  orders,
  totalPages,
  setPage,
  page,
  itens,
  setItens,
  setStatus,
  // search,
  chipData,
  setChipData,
  searchItems,
  selected,
  setSelected,
  setOrderBy,
  consumed,
  provision,
}) {
  const history = useHistory();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [menuOptions, setMenuOptions] = useState(null);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [movimentDetails, setMovimentDetails] = useState([]);
  const [openTooltip, setOpenTooltip] = useState(false);

  const handleMenu = (row, event) => {
    setMenuOptions(row);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [rows, setRows] = useState([]);

  const classes = useStyles();

  useEffect(() => {
    if (type) {
      const listOrder = [];
      if (type === 'Transfer') {
        orders.forEach(or => {
          listOrder.push({
            id: or.id,
            name: or.name || or.company_name,
            cpf: or.cpf || or.cnpj,
            proxy: or.account_number,
            bank: or.bank_number,
            ag: or.branch_number,
            discritivo: or.comments,
            qtd: or.units,
            vlr: `R$${or.subtotal}`,
            dataProc:
              or.date_processing &&
              moment
                .utc(or.date_processing)
                .tz('America/Sao_Paulo')
                .format('DD/MM/yyyy'),
            dataCon:
              or.date_conciliation &&
              moment
                .utc(or.date_conciliation)
                .tz('America/Sao_Paulo')
                .format('DD/MM/yyyy'),
            status: Status(or.status, t),
            productId: or.product_id,
            all: or,
          });
        });
      } else {
        orders.forEach(or => {
          // console.log(or);
          listOrder.push({
            id: or.id,
            name: or.name,
            cpf: or.cpf || or.cnpj,
            proxy: or.proxy,
            discritivo: or.comments,
            qtd: or.units,
            vlr: `R$${or.subtotal}`,
            dataProc:
              or.date_processing &&
              moment
                .utc(or.date_processing)
                .tz('America/Sao_Paulo')
                .format('DD/MM/yyyy'),
            dataCon:
              or.date_conciliation &&
              moment
                .utc(or.date_conciliation)
                .tz('America/Sao_Paulo')
                .format('DD/MM/yyyy'),
            status: Status(or.status, t),
            productId: or.product_id,
            all: or,
          });
        });
      }
      setRows(listOrder);
    }
  }, [orders, type]);

  const disableCheck = productId => {
    switch (productId) {
      case '1':
        return true;
      case '2':
        return true;
      case '5':
        return true;
      case '6':
        return true;
      case '7':
        return true;
      case '17':
        return true;
      case '18':
        return true;
      case '32':
        return true;
      case '43':
        return true;
      case '56':
        return true;
      case '196':
        return true;
      case '171':
        return true;
      default:
        return false;
    }
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = [];
      rows.forEach(n => {
        if (!disableCheck(n.productId)) {
          newSelecteds.push(n.id);
        }
      });
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const isSelected = id => selected.indexOf(id) !== -1;

  const searchLogs = id => {
    setLoadingDetails(true);
    const cancel = new AbortController();
    api.moviment
      .getDetails(id, cancel)
      .then(res => {
        setMovimentDetails(res);
      })
      .catch(err => console.log(err))
      .finally(() => setLoadingDetails(false));
  };

  const view = data => {
    searchLogs(data);
    setOpen(true);
  };

  const closeDetails = () => {
    setOpen(false);
    setMovimentDetails([]);
  };

  function translateStatus(id) {
    switch (id) {
      case '1':
        return t('OrderDetails.table.status.validationError');
      case '2':
        return t('OrderDetails.table.status.bullCharge');
      case '3':
        return t('OrderDetails.table.status.statusChange');
      case '4':
        return t('OrderDetails.table.status.comments');
      case '5':
        return t('OrderDetails.table.status.cnab');
      case '6':
        return t('OrderDetails.table.status.accessoToken');
      case '7':
        return t('OrderDetails.table.status.requestSent');
      case '8':
        return t('OrderDetails.table.status.errorBlock');
      default:
        return '';
    }
  }

  const handleCloseTooltip = () => {
    setOpenTooltip(false);
  };

  const handleOpenTooltip = productId => {
    if (disableCheck(productId)) {
      setOpenTooltip(true);
    }
  };

  return (
    <>
      {/* <SelectOptions
        setStatus={setStatus}
        search={search}
        searchItems={searchItems}
        setOrderBy={setOrderBy}
      /> */}
      <SelectOptions1
        setStatus={setStatus}
        setChipData={setChipData}
        chipData={chipData}
        find={searchItems}
        setOrderBy={setOrderBy}
        product={[]}
        // setProduct={setProduct}
      />
      <h5 className={styles.descriptionTable}>
        {t('OrderDetails.table.descriptionItens')}
      </h5>
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size="medium"
            >
              <EnhancedTableHead
                numSelected={selected.length}
                onSelectAllClick={handleSelectAllClick}
                rowCount={rows.length}
                handleMenu={handleMenu}
                type={type}
              />
              <TableBody>
                {rows ? (
                  rows.length > 0 ? (
                    rows.map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <React.Fragment key={row.id}>
                          <StyledTableRow tabIndex={-1}>
                            <StyledTableCell
                              component="th"
                              id={labelId}
                              scope="row"
                            >
                              {row.name}
                            </StyledTableCell>
                            <StyledTableCell>{row.cpf}</StyledTableCell>
                            {type === 'Transfer' && (
                              <StyledTableCell>{row.bank}</StyledTableCell>
                            )}
                            {type === 'Transfer' && (
                              <StyledTableCell>{row.ag}</StyledTableCell>
                            )}
                            <StyledTableCell>{row.proxy}</StyledTableCell>
                            <StyledTableCell>{row.discritivo}</StyledTableCell>
                            <StyledTableCell>{row.qtd}</StyledTableCell>
                            <StyledTableCell>{row.vlr}</StyledTableCell>
                            <StyledTableCell>{row.dataProc}</StyledTableCell>
                            <StyledTableCell>{row.dataCon}</StyledTableCell>
                            <StyledTableCell>
                              {row?.all?.updated_at &&
                                moment
                                  .utc(row.all?.updated_at)
                                  .format('DD/MM/YYYY HH:mm')}
                            </StyledTableCell>
                            <StyledTableCell style={{ width: 140 }}>
                              {row.status}
                              {row.status === 'Rejeitado' && (
                                <VisibilityIcon
                                  style={{
                                    cursor: 'pointer',
                                    marginLeft: '5px',
                                  }}
                                  onClick={() => view(row.id)}
                                />
                              )}
                            </StyledTableCell>
                            {disableCheck(row.productId) ? (
                              <Tooltip
                                title="Não é permitido a exclusão deste item"
                                onClose={handleCloseTooltip}
                                onOpen={() => handleOpenTooltip(row.productId)}
                                open={openTooltip}
                              >
                                <StyledTableCell padding="checkbox">
                                  <Checkbox
                                    color="primary"
                                    onClick={event =>
                                      handleClick(event, row.id)
                                    }
                                    disabled
                                    checked={isItemSelected}
                                    inputProps={{
                                      'aria-labelledby': labelId,
                                    }}
                                  />
                                </StyledTableCell>
                              </Tooltip>
                            ) : (
                              <StyledTableCell padding="checkbox">
                                <Checkbox
                                  color="primary"
                                  onClick={event => handleClick(event, row.id)}
                                  checked={isItemSelected}
                                  inputProps={{
                                    'aria-labelledby': labelId,
                                  }}
                                />
                              </StyledTableCell>
                            )}
                          </StyledTableRow>
                          {index + 1 === rows.length && (
                            <StyledTableRow>
                              <StyledTableCell
                                component="th"
                                id={labelId}
                                scope="row"
                              >
                                {t('OrderDetails.table.total')}
                              </StyledTableCell>
                              <StyledTableCell />
                              {type === 'Transfer' && <StyledTableCell />}
                              {type === 'Transfer' && <StyledTableCell />}
                              <StyledTableCell />
                              <StyledTableCell />
                              <StyledTableCell />
                              <StyledTableCell>
                                {`R$${(
                                  Number(provision) - Number(consumed)
                                ).toFixed(2)}`}
                              </StyledTableCell>
                              <StyledTableCell />
                              <StyledTableCell />
                              <StyledTableCell />
                              <StyledTableCell padding="checkbox" />
                            </StyledTableRow>
                          )}
                        </React.Fragment>
                      );
                    })
                  ) : (
                    <StyledTableRow>
                      <StyledTableCell colSpan={11} align="center">
                        {t('Order.table.noResult')}
                      </StyledTableCell>
                    </StyledTableRow>
                  )
                ) : (
                  <StyledTableRow>
                    <StyledTableCell colSpan={11} align="center" />
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Menu
            id="menu-appbar"
            style={{ borderRadius: '25px' }}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              className={classes.textNotification}
              onClick={handleClose}
            >
              {t('Order.table.duplicateOrder')}
            </MenuItem>
            <MenuItem
              className={classes.textNotification}
              onClick={() => setOpenModal(true)}
            >
              {t('Order.table.orderDetails')}
            </MenuItem>

            <MenuItem
              className={classes.textNotification}
              onClick={handleClose}
            >
              {t('Order.table.orderCancel')}
            </MenuItem>
          </Menu>
        </Paper>
      </Box>
      <Grid
        container
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: 15,
        }}
        spacing={2}
        direction="row"
      >
        <Grid item>
          <Select
            className="basic-single"
            classNamePrefix="select"
            placeholder="Itens"
            value={itens}
            onChange={setItens}
            options={options}
          />
        </Grid>
        <Grid item>
          <Pagination
            size="large"
            count={totalPages}
            page={page}
            onChange={handleChangePage}
            variant="outlined"
            shape="rounded"
          />
        </Grid>
      </Grid>
      <Dialog
        onClose={() => setOpenModal(false)}
        open={openModal}
        fullWidth
        maxWidth="lg"
      >
        <div style={{ width: 1192, height: 776, padding: 50 }}>
          <p className="modal-header"> {t('OrderDetails.summary')}</p>
          <div className="modal-box">
            <div className="modal-box-body" style={{ marginLeft: 15 }}>
              <p className="modal-box-body-header-label">
                {t('OrderDetails.summary')}
              </p>
              <p className="modal-box-body-value-label">
                {menuOptions && menuOptions.pd}
              </p>
              <p
                className="modal-box-body-header-label"
                style={{ marginTop: -10 }}
              >
                {t('OrderDetails.orderDate')}
              </p>
              <p className="modal-box-body-value-label">
                {menuOptions && menuOptions.pgto}
              </p>
              <p
                className="modal-box-body-header-label"
                style={{ marginTop: -10 }}
              >
                {t('OrderDetails.nf')}
              </p>
              <p className="modal-box-body-value-label">
                {menuOptions && menuOptions.all.Invoice.invoice_number}
              </p>
              <p
                className="modal-box-body-header-label"
                style={{ marginTop: -10 }}
              >
                {t('OrderDetails.productLabel')}
              </p>
              <p className="modal-box-body-value-label">
                {menuOptions && menuOptions.product}
              </p>
              <p
                className="modal-box-body-header-label"
                style={{ marginTop: -10 }}
              >
                {t('OrderDetails.orderBy')}
              </p>
              <p className="modal-box-body-value-label" />
            </div>
            <div className="modal-box-body">
              <p className="modal-box-body-header-label">
                {t('OrderDetails.rz')}
              </p>
              <p className="modal-box-body-value-label">
                {menuOptions && menuOptions.rs}
              </p>
              <p
                className="modal-box-body-header-label"
                style={{ marginTop: -10 }}
              >
                {t('OrderDetails.cnpj')}
              </p>
              <p className="modal-box-body-value-label">
                {menuOptions && menuOptions.all.Invoice.cnpj_cpf}
              </p>
              <p
                className="modal-box-body-header-label"
                style={{ marginTop: -10 }}
              >
                {t('OrderDetails.bearers')}
              </p>
              <p className="modal-box-body-value-label">XX</p>
              <p
                className="modal-box-body-header-label"
                style={{ marginTop: -10 }}
              >
                Cartões novos
              </p>
              <p className="modal-box-body-value-label">XX</p>
              <p
                className="modal-box-body-header-label"
                style={{ marginTop: -10 }}
              >
                Honorários
              </p>
              <p className="modal-box-body-value-label">
                XX <br /> XX
              </p>
            </div>
            <div className="modal-box-body">
              <p className="modal-box-body-header-label">Tarifa de postagem</p>
              <p className="modal-box-body-value-label">R$</p>
              <p
                className="modal-box-body-header-label"
                style={{ marginTop: -10 }}
              >
                Cargas
              </p>
              <p className="modal-box-body-value-label">R$</p>
              <p
                className="modal-box-body-header-label"
                style={{ marginTop: -10 }}
              >
                Tarifa inferior ao minimo
              </p>
              <p className="modal-box-body-value-label">R$</p>
              <p
                className="modal-box-body-header-label"
                style={{ marginTop: -10 }}
              >
                Preço cartão estoque
              </p>
              <p className="modal-box-body-value-label">R$</p>
              <p
                className="modal-box-body-header-label"
                style={{ marginTop: -10 }}
              >
                Preço cartão <br /> com vinculo
              </p>
              <p className="modal-box-body-value-label">R$</p>
            </div>
            <div className="modal-box-body" style={{ marginRight: 15 }}>
              <p className="modal-box-body-header-label">Status do pedido</p>
              <p className="modal-box-body-value-label">
                {menuOptions && menuOptions.status}
              </p>
              <p
                className="modal-box-body-header-label"
                style={{ marginTop: -10 }}
              >
                Total de créditos
              </p>
              <p className="modal-box-body-value-label">R$</p>
              <p
                className="modal-box-body-header-label"
                style={{ marginTop: -10 }}
              >
                Total NF
              </p>
              <p className="modal-box-body-value-label">R$</p>
              <p
                className="modal-box-body-header-label"
                style={{ marginTop: -10 }}
              >
                Total ND
              </p>
              <p className="modal-box-body-value-label">R$</p>
              <p
                className="modal-box-body-header-label"
                style={{ marginTop: -10 }}
              >
                Repasse total
              </p>
              <p className="modal-box-body-value-label">
                {menuOptions && menuOptions.vlr}
              </p>
            </div>
          </div>
          <div className="modal-notification">
            <p
              className="modal-box-body-header-label"
              style={{ marginLeft: 15, marginTop: 5 }}
            >
              Notificação
            </p>
          </div>
          <div
            className="col-sm-12"
            style={{
              marginTop: 30,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'end',
            }}
          >
            <Button
              style={{
                marginBottom: 20,
                marginRight: 20,
                height: 60,
                width: 250,
                fontSize: 24,
              }}
              onClick={() => history.push(routing.orderDetails(menuOptions.id))}
              label="Visualizar pedido"
            />
          </div>
        </div>
      </Dialog>
      <Dialog
        onClose={() => setOpen(!open)}
        open={open}
        fullWidth
        maxWidth="lg"
      >
        <Loading open={loadingDetails} msg="Buscando detalhes..." />
        <DialogTitle id="simple-dialog-title">Movimentação</DialogTitle>
        <Grid
          container
          spacing={1}
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            padding: 20,
          }}
        >
          <Grid item xs={12} sm={12}>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Código</StyledTableCell>
                    <StyledTableCell>Código do item</StyledTableCell>
                    <StyledTableCell>Código do Evento</StyledTableCell>
                    <StyledTableCell>Usuário</StyledTableCell>
                    <StyledTableCell>Data de Criação</StyledTableCell>
                    <StyledTableCell align="right">Status</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {movimentDetails.map(
                    (row, i) => (
                      // i + 1 === movimentDetails?.length && (
                      // eslint-disable-next-line react/no-array-index-key
                      <StyledTableRow key={i}>
                        <StyledTableCell>{row.id}</StyledTableCell>
                        <StyledTableCell>{row.order_item_id}</StyledTableCell>
                        <StyledTableCell>
                          {translateStatus(row.log_event_id)}
                        </StyledTableCell>
                        <StyledTableCell>{row.user}</StyledTableCell>
                        <StyledTableCell>
                          {moment
                            .utc(row.created_at)
                            .tz('America/Sao_Paulo')
                            .format('DD/MM/yyyy HH:mm')}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {Status(row.comments, t)}
                        </StyledTableCell>
                      </StyledTableRow>
                    ),
                    // ),
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <DialogActions>
          <Button
            onClick={closeDetails}
            type="submit"
            color="primary"
            variant="contained"
            autoFocus
            label="ok"
          >
            ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
