/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, TextField, Divider, Dialog } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import styles from '../../../styles/Layout.module.css';
import routing from '../../../service/routes/routing';
import MyButton from '../../../components/button/MyButton';

const useStyles = makeStyles(() =>
  createStyles({
    inputLabel: {
      backgroundColor: '#F2F1F1',
      // color: '#41b490',
      fontWeight: 'bold',
    },
    myTextField: {
      backgroundColor: '#none',
      boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
      border: 'none',
      // color: '#41b490',
      // fontWeight: 'bold',
      fontFamily: 'Open Sans',
    },
    text: {
      // backgroundColor: '#F2F1F1',
      fontFamily: 'Open Sans',
      // fontWeight: 'bold',
      // color: '#41b490',
    },
  }),
);

export default function SendEmail() {
  const history = useHistory();
  const classes = useStyles();
  const [text, setText] = useState(
    `Bem vindo a Element-B!${'\n'}Para dar continuidade nos Pedidos de Carga de valores em nosso sistema, pedimos que realize a assinatura digital do contrato de serviços, emitido por Janaina via D4Sign. ${'\n'}` +
      `Por favor, verifique a caixa de entrada ou de spam do seu e-mail, evitando a interrupção do serviço. ` +
      `${'\n'}Atenciosamente. Equipe Element`,
  );

  useEffect(() => {
    console.log(text);
  }, [text]);
  return (
    <div>
      <p
        className={styles.textMenu}
        onKeyDown={() => history.push(routing.salesDash)}
        onClick={() => history.push(routing.salesDash)}
      >
        Menu Inicial
      </p>
      <div
        className="col-sm-12"
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-end',
          justifyContent: 'space-between',
        }}
      >
        <h4 className={styles.textTitle}>E-MAIL LEMBRETE</h4>
        <h6
          onClick={() => history.push(routing.salesAllContracts)}
          style={{ cursor: 'pointer' }}
        >
          Voltar para o histórioco de contratos
        </h6>
      </div>
      <div
        className="col-sm-12"
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-end',
          justifyContent: 'flex-end',
          marginTop: '3rem',
        }}
      >
        <p className={styles.textMenu} style={{ marginRight: '2rem' }}>
          Número de lembrete. N: XXXX
        </p>
      </div>
      <div
        className="col-sm-12"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          border: '1px solid black',
          borderRadius: '8px',
          minHeight: '300px',
          padding: '2rem',
        }}
      >
        <p>E-mail destinatário</p>
        <TextField
          className={classes.text}
          fullWidth
          style={{ maxWidth: '70%' }}
          variant="outlined"
          size="small"
          placeholder="E-mail"
          InputProps={{
            classes: {
              notchedOutline: classes.myTextField,
              input: classes.text,
            },
          }}
          // value={dest.title}
          // onChange={e => setDest(e.target.value)}
        />
        <p style={{ marginTop: '1rem' }}>Texto</p>
        <TextField
          className={classes.text}
          fullWidth
          multiline
          rows={5}
          variant="outlined"
          size="small"
          InputProps={{
            classes: {
              notchedOutline: classes.myTextField,
              input: classes.text,
            },
          }}
          value={text}
          onChange={e => setText(e.target.value)}
        />
      </div>
      <div
        className="col-sm-12"
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: '2rem',
        }}
      >
        <div>
          <MyButton
            style={{
              width: 120,
              height: 40,
              borderRadius: 10,
              backgroundColor: '#657588',
            }}
            onClick={() => history.push(routing.salesAllContracts)}
            label="Voltar"
          />
        </div>
        <div style={{ display: 'flex' }}>
          <MyButton
            style={{
              width: 120,
              height: 40,
              borderRadius: 10,
              backgroundColor: '#e4e4e4',
              color: '#000',
              marginRight: 10,
            }}
            // onClick={handleBack}
            label="Limpar"
          />
          <MyButton
            style={{
              width: 120,
              height: 40,
              borderRadius: 10,
              // backgroundColor: '#657588',
            }}
            // onClick={handleBack}
            label="Enviar"
          />
        </div>
      </div>
    </div>
  );
}
