/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-self-compare */
import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import {
  createStyles,
  Button,
  Typography,
  Grid,
  makeStyles,
  Menu,
  Modal,
  MenuItem,
  ListItemIcon,
  // Badge,
} from '@material-ui/core';

import { FaSignature, FaEdit, FaDownload, FaLink } from 'react-icons/fa';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Link from '@material-ui/core/Link';
import api from '../../../service/axios/api';
import SelectCommercialAgent from '../components/SelectCommercialAgent/SelectCommercialAgent';
// import SpinnerPage from '../components/SpinnerPage';
import CampaingsTable from '../components/tables/CampaingsTable';

import ProposalModal from '../components/modal/ProposalModal';

import useContextMenu from '../hooks/useContextMenu/useContextMenu';
import useClickPreventionOnDoubleClick from '../hooks/useClickPreventionOnDoubleClick/useClickPreventionOnDoubleClick';
import ProposalRequestModal from '../components/modal/ProposalRequestModal';

export const useStyles = makeStyles(theme =>
  createStyles({
    button: {
      marginLeft: theme.spacing(3),
    },
  }),
);
function Proposals() {
  const classes = useStyles();
  // const query = firestore
  //   .collection('proposals')
  //   .orderBy('proposalNumber', 'desc');

  // const queryProposalRequest = firestore.collection('proposalRequests');
  // const [proposalRequests, loadingProposalRequests] =
  //   useCollectionData < ProposalRequest > queryProposalRequest;

  // const count = Object.keys(proposalRequests || []).length;
  // const count = Object.keys([]).length;

  // const [proposals, loading] = useCollectionData < Proposal > query;
  const [campaings, setCampaings] = useState([]);
  const { state, onOpen, onClose } = useContextMenu();
  const [selected, setSelected] = useState();
  const [modalOpened, setModalOpened] = useState(null > null);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const cancel = new AbortController();
    api.campaign
      .get(cancel, page, size)
      .then(campaign => {
        const list = [];
        campaign.docs.forEach(d => {
          list.push({
            id: d.id,
            name: d.name,
            expires: d.expires_in,
            comments: d.comments,
            createdAt: d.created_at,
          });
        });
        setCampaings(list);
        setTotal(campaign.total);
      })
      .catch(error => {
        console.log(error);
      });
  }, [page, size]);

  const onClick = params => {
    const campaing = campaings?.find(p => p.id === params.row.id);

    console.log('Single click', campaing);
    // if (campaing) {
    //   setModalOpened('review-proposal');
    //   setSelected(campaing);
    // }
  };

  const generateLink = () => {
    // window.open(`https://www.w3schools.com/${selected?.id}`);
    window.open(
      `https://control-v2-dev.web.app/sales/proposals/public/${selected?.id}`,
    );
    onClose();
  };

  const onDoubleClick = (params, e) => {
    const campaing = campaings?.find(pp => pp.id === params.row.id);
    if (campaing) {
      setSelected(campaing);
      onOpen(e);
    }
  };

  const [click, doubleClick] = useClickPreventionOnDoubleClick(
    onClick,
    onDoubleClick,
  );

  function closeModal() {
    setSelected(null);
    setModalOpened(null);
  }

  function openAgentModal() {
    setModalOpened('change-agent');
  }

  // function openProposalRequestModal() {
  //   setModalOpened('proposal-request');
  // }

  // if (loading || loadingProposalRequests) {
  //   return <SpinnerPage />;
  // }
  const isProposalSelected = !!selected;
  const showProposalModal =
    modalOpened === 'review-proposal' && isProposalSelected;
  const showAgentModal = modalOpened === 'change-agent' && isProposalSelected;
  const showProposalRequestModal = modalOpened === 'proposal-request';

  return (
    <>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Typography variant="h4" component="h2" gutterBottom>
          Campanha
        </Typography>

        <Box display="flex" justifyContent="space-between">
          {/* <Button
            variant="text"
            color="primary"
            onClick={openProposalRequestModal}
          >
            <Badge badgeContent={count} color="primary">
              Lead
            </Badge>
          </Button> */}
          <Link href="/sales/campaing/create">
            <Button
              variant="text"
              color="primary"
              endIcon={<AddCircleIcon />}
              className={classes.button}
            >
              Nova campanha
            </Button>
          </Link>
        </Box>
      </Grid>
      <CampaingsTable
        onRowClick={click}
        onRowDoubleClick={doubleClick}
        campaings={campaings || []}
        rowsPerPageOptions={[10, 20, 50]}
        pageSize={size}
        onPageSizeChange={newPageSize => setSize(newPageSize)}
        page={page - 1}
        onPageChange={newPage => setPage(newPage)}
        rowCount={total}
      />

      {selected && (
        <Menu
          keepMounted
          open={state.mouseY !== null}
          onClose={onClose}
          anchorReference="anchorPosition"
          anchorPosition={
            state.mouseY !== null && state.mouseX !== null
              ? { top: state.mouseY, left: state.mouseX }
              : undefined
          }
        >
          <Link href={`/sales/campaign/${selected?.id}/edit`}>
            <MenuItem onClick={onClose}>
              <ListItemIcon>
                <FaEdit />
              </ListItemIcon>
              <Typography>Editar</Typography>
            </MenuItem>
          </Link>
          {/* <Link href={`/sales/campaign/${selected?.id}/edit`}> */}
          <MenuItem onClick={generateLink}>
            <ListItemIcon>
              <FaLink />
            </ListItemIcon>
            <Typography>Gerar link</Typography>
          </MenuItem>
          {/* </Link> */}

          {/* <Link href={`/sales/contracts/create/${selected?.id}`}>
            <MenuItem onClick={onClose}>
              <ListItemIcon>
                <FaSignature />
              </ListItemIcon>
              <Typography>Contratar</Typography>
            </MenuItem>
          </Link>
          <MenuItem
            onClick={onClose}
            component="a"
            download
            href={`/api/campaings/${selected.id}/download`}
          >
            <ListItemIcon>
              <FaDownload />
            </ListItemIcon>
            <Typography>Download</Typography>
          </MenuItem>

          <MenuItem onClick={openAgentModal}>
            <ListItemIcon>
              <FaLink />
            </ListItemIcon>
            <Typography>Redirecionar</Typography>
          </MenuItem> */}
        </Menu>
      )}
      {/* {selected && (
        <ProposalModal
          open={showProposalModal}
          onClose={closeModal}
          proposal={selected}
        />
      )} */}
      {/* {selected && (
        <Modal open={showAgentModal} onClose={closeModal}>
          <SelectCommercialAgent proposal={selected} />
        </Modal>
      )} */}
      <ProposalRequestModal
        open={showProposalRequestModal}
        close={closeModal}
      />
    </>
  );
}

export default Proposals;
