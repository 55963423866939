/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Grid, Dialog } from '@material-ui/core';
// import { makeStyles, createStyles } from '@mui/styles';
import routing from '../../service/routes/routing';
import MyButton from '../../components/button/MyButton';
import './NewProduct.css';
// import Category from './category/Category';
import Category from './acessory/NewCategory';

// const useStyles = makeStyles((theme) => createStyles({
//   myButton: {
//     color: '#2a3176',
//     backgroundColor: '#f4f4f4',
//     fontWeight: 'bold',
//     // minWidth: 200,
//   },
//   myGrid: {
//     minWidth: 320,
//     maxWidth: 320,
//     minHeight: 170,
//     backgroundColor: '#f4f4f4',
//     margin: theme.spacing(1),
//     alignItems: 'center',
//     justifyContent: 'center',
//     display: 'flex',
//     textAlign: 'center',
//     cursor: 'pointer',
//     border: '2px solid #c5c5c5',
//     borderRadius: 10,
//   },
//   myLabel: {
//     padding: theme.spacing(4),
//     color: '#2a3176',
//     fontWeight: 'bold',
//   },
// }));

const NewProduct = function () {
  const { t } = useTranslation();
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);
  // const [selected, setSelected] = useState();
  // const classes = useStyles();
  return (
    <>
      <div className="col-sm-12" style={{ marginTop: 50 }}>
        {/* <p className="textMenu"
         onKeyDown={() => history.push(routing.dashboard)}
         onClick={() => history.push(routing.dashboard)}>Menu Inicial</p> */}
        <div
          className="col-sm-12"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: 50,
          }}
        >
          <h5 className="newProductTitle">{t('Product.category.label1')}</h5>
          <MyButton
            style={{ marginRight: 50 }}
            label={t('Product.acessory')}
            onClick={() => history.push(routing.myacessory)}
          />
        </div>
        <Grid
          container
          spacing={1}
          // style={{
          //   justifyContent: 'center', paddingRight: 40, paddingLeft: 40, paddingTop: 40,
          // }}
          className="rootGrid "
        >
          <Grid
            className="pmyGrid"
            style={{ marginRight: 10 }}
            item
            xs={12}
            sm={8}
            md={3}
            lg={3}
            xl={2}
            onClick={() => history.push(routing.typeProduct('prepaidCard'))}
          >
            <p className="pmyLabel">{t('Product.category.card')}</p>
          </Grid>
          {/* <Grid
            className="pmyGrid"
            style={{ marginRight: 10 }}
            item
            xs={12}
            sm={8}
            md={3}
            lg={3}
            xl={2}
            onClick={() => history.push(routing.typeProduct('digitalAccount'))}
          >
            <p className="pmyLabel">{t('Product.category.account')}</p>
          </Grid> */}
          <Grid
            className="pmyGrid"
            item
            xs={12}
            sm={8}
            md={3}
            lg={3}
            xl={2}
            onClick={() => history.push(routing.typeProduct('transfer'))}
          >
            <p className="pmyLabel">{t('Product.category.transfer')}</p>
          </Grid>
        </Grid>
        {/* <Grid
          container
          spacing={1}
          // style={{
          //   justifyContent: 'center', paddingRight: 40, paddingLeft: 40, paddingTop: 20,
          // }}
          className="rootGrid "
        >
          <Grid
            className="pmyGrid"
            style={{ marginRight: 10 }}
            item
            xs={12}
            sm={8}
            md={3}
            lg={3}
            xl={2}
          >
            <p className="pmyLabel">{t('Product.category.qr')}</p>
          </Grid>
          <Grid
            className="pmyGrid"
            style={{ marginRight: 10 }}
            item
            xs={12}
            sm={8}
            md={3}
            lg={3}
            xl={2}
          >
            <p className="pmyLabel">{t('Product.category.cripto')}</p>
          </Grid>
          <Grid
            className="pmyGrid"
            item
            xs={12}
            sm={8}
            md={3}
            lg={3}
            xl={2}
            onClick={() => setOpenModal(true)}
          >
            <p className="pmyLabel">{t('Product.category.plus')}</p>
          </Grid>
        </Grid> */}
        <div style={{ marginTop: '2%' }}>
          <h6
            className="text-new-products-all"
            onClick={() => history.push(routing.product)}
          >
            {t('Product.accessAll')}
          </h6>
        </div>
      </div>

      <Dialog
        onClose={() => setOpenModal(false)}
        open={openModal}
        fullWidth
        maxWidth="lg"
      >
        <Category setOpenModal={setOpenModal} />
      </Dialog>
    </>
  );
};

export default NewProduct;
