import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    dayWrapper: {
      position: 'relative',
    },
    day: {
      width: 36,
      height: 36,
      // fontSize: theme.typography.caption.fontSize,
      fontSize: '0.55rem',
      margin: '0 2px',
      color: 'inherit',
    },
    customDayHighlight: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: '2px',
      right: '2px',
      // border: `1px solid ${theme.palette.secondary.main}`,
      border: `1px solid #4285f4`,
      borderRadius: '50%',
    },
    nonCurrentMonthDay: {
      // color: theme.palette.text.disabled,
      color: '#c1c1c1',
    },
    highlightNonCurrentMonthDay: {
      color: '#676767',
    },
    highlight: {
      // background: theme.palette.primary.main,
      // color: theme.palette.common.white,
      background: '#2898FF',
      color: 'white',
    },
    firstHighlight: {
      extend: 'highlight',
      borderTopLeftRadius: '50%',
      borderBottomLeftRadius: '50%',
    },
    endHighlight: {
      extend: 'highlight',
      borderTopRightRadius: '50%',
      borderBottomRightRadius: '50%',
    },
  }),
);

export default useStyles;
