/* eslint-disable jsx-a11y/aria-role */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import routing from './routing';

import Login from '../../pages/login/Login';
import Dashboard from '../../pages/dashboard/DashBoard';
import DashboardGM from '../../pages/dashboard/DashBoardGm';
import ContractElement from '../../pages/fidelity/ContractElement';
import ForgotPassword from '../../pages/forgotPassword/ForgotPassword';
import OrderMain from '../../pages/orders/OrderMain';
import Report from '../../pages/reports/ReportMain';
import DetailsOrder from '../../pages/orders/DetailsOrder';
import AcessoFile from '../../pages/AcessoFiles/AcessoFile';
import Process from '../../pages/Process/FilaAcesso';
import Extract from '../../pages/Extract/Extract';
// import Product from '../../pages/product/Product';
import ProductType from '../../pages/product/type/ProductType';
// import CategoryType from '../../pages/product/category/CategoryType';
import NewProduct from '../../pages/product/NewProduct';
import MyAcessory from '../../pages/product/acessory/MyAcessory';
import NewCreditCard from '../../pages/product/type/NewCreditCard';
import NewAccount from '../../pages/product/type/NewAccount';
import NewTransfer from '../../pages/product/type/NewTransfer';
import Notifications from '../../pages/notifications/Notifications';
import NewNotification from '../../pages/notifications/new/NewNotification';
import NewEmailNotification from '../../pages/notifications/new/NewEmailNotification';
import NewEmailTextNotification from '../../pages/notifications/new/EmailTextNotification';
import NewClientsGroup from '../../pages/notifications/groups/NewGroupClients';

// import SavedsNotifications from '../../pages/notifications/saved/Saveds';
import DashboardLayout from '../../layouts/DashboardLayout';
import DashboardAdminLayout from '../../layouts/AdminDashboard/DashBoardAdminLayout';
import LayoutSales from '../../pages/sales/Layout';
import LayoutPublic from '../../pages/sales/LayoutPublic';
import Tutorials from '../../pages/tutorials/tutorials';

// SALES
import DashboardSales from '../../pages/sales/dashboard/DashBoard';
import OpportunitySales from '../../pages/sales/opportunity/Opportunity';
import Extracts from '../../pages/sales/extract/extracts';
import Business from '../../pages/sales/business/business';
import OpportunitySalesNew from '../../pages/sales/opportunity/create';
import PublicOpportunity from '../../pages/sales/opportunity/createPublic';
import LeadToOpportunity from '../../pages/sales/opportunity/create/proposalRequestId';
import CampaingSales from '../../pages/sales/campaing/Campaing';
import CampaignSalesNew from '../../pages/sales/campaing/create';
import CampaignSalesEdit from '../../pages/sales/campaing/campaignId/edit';
import Contracts from '../../pages/sales/contracts/index';
import ContractsNew from '../../pages/sales/contracts/create';
import ContractEdit from '../../pages/sales/contracts/contractId/edit';
import ContractDuplicate from '../../pages/sales/contracts/contractId/duplicate';
import ViewerContract from '../../pages/sales/contracts/viwer/ViewerContract';

// AREA ADMIN Janaina
import DashAdmin from '../../pages/DashBoardAdmin/DashAdmin';
import OrdersAdmin from '../../pages/OrdersAdmin/OrdersAdmin';
import CardsAdmin from '../../pages/CardsAdmin/CardsAdmin';
import AllCardsAdmin from '../../pages/CardsAdminNoFilter/CardsAdmin';
import RpsAdmin from '../../pages/rpss/Rps';
import MovimentAdmin from '../../pages/moviment/Moviment';
import ReleaseMoney from '../../pages/releaseMoney/ReleaseMoney';
import AllContracts from '../../pages/contractGeneric/Contract';
import SalesContract from '../../pages/sales/contractGeneric/Contract';
import SalesContractRemember from '../../pages/sales/contractGeneric/SendEmail';
import SalesSendInvoice from '../../pages/sales/extract/UploadInvoice';
import SalesBankAccount from '../../pages/sales/bank/BankAccount';
import ContractAgents from '../../pages/contractAgents/Contract';
import BaseCards from '../../pages/basecards/BaseCards';
import Contract from '../../pages/contract/Contract';

import { SalesLogin } from '../../pages/sales/login/SalesLogin';

import PrivateRoute from './PrivateRoute';
import Users from '../../pages/users/Users';
import UserDetails from '../../pages/users/UserDetails';
import UserProfile from '../../pages/users/UserProfile';
// eslint-disable-next-line no-unused-vars
import Banks from '../../pages/Banks/Banks';
import CustomizedCards from '../../pages/CustomizedCards/CustomizedCards';
import { Painel } from '../../pages/Painel/Painel';
import { PainelClient } from '../../pages/Painel/PainelClient';
import Permissions from '../../pages/permissions/Permissions';

// fidelity
import Extrac from '../../pages/fidelity/Extract';
import GmUsers from '../../pages/GmUsers/GmUsers';
import PaymentAgents from '../../pages/PaymentAgents/PaymentAgents';
import AddCompanie from '../../pages/sales/contracts/viwer/AddCompanie';
import { Dcp } from '../../pages/dcp/Dcp';
import { AdminDcp } from '../../pages/adminDcp/AdminDcp';
import { SettingsControl } from '../../pages/SettingsControl/SettingsControl';
import { CompanyBase } from '../../pages/companyBase/CompanyBase';
import { CnabFile } from '../../pages/CnabFiles/CnabFile';
import { Bankly } from '../../pages/bankly/Bankly';

// const LANGUAGES = {
//   pt: {
//     urlLang: 'pt',
//     code: 'pt-BR',
//   },
//   en: {
//     urlLang: 'en',
//     code: 'en-US',
//   },
// };
// function MultiLanguageRoute(props) {
//   const defaultLanguage = LANGUAGES.pt.urlLang;
//   const hasLang = props.computedMatch.params.lang;
//   const is404Page = props.path;
//   const isBasePathWithoutLang = props.path === '/';
//   console.log('Passando aqui', isBasePathWithoutLang);
//   if (isBasePathWithoutLang) return <Redirect to={`/${defaultLanguage}`} />;
//   if (!hasLang && !is404Page) return <Redirect to={`/${defaultLanguage}`} />;

//   return <Route {...props} />;
// }

const Routes = function () {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleDrawerOpen = e => {
    if (e === '/orders') {
      setOpen(!open);
    }
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  return (
    <Router>
      <Switch>
        <Route exact path={routing.login} component={Login} />
        <Route exact path={routing.dcp} component={Dcp} />
        <Route exact path={routing.forgotPassword} component={ForgotPassword} />
        <Route exact path={routing.salesLogin} component={SalesLogin} />
        <PrivateRoute
          exact
          role="Administrador,Cliente,Operacional Nível 1"
          path={routing.salesDash}
          render={props => (
            <LayoutSales {...props}>
              <DashboardSales />
            </LayoutSales>
          )}
        />
        <PrivateRoute
          exact
          role="Administrador,Cliente,Operacional Nível 1"
          path={routing.admindcps}
          render={props => (
            <DashboardAdminLayout
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose}
              open={open}
              setOpen={setOpen}
              {...props}
            >
              <AdminDcp />
            </DashboardAdminLayout>
          )}
        />
        <PrivateRoute
          exact
          role="Administrador,Cliente,Operacional Nível 1"
          path={routing.salesOpportunity}
          render={props => (
            <LayoutSales {...props}>
              <OpportunitySales />
            </LayoutSales>
          )}
        />
        <PrivateRoute
          exact
          role="Administrador,Cliente,Operacional Nível 1"
          path={routing.salesExtracts}
          render={props => (
            <LayoutSales {...props}>
              <Extracts />
            </LayoutSales>
          )}
        />
        <PrivateRoute
          exact
          role="Administrador,Cliente,Operacional Nível 1"
          path={routing.salesAllContracts}
          render={props => (
            <LayoutSales {...props}>
              <SalesContract />
            </LayoutSales>
          )}
        />
        <PrivateRoute
          exact
          role="Administrador,Cliente,Operacional Nível 1"
          path={routing.salesRemember}
          render={props => (
            <LayoutSales {...props}>
              <SalesContractRemember />
            </LayoutSales>
          )}
        />
        <PrivateRoute
          exact
          role="Administrador,Cliente,Operacional Nível 1"
          path={routing.salesSendInvoice}
          render={props => (
            <LayoutSales {...props}>
              <SalesSendInvoice />
            </LayoutSales>
          )}
        />
        <PrivateRoute
          exact
          role="Administrador,Cliente,Operacional Nível 1"
          path={routing.salesBankAccount}
          render={props => (
            <LayoutSales {...props}>
              <SalesBankAccount />
            </LayoutSales>
          )}
        />
        <PrivateRoute
          exact
          role="Administrador,Cliente,Operacional Nível 1"
          path={routing.salesBusiness}
          render={props => (
            <LayoutSales {...props}>
              <Business />
            </LayoutSales>
          )}
        />
        <PrivateRoute
          exact
          role="Administrador,Cliente,Operacional Nível 1"
          path={routing.salesOpportunityNew}
          render={props => (
            <LayoutSales {...props}>
              <OpportunitySalesNew />
            </LayoutSales>
          )}
        />
        <PrivateRoute
          exact
          role="Administrador,Cliente,Operacional Nível 1"
          path={routing.salesCampaign}
          render={props => (
            <LayoutSales {...props}>
              <CampaingSales />
            </LayoutSales>
          )}
        />
        <PrivateRoute
          exact
          role="Administrador,Cliente,Operacional Nível 1"
          path={routing.salesCampaignNew}
          render={props => (
            <LayoutSales {...props}>
              <CampaignSalesNew />
            </LayoutSales>
          )}
        />
        <PrivateRoute
          exact
          role="Administrador,Operacional Nível 1"
          path={routing.salesCampaignEdit(':id')}
          render={props => (
            <LayoutSales {...props}>
              <CampaignSalesEdit />
            </LayoutSales>
          )}
        />
        <PrivateRoute
          exact
          role="Administrador,Operacional Nível 1"
          path={routing.salesLeadToOpportunity(':id')}
          render={props => (
            <LayoutSales {...props}>
              <LeadToOpportunity />
            </LayoutSales>
          )}
        />
        <PrivateRoute
          exact
          role="Administrador,Cliente,Operacional Nível 1"
          path={routing.salesContract}
          render={props => (
            <LayoutSales {...props}>
              <Contracts />
            </LayoutSales>
          )}
        />
        <PrivateRoute
          exact
          role="Administrador,Cliente,Operacional Nível 1"
          path={routing.newContract}
          render={props => (
            <DashboardLayout
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose}
              open={open}
              setOpen={setOpen}
              title="Contrato"
              {...props}
            >
              <ContractsNew style={{ marginTop: 15 }} />
            </DashboardLayout>
          )}
        />
        <PrivateRoute
          exact
          role="Administrador,Cliente,Operacional Nível 1"
          path={routing.ContractEdit(':id')}
          render={props => (
            <DashboardLayout
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose}
              open={open}
              setOpen={setOpen}
              title="Contrato"
              {...props}
            >
              <ContractEdit />
            </DashboardLayout>
          )}
        />
        <PrivateRoute
          exact
          role="Administrador,Cliente,Operacional Nível 1"
          path={routing.ContractAddCompany(':id')}
          render={props => (
            <DashboardLayout
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose}
              open={open}
              setOpen={setOpen}
              title="Contrato"
              {...props}
            >
              <AddCompanie />
            </DashboardLayout>
          )}
        />
        <PrivateRoute
          exact
          role="Administrador,Cliente,Operacional Nível 1"
          path={routing.ContractRemoveCompany(':id')}
          render={props => (
            <DashboardLayout
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose}
              open={open}
              setOpen={setOpen}
              title="Contrato"
              {...props}
            >
              <ContractEdit />
            </DashboardLayout>
          )}
        />
        <PrivateRoute
          exact
          role="Administrador,Operacional Nível 1"
          path={routing.contractDuplicate(':id')}
          render={props => (
            <DashboardLayout
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose}
              open={open}
              setOpen={setOpen}
              title="Contrato"
              {...props}
            >
              <ContractDuplicate />
            </DashboardLayout>
          )}
        />
        <PrivateRoute
          exact
          role="Administrador,Operacional Nível 1"
          path={routing.contractViewer(':id')}
          render={props => (
            <DashboardLayout
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose}
              open={open}
              setOpen={setOpen}
              title="Contrato"
              {...props}
            >
              <ViewerContract />
            </DashboardLayout>
          )}
        />
        <PrivateRoute
          exact
          role="Administrador,Cliente,Operacional Nível 1"
          path={routing.tutorial}
          render={props => (
            <DashboardLayout
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose}
              open={open}
              setOpen={setOpen}
              title="Tutoriais"
              {...props}
            >
              <Tutorials />
            </DashboardLayout>
          )}
        />
        <Route
          exact
          path={routing.salesPublicLead(':campaing')}
          render={props => (
            <LayoutPublic {...props}>
              <PublicOpportunity />
            </LayoutPublic>
          )}
        />
        <PrivateRoute
          exact
          role="Administrador,Operacional Nível 1"
          path={routing.administration}
          render={props => (
            <DashboardAdminLayout
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose}
              open={open}
              setOpen={setOpen}
              {...props}
            >
              {/* <Dashboard {...props} /> */}
              <DashAdmin {...props} />
            </DashboardAdminLayout>
          )}
        />
        <PrivateRoute
          exact
          role="Administrador,Operacional Nível 1"
          path={routing.ordersAdmin}
          render={props => (
            <DashboardAdminLayout
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose}
              open={open}
              setOpen={setOpen}
              {...props}
            >
              {/* <Dashboard {...props} /> */}
              <OrdersAdmin {...props} />
            </DashboardAdminLayout>
          )}
        />
        <PrivateRoute
          exact
          role="Administrador,Operacional Nível 1"
          path={routing.cardsAdmin}
          render={props => (
            <DashboardAdminLayout
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose}
              open={open}
              setOpen={setOpen}
              {...props}
            >
              {/* <Dashboard {...props} /> */}
              <CardsAdmin {...props} />
            </DashboardAdminLayout>
          )}
        />
        <PrivateRoute
          exact
          role="Administrador,Operacional Nível 1"
          path={routing.settings}
          render={props => (
            <DashboardAdminLayout
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose}
              open={open}
              setOpen={setOpen}
              {...props}
            >
              {/* <Dashboard {...props} /> */}
              <SettingsControl {...props} />
            </DashboardAdminLayout>
          )}
        />
        <PrivateRoute
          exact
          role="Administrador,Operacional Nível 1"
          path={routing.companybase}
          render={props => (
            <DashboardAdminLayout
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose}
              open={open}
              setOpen={setOpen}
              {...props}
            >
              {/* <Dashboard {...props} /> */}
              <CompanyBase {...props} />
            </DashboardAdminLayout>
          )}
        />
        <PrivateRoute
          exact
          role="Administrador,Operacional Nível 1"
          path={routing.allCardsAdmin}
          render={props => (
            <DashboardAdminLayout
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose}
              open={open}
              setOpen={setOpen}
              {...props}
            >
              {/* <Dashboard {...props} /> */}
              <AllCardsAdmin {...props} />
            </DashboardAdminLayout>
          )}
        />
        <PrivateRoute
          exact
          role="Administrador,Operacional Nível 1"
          path={routing.rps}
          render={props => (
            <DashboardAdminLayout
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose}
              open={open}
              setOpen={setOpen}
              {...props}
            >
              {/* <Dashboard {...props} /> */}
              <RpsAdmin {...props} />
            </DashboardAdminLayout>
          )}
        />
        <PrivateRoute
          exact
          role="Administrador,Operacional Nível 1"
          path={routing.bankly}
          render={props => (
            <DashboardAdminLayout
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose}
              open={open}
              setOpen={setOpen}
              {...props}
            >
              {/* <Dashboard {...props} /> */}
              <Bankly {...props} />
            </DashboardAdminLayout>
          )}
        />
        <PrivateRoute
          exact
          role="Administrador,Operacional Nível 1"
          path={routing.filesAcesso}
          render={props => (
            <DashboardAdminLayout
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose}
              open={open}
              setOpen={setOpen}
              {...props}
            >
              <AcessoFile {...props} />
            </DashboardAdminLayout>
          )}
        />
        <PrivateRoute
          exact
          role="Administrador,Operacional Nível 1"
          path={routing.filesCnab}
          render={props => (
            <DashboardAdminLayout
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose}
              open={open}
              setOpen={setOpen}
              {...props}
            >
              <CnabFile {...props} />
            </DashboardAdminLayout>
          )}
        />
        <PrivateRoute
          exact
          role="Administrador,Operacional Nível 1"
          path={routing.process}
          render={props => (
            <DashboardAdminLayout
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose}
              open={open}
              setOpen={setOpen}
              {...props}
            >
              {/* <Dashboard {...props} /> */}
              <Process {...props} />
            </DashboardAdminLayout>
          )}
        />
        <PrivateRoute
          exact
          role="Administrador,Operacional Nível 1"
          path={routing.extract}
          render={props => (
            <DashboardAdminLayout
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose}
              open={open}
              setOpen={setOpen}
              {...props}
            >
              {/* <Dashboard {...props} /> */}
              <Extract {...props} />
            </DashboardAdminLayout>
          )}
        />
        <PrivateRoute
          exact
          role="Administrador,Operacional Nível 1"
          path={routing.banks}
          render={props => (
            <DashboardAdminLayout
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose}
              open={open}
              setOpen={setOpen}
              {...props}
            >
              <Banks {...props} />
            </DashboardAdminLayout>
          )}
        />
        <PrivateRoute
          exact
          role="Administrador,Operacional Nível 1"
          path={routing.agentPayment}
          render={props => (
            <DashboardAdminLayout
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose}
              open={open}
              setOpen={setOpen}
              {...props}
            >
              <PaymentAgents {...props} />
            </DashboardAdminLayout>
          )}
        />
        <PrivateRoute
          exact
          role="Administrador,Operacional Nível 1"
          path={routing.permissions}
          render={props => (
            <DashboardAdminLayout
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose}
              open={open}
              setOpen={setOpen}
              {...props}
            >
              <Permissions {...props} />
            </DashboardAdminLayout>
          )}
        />
        <PrivateRoute
          exact
          role="Administrador,Operacional Nível 1"
          path={routing.contracts}
          render={props => (
            <DashboardAdminLayout
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose}
              open={open}
              setOpen={setOpen}
              {...props}
            >
              {/* <Dashboard {...props} /> */}
              <Contract {...props} />
            </DashboardAdminLayout>
          )}
        />
        <PrivateRoute
          exact
          role="Administrador,Operacional Nível 1"
          path={routing.agentContract}
          render={props => (
            <DashboardAdminLayout
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose}
              open={open}
              setOpen={setOpen}
              {...props}
            >
              <ContractAgents {...props} />
            </DashboardAdminLayout>
          )}
        />
        <PrivateRoute
          exact
          role="Administrador,Cliente,Operacional Nível 1"
          path={routing.releaseMoney}
          render={props => (
            <DashboardLayout
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose}
              open={open}
              setOpen={setOpen}
              title={t('releaseMoney.label')}
              {...props}
            >
              <ReleaseMoney {...props} />
            </DashboardLayout>
          )}
        />
        <PrivateRoute
          exact
          role="Administrador,Cliente,Operacional Nível 1"
          path={routing.allContracts}
          render={props => (
            <DashboardLayout
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose}
              open={open}
              setOpen={setOpen}
              title={t('contract.label')}
              {...props}
            >
              <AllContracts {...props} />
            </DashboardLayout>
          )}
        />
        <PrivateRoute
          exact
          role="Administrador,Cliente,Operacional Nível 1"
          path={routing.moviment}
          render={props => (
            <DashboardLayout
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose}
              open={open}
              setOpen={setOpen}
              {...props}
            >
              <MovimentAdmin {...props} />
            </DashboardLayout>
          )}
        />
        <PrivateRoute
          exact
          role="Administrador,Cliente,Operacional Nível 1"
          path={routing.baseCards}
          render={props => (
            <DashboardLayout
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose}
              open={open}
              setOpen={setOpen}
              title={t('baseCards.label')}
              {...props}
            >
              <BaseCards {...props} />
            </DashboardLayout>
            // <DashboardAdminLayout {...props}>
            //   {/* <Dashboard {...props} /> */}
            //   <BaseCards {...props} />
            // </DashboardAdminLayout>
          )}
        />
        <PrivateRoute
          exact
          role="Administrador,Cliente,GerenteGM,GerenteLoja"
          path={routing.fidelityextract}
          render={props => (
            <DashboardLayout
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose}
              open={open}
              setOpen={setOpen}
              {...props}
            >
              <Extrac {...props} />
            </DashboardLayout>
          )}
        />
        <PrivateRoute
          exact
          role="Administrador,Cliente,GerenteGM,GerenteLoja"
          path={routing.fidelityusers}
          render={props => (
            <DashboardLayout
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose}
              open={open}
              setOpen={setOpen}
              {...props}
            >
              <GmUsers {...props} />
            </DashboardLayout>
          )}
        />
        <PrivateRoute
          exact
          role="Administrador,Cliente,Operacional Nível 1"
          path={routing.dashboard}
          render={props => (
            <DashboardLayout
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose}
              open={open}
              setOpen={setOpen}
              {...props}
            >
              {/* <Dashboard {...props} /> */}
              <Dashboard {...props} />
            </DashboardLayout>
          )}
        />
        <PrivateRoute
          exact
          role="Administrador,Cliente,GerenteGM,GerenteLoja"
          path={routing.fidelitydashboard}
          render={props => (
            <DashboardLayout
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose}
              open={open}
              setOpen={setOpen}
              {...props}
            >
              {/* <Dashboard {...props} /> */}
              <DashboardGM {...props} />
            </DashboardLayout>
          )}
        />
        <PrivateRoute
          exact
          role="Administrador,Cliente,GerenteGM,GerenteLoja"
          path={routing.fidelitycontract}
          render={props => (
            <DashboardLayout
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose}
              open={open}
              setOpen={setOpen}
              {...props}
            >
              {/* <Dashboard {...props} /> */}
              <ContractElement {...props} />
            </DashboardLayout>
          )}
        />
        <PrivateRoute
          exact
          role="Administrador,Cliente,Operacional Nível 1"
          path={routing.painel}
          render={props => (
            <DashboardLayout
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose}
              open={open}
              setOpen={setOpen}
              {...props}
            >
              <Painel {...props} />
            </DashboardLayout>
          )}
        />
        <PrivateRoute
          exact
          role="Administrador,Cliente,Operacional Nível 1"
          path={routing.reports}
          render={props => (
            <DashboardLayout
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose}
              open={open}
              setOpen={setOpen}
              {...props}
            >
              <Report {...props} />
            </DashboardLayout>
          )}
        />
        <PrivateRoute
          exact
          role="Administrador,Cliente,Operacional Nível 1"
          path={routing.painelc}
          render={props => (
            <DashboardLayout
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose}
              open={open}
              setOpen={setOpen}
              {...props}
            >
              <PainelClient {...props} />
            </DashboardLayout>
          )}
        />
        <PrivateRoute
          exact
          role="Administrador,Cliente,Operacional Nível 1"
          path={routing.orders}
          render={props => (
            <DashboardLayout
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose}
              open={open}
              setOpen={setOpen}
              setOpenModal={setOpenModal}
              title={t('Order.order')}
              {...props}
            >
              <OrderMain
                handleDrawerOpen={handleDrawerOpen}
                handleDrawerClose={handleDrawerClose}
                openModal={openModal}
                setOpenModal={setOpenModal}
                {...props}
              />
            </DashboardLayout>
          )}
        />
        {/* <PrivateRoute
          exact
          role="Administrador,Cliente"
          path={routing.product}
          render={props => (
            <DashboardAdminLayout
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose}
              open={open}
              setOpen={setOpen}
              title={t('Product.product')}
              {...props}
            >
              <Product {...props} />
            </DashboardAdminLayout>
          )}
        /> */}
        <PrivateRoute
          exact
          role="Administrador,Operacional Nível 1"
          path={routing.newProduct}
          render={props => (
            <DashboardAdminLayout
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose}
              open={open}
              setOpen={setOpen}
              title={t('Product.product')}
              {...props}
            >
              <NewProduct {...props} />
            </DashboardAdminLayout>
          )}
        />
        <PrivateRoute
          exact
          role="Administrador,Operacional Nível 1"
          path={routing.typeProduct(':product_type')}
          render={props => (
            <DashboardAdminLayout
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose}
              open={open}
              setOpen={setOpen}
              title={t('Product.product')}
              {...props}
            >
              <ProductType {...props} />
            </DashboardAdminLayout>
          )}
        />
        <PrivateRoute
          exact
          role="Administrador,Operacional Nível 1"
          path={routing.typeCategory(':category_type')}
          render={props => (
            <DashboardAdminLayout
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose}
              open={open}
              setOpen={setOpen}
              title={t('Product.product')}
              {...props}
            >
              <CategoryType {...props} />
            </DashboardAdminLayout>
          )}
        />
        <PrivateRoute
          exact
          role="Administrador,Cliente,Operacional Nível 1"
          path={routing.orderDetails(':id')}
          render={props => (
            <DashboardLayout
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose}
              open={open}
              setOpen={setOpen}
              title={t('Order.order')}
              {...props}
            >
              <DetailsOrder {...props} />
            </DashboardLayout>
          )}
        />
        <PrivateRoute
          exact
          role="Administrador,Operacional Nível 1"
          path={routing.creditCard}
          render={props => (
            <DashboardAdminLayout
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose}
              open={open}
              setOpen={setOpen}
              title={t('Product.product')}
              {...props}
            >
              <NewCreditCard {...props} />
            </DashboardAdminLayout>
          )}
        />
        <PrivateRoute
          exact
          role="Administrador,Operacional Nível 1"
          path={routing.account}
          render={props => (
            <DashboardLayout
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose}
              open={open}
              setOpen={setOpen}
              title={t('Product.product')}
              {...props}
            >
              <NewAccount {...props} />
            </DashboardLayout>
          )}
        />
        <PrivateRoute
          exact
          role="Administrador,Operacional Nível 1"
          path={routing.transfer}
          render={props => (
            <DashboardAdminLayout
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose}
              open={open}
              setOpen={setOpen}
              title={t('Product.product')}
              {...props}
            >
              <NewTransfer {...props} />
            </DashboardAdminLayout>
          )}
        />
        <PrivateRoute
          exact
          role="Administrador,Operacional Nível 1"
          path={routing.customized}
          render={props => (
            <DashboardAdminLayout
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose}
              open={open}
              setOpen={setOpen}
              title="Cartões customizados"
              {...props}
            >
              <CustomizedCards {...props} />
            </DashboardAdminLayout>
          )}
        />
        <PrivateRoute
          exact
          role="Administrador,Cliente,Operacional Nível 1"
          path={routing.myacessory}
          render={props => (
            <DashboardLayout
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose}
              open={open}
              setOpen={setOpen}
              title={t('Product.product')}
              {...props}
            >
              <MyAcessory {...props} />
            </DashboardLayout>
          )}
        />
        <PrivateRoute
          exact
          role="Administrador,Cliente,Operacional Nível 1"
          path={routing.notifications}
          render={props => (
            <DashboardLayout
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose}
              open={open}
              setOpen={setOpen}
              title="Notificações"
              {...props}
            >
              <Notifications {...props} />
            </DashboardLayout>
          )}
        />
        <PrivateRoute
          exact
          role="Administrador,Cliente,Operacional Nível 1,GerenteGM,GerenteLoja"
          path={routing.profile}
          render={props => (
            <DashboardLayout
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose}
              open={open}
              setOpen={setOpen}
              title="Perfil"
              {...props}
            >
              <UserProfile {...props} />
            </DashboardLayout>
          )}
        />
        <PrivateRoute
          exact
          role="Administrador,Operacional Nível 1"
          path={routing.newNotifications}
          render={props => (
            <DashboardLayout
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose}
              open={open}
              setOpen={setOpen}
              title="Notificações"
              {...props}
            >
              <NewNotification {...props} />
            </DashboardLayout>
          )}
        />
        <PrivateRoute
          exact
          role="Administrador,Operacional Nível 1"
          path={routing.newEmailNotifications}
          render={props => (
            <DashboardLayout
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose}
              open={open}
              setOpen={setOpen}
              title="Notificações"
              {...props}
            >
              <NewEmailNotification {...props} />
            </DashboardLayout>
          )}
        />
        <PrivateRoute
          exact
          role="Administrador,Operacional Nível 1"
          path={routing.users}
          render={props => (
            <DashboardLayout
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose}
              open={open}
              setOpen={setOpen}
              title="Usuários"
              {...props}
            >
              <Users {...props} />
            </DashboardLayout>
          )}
        />
        <PrivateRoute
          exact
          role="Administrador,Operacional Nível 1"
          path={routing.usersDetails(':id')}
          render={props => (
            <DashboardLayout
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose}
              open={open}
              setOpen={setOpen}
              title="Detalhes"
              {...props}
            >
              <UserDetails {...props} />
            </DashboardLayout>
          )}
        />
        <PrivateRoute
          exact
          role="Administrador,Operacional Nível 1"
          path={routing.newClientsGroup}
          render={props => (
            <DashboardLayout
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose}
              open={open}
              setOpen={setOpen}
              title="Notificações"
              {...props}
            >
              <NewClientsGroup {...props} />
            </DashboardLayout>
          )}
        />
        <PrivateRoute
          exact
          role="Administrador,Operacional Nível 1"
          path={routing.newEmailTextNotifications}
          render={props => (
            <DashboardLayout
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose}
              open={open}
              setOpen={setOpen}
              title="Notificações"
              {...props}
            >
              <NewEmailTextNotification {...props} />
            </DashboardLayout>
          )}
        />
      </Switch>
    </Router>
  );
};

export default Routes;
