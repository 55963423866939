/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';

// import { useHistory } from 'react-router-dom';

import { Grid } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import api from '../../../service/axios/api';
import Loading from '../../../components/loading/Loading';
import styles from '../../../styles/Layout.module.css';
// import routing from '../../../services/routes/routing';

const useStyles = makeStyles(() =>
  createStyles({
    myButton: {
      // textAlign: 'center',
      // padding: theme.spacing(6),
      // margin: theme.spacing(1),
      // color: theme.palette.text.secondary,
      color: '#2a3176',
      backgroundColor: '#f4f4f4',
      fontWeight: 'bold',
      // minWidth: 200,
    },
    myGrid: {
      // minWidth: 260,
      backgroundColor: '#f4f4f4',
      // margin: theme.spacing(1),
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      textAlign: 'center',
      cursor: 'pointer',
      border: '2px solid #c5c5c5',
      borderRadius: 10,
    },
    myGridSelected: {
      // minWidth: 260,
      backgroundColor: '#2a3176',
      // margin: theme.spacing(1),
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      textAlign: 'center',
      cursor: 'pointer',
      // border: '2px solid #2a3176',
      // borderRadius: 10,
    },
    myLabel: {
      // padding: theme.spacing(6),
      color: '#2a3176',
      fontWeight: 'bold',
      cursor: 'pointer',
    },
    myLabelSelected: {
      // padding: theme.spacing(6),
      color: '#41b490',
      fontWeight: 'bold',
      cursor: 'pointer',
    },
  }),
);

export default function SelectDestination({ setDest }) {
  const classes = useStyles();
  // const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [groups, setGroups] = useState([]);
  const [selected, setSelected] = useState();

  useEffect(() => {
    const cancel = new AbortController();
    api.notification
      .getgroup(cancel)
      .then(res => {
        setGroups(res.docs);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  }, []);

  const handleClick = (title, id) => {
    setSelected(title);
    setDest({
      title,
      id,
    });
  };
  return (
    <div className="col-sm-12">
      <Loading open={loading} msg="Buscando grupo de destinatários..." />
      {/* <div
        className="col-sm-12"
        style={{
          display: 'flex', flexDirection: 'row',
          alignItems: 'center', justifyContent: 'space-between',
        }}
      >

        <h4
          className="textMenu"
          onKeyDown={() => history.push(routing.dashboard)}
          onClick={() => history.push(routing.dashboard)}
        >
          Menu Inicial

        </h4>
        <h4
      // className="textMenu"
          onKeyDown={() => history.push(routing.dashboard)}
          onClick={() => history.push(routing.dashboard)}
          style={{ color: '#41b490' }}
        >
          Criar novo grupo de clientes

        </h4>
      </div> */}
      <div
        className="col-sm-12"
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <h5 className={styles.textTitle}>Escolha os Destinatários</h5>
      </div>
      <Grid
        container
        spacing={1}
        style={{
          justifyContent: 'center',
          padding: 40,
        }}
      >
        <Grid item xs={12}>
          {groups.map(g => (
            <h6
              key={g.id}
              className={
                selected === g.groupTitle
                  ? classes.myLabelSelected
                  : classes.myLabel
              }
              onClick={() => handleClick(g.groupTitle, g.id)}
            >
              {g.groupTitle}
            </h6>
          ))}
          {/* <Grid className={selected === 'contract'
        ? classes.myGridSelected : classes.myGrid} item xs={12} sm={8} md={2} lg={2}
        xl={2} onClick={() => handleClick('contract')}> */}
          {/* <h3 className={selected === 'Cliente de todas as empresas' ?
          classes.myLabelSelected : classes.myLabel} onClick={() =>
           handleClick('Cliente de todas as empresas')}>Cliente de todas as empresas</h3> */}
          {/* </Grid>
        <Grid className={selected === 'new' ? classes.myGridSelected : classes.myGrid}
        item xs={12} sm={8} md={2} lg={2} xl={2} onClick={() => handleClick('new')}> */}
          {/* <h3 className={selected === 'Clientes da empresa X' ? classes.myLabelSelected :
           classes.myLabel} onClick={() => handleClick
           ('Clientes da empresa X')}>Clientes da empresa X</h3> */}
          {/* </Grid>
        <Grid className={selected === 'process' ? classes.myGridSelected : classes.myGrid}
         item xs={12} sm={8} md={2} lg={2} xl={2} onClick={() => handleClick('process')}> */}
          {/* <h3 className={selected === 'Clientes da empresa Y' ? classes.myLabelSelected :
          classes.myLabel} onClick={() =>
           handleClick('Clientes da empresa Y')}>Clientes da empresa Y</h3> */}
          {/* <h3 className={selected === 'Clientes suporte caso X' ? classes.myLabelSelected :
           classes.myLabel} onClick={() =>
            handleClick('Clientes suporte caso X')}>Clientes suporte caso X</h3> */}
          {/* </Grid> */}
        </Grid>
      </Grid>
    </div>
  );
}
