/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */

import React, { useState, useEffect, useRef, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import moment from 'moment-timezone';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import PublishIcon from '@material-ui/icons/Publish';
import MessageIcon from '@material-ui/icons/Message';

import {
  // Tooltip,
  TablePagination,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Paper,
  styled,
  Checkbox,
  DialogContent,
  Menu,
  MenuItem,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  DatePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import { ptBR } from 'date-fns/locale';
import locale from 'date-fns/locale/pt-BR';
import DateFnsUtils from '@date-io/date-fns';
// import GetAppIcon from '@material-ui/icons/GetApp';

// import { useExcelDownloder } from 'react-xls';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';

import Button from '@material-ui/core/Button';

import Select from 'react-select';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import Status from '../../service/translateStatus';
import Loading from '../../components/loading/Loading';

import routing from '../../service/routes/routing';
import FlashContext from '../../components/contexts/FlashContext';

import api from '../../service/axios/api';

function createData(
  id,
  commercialAgentId,
  agent,
  amount,
  amountApproved,
  status,
  reference,
  nfNumber,
) {
  // const density = population / size;
  return {
    id,
    commercialAgentId,
    agent,
    amount,
    amountApproved,
    status,
    reference,
    nfNumber,
  };
}

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  table: {
    minWidth: 1100,
  },
});

const StyledTableCell = withStyles(() => ({
  head: {
    // backgroundColor: theme.palette.common.black,
    // color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark'
        ? 'rgba(57,75,89,.5)'
        : 'rgba(206,217,224,.5)',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#137cbd',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#106ba3',
  },
});

// eslint-disable-next-line no-unused-vars
export default function PaymentAgentsTable({
  docs,
  page,
  setPage,
  itens,
  setItens,
  total,
  selectedExtern,
  setSelectedExtern,
  // selected,
  // setSelected,
  selectedDate,
  search,
  totalAmount,
  totalAmountApproved,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { notifyError, notifySuccess } = useContext(FlashContext);
  const history = useHistory();
  const [rows, setRows] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalInvoice, setModalInvoice] = useState(false);
  const [modalDetails, setModalDetails] = useState(false);
  const [modalPayment, setModalPayment] = useState(false);
  const [modalJustify, setModalJustify] = useState(false);
  const [modalMessage, setModalMessage] = useState(false);
  const [modalAprov, setModalAprov] = useState(false);
  const [selected, setSelected] = React.useState([]);
  const [amountApproved, setAmountApproved] = React.useState('0');
  // const [selectedExtern, setSelectedExtern] = React.useState([]);
  const isSelected = id => selected.indexOf(id) !== -1;
  const isSelectedExtern = id => selectedExtern.indexOf(id) !== -1;
  const [menuOptions, setMenuOptions] = useState(null);
  const fileC = useRef(null);
  const [file, setFile] = useState();
  const [dateInvoice, handleDateInvoice] = useState(null);
  const [invoiceNumber, setInvoiceNumber] = useState();

  const [details, setDetails] = useState([]);
  const [loadingNf, setLoadingNf] = useState(false);
  const [loadingPayment, setLoadingPayment] = useState(false);

  const [pageDetails, setPageDetails] = useState(0);
  const [totalDetails, setTotalDetails] = useState(0);
  const [itensDetails, setItensDetails] = useState(10);

  const [loadingDetails, setLoadingDetails] = useState(false);

  const [comments, setComments] = useState('');
  const [dbJustify, setDbJustify] = useState('');

  const [loadingStatus, setLoadinStatus] = useState(false);
  const [msgStatus, setMsgStatus] = useState('');
  const [disable, setDisable] = useState(false);

  // const [selected, setSelected] = useState([]);

  const handleMenu = (row, event) => {
    setMenuOptions(row);
    setAnchorEl(event.currentTarget);
  };

  const sendStatus = status => {
    if (status === 'approved') {
      setMsgStatus('Aprovando comissões...');
    } else {
      if (comments === '') {
        notifyError('Informe a justificativa da reprova');
        return;
      }
      setMsgStatus('Reprovando comissões...');
    }

    const listFinal = [];
    selected.forEach(s => {
      if (typeof s === 'string') {
        listFinal.push(s);
      }
    });

    setLoadinStatus(true);
    api.commercialAgent
      .changeStatus(status, listFinal, comments)
      .then(res => {
        notifySuccess(res.data.message);
      })
      .catch(err => console.log(err))
      .finally(() => {
        setLoadinStatus(false);
        setModalAprov(false);
        setModalJustify(false);
        search();
        setModalDetails(false);
        setComments('');
        setSelected([]);
      });
  };

  const searchDetails = () => {
    const cancel = new AbortController();
    setLoadingDetails(true);
    api.commercialAgent
      .getSummaryDetails(cancel, menuOptions.id, pageDetails + 1, itensDetails)
      .then(res => {
        const list = [];
        setTotalDetails(res.data.total);
        let myCount = 0;
        res.data?.docs?.forEach(d => {
          myCount = d?.status === 'approved' && myCount + 1;
          list.push({
            id: d?.id,
            status: d?.status,
            comments: d?.comments,
            order: d?.Invoice?.Order?.order_number,
            orderId: d?.Invoice?.Order?.id,
            dateOrder: moment(d?.Invoice?.Order?.created_at).format(
              'DD/MM/YYYY',
            ),
            company: d?.Invoice?.client_name,
            amountOrder: d?.Invoice?.amount,
            datePayment: moment(d?.Invoice?.payment_date).format('DD/MM/YYYY'),
            invoice: d?.Invoice?.invoice_number && (
              <div
                style={{ cursor: 'pointer', color: 'blue' }}
                onClick={() => window.open(d?.Invoice?.gcs_pdf, '_blank')}
              >
                {d?.Invoice?.invoice_number}
              </div>
            ),
            comission: d?.net_commission,
          });
        });
        setDisable(res.data?.docs?.length === myCount);
        setDetails(list);
      })
      .catch(err => notifyError(err.message))
      .finally(() => setLoadingDetails(false));
  };

  useEffect(() => {
    if (menuOptions?.id) {
      searchDetails();
    }
  }, [pageDetails, itensDetails]);

  const searchOldYear = () => {
    const cancel = new AbortController();
    // setLoadingDetails(true);
    api.commercialAgent
      .getSummaryYear(cancel, menuOptions.commercialAgentId, selectedDate)
      .then(res => {
        setAmountApproved(res.data?.approved);
      })
      .catch(err => notifyError(err.message));
  };

  const downloadDetails = () => {
    const cancel = new AbortController();
    setLoadingDetails(true);
    api.commercialAgent
      .getSummaryDetailsXls(cancel, menuOptions.id)
      .then(res => {
        // console.log(res);
        const url = res.data.retorno.link;
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'comissoes_detalhes.xlsx'); // or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => notifyError(err.message))
      .finally(() => setLoadingDetails(false));
  };

  useEffect(() => {
    if (docs) {
      const list = [];
      docs.forEach(d => {
        list.push(
          createData(
            d.id,
            d.commercialAgent_id,
            d.commercialAgent_name,
            d.amount,
            d.amount_approved,
            d.status,
            `${d.month}/${d.year}`,
            d.nf_number && (
              <div
                style={{ cursor: 'pointer', color: 'blue' }}
                onClick={() => window.open(d.invoice_gcs, '_blank')}
              >
                {d.nf_number}
              </div>
            ),
          ),
        );
      });
      setRows(list);
    }
  }, [docs]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangePageDetails = (event, newPage) => {
    setPageDetails(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setItens(event.target.value);
  };

  const handleChangeRowsPerPageDetails = event => {
    setItensDetails(event.target.value);
  };

  const sendNf = () => {
    setModalInvoice(true);
    handleClose();
  };

  const viewDetails = () => {
    // console.log(menuOptions);
    searchDetails();
    searchOldYear();
    setModalDetails(true);
    handleClose();
  };

  const sendPayment = () => {
    setModalPayment(true);
    handleClose();
  };

  const handlePayment = () => {
    setLoadingPayment(true);
    api.commercialAgent
      .sendPayment(menuOptions.id)
      .then(res => {
        notifySuccess(res?.data?.message);
        setModalPayment(false);
        search();
      })
      .catch(err => notifyError(err.message))
      .finally(() => {
        setLoadingPayment(false);
      });
  };

  // const createDownload = url => {
  //   const link = document.createElement('a');
  //   link.href = url;
  //   link.setAttribute('download', 'data:text/plain;charset=utf-8,arquivo.txt'); // or any other extension
  //   document.body.appendChild(link);
  //   link.click();
  // };

  const sendInvoice = () => {
    if (file) {
      if (invoiceNumber) {
        if (dateInvoice) {
          setLoadingNf(true);
          api.commercialAgent
            .sendInvoice(
              [menuOptions.id],
              invoiceNumber,
              menuOptions.amount,
              dateInvoice,
              menuOptions.commercialAgentId,
              file,
            )
            .then(res => {
              notifySuccess(res?.data?.message);
              setFile();
              setInvoiceNumber();
              handleDateInvoice();
              search();
              setModalInvoice(false);
            })
            .catch(err => {
              notifyError(err.message);
            })
            .finally(() => {
              setLoadingNf(false);
            });
        } else {
          notifyError('Informe a data de emissão da nota fiscal a ser enviada');
        }
      } else {
        notifyError('Informe o número da nota fiscal a ser enviada');
      }
    } else {
      notifyError('Selecione a nota fiscal a ser enviada');
    }
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = details.map(n => !n.status && n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleSelectAllClickExtern = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.id);
      setSelectedExtern(newSelecteds);
      return;
    }
    setSelectedExtern([]);
  };

  useEffect(() => {
    console.log(selectedExtern);
  }, [selectedExtern]);

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  // EXTERN TABLE
  const handleClickExtern = (event, id) => {
    const selectedIndex = selectedExtern.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedExtern, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedExtern.slice(1));
    } else if (selectedIndex === selectedExtern.length - 1) {
      newSelected = newSelected.concat(selectedExtern.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedExtern.slice(0, selectedIndex),
        selectedExtern.slice(selectedIndex + 1),
      );
    }

    setSelectedExtern(newSelected);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell padding="checkbox">
                <Checkbox
                  // color="primary"
                  checkedIcon={<BpCheckedIcon />}
                  icon={<BpIcon />}
                  indeterminate={
                    selectedExtern.length > 0 &&
                    selectedExtern.length < rows.length
                  }
                  checked={
                    rows.length > 0 && selectedExtern.length === rows.length
                  }
                  onChange={handleSelectAllClickExtern}
                  inputProps={{
                    'aria-label': 'select all desserts',
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>Agente</StyledTableCell>
              <StyledTableCell>Mês/Ano</StyledTableCell>
              <StyledTableCell>Nota Fiscal</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell align="right">Valor</StyledTableCell>
              <StyledTableCell align="right">Aprovado</StyledTableCell>
              <StyledTableCell padding="checkbox" />
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => {
              const isItemSelected = isSelectedExtern(row.id);
              return (
                // eslint-disable-next-line react/no-array-index-key
                <StyledTableRow key={i}>
                  <StyledTableCell padding="checkbox">
                    <Checkbox
                      disabled={
                        Number(Number(row.amountApproved)?.toFixed(2)) >=
                        Number(Number(row.amount)?.toFixed(2))
                      }
                      checkedIcon={<BpCheckedIcon />}
                      icon={<BpIcon />}
                      onClick={event => handleClickExtern(event, row.id)}
                      checked={isItemSelected}
                      // onClick={() => setCancelAllCards(!cancelAllCards)}
                      // checked={cancelAllCards}
                      inputProps={{
                        'aria-label': 'select all desserts',
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell>{row.agent}</StyledTableCell>
                  <StyledTableCell>{row.reference}</StyledTableCell>
                  <StyledTableCell>{row.nfNumber}</StyledTableCell>
                  <StyledTableCell>{Status(row.status, t)}</StyledTableCell>
                  <StyledTableCell align="right">
                    {new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })
                      .format(row.amount)
                      .replace(',', '.')}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })
                      .format(row.amountApproved)
                      .replace(',', '.')}
                  </StyledTableCell>
                  <StyledTableCell padding="checkbox">
                    <MoreVertIcon
                      onClick={e => handleMenu(row, e)}
                      style={{ cursor: 'pointer' }}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
            <StyledTableRow>
              <StyledTableCell />
              <StyledTableCell />
              <StyledTableCell />
              <StyledTableCell />
              <StyledTableCell align="right">
                TOTAL:{' '}
                {new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })
                  .format(totalAmount)
                  .replace(',', '.')}
              </StyledTableCell>
              <StyledTableCell align="right">
                APROVADO:{' '}
                {new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })
                  .format(totalAmountApproved)
                  .replace(',', '.')}
              </StyledTableCell>
              <StyledTableCell padding="checkbox" />

              {/* </StyledTableCell> */}
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100, 1000, 50000]}
        component="div"
        count={total}
        rowsPerPage={itens}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <Menu
        id="menu-appbar"
        style={{ borderRadius: '25px' }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem className={classes.textNotification} onClick={viewDetails}>
          Detalhes
        </MenuItem>
        <MenuItem
          className={classes.textNotification}
          disabled={menuOptions?.status !== 'AwaitingInvoice'}
          onClick={sendNf}
        >
          Enviar Nota
        </MenuItem>
        <MenuItem
          className={classes.textNotification}
          disabled={!(menuOptions?.status === 'InvoiceReceived')}
          onClick={sendPayment}
        >
          Pagar
        </MenuItem>
      </Menu>

      <Dialog
        onClose={() => setModalDetails(!modalDetails)}
        open={modalDetails}
        fullWidth
        maxWidth="lg"
      >
        <Loading open={loadingDetails} msg="Buscando detalhes..." />
        <DialogTitle id="simple-dialog-title">
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ display: 'flex' }}>
              Detalhes
              <h4
                style={{
                  cursor: 'pointer',
                  // marginRight: 30,
                  marginLeft: 10,
                  color: '#41B490',
                  fontFamily: 'Open Sans',
                  // fontSize: 18,
                }}
                onClick={downloadDetails}
              >
                <img
                  id="refresh"
                  src="./img/download.svg"
                  alt="logo"
                  style={{ height: 20, width: 20 }}
                />
              </h4>
            </div>
            <div style={{ display: 'flex' }}>
              {(api.currentUser.id === '8' ||
                api.currentUser.id === '9' ||
                api.currentUser.id === '214' ||
                api.currentUser.id === '278') && (
                <>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => {
                      if (selected.length > 0) {
                        setModalAprov(true);
                      } else {
                        notifyError(
                          'Selecione pelo menos uma comissão para prosseguir',
                        );
                      }
                    }}
                  >
                    Aprovar
                  </Button>
                  <Button
                    color="primary"
                    variant="outlined"
                    style={{ marginLeft: 10 }}
                    onClick={() => {
                      if (selected.length > 0) {
                        setModalJustify(true);
                      } else {
                        notifyError(
                          'Selecione pelo menos uma comissão para prosseguir',
                        );
                      }
                    }}
                  >
                    Reprovar
                  </Button>
                </>
              )}
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  {!disable && (
                    <StyledTableCell padding="checkbox">
                      <Checkbox
                        // color="primary"
                        checkedIcon={<BpCheckedIcon />}
                        icon={<BpIcon />}
                        indeterminate={
                          selected.length > 0 &&
                          selected.length < details.length
                        }
                        checked={
                          details.length > 0 &&
                          selected.length === details.length
                        }
                        onChange={handleSelectAllClick}
                        inputProps={{
                          'aria-label': 'select all desserts',
                        }}
                      />
                    </StyledTableCell>
                  )}
                  <StyledTableCell>Pedido</StyledTableCell>
                  <StyledTableCell>Data Pedido</StyledTableCell>
                  <StyledTableCell>Valor Pedido</StyledTableCell>
                  <StyledTableCell>Data Pagamento</StyledTableCell>
                  <StyledTableCell>Empresa</StyledTableCell>
                  <StyledTableCell>Nota Fiscal</StyledTableCell>
                  <StyledTableCell>Comissão</StyledTableCell>
                  <StyledTableCell align="right">Aprovada</StyledTableCell>
                  <StyledTableCell align="right">Obs</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {details.map((row, i) => {
                  const isItemSelected = isSelected(row.id);
                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <StyledTableRow key={i}>
                      <StyledTableCell padding="checkbox">
                        {!row.status && (
                          <Checkbox
                            checkedIcon={<BpCheckedIcon />}
                            icon={<BpIcon />}
                            onClick={event => handleClick(event, row.id)}
                            checked={isItemSelected}
                            // onClick={() => setCancelAllCards(!cancelAllCards)}
                            // checked={cancelAllCards}
                            inputProps={{
                              'aria-label': 'select all desserts',
                            }}
                          />
                        )}
                      </StyledTableCell>
                      <StyledTableCell
                        onClick={() =>
                          history.push(routing.orderDetails(row.orderId))
                        }
                        style={{ cursor: 'pointer' }}
                      >
                        {row.order}
                      </StyledTableCell>
                      <StyledTableCell>{row.dateOrder}</StyledTableCell>
                      <StyledTableCell>
                        {new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        })
                          .format(row.amountOrder)
                          .replace(',', '.')}
                      </StyledTableCell>
                      {/* <StyledTableCell>{row.comission}</StyledTableCell> */}
                      <StyledTableCell>{row.datePayment}</StyledTableCell>
                      <StyledTableCell>{row.company}</StyledTableCell>
                      <StyledTableCell>{row.invoice}</StyledTableCell>
                      <StyledTableCell align="right">
                        {new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        })
                          .format(row.comission)
                          .replace(',', '.')}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {row.status === 'approved'
                          ? new Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL',
                            })
                              .format(row.comission)
                              .replace(',', '.')
                          : row.status === 'disapproved'
                          ? ' - '
                          : 'PENDENTE'}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {row.comments && (
                          <MessageIcon
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              setDbJustify(row.comments);
                              setModalMessage(true);
                            }}
                          />
                        )}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100, 1000, 50000]}
            component="div"
            count={totalDetails}
            rowsPerPage={itensDetails}
            page={pageDetails}
            onChangePage={handleChangePageDetails}
            onChangeRowsPerPage={handleChangeRowsPerPageDetails}
          />
        </DialogContent>
        <DialogActions>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <p>
              {`Aprovadas últimos 12 mesês 
              ${new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })
                .format(amountApproved)
                .replace(',', '.')}`}
            </p>
            <Button
              style={{ height: 40 }}
              type="submit"
              color="primary"
              variant="contained"
              onClick={() => {
                setDetails([]);
                setSelected([]);
                setModalDetails(false);
              }}
            >
              FECHAR
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      <Dialog
        onClose={() => setModalDetails(!modalDetails)}
        open={modalPayment}
        fullWidth
        maxWidth="sm"
      >
        <Loading open={loadingPayment} msg="Pagando...." />
        <DialogTitle id="simple-dialog-title">Pagar</DialogTitle>
        <DialogContent>
          Deseja realmente informar como pago a comissão do Agente{' '}
          {menuOptions?.agent} no valor de{' '}
          {new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })
            .format(menuOptions?.amount)
            .replace(',', '.')}
          ?
        </DialogContent>
        <DialogActions>
          <h6
            style={{
              cursor: 'pointer',
              color: '#657588',
              fontWeight: 'bold',
              fontFamily: 'Open Sans',
              marginTop: 5,
            }}
            onClick={() => setModalPayment(false)}
          >
            Cancelar
          </h6>
          <Button
            style={{ height: 40 }}
            type="submit"
            color="primary"
            variant="contained"
            onClick={handlePayment}
          >
            PAGAR
          </Button>
        </DialogActions>
      </Dialog>

      {/* MESSAGE MODAL */}
      <Dialog
        onClose={() => setModalMessage(false)}
        open={modalMessage}
        fullWidth
        maxWidth="sm"
      >
        {/* <Loading open={loadingPayment} msg="Pagando...." /> */}
        <DialogTitle id="simple-dialog-title">Justificativa</DialogTitle>
        <DialogContent>{dbJustify}</DialogContent>
        <DialogActions>
          <Button
            style={{ height: 40 }}
            type="submit"
            color="primary"
            variant="contained"
            onClick={() => setModalMessage(false)}
          >
            FECHAR
          </Button>
        </DialogActions>
      </Dialog>
      {/* FINAL */}
      {/* MESSAGE MODAL */}
      <Dialog
        onClose={() => setModalAprov(false)}
        open={modalAprov}
        fullWidth
        maxWidth="sm"
      >
        <Loading open={loadingStatus} msg={msgStatus} />
        <DialogTitle id="simple-dialog-title">Aprovar</DialogTitle>
        <DialogContent>
          Deseja realmente aprovar as comissões selecionadas?
        </DialogContent>
        <DialogActions>
          <h6
            style={{
              cursor: 'pointer',
              color: '#657588',
              fontWeight: 'bold',
              fontFamily: 'Open Sans',
              marginTop: 5,
            }}
            onClick={() => setModalAprov(false)}
          >
            Cancelar
          </h6>
          <Button
            style={{ height: 40 }}
            type="submit"
            color="primary"
            variant="contained"
            onClick={() => sendStatus('approved')}
          >
            APROVAR
          </Button>
        </DialogActions>
      </Dialog>
      {/* FINAL */}
      {/* JUSTIFY MODAL */}
      <Dialog
        onClose={() => setModalJustify(false)}
        open={modalJustify}
        fullWidth
        maxWidth="sm"
      >
        {/* <Loading open={loadingPayment} msg="Pagando...." /> */}
        <Loading open={loadingStatus} msg={msgStatus} />
        <DialogTitle id="simple-dialog-title">Reprovar</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            id="outlined-basic"
            variant="outlined"
            size="small"
            placeholder="Justificativa..."
            value={comments}
            multiline
            rows={4}
            onChange={e => setComments(e.target.value)}
            // onClick={() => fileC.current.click()}
            // value={file && file.name}
          />
        </DialogContent>
        <DialogActions>
          <h6
            style={{
              cursor: 'pointer',
              color: '#657588',
              fontWeight: 'bold',
              fontFamily: 'Open Sans',
              marginTop: 5,
            }}
            onClick={() => setModalJustify(false)}
          >
            Cancelar
          </h6>
          <Button
            style={{ height: 40 }}
            type="submit"
            color="primary"
            variant="contained"
            onClick={() => sendStatus('disapproved')}
          >
            JUSTIFICAR
          </Button>
        </DialogActions>
      </Dialog>
      {/* FINAL */}
      <Dialog
        onClose={() => setModalInvoice(!modalInvoice)}
        open={modalInvoice}
        fullWidth
        maxWidth="sm"
      >
        <Loading open={loadingNf} msg="Enviando nota...." />
        <DialogTitle id="simple-dialog-title">Envio de Nota</DialogTitle>
        <DialogContent>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {/* <p>Nota fiscal</p> */}
            <TextField
              disabled
              fullWidth
              id="outlined-basic"
              style={{ marginBottom: -5 }}
              variant="outlined"
              size="small"
              placeholder="Selecione a nota fiscal"
              // onClick={() => fileC.current.click()}
              value={file && file.name}
            />
            {/* <PublishIcon
              style={{ cursor: 'pointer' }}
              onClick={() => fileC.current.click()}
            /> */}
            {file ? (
              <HighlightOffIcon
                style={{ cursor: 'pointer' }}
                onClick={() => setFile()}
              />
            ) : (
              <PublishIcon
                style={{ cursor: 'pointer' }}
                onClick={() => fileC.current.click()}
                // onClick={() => cleanFile(row)}
              />
            )}
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: 20 }}>
            <div style={{ width: '50%', marginRight: 10 }}>
              <TextField
                fullWidth
                id="outlined-basic"
                variant="outlined"
                size="small"
                placeholder="Número da NF"
                value={invoiceNumber}
                onChange={e => setInvoiceNumber(e.target.value)}
                // onClick={() => fileC.current.click()}
                // value={file && file.name}
              />
            </div>
            <div style={{ width: '50%' }}>
              <MuiPickersUtilsProvider locale={locale} utils={DateFnsUtils}>
                <KeyboardDatePicker
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  style={{ backgroundColor: '#fff' }}
                  // views={['month', 'year']}
                  placeholder="Data Emissão"
                  size="small"
                  format="dd/MM/yyyy"
                  value={dateInvoice}
                  InputProps={{ readOnly: true }}
                  InputAdornmentProps={{ position: 'start' }}
                  onChange={date => handleDateInvoice(date)}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <h6
            style={{
              cursor: 'pointer',
              color: '#657588',
              fontWeight: 'bold',
              fontFamily: 'Open Sans',
              marginTop: 5,
            }}
            onClick={() => setModalInvoice(false)}
          >
            Cancelar
          </h6>
          <Button
            style={{ height: 40 }}
            type="submit"
            color="primary"
            variant="contained"
            onClick={sendInvoice}
          >
            ENVIAR
          </Button>
        </DialogActions>
      </Dialog>
      <input
        ref={fileC}
        type="file"
        style={{
          opacity: 0,
        }}
        onChange={() => setFile(fileC.current.files[0])}
        className="form-control"
        id="inputNumSerie"
      />
    </Paper>
  );
}
