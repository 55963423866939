/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styles from '../../../styles/Layout.module.css';

import './checkout.css';

export default function SpecTransferOrder({ selected, setSelected }) {
  // const [selected, setSelected] = useState();
  const { t } = useTranslation();
  return (
    <div className="box-new-card">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'start',
          alignItems: 'center',
        }}
      >
        <h6 className={styles.textBoxInform}>
          {t('newOrder.specOrder.label')}
        </h6>
      </div>
      <Grid
        container
        spacing={1}
        direction="column"
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          padding: 40,
        }}
      >
        <Grid item>
          <h5
            className={
              selected !== 'completo' ? 'label-spec' : 'label-spec-selected'
            }
            onClick={() => setSelected('completo')}
          >
            Transferência
          </h5>
        </Grid>
        <Grid item>
          <h5
            className={
              selected !== 'credito' ? 'label-spec' : 'label-spec-selected'
            }
            onClick={() => setSelected('credito')}
          >
            {t('newOrder.specOrder.credit')}
          </h5>
        </Grid>
        <Grid item>
          <h5
            className={
              selected !== 'DCP' ? 'label-spec' : 'label-spec-selected'
            }
            onClick={() => setSelected('DCP')}
          >
            Demonstrativo Consolidado de Premiação
          </h5>
        </Grid>
      </Grid>
    </div>
  );
}
