/* eslint-disable react/prop-types */
import React from 'react';
import { CircularProgress } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';

// import { useTutorialCategories } from '@/lib/API';

const filter = createFilterOptions();

function TutorialCategoryAutocomplete({ value, onChange, ...props }) {
  // const { create } = useTutorialCategories();

  // const query = firestore.collection('tutorialCategories');
  // const [categories, loading] = useCollectionData(query);
  const categories = [];
  const loading = false;

  return (
    <Autocomplete
      loading={loading}
      value={value}
      onChange={async (event, newValue) => {
        if (typeof newValue === 'string') {
          onChange({
            title: newValue,
          });
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          // const { error, data } = await createCategory({
          //   title: newValue.inputValue,
          // });
          // if (!error) {
          //   onChange({
          //     id: data.category.id,
          //     title: data.category.title,
          //   });
          // }
        } else {
          onChange(newValue);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        // Suggest the creation of a new value
        if (
          params.inputValue !== '' &&
          options.every(
            opt => opt.title.toLowerCase() !== params.inputValue.toLowerCase(),
          )
        ) {
          filtered.push({
            inputValue: params.inputValue,
            title: `Agregar "${params.inputValue}"`,
          });
        }

        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="tutorial-category"
      options={categories || []}
      getOptionLabel={option => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.title;
      }}
      renderOption={option => option.title}
      freeSolo
      renderInput={params => (
        <TextField
          {...params}
          {...props}
          label="Categoria"
          variant="outlined"
          fullWidth
          size="small"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}

export default TutorialCategoryAutocomplete;
