export default {
  logic: {
    max: 20,
  },
  styling: {
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
  },
};
