/* eslint-disable react/no-unescaped-entities */
import * as React from 'react';

export default function Review() {
  return (
    <div className="box-new-card" style={{ height: 370, overflowY: 'scroll' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: -15,
          marginBottom: -5,
        }}
      >
        <p className="text-box-inform">Revisão e confirmação</p>
        <p className="text-box-inform-2">COD.: PROD_CDPP_001</p>
      </div>
      <p className="review-label-1">Conta Digital</p>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: -50,
        }}
      >
        <div className="my-colunm-align ">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <p className="review-label ">Nome:</p>
            <p className="review-value "> Cartão Pré-Pago</p>
            <img className="review-img" src="./img/edit.svg" alt="logo" />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: -20,
            }}
          >
            <p className="review-label ">Recarga:</p>
            <p className="review-label ">Sistema:</p>
            <p className="review-value "> R$00.00</p>
            <img className="review-img" src="./img/edit.svg" alt="logo" />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: -15,
              marginLeft: 40,
            }}
          >
            <p className="review-label " />
            <p className="review-label ">PDV:</p>
            <p className="review-value "> R$00.00</p>
            <img className="review-img" src="./img/edit.svg" alt="logo" />
          </div>
        </div>
        <div className="my-colunm-align ">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <p className="review-label ">Adesão conta:</p>
            <p className="review-value "> R$00.00</p>
            <img className="review-img" src="./img/edit.svg" alt="logo" />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: -20,
              marginLeft: -80,
            }}
          >
            <p className="review-label ">Saques:</p>
            <p className="review-label ">QR:</p>
            {/* <p className="review-value "> R$00.00</p> */}
            <img className="review-img" src="./img/edit.svg" alt="logo" />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: -15,
              marginLeft: 25,
            }}
          >
            <p className="review-label " />
            <p className="review-label ">Loterica:</p>
            {/* <p className="review-value "> R$00.00</p> */}
            <img className="review-img" src="./img/edit.svg" alt="logo" />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: -15,
              marginLeft: -5,
            }}
          >
            <p className="review-label " />
            <p className="review-label ">PDV:</p>
            {/* <p className="review-value "> R$00.00</p> */}
            <img className="review-img" src="./img/edit.svg" alt="logo" />
          </div>
        </div>
        <div className="my-colunm-align ">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <p className="review-label ">Mensalidade:</p>
            <p className="review-value "> R$00.00</p>
            <img className="review-img" src="./img/edit.svg" alt="logo" />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: -10,
            }}
          >
            <p className="review-label ">Recarga:</p>
            <p className="review-label ">Sistema:</p>
            <p className="review-value "> R$00.00</p>
            <img className="review-img" src="./img/edit.svg" alt="logo" />
          </div>
        </div>
        <div className="my-colunm-align ">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <p className="review-label ">Transferência TED:</p>
            <p className="review-value "> R$00.00</p>
            <img className="review-img" src="./img/edit.svg" alt="logo" />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: -15,
              marginLeft: 135,
            }}
          >
            <p className="review-label " />
            <p className="review-label ">Pix(PF):</p>
            <p className="review-value "> R$00.00</p>
            <img className="review-img" src="./img/edit.svg" alt="logo" />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: -15,
              marginLeft: 135,
            }}
          >
            <p className="review-label " />
            <p className="review-label ">PIX(PJ):</p>
            <p className="review-value "> R$00.00</p>
            <img className="review-img" src="./img/edit.svg" alt="logo" />
          </div>
        </div>
      </div>
      {/* CARTÃO */}
      <p className="review-label-1">Cartão</p>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: -50,
        }}
      >
        <div className="my-colunm-align ">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <p className="review-label ">Cartão:</p>
            <p className="review-value "> Virtual</p>
            <img className="review-img" src="./img/edit.svg" alt="logo" />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: -20,
              marginLeft: 50,
            }}
          >
            <p className="review-value "> Físico</p>
            <img className="review-img" src="./img/edit.svg" alt="logo" />
          </div>
        </div>
        <div className="my-colunm-align ">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <p className="review-label ">Bandeira:</p>
            <p className="review-value "> Mastercard</p>
            <img className="review-img" src="./img/edit.svg" alt="logo" />
          </div>
        </div>
        <div className="my-colunm-align ">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <p className="review-label ">Emissor:</p>
            <p className="review-value "> Acesso</p>
            <img className="review-img" src="./img/edit.svg" alt="logo" />
          </div>
        </div>
        <div className="my-colunm-align ">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <p className="review-label ">Personalização:</p>
            <p className="review-value "> Sim/Não</p>
            <img className="review-img" src="./img/edit.svg" alt="logo" />
          </div>
        </div>
        <div className="my-colunm-align ">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <p className="review-label ">Cobertura:</p>
            <p className="review-value "> Internacional</p>
            <img className="review-img" src="./img/edit.svg" alt="logo" />
          </div>
        </div>
        <div className="my-colunm-align ">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <p className="review-label ">Função:</p>
            <p className="review-value "> Crédito</p>
            <img className="review-img" src="./img/edit.svg" alt="logo" />
          </div>
        </div>
      </div>
      {/* CARTÃO FISICO */}
      <p className="review-label-1">Cartão físico</p>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'start',
          alignItems: 'center',
          marginTop: -40,
        }}
      >
        <div className="my-colunm-align ">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <p className="review-label ">Valor da emissão:</p>
            <p className="review-value "> R$00.00</p>
            <img className="review-img" src="./img/edit.svg" alt="logo" />
          </div>
        </div>
        <div className="my-colunm-align ">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginLeft: 30,
            }}
          >
            <p className="review-label ">Tarifa portador:</p>
            <p className="review-value "> R$00.00</p>
            <img className="review-img" src="./img/edit.svg" alt="logo" />
          </div>
        </div>
        <div className="my-colunm-align ">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginLeft: 30,
            }}
          >
            <p className="review-label ">Limites:</p>
            <p className="review-value "> R$00.00</p>
            <img className="review-img" src="./img/edit.svg" alt="logo" />
          </div>
        </div>
      </div>
    </div>
  );
}
