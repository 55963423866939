/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */

import React, { useState, useEffect, useContext } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';

import {
  TablePagination,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Paper,
  FormControlLabel,
  Menu,
  MenuItem,
  FormGroup,
  Checkbox,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';

import Button from '@material-ui/core/Button';

import Select from 'react-select';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import moment from 'moment';
import Loading from '../../components/loading/Loading';

import FlashContext from '../../components/contexts/FlashContext';

import api from '../../service/axios/api';

const UFsOpt = [
  { label: 'AC', value: 'AC' },
  { label: 'RO', value: 'RO' },
  { label: 'AM', value: 'AM' },
  { label: 'RR', value: 'RR' },
  { label: 'AP', value: 'AP' },
  { label: 'PA', value: 'PA' },
  { label: 'TO', value: 'TO' },
  { label: 'MA', value: 'MA' },
  { label: 'PI', value: 'PI' },
  { label: 'BA', value: 'BA' },
  { label: 'SE', value: 'SE' },
  { label: 'AL', value: 'AL' },
  { label: 'PE', value: 'PE' },
  { label: 'PB', value: 'PB' },
  { label: 'RN', value: 'RN' },
  { label: 'CE', value: 'CE' },
  { label: 'MT', value: 'MT' },
  { label: 'MS', value: 'MS' },
  { label: 'GO', value: 'GO' },
  { label: 'MG', value: 'MG' },
  { label: 'ES', value: 'ES' },
  { label: 'RJ', value: 'RJ' },
  { label: 'SP', value: 'SP' },
  { label: 'PR', value: 'PR' },
  { label: 'SC', value: 'SC' },
  { label: 'RS', value: 'RS' },
];

const customStylesEdit = {
  control: provided => ({
    ...provided,
    height: 40,
    borderRadius: 10,
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  option: provided => ({
    ...provided,
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
};

function createData(id, rs, cnpj, prefix, ie, im, createdAt, status, all) {
  return {
    id,
    rs,
    cnpj,
    prefix,
    ie,
    im,
    createdAt,
    status,
    all,
  };
}

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  table: {
    minWidth: 1100,
  },
});

const StyledTableCell = withStyles(() => ({
  head: {},
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

// eslint-disable-next-line no-unused-vars
export function CompanyBaseTable({
  docs,
  page,
  setPage,
  itens,
  setItens,
  total,
  search,
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);

  const { notifyError, notifySuccess } = useContext(FlashContext);
  const [menuOptions, setMenuOptions] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  // edição
  const [companyName, setCompanyName] = useState(null);
  const [cnpj, setCnpj] = useState('');
  const [ie, setIe] = useState('');
  const [im, setIm] = useState('');
  const [prefix, setPrefix] = useState('');
  const [address, setAddress] = useState('');
  const [number, setNumber] = useState('');
  const [district, setDistrict] = useState('');
  const [city, setCity] = useState('');
  const [complement, setComplement] = useState('');
  const [uf, setUf] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [principal, setPrincipal] = useState(false);
  const [certName, setCertName] = useState('');

  const handleMenu = (row, event) => {
    setMenuOptions(row);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const { ExcelDownloder, Type } = useExcelDownloder();

  useEffect(() => {
    const list = [];
    docs.forEach(d => {
      list.push(
        createData(
          d.id,
          d.company_name,
          d.cnpj,
          d.prefix,
          d.ie,
          d.im,
          d.createdAt,
          d.is_active,
          d,
        ),
      );
    });
    setRows(list);
  }, [docs]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setItens(event.target.value);
  };

  const updateConf = e => {
    setLoading(true);
    e.preventDefault();
    api.company
      .edit(
        menuOptions?.id,
        companyName,
        cnpj,
        im,
        ie,
        address,
        number,
        uf?.value,
        postalCode,
        complement,
        prefix,
        district,
        city,
        principal,
        certName,
      )
      .then(res => {
        search();
        notifySuccess(res.message);
        setOpen(false);
      })
      .catch(err => {
        if (err?.problems?.length > 0) {
          err.problems.forEach(p => {
            notifyError(p.msg);
          });
        } else {
          notifyError(err.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleEdit = () => {
    setCertName(menuOptions?.all?.cert_file_name);
    setPrincipal(menuOptions?.all?.is_active);
    setCompanyName(menuOptions?.rs);
    setCnpj(menuOptions?.cnpj);
    setIe(menuOptions?.ie);
    setIm(menuOptions?.im);
    setPrefix(menuOptions?.prefix);
    setAddress(menuOptions?.all?.street_name);
    setNumber(menuOptions?.all?.number);
    setDistrict(menuOptions?.all?.district);
    setCity(menuOptions?.all?.city);
    setComplement(menuOptions?.all?.complement);
    setUf({ label: menuOptions?.all?.state, value: menuOptions?.all?.state });
    setPostalCode(menuOptions?.all?.postal_code);
    setOpen(true);
  };
  return (
    <>
      <Paper className={classes.root}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Razão Social</StyledTableCell>
                <StyledTableCell>CNPJ</StyledTableCell>
                <StyledTableCell style={{ width: 100 }}>IE</StyledTableCell>
                <StyledTableCell>IM</StyledTableCell>
                <StyledTableCell>Prefixo NF</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell>Data criação</StyledTableCell>
                <StyledTableCell align="right" />
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => (
                <StyledTableRow key={row?.id}>
                  <StyledTableCell>{row.rs}</StyledTableCell>
                  <StyledTableCell>{row.cnpj}</StyledTableCell>
                  <StyledTableCell style={{ width: 100 }}>
                    {row.ie}
                  </StyledTableCell>
                  <StyledTableCell>{row.im}</StyledTableCell>
                  <StyledTableCell>{row.prefix}</StyledTableCell>
                  <StyledTableCell>
                    {row.status ? 'Ativa' : 'Inativa'}
                  </StyledTableCell>
                  <StyledTableCell>
                    {moment(row.createdAt).format('DD/MM/YYYY')}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <MoreVertIcon
                      onClick={e => handleMenu(row, e)}
                      style={{ cursor: 'pointer' }}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Menu
          id="menu-appbar"
          style={{ borderRadius: '25px' }}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            className={classes.textNotification}
            onClick={() => {
              handleEdit();
              handleClose();
            }}
          >
            Editar
          </MenuItem>
          {/* <MenuItem className={classes.textNotification} onClick={handleClose}>
            Ativar
          </MenuItem> */}
        </Menu>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100, 1000, 50000]}
          component="div"
          count={total}
          rowsPerPage={itens}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      {/* MODAL DE EDIÇÃO */}
      <Dialog
        onClose={() => setOpen(!open)}
        open={open}
        fullWidth
        maxWidth="sm"
      >
        <Loading open={loading} msg="Salvando..." />
        <DialogTitle id="simple-dialog-title">Editar empresa</DialogTitle>
        <form onSubmit={updateConf}>
          <Grid
            container
            spacing={1}
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              padding: 20,
            }}
          >
            <Grid item xs={12} sm={12}>
              <Grid
                container
                spacing={1}
                style={{
                  paddingRight: 20,
                  paddingLeft: 20,
                }}
              >
                <Grid item xs={12} sm={12}>
                  <h6>Razão social</h6>
                  <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    type="text"
                    value={companyName}
                    onChange={e => setCompanyName(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Grid
                container
                spacing={1}
                style={{
                  paddingRight: 20,
                  paddingLeft: 20,
                }}
              >
                <Grid item xs={12} sm={6}>
                  <h6>CNPJ</h6>
                  <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    type="number"
                    value={cnpj}
                    onChange={e => setCnpj(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <h6>IE</h6>
                  <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    type="text"
                    value={ie}
                    onChange={e => setIe(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <h6>IM</h6>
                  <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    value={im}
                    onChange={e => setIm(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <h6>Prefixo NF</h6>
                  <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    type="text"
                    value={prefix}
                    onChange={e => setPrefix(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Grid
                container
                spacing={1}
                style={{
                  paddingRight: 20,
                  paddingLeft: 20,
                }}
              >
                <Grid item xs={12} sm={12}>
                  <h6>Endereço</h6>
                  <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    type="text"
                    value={address}
                    onChange={e => setAddress(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Grid
                container
                spacing={1}
                style={{
                  paddingRight: 20,
                  paddingLeft: 20,
                }}
              >
                <Grid item xs={12} sm={4}>
                  <h6>Número</h6>
                  <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    type="text"
                    value={number}
                    onChange={e => setNumber(e.target.value)}
                    // defaultValue={dataEdit && dataEdit.cardId}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <h6>Bairro</h6>
                  <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    type="text"
                    value={district}
                    onChange={e => setDistrict(e.target.value)}
                    // defaultValue={dataEdit && dataEdit.cardId}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <h6>Cidade</h6>
                  <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    type="text"
                    value={city}
                    onChange={e => setCity(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Grid
                container
                spacing={1}
                style={{
                  paddingRight: 20,
                  paddingLeft: 20,
                  alignItems: 'end',
                }}
              >
                <Grid item xs={12} sm={12}>
                  <h6>Complemento</h6>
                  <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    type="text"
                    value={complement}
                    onChange={e => setComplement(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <h6>Estado</h6>
                  <Select
                    fullWidth
                    styles={customStylesEdit}
                    classNamePrefix="select"
                    placeholder="Tipo"
                    value={uf}
                    onChange={setUf}
                    isSearchable={false}
                    options={UFsOpt}
                    menuPortalTarget={document.body}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <h6>CEP</h6>
                  <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    type="text"
                    value={postalCode}
                    onChange={e => setPostalCode(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div>
                    <h6>Nome certificado</h6>
                    <TextField
                      size="small"
                      variant="outlined"
                      placeholder="Ex: Certificado.pfx"
                      fullWidth
                      type="text"
                      value={certName}
                      onChange={e => setCertName(e.target.value)}
                    />
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  style={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={principal}
                          onChange={e => setPrincipal(e.target.checked)}
                          name="principal"
                          color="primary"
                        />
                      }
                      label="Tornar empresa principal"
                    />
                  </FormGroup>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <DialogActions>
            <Button onClick={() => setOpen(false)} color="secondary">
              Cancelar
            </Button>
            <Button type="submit" color="primary" variant="contained" autoFocus>
              Salvar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
